import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Input, Tabs, Tag, Modal, Spin } from 'antd'

import DraggableTabs from '../../Components/DraggableTabs'
import SettingsActions from '../../Redux/SettingsRedux'
import Images from '../../Images'

const { TabPane } = Tabs;

const okButtonProps = {
  type:"primary",
  className:"btn-primary",
  style: {width: 172}
};

const deleteButtonProps = {
  type:"primary orange",
  className:"btn-primary",
  style: {width: 172}
};

const cancelButtonProps = {
  type:"primary white",
  className:"btn-primary",
  style: {width: 172}
};

const modalProps = {
  centered: true,
  closable:false,
  destroyOnClose: true,
  width: 560
};

function FocusManagerPage(props) {
  const { sectorFocus, fetching, saveResult, saveFetching, deleteResult, deleteFetching, changeOrderResult } = props;

  const [activeRootKey, setActiveRootKey] = useState(null);
  const [activeSubKey, setActiveSubKey] = useState(null);
  const [root, setRoot] = useState(null);
  const [start, setStart] = useState(true);

  const [addVisible, setAddVisible] = useState(false);
  const [addModalValue, setAddModalValue] = useState(null);
  const [addModalTitle, setAddModalTitle] = useState(null);

  const [editVisible, setEditVisible] = useState(false);
  const [editModalValue, setEditModalValue] = useState(null);
  const [editModalTitle, setEditModalTitle] = useState(null);

  const [deleteVisible, setDeleteVisible] = useState(false);
  const [deleteModalTitle, setDeleteModalTitle] = useState(null);

  const [tagActive, setTagActive] = useState(false);
  const [tagAction, setTagAction] = useState(false);

  const [draggableItem, setDraggableItem] = useState(null);

  const rootOperation = (<Button type="link" onClick={() => openAddModal(true)}>+ Add sector</Button>);
  const subOperation = (<Button type="link" onClick={() => openAddModal(false)}>+ Add group</Button>);

  useEffect(() => {
    props.getSectors();
  }, [0]);

  useEffect(() => {
    if (sectorFocus && start) {
      setActiveRootKey(String(sectorFocus[0]?.id));
      setActiveSubKey(String(sectorFocus[0]?.children[0]?.id));
      setStart(false);
    }
  }, [sectorFocus]);

  useEffect(() => {
    if (activeRootKey) {
      const item = sectorFocus.find(el => el.id === +activeRootKey);
      setActiveSubKey(String(item?.children[0]?.id));
    }
  }, [activeRootKey]);

  useEffect(() => {
    if (saveResult) {
      if (tagAction) {
        setTagActive(false);
        setTagAction(false);
      } else {
        setAddVisible(false);
        setEditVisible(false);
        if (root) {
          setActiveRootKey(String(saveResult.id));
        } else {
          setActiveSubKey(String(saveResult.id));
        }
      }
    }
  }, [saveResult]);

  useEffect(() => {
    if (deleteResult) {
      if (tagAction) {
        setTagActive(false);
        setTagAction(false);
      } else {
        setDeleteVisible(false);
        if (root) {
          setActiveRootKey(String(sectorFocus[0]?.id));
        } else {
          const item = sectorFocus.find(el => el.id === +activeRootKey)?.children[0]?.id;
          setActiveSubKey(String(item));
        }
      }
    }
  }, [deleteResult]);

  useEffect(() => {
    if (changeOrderResult) {
      setActiveRootKey(draggableItem);
      const item = sectorFocus.find(el => el.id === +draggableItem)?.children[0]?.id;
      setActiveSubKey(String(item));
    }
  }, [changeOrderResult]);

  function generateTabTitle(name, id, isRoot) {
    const active = isRoot ? activeRootKey : activeSubKey;
    return (
      <>
        <span>{name}</span>
        {
          +active === id &&
            <>
              <img src={Images.editTab} alt="" onClick={() => openEditModal(isRoot, name)} />
              <img src={Images.removeTab} alt="" onClick={() => openDeleteModal(isRoot, name)} />
            </>
        }
      </>
    )
  }

  function openAddModal(isRoot) {
    setRoot(isRoot);
    setAddModalValue(null);
    setAddModalTitle(isRoot ? 'sector' : 'group');
    setAddVisible(true);
  }

  function openEditModal(isRoot, name) {
    setRoot(isRoot);
    setEditModalValue(name);
    setEditModalTitle(isRoot ? "sector's" : "group's");
    setEditVisible(true);
  }

  function openDeleteModal(isRoot, name) {
    setRoot(isRoot);
    setDeleteModalTitle(name);
    setDeleteVisible(true);
  }

  function createItem() {
    if (addModalValue) {
      const data = {
        parent: root ? null : sectorFocus.find(el => el.id === +activeRootKey)?.id,
        name: addModalValue
      }
      props.createSectorItem(data);
    }
  }

  function editItem() {
    if (editModalValue) {
      const data = {
        parent: root ? null : sectorFocus.find(el => el.id === +activeRootKey)?.id,
        name: editModalValue
      }
      const itemId = root ?
        sectorFocus.find(el => el.id === +activeRootKey)?.id
      :
        sectorFocus.find(el => el.id === +activeRootKey)?.children?.find(child => child.id === +activeSubKey)?.id;
      props.updateSectorItem(itemId, data);
    }
  }

  function deleteItem() {
    const itemId = root ?
      sectorFocus.find(el => el.id === +activeRootKey)?.id
    :
      sectorFocus.find(el => el.id === +activeRootKey)?.children?.find(child => child.id === +activeSubKey)?.id;
    props.deleteSectorItem(itemId);
  }

  function addTag(e) {
    const data = {
      parent: sectorFocus.find(el => el.id === +activeRootKey)?.children?.find(child => child.id === +activeSubKey)?.id,
      name: e.target.value
    }
    props.createSectorItem(data);
    setTagAction(true);
  }

  function deleteTag(itemId, e) {
    e.preventDefault();
    props.deleteSectorItem(itemId);
    setTagAction(true);
  }

  function changeOrder(key, data) {
    setDraggableItem(key);
    const newData = data.map(item => (
      sectorFocus.find(el => el.id === +item)
    ));
    props.changeOrder(newData);
  }

  return (
    <div className="main-container admin-container">
      <div className="focus-manager">
        <Spin spinning={fetching || saveFetching || deleteFetching} size="large">
          <DraggableTabs
            className="focus-manager-tabs"
            tabBarExtraContent={rootOperation}
            activeKey={activeRootKey}
            onChange={setActiveRootKey}
            changeOrder={changeOrder}
          >
            {
              sectorFocus && sectorFocus.map(sector => (
                <TabPane key={sector.id} tab={generateTabTitle(sector.name, sector.id, true)}>
                  <Tabs
                    className="focus-manager-tabs"
                    tabBarExtraContent={subOperation}
                    activeKey={activeSubKey}
                    onChange={setActiveSubKey}
                  >
                    {
                      sector.children.map(child => (
                        <TabPane key={child.id} tab={generateTabTitle(child.name, child.id)}>
                          {
                            child.children.map(subsector => (
                              <Tag
                                className="focus-manager-tag"
                                closable
                                key={subsector.id}
                                onClose={e => deleteTag(subsector.id, e)}
                              >
                                {subsector.name}
                              </Tag>
                            ))
                          }
                          {
                            tagActive ?
                              <Input
                                className="tag-input"
                                suffix={<img src={Images.closeGrey} style={{cursor: 'pointer'}} alt="" onClick={()=>setTagActive(false)} />}
                                onPressEnter={addTag}
                              />
                            :
                              <span className="plus-tag" onClick={()=>setTagActive(true)}>+</span>
                          }
                        </TabPane>
                      ))
                    }
                  </Tabs>
                </TabPane>
              ))
            }
          </DraggableTabs>

          <Modal
            {...modalProps}
            visible={addVisible}
            onOk={createItem}
            okText="Add"
            okButtonProps={okButtonProps}
            onCancel={() => setAddVisible(false)}
            cancelButtonProps={cancelButtonProps}
          >
            <div className="modal-title">
              <span>{`Add ${addModalTitle}`}</span>
            </div>
            <Input
              placeholder={`Enter ${addModalTitle} name`}
              defaultValue={addModalValue}
              onBlur={e=>setAddModalValue(e.target.value)}
            />
          </Modal>
          <Modal
            {...modalProps}
            visible={editVisible}
            onOk={editItem}
            okText="Edit"
            okButtonProps={okButtonProps}
            onCancel={() => setEditVisible(false)}
            cancelButtonProps={cancelButtonProps}
          >
            <div className="modal-title">
              <span>{`Edit ${editModalTitle} name`}</span>
            </div>
            <Input
              defaultValue={editModalValue}
              onBlur={e=>setEditModalValue(e.target.value)}
            />
          </Modal>
          <Modal
            {...modalProps}
            visible={deleteVisible}
            onOk={deleteItem}
            okText="Delete"
            okButtonProps={deleteButtonProps}
            onCancel={() => setDeleteVisible(false)}
            cancelButtonProps={cancelButtonProps}
          >
            <div className="delete-title">
              <span>Do you want to delete </span>
              <span>{root ? 'sector' : 'group'}</span>
              <br />
              <span className="delete-item">{deleteModalTitle}</span>
              <span>?</span>
            </div>
          </Modal>
        </Spin>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sectorFocus: state.settings.sectorFocus,
    fetching: state.settings.fetching,
    saveResult: state.settings.saveResult,
    saveFetching: state.settings.saveFetching,
    deleteResult: state.settings.deleteResult,
    deleteFetching: state.settings.deleteFetching,
    changeOrderResult:state.settings.changeOrderResult
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  createSectorItem: (data) => dispatch(SettingsActions.sectorFocusItemCreateRequest(data)),
  updateSectorItem: (itemId, data) => dispatch(SettingsActions.sectorFocusItemUpdateRequest(itemId, data)),
  deleteSectorItem: (itemId) => dispatch(SettingsActions.sectorFocusItemDeleteRequest(itemId)),
  changeOrder: (data) => dispatch(SettingsActions.sectorFocusOrderRequest(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FocusManagerPage)
