import { call, put, take, select, delay } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import DealsActions, {DealsTypes, DealsSelectors} from '../Redux/DealsRedux'
import AuthActions, {AuthSelectors} from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'
import NotificationsActions from '../Redux/NotificationsRedux'

export function * getDeals(api, authApi, { page, page_size, sort_field, sort_order, search, filter }) {
  try {
    const response = yield call(api.getDeals, page, page_size, sort_field, sort_order, search, filter)
    if (response && response.ok) {
      yield put(DealsActions.dealsSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getDeals(api, authApi, { page, page_size, sort_field, sort_order, search, filter })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getDeal(api, authApi, { dealId }) {
  try {
    const response = yield call(api.getDeal, dealId)
    if (response && response.ok) {
      yield put(DealsActions.dealSuccess(response.data))
      yield put(DealsActions.setDealId(dealId))
      yield put(DealsActions.dealReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getDeal(api, authApi, { dealId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * createDeal(api, authApi, { data }) {
  try {
    const response = yield call(api.createDeal, data)
    if (response && response.ok) {
      yield put(DealsActions.dealSaveSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * createDeal(api, authApi, { data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * deleteDeal(api, authApi, { dealId }) {
  try {
    const response = yield call(api.deleteDeal, dealId)
    if (response && response.ok) {
      yield put(DealsActions.dealDeleteSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * deleteDeal(api, authApi, { dealId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * changeDealStatus(api, authApi, { dealId, status }) {
  try {
    const response = yield call(api.changeDealStatus, dealId, status)
    if (response && response.ok) {
      yield put(DealsActions.dealChangeStatusSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * changeDealStatus(api, authApi, { dealId, status })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * deleteDealNotification(api, authApi, { dealId, data }) {
  try {
    const response = yield call(api.deleteDealNotification, dealId, data)
    if (response && response.ok) {
      yield put(DealsActions.dealNotificationDeleteSuccess(response.data))
      yield put(DealsActions.resultsReset())
      const role = yield select(AuthSelectors.getRole);
      yield put(NotificationsActions.notificationsRequest())
      if (role === 0) {
        yield put(NotificationsActions.advisorDealGroupNotificationsRequest())
      } else if (role === 2) {
        yield put(NotificationsActions.investorDealGroupNotificationsRequest())
      }
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * deleteDealNotification(api, authApi, { dealId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * deleteDealNotification_(api, authApi, { dealId }) {
  try {
    const response = yield call(api.deleteDealNotification_, dealId)
    if (response && response.ok) {
      yield put(DealsActions.dealNotificationDeleteSuccess_(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * deleteDealNotification_(api, authApi, { dealId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * saveDealOverview(api, authApi, { data }) {
  try {
    const response = yield call(api.saveDealOverview, data)
    if (response && response.ok) {
      yield put(DealsActions.dealOverviewSaveSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * saveDealOverview(api, authApi, { data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * saveDealRoleInfo(api, authApi, { data }) {
  try {
    const response = yield call(api.saveDealRoleInfo, data)
    if (response && response.ok) {
      yield put(DealsActions.dealRoleInfoSaveSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * saveDealRoleInfo(api, authApi, { data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * saveDealInvestorSought(api, authApi, { data }) {
  try {
    const response = yield call(api.saveDealInvestorSought, data)
    if (response && response.ok) {
      yield put(DealsActions.dealInvestorSoughtSaveSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * saveDealInvestorSought(api, authApi, { data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * saveDealGroupData(api, authApi, { dealId, data }) {
  try {
    const response = yield call(api.saveDealGroupData, dealId, data)
    if (response && response.ok) {
      yield put(DealsActions.dealGroupDataSaveSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * saveDealGroupData(api, authApi, { dealId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * withdrawDeal(api, authApi, { dealId, data }) {
  try {
    const response = yield call(api.withdrawDeal, dealId, data)
    if (response && response.ok) {
      yield put(DealsActions.dealWithdrawSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * withdrawDeal(api, authApi, { dealId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getDealStatuses(api, authApi) {
  try {
    const response = yield call(api.getDealStatuses)
    if (response && response.ok) {
      yield put(DealsActions.dealStatusesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getDealStatuses(api, authApi)
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getInvestorShareStatuses(api, authApi) {
  try {
    const response = yield call(api.getInvestorShareStatuses)
    if (response && response.ok) {
      yield put(DealsActions.dealInvestorShareStatusesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getInvestorShareStatuses(api, authApi)
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getDealAdvisorShared(api, authApi, { data, page, page_size }) {
  try {
    const response = yield call(api.getDealAdvisorShared, data, page, page_size)
    if (response && response.ok) {
      yield put(DealsActions.dealAdvisorsSharedSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getDealAdvisorShared(api, authApi, data, page, page_size)
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getDealAdvisorSharedHistory(api, authApi, { data, page, page_size }) {
  try {
    const response = yield call(api.getDealAdvisorSharedHistory, data, page, page_size )
    if (response && response.ok) {
      yield put(DealsActions.dealAdvisorsSharedHistorySuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getDealAdvisorSharedHistory(api, authApi, data, page, page_size)
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getDealInvestorsShared(api, authApi, {data}) {
  try {
    const response = yield call(api.getDealInvestorsShared, data)
    if (response && response.ok) {
      yield put(DealsActions.dealInvestorsSharedSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getDealInvestorsShared(api, authApi, {data})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * shareDealWithInvestors(api, authApi, {dealId, data}) {
  try {
    const response = yield call(api.shareDealWithInvestors, dealId, data)
    if (response && response.ok) {
      yield put(DealsActions.dealShareWithInvestorsSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * shareDealWithInvestors(api, authApi, {dealId, data})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * shareDealWithSpecificInvestor(api, authApi, { investor_id, data }) {
  try {
    const response = yield call(api.shareDealWithSpecificInvestor, investor_id, data)
    if (response && response.ok) {
      yield put(DealsActions.dealShareWithSpecificInvestorSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * shareDealWithSpecificInvestor(api, authApi, investor_id, data)
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * reshareDealRequestByAdvisor(api, authApi, {dealId}) {
  try {
    const response = yield call(api.reshareDealRequestByAdvisor, dealId)
    if (response && response.ok) {
      yield put(DealsActions.dealReshareRequestByAdvisorSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * reshareDealRequestByAdvisor(api, authApi, {dealId})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * reshareDealByAdmin(api, authApi, {dealId}) {
  try {
    const response = yield call(api.reshareDealByAdmin, dealId)
    if (response && response.ok) {
      yield put(DealsActions.dealReshareByAdminSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * reshareDealByAdmin(api, authApi, {dealId})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * reshareDealByAdvisor(api, authApi, {dealId}) {
  try {
    const response = yield call(api.reshareDealByAdvisor, dealId)
    if (response && response.ok) {
      yield put(DealsActions.dealReshareByAdvisorSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * reshareDealByAdvisor(api, authApi, {dealId})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * shareDealByAdvisor(api, authApi, {dealId}) {
  try {
    const response = yield call(api.shareDealByAdvisor, dealId)
    if (response && response.ok) {
      yield put(DealsActions.dealShareByAdvisorSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * shareDealByAdvisor(api, authApi, {dealId})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * applyDealByInvestor_(api, authApi, { dealId, data }) {
  try {
    const response = yield call(api.applyDealByInvestor_, dealId, data)
    if (response && response.ok) {
      yield put(DealsActions.dealInvestorApplySuccess_(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * applyDealByInvestor_(api, authApi, { dealId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * shareDealWithColleagues_(api, authApi, { dealId, data }) {
  try {
    const response = yield call(api.shareDealWithColleagues_, dealId, data)
    if (response && response.ok) {
      yield put(DealsActions.dealInvestorSendColleaguesSuccess_(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * shareDealWithColleagues_(api, authApi, { dealId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getDealTargetStatus(api, authApi, {dealId}) {
  try {
    const response = yield call(api.getDealTargetStatus, dealId)
    if (response && response.ok) {
      yield put(DealsActions.dealTargetStatusSuccess(response.data))
      yield put(DealsActions.dealTargetStatusReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getDealTargetStatus(api, authApi, {dealId})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * saveDealTargetStatus(api, authApi, { data }) {
  try {
    const response = yield call(api.saveDealTargetStatus, data)
    if (response && response.ok) {
      yield put(DealsActions.dealTargetStatusSaveSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * saveDealTargetStatus(api, authApi, { data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * updateDealTargetStatus(api, authApi, { targetId, data }) {
  try {
    const response = yield call(api.updateDealTargetStatus, targetId, data)
    if (response && response.ok) {
      yield put(DealsActions.dealTargetStatusUpdateSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * updateDealTargetStatus(api, authApi, { targetId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * rejectMandateDeal(api, authApi, { dealId }) {
  try {
    const response = yield call(api.rejectMandateDeal, dealId)
    if (response && response.ok) {
      yield put(DealsActions.mandateDealRejectSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * rejectMandateDeal(api, authApi, { dealId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * shareMandateDealByAdmin(api, authApi, { dealId }) {
  try {
    const response = yield call(api.shareMandateDealByAdmin, dealId)
    if (response && response.ok) {
      yield put(DealsActions.adminShareMandateDealSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * shareMandateDealByAdmin(api, authApi, { dealId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * changeNotificationDealStatus(api, authApi, { notificationId }) {
  try {
    const response = yield call(api.changeNotificationDealStatus, notificationId)
    if (response && response.ok) {
      yield put(DealsActions.notificationChangeDealStatusSuccess(response.data))
      yield put(DealsActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealsActions.dealsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * changeNotificationDealStatus(api, authApi, { notificationId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealsActions.dealsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * dealsSyncStartMonitor() {
  while (true) {
    const payload = yield take(DealsTypes.DEALS_SYNC_TOGGLE);
    let doWork = payload.syncIsStart;
    while (doWork) {
      const {page, page_size, sort_field, sort_order, search, filter, syncIsStart} = yield select(DealsSelectors.getState);
      if (!syncIsStart) {
        break;
      }
      yield put(DealsActions.dealsRequest(page, page_size, sort_field, sort_order, search, filter));
      doWork = syncIsStart;
      yield delay(30000);
    }
  }
}
