import React from 'react'
import { Button, Spin } from 'antd'
import { connect } from 'react-redux'

import PopupWrapper from './PopupWrapper';
import MandateSharesActions from '../../Redux/MandateSharesRedux'

import Images from '../../Images'
import './PopupPage.less'

function CloseOutMandatePopupPage(props) {

  const { match, fetching } = props;
  const { mandateShareId, tab } = match.params;

  function onConfirm() {
    props.mandateSharesDeleteRequest(mandateShareId)
  }

  return (
    <PopupWrapper
      width={420}
      path={`/home/mandate-share-info/${mandateShareId}/${tab}`}
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content" style={{ padding: '40px 30px' }}>
          <img src={Images.phoneOk} alt="" />
          <div className="dialog-title deal">Close out Mandate</div>
          <div className="deal-text">By closing out a Mandate, all the deals that you have submitted in response to this Mandate that have not entered yet the "Engaged" status" will be automatically withdrawn. Also, the Mandate will be moved from "Your Mandates" tab into the "Closed Mandate" tab.</div>
          <br />
          <div className="btn-footer" style={{marginTop: 20}}>
            <Button type="primary white" className="btn-primary" onClick={onConfirm}>
              Confirm
              </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
	return {
    fetching: state.mandateShares.fetching,
	}
}

const mapDispatchToProps = dispatch => ({
  mandateSharesDeleteRequest: (mandateShareId) => dispatch(MandateSharesActions.mandateSharesDeleteRequest(mandateShareId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CloseOutMandatePopupPage)
