import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table, Switch  } from 'antd'
import moment from 'moment'

import PaginationItemRender from '../PaginationItemRender'
import { TitleWithSortFilters } from '../TitleWithSortFilters'
import CustomIcon from '../../Icon'

import './Table.less'

function AdvisorsTable(props){
  const {
    page,
    pageSize,
    data,
    fetching
  } = props;

  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    let arr = (data && data.results) ? data.results.asMutable({deep:true}) : [];
    arr = arr.map(el => ({
      id: el.user?.id,
      name: `${el.name} ${el.surname}`,
      status: el.status_obj?.name,
      account_manager: el.account_manager?.name,
      created: el.user?.created,
      converted: el.converted,
      last_login: el.user?.last_login,
      is_blocked: el.user?.is_blocked,
      is_active: el.user?.is_active,
      register_status: el.user?.register_status_obj?.id,
      source: el.user?.source_obj?.id
    }))
    setDataSource(arr);
  }, [data]);

  function getColumns() {
    return [
      {
        title: (stateData)=>TitleWithSortFilters("Advisor", true, false, stateData, 'name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        render: (text, record) => {
          return (
            <Link to={'/home/advisor-details/' + record.id} onClick={()=>props.checkUserStatus(record)}>
              {text}
            </Link>
          )
        },
      },
      {
        title: (stateData)=>TitleWithSortFilters("Status", true, false, stateData, 'status'),
        dataIndex: 'status',
        key: 'status',
        sorter:true,
      },
      {
        title: (stateData)=>TitleWithSortFilters("Account Manager", true, false, stateData, 'account_manager'),
        dataIndex: 'account_manager',
        key: 'account_manager',
        sorter: true,
      },
      {
        title: (stateData)=>TitleWithSortFilters("Created on", true, false, stateData, 'created'),
        dataIndex: 'created',
        key: 'created',
        sorter: true,
        render: (text, record) => {
          return (
              <span>{text && moment(text).format('DD/MM/YYYY')}</span>
          )
        },
      },
      {
        title: (stateData)=>TitleWithSortFilters("Converted on", true, false, stateData, 'converted'),
        dataIndex: 'converted',
        key: 'converted',
        sorter: true,
        render: (text, record) => {
          return (
            <span>{text && moment(text).format('DD/MM/YYYY')}</span>
          )
        },
      },
      {
        title: (stateData)=>TitleWithSortFilters("Last access", true, false, stateData, 'last_login'),
        dataIndex: 'last_login',
        key: 'last_login',
        sorter: true,
        render: (text, record) => {
          return (
            <span>{text && moment(text).format('DD/MM/YYYY')}</span>
          )
        },
      },
      {
        title: (stateData)=>TitleWithSortFilters("Locked", true, false, stateData, 'is_blocked'),
        dataIndex: 'is_blocked',
        key: 'is_blocked',
        sorter: true,
        render: (text, record) => {
          return (
            <span className={text ? 'red-cell' : 'blue-cell'}>{text ? 'Locked' : 'Unlocked'}</span>
          )
        },
      }
    ];
  }

  function handleTableChange(pagination, filters, sorter){

    var sort_field = null;
    var sort_order = null;

    if(sorter && sorter.field && sorter.order){
      sort_field = sorter.field;
      sort_order = sorter.order //=== "ascend" ? 1 : 0;
    }

    props.onChangePage(pagination.current, pagination.pageSize, sort_field, sort_order)

  }

  const totalCount = data ? data.count : 0;

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount, showSizeChanger: true}}
      className="main-table"
      rowKey={row => row.id}
      rowClassName={row => {
        if (row?.source === 'app_form' && row?.register_status === 'new') {
          return 'new-advisor-row'
        } else return '';
      }}
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
      showSorterTooltip={false}
    />
   )


}

export default AdvisorsTable
