import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Radio, Collapse, Button, Tabs, Checkbox, Row, Col, Input, Space, Tooltip, InputNumber, message } from 'antd'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import Utils from '../../Utils/utils'
import SettingsActions from '../../Redux/SettingsRedux'
import CountryGrid from '../../Components/CountryGrid'
import SectorGrid from '../../Components/SectorGrid'
import CollapseHeader from '../../Components/CollapseHeader'
import Images from '../../Images'

const { Panel } = Collapse;
const { TabPane } = Tabs;

const addInfo = {
  field: 'open_to_being_matched',
  value: null,
};

function InvestorFiltersEditPage(props) {
  const { sectorFocus, geographyFocus, filters, firmFilters, saveResult, equityGovernanceList, investmentTypes, countries, euCountries, isAdmin, match } = props;
  const { id } = match.params;

  const [sectorCheckedList, setSectorCheckedList] = useState(null);
  const [sectorCheckAll, setSectorCheckAll] = useState(null);
  const [sectorActiveTab, setSectorActiveTab] = useState(null);
  const [sectorOptions, setSectorOptions] = useState(null);

  const [continentCheckedList, setContinentCheckedList] = useState(null);
  const [continentCheckAll, setContinentCheckAll] = useState(null);
  const [continentActiveTab, setContinentActiveTab] = useState(null);
  const [continentOptions, setContinentOptions] = useState(null);
  const [countryList, setCountryList] = useState(null);

  const [otherSectorText, setOtherSectorText] = useState(null);
  const [otherSectorValue, setOtherSectorValue] = useState(null);
  const [otherSectorList, setOtherSectorList] = useState(null);

  const [investmentSector, setInvestmentSector] = useState(null);
  const [governanceOptions, setGovernanceOptions] = useState(null);
  const [governanceActive, setGovernanceActive] = useState(false);
  const [investmentTypeOptions, setInvestmentTypeOptions] = useState(null);

  const [addInfoValues, setAddInfoValues] = useState(null);

  const [required, setRequired] = useState({
    active: false,
    investment_focus: false,
    sector_focus: false,
    geographic_focus: false
  })
  const [isFirm, setIsFirm] = useState(null);
  const [filterData, setFilterData] = useState(null);

  const history = useHistory();

  function backNavigate() {
    let backPath = null;
    if (isAdmin) {
      backPath = props.location.pathname.includes('investor-firm') ? `/home/firm-details/${id}/filters` : `/home/investor-details/${id}/filters`;
    } else {
      backPath = props.location.pathname.includes('investor-firm') ? "/home/firm's-profile/filters" : "/home/settings/filters";
    }
    history.push(backPath);
  }

  useEffect(() => {
    props.getSectors();
    props.getContinents({oecd: true});
    props.getEquityGovernance();
    props.getInvestmentTypes();
    props.getCountries();
    props.getEuCountries(true);
    setIsFirm(props.location.pathname.includes('investor-firm'));
  }, [0]);

  useEffect(() => {
    if (isFirm === true) {
      props.getFirmFilters(id);
    } else if (isFirm === false) {
      props.getFilters(id, 'investor');
    }
  }, [isFirm]);

  useEffect(() => {
    if (isFirm === false) {
      setFilterData(filters);
    }
    if (isFirm === true) {
      setFilterData(firmFilters);
    }

  }, [filters, firmFilters]);

  useEffect(() => {
    if (filterData) {
      const investment = {
        enterprise_value: [filterData.investment_focus?.enterprise_value_from, filterData.investment_focus?.enterprise_value_to],
        equity_investment: [filterData.investment_focus?.equity_investment_from, filterData.investment_focus?.equity_investment_to],
        equity_stake: [filterData.investment_focus?.equity_stake_from, filterData.investment_focus?.equity_stake_to],
        governance: filterData.investment_focus?.governace_of_the_targeted_equity ? filterData.investment_focus?.governace_of_the_targeted_equity.map(el => el.id) : [],
        governance_other: filterData.investment_focus?.specified_governace,
        investment_type: filterData.investment_focus?.investment_type ? filterData.investment_focus?.investment_type.map(el => el.id) : [],
        comment: filterData.investment_focus?.comment,
      }
      setInvestmentSector(investment);
      setGovernanceActive(filterData.investment_focus?.specified_governace);
      if (filterData.open_to_being_matched) {
        const newData = { ...addInfo, ...filterData.open_to_being_matched };
        setAddInfoValues(newData);
      } else {
        setAddInfoValues(addInfo);
      }
    }
  }, [filterData]);

  useEffect(() => {
    if (saveResult) {
      backNavigate();
    }
  }, [saveResult]);

  useEffect(() => {
    if (sectorFocus) {
      let sectorsInit = {};
      let sectorsListInit = {};
      let otherSectorsInit = {};
      let options = {};
      sectorFocus.forEach(sector => {
        sectorsInit[sector.name] = false;
        sectorsListInit[sector.name] = [];
        otherSectorsInit[sector.name] = {
          active: false,
          text: '',
          parent: sector.id
        };
        options[sector.name] = sector.children;
      });
      if (filterData) {
        filterData.industry_focus_sub_sectors.forEach(el => {
          sectorsListInit[el.name] = el.children.map(child => child.id);
          sectorsInit[el.name] = sectorFocus.find(sector => sector.id === el.id).children.length === el.children.length;
          otherSectorsInit[el.name].text = el.additional_specification;
        });
        setOtherSectorList(otherSectorsInit);
        setSectorCheckAll(sectorsInit);
        setSectorCheckedList(sectorsListInit);
      }
      setSectorOptions(options);
      setSectorActiveTab(sectorFocus[0]?.name);
    }
  }, [sectorFocus, filterData]);

  useEffect(() => {
    if (sectorActiveTab) {
      setOtherSectorValue(null);
      let text = sectorFocus.find(sector => sector.name === sectorActiveTab).other_sector;
      setOtherSectorText(text);
    }
  }, [sectorActiveTab]);

  useEffect(() => {
    if (geographyFocus && countries) {
      let continentsInit = {};
      let continentsListInit = {};
      let options = {};
      let countryList = {};
      countries.forEach(country => {
        countryList[country.id] = [];
      });
      geographyFocus.forEach(continent => {
        continentsInit[continent.name] = false;
        continentsListInit[continent.name] = [];
        options[continent.name] = [];
        for (let i = 0; i < continent.children.length; i++) {
          countryList[continent.children[i].id].push(continent.name);
          options[continent.name].push(
            { label: continent.children[i].name, value: continent.children[i].id },
          );
        }
      });
      if (filterData) {
        filterData.continents.forEach(el => {
          continentsListInit[el.name] = el.children.map(child => child.id);
          continentsInit[el.name] = geographyFocus.find(continent => continent.id === el.id).children.length === el.children.length;
        });
        setContinentCheckAll(continentsInit);
        setContinentCheckedList(continentsListInit);
      }
      setCountryList(countryList);
      setContinentOptions(options);
      setContinentActiveTab(geographyFocus[0]?.name);
    }
  }, [geographyFocus, filterData, countries]);

  useEffect(() => {
    if (equityGovernanceList) {
      const options = [];
      for (let i = 0; i < equityGovernanceList.length; i++) {
        options.push(
          { label: equityGovernanceList[i].name, value: equityGovernanceList[i].id },
        );
      }
      setGovernanceOptions(options);
    }
  }, [equityGovernanceList]);

  useEffect(() => {
    if (investmentTypes) {
      const options = [];
      for (let i = 0; i < investmentTypes.length; i++) {
        options.push(
          { label: investmentTypes[i].name, value: investmentTypes[i].id },
        );
      }
      setInvestmentTypeOptions(options);
    }
  }, [investmentTypes]);

  useEffect(() => {
    if (sectorCheckedList && continentCheckedList && investmentSector && otherSectorList) {
      let checkSectors = Object.values(sectorCheckedList).some(el => el.length);
      let checkOtherSectorsList = Object.values(otherSectorList).filter(el => el.active).every(el => el.text);
      let checkContinents = Object.values(continentCheckedList).some(el => el.length);
      let checkInvestment =
        Utils.checkEmptyValue(investmentSector.enterprise_value) &&
        Utils.checkEmptyValue(investmentSector.equity_investment) &&
        (Utils.checkEmptyValue(investmentSector.equity_stake) && investmentSector.equity_stake.every(el => el > 0 && el < 101)) &&
        investmentSector.investment_type.length > 0;
      if (investmentSector.investment_type.includes(3) && !investmentSector.comment) {
        checkInvestment = false;
      }

      const newData = {
        investment_focus: checkInvestment,
        sector_focus: checkSectors && checkOtherSectorsList,
        geographic_focus: checkContinents,
      };
      setRequired({ ...required, ...newData });
    }
  }, [sectorCheckedList, continentCheckedList, investmentSector, otherSectorList]);

  const selectCountries = (data, list, activeTab, checkAll) => {
    if (list.length > data[activeTab].length) {
      list.forEach(el => {
        countryList[el].forEach(continent => {
          data[continent].push(el);
        })
      })
    } else {
      const diff = _.difference(data[activeTab], list);
      diff.forEach(el => {
        countryList[el].forEach(continent => {
          data[continent] = _.without(data[continent], el);
          checkAll[continent] = false;
        })
      })
    }
  }

  const onChange = (list, isSector) => {
    if (isSector) {
      const newData = { ...sectorCheckedList };
      newData[sectorActiveTab] = list;
      setSectorCheckedList(newData);
    } else {
      const data = _.cloneDeep(continentCheckedList);
      const checkAll = _.cloneDeep(continentCheckAll);

      selectCountries(data, list, continentActiveTab, checkAll);

      data[continentActiveTab] = list;
      setContinentCheckedList(data);
      setContinentCheckAll(checkAll);
    }
  };

  const onCheckAllChange = isSector => {
    if (isSector === true) {
      let dataList = { ...sectorCheckedList };
      let dataCheck = { ...sectorCheckAll };

      dataList[sectorActiveTab] = !dataCheck[sectorActiveTab] ?
        sectorOptions[sectorActiveTab].map(el => el.children).flat().map(el => el.id)
        :
        []
      dataCheck[sectorActiveTab] = !dataCheck[sectorActiveTab];

      setSectorCheckedList(dataList);
      setSectorCheckAll(dataCheck);
    } else {
      const data =  _.cloneDeep(continentCheckedList);
      let dataList =  _.cloneDeep(continentCheckedList);
      const checkAll = _.cloneDeep(continentCheckAll);

      dataList[continentActiveTab] = !checkAll[continentActiveTab] ?
        continentOptions[continentActiveTab].map(el=>el.value)
      :
        [];

      checkAll[continentActiveTab] = !checkAll[continentActiveTab];

      selectCountries(data, dataList[continentActiveTab], continentActiveTab, checkAll);

      setContinentCheckedList(data);
      setContinentCheckAll(checkAll);
    }
  };

  const onEuropeanChange = () => {
    const data =  _.cloneDeep(continentCheckedList);
    let dataList =  _.cloneDeep(continentCheckedList);
    const checkAll = _.cloneDeep(continentCheckAll);

    dataList[continentActiveTab] = !checkAll[continentActiveTab] ?
      euCountries.map(el=>el.id)
    :
      []

    checkAll[continentActiveTab] = !checkAll[continentActiveTab];

    selectCountries(data, dataList[continentActiveTab], continentActiveTab, checkAll);

    setContinentCheckedList(data);
    setContinentCheckAll(checkAll);
  };

  const changeOtherSector = e => {
    const newData = { ...otherSectorList };
    newData[sectorActiveTab].active = e.target.checked;
    setOtherSectorList(newData);
  }

  const changeSpecValue = e => {
    const newData = { ...otherSectorList };
    newData[sectorActiveTab].text = e.target.value;
    setOtherSectorList(newData);
  }

  const changeInvestmentRange = (e, position, field) => {
    if (e.target.value !== '') {
      const newData = {...investmentSector};
      newData[field][position] = Number(e.target.value);
      if (typeof newData[field][1] === 'number' && newData[field][0] > newData[field][1]) {
        newData[field][0] = newData[field][1];
      }
      setInvestmentSector(newData);
    }
  }

  const changeInvestmentCheck = list => {
    const newData = { ...investmentSector };
    newData.governance = list;
    setInvestmentSector(newData);
  }

  const changeInvestmentOtherInput = e => {
    const newData = { ...investmentSector };
    newData.governance_other = e.target.value;
    setInvestmentSector(newData);
  }

  const changeAddInfoValue = value => {
    const newData = {...addInfoValues};
    newData.value = value;
    setAddInfoValues(newData);
  }

  const changeInvestmentType = list => {
    const newData = {...investmentSector};
    newData.investment_type = list;
    setInvestmentSector(newData);
  }
  const changeComment = value => {
    const newData = {...investmentSector};
    newData.comment = value;
    setInvestmentSector(newData);
  }

  const updateData = () => {
    let checkSectors = Object.values(sectorCheckedList).some(el => el.length);
    let checkOtherSectorsList = Object.values(otherSectorList).filter(el => el.active).every(el => el.text);
    let checkContinents = Object.values(continentCheckedList).some(el => el.length);
    let checkInvestment =
        Utils.checkEmptyValue(investmentSector.enterprise_value) &&
        Utils.checkEmptyValue(investmentSector.equity_investment) &&
        (Utils.checkEmptyValue(investmentSector.equity_stake) && investmentSector.equity_stake.every(el => el > 0 && el < 101)) &&
        investmentSector.investment_type.length > 0;
    if (investmentSector.investment_type.includes(3) && !investmentSector.comment) {
      checkInvestment = false;
    }

    const newData = {
      active: true,
      investment_focus: checkInvestment,
      sector_focus: checkSectors && checkOtherSectorsList,
      geographic_focus: checkContinents,
    };
    setRequired({ ...required, ...newData });

    if (checkSectors && checkContinents && checkInvestment && checkOtherSectorsList) {
      const totalData = {
        industry_focus_sub_sectors: Object.values(sectorCheckedList).flat(),
        countries: Object.values(continentCheckedList).flat(),
        additional_specification: Object.values(otherSectorList).flat().map(el => ({
          name: el.text,
          parent: el.parent
        })),
        investment_focus: {
          enterprise_value_from: investmentSector.enterprise_value[0],
          enterprise_value_to: investmentSector.enterprise_value[1],
          equity_investment_from: investmentSector.equity_investment[0],
          equity_investment_to: investmentSector.equity_investment[1],
          equity_stake_from: investmentSector.equity_stake[0],
          equity_stake_to: investmentSector.equity_stake[1],
          governace_of_the_targeted_equity: investmentSector.governance,
          specified_governace: governanceActive ? investmentSector.governance_other : null,
          investment_type: investmentSector.investment_type,
          comment: investmentSector.investment_type.includes(3) ? investmentSector.comment : null
        },
        open_to_being_matched: {
          value: addInfoValues.value,
          text: null
        }
      };
      if (isFirm === true) {
        props.updateFirmFilters(id, totalData);
      } else if (isFirm === false) {
        props.updateFilters(id, 'investor', totalData);
      }
    } else {
      message.error('All fields must be filled in to proceed', 3);
    }
  }

  return (
    <div className="sign-up-step white filters-edit">
      <img src={Images.logo} alt="" />
      <div className="step2-content">
        <div className="step-number"></div>
        <div className="step-title" style={{ marginTop: 96, marginBottom: 150 }}>Edit Matching Filters</div>
      </div>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ?
            <img src={Images.angleUp} alt="" />
            :
            <img src={Images.angleDown} alt="" />
        }
        expandIconPosition="right"
        className="site-collapse-custom-collapse"
        accordion
      >
        <Panel
          header={<CollapseHeader text="Investment focus" imageType="coins" />} key="investment"
          className={`site-collapse-custom-panel${(required.active && !required.investment_focus) ? ' error-block' : ''}`}
        >
          <div>
            <div className="collapse-content-title">{`Please indicate the size and type of investment opportunities ${isFirm ? 'your firm is targeting' : 'you are targeting'}`}</div>
            <div className="investment-range">
              <div className="investment-range-title">Enterprise value</div>
              <div className="investment-range-data">
                <span>Range (ml)</span>
                <div className="investment-range-label">From</div>
                <InputNumber
                  min={0}
                  max={99999}
                  placeholder="...ml"
                  parser={value => Number(value).toFixed()}
                  value={investmentSector?.enterprise_value[0]}
                  onBlur={(e) => changeInvestmentRange(e, 0, 'enterprise_value')}
                />

                <div className="investment-range-label">To</div>
                <InputNumber
                  min={0}
                  max={99999}
                  placeholder="...ml"
                  parser={value => Number(value).toFixed()}
                  value={investmentSector?.enterprise_value[1]}
                  onBlur={(e) => changeInvestmentRange(e, 1, 'enterprise_value')}
                />
                <span>[in £]</span>
              </div>
            </div>
            <div className="investment-range">
              <div className="investment-range-title">Equity ticket</div>
              <div className="investment-range-data">
                <span>Range (ml)</span>
                <div className="investment-range-label">From</div>
                <InputNumber
                  min={0}
                  max={99999}
                  placeholder="...ml"
                  parser={value => Number(value).toFixed()}
                  value={investmentSector?.equity_investment[0]}
                  onBlur={(e) => changeInvestmentRange(e, 0, 'equity_investment')}
                />

                <div className="investment-range-label">To</div>
                <InputNumber
                  min={0}
                  max={99999}
                  placeholder="...ml"
                  parser={value => Number(value).toFixed()}
                  value={investmentSector?.equity_investment[1]}
                  onBlur={(e) => changeInvestmentRange(e, 1, 'equity_investment')}
                />
                <span>[in £]</span>
              </div>
            </div>
            <div className="investment-range">
              <div className="investment-range-title">Equity stake</div>
              <div className="investment-range-data">
                <span className="percent-span">Range (%)</span>
                <div className="investment-range-label">From</div>
                <InputNumber
                  min={1}
                  max={100}
                  placeholder="%"
                  parser={value => Number(value).toFixed()}
                  value={investmentSector?.equity_stake[0]}
                  onBlur={(e) => changeInvestmentRange(e, 0, 'equity_stake')}
                />

                <div className="investment-range-label">To</div>
                <InputNumber
                  min={1}
                  max={100}
                  placeholder="%"
                  parser={value => Number(value).toFixed()}
                  value={investmentSector?.equity_stake[1]}
                  onBlur={(e) => changeInvestmentRange(e, 1, 'equity_stake')}
                />
              </div>
            </div>
            <div className="investment-range base">
              <div className="investment-range-title">
                <div>Governance of the targeted equity</div>
                <span>(Select all applicable)</span>
              </div>
              <div className="investment-range-check">
                <Checkbox.Group
                  value={investmentSector?.governance}
                  onChange={(list) => changeInvestmentCheck(list)}
                >
                  <Row gutter={[16, 30]} style={{marginBottom: 24}}>
                    {
                      governanceOptions && governanceOptions?.map(option => (
                        <Col span={12} key={option.value}>
                          <Checkbox
                            value={option.value}
                          >
                            {option.label}
                          </Checkbox>
                        </Col>
                      ))
                    }
                  </Row>
                </Checkbox.Group>
                <Row gutter={[16, 30]}>
                  <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      defaultChecked={filterData?.investment_focus?.specified_governace}
                      onChange={(e) => setGovernanceActive(e.target.checked)}
                    >
                      Other
                    </Checkbox>
                  </Col>
                  <Col span={16}>
                    <Input
                      placeholder="Please Specify…"
                      maxLength={100}
                      disabled={!governanceActive}
                      defaultValue={filterData?.investment_focus?.specified_governace}
                      onBlur={changeInvestmentOtherInput}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="investment-range base" style={{borderBottom: 'none'}}>
              <div className="investment-range-title">
                <div>Investment type</div>
                <span>(Select all applicable)</span>
              </div>
              <div className="investment-range-check">
                <Checkbox.Group
                  style={{width: '100%'}}
                  value={investmentSector?.investment_type}
                  onChange={changeInvestmentType}
                >
                  <Row gutter={[16, 30]}>
                    {
                      investmentTypeOptions && investmentTypeOptions?.map(option => (
                        <Col span={8} key={option.value}>
                          <Checkbox value={option.value}>{option.label}</Checkbox>
                        </Col>
                      ))
                    }
                  </Row>
                </Checkbox.Group>

                {
                  (investmentSector && investmentSector.investment_type.includes(3)) &&
                    <div className="investment-range base" style={{marginTop: 16, padding: 0}}>
                      <Row gutter={[16, 0]} style={{width: '100%'}}>
                        <Col span={24}>
                          <Input
                            className={investmentSector && investmentSector.investment_type.includes(3) && !investmentSector.comment ? 'error-input' : ''}
                            placeholder="Please Specify…"
                            maxLength={100}
                            defaultValue={investmentSector.comment}
                            onBlur={e=>changeComment(e.target.value)}
                          />
                        </Col>
                      </Row>
                      </div>
                }
              </div>

            </div>
          </div>
        </Panel>
        <Panel
          header={<CollapseHeader text="Sector Focus" imageType="puzzle" />} key="sector"
          className={`site-collapse-custom-panel${(required.active && !required.sector_focus) ? ' error-block' : ''}`}
        >
          <div>
            <div className="collapse-content-title">{`Please indicate the sectors and related sub-sectors in which ${isFirm ? 'your firm ' : 'you'} can consider investment opportunities`}</div>
            <div className="tabs-content">
              <div className="tabs-header">
                <div style={{width: 300}}>Sectors</div>
                <div style={{width: 'auto'}}>Sub Sectors</div>
                <span onClick={() => onCheckAllChange(true)}>{sectorCheckAll && sectorCheckAll[sectorActiveTab] ? 'Unselect all' : 'Select all'}</span>
              </div>
              <Tabs tabPosition="left" onChange={(key) => setSectorActiveTab(key)} activeKey={sectorActiveTab}>
                {
                  sectorCheckedList && sectorFocus.map(sector => (
                    <TabPane tab={sector.name} key={sector.name}>
                      <div className="vertical-tab-line"></div>
                      <Checkbox.Group
                        value={sectorCheckedList[sector.name]}
                        onChange={(list) => onChange(list, true)}
                      >
                        <SectorGrid
                          data={sectorOptions[sector.name]}
                        />
                      </Checkbox.Group>
                    </TabPane>
                  ))
                }
              </Tabs>
              <div className="divider" style={{ height: 7, margin: '24px 0 16px' }}></div>
              <Row gutter={[16, 24]}>
                <Col span={12} offset={12}>
                  <Checkbox
                    checked={otherSectorList && otherSectorList[sectorActiveTab].active}
                    onChange={e => changeOtherSector(e)}
                  >
                    {otherSectorText}
                  </Checkbox>
                </Col>
              </Row>
              {
                ((sectorCheckedList && sectorCheckedList[sectorActiveTab].length > 0) || (otherSectorList && otherSectorList[sectorActiveTab].active)) &&
                <Row gutter={[16, 24]} style={{marginTop: 16}}>
                  <Col span={12}>
                    <div className="add-spec">Additional sector specification</div>
                  </Col>
                  <Col span={12} className="flex-center-vertical">
                    <Input
                      className={otherSectorList[sectorActiveTab].active && !otherSectorList[sectorActiveTab].text ? 'error-input' : ''}
                      placeholder="Please include any additional specification"
                      maxLength={180}
                      value={otherSectorValue != null ? otherSectorValue : otherSectorList[sectorActiveTab].text}
                      onChange={e => setOtherSectorValue(e.target.value)}
                      onBlur={e => changeSpecValue(e)}
                    />
                  </Col>
                </Row>
              }
              <Row gutter={[16, 24]} style={{marginTop: 16}}>
                <Col span={12} style={{ fontWeight: 500 }}>Are you open to being matched to and consider opportunities in sub-sectors not yet classified?</Col>
                <Col span={12}>
                  <Radio.Group
                    value={addInfoValues && addInfoValues.value}
                    onChange={(e) => changeAddInfoValue(e.target.value)}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </div>
          </div>
        </Panel>
        <Panel
          header={<CollapseHeader text="Geographic Focus" imageType="globe" />} key="geography"
          className={`site-collapse-custom-panel${(required.active && !required.geographic_focus) ? ' error-block' : ''}`}
        >
          <div>
            <div className="collapse-content-title">{`Please indicate the continent/countries in which ${isFirm ? 'your firm' : 'you'} can consider investment opportunities`}</div>
            <div className="tabs-content">
              <div className="tabs-header">
                <div>Area</div>
                <div>Countries</div>
                {
                  continentActiveTab === 'Europe' ?
                    <span onClick={onEuropeanChange}>Select all European Union countries</span>
                  :
                    <span onClick={onCheckAllChange}>{continentCheckAll && continentCheckAll[continentActiveTab] ? 'Unselect all' : 'Select all'}</span>
                }
              </div>
              <Tabs tabPosition="left" onChange={(key) => setContinentActiveTab(key)} activeKey={continentActiveTab}>
                {
                  continentCheckedList && geographyFocus.map(continent => (
                    <TabPane tab={continent.name} key={continent.name}>
                      <div className="vertical-tab-line"></div>
                      <Checkbox.Group
                        value={continentCheckedList[continent.name]}
                        onChange={onChange}
                      >
                        <CountryGrid
                          data={continentOptions[continent.name]}
                        />
                      </Checkbox.Group>
                    </TabPane>
                  ))
                }
              </Tabs>
            </div>
          </div>
        </Panel>
      </Collapse>
      <Space size="large" style={{ marginTop: 160 }}>
        <Button type="primary" className="btn-primary" style={{ width: 217 }} onClick={updateData}>
          Save
          </Button>
        <Button type="primary white" className="btn-primary" style={{ width: 217 }} onClick={backNavigate}>
          Cancel
          </Button>
      </Space>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    equityGovernanceList: state.settings.equityGovernanceList,
    investmentTypes: state.settings.investmentTypes,
    countries: state.settings.countries,
    euCountries: state.settings.euCountries,
    filters: state.settings.filters,
    firmFilters: state.settings.firmFilters,
    saveResult: state.settings.saveResult,
    saveFetching: state.settings.saveFetching,
    user: state.auth.userInfo,
    isAdmin: state.auth.isAdmin
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: (filter) => dispatch(SettingsActions.geographyFocusRequest(filter)),
  getEquityGovernance: () => dispatch(SettingsActions.equityGovernanceRequest()),
  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),
  getCountries: () => dispatch(SettingsActions.countriesRequest()),
  getEuCountries: (filter) => dispatch(SettingsActions.euCountriesRequest(filter)),

  getFilters: (investorId, role) => dispatch(SettingsActions.filtersRequest(investorId, role)),
  updateFilters: (investorId, role, data) => dispatch(SettingsActions.filtersSaveRequest(investorId, role, data)),

  getFirmFilters: (firmId) => dispatch(SettingsActions.firmFiltersRequest(firmId)),
  updateFirmFilters: (firmId, data) => dispatch(SettingsActions.firmFiltersSaveRequest(firmId, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvestorFiltersEditPage)
