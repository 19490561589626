import React, { useState, useEffect } from 'react'
import { Space, Button } from 'antd'
import { useHistory } from 'react-router-dom'

import Images from '../Images'

function InvestorInviteColleaguesSuccessPage(props) {
  const { location } = props;
  const { state } = location;

  const history = useHistory();

  function handleNavigate() {
    if (state) {
      history.push(`/home/firm's-profile/${state?.tab}`);
    } else {
      history.push('/home/deals');
    }
  }

  return (
    <div className="sign-up-step with-bg">
      <div className="with-bg-top">
        <img src={Images.logoWhite} alt="" onClick={()=>history.push('/')} />
      </div>
      <div className="modal-container" style={{ width: 360, padding: '32px 48px' }}>
        <Space style={{ marginBottom: 24 }}>
          <img src={Images.phoneOk} alt="" />
        </Space>
        <div className="login-title">Colleagues invited!</div>
        <div className="sign-up-start-text" style={{ marginBottom: 32 }}>You can now proceed to<br />access your dashboard.</div>
        <Button type="primary" className="btn-primary" style={{ width: 217 }} onClick={handleNavigate}>
          Continue
        </Button>
      </div>
    </div>
  )
}

export default InvestorInviteColleaguesSuccessPage
