import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Input } from 'antd'

import InvestorFilterPanel from '../../Components/FilterPanels/InvestorFilterPanel'
import FirmsTable from '../../Components/Tables/FirmsTable'
import FirmsActions from '../../Redux/FirmsRedux'
import SettingsActions from '../../Redux/SettingsRedux'
import Images from '../../Images'

const { Search } = Input;

function InvestmentFirmsPage(props) {
  const { firms, sectorFocus, geographyFocus, equityGovernanceList } = props;

  const [active, setActive] = useState(false);
  const [count, setCount] = useState(null);

  const history = useHistory();

  function handleNavigate(path) {
    history.push(path);
  }

  useEffect(() => {
    props.getFirms(firms.page, firms.page_size, firms.sort_field, firms.sort_order, firms.search, firms.filter);
    props.getSectors();
    props.getContinents({oecd: true});
    props.getEquityGovernance();
  }, [0]);


  function handleSearch(val) {
    if (firms.search === val) {
      return;
    }
    props.getFirms(1, firms.page_size, firms.sort_field, firms.sort_order, val, firms.filter);
  }

  function changeCount(value, data, start) {
    setCount(value);
    !start && props.getFirms(1, firms.page_size, firms.sort_field, firms.sort_order, firms.search, data);
  }

  function handleChangePage(page, page_size, sort_field, sort_order){
    props.getFirms(page, page_size, sort_field, sort_order, firms.search, firms.filter);
  }

  function closeFilter() {
    setActive(!active);
  }

  return (
    <div className="main-container admin-container">
      <div className="action-panel">
        <Search
          placeholder="Search"
          allowClear
          className="content-search"
          suffix={<img src={Images.search} alt="" />}
          defaultValue={firms.search}
          onSearch={handleSearch}
        />
        <Button type={`primary${count ? ' orange' : ' white'}`} className="btn-primary" onClick={closeFilter}>
          {'Filter' + (count ? ` (${count})` : '')}
        </Button>
        <Button 
          type="primary" className="btn-primary" style={{marginLeft: 'auto'}} onClick={() => handleNavigate('/home/investment-firms/add-company')}>
          Add company
        </Button>
        <InvestorFilterPanel
          filterData={firms.filter}
          active={active}
          sectors={sectorFocus}
          continents={geographyFocus}
          governance={equityGovernanceList}
          changeCount={changeCount}
          closeFilter={closeFilter}
        />
      </div>
      <div className="table-container">
        <FirmsTable
          data={firms.list}
          page={firms.page}
          fetching={firms.fetching}
          pageSize={firms.page_size}
          onChangePage={handleChangePage}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    firms: state.firms,
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    equityGovernanceList: state.settings.equityGovernanceList,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getFirms: (page, page_size, sort_field, sort_order, search, filter) => dispatch(FirmsActions.firmsRequest(page, page_size, sort_field, sort_order, search, filter)),

  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: (filter) => dispatch(SettingsActions.geographyFocusRequest(filter)),
  getEquityGovernance: () => dispatch(SettingsActions.equityGovernanceRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentFirmsPage)
