import { call, put, take, select, delay } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import MandatesActions, {MandatesTypes, MandatesSelectors} from '../Redux/MandatesRedux'
import AuthActions, {AuthSelectors} from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'
import NotificationsActions from '../Redux/NotificationsRedux'

export function * getMandates(api, authApi, { page, page_size, sort_field, sort_order, search, filter }) {
  try {
    const response = yield call(api.getMandates, page, page_size, sort_field, sort_order, search, filter)
    if (response && response.ok) {
      yield put(MandatesActions.mandatesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getMandates(api, authApi, { page, page_size, sort_field, sort_order, search, filter })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getDealsSubmitted(api, authApi, { page, page_size, mandateId }) {
  try {
    const response = yield call(api.getDealsSubmitted, page, page_size, mandateId)
    if (response && response.ok) {
      yield put(MandatesActions.dealsSubmittedSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getDealsSubmitted(api, authApi, { page, page_size, mandateId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getMandate(api, authApi, { mandateId }) {
  try {
    const response = yield call(api.getMandate, mandateId)
    if (response && response.ok) {
      yield put(MandatesActions.mandateSuccess(response.data))
      yield put(MandatesActions.setMandateId(mandateId))
      yield put(MandatesActions.mandateReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getMandate(api, authApi, { mandateId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * createMandate(api, authApi, { data }) {
  try {
    const response = yield call(api.createMandate, data)
    if (response && response.ok) {
      yield put(MandatesActions.mandateSaveSuccess(response.data))
      yield put(MandatesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * createMandate(api, authApi, { data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * deleteMandateNotification(api, authApi, { mandateId, data }) {
  try {
    const response = yield call(api.deleteMandateNotification, mandateId, data)
    if (response && response.ok) {
      yield put(MandatesActions.mandateNotificationDeleteSuccess(response.data))
      yield put(MandatesActions.resultsReset())
      const role = yield select(AuthSelectors.getRole);
      yield put(NotificationsActions.notificationsRequest())
      if (role === 0) {
        yield put(NotificationsActions.advisorDealGroupNotificationsRequest())
      } else if (role === 2) {
        yield put(NotificationsActions.investorDealGroupNotificationsRequest())
      }
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * deleteMandateNotification(api, authApi, { mandateId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getDealSize(api, authApi) {
  try {
    const response = yield call(api.getDealSize)
    if (response && response.ok) {
      yield put(MandatesActions.dealSizeSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getDealSize(api, authApi)
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * saveMandateGroupData(api, authApi, { mandateId, data }) {
  try {
    const response = yield call(api.saveMandateGroupData, mandateId, data)
    if (response && response.ok) {
      yield put(MandatesActions.mandateGroupDataSaveSuccess(response.data))
      yield put(MandatesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * saveMandateGroupData(api, authApi, { mandateId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * sharedMandateByInvestor(api, authApi, {mandateId}) {
  try {
    const response = yield call(api.sharedMandateByInvestor, mandateId)
    if (response && response.ok) {
      yield put(MandatesActions.investorSharedMandateSuccess(response.data))
      yield put(MandatesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * sharedMandateByInvestor(api, authApi, {mandateId})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * pauseMandate(api, authApi, {mandateId}) {
  try {
    const response = yield call(api.pauseMandate, mandateId)
    if (response && response.ok) {
      yield put(MandatesActions.pauseMandateSuccess(response.data))
      yield put(MandatesActions.resultsReset())
      yield put(MandatesActions.mandateRequest(mandateId))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * pauseMandate(api, authApi, {mandateId})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * unpauseMandate(api, authApi, {mandateId}) {
  try {
    const response = yield call(api.unpauseMandate, mandateId)
    if (response && response.ok) {
      yield put(MandatesActions.unpauseMandateSuccess(response.data))
      yield put(MandatesActions.resultsReset())
      yield put(MandatesActions.mandateRequest(mandateId))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * unpauseMandate(api, authApi, {mandateId})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * closeMandate(api, authApi, {mandateId}) {
  try {
    const response = yield call(api.closeMandate, mandateId)
    if (response && response.ok) {
      yield put(MandatesActions.closeMandateSuccess(response.data))
      yield put(MandatesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * closeMandate(api, authApi, {mandateId})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * answerMandateQuestion(api, authApi, {mandateId, data}) {
  try {
    const response = yield call(api.answerMandateQuestion, mandateId, data)
    if (response && response.ok) {
      yield put(MandatesActions.mandateAnswerQuestionSuccess(response.data))
      yield put(MandatesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * answerMandateQuestion(api, authApi, {mandateId, data})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * inviteColleaguesToMandate(api, authApi, {mandateId, data}) {
  try {
    const response = yield call(api.inviteColleaguesToMandate, mandateId, data)
    if (response && response.ok) {
      yield put(MandatesActions.mandateInviteColleaguesSuccess(response.data))
      yield put(MandatesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * inviteColleaguesToMandate(api, authApi, {mandateId, data})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getMandateStatuses(api, authApi) {
  try {
    const response = yield call(api.getMandateStatuses)
    if (response && response.ok) {
      yield put(MandatesActions.mandateStatusesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getMandateStatuses(api, authApi)
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getAdvisorShareStatuses(api, authApi) {
  try {
    const response = yield call(api.getAdvisorShareStatuses)
    if (response && response.ok) {
      yield put(MandatesActions.mandateAdvisorShareStatusesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getAdvisorShareStatuses(api, authApi)
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getMandateAdvisorsShared(api, authApi, {data}) {
  try {
    const response = yield call(api.getMandateAdvisorsShared, data)
    if (response && response.ok) {
      yield put(MandatesActions.mandateAdvisorsSharedSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getMandateAdvisorsShared(api, authApi, {data})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * shareMandateWithAdvisors(api, authApi, {mandateId, data}) {
  try {
    const response = yield call(api.shareMandateWithAdvisors, mandateId, data)
    if (response && response.ok) {
      yield put(MandatesActions.mandateShareWithAdvisorsSuccess(response.data))
      yield put(MandatesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * shareMandateWithAdvisors(api, authApi, {mandateId, data})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getMandateDeals(api, authApi, {mandateId, page, page_size, filter}) {
  try {
    const response = yield call(api.getMandateDeals, mandateId, page, page_size, filter)
    if (response && response.ok) {
      yield put(MandatesActions.mandateDealsMatchesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getMandateDeals(api, authApi, {mandateId, page, page_size, filter})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getMandateStats(api, authApi, {mandateId}) {
  try {
    const response = yield call(api.getMandateStats, mandateId)
    if (response && response.ok) {
      yield put(MandatesActions.mandateStatsSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getMandateStats(api, authApi, {mandateId})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * mandateAskQuestionRequest(api, authApi, { mandateId, data }) {
  try {
    const response = yield call(api.mandateAskQuestionRequest, mandateId, data)
    if (response && response.ok) {
      yield put(MandatesActions.mandateAskQuestionSuccess(response.data))
      yield put(MandatesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * mandateAskQuestionRequest(api, authApi, { mandateId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * addMandateByAdvisor(api, authApi, { mandateId }) {
  try {
    const response = yield call(api.addMandateByAdvisor, mandateId)
    if (response && response.ok) {
      yield put(MandatesActions.mandateAddByAdvisorSuccess(response.data))
      yield put(MandatesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * addMandateByAdvisor(api, authApi, { mandateId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * changeNotificationMandateStatus(api, authApi, { notificationId }) {
  try {
    const response = yield call(api.changeNotificationMandateStatus, notificationId)
    if (response && response.ok) {
      yield put(MandatesActions.notificationMandateStatusSuccess(response.data))
      yield put(MandatesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * changeNotificationMandateStatus(api, authApi, { notificationId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getFirmMandates(api, authApi, { page, page_size }) {
  try {
    const response = yield call(api.getFirmMandates, page, page_size)
    if (response && response.ok) {
      yield put(MandatesActions.firmMandatesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getFirmMandates(api, authApi, { page, page_size })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getFirmMandate(api, authApi, { mandateId }) {
  try {
    const response = yield call(api.getFirmMandate, mandateId)
    if (response && response.ok) {
      yield put(MandatesActions.firmMandateSuccess(response.data))
      yield put(MandatesActions.setMandateId(mandateId))
      yield put(MandatesActions.firmMandateReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getFirmMandate(api, authApi, { mandateId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getFirmActivitiesMandateStats(api, authApi) {
  try {
    const response = yield call(api.getFirmActivitiesMandateStats)
    if (response && response.ok) {
      yield put(MandatesActions.firmActivitiesMandateStatsSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getFirmActivitiesMandateStats(api, authApi)
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getFirmMandateStats(api, authApi, { mandateId }) {
  try {
    const response = yield call(api.getFirmMandateStats, mandateId)
    if (response && response.ok) {
      yield put(MandatesActions.firmMandateStatsSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getFirmMandateStats(api, authApi, { mandateId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getFirmMandateDeals(api, authApi, {mandateId, page, page_size}) {
  try {
    const response = yield call(api.getFirmMandateDeals, mandateId, page, page_size)
    if (response && response.ok) {
      yield put(MandatesActions.firmMandateDealsMatchesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getFirmMandateDeals(api, authApi, {mandateId, page, page_size})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * editMandateQuestion(api, authApi, { mandateId, data }) {
  try {
    const response = yield call(api.editMandateQuestion, mandateId, data)
    if (response && response.ok) {
      yield put(MandatesActions.mandateQuestionEditSuccess(response.data))
      yield put(MandatesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * editMandateQuestion(api, authApi, { mandateId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * deleteMandateQuestion(api, authApi, { mandateId, data }) {
  try {
    const response = yield call(api.deleteMandateQuestion, mandateId, data)
    if (response && response.ok) {
      yield put(MandatesActions.mandateQuestionDeleteSuccess(response.data))
      yield put(MandatesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * deleteMandateQuestion(api, authApi, { mandateId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * publishMandateQuestion(api, authApi, { mandateId, questionId }) {
  try {
    const response = yield call(api.publishMandateQuestion, mandateId, questionId)
    if (response && response.ok) {
      yield put(MandatesActions.mandateQuestionPublishSuccess(response.data))
      yield put(MandatesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandatesActions.mandatesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * publishMandateQuestion(api, authApi, { mandateId, questionId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandatesActions.mandatesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * mandatesSyncStartMonitor() {
  while (true) {
    const payload = yield take(MandatesTypes.MANDATES_SYNC_TOGGLE);
    let doWork = payload.syncIsStart;
    while (doWork) {
      const {page, page_size, sort_field, sort_order, search, filter, syncIsStart} = yield select(MandatesSelectors.getState);
      if (!syncIsStart) {
        break;
      }
      yield put(MandatesActions.mandatesRequest(page, page_size, sort_field, sort_order, search, filter));
      doWork = syncIsStart;
      yield delay(30000);
    }
  }
}
