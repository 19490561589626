import React, { useState, useEffect } from 'react'
import { Form, Input, Space, Checkbox } from 'antd'
import Utils from '../../Utils/utils'

import Images from '../../Images'

function InviteColleaguesToDealDashboardForm(props) {
  const [form] = Form.useForm();

  const [checkLength, setCheckLength] = useState(null);

  useEffect(() => {
    if (checkLength === 1) {
      props.changeView();
    }
  }, [checkLength]);

  const onFinish = values => {
    const data = { ...values };
    data.users.forEach(user => {
      user.is_comain = !!user.is_comain;
    })
    props.onSubmit(data.users);
  }

  return (
    <Form id="share-deal-with-colleagues-form" form={form} onFinish={onFinish} className="login-form share" layout="vertical">
      <Form.List name="users">
        {(fields, { add, remove }) => {
          if (!fields.length) {
            setTimeout(() => {
              add();
            })
          }
          return (
            <>
              {fields.map((field, i) => (
                <div key={field.key} className="form-wrapper" style={{padding: '16px 24px 0'}}>
                  <svg width="16" height="16" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {setCheckLength(fields.length); remove(field.name)}}>
                    <path d="M1.66663 1.66667L11 11M11 11L20.3333 20.3333M11 11L20.3333 1.66667M11 11L1.66663 20.3333" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <Form.Item>
                    <Space className="invite-form-title flex-center-horizontal">{`Colleague ${i + 1}`}</Space>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, 'name']}
                    fieldKey={[field.fieldKey, 'name']}
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <Input
                      placeholder="Name"
                      maxLength={100}
                      prefix={<img src={Images.user} alt="" />}
                      suffix={'*'}
                    />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, 'surname']}
                    fieldKey={[field.fieldKey, 'surname']}
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <Input
                      placeholder="Surname"
                      maxLength={100}
                      prefix={<img src={Images.user} alt="" />}
                      suffix={'*'}
                    />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, 'email']}
                    fieldKey={[field.fieldKey, 'email']}
                    rules={[
                      { required: true, message: 'This field is required' },
                      { type: 'email', message: 'Invalid email format' },
                      { min: 5, message: 'Field must contain at least 5 characters' },
                    ]}
                    onKeyPress={e=>{if(e.charCode === 32) e.preventDefault()}}
                    onBlur={e=>Utils.clearSpaces(e, 'email', form.setFieldsValue)}
                  >
                    <Input
                      placeholder="Work Email"
                      maxLength={100}
                      prefix={<img src={Images.envelope} alt="" />}
                      suffix={'*'}
                    />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, 'is_comain']}
                    fieldKey={[field.fieldKey, 'is_comain']}
                    valuePropName="checked"
                    style={{ textAlign: 'center' }}
                  >
                    <Checkbox style={{fontSize: 11}}>Invite as Co-Main point of contact</Checkbox>
                  </Form.Item>

                </div>
              ))}
              <Form.Item>
                <div className="btn-footer invite" style={{marginTop: 0}}>
                  <span className="link-tag underline" onClick={() => fields.length < 10 && add()}>Add Another One</span>
                </div>
              </Form.Item>
            </>
          )
        }}
      </Form.List>
    </Form>
  )
}

export default InviteColleaguesToDealDashboardForm
