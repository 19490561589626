import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Input } from 'antd'

import AdvisorFilterPanel from '../../Components/FilterPanels/AdvisorFilterPanel'
import AdvisorsTable from '../../Components/Tables/AdvisorsTable'
import UsersActions from '../../Redux/UsersRedux'
import SettingsActions from '../../Redux/SettingsRedux'
import Images from '../../Images'

const { Search } = Input;

function AdvisorsPage(props) {
  const { users, sectorFocus, geographyFocus, countries } = props;

  const [active, setActive] = useState(false);
  const [count, setCount] = useState(null);

  const history = useHistory();

  function handleNavigate(path) {
    history.push(path);
  }

  useEffect(() => {
    props.getUsers('advisor', users.page_adv, users.page_size_adv, users.sort_field_adv, users.sort_order_adv, users.search_adv, users.filter_adv);
    props.getSectors();
    props.getContinents();
    //props.getCountries();
    props.getChoices('advisor');
  }, [0]);


  function handleSearch(val) {
    if (users.search === val) {
      return;
    }
    props.getUsers('advisor', 1, users.page_size_adv, users.sort_field_adv, users.sort_order_adv, val, users.filter_adv);
  }

  function changeCount(value, data, start) {
    setCount(value);
    !start && props.getUsers('advisor', 1, users.page_size_adv, users.sort_field_adv, users.sort_order_adv, users.search_adv, data);
  }

  function handleChangePage(page, page_size, sort_field, sort_order){
    props.getUsers('advisor', page, page_size, sort_field, sort_order, users.search_adv,  users.filter_adv);
  }

  function checkUserStatus(data) {
    if (data?.register_status === 'new') {
      props.updateFilters(data?.id, 'advisor', { register_status: 'viewed' });
    }
  }

  function closeFilter() {
    setActive(!active);
  }

  return (
    <div className="main-container admin-container">
      <div className="action-panel">
        <Search
          placeholder="Search"
          allowClear
          className="content-search"
          suffix={<img src={Images.search} alt="" />}
          defaultValue={users.search_adv}
          onSearch={handleSearch}
        />
        <Button type={`primary${count ? ' orange' : ' white'}`} className="btn-primary" onClick={closeFilter}>
          {'Filter' + (count ? ` (${count})` : '')}
        </Button>
        <Button type="primary" className="btn-primary" style={{ marginLeft: 'auto'}} onClick={() => handleNavigate('/home/advisors/add-advisor')}>
          Add Advisor
        </Button>
        <AdvisorFilterPanel
          filterData={users.filter_adv}
          active={active}
          sectors={sectorFocus}
          continents={geographyFocus}
          //countries={countries}
          choices={users.choices}
          changeCount={changeCount}
          closeFilter={closeFilter}
        />
      </div>
      <div className="table-container">
        <AdvisorsTable
          data={users.list}
          page={users.page_adv}
          fetching={users.fetching}
          pageSize={users.page_size_adv}
          onChangePage={handleChangePage}
          checkUserStatus={checkUserStatus}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    //countries: state.settings.countries,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUsers: (role, page, page_size, sort_field, sort_order, search, filter) => dispatch(UsersActions.usersRequest(role, page, page_size, sort_field, sort_order, search, filter)),
  getChoices: (role) => dispatch(UsersActions.userChoicesRequest(role)),

  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: () => dispatch(SettingsActions.geographyFocusRequest()),
  //getCountries: () => dispatch(SettingsActions.countriesRequest()),

  updateFilters: (advisorId, role, data) => dispatch(SettingsActions.filtersSaveRequest(advisorId, role, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorsPage)
