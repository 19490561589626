import React, { useState } from 'react'
import { Layout, Spin, Button, Space, Checkbox } from 'antd'
import { useHistory } from 'react-router-dom'

import Images from '../../Images'

function InvestorSignUpLeadConfirm(props) {
  const { fetching, firm } = props;

  const history = useHistory();

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <div className="sign-up-step with-bg">
          <div className="with-bg-top">
            <img src={Images.logoWhite} alt="" onClick={()=>history.push('/')} />
          </div>
          <div className="modal-container" style={{ width: 460, padding: '24px 24px 32px' }}>
            <Space style={{marginBottom: 24}}>
              <img src={Images.phoneOk} alt="" />
            </Space>
            <div className="step-title intro sign-up-start">Lead Investor</div>
            <div className="sign-up-start-text" style={{margin: '0 32px'}}>You have been appointed as your firm’s<br />“Lead Investor” on IDN.<br /><br /><span>The Lead Investor is primarily responsible for creating his/her investment firm’s profile,  and agreeing  to IDN's Terms and Conditions on behalf of his/her investment firm.</span></div>
            <div className="sign-up-start-divider" style={{margin: '24px 32px 0'}}></div>
            <Space className="lead-investor-bottom" style={{margin: '24px 0'}}>{`I hereby confirm that I am an appointed Lead Investor by ${firm.name}`}
            </Space>
            <Button type="primary white" htmlType="submit" className="btn-primary" style={{width: 217}} onClick={props.onChangeStep}>
              Confirm
            </Button>
          </div>
        </div>
      </Spin>
    </Layout>
  )
}

export default InvestorSignUpLeadConfirm
