import React, { useState, useEffect } from 'react'
import {Form, Input, Button, Row, Col, Space, Checkbox, message} from 'antd'
import Utils from '../../Utils/utils'

import Images from '../../Images'


function InvestorSignUpCreateAccountForm(props) {
  const {preview, subRole} = props;
  const [active, setActive] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (preview) {
      form.setFieldsValue({
        name: preview.name,
        surname: preview.surname,
        email: preview.email,
        company_name: preview.company_name,
      })
    }
  }, [preview])

  const onFinish = values => {
    if (!active) {
      message.error("You should agree to IDN's Terms of Use & Privacy Policy", 3);
    } else {
      const data = {...values};
      delete data.confirm;
      props.onSubmit(data);
    }
  }

  return (
    <Form form={form} onFinish={onFinish} className="login-form" layout="vertical">

      <Form.Item
        name="company_name"
        validateTrigger="onBlur"
        rules={[{required: true, message: 'This field is required'}]}
      >
        <Input
          placeholder="Investment Firm"
          maxLength={100}
          prefix={<img src={Images.briefcaseSm} alt="" />}
          suffix={'*'}
          disabled={subRole !== 0}
        />
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="name"
            validateTrigger="onBlur"
            rules={[{required: true, message: 'This field is required'}]}
          >
            <Input
              placeholder="Name"
              maxLength={100}
              prefix={<img src={Images.user} alt="" />}
              suffix={'*'}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="surname"
            validateTrigger="onBlur"
            rules={[{required: true, message: 'This field is required'}]}
          >
            <Input
              placeholder="Surname"
              maxLength={100}
              prefix={<img src={Images.user} alt="" />}
              suffix={'*'}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="job_title"
        validateTrigger="onBlur"
        rules={[{required: true, message: 'This field is required'}]}
      >
        <Input
          placeholder="Job Title"
          maxLength={100}
          prefix={<img src={Images.crosshairs} alt="" />}
          suffix={'*'}
        />
      </Form.Item>

      <Form.Item
        name="linkedin_url"
        validateTrigger="onBlur"
        rules={[
          {required: true, message: 'This field is required'},
          {pattern: /linkedin.com/i, message: 'Field must contain "linkedin.com"'},
          { min: 12, message: 'Field must contain at least 12 characters' },
        ]}
      >
        <Input
          placeholder="URL Linkedin Profile"
          maxLength={100}
          prefix={<img src={Images.linkedin} alt="" />}
          suffix={'*'}
        />
      </Form.Item>

      <Form.Item
        name="phone"
        validateTrigger="onBlur"
        rules={[
          {required: true, message: 'This field is required'},
          {pattern: /^\+?[0-9]{7,14}$/, message: 'Invalid format'},
          { min: 7, message: 'Field must contain at least 7 characters' },
        ]}
      >
        <Input
          placeholder="+447911123456"
          type="tel"
          maxLength={20}
          prefix={<img src={Images.mobile} alt="" />}
          suffix={'*'}
        />
      </Form.Item>

      <Form.Item
        name="email"
        validateTrigger="onBlur"
        rules={[
          {required: true, message: 'This field is required'},
          { type: 'email', message: 'Invalid email format' },
          { min: 5, message: 'Field must contain at least 5 characters' },
        ]}
        onKeyPress={e=>{if(e.charCode === 32) e.preventDefault()}}
        onBlur={e=>Utils.clearSpaces(e, 'email', form.setFieldsValue)}
      >
        <Input
          placeholder="Email (same as username)"
          maxLength={100}
          prefix={<img src={Images.envelope} alt="" />}
          suffix={'*'}
        />
      </Form.Item>

      <Form.Item
        name="password"
        validateTrigger="onBlur"
        rules={[
          {required: true, message: "This field is required"},
          {pattern: /^[a-zA-Z0-9!@#$]+$/, message: 'Invalid character'},
          { min: 8, message: 'Field must contain at least 8 characters' },
        ]}
      >
        <Input.Password
          placeholder="Password"
          maxLength={100}
          autoComplete="new-password"
          prefix={<img src={Images.lock} alt="" />}
          suffix={'*'}
        />
      </Form.Item>

      <Form.Item
        dependencies={['password']}
        name="confirm"
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: "This field is required",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('The passwords don’t match');
            },
          }),
        ]}
      >
        <Input.Password
          placeholder="Re-type password"
          maxLength={100}
          autoComplete="new-password"
          prefix={<img src={Images.lock} alt="" />}
          suffix={'*'}
        />
      </Form.Item>

      <Space className="terms-conditions-bottom links">
        <Checkbox onChange={(e)=>setActive(e.target.checked)}>I confirm I have read and agree to IDN's<br /><a href="/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a> & <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></Checkbox>
      </Space>

      <Button type="primary" htmlType="submit" className="btn-primary" style={{width: 217, marginTop: 24}}>
        Next
      </Button>

    </Form>
  )
}

export default InvestorSignUpCreateAccountForm
