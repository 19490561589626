import React, { useState, useEffect } from 'react'
import { Button, Tabs, Spin, Input, message } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
// import mixpanel from 'mixpanel-browser'

import SettingsActions from '../../Redux/SettingsRedux'
import MandatesActions from '../../Redux/MandatesRedux'
import MandateSharesActions from '../../Redux/MandateSharesRedux'

import MandateSharingReview from '../../Components/MandateSharing/MandateSharingReview'
import Images from '../../Images'

const { TabPane } = Tabs;
const { TextArea } = Input;

function MandateShareInfoAllPage(props) {
  const { user, mandate, investmentTypes, dealSizeList, match, location, mandateAddByAdvisorResult, mandateSharesDeleteResult, styleObj, mandateAskQuestionResult, } = props;

  const { mandateId } = match.params;
  const { state } = location;

  const [mandateData, setMandateData] = useState(null);
  const [backPath, setBackPath] = useState('');

  const history = useHistory();

  useEffect(() => {
    props.getInvestmentTypes();
    props.getDealSize();
    props.getMandate(mandateId);
    if (state?.tab) {
      setBackPath(`/home/mandates/${state.tab}`);
    } else {
      setBackPath('/home/mandates');
    }
  }, [0]);

  useEffect(() => {
    if (mandate) {
      setMandateData(mandate);
    }
  }, [mandate]);

  useEffect(() => {
    if (mandateAddByAdvisorResult && mandateAddByAdvisorResult.success) {
      message.success(mandateAddByAdvisorResult.success, 3);
      history.push('/home/mandates');
      // mixpanel.track('advisor adds mandate to Your Mandates tab', {
      //   mandate_id: mandateId,
      //   role: 'advisor'
      // })
    }
    if (mandateSharesDeleteResult && mandateSharesDeleteResult.success) {
      message.success(mandateSharesDeleteResult.success, 3);
    }
    if (mandateAskQuestionResult && mandateAskQuestionResult.success) {
      message.success(mandateAskQuestionResult.success, 3);
    }
  }, [mandateAddByAdvisorResult, mandateSharesDeleteResult, mandateAskQuestionResult])

  const onAddMandate = key => {
    props.addMandateByAdvisor(mandateId);
  }

  const onAskQuestion = () => {
    history.push({ pathname: `/home/mandate-share-info-all/${mandateId}/ask-question` });
  }

  const onSubmit = () => {
    if (user?.signed_platform_agreement) {
      history.push({
        pathname: `/home/mandate-deal-sharing/step/start`,
        state: { mandateId }
      });
      // mixpanel.track('advisor starts deal sharing process', {
      //   mandate_id: mandateId,
      //   role: 'advisor'
      // })
    } else {
      history.push({
        pathname: `/home/mandate-share-info-all/${mandateId}/platform-agreement-info`,
        state: { isMandate: true, allMandate: true }
      })
    }
  }

  return (
    <div className="sign-up-step filters-edit wo-padding" style={{ paddingBottom: 0 }}>
      <div className="absolute-header">
        <div className="flex-center-vertical">
          <div className="logo-block">
            <img src={Images.logoWhite} alt="" />
          </div>
          <div className="title-block bottom">{mandateId}</div>
        </div>
      </div>
      <div className="return-block">
        <Link to={backPath}>
          <img src={Images.arrowLeft} alt="" />
          <span>Return to Mandates</span>
        </Link>
      </div>

      <Tabs>
        <TabPane tab="Mandate Summary" key="summary">
          <div className="close-document">
            {match.url.includes('all') && <div className="close-document link add" onClick={onAddMandate}>+ Add to your Mandate dashboard</div>}
          </div>
          <MandateSharingReview
            mandateData={mandateData}
            investmentTypes={investmentTypes}
            dealSizeList={dealSizeList}
          />

          {mandateData?.mandate_questions?.length > 0 &&
            <div className="review-block" style={styleObj}>
              <div className="overview-title mandate" style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                <div style={{ fontSize: 22, alignSelf: 'flex-start' }}>Additional information - Q&A</div>
                <div className="overview-title mandate subtitle" style={{ margin: '15px 0' }}>Below are listed all queries asked by Senior Advisors to the investor in relation to the mandate, as well as the investor's answers to those</div>
              </div>
              {mandateData?.mandate_questions?.map(el =>
                <>
                  <div className="overview-title mandate smaller">
                    <div className="overview-title mandate smaller mandate-question">Question:</div>
                    <div style={{ fontWeight: 600, wordBreak: 'break-word' }}>{el.question}</div>
                  </div>
                  <div className="overview-title mandate smaller" >
                    <div className="overview-title mandate smaller" style={{ padding: '25px 0', marginBottom: 'auto' }}>Answer:</div>
                    <div className="area-text addinfo">
                      <TextArea
                        value={el.answer}
                        disabled={true}
                        autoSize={true}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          }

          <div className="review-block deal-share-info">
            <div>
              <Button type="primary white"
                className="btn-primary"
                onClick={onAskQuestion}
              >
                Ask a question
              </Button>
              <span>[Get clarifications from the investor]</span>
            </div>
            <div>
              <Button type="primary orange-margin"
                className="btn-primary"
              >
                <a
                  className="share-now-link"
                  href='https://www.google.com/url?q=https://docs.google.com/forms/d/1Vy-38r8uO8k_hsVz-dqwl4NQuqC9jCzkFnupsJOhlw8/edit&sa=D&source=docs&ust=1644395572666159&usg=AOvVaw1N__yr0RvDFsqiqjliCp2f'
                  target="_blank" rel="noopener noreferrer">
                  Referral
                </a>
              </Button>
              <span>[Refer a Senior Advisor to get a fee]</span>
            </div>
            <div>
              <Button
                type="primary"
                className="btn-primary"
                onClick={onSubmit}
              >
                Submit a deal
              </Button>
              <span>[Submit a deal in response to this Mandate]</span>
            </div>
          </div>

        </TabPane>
      </Tabs>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    role: state.auth.role,
    user: state.auth.userInfo,
    mandate: state.mandates.mandate,
    fetching: state.deals.fetching,
    mandateShares: state.mandateShares,
    mandateAddByAdvisorResult: state.mandates.mandateAddByAdvisorResult,
    mandateSharesDeleteResult: state.mandateShares.mandateSharesDeleteResult,
    mandateAskQuestionResult: state.mandates.mandateAskQuestionResult,
    investmentTypes: state.settings.investmentTypes,
    dealSizeList: state.mandates.dealSizeList,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getMandate: (mandateId) => dispatch(MandatesActions.mandateRequest(mandateId)),
  getDealSize: () => dispatch(MandatesActions.dealSizeRequest()),
  addMandateByAdvisor: (mandateId) => dispatch(MandatesActions.mandateAddByAdvisorRequest(mandateId)),

  getMandateMatchInfo: (mandateShareId) => dispatch(MandateSharesActions.mandateShareMatchRequest(mandateShareId)),

  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MandateShareInfoAllPage)
