import React from 'react'
import { Layout, Spin, Progress, Button } from 'antd'

import AdvisorPreviewForm2 from '../Forms/AdvisorPreviewForm2'

function AdvisorPreviewStep2(props) {
  const { fetching } = props;

  function handleSubmit(data) {
    props.onChangeStep(data);
  }

  return (
    <Layout>
      <div className="sign-up-step intro-bg">
        <div className="modal-container" style={{ width: 450, padding: '40px 24px' }}>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.onClose}>
            <path d="M1.66663 1.66667L11 11M11 11L20.3333 20.3333M11 11L20.3333 1.66667M11 11L1.66663 20.3333" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <Spin size="large" spinning={fetching}>
            <div className="app-form-title">
              <div>Application form</div>
              <Progress
                percent={100}
                showInfo={false}
                strokeWidth={7}
                trailColor="rgba(31, 73, 228, 0.12)"
                strokeColor="#3680F6"
                status="active"
              />
            </div>
            <div className="app-form-text">Tell us more about your experience.<br />Senior Advisors are rigorously selected to join IDN</div>
            <div className="app-form-quiz">
              <AdvisorPreviewForm2
                onSubmit={handleSubmit}
              />
            </div>
            <Button type="primary" htmlType="submit" form="advisor-preview-form" className="btn-primary" style={{ width: 217, marginTop: 32 }}>
              Submit
            </Button>
          </Spin>
        </div>
      </div>
    </Layout>
  )
}

export default AdvisorPreviewStep2
