import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Table, Button } from 'antd'
import moment from 'moment'

import PaginationItemRender from '../PaginationItemRender'
import CustomIcon from '../../Icon'
import Images from '../../Images'

import './Table.less'

function MandateMatchesTable(props){
  const {
    page,
    pageSize,
    data,
    mandateId,
    fetching,
    hideAll,
    isOwnMandate,
    fromActivities
  } = props;

  const [dataSource, setDataSource] = useState(null);

  const history = useHistory();

  useEffect(() => {
    let arr = (data && data.results) ? data.results.asMutable({deep:true}) : [];
    arr = arr.map(el => ({
      id: el.id,
      status: el.status_obj?.name || el.admin_status_obj?.name,
      received: el.shared,
      subsector: el.subsector,
      geography: el.geography,
      eq_ticket: el.eq_ticket,
      status_id: el.status_obj?.id || el.admin_status_obj?.id,
      dashboardId: el.dashboard,
      shareId: el.share_id,
      isNotification: el.investor_notification,
    }))
    setDataSource(arr);
  }, [data]);

  function getColumns() {
    return [
      {
        title: 'Deal ID',
        dataIndex: 'id',
        key: 'id',
        className: 'cell-center',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <div className={`deal-status${record.isNotification ? ' orange' : ''}`}>{text}</div>
          )
        },
      },
      {
        title: 'Received on',
        dataIndex: 'received',
        key: 'received',
        className: 'cell-center',
        render: (text, record) => {
          return (
              <span>{text && moment(text).format('DD/MM/YYYY')}</span>
          )
        },
      },
      {
        title: 'Sector',
        dataIndex: 'subsector',
        key: 'subsector',
        render: (text, record) => {
          return (
            text?.map((el, i) => (
              <div key={i}>{el}</div>
            ))
          )
        },
      },
      {
        title: 'Geography',
        dataIndex: 'geography',
        key: 'geography',
        render: (text, record) => {
          return (
            text?.map((el, i) => (
              <div key={i}>{el}</div>
            ))
          )
        },
      },
      {
        title: 'Equity ticket',
        dataIndex: 'eq_ticket',
        key: 'eq_ticket',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <span style={{whiteSpace: 'nowrap'}}>{text ? `${text}ml` : 'N/A'}</span>
          )
        },
      },
      {
        title: '',
        dataIndex: 'status_id',
        key: 'status_id',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <div className="deal-action">
              {
                record.isNotification &&
                  <img src={Images.notification} alt="" />
              }
              <Button type="primary" className="btn-primary" onClick={()=>generateBtnAction(record)}>
                View
              </Button>
            </div>
          )
        },
      }
    ];
  }

  function handleTableChange(pagination) {
    props.onChangePage(pagination.current, pagination.pageSize)
  }

  const totalCount = data ? data.count : 0;

  const generateBtnAction = record => {
    if (record?.status_id) {
      switch(record.status_id) {
        case 'closed':
        case 'won':
        case 'engaged': {
          if (record.isNotification) {
            props.deleteNotification(record.shareId, record.id);
          } else {
            if (fromActivities && isOwnMandate) {
              history.push({
                pathname: `/deal-dashboard/overview/${record.dashboardId}`,
                state: {
                  backPath: `/home/mandate/${mandateId}/matches`,
                  backState: {
                    hideAll: true,
                    isOwnMandate: true,
                    fromActivities: true
                  }
                }
              });
            } else if (isOwnMandate != null) {
              history.push({
                pathname: `/home/deal-for-mandate/${record.id}${record.shareId ? '/' + record.shareId : ''}`,
                state: {
                  hideAll,
                  isOwnMandate
                }
              })
            } else {
              history.push({
                pathname: `/deal-dashboard/overview/${record.dashboardId}`,
                state: {backPath: `/home/mandate/${mandateId}/matches`}
              });
            }
          }
          break;
        }
        default: {
          if (record.isNotification) {
            props.deleteNotification(record.shareId, record.id);
          } else {
            history.push({
              pathname: `/home/deal-for-mandate/${record.id}${record.shareId ? '/' + record.shareId : ''}`,
              state: {
                hideAll,
                isOwnMandate
              }
            })
          }
          break;
        }
      }
    }
  }

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount, showSizeChanger: true}}
      className='deal-table'
      rowKey={row => row.id}
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
      showSorterTooltip={false}
    />
   )


}

export default MandateMatchesTable
