import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Input, Button } from 'antd'

import DealsActions from '../../Redux/DealsRedux'
import DealDashboardActions from '../../Redux/DealDashboardRedux'
import DealSharingReview from '../../Components/DealSharing/DealSharingReview'
import MandateDealSharingReview from '../../Components/MandateDealSharing/MandateDealSharingReview'

const { TextArea } = Input;

const styleObj = {
  maxWidth: 'auto',
  // minWidth: '1392px'
  width: '100%',
};

function DealDetailsPage(props) {
  const { deal, dashboardId, dashboardInfo, role, investmentTypes, match } = props;
  const { dealId } = match.params;

  const [dealData, setDealData] = useState(null);
  const [targetCompanyData, setTargetCompanyData] = useState(null);

  const history = useHistory();

  useEffect(() => {
    props.getDeal(dealId);
    props.getDealDashboardInfo(dashboardId);
  }, [0]);

  useEffect(() => {
    if (deal) {
      setDealData(deal);
    }
  }, [deal]);

  useEffect(() => {
    if (dashboardInfo) {
      const newData = {
        name: dashboardInfo.target_company?.name,
        website: dashboardInfo.target_company?.website,
        target_description: dashboardInfo.target_company?.target_description,
      };
      setTargetCompanyData(newData);
    }
  }, [dashboardInfo]);

  return (
    <div className="main-container" style={{ paddingTop: 40 }}>
      {
        dealData &&
        <>
          <div className="firm-profile-block match dd">
            <div className="pa-header" style={{marginBottom: 32}}>
            <div className="section-title" style={{ alignSelf: 'self-start', margin: 0 }}>Deal Details</div>
            {
              (role === 0 && dealData.status_obj?.id === 'closed') &&
                <Button
                  type="primary"
                  className="btn-primary"
                  onClick={() => history.push(
                    dashboardInfo?.mandate ?
                    {pathname: `/deal-dashboard/deal-details/${dealId}/reshare-mandate-deal`,
                    state: {fromDealDeatailsPage: true}}
                    :
                    {pathname: `/deal-dashboard/deal-details/${dealId}/reshare-deal`,
                    state: {terminated: true}
                  })}
                >
                  Re-share
                </Button>
            }
            </div>
            <div className="firm-profile-section-top dashboard" style={{ marginBottom: 0 }}>
              <div className="firm-profile-input" style={{ marginRight: 40 }}>
                <span>Target Company:</span>
                <Input
                  defaultValue={targetCompanyData?.name}
                  disabled={true}
                />
              </div>
              <div className="firm-profile-input">
                <span>Link to Website:</span>
                <Input
                  defaultValue={targetCompanyData?.website}
                  disabled={true}
                />
              </div>
              {!dashboardInfo?.mandate && <div className="firm-profile-input">
                <span>Target Description:</span>
                <TextArea
                  defaultValue={targetCompanyData?.target_description}
                  disabled={true}
                  autoSize={true}
                />
              </div>}
            </div>
          </div>
          {
            dashboardInfo?.mandate ?
              <MandateDealSharingReview
                dealData={dealData}
                investmentTypes={investmentTypes}
                role={role}
                disableTargetInfo={match.url.includes('/deal-dashboard/deal-details/') ? true : false}
              />
            :
              <DealSharingReview
                dealData={dealData}
                styleObj={styleObj}
                disableSought={true}
                role={role}
              />
          }
        </>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    deal: state.deals.deal,
    dashboardId: state.dealDashboard.dashboardId,
    dashboardInfo: state.dealDashboard.dashboardInfo,
    role: state.auth.role,
    investmentTypes: state.settings.investmentTypes,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDeal: (id) => dispatch(DealsActions.dealRequest(id)),
  getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),

})

export default connect(mapStateToProps, mapDispatchToProps)(DealDetailsPage)
