import React, { useState, useEffect } from 'react'
import { Button, message, Spin } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

import SettingsActions from '../../Redux/SettingsRedux'
import MandatesActions from '../../Redux/MandatesRedux'

import MandateSharingStart from '../../Components/MandateSharing/MandateSharingStart'
import Images from '../../Images'

function MandateEditPage(props) {
  const { mandate, fetching, dealSizeList, mandateGroupDataResult, match, location, sectorFocus, geographyFocus, investmentTypes, isAdmin } = props;
  const { mandateId } = match.params;
  const { state } = location;

  const [mandateData, setMandateData] = useState(null);
  const [data, setData] = useState(null);
  const [checkRequired, setCheckRequired] = useState(false);
  const [backPath, setBackPath] = useState('');
  const [status, setStatus] = useState(null);

  const history = useHistory();

  useEffect(() => {
    props.getSectors();
    props.getContinents({ regions: true });
    props.getInvestmentTypes();
    props.getDealSize();
  }, [0]);

  useEffect(() => {
    if (mandateId) {
      props.getMandate(mandateId);
    }
  }, [mandateId]);

  useEffect(() => {
    if (mandate) {
      setMandateData(mandate);
      setStatus(mandate.status_obj?.id)
      if (mandate.status_obj?.id === 'in_progress') {
        setBackPath({
          pathname: '/home/mandate-sharing/step/review',
          state: {mandateId}
        });
      } else {
        isAdmin ?
          setBackPath(`/home/mandate-details/${mandateId}`)
          :
          setBackPath(`/home/mandate/${mandateId}/summary`);
      }
    }
  }, [mandate]);

  useEffect(() => {
    if (data) {
      props.saveMandateGroupData(mandateId, data);
    }
  }, [data]);

  useEffect(() => {
    if (mandateGroupDataResult) {
      props.getMandate(mandateId);
      if (state) {
        history.push({
          pathname: state.backPath,
          state
        });
      } else {
        isAdmin ?
          history.push(`/home/mandate-details/${mandateId}`)
        :
          history.push(`/home/mandate/${mandateId}/summary`);
      }
    }
  }, [mandateGroupDataResult]);

  const changeStep = (data) => {
    if (!data) {
      message.error('All fields must be filled in to proceed', 3);
    }
    setData(data);
  }

  const checkRequiredFields = () => {
    setCheckRequired(true);
    setTimeout(() => {
      setCheckRequired(false);
    })
  }

  return (
    <div className="sign-up-step filters-edit white" style={{ padding: '133px 80px 32px' }}>
      <div className="absolute-header">
        <div className="flex-center-vertical">
          <div className="logo-block">
            <img src={Images.logoWhite} alt="" />
          </div>
          <div className="title-block bottom">{mandateId}</div>
        </div>
      </div>
      <Spin size="large" spinning={fetching}>
      {
        backPath &&
        <div className="return-block" style={{ padding: '24px 0' }}>
          <Link to={backPath}>
            <img src={Images.arrowLeft} alt="" />
            <span>Back</span>
          </Link>
        </div>
      }
      <div className="section-title" style={{ alignSelf: 'self-start', marginTop: 0 }}>EDIT THE MANDATE</div>

      <MandateSharingStart
        checkRequired={checkRequired}
        mandateData={mandateData}
        sectorFocus={sectorFocus}
        geographyFocus={geographyFocus}
        investmentTypes={investmentTypes}
        dealSizeList={dealSizeList}
        onChangeStep={changeStep}
      />

      <div className="btn-footer invite" style={{ marginTop: 58 }}>
        <Button type="primary" className="btn-primary" style={{ width: 217, margin: '0 32px' }} onClick={checkRequiredFields}>
          Save
        </Button>
      </div>
      </Spin>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    mandate: state.mandates.mandate,
    fetching: state.mandates.saveFetching,
    dealSizeList: state.mandates.dealSizeList,
    mandateGroupDataResult: state.mandates.mandateGroupDataResult,
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    investmentTypes: state.settings.investmentTypes,
    isAdmin: state.auth.isAdmin,
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveMandateGroupData: (mandateId, data) => dispatch(MandatesActions.mandateGroupDataSaveRequest(mandateId, data)),
  getMandate: (mandateId) => dispatch(MandatesActions.mandateRequest(mandateId)),

  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: (filter) => dispatch(SettingsActions.geographyFocusRequest(filter)),
  getCountries: (filter) => dispatch(SettingsActions.countriesRequest(filter)),
  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),
  getDealSize: () => dispatch(MandatesActions.dealSizeRequest()),

})

export default connect(mapStateToProps, mapDispatchToProps)(MandateEditPage)
