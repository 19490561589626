import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, InputNumber, Upload, message, Spin } from 'antd'

import {useHistory} from 'react-router-dom'

import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

import ExpertConsultActions from '../../../Redux/ExpertConsultRedux'
import DealDashboardActions from '../../../Redux/DealDashboardRedux'

function ReUploadDaysOfWorkPopupPage(props) {
  const { expertConsultInfo, result, match, fetching, location } = props;
	const {dashboardId} = match.params;
	const { state } = location;

  const history = useHistory()

	const [value, setValue] = useState(state?.add_info?.days ? state?.add_info?.days : null)
	const [active, setActive] = useState(false)
	const [fileUid, setFileUid] = useState(null)
	const [fileData, setFileData] = useState(null)
	const [consultId, setConsultId] = useState(null)
	const [start, setStart] = useState(false)

	useEffect(() => {
    props.dealDashboardSyncToggle(false);
    setStart(true)
    return function cleanup() {
      props.dealDashboardSyncToggle(true);
    }
  }, [0]);

	useEffect(() => {
		if (expertConsultInfo) {
			setConsultId(expertConsultInfo.id);
		}
	}, [expertConsultInfo]);

  useEffect(() => {
    if (result && start) {
      props.getDealDashboardInfo(dashboardId);
      props.getExpertConsultInfo(dashboardId);
      history.push({ pathname: `/deal-dashboard/overview/${dashboardId}`})
    }
  }, [result])

	function onChange({ file, fileList }) {
		if (fileUid && fileUid === file.uid) {
			return
		}
		setFileUid(file.uid)

		let formData = new FormData()

		if (fileList && fileList.length) {
			formData.set('file', fileList[0].originFileObj, fileList[0].originFileObj.name)
		}
		setFileData(formData)
	}

	const checkRequired = () => {
		if (state?.isHistory) {
      history.push(`/deal-dashboard/overview/${dashboardId}`);
			return;
    }
		if (!value) {
			message.error('Indicate the number of days', 3)
			if (!active) {
				setActive(true)
			}
		} else if (!fileData) {
			message.error('Upload the file', 3)
		} else {
			fileData.set('days', value)
			props.hoursUpDate(dashboardId, consultId, fileData)
			// props.getDealDashboardInfo(dashboardId);
		}
	}

	return (
		<PopupWrapper
			width={420}
			path={`/deal-dashboard/overview/${dashboardId}`}
			hasDropdown={true}
		>
			<Spin spinning={fetching} size="large">
				<div className='dialog-content' style={{ padding: 30 }}>
					<div className='dialog-title bold' style={{ margin: 6 }}>
						Upload work done
				</div>
					<div className='deal-content-wrapper'>
						<div className='deal-text' style={{ marginTop: 4, fontSize: 13 }}>
							Upload the days you have worked, rounded up to half day (i.e. 4.5), which have to be approved by the Investor
					</div>
						<div style={{ display: 'flex', alignSelf: 'center' }}>
							<InputNumber
								// defaultValue={state?.add_info?.days}
								value={value}
								className={`hours option-input${active && !value ? ' error-input' : ''}`}
								onChange={e => setValue(Math.abs(Math.floor(e)))}
								maxLength={3}
								disabled={state?.isHistory}
							/>
							<span className='hours-text'>Days</span>
						</div>
						<Upload
							className='upload-work-done-pop'
							method='get'
							maxCount={1}
							disabled={state?.isHistory}
							onChange={onChange}
							beforeUpload={file =>
								new Promise((resolve, reject) => {
									if (file.size > 20971520) {
										message.error('File size must not exceed 20 Mbyte', 3)
										reject()
									} else {
										resolve()
									}
								})
							}
						>
							<div className='download-wrapper active'>
								<div className='download-text' style={{ fontSize: 8 }}>
									Upload supporting document
							</div>
								<img src={Images.upload} alt='' />
							</div>
						</Upload>
					</div>
					<div className='btn-footer' style={{ marginTop: 12 }}>
						<Button
							type='primary'
							className='btn-primary flat'
							style={{ width: 144, marginTop: 30, background: '@blue_light', border: '1px solid black' }}
							onClick={checkRequired}
						>
							{state?.isHistory ? 'Go back' : 'Submit'}
						</Button>
					</div>
					<div style={{ fontSize: 9, marginTop: 8 }}>
						<span style={{ fontWeight: 700, marginRight: 6 }}>Please note:</span>
					this re-submition replaces the previous days uploaded
				</div>
				</div>
			</Spin>
		</PopupWrapper>
	)
}

const mapStateToProps = state => {
	return {
		expertConsultInfo: state.expertConsult.expertConsultInfo,
		fetching: state.expertConsult.fetching_,
    result: state.expertConsult.result
	}
}

const mapDispatchToProps = dispatch => ({
	hoursUpDate: (dashboardId, consultId, fileData) => dispatch(ExpertConsultActions.hoursUpDateRequest(dashboardId, consultId, fileData)),
	getDealDashboardInfo: dashboardId => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
	dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
  getExpertConsultInfo: dashboardId => dispatch(ExpertConsultActions.expertConsultingRequest(dashboardId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReUploadDaysOfWorkPopupPage)
