import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import Images from '../../../Images'
import '../PopupPage.less'

function EngagementTerminatedAdvisorPopupPage(props) {
  const { result, dealClosedResult, fetching, match, location, dashboardInfo } = props;
  const { dashboardId } = match.params;
  const { state } = location;

  const history = useHistory();

  useEffect(() => {
    props.dealDashboardSyncToggle(false);
    return function cleanup() {
      props.dealDashboardSyncToggle(true);
    }
  }, [0]);

  useEffect(() => {
    if (result) {
      props.viewDealClosedAdvisor(dashboardId);
    }
  }, [result]);

  useEffect(() => {
    if (dealClosedResult) {
      dashboardInfo?.mandate ?
        history.push(`/deal-dashboard/overview/${dashboardId}/reshare-mandate-deal`)
        :
        history.push(`/deal-dashboard/overview/${dashboardId}/reshare-deal`)
    }
  }, [dealClosedResult]);

  const removeNotification = () => {
    if (state?.isHistory) {
      history.push(`/deal-dashboard/overview/${dashboardId}`);
    } else {
      props.changeNotificationStatus(state?.notification)
    }
  }

  return (
    <div className="popup-dialog">
      <div className="dialog-container" style={{ width: 420 }}>
        <Spin spinning={fetching} size="large">
          <div className="dialog-content" style={{ padding: 40, paddingTop: 20, paddingBottom: 30 }}>
            <img src={Images.phoneCancel} alt="" style={{ marginTop: 14 }} />
            <div className="dialog-title welcome" style={{ margin: 6 }}>Engagement Terminated</div>
            <div className="deal-text engagment" style={{ marginTop: 14 }}>We are sorry to inform you that the Investor<br />has decided to terminate the engagement</div>
            <br />
            <div className="deal-text sm" style={{ width: '90%' }}>Please review IDN's Platform Agreement to remember your rights and obligations in the context of the termination of this engagement</div>
            <div className="btn-footer">
              <Button type="primary white"
                className="btn-primary welcome"
                style={{ width: 144 }}
                onClick={removeNotification}
              >
                {state?.isHistory ? 'Go back' : 'Continue'}
              </Button>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    result: state.dealDashboard.result,
    dealClosedResult: state.dealDashboard.dealClosedResult,
    fetching: state.dealDashboard.fetching,
    dashboardInfo: state.dealDashboard.dashboardInfo,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
  changeNotificationStatus: (notificationId) => dispatch(DealDashboardActions.notificationChangeStatusRequest(notificationId)),
  viewDealClosedAdvisor: (dashboardId) => dispatch(DealDashboardActions.advisorSeenClosedDealRequest(dashboardId)),
  dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EngagementTerminatedAdvisorPopupPage)
