import React, { useState, useEffect } from 'react'
import {  Button, message, Spin } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

import SettingsActions from '../../Redux/SettingsRedux'
import DealsActions from '../../Redux/DealsRedux'

import DealSharingStep1 from '../../Components/DealSharing/DealSharingStep1'
import DealSharingStep2 from '../../Components/DealSharing/DealSharingStep2'
import DealSharingStep3 from '../../Components/DealSharing/DealSharingStep3'
import DealSharingStep4 from '../../Components/DealSharing/DealSharingStep4'
import Images from '../../Images'

function DealEditPage(props) {
  const { deal, fetching, dealGroupDataResult, match, location, sectorFocus, geographyFocus, equityGovernanceList, investmentTypes, isAdmin } = props;
  const { dealId } = match.params;
  const { state } = location;

  const [dealData, setDealData] = useState(null);
  const [checkRequired, setCheckRequired] = useState(false);
  const [backPath, setBackPath] = useState('');

  const [step1Data, setStep1Data] = useState(null);
  const [step2Data, setStep2Data] = useState(null);
  const [step3Data, setStep3Data] = useState(null);
  const [step4Data, setStep4Data] = useState(null);

  const history = useHistory();

  useEffect(() => {
    props.getSectors();
    props.getContinents({ regions: true });
    props.getEquityGovernance();
    props.getInvestmentTypes();
  }, [0]);

  useEffect(() => {
    if (deal) {
      setDealData(deal);
    }
  }, [deal]);

  useEffect(() => {
    if (dealId) {
      props.getDeal(dealId);
      if (state) {
        setBackPath({pathname: '/home/deal-sharing/step/review', state: {dealId: dealId}});
      } else {
        isAdmin ?
          setBackPath(`/home/deal-details/${dealId}`)
        :
          setBackPath(`/home/deal/${dealId}/summary`);
      }
    }
  }, [dealId]);

  useEffect(() => {
    if (step1Data && step2Data && step3Data && step4Data) {
      const totalData = {
        ...step1Data,
        investment_overview: step2Data,
        advisor_role: step3Data,
        investor_sought: step4Data,
      }
      props.saveDealGroupData(dealId, totalData);
    }
  }, [step1Data, step2Data, step3Data, step4Data]);

  useEffect(() => {
    if (dealGroupDataResult) {
      props.getDeal(dealId);
      if (state) {
        history.push({pathname: '/home/deal-sharing/step/review', state: {dealId: dealId}});
      } else {
        isAdmin ?
          history.push(`/home/deal-details/${dealId}`)
        :
          history.push(`/home/deal/${dealId}/summary`);
      }
    }
  }, [dealGroupDataResult]);

  const changeStep = (data, field) => {
    if (!data) {
      message.error('All fields must be filled in to proceed', 3);
    }
    switch(field) {
      case 'matching_criteria': {
        setStep1Data(data);
        break;
      }
      case 'investment_overview': {
        setStep2Data(data);
        break;
      }
      case 'advisor_role': {
        setStep3Data(data);
        break;
      }
      case 'investor_sought': {
        setStep4Data(data);
        break;
      }
      default: break;
    }
  }

  const checkRequiredFields = () => {
    setCheckRequired(true);
    setTimeout(()=> {
      setCheckRequired(false);
    })
  }

  return (
    <div className="sign-up-step filters-edit white">
      <div className="absolute-header">
        <div className="flex-center-vertical">
          <div className="logo-block">
            <img src={Images.logoWhite} alt="" />
          </div>
          <div className="title-block bottom">{dealId}</div>
        </div>
      </div>
      <Spin size="large" spinning={fetching}>
      {
        backPath &&
          <div className="return-block" style={{ padding: '24px 0' }}>
            <Link to={backPath}>
              <img src={Images.arrowLeft} alt="" />
              <span>Back</span>
            </Link>
          </div>
      }
      <div className="section-title" style={{alignSelf: 'self-start', marginTop: 0}}>EDIT THE DEAL</div>

      <DealSharingStep1
        checkRequired={checkRequired}
        dealData={dealData}
        sectorFocus={sectorFocus}
        geographyFocus={geographyFocus}
        equityGovernanceList={equityGovernanceList}
        investmentTypes={investmentTypes}
        onChangeStep={changeStep}
      />
      <DealSharingStep2
        checkRequired={checkRequired}
        dealData={dealData}
        onChangeStep={changeStep}
        isAdmin={isAdmin}
      />
      <DealSharingStep3
        checkRequired={checkRequired}
        dealData={dealData}
        onChangeStep={changeStep}
      />
      {<DealSharingStep4
        checkRequired={checkRequired}
        onChangeStep={changeStep}
        dealData={dealData}
      />}

      <div className="btn-footer invite" style={{ marginTop: 58 }}>
        <Button type="primary" className="btn-primary" style={{ width: 217, margin: '0 32px' }} onClick={checkRequiredFields}>
          Save
        </Button>
      </div>
      </Spin>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    deal: state.deals.deal,
    fetching: state.deals.saveFetching,
    dealShares: state.dealShares,
    dealGroupDataResult: state.deals.dealGroupDataResult,
    dealChangeStatusResult: state.deals.dealChangeStatusResult,
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    equityGovernanceList: state.settings.equityGovernanceList,
    investmentTypes: state.settings.investmentTypes,
    user: state.auth.userInfo,
    isAdmin: state.auth.isAdmin,
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveDealGroupData: (id, data) => dispatch(DealsActions.dealGroupDataSaveRequest(id, data)),
  getDeal: (id) => dispatch(DealsActions.dealRequest(id)),

  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: (filter) => dispatch(SettingsActions.geographyFocusRequest(filter)),
  getCountries: (filter) => dispatch(SettingsActions.countriesRequest(filter)),
  getEquityGovernance: () => dispatch(SettingsActions.equityGovernanceRequest()),
  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),

})

export default connect(mapStateToProps, mapDispatchToProps)(DealEditPage)
