import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Input } from 'antd'

import DealsInvestorTable from '../../Components/Tables/DealsInvestorTable'
import DealsActions from '../../Redux/DealsRedux'

import SettingsActions from '../../Redux/SettingsRedux'
import ShareFilterPanel from '../../Components/FilterPanels/ShareFilterPanel'
import Images from '../../Images'

const { Search } = Input;

function AllDealsPage(props) {
  const { deals, sectorFocus, geographyFocus, equityGovernanceList, investmentTypes } = props;

  const [active, setActive] = useState(false);
  const [count, setCount] = useState(null);

  useEffect(() => {
    props.getDeals(deals.page, deals.page_size, null, null, deals.search, deals.filter);
    props.getSectors();
    props.getContinents();
    props.getEquityGovernance();
    props.getInvestmentTypes();
  }, [0]);

  const handleChangePage = (page, page_size) => {
    props.getDeals(page, page_size, null, null, deals.search, deals.filter);
  }

  const handleSearch = val => {
    if (deals.search === val) {
      return;
    }
    props.getDeals(1, deals.page_size, null, null, val, deals.filter);
  }

  const changeCount = (value, data, start) => {
    setCount(value);
    !start && props.getDeals(1, deals.page_size, null, null, deals.search, data);
  }

  function closeFilter() {
    setActive(!active);
  }

  return (
    <div className="main-container">
      <div className="action-panel" style={{marginTop: '1.67vw'}}>
        <Search
          placeholder="Search"
          allowClear
          className="content-search"
          suffix={<img src={Images.search} alt="" />}
          defaultValue={deals.search}
          onSearch={handleSearch}
        />
        <Button type={`primary${count ? ' orange' : ' white'}`} className="btn-primary" onClick={closeFilter}>
          {'Filter' + (count ? ` (${count})` : '')}
        </Button>
        <ShareFilterPanel
          filterData={deals.filter}
          active={active}
          sectors={sectorFocus}
          continents={geographyFocus}
          governance={equityGovernanceList}
          investmentTypes={investmentTypes}
          changeCount={changeCount}
          closeFilter={closeFilter}
        />
      </div>
      <DealsInvestorTable
        tab="all"
        data={deals.list}
        page={deals.page}
        fetching={false}
        pageSize={deals.page_size}
        allDeal={true}
        onChangePage={handleChangePage}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    deals: state.deals,
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    equityGovernanceList: state.settings.equityGovernanceList,
    investmentTypes: state.settings.investmentTypes,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDeals: (page, page_size, sort_field, sort_order, search, filter) => dispatch(DealsActions.dealsRequest(page, page_size, sort_field, sort_order, search, filter)),

  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: (filter) => dispatch(SettingsActions.geographyFocusRequest(filter)),
  getEquityGovernance: () => dispatch(SettingsActions.equityGovernanceRequest()),
  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),

})

export default connect(mapStateToProps, mapDispatchToProps)(AllDealsPage)
