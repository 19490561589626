import React from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

import PopupWrapper from './PopupWrapper'
import Images from '../../Images'
import './PopupPage.less'

function ApplicationSubmittedPopup(props) {

  const history = useHistory();

  return (
    <PopupWrapper
      width={420}
      path={`/home/deals`}
    >
      <div className="dialog-content" style={{ padding: '24px 64px' }}>
        <img src={Images.phoneOk} alt="" />
        <div className="dialog-title deal">Application Submitted</div>
        <div className="deal-text">
          {/* Your application for this deal opportunity has been correctly submitted.  You will be notified about the Senior Advisor's decision. */}
          Congratulations on matching this opportunity. You will be notified of the Senior Advisor's response.
          </div>
        <br />
        <div className="btn-footer" style={{ marginTop: 24 }}>
          <Button type="primary white" className="btn-primary" onClick={()=>history.push('/home/deals')}>
            Continue
        </Button>
        </div>
      </div>
    </PopupWrapper>
  )
}

export default ApplicationSubmittedPopup
