import React, { useState, useEffect } from 'react'
import { Form, Input, Space, Button, Row, Col } from 'antd'
import Utils from '../../Utils/utils'

import Images from '../../Images'

const { TextArea } = Input;

function ContactInvestorForm(props) {
  const {checkForm, defaultData} = props;

  const [start, setStart] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (checkForm != null) {
      form.validateFields()
        .then(values => {
          const data = { ...values };
          props.onSubmit(data.users);
        })
        .catch(error => {
          props.onSubmit();
        })
    }
  }, [checkForm]);

  return (
    <Form
      id="contact-investor-form"
      initialValues={{ users: defaultData ? defaultData : [] }}
      form={form}
      className="login-form"
      layout="vertical">
      <Form.List name="users">
        {(fields, { add, remove }) => {
          if (!defaultData.length) {
            if (!start) {
              add();
              setStart(true);
            }
          }
          return (
              <>
                {fields.map((field, i) => (
                  <div key={field.key} className="dnc-form-wrapper">
                    <Row>
                      <Col span={6} offset={2}>
                        <Form.Item>
                          <Space className="dnc-title">
                            <div>
                              <img src={Images.user} alt="" />
                              {`Contact Investor ${i + 1}`}
                            </div>
                            <img src={Images.ex} alt="" onClick={() => remove(field.name)} />
                          </Space>
                        </Form.Item>
                      </Col>
                      <Col span={16}>
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'firm_name']}
                              fieldKey={[field.fieldKey, 'firm_name']}
                              rules={[{ required: true, message: 'This field is required' }]}
                            >
                              <Input
                                placeholder="Name of Firm"
                                maxLength={100}
                                suffix={'*'}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'website']}
                              fieldKey={[field.fieldKey, 'website']}
                              rules={[{ required: true, message: 'This field is required' }]}
                            >
                              <Input
                                placeholder="Link of Website"
                                maxLength={100}
                                suffix={'*'}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'person_name']}
                              fieldKey={[field.fieldKey, 'person_name']}
                            >
                              <Input
                                placeholder="Person to Contact (name, surname)"
                                maxLength={100}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'job']}
                              fieldKey={[field.fieldKey, 'job']}
                            >
                              <Input
                                placeholder="Job Title"
                                maxLength={100}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={7}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'email']}
                              fieldKey={[field.fieldKey, 'email']}
                              rules={[
                                { type: 'email', message: 'Invalid email format' },
                                { min: 5, message: 'Field must contain at least 5 characters' },
                              ]}
                              onKeyPress={e=>{if(e.charCode === 32) e.preventDefault()}}
                              onBlur={e=>Utils.clearSpaces(e, 'email', form.setFieldsValue)}
                            >
                              <Input
                                placeholder="Email"
                                maxLength={100}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={24}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'message']}
                              fieldKey={[field.fieldKey, 'message']}
                            >
                              <TextArea
                                placeholder="Please provide some context and add a message"
                                rows={3}
                                maxLength={3000}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                  </div>
                ))}
                <Form.Item>
                  <div className="btn-footer invite">
                    <Button type="primary white" className="btn-primary" style={{ width: 217 }} onClick={() => fields.length < 20 && add()} disabled={fields.length > 19}>
                      Add Another One
                    </Button>
                  </div>
                </Form.Item>
              </>
          )
        }}
      </Form.List>
    </Form>
  )
}

export default ContactInvestorForm
