import React, { useState, useEffect } from 'react'
import { Button, Tabs, Input, message } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

import DealsActions from '../../Redux/DealsRedux'
import ExperienceActions from '../../Redux/ExperienceRedux'

import DealSharingReview from '../../Components/DealSharing/DealSharingReview'
import Images from '../../Images'

const { TabPane } = Tabs;
const { TextArea } = Input;

function DealShareInfoPage(props) {
  const { match, deal, experience, filters } = props;
  const { dealId, tab } = match.params;

  const status = filters?.company?.status?.id;

  const [dealData, setDealData] = useState(null);
  const [activeKey, setActiveKey] = useState('summary');

  const history = useHistory();

  useEffect(() => {
    props.getDeal(dealId);
  }, [0]);

  useEffect(() => {
    setActiveKey(tab ? tab : 'summary');
  }, [tab]);

  useEffect(() => {
    if (deal) {
      setDealData(deal);
      props.getExperience(deal.owner_obj?.id);
    }
  }, [deal]);

  const applyDeal = () => {
    if (!filters.all_agreements) {
      message.error('Legal agreement not approved - please get in touch with IDN', 3);
    } else {
      history.push({
        pathname: '/home/your-application',
        state: { shareId: null, dealData, tab: activeKey }
      });
    }
  }

  const onTabChange = key => {
    history.push(`/home/deal-share-info-all/${dealId}/${key}`);
    setActiveKey(key);
  }

  return (
    <div className="sign-up-step filters-edit wo-padding">
      <div className="absolute-header">
        <div className="flex-center-vertical">
          <div className="logo-block">
            <img src={Images.logoWhite} alt="" />
          </div>
          <div className="title-block bottom">{dealId}</div>
        </div>
      </div>
      <div className="return-block">
        <Link to={"/home/all-deals"}>
          <img src={Images.arrowLeft} alt="" />
          <span>Return to Dashboard</span>
        </Link>
      </div>
      <Tabs activeKey={activeKey} onChange={onTabChange}>
        <TabPane tab="Deal Summary" key="summary">
          <DealSharingReview
            dealData={dealData}
            disableSought={true}
          />
        </TabPane>
        <TabPane tab="Advisor profile" key="profile">
          <div className="firm-profile-block match deal-share-info">
            <div className="firm-profile-section-middle">
              <div className="firm-profile-title">
                <span>Experience - Summary</span>
              </div>

              <div className="firm-profile-area">
                <TextArea
                  value={experience?.executive_summary}
                  autoSize={true}
                  disabled={true}
                />
              </div>
              <div className="firm-profile-title">
                <span>Experience - Deal origination</span>
              </div>
              <div className="firm-profile-area">
                <TextArea
                  value={experience?.deal_origination_experience}
                  autoSize={true}
                  disabled={true}
                />
              </div>
              <div className="firm-profile-title">
                <span>Experience - Working with Private Equity</span>
              </div>
              <div className="firm-profile-area" style={{ marginBottom: 0 }}>
                <TextArea
                  value={experience?.private_equity_experience}
                  autoSize={true}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </TabPane>
      </Tabs>
      <div className="review-block deal-share-info">
        <div>
          <Button type="primary white"
            className="btn-primary"
            onClick={() => history.push(`/home/deal-share-info-all/${dealId}/${activeKey}/share-deal`)}
          >
            Share
          </Button>
          <span>[Send it to a colleague]</span>
        </div>
        {
          status === 1 &&
            <div>
              <Button type="primary"
                className="btn-primary"
                onClick={applyDeal}
              >
                Apply
              </Button>
              <span>[Express interested in this deal]</span>
            </div>
        }
      </div>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    experience: state.experience.list,
    filters: state.settings.filters,
    deal: state.deals.deal,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDeal: (id) => dispatch(DealsActions.dealRequest(id)),

  getExperience: (advisorId) => dispatch(ExperienceActions.experienceRequest(advisorId)),

})

export default connect(mapStateToProps, mapDispatchToProps)(DealShareInfoPage)
