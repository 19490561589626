import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Table } from 'antd'
import moment from 'moment'

import PaginationItemRender from '../PaginationItemRender'
import { TitleWithSortFilters } from '../TitleWithSortFilters'
import CustomIcon from '../../Icon'

import './Table.less'

function DealMatchesAdminTable(props){
  const {
    page,
    pageSize,
    data,
    fetching,
  } = props;

  const [dataSource, setDataSource] = useState(null);

  const history = useHistory();

  useEffect(() => {
    let arr = (data && data.results) ? data.results.asMutable({deep:true}) : [];
    arr = arr.map(el => ({
      id: el.id,
      investor_name: el.investor_name,
      invest_company: el.invest_company,
      account_manager: el.account_manager,
      matched: el.matched,
      advisor_status: el.advisor_status_obj?.name,
      status: el.advisor_status_obj?.id,
      dashboardId: el.dashboard
    }))
    setDataSource(arr);
  }, [data]);

  function getColumns() {
    return [
      {
        title: (stateData)=>TitleWithSortFilters("Name", true, false, stateData, 'investor_name'),
        dataIndex: 'investor_name',
        key: 'investor_name',
        sorter:true,
      },
      {
        title: (stateData)=>TitleWithSortFilters("Investment firm", true, false, stateData, 'invest_company'),
        dataIndex: 'invest_company',
        key: 'invest_company',
        sorter:true,
      },
      {
        title: (stateData)=>TitleWithSortFilters("Account Manager", true, false, stateData, 'account_manager'),
        dataIndex: 'account_manager',
        key: 'account_manager',
        sorter:true,
      },
      {
        title: (stateData)=>TitleWithSortFilters("Date", true, false, stateData, 'matched'),
        dataIndex: 'matched',
        key: 'matched',
        sorter: true,
        render: (text, record) => {
          return (
              <span>{text && moment(text).format('DD/MM/YYYY')}</span>
          )
        },
      },
      {
        title: (stateData)=>TitleWithSortFilters("Status", true, false, stateData, 'advisor_status'),
        dataIndex: 'advisor_status',
        key: 'advisor_status',
        sorter:true,
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        render: (text, record) => {
          return (
            <span className="link-tag" onClick={()=>generateAction(record)}>{generateText(record)}</span>
            // <Link to={generateLink(record)}>{generateText(record)}</Link>
          )
        },
      }
    ];
  }

  function handleTableChange(pagination, filters, sorter){
    var sort_field = null;
    var sort_order = null;

    if(sorter && sorter.field && sorter.order){
      sort_field = sorter.field;
      sort_order = sorter.order //=== "ascend" ? 1 : 0;
    }

    props.onChangePage(pagination.current, pagination.pageSize, sort_field, sort_order)

  }

  const totalCount = data ? data.count : 0;

  const generateText = record => {
    switch(record.status) {
      case 'engaged': return 'Go to deal';
      default: return 'View';
    }
  }

  const generateAction = record => {
    if (record.dashboardId) {
      props.resetDealDashboard();
      history.push(`/home/match-company/${record.dashboardId}`);
    } else {
      history.push({
        pathname:`/home/deal-investor-firm/${record.id}`,
        state: true
      });
    }
  }

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount, showSizeChanger: true}}
      className="main-table share"
      rowKey={row => row.id}
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
      showSorterTooltip={false}
      rowClassName={row => {
        switch(row?.status) {
          case 'engaged':
          case 'closed_success': return 'bold-row';
          case 'terminated': return 'red-row';
          default: return '';
        }
      }}
    />
   )


}

export default DealMatchesAdminTable
