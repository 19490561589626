import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  experienceRequest: ['advisorId'],
  experienceSuccess: ['list'],

  experienceSaveRequest: ['advisorId', 'data'],
  experienceSaveSuccess: ['saveResult'],
  experienceSaveReset: null,

  experienceDeleteRequest: ['advisorId'],
  experienceDeleteSuccess: ['deleteResult'],
  experienceDeleteReset: null,

  experienceJobsRequest: ['advisorId'],
  experienceJobsSuccess: ['jobs'],

  experienceJobSaveRequest: ['advisorId', 'jobId', 'data'],
  experienceJobSaveSuccess: ['saveResult'],
  experienceJobSaveReset: null,

  experienceJobDeleteRequest: ['advisorId', 'jobId'],
  experienceJobDeleteSuccess: ['deleteResult'],
  experienceJobDeleteReset: null,

  experienceReset: null,

  experienceFetchingReset: null
})

export const ExperienceTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  fetching: false,

  saveResult: null,
  saveFetching: false,

  deleteResult: null,
  deleteFetching: false,

  jobs: null
})

/* ------------- Selectors ------------- */
export const ExperienceSelectors = {
  getState: (state) => state.experience
}

/* ------------- Reducers ------------- */

export const experienceRequest = (state, { advisorId }) =>{
  return state.merge({ fetching: true })
}

export const experienceSuccess = (state, { list }) => {
  return state.merge({ fetching: false, list })
}

export const experienceSaveRequest = (state, { advisorId, data }) => {
  return state.merge({ saveFetching: true })
}

export const experienceSaveSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const experienceSaveReset = (state) =>{
  return state.merge({ saveFetching: false,  saveResult:null })
}

export const experienceDeleteRequest = (state, { advisorId }) => {
  return state.merge({ deleteFetching: true })
}

export const experienceDeleteSuccess = (state, { deleteResult }) => {
  return state.merge({ deleteFetching: false, deleteResult })
}

export const experienceDeleteReset = (state) =>{
  return state.merge({ deleteFetching: false,  deleteResult:null })
}


export const experienceJobsRequest = (state, { advisorId }) =>{
  return state.merge({ fetching: true })
}

export const experienceJobsSuccess = (state, { jobs }) => {
  return state.merge({ fetching: false, jobs })
}

export const experienceJobSaveRequest = (state, { advisorId, jobId, data }) => {
  return state.merge({ saveFetching: true })
}

export const experienceJobSaveSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const experienceJobSaveReset = (state) =>{
  return state.merge({ saveFetching: false,  saveResult:null })
}

export const experienceJobDeleteRequest = (state, { advisorId, jobId }) => {
  return state.merge({ deleteFetching: true })
}

export const experienceJobDeleteSuccess = (state, { deleteResult }) => {
  return state.merge({ deleteFetching: false, deleteResult })
}

export const experienceJobDeleteReset = (state) =>{
  return state.merge({ deleteFetching: false,  deleteResult:null })
}

export const experienceReset = (state) =>{
  return INITIAL_STATE
}

export const experienceFetchingReset = (state) => {
  return state.merge({ fetching:false, saveFetching:false, deleteFetching:false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.EXPERIENCE_REQUEST]: experienceRequest,
  [Types.EXPERIENCE_SUCCESS]: experienceSuccess,

  [Types.EXPERIENCE_SAVE_REQUEST]: experienceSaveRequest,
  [Types.EXPERIENCE_SAVE_SUCCESS]: experienceSaveSuccess,
  [Types.EXPERIENCE_SAVE_RESET]: experienceSaveReset,

  [Types.EXPERIENCE_DELETE_REQUEST]: experienceDeleteRequest,
  [Types.EXPERIENCE_DELETE_SUCCESS]: experienceDeleteSuccess,
  [Types.EXPERIENCE_DELETE_RESET]: experienceDeleteReset,

  [Types.EXPERIENCE_JOBS_REQUEST]: experienceJobsRequest,
  [Types.EXPERIENCE_JOBS_SUCCESS]: experienceJobsSuccess,

  [Types.EXPERIENCE_JOB_SAVE_REQUEST]: experienceJobSaveRequest,
  [Types.EXPERIENCE_JOB_SAVE_SUCCESS]: experienceJobSaveSuccess,
  [Types.EXPERIENCE_JOB_SAVE_RESET]: experienceJobSaveReset,

  [Types.EXPERIENCE_JOB_DELETE_REQUEST]: experienceJobDeleteRequest,
  [Types.EXPERIENCE_JOB_DELETE_SUCCESS]: experienceJobDeleteSuccess,
  [Types.EXPERIENCE_JOB_DELETE_RESET]: experienceJobDeleteReset,

  [Types.EXPERIENCE_RESET]: experienceReset,

  [Types.EXPERIENCE_FETCHING_RESET]: experienceFetchingReset,
})
