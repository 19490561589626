import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Input, Select, Row, Col, Spin, message } from 'antd'

import MailTemplatesActions from '../../Redux/MailTemplatesRedux'

const { Option } = Select;
const { TextArea } = Input;

function MailTemplatesPage(props) {
  const { templates, result, fetching } = props;

  const [active, setActive] = useState(null);
  const [cursorPos, setCursorPos] = useState(null);
  const [value, setValue] = useState(null);
  const [variableList, setVariableList] = useState(null);

  const templateList = [];
  if (templates && templates.results) {
    for (let i = 0; i < templates.results.length; i++) {
      templateList.push(<Option key={templates.results[i].id}>{templates.results[i].name}</Option>);
    }
  }

  useEffect(() => {
    props.getTemplates();
  }, [0]);

  useEffect(() => {
    if (result) {
      message.success('Template successfully updated', 3);
      setActive(result);
    }
  }, [result]);

  function getCursorPosition(e) {
    let content = document.getElementById('template_form_description');
    if ((content.selectionStart !== null) && (content.selectionStart !== undefined)) {
      setCursorPos(content.selectionStart);
    }
  }

  function onChange(e) {
    getCursorPosition(e);
    setValue(e.target.value);
  }

  function pastVariable(variable) {
    let start = value.slice(0, cursorPos);
    let end = value.slice(cursorPos);
    setValue(start + variable + end);
  }

  function changeTemplate(value) {
    const item = templates.results.find(el => el.id === +value);
    const variables = item.variables.map(variable => ({
      id: variable.id,
      text: variable.name,
      value: `{${variable.id}}`
    }))
    setVariableList(variables);
    setValue(item.body);
    setActive(item);
  }

  function updateMailTemplate() {
    if (active.body !== value) {
      const data = {
        name: active.name,
        title: active.title,
        body: value
      };
      props.updateTemplate(active.id, data);
    }
  }
  return (
    <div className="main-container admin-container">
      <Spin spinning={fetching} size="large">
        <div className="templates-block">
          <div className="select-section">
            <Select
              style={{ width: '100%' }}
              placeholder="Select template"
              onChange={changeTemplate}
            >
              {templateList}
            </Select>
          </div>
          <div className="content-section">
            {
              active ?
                <>
                  <div className="template-variable-title" style={{ marginBottom: 16 }}>You can add next variables</div>
                  {
                    variableList.map(variable => (
                      <Row gutter={[16, 8]} style={{ marginTop: 0, marginBottom: 0 }} key={variable.id}>
                        <Col span={12}>
                          <span className="variable-text">{variable.text}</span>
                        </Col>
                        <Col span={12}>
                          <span className="variable-value" onClick={() => pastVariable(variable.value)}>{variable.value}</span>
                        </Col>
                      </Row>
                    ))
                  }
                  <TextArea
                    id="template_form_description"
                    rows={10}
                    value={value}
                    onClick={getCursorPosition}
                    onChange={onChange}
                  />
                  <Button type="primary" className="btn-primary" onClick={updateMailTemplate}>
                    Save
                </Button>
                </>
                :
                <div className="content-section-message">Select a template to edit</div>
            }
          </div>
        </div>
      </Spin>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    templates: state.mailTemplates.list,
    result: state.mailTemplates.saveResult,
    fetching: state.mailTemplates.saveFetching

  }
}

const mapDispatchToProps = (dispatch) => ({
  getTemplates: () => dispatch(MailTemplatesActions.mailTemplatesRequest()),
  updateTemplate: (templateId, data) => dispatch(MailTemplatesActions.mailTemplateSaveRequest(templateId, data)),

})

export default connect(mapStateToProps, mapDispatchToProps)(MailTemplatesPage)
