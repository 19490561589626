import React from 'react'
import { Form, Input, Button } from 'antd'

function ChangePasswordPopupForm(props) {
  const [form] = Form.useForm();

  const onFinish = values => {
    const data = { ...values }
    delete data.confirm;
    props.onSubmit(data);
  };

  function compareToFirstPassword(rule, value) {
    if (value && value !== form.getFieldValue('new_password')) {
      return Promise.reject("Passwords don't match");
    } else {
      return Promise.resolve();
    }
  };

  return (
    <Form form={form} onFinish={onFinish} className="login-form" layout="vertical">

      <Form.Item label="Current password"
        name="old_password"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: 'Field is required' },
          { pattern: /^[a-zA-Z0-9!@#$]+$/, message: 'Invalid character' },
          { min: 8, message: 'Field must contain at least 8 characters' },
        ]}
      >
        <Input.Password
          type="password"
        />
      </Form.Item>

      <Form.Item label="New password"
        name="new_password"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: 'Field is required' },
          { pattern: /^[a-zA-Z0-9!@#$]+$/, message: 'Invalid character' },
          { min: 8, message: 'Field must contain at least 8 characters' },
        ]}
      >
        <Input.Password
          type="password"
        />
      </Form.Item>

      <Form.Item label="Confirm password"
        dependencies={['new_password']}
        name="confirm"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: 'Field is required' },
          { pattern: /^[a-zA-Z0-9!@#$]+$/, message: 'Invalid character' },
          { min: 8, message: 'Field must contain at least 8 characters' },
          { validator: compareToFirstPassword }
        ]}
      >
        <Input.Password
          type="password"
        />
      </Form.Item>

      <Button type="primary" htmlType="submit" className="btn-primary" style={{width: 217, marginTop: 24}}>
        Submit
      </Button>

    </Form>
  )
}

export default ChangePasswordPopupForm
