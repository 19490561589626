import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

import MandatesActions from '../../Redux/MandatesRedux'

import PopupWrapper from './PopupWrapper';

import Images from '../../Images'
import './PopupPage.less'

function CloseOutMandateAdvisorPopupPage(props) {

  const { match, mandateResult, location } = props;
  const { tab, mandateShareId } = match.params;
  const { state } = location;

  const history = useHistory();

  useEffect(() => {
    if (mandateResult) {
      history.push('/home/mandates');
    }
	}, [mandateResult])

	const handleClose = () => {
    props.notificationViewedRequest(state?.notification)
	}

  return (
    <PopupWrapper
      width={460}
      // path={`/home/mandate-share-info/${mandateShareId}/${tab}`}
    >
        <div className="dialog-content" style={{ padding: '40px 24px' }}>
            <img src={Images.phoneCancel} alt="" />
            <div className="dialog-title deal">Mandate closed out</div>
            <div className="deal-text" style={{ padding: '0 10px' }}>
            The Investor closed out this Mandate. Any deal that you have submitted in response to this Mandate that have not entered yet the "Engaged" status" will be automatically rejected. Also, the Mandate will be moved from "Your Mandates" tab into the "Closed Mandate" tab.
            </div>
            <div className="btn-footer" style={{ marginTop: 20 }}>
            <Button type="primary white" className="btn-primary" onClick={handleClose}>
                Continue
            </Button>
            </div>
        </div>
    </PopupWrapper>
  )
}

const mapStateToProps = state => {
	return {
    mandateResult: state.mandates.mandateResult,
	}
}

const mapDispatchToProps = dispatch => ({
  notificationViewedRequest: (notificationId) =>
		dispatch(MandatesActions.notificationMandateStatusRequest(notificationId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CloseOutMandateAdvisorPopupPage)