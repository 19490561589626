import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table, Switch  } from 'antd'
import moment from 'moment'

import PaginationItemRender from '../PaginationItemRender'
import { TitleWithSortFilters } from '../TitleWithSortFilters'
import CustomIcon from '../../Icon'

import './Table.less'

function ColleaguesTable(props){
  const {
    page,
    pageSize,
    data,
    fetching
  } = props;

  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    let arr = (data && data.results) ? data.results.asMutable({deep:true}) : [];
    arr = arr.map(el => ({
      name: `${el.name} ${el.surname}`,
      role: el.role_obj?.name,
      created: el.user?.created,
    }))
    setDataSource(arr);
  }, [data]);

  function getColumns() {
    return [
      {
        title: (stateData)=>TitleWithSortFilters("Full name", true, false, stateData, 'name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        ellipsis: true,
      },
      {
        title: (stateData)=>TitleWithSortFilters("Status", true, false, stateData, 'role'),
        dataIndex: 'role',
        key: 'role',
        sorter:true,
        ellipsis: true,
      },
      {
        title: (stateData)=>TitleWithSortFilters("Joined on", true, false, stateData, 'created'),
        dataIndex: 'created',
        key: 'created',
        sorter: true,
        ellipsis: true,
        render: (text, record) => {
          return (
            <span>{text && moment(text).format('DD/MM/YYYY')}</span>
          )
        },
      },
    ];
  }

  function handleTableChange(pagination, filters, sorter){

    var sort_field = null;
    var sort_order = null;

    if(sorter && sorter.field && sorter.order){
      sort_field = sorter.field;
      sort_order = sorter.order //=== "ascend" ? 1 : 0;
    }

    props.onChangePage(pagination.current, pagination.pageSize, sort_field, sort_order)

  }

  const totalCount = data ? data.count : 0;

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount, showSizeChanger: true}}
      className="main-table"
      rowKey={row => row.created}
      rowClassName="process-table-row"
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
      showSorterTooltip={false}
    />
   )


}

export default ColleaguesTable
