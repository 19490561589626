import React from 'react';
import { Link } from 'react-router-dom'

function MainFooter(props) {

  return (
    <div className="Screen9 P1-S9 X1023">
      <Link to="/" className="idn-img"></Link>
      <div className="foot-lists">
        <ul className="foot-ul">
          <li className="foot-li foot-li-head">Clients</li>
          <li className="foot-li opacity6"><Link className="footA" to="/investors">Investors</Link></li>
          <li className="foot-li opacity6"><Link className="footA" to="/advisors">Senior Advisors</Link></li>
        </ul>
        <ul className="foot-ul">
          <li className="foot-li foot-li-head">Sectors</li>
          <li className="foot-li opacity6"><Link className="footA" to="/sectors">Sectors</Link></li>
        </ul>
        <ul className="foot-ul">
          <li className="foot-li foot-li-head">About</li>
          <li className="foot-li opacity6"><Link className="footA" to="/about">About Us</Link></li>
          <li className="foot-li opacity6"><Link className="footA" to="/privacy-policy">Privacy policy</Link></li>
          <li className="foot-li opacity6"><Link className="footA" to="/cookies-policy">Cookies Policy</Link></li>
          <li className="foot-li opacity6"><Link className="footA" to="/terms-of-use">Terms of Use</Link></li>
        </ul>
        <ul className="foot-ul">
          <li className="foot-li foot-li-head">Contact</li>
          <li className="foot-li opacity6"><a className="footA"
            href="mailto:info@idn.ltd">info@idn.ltd</a></li>
        </ul>
      </div>
    </div>
  )
}

export default MainFooter
