import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Tooltip } from 'antd'
import Utils from '../Utils/utils';

import Loader from './img/Loader'
import PaginationPrev from './img/PaginationPrev'
import PaginationNext from './img/PaginationNext'

const __TYPES__ = {
  Loader,
  PaginationPrev,
  PaginationNext,
};


class Icon extends PureComponent {
  // constructor(props, context) {
  //   super(props, context);
  // }

  render() {
    const { type } = this.props;
    const IconComp = __TYPES__[Utils.toPascalCase(type)];
    let {
      id,
      onClick,
      color,
      opacity,
      role,
      btnType,
      titleText,
      className
    } = this.props;

    if (role === "button") {
      if (!btnType) btnType = "";

      return (
        <Tooltip
          placement="right"
          title={titleText}>
          <a id={id} onClick={onClick} className={"icon-btn" + " " + btnType}>
            <i>
              {IconComp ? (
                <IconComp
                  className="icon"
                  opacity={opacity}
                  color={color}
                />
              ) : (
                  ''
                )}
            </i>
          </a>
        </Tooltip>
      );
    } else {
      return (
        <i
          id={id}
          onClick={onClick}

        >
          {IconComp ? (
            <IconComp
              className={"icon" + className ? " "+className : ""}
              onClick={onClick}
              opacity={opacity}
              color={color}
            />
          ) : (
              ''
            )}
        </i>
      );
    }
  }
}

Icon.defaultProps = {
  onClick: () => {
  },
};

Icon.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
};

export default Icon;
