import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Tooltip, Button } from 'antd'
import moment from 'moment'

import PaginationItemRender from '../PaginationItemRender'
import CustomIcon from '../../Icon'
import Images from '../../Images'

import './Table.less'

function MandatesAdvisorTable(props){
  const {
    page,
    pageSize,
    data,
    fetching,
    tab,
    allMandate
  } = props;

  const [dataSource, setDataSource] = useState(null);

  const history = useHistory();

  useEffect(() => {
    let arr = (data && data.results) ? data.results.asMutable({deep:true}) : [];
    if (allMandate) {
      arr = arr.map(el => ({
        mandate_id: el.id,
        issued_on: el.shared,
        status_name: el.status_obj?.name,
        subsector: el.subsector,
        geography: el.geography,
        eq_ticket: el.eq_ticket,
        status_btn: el.status_obj.id,
        isNotification: el.advisor_notification,
      }))

    } else {
      arr = arr.map(el => ({
        mandateShare_id: el.id,
        mandate_id: el.mandate.id,
        issued_on: el.received,
        status_name: el.advisor_status_obj?.name,
        deals_shared: el.deals_shared,
        subsector: el.mandate.subsector,
        geography: el.mandate.geography,
        eq_ticket: el.mandate.eq_ticket,
        status_btn: el.advisor_status_obj?.id,
        isNotification: el.advisor_notification,
        //dashboardId: el.dashboard
      }))
    }
    setDataSource(arr);
  }, [data]);

  function getColumns() {
    return [
      {
        title: 'Mandate ID',
        dataIndex: 'mandate_id',
        key: 'mandate_id',
        className: 'cell-center',
      },
      {
        title: 'Issued on',
        dataIndex: 'issued_on',
        key: 'issued_on',
        className: 'cell-center',
        render: (text, record) => {
          return (
              <span>{text && moment(text).format('DD/MM/YYYY')}</span>
          )
        },
      },
      {
        title: 'Status',
        dataIndex: 'status_name',
        key: 'status_name',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <div className={`deal-status${record.isNotification ? ' orange' : ''}`}>{text}</div>
          )
        },
      },
      {
        title: 'Sector',
        dataIndex: 'subsector',
        key: 'subsector',
        render: (text, record) => {
          return (
            text?.map((el, i) => (
              <div key={i}>{el}</div>
            ))
          )
        },
      },
      {
        title: 'Geography',
        dataIndex: 'geography',
        key: 'geography',
        render: (text, record) => {
          return (
            text?.map((el, i) => (
              <div key={i}>{el}</div>
            ))
          )
        },
      },
      {
        title: 'Deals shared',
        dataIndex: 'deals_shared',
        key: 'deals_shared',
        className: allMandate ? 'hidden-column' : 'cell-center',
        render: (text, record) => {
          return (
            <div className={`deal-status mandate`}>{text}</div>
          )
        },
      },
      {
        title: 'Equity ticket',
        dataIndex: 'eq_ticket',
        key: 'eq_ticket',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <span style={{whiteSpace: 'nowrap'}}>{text ? `${text}ml` : 'N/A'}</span>
          )
        },
      },
      {
        title: '',
        dataIndex: 'status_btn',
        key: 'status_btn',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <div className="deal-action">
              {
                record.isNotification &&
                  <img src={Images.notification} alt="" />
              }
              <Button
              type="primary" className="btn-primary"
              onClick={()=>generateBtnAction(record)}
              >
                View
              </Button>
            </div>
          )
        },
      }
    ];
  }

  function handleTableChange(pagination) {
    props.onChangePage(pagination.current, pagination.pageSize)
  }

  const totalCount = data ? data.count : 0;

  const generateBtnAction = record => {
    if (record.isNotification) {
      props.deleteNotification(allMandate ? record.mandate_id : record.mandateShare_id);
    } else {
      allMandate ?
        history.push({
          pathname: `/home/mandate-share-info-all/${record.mandate_id}`,
          state: {tab}
        })
      :
        history.push(`/home/mandate-share-info/${record.mandateShare_id}/summary`)
    }
  }

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender: PaginationItemRender, current: page, pageSize: pageSize, total: totalCount, showSizeChanger: true}}
      className="deal-table"
      rowKey={row => row.mandate_id}
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
      showSorterTooltip={false}
    />
   )
}

export default MandatesAdvisorTable
