import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Spin } from 'antd'

import AuthActions from '../Redux/AuthRedux'

function InvestorLoginCheckPage (props) {
  const { match } = props;
  const { token } = match.params;

  useEffect(()=>{
    props.investorLogin(token);
  }, [0]);

  return (
    <Spin spinning={true} size="large">
      <div className="page-full-wrapper"/>
    </Spin>
  )
}

const mapDispatchToProps = (dispatch) => ({
  investorLogin: (token) => dispatch(AuthActions.investorLoginRequest(token)),
})

export default connect(null, mapDispatchToProps)(InvestorLoginCheckPage)
