import React from 'react'

import MainHeader from './MainHeader.js'
import MainFooter from './MainFooter.js'
import Terms from '../../Components/Terms'

function TermsOfUsePage(props) {

  return (
    <div className="page5 twofixed terms">
      <MainHeader />
        <Terms />
      <MainFooter />
    </div>
  )
}

export default TermsOfUsePage;
