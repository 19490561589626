import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import moment from 'moment'

import PaginationItemRender from '../PaginationItemRender'
import CustomIcon from '../../Icon'

import './Table.less'

const closedStatuses = ['terminated', 'rejected', 'DNC'];
const inProgressStatuses = ['shared', 'matched'];

function ShareAdvisorsTable(props){
  const {
    page,
    pageSize,
    data,
    isReshare,
    fetching
  } = props;

  const [dataSource, setDataSource] = useState(null);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const filterData = selectedRows.filter(row => (!closedStatuses.includes(row.status) && !((row.status === 'shared' || row.status === 'matched')))).map(el => el.id);
      props.setAdvisors(filterData);
    },
    renderCell: (checked, record, index, originNode) => {
      if (record.status === 'shared') {
        return null;
      } else {
        return originNode;
      }
    },
    hideSelectAll: isReshare
  };

  useEffect(() => {
    let arr = (data && data.results) ? data.results.asMutable({deep:true}) : [];
    arr = arr.map(el => ({
      id: el.id,
      name: el.name,
      //invest_company: el.invest_company?.name,
      account_manager: el.account_manager?.name,
      status: el.status,
      shared: el.shared_on,
      //firmId: el.invest_company?.id
    }))
    setDataSource(arr);
  }, [data]);

  function getColumns() {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => {
          return (
            <Link to={'/home/advisor-details/' + record.id}>
              {text}
            </Link>
          )
        },
      },
      // {
      //   title: 'Firm',
      //   dataIndex: 'invest_company',
      //   key: 'invest_company',
      //   render: (text, record) => {
      //     return (
      //       <Link to={'/home/firm-details/' + record.firmId}>{text}</Link>
      //     )
      //   },
      // },
      {
        title: 'Account Manager',
        dataIndex: 'account_manager',
        key: 'account_manager',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Shared on',
        dataIndex: 'shared',
        key: 'shared',
        render: (text, record) => {
          return (
              <span>{text && moment(text).format('DD/MM/YYYY')}</span>
          )
        },
      }
    ];
  }

  function handleTableChange(pagination, filters, sorter){

    var sort_field = null;
    var sort_order = null;

    if(sorter && sorter.field && sorter.order){
      sort_field = sorter.field;
      sort_order = sorter.order //=== "ascend" ? 1 : 0;
    }

    props.onChangePage(pagination.current, pagination.pageSize, sort_field, sort_order)

  }

  const totalCount = data ? data.count : 0;

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount, showSizeChanger: true}}
      className="main-table share"
      rowKey={row => row.id}
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
      showSorterTooltip={false}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      rowClassName={row => {
        switch(row?.status) {
          case 'contact investor': return 'bold-row';
          case 'DNC': return 'red-bold-row';
          case 'matched': return 'green-row';
          case 'rejected':
          case 'terminated': return 'red-row';
          default: return '';
        }
      }}
    />
   )


}

export default ShareAdvisorsTable
