import React from 'react'

import PopupWrapper from './PopupWrapper'
import './PopupPage.less'

function ConfirmingSubmissionPopupPage(props) {
  return (
    <PopupWrapper
      width={420}
      path={`/home/deals`}
    >
      <div className="dialog-content" style={{ padding: '40px 56px' }}>
          <div className="dialog-container" style={{ width: 420 }}>
            <div className="dialog-content" style={{ padding: '40px 30px' }}>
              <div className="dialog-title deal">Confirming Submission</div>
              <div className="deal-text" >
              In order to submit an investment opportunity  in response to this mandate request, you have to review carefully the confirmation email we have just sent you, and click on the link contained therein in order to progress.
              </div>
              <br />
              <div className="btn-footer" style={{marginTop: 20}}>
              </div>
            </div>
          </div>
      </div>
    </PopupWrapper>
  )
}

export default ConfirmingSubmissionPopupPage
