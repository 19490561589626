import React, { useState, useEffect, Fragment } from 'react'
import { Button, Input, Space, Row, Col, Spin } from 'antd'
import { useHistory, Link } from 'react-router-dom'
import _ from 'lodash'

import Images from '../../Images'

const { TextArea } = Input;

function MandateDealSharingReview(props) {
  const { dealId, dealData, isSharing, styleObj, disableSought, role, disableTargetInfo } = props;

  const [data, setData] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (dealData) {
      const deal = _.cloneDeep(dealData);
      const newData = {
        sectors: deal.industry_focus_sub_sectors.map(el => ({
          name: el.name,
          add_spec: el.additional_specification,
          subsectors: el.children
        })),
        geography: deal.geography.map(el =>
          el.regions.map(child => ({
            id: child.id,
            name: child.name
          }))
        ).flat(),
        investment_focus: deal.investment_focus,
        comment: deal.comment,
        investment_overview: deal.investment_overview,
        advisor_role: deal.advisor_role,
        investor_sought: deal.investor_sought,
        target_name: deal.target_information?.target_name,
        target_url: deal.target_information?.target_information_url,
      }
      setData(newData);
    }
  }, [dealData]);

  return (
    data &&
    <>
      <div className="review-block" style={styleObj}>
        <div className="review-title">Deal Information</div>
        <div className="review-info-block">
          <div className="review-info-left">
            <div className="review-subblock">
              <span><img src={Images.puzzle} alt="" /></span>
              {
                data.sectors?.map(sector => (
                  <div key={sector.name}>
                    <div className="sector-title">
                      <div className="sector-title-name">Sector:</div>
                      <div><span>{sector.name}</span></div>
                    </div>
                    <div className="sector-title dealSharingReview">
                      <div className="sector-title-name">Sub-sector:</div>
                      <div>
                        <span>{sector.subsectors.map(el => el.name).join(', ')}</span>
                      </div>
                    </div>
                    {
                      sector.add_spec &&
                      <div className="add-spec-block">
                        <div className="sector-add-spec">Additional specifications</div>
                        <div className="sector-add-spec-value">{sector.add_spec}</div>
                      </div>
                    }
                  </div>
                ))
              }

            </div>
            <div className="review-subblock">
              <span><img src={Images.globe} alt="" /></span>
              <div className="sector-title">
                <div className="sector-title-name">Geography:</div>
                <div>
                  <span>{data.geography.map(el => el.name).join(', ')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="review-info-right">
            <div className="review-subblock">
              <span><img src={Images.coins} alt="" /></span>
              <div className="sector-title">
                <div>Financial Information</div>
              </div>
              <div className="sector-subtitle">
                <div className="subtitle-range-name">Estimated Enterprise Value</div>
                {
                  data.investment_focus?.enterprise_value_from == null ?
                    <div className="subtitle-na">N/A</div>
                  :
                    <>
                      <div className="subtitle-range-value">{data.investment_focus?.enterprise_value_from}</div>
                      <div className="subtitle-range-value">{data.investment_focus?.enterprise_value_to}</div>
                    </>
                }
                <div className="subtitle-range-end">Range (£)</div>
              </div>
              <div className="sector-subtitle">
                <div className="subtitle-range-name">Equity Ticket</div>
                {
                  data.investment_focus?.equity_ticket_from == null ?
                    <div className="subtitle-na">N/A</div>
                  :
                    <>
                      <div className="subtitle-range-value">{data.investment_focus?.equity_ticket_from}</div>
                      <div className="subtitle-range-value">{data.investment_focus?.equity_ticket_to}</div>
                    </>
                }
                <div className="subtitle-range-end">Range (£)</div>
              </div>
              <div className="sector-subtitle">
                <div className="subtitle-range-name">Equity Stake on sale</div>
                {
                  data.investment_focus?.equity_stake_from == null ?
                    <div className="subtitle-na">N/A</div>
                  :
                    <>
                      <div className="subtitle-range-value">{data.investment_focus?.equity_stake_from}</div>
                      <div className="subtitle-range-value">{data.investment_focus?.equity_stake_to}</div>
                    </>
                }
                <div className="subtitle-range-end">Range (%)</div>
              </div>
              <div className="sector-subtitle start">
                <div className="subtitle-range-name">Governance of the equity</div>
                <div>
                  <span>{data?.investment_focus?.governace_of_the_targeted_equity.map(el => el.name).join(', ')}</span>
                  {
                    data?.investment_focus?.specified_governace &&
                      data?.investment_focus?.governace_of_the_targeted_equity?.length ?
                        <span>{`, ${data?.investment_focus?.specified_governace}`}</span>
                      :
                        <span>{data?.investment_focus?.specified_governace}</span>
                  }
                </div>
              </div>
              <div className="sector-subtitle start">
                <div className="subtitle-range-name">Type of Investment</div>
                <div>
                  <span>{data?.investment_focus?.investment_type.filter(el => el.id !== 3).map(el => el.name).join(', ')}</span>
                  {
                    data?.investment_focus?.comment &&
                      data?.investment_focus?.investment_type?.length > 1 ?
                        <span>{`, ${data?.investment_focus?.comment}`}</span>
                      :
                        <span>{data?.investment_focus?.comment}</span>
                  }
                </div>
              </div>
              {
                data?.comment &&
                <>
                  <div className="sector-add-spec" style={{ marginTop: 24 }}>Comments</div>
                  <div className="sector-add-spec-value">{data.comment}</div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
      {!disableTargetInfo ?
        role === 0 || role === 1 
        ?
        <div className="review-block" style={styleObj}>
          <div className="review-title">Target Information</div>
            <Row gutter={[24, 24]}>
            <Col span={8}>
              <div className="overview-title">
              <span><img src={Images.target1} alt="" /></span>
                <div>Target name</div>
              </div>
              <div
                className="area-text mandate-deal"
              >
                <TextArea
                  value={data?.target_name}
                  disabled={true}
                  autoSize={true}
                />
              </div>
            </Col>
            <Col span={16}>
              <div className="overview-title">
              <span><img src={Images.url} alt="" /></span>
                <div>Target url</div>
              </div>
              <div className="area-text">
                <TextArea
                  value={data?.target_url}
                  disabled={true}
                  autoSize={true}
                />
              </div>
            </Col>
            </Row>        
          </div>
          : 
          null
          : null
        }

      <div className="review-block" style={styleObj}>
        <div className="review-title">Investment Overview</div>
        {(role === 1) && 
          <>
            <div className="overview-title">
              <span><img src={Images.briefcase} alt="" /></span>
              <div>Deal Overview</div>
            </div>
            <div className="area-text">
              <TextArea
                value={data.investment_overview?.deal_overview}
                disabled={true}
                autoSize={true}
              />
            </div>
          </>
        }
        <div className="overview-title">
          <span><img src={Images.company} alt="" /></span>
          <div>Company Overview</div>
        </div>
        <div className="area-text">
          <TextArea
            value={data.investment_overview?.company_overview}
            disabled={true}
            autoSize={true}
          />
        </div>
        <div className="overview-title">
          <span><img src={Images.coins} alt="" /></span>
          <div>Transaction background & rationale</div>
        </div>
        <div className="area-text">
          <TextArea
            value={data.investment_overview?.transaction}
            disabled={true}
            autoSize={true}
          />
        </div>
        <div className="overview-title">
          <span><img src={Images.clock} alt="" /></span>
          <div>Expected Timeline</div>
        </div>
        <div className="area-text">
          <TextArea
            value={data.investment_overview?.timeline}
            disabled={true}
            autoSize={true}
          />
        </div>
      </div>

      <div className="review-block" style={styleObj}>
        <div className="review-title">Advisor role in the deal</div>
        <div className="overview-title">
          <span><img src={Images.certificate} alt="" /></span>
          <div>Relationship with Target Company:</div>
          <div className="overview-value">{data.advisor_role?.relationship_obj?.name}</div>
        </div>
        <div className="area-text">
          <TextArea
            value={data.advisor_role?.relationship_note}
            disabled={true}
            autoSize={true}
          />
        </div>
        <div className="overview-title">
          <span><img src={Images.userEdit} alt="" /></span>
          <div>Advisor Role:</div>
          <div className="overview-value">{data.advisor_role?.role_obj?.name}</div>
        </div>
        <div className="area-text">
          <TextArea
            value={data.advisor_role?.role_note}
            disabled={true}
            autoSize={true}
          />
        </div>
      </div>
      {/* {
        !disableSought &&
        <div className="review-block" style={styleObj}>
          <div className="review-title">Investor Sought</div>
          <div className="overview-title">
            <span><img src={Images.check} alt="" /></span>
            <div>Preferred Investor's Criteria</div>
          </div>
          <div className="area-text">
            <TextArea
              value={dealData?.investor_sought?.criteria}
              disabled={true}
              autoSize={true}
            />
          </div>
          <div className="overview-title">
            <span><img src={Images.crossOutAddressBook} alt="" /></span>
            <div>Do Not Contact Investors (DNC):</div>
          </div>

          <div className="overview-text">
            {
              data.investor_sought?.dnc_investors?.map((el, i) => (
                <Row key={el.id}>
                  <Col span={8}>
                    <div className="dnc-user">
                      <img src={Images.user} alt="" />
                      <span>{`DNC Investor ${i + 1}`}</span>
                    </div>
                  </Col>
                  <Col span={16}>
                    <Row gutter={[24, 24]}>
                      <Col span={12}>
                        <div className="dnc-input">{el.firm_name}</div>
                      </Col>
                      <Col span={12}>
                        <div className="dnc-input">{el.website}</div>
                      </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                      <Col span={24}>
                        <div className="dnc-input area">{el.comment}</div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))
            }
          </div>

          <div className="overview-title">
            <span><img src={Images.addressBook} alt="" /></span>
            <div>Contact Investors:</div>
          </div>
          <div className="overview-text">
            {
              data.investor_sought?.specific_investors?.map((el, i) => (
                <Row key={el.id}>
                  <Col span={8}>
                    <div className="dnc-user">
                      <img src={Images.user} alt="" />
                      <span>{`Contact Investor ${i + 1}`}</span>
                    </div>
                  </Col>
                  <Col span={16}>
                    <Row gutter={[24, 24]}>
                      <Col span={12}>
                        <div className="dnc-input">{el.firm_name}</div>
                      </Col>
                      <Col span={12}>
                        <div className="dnc-input">{el.website}</div>
                      </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                      <Col span={12}>
                        <div className="dnc-input">{el.person_name}</div>
                      </Col>
                      <Col span={5}>
                        <div className="dnc-input">{el.job}</div>
                      </Col>
                      <Col span={7}>
                        <div className="dnc-input">{el.email}</div>
                      </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                      <Col span={24}>
                        <div className="dnc-input area">{el.message}</div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))
            }
          </div>
          <div className="overview-title">
            <span><img src={Images.question} alt="" /></span>
            <div>Investors’ Screening Questionnaire</div>
          </div>
          <div className="overview-text question-text">
            {
              data.investor_sought?.questions?.map(el => (
                <div key={el.id} className="sector-add-spec-value question-value">{el.text}</div>
              ))
            }
          </div>
        </div>
      } */}
      {
        isSharing &&
        <>
          <div className="btn-footer invite" style={{ marginTop: 58 }}>
            <Button type="primary white" className="btn-primary" style={{ width: 217, margin: '0 32px' }} 
              onClick={() => history.push({
                pathname: `/home/mandate-deal-sharing-edit/${dealId}`,
                state: {
                  backPath: `/home/mandate-deal-sharing/step/review`,
                  dealId
                }
              }
              )}
            >
              Edit Deal
              </Button>
            <Button type="primary" className="btn-primary" style={{ width: 217, margin: '0 32px' }} onClick={props.onChangeStep}>
              Continue
              </Button>
          </div>

          <Space className="return-link" style={{ marginTop: 32 }}>
            <Link to="/home/deals">
              <img src={Images.arrowLeft} alt="" />
              <span>Return to main dashboard</span>
            </Link>
          </Space>
        </>
      }
    </>
  )
}

export default MandateDealSharingReview
