import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Button } from 'antd'
import moment from 'moment'

import PaginationItemRender from '../PaginationItemRender'
import CustomIcon from '../../Icon'
import Images from '../../Images'

import './Table.less'

function FirmMandatesTable(props){
  const {
    page,
    pageSize,
    data,
    fetching,
    userId
  } = props;

  const [dataSource, setDataSource] = useState(null);

  const history = useHistory();

  useEffect(() => {
    let arr = (data && data.results) ? data.results.asMutable({deep:true}) : [];
    arr = arr.map(el => ({
      id: el.id,
      deals_received: el.deals_received,
      shared_on: el.shared,
      subsector: el.subsector,
      geography: el.geography,
      equity_ticket: el.eq_ticket,
      status: el.status_obj?.id,
      colleague: el.owner_obj?.name,
      isOwner: el.owner_obj?.id === userId
    }))
    setDataSource(arr);
  }, [data]);

  function getColumns() {
    return [
      {
        title: 'Mandate ID',
        dataIndex: 'id',
        key: 'id',
        className: 'cell-center',
      },
      {
        title: 'Colleague',
        dataIndex: 'colleague',
        key: 'colleague',
      },
      {
        title: 'Deals received',
        dataIndex: 'deals_received',
        key: 'deals_received',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <div className="deal-status">{text}</div>
          )
        },
      },
      {
        title: 'Shared on',
        dataIndex: 'shared_on',
        key: 'shared_on',
        className: 'cell-center',
        render: (text, record) => {
          return (
              <span>{text && moment(text).format('DD/MM/YYYY')}</span>
          )
        },
      },
      {
        title: 'Sector',
        dataIndex: 'subsector',
        key: 'subsector',
        render: (text, record) => {
          return (
            text?.map((el, i) => (
              <div key={i}>{el}</div>
            ))
          )
        },
      },
      {
        title: 'Geography',
        dataIndex: 'geography',
        key: 'geography',
        render: (text, record) => {
          return (
            text?.map((el, i) => (
              <div key={i}>{el}</div>
            ))
          )
        },
      },
      {
        title: 'Equity ticket',
        dataIndex: 'equity_ticket',
        key: 'equity_ticket',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <span style={{whiteSpace: 'nowrap'}}>{text ? `${text}ml` : 'N/A'}</span>
          )
        },
      },
      {
        title: '',
        dataIndex: 'status',
        key: 'status',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <div className="deal-action">
              {
                record.isNotification &&
                  <img src={Images.notification} alt="" />
              }
              <Button type="primary" className="btn-primary" onClick={()=>generateBtnAction(record)}>
                View
              </Button>
            </div>
          )
        },
      }
    ];
  }

  function handleTableChange(pagination) {
    props.onChangePage(pagination.current, pagination.pageSize)
  }

  const totalCount = data ? data.count : 0;

  const generateBtnAction = record => {
    history.push({
      pathname: `/home/mandate/${record.id}`,
      state: {
        hideAll: true,
        isOwnMandate: record.isOwner,
        fromActivities: true
      }
    });
  }

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount, showSizeChanger: true}}
      className="deal-table"
      rowKey={row => row.id}
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
      showSorterTooltip={false}
    />
   )
}

export default FirmMandatesTable
