import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function CompetitiveProcessLaunchedInvestorPopupPage(props) {
	const { result, fetching, match, isAdmin, location } = props;
	const { dashboardId } = match.params;
	const { state } = location;

	const history = useHistory()

	useEffect(() => {
		props.dealDashboardSyncToggle(false);
		return function cleanup() {
			props.dealDashboardSyncToggle(true);
		}
	}, [0]);

	useEffect(() => {
		if (result) {
			props.getDealDashboardInfo(dashboardId);
			isAdmin ?
				history.push(`/home/match-company/${dashboardId}`)
				:
				history.push(`/deal-dashboard/overview/${dashboardId}`);
		}
	}, [result]);

	const onSubmit = () => {
		if (state?.isHistory) {
			history.push(`/deal-dashboard/overview/${dashboardId}`);
		} else {
			props.changeDealToPublicByInvestor(dashboardId)
		}
	}

	return (
		<PopupWrapper
			width={420}
			path={isAdmin ?
				`/home/match-company/${dashboardId}`
				:
				`/deal-dashboard/overview/${dashboardId}`
			}
		>
			<Spin spinning={fetching} size="large">
				<div className='dialog-content' style={{ padding: '32px 40px' }}>
					<img src={Images.phoneOk} alt='' />
					<div className='dialog-title welcome' style={{ marginTop: 10, marginBottom: 0, borderBottom: 'none' }}>
						Competitive process
				</div>
					<div className='dialog-title welcome' style={{ marginBottom: 15 }}>
						launched
				</div>
					<div className='deal-text'>By clicking “Confirm”, you formally notify IDN that a structured, competitive bidding process has been launched by the Target, and third parties investors have been formally invited to participate in it.</div>
					<br />
					<br />
        	<div className="deal-text sm" style={{width: '90%'}}>Please review IDN's Platform Agreement to remember your<br />rights and obligations in the context of this engagement</div>
					<div className='btn-footer'>
						<Button
							type='primary white'
							className='btn-primary welcome'
							style={{ width: 144 }}
							onClick={onSubmit}
						>
							{state?.isHistory ? 'Go back' : 'Confirm'}
						</Button>
					</div>
				</div>
			</Spin>
		</PopupWrapper>
	)
}

const mapStateToProps = (state) => {
	return {
		isAdmin: state.auth.isAdmin,
		result: state.dealDashboard.result,
		fetching: state.dealDashboard.fetching
	}
}

const mapDispatchToProps = (dispatch) => ({
	getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
	changeDealToPublicByInvestor: (dashboardId) => dispatch(DealDashboardActions.dealChangePublicRequest(dashboardId)),
	dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompetitiveProcessLaunchedInvestorPopupPage)
