import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Spin } from 'antd'

import AuthActions from '../Redux/AuthRedux'

function ConfirmEmailCheckPage (props) {
  const { match } = props;
  const { hash, role } = match.params;

  useEffect(()=>{
    props.checkConfirmEmailHash(hash, role);
  }, [0]);

  return (
    <Spin spinning={true} size="large">
      <div className="page-full-wrapper"/>
    </Spin>
  )
}

const mapDispatchToProps = (dispatch) => ({
  checkConfirmEmailHash: (hash, role) => dispatch(AuthActions.confirmEmailHashRequest(hash, role)),
})

export default connect(null, mapDispatchToProps)(ConfirmEmailCheckPage)
