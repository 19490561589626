import React, { useState, useEffect } from 'react'
import { Input, Tooltip } from 'antd'
import { connect } from 'react-redux'
// import {FileIcon, defaultStyles} from 'react-file-icon'

import SettingsActions from '../../Redux/SettingsRedux'

const { TextArea } = Input;

function InvestorProfilePage(props) {
  const { filters, match } = props;
  const { firmId } = match.params;

  const [data, setData] = useState(null);

  useEffect(() => {
    props.getFirmFilters(firmId);
  }, [0]);

  useEffect(() => {
    if (filters) {
      setData(filters);
    }
  }, [filters]);

  return (
    <div className="main-container" style={{paddingTop: 40}}>
      <div className="firm-profile-block match">
        {
          data &&
          <>
            <div className="section-title" style={{ alignSelf: 'self-start', marginTop: 0 }}>Profile</div>

            <div className="firm-profile-section-top inv-det">
              <div className="firm-profile-input" style={{ marginRight: 40 }}>
                <span>Firm name</span>
                <Input
                  defaultValue={data.name}
                  disabled={true}
                />
              </div>
              <div className="firm-profile-input">
                <span>Website</span>
                <Input
                  defaultValue={data.website}
                  disabled={true}
                />
              </div>
            </div>

            <div className="firm-profile-section-middle inv-det">
              <div className="firm-profile-title">
                <span>Firm Overview</span>
              </div>

              <div className="firm-profile-area">
                <TextArea
                  defaultValue={data.overview}
                  autoSize={true}
                  disabled={true}
                />
              </div>
              <div className="firm-profile-title">
                <span>Investment preferences</span>
              </div>
              <div className="firm-profile-area">
                <TextArea
                  defaultValue={data.investment_preferences}
                  autoSize={true}
                  disabled={true}
                />
              </div>
              <div className="firm-profile-title">
                <span>Asset Management Style</span>
              </div>
              <div className="firm-profile-area">
                <TextArea
                  defaultValue={data.management_style}
                  autoSize={true}
                  disabled={true}
                />
              </div>
              <div className="firm-profile-input-bottom row">
                <span>Portfolio companies</span>
                <div>
                  <Input
                    defaultValue={data.portfolio}
                    placeholder="Link to Website"
                    disabled={true}
                  />
                </div>
              </div>
              {/* <div className="firm-profile-input-bottom row">
                <span>Bilateral contract</span>
                <div>
                {filters?.bilateral_contract ? <div className='document-admin'>
                    <a href={filters?.bilateral_contract} target='_blank' rel='noreferrer'
                      style={{marginTop: 5, display: 'flex', alignItems: 'center', flexDirection: 'column'}}
                    >
                      <div className='document-admin-icon' >
                      <FileIcon
                        extension={'pdf'}
                        {...defaultStyles[`pdf`]}
                      />
                      </div>
                      <Tooltip
                        placement="bottom"
                        title={'Bilateral contract'}
                      >
                        <div className='document-admin-title'>Bilateral contract</div>
                      </Tooltip>
                    </a>
                  </div> : 'N/A'}
                </div>
              </div> */}
            </div>
          </>
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    filters: state.settings.firmFilters,

  }
}

const mapDispatchToProps = (dispatch) => ({
  getFirmFilters: (firmId) => dispatch(SettingsActions.firmFiltersRequest(firmId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvestorProfilePage)
