import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import moment from 'moment'

import PaginationItemRender from '../PaginationItemRender'
import CustomIcon from '../../Icon'

import './Table.less'

function MandateMatchesAdminTable(props){
  const {
    page,
    pageSize,
    data,
    fetching,
  } = props;

  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    let arr = (data && data.results) ? data.results.asMutable({deep:true}) : [];
    arr = arr.map(el => ({
      id: el.id,
      advisor: el.owner_obj?.name,
      account_manager: el.account_manager_obj?.name,
      status: el.admin_status_obj?.name,
      received: el.shared,
      subsector: el.subsector,
      geography: el.geography,
      eq_ticket: el.eq_ticket,
      status_id: el.admin_status_obj?.id,
      dashboardId: el.dashboard
    }))
    setDataSource(arr);
  }, [data]);

  function getColumns() {
    return [
      {
        title: 'Deal ID',
        dataIndex: 'id',
        key: 'id',
        render: (text, record) => {
          return (
              <Link to={
                record.dashboardId ?
                  `/home/match-company/${record.dashboardId}/deal_info`
                :
                  `/home/deal-details/${record.id}`
              }>
                {text}
              </Link>
          )
        },
      },
      {
        title: 'Senior Advisor',
        dataIndex: 'advisor',
        key: 'advisor',
      },
      {
        title: 'Account Manager',
        dataIndex: 'account_manager',
        key: 'account_manager',
      },
      {
        title: 'Shared on',
        dataIndex: 'received',
        key: 'received',
        render: (text, record) => {
          return (
              <span>{text && moment(text).format('DD/MM/YYYY')}</span>
          )
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Sector',
        dataIndex: 'subsector',
        key: 'subsector',
        render: (text, record) => {
          return (
            text?.map((el, i) => (
              <div key={i}>{el}</div>
            ))
          )
        },
      },
      {
        title: 'Geography',
        dataIndex: 'geography',
        key: 'geography',
        render: (text, record) => {
          return (
            text?.map((el, i) => (
              <div key={i}>{el}</div>
            ))
          )
        },
      },
      {
        title: 'Equity ticket',
        dataIndex: 'eq_ticket',
        key: 'eq_ticket',
        render: (text, record) => {
          return (
            <span style={{whiteSpace: 'nowrap'}}>{text ? `${text}ml` : 'N/A'}</span>
          )
        },
      }
    ];
  }

  function handleTableChange(pagination) {
    props.onChangePage(pagination.current, pagination.pageSize)
  }

  const totalCount = data ? data.count : 0;

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount, showSizeChanger: true}}
      className="main-table"
      rowKey={row => row.id}
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
      showSorterTooltip={false}
    />
   )


}

export default MandateMatchesAdminTable
