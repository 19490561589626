import React, { useState, useEffect } from 'react'
import { Button, Spin } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from '../../Redux/DealDashboardRedux'
import DiscloseCompanyInvestorForm from '../../Components/Forms/DiscloseCompanyInvestorForm'
import Images from '../../Images'


function DiscloseCompanyInvestorPage(props) {
  const {dashboardInfo, editResult, saveFetching, targetCompanyResult, location} = props;
  const {state} = location;

  const [data, setData] = useState(null);
  const [targetCompany, setTargetCompany] = useState(null);
  const [check, setCheck] = useState(location.pathname.includes('investor'));

  const history = useHistory();

  useEffect(() => {
    if (state) {
      if (state.targetCompanyId) {
        props.getTargetCompany(state.targetCompanyId);
      }
    } else if (!check) {
      history.push('/home/deals');
    }
  }, [0]);

  useEffect(() => {
    if (dashboardInfo && check) {
      const newData = {
        name: dashboardInfo.target_company?.name,
        website: dashboardInfo.target_company?.website,
        comment: dashboardInfo.target_company?.comment,
        advisor: dashboardInfo.advisor?.name,
      };
      setData(newData);
    }
  }, [dashboardInfo]);

  useEffect(() => {
    if (targetCompanyResult) {
      const newData = {
        name: state.data?.name,
        website: state.data?.website,
        comment: '',
        advisor: state.data?.advisor,
      };
      setData(newData);
      setTargetCompany(targetCompanyResult);
    }
  }, [targetCompanyResult]);

  useEffect(() => {
    if (editResult) {
      history.push(`/deal-dashboard/overview/${targetCompany?.dashboard}`);
    }
  }, [editResult]);

  const onConfirm = () => {
    if (check) {
      history.push(`/deal-dashboard/overview/${dashboardInfo?.id}`)
    }
  }

  function handleSubmit(data) {
    if (targetCompany) {
      if (data.comment) {
        props.editTargetCompany(targetCompany.id, {comment: data.comment});
      } else {
        history.push(`/deal-dashboard/overview/${targetCompany?.dashboard}`);
      }
    }
  }

  return (
    <div className="sign-up-step filters-edit wo-padding" style={{ padding: '133px 80px 32px' }}>
      <div className="absolute-header">
        <div className="logo-block">
          <img src={Images.logoWhite} alt="" />
        </div>
      </div>
      <Spin size="large" spinning={saveFetching} wrapperClassName="dtc-loader">
        <div className="dtc-form">
          <div className="dtc-title">Target Company Disclosed</div>
          <DiscloseCompanyInvestorForm
            data={data}
            mandateDeal={true}
            onSubmit={handleSubmit}
          />
        </div>

        <Button type="primary" htmlType="submit" className="btn-primary" form="disclose-company-form" style={{ width: 217 }} onClick={onConfirm}>
          Continue
        </Button>
      </Spin>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    dashboardInfo: state.dealDashboard.dashboardInfo,
    editResult: state.dealDashboard.editResult,
    saveFetching: state.dealDashboard.saveFetching,
    targetCompanyResult: state.dealDashboard.targetCompanyResult
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveTargetCompany: (data, dealId) => dispatch(DealDashboardActions.targetCompanySaveRequest(data, dealId)),
  getTargetCompany: (targetCompanyId) => dispatch(DealDashboardActions.targetCompanyRequest(targetCompanyId)),
  editTargetCompany: (targetCompanyId, data) => dispatch(DealDashboardActions.targetCompanyEditRequest(targetCompanyId, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DiscloseCompanyInvestorPage)
