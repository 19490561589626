import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import Images from '../../../Images'
import '../PopupPage.less'

function DisengagementNoticePopupPage(props) {
  const { result, fetching, match, location } = props;
  const { dashboardId } = match.params;
  const { state } = location;

  const [action, setAction] = useState(false);

  const history = useHistory();

  useEffect(() => {
    props.dealDashboardSyncToggle(false);
  }, [0]);

  useEffect(() => {
    if (result) {
      if (!action) {
        setAction(true);
        props.changeNotificationStatus(state?.notification)
      } else {
        props.resetDealDashboard();
        history.push(`/home/deals`);
      }
    }
  }, [result]);

  const onConfirm = () => {
    if (state?.isHistory) {
      props.dealDashboardSyncToggle(true);
      history.push(`/deal-dashboard/overview/${dashboardId}`);
    } else {
      props.proceedInvestorParties(dashboardId);
    }
  }

  return (
    <div className="popup-dialog">
      <div className="dialog-container" style={{ width: 420 }}>
        <Spin spinning={fetching} size="large">
          <div className="dialog-content" style={{ padding: 30, paddingTop: 20, paddingBottom: 30 }}>
            <img src={Images.phoneCancel} alt="" />
            <div className="dialog-title welcome" style={{ margin: 6 }}>Disengagement Notice</div>
            <div className='deal-text engagment' style={{ marginTop: 14 }}>
              The Senior Advisor has notified us that one of the parties [the Target Company / you] has terminated the negotiations on this deal
              opportunity. If you believe this is not the case, please notify IDN within three business days.</div>
            <div className='deal-subtext engagment' style={{ marginTop: 24, lineHeight: 2 }}>
              Please get in touch with the Senior Advisor or IDN in case you have any question, and refer to our Terms and Conditions for
              further details
				    </div>
            <div className="btn-footer" style={{ marginTop: 22 }}>
              <Button type="primary white"
                className="btn-primary welcome"
                style={{ width: 144 }}
                onClick={onConfirm}
              >
                {state?.isHistory ? 'Go back' : 'Confirm'}
            </Button>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    result: state.dealDashboard.result,
    fetching: state.dealDashboard.fetching
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
  proceedInvestorParties: (dashboardId) => dispatch(DealDashboardActions.investorProceedPartiesRequest(dashboardId)),
  changeNotificationStatus: (notificationId) => dispatch(DealDashboardActions.notificationChangeStatusRequest(notificationId)),
  dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
  resetDealDashboard: () => dispatch(DealDashboardActions.dealDashboardReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DisengagementNoticePopupPage)
