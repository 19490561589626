import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Tabs, Button } from 'antd'

import DealsAdvisorTable from '../../Components/Tables/DealsAdvisorTable'
import DealsInvestorTable from '../../Components/Tables/DealsInvestorTable'
import DealsActions from '../../Redux/DealsRedux'
import DealSharesActions from '../../Redux/DealSharesRedux'
import DealDashboardActions from '../../Redux/DealDashboardRedux'
import ExpertConsultActions from '../../Redux/ExpertConsultRedux'
import NotificationsActions from '../../Redux/NotificationsRedux'
import ExperienceActions from '../../Redux/ExperienceRedux'
import Images from '../../Images'

const { TabPane } = Tabs;

function DealsPage(props) {
  const { user, role, deals, deal, dealTargetStatus, dealShares, stats, dashboardInfo, investorDealGroupNotifications, advisorDealGroupNotifications, notifications, match } = props;
  const {tab} = match.params;

  const [activeKey, setActiveKey] = useState(tab ? tab : 'your');
  const [dealId, setDealId] = useState(null);
  const [shareId, setShareId] = useState(null);
  const [start, setStart] = useState(false);
  const [updatePage, setUpdatePage] = useState(false);

  const history = useHistory();

  const handleNavigate = (pathname, state) => {
    history.push({
      pathname,
      state
    })
  }

  useEffect(() => {
    props.resetDealDashboard();
    props.expertConsultReset();
    props.resetExperience();
    setStart(true);
    if (role === 0) {
      props.getAdvisorDealGroupNotifications();
    } else {
      props.getInvestorStats();
      props.getInvestorDealGroupNotifications();
    }
    return function cleanup() {
      props.dealsSyncToggle(false);
      props.dealSharesSyncToggle(false);
    }
  }, [0]);

  useEffect(() => {
    setActiveKey(tab ? tab : 'your');
  }, [tab]);

  useEffect(() => {
    if (deals.list && role === 0) {
      const checkNote = deals.list.results?.some(el => el.advisor_notification);
      props.setDealNotification(checkNote);
    }
  }, [deals.list]);

  useEffect(() => {
    if (dealShares.list && role === 2) {
      const checkNote = dealShares.list.results?.some(el => el.investor_notification);
      props.setDealSharesNotification(checkNote);
    }
  }, [dealShares.list]);

  useEffect(() => {
    if (dealTargetStatus) {
      history.push({
        pathname: `/home/deal-sharing/step/start`,
        state: {
          dealId: dealTargetStatus.deal,
          targetId: dealTargetStatus.id
        }
      });
    }
  }, [dealTargetStatus]);

  useEffect(() => {
    if (deals.dealNotificationResult) {
      if (role === 0) {
        const index = deals.list.results.findIndex(el => el.id === dealId);
        if (index != null) {
          const status = deals.list.results[index].status_obj?.id;
          const dashboardId = deals.list.results[index].dashboard;
          const check = deals.list.results[index].reshared;
          if (status === 'engaged' || status === 'won') {
            history.push({
              pathname: `/deal-dashboard/overview/${dashboardId}`,
              state: {backPath: `/home/deals/${activeKey}`, abortUpdate: true}
            });
          } else if (status === 'resharing') {
            if (check) {
              history.push({
                pathname: `/home/deal-sharing/step/start`,
                state: {
                  dealId,
                  fromMandate: true
                }
              });
            } else {
              props.getDealTargetStatus(dealId);
            }
          } else if (status === 'closed') {
            if (dashboardId) {
              history.push({
                pathname: `/deal-dashboard/overview/${dashboardId}`,
                state: {backPath: `/home/deals/${activeKey}`, abortUpdate: true}
              });
            } else {
              deals.list.results[index].mandate ?
                history.push({
                  pathname: `/home/mandate-deal/${dealId}`,
                  state: {backPath: `/home/deals/${activeKey ? activeKey : ''}`}
                })
              :
                history.push({
                  pathname: `/home/deal/${dealId}`,
                  state: {tab: activeKey}
                });
            }
          } else {
            deals.list.results[index].mandate ?
              history.push({
                pathname: `/home/mandate-deal/${dealId}`,
                state: {backPath: `/home/deals/${activeKey ? activeKey : ''}`}
              })
            :
            history.push({
              pathname: `/home/deal/${dealId}`,
              state: {tab: activeKey}
            });
          }
        }
      } else {
        const index = deals.list.results.findIndex(el => el.id === dealId);
        if (index != null) {
          deals.list.results[index].mandate ?
            history.push({
              pathname: `/home/deal-for-mandate/${dealId}`,
              state: {backPath: '/home/deals'}
            })
          :
            history.push({
              pathname: `/home/deal-share-info-all/${dealId}`,
              state: {tab: activeKey}
            });
        }
      }
    }
  }, [deals.dealNotificationResult]);

  useEffect(() => {
    if (dealShares.dealSharesNotificationResult) {
      const status = dealShares.dealSharesNotificationResult.investor_status;
      const item = dealShares.list.results.find(el => el.id === shareId);
      const dashboardId = item.dashboard;
      const isClosed = !!item.closed;
      if (status === 'engaged') {
        props.getDealDashboardInfo(dashboardId);
      } else if (isClosed && dashboardId) {
        history.push({
          pathname: `/deal-dashboard/overview/${dashboardId}`,
          state: {backPath: `/home/deals/${activeKey}`, abortUpdate: true}
        });
      } else {
        item.deal?.mandate ?
          history.push({
            pathname: `/home/deal-for-mandate/${item.deal?.id}${item.id ? '/' + item.id : ''}`,
            state: {backPath: `/home/deals/${activeKey ? activeKey : ''}`}
          })
        :
          history.push({
            pathname: `/home/deal-share-info/${shareId}`,
            state: {backPath: `/home/deals/${activeKey}`}
          });
      }
    }
  }, [dealShares.dealSharesNotificationResult]);

  useEffect(() => {
    if (deal) {
      if (!deal.mandate) {
        if (deal.investment_focus && !deal.investment_overview) {
          handleNavigate('/home/deal-sharing/step/2', {dealId: deal.id});
        } else if (deal.investment_focus && !deal.advisor_role) {
          handleNavigate('/home/deal-sharing/step/3', {dealId: deal.id});
        } else if (deal.investment_focus && !deal.investor_sought) {
          handleNavigate('/home/deal-sharing/step/4', {dealId: deal.id});
        } else {
          handleNavigate('/home/deal-sharing/step/review', {dealId: deal.id});
        }
      } else {
        if (deal.investment_focus && !deal.investment_overview) {
          handleNavigate('/home/mandate-deal-sharing/step/2', {dealId: deal.id});
        } else if (deal.investment_focus && !deal.advisor_role) {
          handleNavigate('/home/mandate-deal-sharing/step/3', {dealId: deal.id});
        } else {
          handleNavigate('/home/mandate-deal-sharing/step/review', {dealId: deal.id});
        }
      }
    }
  }, [deal]);

  useEffect(() => {
    if (dashboardInfo) {
      if (role === 2) {
        if (!start) {
          return;
        }
        if (dashboardInfo.target_company?.seen_by_investor === false) {
          history.push('/home/deal-disclose-company-investor/match-confirmed');
        } else {
          history.push({
            pathname: `/deal-dashboard/overview/${dashboardInfo.id}`,
            state: {backPath: `/home/deals/${activeKey}`, abortUpdate: true}
          });
        }
      }
    }
  }, [dashboardInfo]);

  useEffect(() => {
    if (notifications?.deals) {
      if (role === 0) {
        onTabChange(activeKey);
        props.getAdvisorDealGroupNotifications();
      } else {
        onTabChange(activeKey);
        props.getInvestorDealGroupNotifications();
      }
    }
  }, [notifications]);

  useEffect(() => {
    if (activeKey) {
      if (role === 0) {
        if (activeKey === 'your') {
          props.getDeals(updatePage ? 1 : deals.page, deals.page_size, null, null, null, { group: '' });
          props.dealsSyncToggle(true);
        } else if (activeKey === 'closed') {
          props.dealsSyncToggle(false);
          props.getDeals(updatePage ? 1 : deals.page, deals.page_size, null, null, null, { group: 'closed' });
        }
      } else {
        if (activeKey === 'your') {
          props.getDealShares(updatePage ? 1 : dealShares.page, dealShares.page_size, dealShares.sort_field, dealShares.sort_order, { investor_group: 'own' });
          props.dealSharesSyncToggle(true);
        } else if (activeKey === 'all') {
          props.dealSharesSyncToggle(false);
          props.getDeals(deals.page, deals.page_size, null, null, deals.search, deals.filter);
        } else if (activeKey === 'closed') {
          props.dealSharesSyncToggle(false);
          props.getDealShares(updatePage ? 1 : dealShares.page, dealShares.page_size, dealShares.sort_field, dealShares.sort_order, { investor_group: 'closed' });
        }
      }
      if (!updatePage) {
        setUpdatePage(true);
      }
    }
  }, [activeKey]);

  const handleChangePage = (page, page_size) => {
    if (role === 0) {
      if (activeKey === 'your') {
        props.getDeals(page, page_size);
      } else {
        props.getDeals(page, page_size, null, null, null, { group: 'closed' });
      }
    } else {
      if (activeKey === 'your') {
        props.getDealShares(page, page_size, dealShares.sort_field, dealShares.sort_order, { investor_group: 'own' });
      } else if (activeKey === 'all') {
        props.getDeals(page, page_size, null, null, deals.search, deals.filter);
      } else {
        props.getDealShares(page, page_size, dealShares.sort_field, dealShares.sort_order, { investor_group: 'closed' });
      }
    }
  }

  const onTabChange = key => {
    history.push(`/home/deals/${key}`);
    setActiveKey(key);
  }

  const deleteNotification = id => {
    if (role === 0) {
      setDealId(id);
      props.deleteDealNotification(id, {advisor_notification: false});
    } else {
      if (activeKey === 'all') {
        setDealId(id);
        props.deleteDealNotification_(id);
      } else {
        setShareId(id);
        props.deleteDealSharesNotification(id, {investor_notification: false});
      }
    }
  }

  const generateTabTitle = key => {
    let title;
    switch(key) {
      case 'own': {
        title = 'Open Deals';
        break;
      }
      case 'closed': {
        title = 'Closed Deals';
        break;
      }
      default: {
        title = 'All Deals';
        break;
      }
    }
    let notes = (role === 0) ? advisorDealGroupNotifications : investorDealGroupNotifications;
    return (
      <div className="tab-title">
        <span>{title}</span>
        {
          notes && notes[key] &&
            <img src={Images.notification} alt="" />
        }
      </div>
    )
  }

  const onShareDeal = () => {
    history.push('/home/deal-sharing/step/start')
    // if (user?.signed_platform_agreement) {
    //   history.push('/home/deal-sharing/step/start')
    // } else {
    //   history.push(`/home/deals/${activeKey ? activeKey + '/' : ''}platform-agreement-info`)
    // }
  }

  return (
    <div className="main-container">
      {
        role === 0 ?
          <>
            <div className="section-title">New Deal</div>
            <div className="section-content" style={{ marginBottom: 0 }}>
              <div className="section-text">
                <span>Have you originated a new investment opportunity?</span>
                <span><b>Share it on IDN</b> and connect to investors.</span>
                <span className="blue">Success fees up to 2 million pounds</span>
              </div>
              <Button type="primary orange-margin" className="btn-primary deal-btn" onClick={onShareDeal}>
                Share it now
              </Button>
            </div>
            <Tabs
              className="dashboard-tabs"
              activeKey={activeKey}
              onChange={onTabChange}
            >
              <TabPane tab={generateTabTitle('own')} key="your">
                {
                  deals?.list?.results?.length ?
                    <DealsAdvisorTable
                      data={deals.list}
                      page={deals.page}
                      fetching={false}
                      pageSize={deals.page_size}
                      tab={activeKey}
                      onChangePage={handleChangePage}
                      getDeal={props.getDeal}
                      getDealTargetStatus={props.getDealTargetStatus}
                      deleteNotification={deleteNotification}
                    />
                    :
                    <div className="section-content info">You don’t have any active deal in your pipeline</div>
                }
              </TabPane>
              <TabPane tab={generateTabTitle('closed')} key="closed">
                {
                  deals?.list?.results?.length ?
                    <DealsAdvisorTable
                      data={deals.list}
                      page={deals.page}
                      fetching={false}
                      pageSize={deals.page_size}
                      tab={activeKey}
                      onChangePage={handleChangePage}
                      getDeal={props.getDeal}
                      deleteNotification={deleteNotification}
                    />
                    :
                    <div className="section-content info">You don’t have any closed deal in your pipeline</div>
                }
              </TabPane>
            </Tabs>
          </>
          :
          <Tabs className="dashboard-tabs" activeKey={activeKey} onChange={onTabChange}>
            <TabPane tab={generateTabTitle('own')} key="your">
              <div className="section-content stat" style={{ maxWidth: 'max-content', marginBottom: 16 }}>
                <div className="section-count">
                  <span>Deals Received</span>
                  <div>{stats?.deals_received}</div>
                </div>
                <div className="section-count">
                  <span>Deals Applied</span>
                  <div>{stats?.deals_applied}</div>
                </div>
                <div className="section-count">
                  <span>Engagements</span>
                  <div>{stats?.engagements}</div>
                </div>
                <div className="section-count">
                  <span>Deals Closed</span>
                  <div>{stats?.deals_closed}</div>
                </div>
              </div>
              {
                dealShares?.list?.results?.length ?
                  <DealsInvestorTable
                    tab="your"
                    data={dealShares.list}
                    page={dealShares.page}
                    fetching={false}
                    pageSize={dealShares.page_size}
                    onChangePage={handleChangePage}
                    deleteNotification={deleteNotification}
                    getDealDashboardInfo={props.getDealDashboardInfo}
                  />
                :
                  null
              }
            </TabPane>
            <TabPane tab={generateTabTitle('closed')} key="closed">
              <div className="section-content stat" style={{ maxWidth: 'max-content', marginBottom: 16 }}>
                <div className="section-count">
                  <span>Deals Received</span>
                  <div>{stats?.deals_received}</div>
                </div>
                <div className="section-count">
                  <span>Deals Applied</span>
                  <div>{stats?.deals_applied}</div>
                </div>
                <div className="section-count">
                  <span>Engagements</span>
                  <div>{stats?.engagements}</div>
                </div>
                <div className="section-count">
                  <span>Deals Closed</span>
                  <div>{stats?.deals_closed}</div>
                </div>
              </div>
              {
                dealShares?.list?.results?.length ?
                  <DealsInvestorTable
                    tab="closed"
                    data={dealShares.list}
                    page={dealShares.page}
                    fetching={false}
                    pageSize={dealShares.page_size}
                    onChangePage={handleChangePage}
                    deleteNotification={deleteNotification}
                  />
                  :
                  null
              }
            </TabPane>
          </Tabs>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    role: state.auth.role,
    deals: state.deals,
    deal: state.deals.deal,
    dealTargetStatus: state.deals.dealTargetStatus,
    dealShares: state.dealShares,
    stats: state.dealShares.investorStats,
    dashboardInfo: state.dealDashboard.dashboardInfo,
    investorDealGroupNotifications: state.notifications.investorDealGroupNotifications,
    advisorDealGroupNotifications: state.notifications.advisorDealGroupNotifications,
    notifications: state.notifications.notifications
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDeals: (page, page_size, sort_field, sort_order, search, filter) => dispatch(DealsActions.dealsRequest(page, page_size, sort_field, sort_order, search, filter)),
  getDeal: (id) => dispatch(DealsActions.dealRequest(id)),
  setDealNotification: (value) => dispatch(DealsActions.setDealNotification(value)),
  deleteDealNotification: (id, data) => dispatch(DealsActions.dealNotificationDeleteRequest(id, data)),
  deleteDealNotification_: (dealId) => dispatch(DealsActions.dealNotificationDeleteRequest_(dealId)),
  dealsSyncToggle: (syncIsStart) => dispatch(DealsActions.dealsSyncToggle(syncIsStart)),
  getDealTargetStatus: (dealId) => dispatch(DealsActions.dealTargetStatusRequest(dealId)),

  getDealShares: (page, page_size, sort_field, sort_order, filter) => dispatch(DealSharesActions.dealSharesRequest(page, page_size, sort_field, sort_order, filter)),
  setDealSharesNotification: (value) => dispatch(DealSharesActions.setDealSharesNotification(value)),
  deleteDealSharesNotification: (id, data) => dispatch(DealSharesActions.dealSharesNotificationDeleteRequest(id, data)),
  dealSharesSyncToggle: (syncIsStart) => dispatch(DealSharesActions.dealSharesSyncToggle(syncIsStart)),
  getInvestorStats: () => dispatch(DealSharesActions.dealSharesInvestorStatsRequest()),

  getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
  resetDealDashboard: () => dispatch(DealDashboardActions.dealDashboardReset()),
  expertConsultReset: () => dispatch(ExpertConsultActions.expertConsultReset()),

  getInvestorDealGroupNotifications: () => dispatch(NotificationsActions.investorDealGroupNotificationsRequest()),
  getAdvisorDealGroupNotifications: () => dispatch(NotificationsActions.advisorDealGroupNotificationsRequest()),

  resetExperience: () => dispatch(ExperienceActions.experienceReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DealsPage)
