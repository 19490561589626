import React from 'react'
import {Form, Input, Button, Row, Col} from 'antd'
import Utils from '../../Utils/utils'

import Images from '../../Images'


function AdvisorPreviewForm1(props) {
  const [form] = Form.useForm();

  const onFinish = values => {
    props.onSubmit(values);
  }

  return (
    <Form form={form} onFinish={onFinish} className="login-form" layout="vertical">

      <Row gutter={16}>
        <Col sm={24} xl={12}>
          <Form.Item
            name="name"
            rules={[{required: true, message: 'This field is required'}]}
          >
            <Input
              placeholder="Name"
              maxLength={100}
              prefix={<img src={Images.user} alt="" />}
              suffix={'*'}
            />
          </Form.Item>
        </Col>
        <Col sm={24} xl={12}>
          <Form.Item
            name="surname"
            rules={[{required: true, message: 'This field is required'}]}
          >
            <Input
              placeholder="Surname"
              maxLength={100}
              prefix={<img src={Images.user} alt="" />}
              suffix={'*'}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="email"
            rules={[
              {required: true, message: 'This field is required'},
              { type: 'email', message: 'Invalid email format' },
              { min: 5, message: 'Field must contain at least 5 characters' },
            ]}
            onKeyPress={e=>{if(e.charCode === 32) e.preventDefault()}}
            onBlur={e=>Utils.clearSpaces(e, 'email', form.setFieldsValue)}
          >
            <Input
              placeholder="Email"
              maxLength={100}
              prefix={<img src={Images.envelope} alt="" />}
              suffix={'*'}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="phone"
            rules={[
              {required: true, message: 'This field is required'},
              {pattern: /^\+?[0-9]{7,14}$/, message: 'Invalid format'},
              { min: 7, message: 'Field must contain at least 7 characters' },
            ]}
          >
            <Input
              placeholder="+447911123456"
              type="tel"
              maxLength={20}
              prefix={<img src={Images.mobile} alt="" />}
              suffix={'*'}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="linkedin_url"
            rules={[
              {required: true, message: 'This field is required'},
              {pattern: /linkedin.com/i, message: 'Field must contain "linkedin.com"'},
              { min: 12, message: 'Field must contain at least 12 characters' },
            ]}
          >
            <Input
              placeholder="URL Linkedin Profile"
              maxLength={100}
              prefix={<img src={Images.linkedin} alt="" />}
              suffix={'*'}
            />
          </Form.Item>
        </Col>
      </Row>

      <Button type="primary" htmlType="submit" className="btn-primary" style={{width: 217, marginTop: 32}}>
        Next
      </Button>

    </Form>
  )
}

export default AdvisorPreviewForm1
