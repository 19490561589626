import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function RejectUltimatumPopupPage(props) {
  const { result, fetching, match, isAdmin } = props;
  const { dashboardId } = match.params;

  const history = useHistory();

  useEffect(() => {
    props.dealDashboardSyncToggle(false);
    return function cleanup() {
      if (isAdmin) {
        props.dealDashboardSyncToggle(true);
      }
    }
  }, [0]);

  useEffect(() => {
    if (result) {
      if (isAdmin) {
        props.getDealDashboardInfo(dashboardId);
        history.push(`/home/match-company/${dashboardId}`);
      } else {
        props.resetDealDashboard();
        history.push(`/home/deals`);
      }
    }
  }, [result]);

  return (
    <PopupWrapper
      width={420}
      path={isAdmin ?
				`/home/match-company/${dashboardId}`
				:
        {
          pathname: `/deal-dashboard/overview/${dashboardId}`,
          state: {
            isToggle: true
          }
        }
			}
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content" style={{ padding: 30, paddingTop: 20, paddingBottom: 30 }}>
          <img src={Images.phoneCancel} alt="" />
          <div className="dialog-title welcome" style={{ margin: 6 }}>Reject Ultimatum</div>
          <div className="deal-text engagment" style={{ marginTop: 14 }}>By clicking confirm, you reject the ultimatum and terminate the engagement.</div>
          <br />
          <div className="deal-text engagment">You will then be redirected to the main dashboard</div>
          <br />
        	<div className="deal-text sm" style={{width: '90%'}}>Please review IDN's Platform Agreement to remember your<br />rights and obligations in the context of the termination<br />of this engagement</div>
          <div className="btn-footer" style={{ marginTop: 12 }}>
            <Button type="primary white"
              className="btn-primary welcome"
              style={{ width: 144 }}
              onClick={() => props.rejectUltimatumByInvestor(dashboardId)}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
	return {
    isAdmin: state.auth.isAdmin,
		result: state.dealDashboard.result,
		fetching: state.dealDashboard.fetching
	}
}

const mapDispatchToProps = (dispatch) => ({
  getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
	rejectUltimatumByInvestor: (dashboardId) => dispatch(DealDashboardActions.investorRejectUltimatumRequest(dashboardId)),
	dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
  resetDealDashboard: () => dispatch(DealDashboardActions.dealDashboardReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RejectUltimatumPopupPage)
