import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  dealSharesRequest: ['page', 'page_size', 'sort_field', 'sort_order', 'filter'],
  dealSharesSuccess: ['list'],

  dealShareMatchRequest: ['shareId'],
  dealShareMatchSuccess: ['matchResult'],

  dealShareChangeStatusRequest: ['shareId', 'status'],
  dealShareChangeStatusSuccess: ['statusResult'],

  dealSharesNotificationDeleteRequest: ['shareId', 'data'],
  dealSharesNotificationDeleteSuccess: ['dealSharesNotificationResult'],

  dealShareUpdateRequest: ['shareId', 'data'],
  dealShareUpdateSuccess: ['dealShareUpdateResult'],

  dealSharesInvestorStatsRequest: null,
  dealSharesInvestorStatsSuccess: ['investorStats'],

  dealInvestorApplyRequest: ['shareId', 'data'],
  dealInvestorApplySuccess: ['dealInvestorApplyResult'],

  dealInvestorReapplyRequest: ['shareId'],
  dealInvestorReapplySuccess: ['dealInvestorReapplyResult'],

  dealInvestorSendColleaguesRequest: ['shareId', 'data'],
  dealInvestorSendColleaguesSuccess: ['dealInvestorSendColleaguesResult'],

  firmDealsRequest: ['page', 'page_size'],
  firmDealsSuccess: ['firmDeals'],

  firmDealRequest: ['shareId'],
  firmDealSuccess: ['firmDealResult'],

  firmStatsRequest: null,
  firmStatsSuccess: ['firmStats'],

  applicationWithdrawRequest: ['shareId', 'data'],
  applicationWithdrawSuccess: ['applicationWithdrawResult'],

  dealInvestorRejectRequest: ['shareId'],
  dealInvestorRejectSuccess: ['dealInvestorRejectResult'],

  matchAnswerSaveRequest: ['data'],
  matchAnswerSaveSuccess: ['answerResult'],

  matchAnswerUpdateRequest: ['answerId', 'data'],
  matchAnswerUpdateSuccess: ['answerResult'],

  advisorKeepMatchReviewRequest: ['shareId'],
  advisorKeepMatchReviewSuccess: ['keepMatchReviewResult'],

  seenByInvestorRequest: ['shareId'],
  seenByInvestorSuccess: ['seenByInvestorResult'],

  advisorMatchConfirmRequest: ['shareId'],
  advisorMatchConfirmSuccess: ['advisorMatchConfirmResult'],

  matchConfirmHashRequest: ['hash'],
  matchConfirmHashSuccess: ['matchConfirmHashResult'],

  setDealSharesNotification: ['dealSharesNotification'],

  dealSharesSyncToggle: ['syncIsStart'],

  dealSharesTableSettingsReset: null,

  dealSharesReset: null,

  resultsReset: null,
  dealSharesFetchingReset: null
})

export const DealSharesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  fetching: false,

  firmDeals: null,

  matchResult: null,
  statusResult: null,
  dealSharesNotificationResult: null,
  dealShareUpdateResult: null,
  dealInvestorApplyResult: null,
  dealInvestorReapplyResult: null,
  dealInvestorSendColleaguesResult: null,
  applicationWithdrawResult: null,
  dealInvestorRejectResult: null,
  answerResult: null,
  keepMatchReviewResult: null,
  seenByInvestorResult: null,
  advisorMatchConfirmResult: null,
  matchConfirmHashResult: null,
  firmDealResult: null,

  page:1,
  page_size:20,
  sort_field:'',
  sort_order: 1,

  filter: {
    investor_group: '',
    deal: ''
  },

  page_fd:1,
  page_size_fd:20,

  dealSharesNotification: false,

  syncIsStart: false,

  investorStats: null,
  firmStats: null

})

/* ------------- Selectors ------------- */
export const DealSharesSelectors = {
  getState: (state) => state.dealShares
}

/* ------------- Reducers ------------- */

export const dealSharesRequest = (state, { page, page_size, sort_field, sort_order, filter }) => {
  const newState = {
    fetching: true
  };
  page && (newState.page = page);
  page_size && (newState.page_size = page_size);
  newState.sort_field = sort_field ? sort_field : '';
  newState.sort_order = sort_order ? sort_order : 1;
  filter && (newState.filter = state.filter.merge(filter));
  return state.merge(newState)
}

export const dealSharesSuccess = (state, { list }) => {
  return state.merge({ fetching: false, list })
}

export const dealShareMatchRequest = (state, { shareId }) => {
  return state.merge({ fetching: true })
}

export const dealShareMatchSuccess = (state, { matchResult }) => {
  return state.merge({ fetching: false, matchResult })
}

export const dealSharesReset = (state) =>{
  return INITIAL_STATE
}

export const dealShareChangeStatusRequest = (state, { shareId, status }) => {
  return state.merge({ fetching: true})
}

export const dealShareChangeStatusSuccess = (state, { statusResult }) => {
  return state.merge({ fetching: false, statusResult })
}

export const dealSharesNotificationDeleteRequest = (state, { shareId, data }) => {
  return state.merge({ fetching: true})
}

export const dealSharesNotificationDeleteSuccess = (state, { dealSharesNotificationResult }) => {
  return state.merge({ fetching: false, dealSharesNotificationResult })
}

export const dealShareUpdateRequest = (state, { shareId, data }) => {
  return state.merge({ fetching: true})
}

export const dealShareUpdateSuccess = (state, { dealShareUpdateResult }) => {
  return state.merge({ fetching: false, dealShareUpdateResult })
}

export const dealSharesInvestorStatsRequest = (state) => {
  return state.merge({ fetching: true})
}

export const dealSharesInvestorStatsSuccess = (state, { investorStats }) => {
  return state.merge({ fetching: false, investorStats })
}

export const dealInvestorApplyRequest = (state, { shareId, data }) => {
  return state.merge({ fetching: true})
}

export const dealInvestorApplySuccess = (state, { dealInvestorApplyResult }) => {
  return state.merge({ fetching: false, dealInvestorApplyResult })
}

export const dealInvestorReapplyRequest = (state, { shareId }) => {
  return state.merge({ fetching: true})
}

export const dealInvestorReapplySuccess = (state, { dealInvestorReapplyResult }) => {
  return state.merge({ fetching: false, dealInvestorReapplyResult })
}

export const dealInvestorSendColleaguesRequest = (state, { shareId, data }) => {
  return state.merge({ fetching: true})
}

export const dealInvestorSendColleaguesSuccess = (state, { dealInvestorSendColleaguesResult }) => {
  return state.merge({ fetching: false, dealInvestorSendColleaguesResult })
}

export const firmDealsRequest = (state, { page, page_size }) => {
  const newState = {
    fetching: true
  };
  page && (newState.page_fd = page);
  page_size && (newState.page_size_fd = page_size);
  return state.merge(newState)
}

export const firmDealsSuccess = (state, { firmDeals }) => {
  return state.merge({ fetching: false, firmDeals })
}

export const firmDealRequest = (state, { shareId }) => {
  return state.merge({ fetching: true})
}

export const firmDealSuccess = (state, { firmDealResult }) => {
  return state.merge({ fetching: false, firmDealResult })
}

export const firmStatsRequest = (state) => {
  return state.merge({ fetching: true})
}

export const firmStatsSuccess = (state, { firmStats }) => {
  return state.merge({ fetching: false, firmStats })
}

export const applicationWithdrawRequest = (state, { shareId, data }) => {
  return state.merge({ fetching: true})
}

export const applicationWithdrawSuccess = (state, { applicationWithdrawResult }) => {
  return state.merge({ fetching: false, applicationWithdrawResult })
}

export const dealInvestorRejectRequest = (state, { shareId }) => {
  return state.merge({ fetching: true})
}

export const dealInvestorRejectSuccess = (state, { dealInvestorRejectResult }) => {
  return state.merge({ fetching: false, dealInvestorRejectResult })
}

export const matchAnswerSaveRequest = (state, { data }) => {
  return state.merge({ fetching: true})
}

export const matchAnswerSaveSuccess = (state, { answerResult }) => {
  return state.merge({ fetching: false, answerResult })
}

export const matchAnswerUpdateRequest = (state, { answerId, data }) => {
  return state.merge({ fetching: true})
}

export const matchAnswerUpdateSuccess = (state, { answerResult }) => {
  return state.merge({ fetching: false, answerResult })
}

export const advisorKeepMatchReviewRequest = (state, { shareId }) => {
  return state.merge({ fetching: true})
}

export const advisorKeepMatchReviewSuccess = (state, { keepMatchReviewResult }) => {
  return state.merge({ fetching: false, keepMatchReviewResult })
}

export const seenByInvestorRequest = (state, { shareId }) => {
  return state.merge({ fetching: true })
}

export const seenByInvestorSuccess = (state, { seenByInvestorResult }) => {
  return state.merge({ fetching: false, seenByInvestorResult })
}

export const advisorMatchConfirmRequest = (state, { shareId }) => {
  return state.merge({ fetching: true })
}

export const advisorMatchConfirmSuccess = (state, { advisorMatchConfirmResult }) => {
  return state.merge({ fetching: false, advisorMatchConfirmResult })
}

export const matchConfirmHashRequest = (state, { hash }) => {
  return state.merge({ fetching: true })
}

export const matchConfirmHashSuccess = (state, { matchConfirmHashResult }) => {
  return state.merge({ fetching: false, matchConfirmHashResult })
}

export const setDealSharesNotification = (state, { dealSharesNotification }) => {
  return state.merge({ dealSharesNotification })
}

export const dealSharesTableSettingsReset = (state) =>{
  const newState = {
    page:1,
    sort_field:'',
    sort_order: 1,
    page_fd:1,
    filter: {
      investor_group: '',
      deal: ''
    },
  };
  return state.merge(newState)
}

export const dealSharesSyncToggle = (state, { syncIsStart }) => {
  return state.merge({ syncIsStart })
}

export const resultsReset = (state) => {
  return state.merge({
    matchResult: null,
    statusResult: null,
    dealSharesNotificationResult: null,
    dealShareUpdateResult: null,
    dealInvestorApplyResult: null,
    dealInvestorReapplyResult: null,
    dealInvestorSendColleaguesResult: null,
    applicationWithdrawResult: null,
    dealInvestorRejectResult: null,
    answerResult: null,
    keepMatchReviewResult: null,
    seenByInvestorResult: null,
    advisorMatchConfirmResult: null,
    matchConfirmHashResult: null,
    firmDealResult: null
  })
}

export const dealSharesFetchingReset = (state) => {
  return state.merge({ fetching:false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.DEAL_SHARES_REQUEST]: dealSharesRequest,
  [Types.DEAL_SHARES_SUCCESS]: dealSharesSuccess,

  [Types.DEAL_SHARE_MATCH_REQUEST]: dealShareMatchRequest,
  [Types.DEAL_SHARE_MATCH_SUCCESS]: dealShareMatchSuccess,

  [Types.DEAL_SHARE_CHANGE_STATUS_REQUEST]: dealShareChangeStatusRequest,
  [Types.DEAL_SHARE_CHANGE_STATUS_SUCCESS]: dealShareChangeStatusSuccess,

  [Types.DEAL_SHARES_NOTIFICATION_DELETE_REQUEST]: dealSharesNotificationDeleteRequest,
  [Types.DEAL_SHARES_NOTIFICATION_DELETE_SUCCESS]: dealSharesNotificationDeleteSuccess,

  [Types.DEAL_SHARE_UPDATE_REQUEST]: dealShareUpdateRequest,
  [Types.DEAL_SHARE_UPDATE_SUCCESS]: dealShareUpdateSuccess,

  [Types.DEAL_SHARES_INVESTOR_STATS_REQUEST]: dealSharesInvestorStatsRequest,
  [Types.DEAL_SHARES_INVESTOR_STATS_SUCCESS]: dealSharesInvestorStatsSuccess,

  [Types.DEAL_INVESTOR_APPLY_REQUEST]: dealInvestorApplyRequest,
  [Types.DEAL_INVESTOR_APPLY_SUCCESS]: dealInvestorApplySuccess,

  [Types.DEAL_INVESTOR_REAPPLY_REQUEST]: dealInvestorReapplyRequest,
  [Types.DEAL_INVESTOR_REAPPLY_SUCCESS]: dealInvestorReapplySuccess,

  [Types.DEAL_INVESTOR_SEND_COLLEAGUES_REQUEST]: dealInvestorSendColleaguesRequest,
  [Types.DEAL_INVESTOR_SEND_COLLEAGUES_SUCCESS]: dealInvestorSendColleaguesSuccess,

  [Types.FIRM_DEALS_REQUEST]: firmDealsRequest,
  [Types.FIRM_DEALS_SUCCESS]: firmDealsSuccess,

  [Types.FIRM_DEAL_REQUEST]: firmDealRequest,
  [Types.FIRM_DEAL_SUCCESS]: firmDealSuccess,

  [Types.FIRM_STATS_REQUEST]: firmStatsRequest,
  [Types.FIRM_STATS_SUCCESS]: firmStatsSuccess,

  [Types.APPLICATION_WITHDRAW_REQUEST]: applicationWithdrawRequest,
  [Types.APPLICATION_WITHDRAW_SUCCESS]: applicationWithdrawSuccess,

  [Types.DEAL_INVESTOR_REJECT_REQUEST]: dealInvestorRejectRequest,
  [Types.DEAL_INVESTOR_REJECT_SUCCESS]: dealInvestorRejectSuccess,

  [Types.MATCH_ANSWER_SAVE_REQUEST]: matchAnswerSaveRequest,
  [Types.MATCH_ANSWER_SAVE_SUCCESS]: matchAnswerSaveSuccess,

  [Types.MATCH_ANSWER_UPDATE_REQUEST]: matchAnswerUpdateRequest,
  [Types.MATCH_ANSWER_UPDATE_SUCCESS]: matchAnswerUpdateSuccess,

  [Types.ADVISOR_KEEP_MATCH_REVIEW_REQUEST]: advisorKeepMatchReviewRequest,
  [Types.ADVISOR_KEEP_MATCH_REVIEW_SUCCESS]: advisorKeepMatchReviewSuccess,

  [Types.SEEN_BY_INVESTOR_REQUEST]: seenByInvestorRequest,
  [Types.SEEN_BY_INVESTOR_SUCCESS]: seenByInvestorSuccess,

  [Types.ADVISOR_MATCH_CONFIRM_REQUEST]: advisorMatchConfirmRequest,
  [Types.ADVISOR_MATCH_CONFIRM_SUCCESS]: advisorMatchConfirmSuccess,

  [Types.MATCH_CONFIRM_HASH_REQUEST]: matchConfirmHashRequest,
  [Types.MATCH_CONFIRM_HASH_SUCCESS]: matchConfirmHashSuccess,

  [Types.SET_DEAL_SHARES_NOTIFICATION]: setDealSharesNotification,

  [Types.DEAL_SHARES_SYNC_TOGGLE]: dealSharesSyncToggle,

  [Types.DEAL_SHARES_TABLE_SETTINGS_RESET]: dealSharesTableSettingsReset,

  [Types.DEAL_SHARES_RESET]: dealSharesReset,

  [Types.RESULTS_RESET]: resultsReset,

  [Types.DEAL_SHARES_FETCHING_RESET]: dealSharesFetchingReset,
})
