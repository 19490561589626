import React from 'react'

import Images from '../Images'


function CollapseHeader (props) {
  return (
    <div className="collapse-header-custom">
      <img src={Images[props.imageType]} alt="" />
      <span>{props.text}</span>
    </div>
  )
}

export default CollapseHeader
