import React from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function AgreementSharedPopupPage({ match }) {
  const {dashboardId} = match.params
  const history = useHistory();

  return (
    <PopupWrapper
      width={420}
      path={`/deal-dashboard/overview/${dashboardId}`}
      hasDropdown={true}
    >
      <div className="dialog-content" style={{ padding: '24px 56px'}}>
        <img src={Images.phoneOk} alt="" />
        <div className="dialog-title bold" style={{ margin: 6 }}>Agreement shared</div>
        <div className="deal-text" style={{ marginTop: 14 }}>The Consulting Agreement has been successfully shared with the Senior Advisor. You will be notified when the Senior Advisor signs it. In case you need to edit the agreement, you can do so by re-submitting a new version of the document</div>
        <div className="btn-footer" style={{marginTop: 12}}>
        <Button type="primary white"
        className="btn-primary flat"
        style={{ width: 144 }}
        onClick={() => history.push({ pathname: `/deal-dashboard/overview/${dashboardId}`})}>
          Continue
        </Button>
        </div>
      </div>
    </PopupWrapper>
  )
}

export default AgreementSharedPopupPage
