import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  notificationsRequest: null,
  notificationsSuccess: ['notifications'],

  investorDealGroupNotificationsRequest: null,
  investorDealGroupNotificationsSuccess: ['investorDealGroupNotifications'],

  advisorDealGroupNotificationsRequest: null,
  advisorDealGroupNotificationsSuccess: ['advisorDealGroupNotifications'],

  dealDashboardNotificationsRequest: ['dashboardId'],
  dealDashboardNotificationsSuccess: ['ddNotifications'],

  investorMandateGroupNotificationsRequest: null,
  investorMandateGroupNotificationsSuccess: ['investorMandateGroupNotifications'],

  advisorMandateGroupNotificationsRequest: null,
  advisorMandateGroupNotificationsSuccess: ['advisorMandateGroupNotifications'],

  notificationsReset: null,

  notificationsFetchingReset: null,

  notificationsSyncToggle: ['syncIsStart'],
  dealDashboardNotificationsSyncToggle: ['ddSyncIsStart'],
})

export const NotificationsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  notifications: null,
  ddNotifications: null,
  fetching: false,

  investorDealGroupNotifications: null,
  advisorDealGroupNotifications: null,
  investorMandateGroupNotifications: null,
  advisorMandateGroupNotifications: null,

  syncIsStart: false,
  ddSyncIsStart: false,
})

/* ------------- Selectors ------------- */
export const NotificationsSelectors = {
  getState: (state) => state.notifications
}

/* ------------- Reducers ------------- */

export const notificationsRequest = (state) =>{
  return state.merge({ fetching: true })
}

export const notificationsSuccess = (state, { notifications }) => {
  return state.merge({ fetching: false, notifications })
}

export const investorDealGroupNotificationsRequest = (state) =>{
  return state.merge({ fetching: true })
}

export const investorDealGroupNotificationsSuccess = (state, { investorDealGroupNotifications }) => {
  return state.merge({ fetching: false, investorDealGroupNotifications })
}

export const advisorDealGroupNotificationsRequest = (state) =>{
  return state.merge({ fetching: true })
}

export const advisorDealGroupNotificationsSuccess = (state, { advisorDealGroupNotifications }) => {
  return state.merge({ fetching: false, advisorDealGroupNotifications })
}

export const dealDashboardNotificationsRequest = (state, { dashboardId }) =>{
  return state.merge({ fetching: true })
}

export const dealDashboardNotificationsSuccess = (state, { ddNotifications }) => {
  return state.merge({ fetching: false, ddNotifications })
}

export const investorMandateGroupNotificationsRequest = (state) =>{
  return state.merge({ fetching: true })
}

export const investorMandateGroupNotificationsSuccess = (state, { investorMandateGroupNotifications }) => {
  return state.merge({ fetching: false, investorMandateGroupNotifications })
}

export const advisorMandateGroupNotificationsRequest = (state) =>{
  return state.merge({ fetching: true })
}

export const advisorMandateGroupNotificationsSuccess = (state, { advisorMandateGroupNotifications }) => {
  return state.merge({ fetching: false, advisorMandateGroupNotifications })
}

export const notificationsReset = (state) => {
  return INITIAL_STATE
}

export const notificationsFetchingReset = (state) => {
  return state.merge({ fetching:false })
}

export const notificationsSyncToggle = (state, { syncIsStart }) => {
  return state.merge({ syncIsStart })
}

export const dealDashboardNotificationsSyncToggle = (state, { ddSyncIsStart }) => {
  return state.merge({ ddSyncIsStart })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.NOTIFICATIONS_REQUEST]: notificationsRequest,
  [Types.NOTIFICATIONS_SUCCESS]: notificationsSuccess,

  [Types.INVESTOR_DEAL_GROUP_NOTIFICATIONS_REQUEST]: investorDealGroupNotificationsRequest,
  [Types.INVESTOR_DEAL_GROUP_NOTIFICATIONS_SUCCESS]: investorDealGroupNotificationsSuccess,

  [Types.ADVISOR_DEAL_GROUP_NOTIFICATIONS_REQUEST]: advisorDealGroupNotificationsRequest,
  [Types.ADVISOR_DEAL_GROUP_NOTIFICATIONS_SUCCESS]: advisorDealGroupNotificationsSuccess,

  [Types.DEAL_DASHBOARD_NOTIFICATIONS_REQUEST]: dealDashboardNotificationsRequest,
  [Types.DEAL_DASHBOARD_NOTIFICATIONS_SUCCESS]: dealDashboardNotificationsSuccess,

  [Types.INVESTOR_MANDATE_GROUP_NOTIFICATIONS_REQUEST]: investorMandateGroupNotificationsRequest,
  [Types.INVESTOR_MANDATE_GROUP_NOTIFICATIONS_SUCCESS]: investorMandateGroupNotificationsSuccess,

  [Types.ADVISOR_MANDATE_GROUP_NOTIFICATIONS_REQUEST]: advisorMandateGroupNotificationsRequest,
  [Types.ADVISOR_MANDATE_GROUP_NOTIFICATIONS_SUCCESS]: advisorMandateGroupNotificationsSuccess,

  [Types.NOTIFICATIONS_RESET]: notificationsReset,

  [Types.NOTIFICATIONS_FETCHING_RESET]: notificationsFetchingReset,

  [Types.NOTIFICATIONS_SYNC_TOGGLE]: notificationsSyncToggle,
  [Types.DEAL_DASHBOARD_NOTIFICATIONS_SYNC_TOGGLE]: dealDashboardNotificationsSyncToggle,
})
