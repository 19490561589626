import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Upload, message, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import ExpertConsultActions from '../../../Redux/ExpertConsultRedux'
import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function EngageAdvisorPopupPage(props) {
  const { isAdmin, expertConsultInfo, result, fetching, match, location } = props;
  const { dashboardId } = match.params;
  const { state } = location;

  const [fileUid, setFileUid] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [consultId, setConsultId] = useState(null);

  const history = useHistory();

  useEffect(() => {
    props.dealDashboardSyncToggle(false);
    return function cleanup() {
      props.dealDashboardSyncToggle(true);
    }
  }, [0]);

  useEffect(() => {
		if (expertConsultInfo) {
			setConsultId(expertConsultInfo.id);
		}
	}, [expertConsultInfo]);

  useEffect(() => {
    if (result) {
      props.getDealDashboardInfo(dashboardId);
      history.push(`/deal-dashboard/overview/${dashboardId}/agreement-shared`);
    }
  }, [result]);

  function onChange({ file, fileList }) {
    if (fileUid && fileUid === file.uid) {
      return;
    }
    setFileUid(file.uid);

    let formData = new FormData();

    if (fileList && fileList.length) {
      formData.set('file', fileList[0].originFileObj, fileList[0].originFileObj.name);
    }
    setFileData(formData);
  }

  const checkRequired = () => {
    if (state?.isHistory) {
      history.push(`/deal-dashboard/overview/${dashboardId}`);
      return;
    }
    if (!fileData) {
      message.error('Upload the file', 3);
    } else {
      props.uploadAgreementByInvestor(consultId, fileData);
    }
  }

  return (
    <PopupWrapper
      width={420}
      path={!isAdmin ?
        `/deal-dashboard/overview/${dashboardId}`
        :
        `/home/match-company/${dashboardId}`
      }
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content" style={{ padding: '24px 16px' }}>
          <img src={Images.phoneOk} alt="" />
          <div className="dialog-title bold" style={{ margin: 6 }}>Engage Advisor</div>
          <div className="deal-text" style={{ marginTop: 14 }}>To engage the Senior Advisor for consulting work, please upload a signed Consulting Agreement, in the form approved by IDN and your firm, and share it with the Senior Advisor.</div>
          <br />
          <Upload
            method="get"
            maxCount={1}
            onChange={onChange}
            className="upload-block"
            beforeUpload={file =>
              new Promise((resolve, reject) => {
                if (file.size > 20971520) {
                  message.error('File size must not exceed 20 Mbyte', 3)
                  reject()
                } else {
                  resolve()
                }
              })
            }
          >
            <Button
              type='primary white'
              className='btn-primary upload'
              style={{ width: 62, borderRadius: 5, margin: 6 }}
              disabled={state?.isHistory}
            >
              <img src={Images.upload} alt='' style={{ height: 20 }} />
            </Button>
          </Upload>
          <br />
          <div className="deal-text sm" style={{ width: '90%' }}>Please review IDN's Platform Agreement to remember your<br />rights and obligations in the context of this engagement</div>
          <div className="btn-footer" style={{ marginTop: 12 }}>
            <Button type="primary white"
              className="btn-primary flat"
              style={{ width: 144 }}
              onClick={checkRequired}
            >
              {state?.isHistory ? 'Go back' : 'Confirm'}
            </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    expertConsultInfo: state.expertConsult.expertConsultInfo,
    result: state.expertConsult.result,
    fetching: state.expertConsult.fetching_
  }
}

const mapDispatchToProps = (dispatch) => ({
  uploadAgreementByInvestor: (consultId, data) => dispatch(ExpertConsultActions.investorUploadAgreementRequest(consultId, data)),
  getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
  dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EngageAdvisorPopupPage)
