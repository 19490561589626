import React from 'react'
import IconCustom from '../Icon'

function PaginationItemRender (page, type: 'page' | 'prev' | 'next', originalElement) {

  if (type === 'prev') {
    return (
      <a className="ant-pagination-item-link">
      <IconCustom
        color={"rgba(255,255,255,0.5)"}
        type="pagination_prev"
        className="anticon anticon-right"
      />
      </a>
    )
  } else if(type === 'next') {
    return (
      <a className="ant-pagination-item-link">
      <IconCustom
        color={"rgba(255,255,255,0.5)"}
        type="pagination_next"
        className="anticon anticon-right"
      />
      </a>
    )
  } else {
    return originalElement;
  }
}

export default PaginationItemRender
