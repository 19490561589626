import React, { useState, useEffect } from 'react'
import { Layout, Spin, message } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Switch, Route } from 'react-router-dom'

import AuthActions from '../Redux/AuthRedux'
import SettingsActions from '../Redux/SettingsRedux'
import AdvisorSignUpStep1 from '../Components/AdvisorSignUp/AdvisorSignUpStep1'
import AdvisorSignUpStep2 from '../Components/AdvisorSignUp/AdvisorSignUpStep2'
import AdvisorSignUpFinish from '../Components/AdvisorSignUp/AdvisorSignUpFinish'
import errorHandler from '../Utils/errorHandler'

function AdvisorSignUpPage(props) {
  const { user, result, error, fetching, saveResult, match, location, sectorFocus, geographyFocus, countries, getSectors, getContinents, getCountries } = props;
  const { step } = match.params;
  const { state } = location;

  const [previewData, setPreviewData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  const [hash, setHash] = useState(null);

  const history = useHistory();

  function changeStep(data) {
    if (Number(step) === 1) {
      data.hash = hash;
      setPreviewData(data);
      props.checkEmail(data.email);
    } else if (Number(step) === 2) {
      data.hash = hash;
      setUserInfo(data);
      props.signUpAdvisor(userData, 'advisor');
    }
  }

  useEffect(() => {
    if (!state) {
      history.push('/');
    } else {
      setHash(state?.hash);
    }
  }, [0]);

  useEffect(() => {
    if (error) {
      if (error.is_registered) {
        message.error(`User with this Email already exists`, 5);
        props.resetError();
      } else {
        errorHandler(error, props.resetError);
      }
    }
  }, [error]);

  useEffect(() => {
    if (result) {
      setUserData(previewData);
      setPreviewData(null);
      history.push('/advisor/sign-up/step/2');
    }
  }, [result]);

  useEffect(() => {
    if (user) {
      props.updateFilters(user.id, userInfo);
    }
  }, [user]);

  useEffect(() => {
    if (saveResult) {
      props.confirmEmail(hash);
    }
  }, [saveResult]);

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <Switch>
          <Route path="/advisor/sign-up/step/1" render={
            props => (
              <AdvisorSignUpStep1
                preview={state?.preview}
                countries={countries}
                geographyFocus={geographyFocus}
                onChangeStep={changeStep}
                getCountries={getCountries}
                getContinents={getContinents}
              />
            )} />
          <Route path="/advisor/sign-up/step/2" render={
            props => (
              <AdvisorSignUpStep2
                sectorFocus={sectorFocus}
                geographyFocus={geographyFocus}
                onChangeStep={changeStep}
                getSectors={getSectors}
                getContinents={getContinents}
              />
            )} />
          <Route path="/advisor/sign-up/step/finish" render={
            props => (
              <AdvisorSignUpFinish />
            )} />
        </Switch>
      </Spin>
    </Layout>
  )
}


const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    result: state.auth.result,
    error: state.auth.error,
    fetching: state.auth.fetching,
    saveResult: state.auth.advisorFiltersResult,
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    countries: state.settings.countries,
  }
}

const mapDispatchToProps = (dispatch) => ({
  checkEmail: (email) => dispatch(AuthActions.checkEmailExistRequest(email)),
  resetError: () => dispatch(AuthActions.authErrorReset()),

  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: () => dispatch(SettingsActions.geographyFocusRequest()),
  getCountries: () => dispatch(SettingsActions.countriesRequest()),

  signUpAdvisor: (data, role) => dispatch(AuthActions.signUpRequest(data, role)),
  updateFilters: (advisorId, data) => dispatch(AuthActions.advisorFiltersRequest(advisorId, data)),
  confirmEmail: (hash) => dispatch(AuthActions.confirmEmailRequest(hash)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorSignUpPage)
