import React, { useEffect } from 'react'
import {Form, Input, Select} from 'antd'
import Utils from '../../Utils/utils'

import Images from '../../Images'

const { Option } = Select;

function InvestorDetailsProfileForm(props) {
  const {active, data, admins, firms, roles} = props;

  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        surname: data.surname,
        company: `${data.company?.id}`,
        investor_role: `${data.investor_role?.id}`,
        job_title: data.job_title,
        linkedin_url: data.linkedin_url,
        phone: data.phone,
        email: data.email,
        account_manager: data.account_manager?.id
      })
    }
  }, [data]);

  const adminList = [];
  if (admins && admins.results) {
    for (let i = 0; i < admins.results.length; i++) {
      adminList.push(<Option key={admins.results[i]?.user?.id}>{`${admins.results[i]?.name} ${admins.results[i]?.surname}`}</Option>);
    }
  }

  const firmList = [];
  if (firms && firms.results) {
    for (let i = 0; i < firms.results.length; i++) {
      firmList.push(<Option key={firms.results[i]?.id}>{firms.results[i]?.name}</Option>);
    }
  }

  const roleList = [];
  if (roles) {
    for (let i = 0; i < roles.length; i++) {
      roleList.push(<Option key={roles[i]?.id}>{roles[i]?.name}</Option>);
    }
  }

  function updateField(field) {
    form.validateFields()
      .then(values => {
        if (values[field] != null) {
          props.updateField(field, values[field]);
        }
      })
  }

  return (
    <Form id="investor-details-profile-form" form={form} className="login-form" layout="vertical">

      <Form.Item
        name="name"
        rules={[{required: true, message: 'This field is required'}]}
      >
        <Input
          placeholder="Name"
          maxLength={100}
          prefix={<img src={Images.user} alt="" />}
          disabled={!active}
          onBlur={() => updateField('name')}
        />
      </Form.Item>

      <Form.Item
        name="surname"
        rules={[{required: true, message: 'This field is required'}]}
      >
        <Input
          placeholder="Surname"
          maxLength={100}
          prefix={<img src={Images.user} alt="" />}
          disabled={!active}
          onBlur={() => updateField('surname')}
        />
      </Form.Item>

      <Form.Item
        name="company"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <div className="custom-select notScaleFontSize">
          <span className="flex-center-horizontal">
            <img src={Images.briefcaseSm} alt="" />
          </span>
          <Select
            defaultValue={`${data.company?.id}`}
            style={{ width: '100%' }}
            placeholder="Investment firm"
            disabled={!active}
            onChange={(e) => form.setFieldsValue({ company: +e })}
            onBlur={() => updateField('company')}
          >
            {firmList}
          </Select>

        </div>
      </Form.Item>

      <Form.Item
        name="investor_role"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <div className="custom-select notScaleFontSize">
          <span className="flex-center-horizontal">
            <img src={Images.role} alt="" />
          </span>
          <Select
            defaultValue={`${data.investor_role?.id}`}
            style={{ width: '100%' }}
            placeholder="Role"
            disabled={!active}
            onChange={(e) => form.setFieldsValue({ investor_role: +e })}
            onBlur={() => updateField('investor_role')}
          >
            {roleList}
          </Select>

        </div>
      </Form.Item>

      <Form.Item
        name="job_title"
      >
        <Input
          placeholder="Job Position"
          maxLength={100}
          prefix={<img src={Images.crosshairs} alt="" />}
          disabled={!active}
          onBlur={() => updateField('job_title')}
        />
      </Form.Item>

      <Form.Item
        name="linkedin_url"
        rules={[
          {pattern: /linkedin.com/i, message: 'Field must contain "linkedin.com"'},
          { min: 12, message: 'Field must contain at least 12 characters' },
        ]}
      >
        <Input
          placeholder="URL Linkedin Profile"
          maxLength={100}
          prefix={<img src={Images.linkedin} alt="" />}
          disabled={!active}
          onBlur={() => updateField('linkedin_url')}
        />
      </Form.Item>

      <Form.Item
        name="phone"
        rules={[
          {pattern: /^\+?[0-9]{7,14}$/, message: 'Invalid format'},
          { min: 7, message: 'Field must contain at least 7 characters' },
        ]}
      >
        <Input
          placeholder="+447911123456"
          type="tel"
          maxLength={20}
          prefix={<img src={Images.mobile} alt="" />}
          disabled={!active}
          onBlur={() => updateField('phone')}
        />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          {required: true, message: 'This field is required'},
          { type: 'email', message: 'Invalid email format' },
          { min: 5, message: 'Field must contain at least 5 characters' },
        ]}
        onKeyPress={e=>{if(e.charCode === 32) e.preventDefault()}}
        onBlur={e=>Utils.clearSpaces(e, 'email', form.setFieldsValue)}
      >
        <Input
          placeholder="Email"
          maxLength={100}
          prefix={<img src={Images.envelope} alt="" />}
          disabled={!active}
          onBlur={() => updateField('email')}
        />
      </Form.Item>

      <Form.Item
        name="account_manager"
      >
        <div className="custom-select notScaleFontSize">
          <span className="flex-center-horizontal">
            <img src={Images.accManager} alt="" />
          </span>
          <Select
            defaultValue={data.account_manager?.id}
            style={{ width: '100%' }}
            placeholder="Account manager"
            disabled={!active}
            onChange={e => form.setFieldsValue({ account_manager: e })}
            onBlur={() => updateField('account_manager')}
          >
            {adminList}
          </Select>

        </div>
      </Form.Item>

    </Form>
  )
}

export default InvestorDetailsProfileForm
