import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	expertConsultingRequest: ['dashboardId'],
	expertConsultingSuccess: ['expertConsultInfo'],

	agreementDataSaveRequest: ['dashboardId', 'data'],
	agreementDataSaveSuccess: ['result'],

	agreementDataUpdateRequest: ['agreementId', 'dashboardId', 'data'],
	agreementDataUpdateSuccess: ['result'],

	getDataForAgreementRequest: ['dashboardId'],
	getDataForAgreementSuccess: ['agreementData'],

	getAgreementRequest: ['consultId', 'dashboardId'],
	getAgreementSuccess: ['result'],

	investorAngageAdvisorRequest: ['consultId', 'dashboardId'],
	investorAngageAdvisorSuccess: ['result'],

	investorEditAgreementRequest: ['consultId', 'dashboardId'],
	investorEditAgreementSuccess: ['result'],

	advisorSignAgreementRequest: ['consultId', 'dashboardId'],
	advisorSignAgreementSuccess: ['result'],

	hoursUploadRequest: ['dashboardId', 'consultId', 'uploadHours'],
	hoursUploadSuccess: ['result'],

	getHoursRequest: ['consultId'],
	getHoursSuccess: ['result'],

	hoursUpDateRequest: ['dashboardId', 'consultId', 'uploadHours'],
	hoursUpDateSuccess: ['result'],

	investorImproveHoursRequest: ['consultId', 'dashboardId'],
	investorImproveHoursSuccess: ['result'],

	investorExtendAgreementRequest: ['consultId', 'dashboardId'],
	investorExtendAgreementSuccess: ['result'],

	investorEditExtensionRequest: ['consultId', 'dashboardId'],
	investorEditExtensionSuccess: ['result'],

	advisorSignExtensionRequest: ['consultId', 'dashboardId'],
	advisorSignExtensionSuccess: ['result'],

	notificationViewedRequest: ['notifId', 'dashboardId', 'data'],
	notificationViewedSuccess: ['result'],

	setEditAgreementTrue: ['editAgreement'],
	setEditAgreementFalse: ['editAgreement'],

	// seenByInvestorRequest: ['targetCompanyId'],
	// seenByInvestorSuccess: ['seenByInvestorResult'],

	expertConsultActionHistoryRequest: ['dashboardId'],
	expertConsultActionHistorySuccess: ['ecHistoryActions'],

	investorUploadAgreementRequest: ['consultId', 'data'],
	investorUploadAgreementSuccess: ['result'],

	investorReuploadAgreementRequest: ['consultId', 'data'],
	investorReuploadAgreementSuccess: ['result'],

  advisorUploadAgreementRequest: ['consultId', 'data'],
	advisorUploadAgreementSuccess: ['result'],

  advisorEnterOptionsRequest: ['consultId', 'data'],
	advisorEnterOptionsSuccess: ['result'],

	investorWorkDoneDataRequest: ['consultId'],
	investorWorkDoneDataSuccess: ['workDoneResult'],

	adminEditDataRequest: ['consultId', 'data'],
	adminEditDataSuccess: ['adminEditResult'],

	expertConsultReset: null,

	resultsReset: null,
	expertConsultFetchingReset: null,

	expertConsultSyncToggle: ['syncIsStart'],

})

export const ExpertConsultTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	expertConsultInfo: null,

	fetching: false,
	fetching_: false,
	result: null,
	workDoneResult: null,
	adminEditResult: null,

	ecHistoryActions: null,

	dashboardId: null,
	agreementData: null,
	editAgreement: false,

	saveFetching: false,
	saveResult: null,
	seenByInvestorResult: null,

	syncIsStart: false,

})

/* ------------- Selectors ------------- */
export const ExpertConsultSelectors = {
	getState: state => state.expertConsult,
}

/* ------------- Reducers ------------- */

export const expertConsultingRequest = (state, {dashboardId}) => {
	return state.merge({fetching: true})
}

export const expertConsultingSuccess = (state, {expertConsultInfo}) => {
	return state.merge({
		fetching: false,
		expertConsultInfo,
	})
}

export const setEditAgreementTrue = (state) => {
	return state.merge({
		editAgreement: true,
	})
}

export const setEditAgreementFalse = (state) => {
	return state.merge({
		editAgreement: false,
	})
}

export const agreementDataSaveRequest = (state, {dashboardId, data}) => {
	return state.merge({saveFetching: true})
}
export const agreementDataSaveSuccess = (state, {result}) => {
	return state.merge({saveFetching: false, result})
}

export const agreementDataUpdateRequest = (state, {agreementId, dashboardId, data}) => {
	return state.merge({saveFetching: true})
}
export const agreementDataUpdateSuccess = (state, {result}) => {
	return state.merge({saveFetching: false, result})
}

export const getDataForAgreementRequest = (state, {dashboardId}) => {
	return state.merge({fetching: true})
}
export const getDataForAgreementSuccess = (state, {agreementData}) => {
	return state.merge({fetching: false, agreementData})
}

export const investorAngageAdvisorRequest = (state, {consultId, dashboardId}) => {
	return state.merge({fetching: true})
}
export const investorAngageAdvisorSuccess = (state, {result}) => {
	return state.merge({fetching: false, result})
}

export const investorEditAgreementRequest = (state, {consultId, dashboardId}) => {
	return state.merge({fetching: true, editAgreement: false})
}
export const investorEditAgreementSuccess = (state, {result}) => {
	return state.merge({fetching: false, result})
}

export const getAgreementRequest = (state, {consultId, dashboardId}) => {
	return state.merge({fetching: true})
}
export const getAgreementSuccess = (state, {result}) => {
	return state.merge({fetching: false, result})
}

export const advisorSignAgreementRequest = (state, {consultId, dashboardId}) => {
	return state.merge({fetching: true})
}
export const advisorSignAgreementSuccess = (state, {result}) => {
	return state.merge({fetching: false, result})
}

export const hoursUploadRequest = (state, {dashboardId, consultId, uploadHours}) => {
	return state.merge({fetching_: true})
}
export const hoursUploadSuccess = (state, {result}) => {
	return state.merge({fetching_: false, result})
}

export const getHoursRequest = (state, {consultId}) => {
	return state.merge({fetching: true})
}
export const getHoursSuccess = (state, {result}) => {
	return state.merge({fetching: false, result})
}

export const hoursUpDateRequest = (state, {dashboardId, consultId, uploadHours}) => {
	return state.merge({fetching_: true})
}
export const hoursUpDateSuccess = (state, {result}) => {
	return state.merge({fetching_: false, result})
}

export const investorImproveHoursRequest = (state, {consultId, dashboardId}) => {
	return state.merge({fetching: true})
}
export const investorImproveHoursSuccess = (state, {result}) => {
	return state.merge({fetching: false, result})
}

export const investorExtendAgreementRequest = (state, {consultId, dashboardId}) => {
	return state.merge({fetching: true})
}
export const investorExtendAgreementSuccess = (state, {result}) => {
	return state.merge({fetching: false, result})
}

export const investorEditExtensionRequest = (state, {consultId, dashboardId}) => {
	return state.merge({fetching: true})
}
export const investorEditExtensionSuccess = (state, {result}) => {
	return state.merge({fetching: false, result})
}

export const advisorSignExtensionRequest = (state, {consultId, dashboardId}) => {
	return state.merge({fetching: true})
}
export const advisorSignExtensionSuccess = (state, {result}) => {
	return state.merge({fetching: false, result})
}

export const notificationViewedRequest = (state, {notifId, dashboardId, data}) => {
	return state.merge({fetching: true})
}
export const notificationViewedSuccess = (state, {result}) => {
	return state.merge({fetching: false, result})
}

export const expertConsultActionHistoryRequest = (state, {dashboardId}) => {
	return state.merge({fetching: true})
}
export const expertConsultActionHistorySuccess = (state, {ecHistoryActions}) => {
	return state.merge({fetching: false, ecHistoryActions})
}

export const investorUploadAgreementRequest = (state, {consultId, data}) => {
	return state.merge({fetching_: true})
}
export const investorUploadAgreementSuccess = (state, {result}) => {
	return state.merge({fetching_: false, result})
}

export const investorReuploadAgreementRequest = (state, {consultId, data}) => {
  return state.merge({fetching_: true})
}
export const investorReuploadAgreementSuccess = (state, {result}) => {
  return state.merge({fetching_: false, result})
}

export const advisorUploadAgreementRequest = (state, {consultId, data}) => {
  return state.merge({fetching_: true})
}
export const advisorUploadAgreementSuccess = (state, {result}) => {
  return state.merge({fetching_: false, result})
}

export const advisorEnterOptionsRequest = (state, {consultId, data}) => {
  return state.merge({fetching: true})
}
export const advisorEnterOptionsSuccess = (state, {result}) => {
  return state.merge({fetching: false, result})
}

export const investorWorkDoneDataRequest = (state, {consultId}) => {
  return state.merge({fetching: true})
}

export const investorWorkDoneDataSuccess = (state, {workDoneResult}) => {
  return state.merge({fetching: false, workDoneResult})
}

export const adminEditDataRequest = (state, {consultId, data}) => {
  return state.merge({fetching: true})
}

export const adminEditDataSuccess = (state, {adminEditResult}) => {
  return state.merge({fetching: false, adminEditResult})
}

export const expertConsultReset = (state) => {
	return INITIAL_STATE
}

export const resultsReset = (state) => {
	return state.merge({
		result: null,
		seenByInvestorResult: null,
		workDoneResult: null,
		adminEditResult: null
	})
}

export const expertConsultFetchingReset = (state) => {
	return state.merge({fetching: false, saveFetching: false, fetching_: false})
}

export const expertConsultSyncToggle = (state, {syncIsStart}) => {
	return state.merge({syncIsStart})
}


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
	[Types.EXPERT_CONSULTING_REQUEST]: expertConsultingRequest,
	[Types.EXPERT_CONSULTING_SUCCESS]: expertConsultingSuccess,

	[Types.AGREEMENT_DATA_SAVE_REQUEST]: agreementDataSaveRequest,
	[Types.AGREEMENT_DATA_SAVE_SUCCESS]: agreementDataSaveSuccess,

	[Types.AGREEMENT_DATA_UPDATE_REQUEST]: agreementDataUpdateRequest,
	[Types.AGREEMENT_DATA_UPDATE_SUCCESS]: agreementDataUpdateSuccess,

	[Types.GET_DATA_FOR_AGREEMENT_REQUEST]: getDataForAgreementRequest,
	[Types.GET_DATA_FOR_AGREEMENT_SUCCESS]: getDataForAgreementSuccess,

	[Types.GET_AGREEMENT_REQUEST]: getAgreementRequest,
	[Types.GET_AGREEMENT_SUCCESS]: getAgreementSuccess,

	[Types.INVESTOR_ANGAGE_ADVISOR_REQUEST]: investorAngageAdvisorRequest,
	[Types.INVESTOR_ANGAGE_ADVISOR_SUCCESS]: investorAngageAdvisorSuccess,

	[Types.INVESTOR_EDIT_AGREEMENT_REQUEST]: investorEditAgreementRequest,
	[Types.INVESTOR_EDIT_AGREEMENT_SUCCESS]: investorEditAgreementSuccess,

	[Types.ADVISOR_SIGN_AGREEMENT_REQUEST]: advisorSignAgreementRequest,
	[Types.ADVISOR_SIGN_AGREEMENT_SUCCESS]: advisorSignAgreementSuccess,

	[Types.HOURS_UPLOAD_REQUEST]: hoursUploadRequest,
	[Types.HOURS_UPLOAD_SUCCESS]: hoursUploadSuccess,

	[Types.GET_HOURS_REQUEST]: getHoursRequest,
	[Types.GET_HOURS_SUCCESS]: getHoursSuccess,

	[Types.HOURS_UP_DATE_REQUEST]: hoursUpDateRequest,
	[Types.HOURS_UP_DATE_SUCCESS]: hoursUpDateSuccess,

	[Types.INVESTOR_IMPROVE_HOURS_REQUEST]: investorImproveHoursRequest,
	[Types.INVESTOR_IMPROVE_HOURS_SUCCESS]: investorImproveHoursSuccess,

	[Types.INVESTOR_EXTEND_AGREEMENT_REQUEST]: investorExtendAgreementRequest,
	[Types.INVESTOR_EXTEND_AGREEMENT_SUCCESS]: investorExtendAgreementSuccess,

	[Types.INVESTOR_EDIT_EXTENSION_REQUEST]: investorEditExtensionRequest,
	[Types.INVESTOR_EDIT_EXTENSION_SUCCESS]: investorEditExtensionSuccess,

	[Types.ADVISOR_SIGN_EXTENSION_REQUEST]: advisorSignExtensionRequest,
	[Types.ADVISOR_SIGN_EXTENSION_SUCCESS]: advisorSignExtensionSuccess,

	[Types.NOTIFICATION_VIEWED_REQUEST]: notificationViewedRequest,
	[Types.NOTIFICATION_VIEWED_SUCCESS]: notificationViewedSuccess,

	[Types.EXPERT_CONSULT_ACTION_HISTORY_REQUEST]: expertConsultActionHistoryRequest,
	[Types.EXPERT_CONSULT_ACTION_HISTORY_SUCCESS]: expertConsultActionHistorySuccess,

	[Types.INVESTOR_UPLOAD_AGREEMENT_REQUEST]: investorUploadAgreementRequest,
	[Types.INVESTOR_UPLOAD_AGREEMENT_SUCCESS]: investorUploadAgreementSuccess,

	[Types.INVESTOR_REUPLOAD_AGREEMENT_REQUEST]: investorReuploadAgreementRequest,
	[Types.INVESTOR_REUPLOAD_AGREEMENT_SUCCESS]: investorReuploadAgreementSuccess,

  [Types.ADVISOR_UPLOAD_AGREEMENT_REQUEST]: advisorUploadAgreementRequest,
	[Types.ADVISOR_UPLOAD_AGREEMENT_SUCCESS]: advisorUploadAgreementSuccess,

  [Types.ADVISOR_ENTER_OPTIONS_REQUEST]: advisorEnterOptionsRequest,
	[Types.ADVISOR_ENTER_OPTIONS_SUCCESS]: advisorEnterOptionsSuccess,

	// [Types.SEEN_BY_INVESTOR_REQUEST]: seenByInvestorRequest,
	// [Types.SEEN_BY_INVESTOR_SUCCESS]: seenByInvestorSuccess,

	[Types.SET_EDIT_AGREEMENT_TRUE]: setEditAgreementTrue,
	[Types.SET_EDIT_AGREEMENT_FALSE]: setEditAgreementFalse,

	[Types.INVESTOR_WORK_DONE_DATA_REQUEST]: investorWorkDoneDataRequest,
	[Types.INVESTOR_WORK_DONE_DATA_SUCCESS]: investorWorkDoneDataSuccess,

	[Types.ADMIN_EDIT_DATA_REQUEST]: adminEditDataRequest,
	[Types.ADMIN_EDIT_DATA_SUCCESS]: adminEditDataSuccess,

	[Types.EXPERT_CONSULT_RESET]: expertConsultReset,
	[Types.RESULTS_RESET]: resultsReset,

	[Types.EXPERT_CONSULT_FETCHING_RESET]: expertConsultFetchingReset,

	[Types.EXPERT_CONSULT_SYNC_TOGGLE]: expertConsultSyncToggle,

})
