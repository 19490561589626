import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Tabs, Input, Space, Button, Tooltip } from 'antd'
import {FileIcon, defaultStyles} from 'react-file-icon'

import SettingsActions from '../../Redux/SettingsRedux'
import UsersActions from '../../Redux/UsersRedux'
import ColleaguesTable from '../../Components/Tables/ColleaguesTable'
import Images from '../../Images'

const { TabPane } = Tabs;
const { TextArea } = Input;

function FirmProfilePage(props) {
  const { filters, user, subRole, users, match } = props;
  const { tab } = match.params;

  const [profileData, setProfileData] = useState(null);
  const [activeKey, setActiveKey] = useState('profile');

  const history = useHistory();

  const inviteButton =
    <Button type="primary white" htmlType="submit" className="btn-primary" style={{width: 217}} onClick={()=>handleNavigate({pathname: '/invite-colleagues', state: {tab: 'colleagues'}})}>
      Invite Colleagues
    </Button>

  function handleNavigate(path) {
    history.push(path);
  }

  useEffect(() => {
    props.getFirmFilters(user.company.id);
    props.getUsers('investor', users.page_firm, users.page_size_firm, users.sort_field_firm, users.sort_order_firm, null, { invest_company: user.company.id }, true);
  }, [0]);

  useEffect(() => {
    setActiveKey(tab ? tab : 'profile');
  }, [tab]);

  useEffect(() => {
    if (filters) {
      let newData = {
        overview: {
          value: filters.overview,
          active: false,
        },
        investment_preferences: {
          value: filters.investment_preferences,
          active: false,
        },
        management_style: {
          value: filters.management_style,
          active: false,
        },
        portfolio: {
          value: filters.portfolio,
          active: false,
        },
        website: {
          value: filters.website,
          active: false,
        }
      };
      setProfileData(newData);
    }
  }, [filters]);

  function setActive(field) {
    let newData = { ...profileData };
    newData[field].active = true;
    setProfileData(newData);
  }

  function setInactive(field, e) {
    let newData = { ...profileData };
    newData[field].active = false;
    newData[field].value = e.target.value;
    setProfileData(newData);
    props.updateFirmFilters(user.company.id, { [field]: e.target.value });
  }

  function handleChangePage(page, page_size, sort_field, sort_order) {
    props.getUsers('investor', page, page_size, sort_field, sort_order, null, { invest_company: user.company.id }, true);
  }

  const onTabChange = key => {
    history.push(`/home/firm's-profile/${key}`);
    setActiveKey(key);
  }

  return (
    <div className="main-container">
      <Tabs
        activeKey={activeKey}
        style={{ marginTop: 32 }}
        tabBarExtraContent={activeKey === 'colleagues' && inviteButton}
        onChange={onTabChange}
      >
        <TabPane tab="Profile" key="profile">
          <div className="firm-profile-block" style={{marginBottom: 0}}>
            {
              filters &&
              <>
                <div className="firm-profile-section-top">
                  <div className="firm-profile-input" style={{marginRight: 40}}>
                    {/* <img src={Images.edit} alt="" /> */}
                    <span>Firm name</span>
                    <Input disabled={true} defaultValue={filters.name} />
                  </div>
                  <div className="firm-profile-input">
                    {
                      subRole !== 2 &&
                        <img src={Images.edit} alt="" onClick={() => setActive('website')} />
                    }
                    <span style={{color: '#232D53'}}>Website</span>
                    <Input
                      defaultValue={filters.website}
                      disabled={!profileData?.website?.active}
                      onBlur={(e) => setInactive('website', e)}
                    />
                  </div>
                </div>

                <div className="firm-profile-title">
                  <span>Firm Overview</span>
                  {
                    subRole !== 2 &&
                      <img src={Images.edit} alt="" onClick={() => setActive('overview')} />
                  }
                </div>
                <div className="firm-profile-area">
                  <TextArea
                    defaultValue={filters.overview}
                    autoSize={true}
                    maxLength={5000}
                    disabled={!profileData?.overview?.active}
                    onBlur={(e) => setInactive('overview', e)}
                  />
                </div>
                <div className="firm-profile-title">
                  <span>Investment preferences</span>
                  {
                    subRole !== 2 &&
                      <img src={Images.edit} alt="" onClick={() => setActive('investment_preferences')} />
                  }
                </div>
                <div className="firm-profile-area">
                  <TextArea
                    defaultValue={filters.investment_preferences}
                    autoSize={true}
                    maxLength={5000}
                    disabled={!profileData?.investment_preferences?.active}
                    onBlur={(e) => setInactive('investment_preferences', e)}
                  />
                </div>
                <div className="firm-profile-title">
                  <span>Asset Management Style</span>
                  {
                    subRole !== 2 &&
                      <img src={Images.edit} alt="" onClick={() => setActive('management_style')} />
                  }
                </div>
                <div className="firm-profile-area">
                  <TextArea
                    defaultValue={filters.management_style}
                    autoSize={true}
                    maxLength={5000}
                    disabled={!profileData?.management_style?.active}
                    onBlur={(e) => setInactive('management_style', e)}
                  />
                </div>
                  <div className="firm-profile-input-bottom row" style={{padding: '16px 0 0'}}>
                    <span>Portfolio companies</span>
                    <div>
                      {
                        subRole !== 2 &&
                          <img src={Images.edit} alt="" onClick={() => setActive('portfolio')} />
                      }
                      <Input
                        defaultValue={filters.portfolio}
                        placeholder="Link to Website"
                        disabled={!profileData?.portfolio?.active}
                        onBlur={(e) => setInactive('portfolio', e)}
                      />
                    </div>
                </div>
                <div className="firm-profile-input-bottom row"
                >
                <span style={{width: 'fit-content'}}>Approved form of Bilateral contract</span>
                <div style={{width: 'fit-content'}}>
                  {
                    filters?.bilateral_contract ?
                      <div className='document-admin' style={{ marginLeft: 30 }}>
                        <a href={filters?.bilateral_contract} target='_blank' rel='noreferrer'
                          style={{marginTop: 5, display: 'flex', alignItems: 'center', flexDirection: 'column'}}
                        >
                          <div className='document-admin-icon' >
                          <FileIcon
                            extension={'pdf'}
                            {...defaultStyles[`pdf`]}
                          />
                          </div>
                          <Tooltip
                            placement="bottom"
                            title={'Bilateral contract'}
                          >
                            <div className='document-admin-title'>Bilateral contract</div>
                          </Tooltip>
                        </a>
                      </div>
                    : null
                  }
                  </div>
                </div>
              </>
            }
          </div>
        </TabPane>
        <TabPane tab="Matching filters" key="filters">
          <div className="firm-profile-block">
            <div className="firm-filter-section">
              <div className="firm-filter-header flex-center-vertical">
                <img src={Images.coins} alt="" />
                <span>Investment focus</span>
                {
                  subRole !== 2 &&
                    <img src={Images.edit} alt="" onClick={() => handleNavigate(`/home/investor-firm/filters-edit/${user.company.id}`)} />
                }
              </div>

              <div className="firm-filter-content">
                <div className="investment-range">
                  <div className="investment-range-title">Enterprise value</div>
                  <div className="investment-range-data">
                    <span>Range (ml)</span>
                    <div className="investment-range-label">From</div>
                    <Input value={filters?.investment_focus?.enterprise_value_from} disabled={true} />

                    <div className="investment-range-label">To</div>
                    <Input value={filters?.investment_focus?.enterprise_value_to} disabled={true} />
                    <span>[in £]</span>
                  </div>
                </div>
                <div className="investment-range">
                  <div className="investment-range-title">Equity ticket</div>
                  <div className="investment-range-data">
                    <span>Range (ml)</span>
                    <div className="investment-range-label">From</div>
                    <Input value={filters?.investment_focus?.equity_investment_from} disabled={true} />

                    <div className="investment-range-label">To</div>
                    <Input value={filters?.investment_focus?.equity_investment_to} disabled={true} />
                    <span>[in £]</span>
                  </div>
                </div>
                <div className="investment-range">
                  <div className="investment-range-title">Equity stake</div>
                  <div className="investment-range-data">
                    <span className="percent-span">Range (%)</span>
                    <div className="investment-range-label">From</div>
                    <Input value={filters?.investment_focus?.equity_stake_from} disabled={true} />

                    <div className="investment-range-label">To</div>
                    <Input value={filters?.investment_focus?.equity_stake_to} disabled={true} />
                  </div>
                </div>

                <div className="investment-range base" style={{ alignItems: 'center', paddingBottom: 16 }}>
                  <div className="investment-range-title">Governance of the targeted equity</div>
                  <div className="investment-range-value flex-center-vertical">
                    {
                      filters?.investment_focus?.governace_of_the_targeted_equity.map(el => (
                        <span key={el.id}>{el.name},&nbsp;</span>
                      ))
                    }
                    {
                      filters?.investment_focus?.specified_governace &&
                        <span>{filters.investment_focus.specified_governace}</span>
                    }
                  </div>
                </div>

                <div className="investment-range base" style={{ alignItems: 'center' }}>
                  <div className="investment-range-title">Type of investment</div>
                  <div className="investment-range-value flex-center-vertical">
                    {
                      filters?.investment_focus?.investment_type.map(el => (
                        <span key={el.id} style={{display: el.id === 3 ? 'none' : 'block'}}>{el.name},&nbsp;</span>
                      ))
                    }
                    {
                      filters?.investment_focus?.comment &&
                        <span>{filters.investment_focus.comment}</span>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="firm-filter-section">
              <div className="firm-filter-header flex-center-vertical">
                <img src={Images.puzzle} alt="" />
                <span>Sector Focus</span>
                {
                  subRole !== 2 &&
                    <img src={Images.edit} alt="" onClick={() => handleNavigate(`/home/investor-firm/filters-edit/${user.company.id}`)} />
                }
              </div>
              <div className="firm-filter-content" style={{ paddingLeft: 40 }}>
                <div className="firm-filter-line flex-center-vertical">
                  <div className="firm-filter-left">
                    <span className="flex-center-vertical">Sectors</span>
                  </div>
                  <div className="firm-filter-right">
                    <span className="flex-center-vertical">Sub Sectors</span>
                  </div>
                </div>
                {
                  filters?.industry_focus_sub_sectors.map(sector => (
                    <Fragment key={sector.id}>
                      <div className="firm-filter-line flex-center-vertical">
                        <div className="firm-filter-left">
                          <div className="firm-filter-item-name flex-center-vertical">{sector.name}</div>
                        </div>
                        <div className="firm-filter-right">
                          <div className="firm-filter-item-value flex-center-vertical">
                            {
                              sector.children.map(child => (
                                <span key={child.id}>{child.name},&nbsp;</span>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                      {
                        sector.additional_specification &&
                        <div className="firm-filter-line flex-center-vertical">
                          <div className="firm-filter-left">
                            <div className="firm-filter-add-spec flex-center-vertical">Additional specifications:</div>
                          </div>
                          <div className="firm-filter-right">
                            <div className="firm-filter-add-spec-value flex-center-vertical">{sector.additional_specification}</div>
                          </div>
                        </div>
                      }
                    </Fragment>
                  ))
                }
              </div>
            </div>

            <div className="firm-filter-section">
              <div className="firm-filter-header flex-center-vertical">
                <img src={Images.globe} alt="" />
                <span>Geographic Focus</span>
                {
                  subRole !== 2 &&
                    <img src={Images.edit} alt="" onClick={() => handleNavigate(`/home/investor-firm/filters-edit/${user.company.id}`)} />
                }
              </div>
              <div className="firm-filter-content" style={{ paddingLeft: 40 }}>
                <div className="firm-filter-line flex-center-vertical">
                  <div className="firm-filter-left">
                    <span className="flex-center-vertical">Continents</span>
                  </div>
                  <div className="firm-filter-right">
                    <span className="flex-center-vertical">Country</span>
                  </div>
                </div>
                {
                  filters?.continents.map(continent => (
                    <div className="firm-filter-line flex-center-vertical" key={continent.id}>
                      <div className="firm-filter-left">
                        <div className="firm-filter-item-name flex-center-vertical">{continent.name}</div>
                      </div>
                      <div className="firm-filter-right">
                        <div className="firm-filter-item-value flex-center-vertical">
                          {
                            continent.children.map(child => (
                              <span key={child.id}>{child.name},&nbsp;</span>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tab="Colleagues" key="colleagues">
          <div className="firm-profile-block">
            <ColleaguesTable
              data={users.list}
              page={users.page}
              fetching={users.fetching}
              pageSize={users.page_size}
              onChangePage={handleChangePage}
            />
          </div>
        </TabPane>
      </Tabs>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    filters: state.settings.firmFilters,
    user: state.auth.userInfo,
    subRole: state.auth.investorSubRole,
    users: state.users,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getFirmFilters: (firmId) => dispatch(SettingsActions.firmFiltersRequest(firmId)),
  updateFirmFilters: (firmId, data) => dispatch(SettingsActions.firmFiltersSaveRequest(firmId, data)),

  getUsers: (role, page, page_size, sort_field, sort_order, search, filter) => dispatch(UsersActions.usersRequest(role, page, page_size, sort_field, sort_order, search, filter)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FirmProfilePage)
