import React, { useState, useEffect } from 'react'
import { List } from 'antd'
import { connect } from 'react-redux'

import PopupWrapper from './PopupWrapper'
import UsersActions from '../../Redux/UsersRedux'
import Images from '../../Images'
import './PopupPage.less'

function EditFirmProfilePopupPage(props) {
  const { colleagues } = props;

  const [data, setData] = useState([]);

  useEffect(() => {
    props.getColleagues();
  }, [0]);

  useEffect(() => {
    if (colleagues) {
      setData(colleagues.map(el => ({ title: el.name })));
    }
  }, [colleagues]);

  return (
    <PopupWrapper
      width={420}
      path={`/home/review-profile`}
    >
      <div className="dialog-content" style={{ padding: '24px 48px' }}>
        <div className="dialog-title deal">Edit Firm Profile</div>
        <div className="deal-text" style={{ padding: '0 40px' }}>To edit your Firm's profile, please contact one of the lead users at your firm listed below</div>
        <br />
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<img src={Images.user} alt="" />}
                title={<span className="list-text">{item.title}</span>}
              />
            </List.Item>
          )}
        />
        </div>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    colleagues: state.users.investorColleagues,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getColleagues: (filter) => dispatch(UsersActions.investorColleaguesRequest(filter)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditFirmProfilePopupPage)
