import React, { useEffect } from 'react'
import {Form, Input} from 'antd'
import Utils from '../../Utils/utils'

import Images from '../../Images'

function InvestorSettingsForm(props) {
  const {active, data} = props;

  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        surname: data.surname,
        job_title: data.job_title,
        linkedin_url: data.linkedin_url,
        phone: data.phone,
        email: data.email,
      })
    }
  }, [data]);

  function updateField(field) {
    form.validateFields()
      .then(values => {
        if (values[field] != null) {
          props.updateField(field, values[field]);
        }
      })
  }

  return (
    <Form id="investor-settings-form" form={form} className="login-form" layout="vertical">

      <Form.Item
        name="name"
        rules={[{required: true, message: 'This field is required'}]}
      >
        <Input
          placeholder="Name"
          maxLength={100}
          prefix={<img src={Images.user} alt="" />}
          disabled={!active}
          onBlur={() => updateField('name')}
        />
      </Form.Item>

      <Form.Item
        name="surname"
        rules={[{required: true, message: 'This field is required'}]}
      >
        <Input
          placeholder="Surname"
          maxLength={100}
          prefix={<img src={Images.user} alt="" />}
          disabled={!active}
          onBlur={() => updateField('surname')}
        />
      </Form.Item>

      <Form.Item
        name="job_title"
      >
        <Input
          placeholder="Job Position"
          maxLength={100}
          prefix={<img src={Images.crosshairs} alt="" />}
          disabled={!active}
          onBlur={() => updateField('job_title')}
        />
      </Form.Item>

      <Form.Item
        name="linkedin_url"
        rules={[
          {pattern: /linkedin.com/i, message: 'Field must contain "linkedin.com"'},
          { min: 12, message: 'Field must contain at least 12 characters' },
        ]}
      >
        <Input
          placeholder="URL Linkedin Profile"
          maxLength={100}
          prefix={<img src={Images.linkedin} alt="" />}
          disabled={!active}
          onBlur={() => updateField('linkedin_url')}
        />
      </Form.Item>

      <Form.Item
        name="phone"
        rules={[
          {pattern: /^\+?[0-9]{7,14}$/, message: 'Invalid format'},
          { min: 7, message: 'Field must contain at least 7 characters' },
        ]}
      >
        <Input
          placeholder="+447911123456"
          type="tel"
          maxLength={20}
          prefix={<img src={Images.mobile} alt="" />}
          disabled={!active}
          onBlur={() => updateField('phone')}
        />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          {required: true, message: 'This field is required'},
          { type: 'email', message: 'Invalid email format' },
          { min: 5, message: 'Field must contain at least 5 characters' },
        ]}
        onKeyPress={e=>{if(e.charCode === 32) e.preventDefault()}}
        onBlur={e=>Utils.clearSpaces(e, 'email', form.setFieldsValue)}
      >
        <Input
          placeholder="Email"
          maxLength={100}
          prefix={<img src={Images.envelope} alt="" />}
          disabled={!active}
          onBlur={() => updateField('email')}
        />
      </Form.Item>

    </Form>
  )
}

export default InvestorSettingsForm
