import React, { useState } from 'react'
import { Layout } from 'antd'
import { Link } from 'react-router-dom'

import Images from '../Images'

const { Sider } = Layout;

function Sidebar(props) {
  const {path, onNavigate, role, subRole, isAdmin, hasDealNotification, hasMandateNotification, notifications} = props;

  return (
    <Sider className="sidebar" width="auto">
      <div className="logo-section">
        <img src={Images.logo} alt="" />
      </div>
      <div className="nav-content">
      {
        role === 0 &&
          <>
            <div className="nav-section">
              <div className="nav-title">DEAL SHARING</div>
              <div className="nav-divider"></div>
              {/* <Link
                to="/home/deals" className={'nav-item' + ((path && path.includes('/deals')) ? ' active' : '')}
              > */}
              <div
                className={'nav-item' + ((path && path.includes('/deals')) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/deals')}
              >
                <div>
                  <img src={Images.file} alt="" />
                </div>
                <span>Deals</span>
                {
                  (hasDealNotification || notifications?.deals) &&
                    <img src={Images.notification} alt="" />
                }
              </div>
            </div>
            <div className="nav-section">
              <div className="nav-title">INVESTORS' REQUESTS</div>
              <div className="nav-divider"></div>
              <div
                className={'nav-item' + ((path && path.includes('/mandates')) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/mandates')}
              >
                <div>
                  <img src={Images.mandate} alt="" />
                </div>
                <span>Mandates</span>
                {
                  (hasMandateNotification || notifications?.mandate) &&
                    <img src={Images.notification} alt="" />
                }
              </div>
              <div
                className={'nav-item' + ((path && path.includes('/expertise')) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/expertise')}
              >
                <div>
                  <img src={Images.userEdit} alt="" />
                </div>
                <span>Expertise</span>
              </div>
              {/* <div
                className={'nav-item' + ((path && path.includes('/deal-advisory')) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/deal-advisory')}
              >
                <div>
                  <img src={Images.userEdit} alt="" />
                </div>
                <span>Advisory placements</span>
              </div> */}
              {/* <div
                className={'nav-item' + ((path && path.includes('/home/placements')) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/placements')}
              >
                <div>
                  <img src={Images.clock} alt="" />
                </div>
                <span>Placements</span>
              </div> */}
            </div>
            <div className="nav-section">
              <div className="nav-title">YOUR PROFILE</div>
              <div className="nav-divider"></div>
              <div
                className={'nav-item' + ((path && path.includes('/experience')) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/experience')}
              >
                <div>
                  <img src={Images.briefcase} alt="" />
                </div>
                <span>Experience</span>
              </div>
            </div>
          </>
      }
      {
        role === 2 &&
          <>
            <div className="nav-section">
              <div className="nav-title">DEAL PIPELINE</div>
              <div className="nav-divider"></div>
              <div
                className={'nav-item' + ((path && path.includes('/deals')) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/deals')}
              >
                <div>
                  <img src={Images.file} alt="" />
                </div>
                <span>Your deals</span>
                {
                  (hasDealNotification || notifications?.deals) &&
                    <img src={Images.notification} alt="" />
                }
              </div>
              <div
                className={'nav-item' + ((path && path.includes('/all-deals')) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/all-deals')}
              >
                <div>
                  <img src={Images.file} alt="" />
                </div>
                <span>All deals</span>
              </div>
            </div>

            <div className="nav-section">
              <div className="nav-title">SERVICES</div>
              <div className="nav-divider"></div>
              <div
                className={'nav-item' + ((path && path.includes('/mandates')) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/mandates')}
              >
                <div>
                  <img src={Images.mandate} alt="" />
                </div>
                <span>Mandates</span>
                {
                  (hasMandateNotification || notifications?.mandate) &&
                    <img src={Images.notification} alt="" />
                }
              </div>
              <div
                className={'nav-item' + ((path && path.includes('/expertise')) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/expertise')}
              >
                <div>
                  <img src={Images.userEdit} alt="" />
                </div>
                <span>Expertise</span>
              </div>
              {/* <div
                className={'nav-item' + ((path && path.includes('/ned-sa')) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/ned-sa')}
              >
                <div>
                  <img src={Images.clock} alt="" />
                </div>
                <span>NED & SA Placements</span>
              </div> */}
            </div>

            <div className="nav-section">
              <div className="nav-title">YOUR FIRM</div>
              <div className="nav-divider"></div>
              <div
                className={'nav-item' + ((path && path.includes("/firm's-profile")) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate("/home/firm's-profile")}
              >
                <div>
                  <img src={Images.briefcase} alt="" />
                </div>
                <span>Firm's profile</span>
              </div>
              {
                subRole !== 2 &&
                  <div
                    className={'nav-item' + ((path && path.includes("/firm's-activities")) ? ' active' : '')}
                    onClick={()=>onNavigate && onNavigate("/home/firm's-activities")}
                  >
                    <div>
                      <img src={Images.briefcase} alt="" />
                    </div>
                    <span>Firm's activities</span>
                  </div>
              }
            </div>
          </>
      }
      {
        isAdmin &&
          <>
            <div className="nav-section">
              <div
                className={'nav-item' + ((path && (path.includes('/deal-pipeline') || path.includes('/deal-details'))) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/deal-pipeline')}
              >
                <div>
                  <img src={Images.deal} alt="" />
                </div>
                <span>Deal pipeline</span>
              </div>
              <div
                className={'nav-item' + ((path && (path.includes('/mandate-pipeline') || path.includes('/mandate-details'))) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/mandate-pipeline')}
              >
                <div>
                  <img src={Images.mandateSidebar} alt="" />
                </div>
                <span>Mandate pipeline</span>
              </div>
            </div>
            <div className="nav-section">
              <div className="nav-divider"></div>
              <div
                className={'nav-item' + ((path && (path.includes('/investment-firms') || path.includes('/firm-details'))) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/investment-firms')}
              >
                <div>
                  <img src={Images.briefcase} alt="" />
                </div>
                <span>Investment firms</span>
              </div>
              <div
                className={'nav-item' + ((path && (path.includes('/investors') || path.includes('/investor-details'))) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/investors')}
              >
                <div>
                  <img src={Images.chartLine} alt="" />
                </div>
                <span>Investors</span>
              </div>
              <div
                className={'nav-item' + ((path && (path.includes('/advisors') || path.includes('/advisor-details'))) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/advisors')}
              >
                <div>
                  <img src={Images.apple} alt="" />
                </div>
                <span>Advisors</span>
              </div>
            </div>
            <div className="nav-section">
              <div className="nav-divider"></div>
              <div
                className={'nav-item' + ((path && path.includes('/mail-templates')) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/mail-templates')}
              >
                <div>
                  <img src={Images.mailTemplates} alt="" />
                </div>
                <span>Mail templates</span>
              </div>
              <div
                className={'nav-item' + ((path && path.includes('/focus-manager')) ? ' active' : '')}
                onClick={()=>onNavigate && onNavigate('/home/focus-manager')}
              >
                <div>
                  <img src={Images.focusManager} alt="" />
                </div>
                <span>Focus manager</span>
              </div>
            </div>
            <div className="nav-section">
              <div className="nav-divider"></div>
              <div
                className="nav-item btn"
              >
                <div>
                  <img src={Images.xls} alt="" />
                </div>
                <span onClick={props.getExportData}>Extract data</span>
              </div>
            </div>
          </>
      }
        <div className="nav-section">
          <div
            className='nav-item'
            onClick={()=>onNavigate && onNavigate('/logout')}
          >
            <div>
              <img src={Images.signOut} alt="" />
            </div>
            <span>Logout</span>
          </div>
        </div>
      </div>
    </Sider>
  )
}

export default Sidebar
