import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spin, message, Upload } from 'antd'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function RequestIntroductionPopupPage(props) {
	const { result, fetching, match, isAdmin, location } = props;
	const { dashboardId } = match.params;
	const { state } = location;

	const [fileUid, setFileUid] = useState(null);
  const [fileData, setFileData] = useState(null);

	const history = useHistory()

	useEffect(() => {
    props.dealDashboardSyncToggle(false);
    return function cleanup() {
      props.dealDashboardSyncToggle(true);
    }
  }, [0]);

	useEffect(() => {
		if (result) {
			props.getDealDashboardInfo(dashboardId);
			isAdmin ?
				history.push(`/home/match-company/${dashboardId}`)
			:
				history.push(`/deal-dashboard/overview/${dashboardId}/introduction-requested`)
		}
	}, [result]);

	function onChange({ file, fileList }) {
		if (fileUid && fileUid === file.uid) {
      return;
    }
    setFileUid(file.uid);

    let formData = new FormData();

    if (fileList && fileList.length) {
      formData.set('file', fileList[0].originFileObj, fileList[0].originFileObj.name);
    }
    setFileData(formData);
  }

	const checkRequired = () => {
		if (state?.isHistory) {
			history.push(`/deal-dashboard/overview/${dashboardId}`);
			return;
		}
		if (!fileData) {
			message.error('Upload the file', 3);
		} else {
			props.requestIntroByInvestor(dashboardId, fileData);
		}
	}

	return (
		<PopupWrapper
			width={460}
			path={isAdmin ?
				`/home/match-company/${dashboardId}`
				:
				`/deal-dashboard/overview/${dashboardId}`
			}
		>
			<Spin spinning={fetching} size="large">
				<div className='dialog-content' style={{ padding: '20px 24px 30px' }}>
					<img src={Images.phoneOk} alt='' />
					<div className='dialog-title welcome' style={{ marginTop: 10 }}>Request Introduction</div>
					<div className='deal-text'>To request an introduction to the Target, please upload a signed version of IDN's Bilateral contract agreed with your firm. You can find a copy of it on your firm's profile on your main dashboard.</div>
					<br />
					<Upload
						method="get"
						maxCount={1}
						onChange={onChange}
						className="upload-block"
						beforeUpload={file => new Promise((resolve, reject) => {
							if (file.size > 20971520) {
								message.error('File size must not exceed 20 Mbyte', 3);
								reject();
							} else {
								resolve();
							}
						})}
					>
						<Button
							disabled={state?.isHistory}
							type='primary white'
							className='btn-primary upload'
							style={{ width: 62, borderRadius: 5, margin: 6 }}
						>
							<img src={Images.upload} alt='' style={{ height: 20 }} />
						</Button>
					</Upload>
					<br />
					<div className="deal-text sm" style={{width: '90%'}}>Please review IDN's Platform Agreement to remember your<br />rights and obligations in the context of this engagement</div>
					<div className='btn-footer' style={{ marginTop: 24 }}>
						<Button
							type='primary white'
							className='btn-primary welcome'
							style={{ width: 144 }}
							onClick={checkRequired}
						>
							{state?.isHistory ? 'Go back' : 'Confirm'}
					</Button>
					</div>
				</div>
			</Spin>
		</PopupWrapper>
	)
}

const mapStateToProps = (state) => {
	return {
		isAdmin: state.auth.isAdmin,
		result: state.dealDashboard.result,
		fetching: state.dealDashboard.fetching,
	}
}

const mapDispatchToProps = (dispatch) => ({
	getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
	requestIntroByInvestor: (dashboardId, data) => dispatch(DealDashboardActions.investorRequestIntroRequest(dashboardId, data)),
	dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestIntroductionPopupPage)
