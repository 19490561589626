import logo from './logo.svg'
import logoWhite from './logo-white.svg'

import angleDown from './angle-down.svg'
import angleDownLightBlue from './angle-down-light_blue.svg'
import angleUp from './angle-up.svg'
import checkCircle from './check-circle.svg'
import envelope from './envelope.svg'
import globe from './globe.svg'
import globeSm from './globe-sm.svg'
import industry from './industry.svg'
import linkedin from './linkedin.svg'
import lock from './lock.svg'
import mobile from './mobile-alt.svg'
import pdf from './pdf.svg'
import user from './user.svg'
import userEdit from './user-edit.svg'
import key from './key.svg'
import profile from './profile.svg'
import file from './file.svg'
import mandate from './mandate.svg'
import mandateSidebar from './mandate-sidebar.svg'
import clock from './clock.svg'
import briefcase from './briefcase.svg'
import briefcaseSm from './briefcase-sm.svg'
import signOut from './sign-out-alt.svg'
import question from './question.svg'
import questionWhite from './question-white.svg'
import edit from './edit.svg'
import addressBook from './address-book.svg'
import addressBookLg from './address-book-lg.svg'
import addressBookSm from './address-book-sm.svg'
import crosshairs from './crosshairs.svg'
import coins from './coins.svg'
import puzzle from './puzzle-piece.svg'
import bullseye from './bullseye.svg'
import userTie from './user-tie.svg'
import folderOpen from './folder-open.svg'
import userPlus from './user-plus.svg'
import mailBox from './mail-box.svg'
import arrowdown from './down-arrow.svg'
import chartLine from './chart-line.svg'
import apple from './apple-alt.svg'
import mailTemplates from './mail-templates.svg'
import focusManager from './focus-manager.svg'
import search from './search.svg'
import close from './close.svg'
import closeGrey from './close-grey.svg'
import accManager from './acc-manager.svg'
import role from './role.svg'
import editTab from './edit-tab.svg'
import removeTab from './remove-tab.svg'
import monitor from './monitor.svg'
import phoneOk from './phone-ok.svg'
import phoneCancel from './phone-cancel.svg'
import blankOk from './blank-ok.svg'
import filmoscope from './filmoscope.svg'
import handShake from './handshake.svg'
import pen from './pen-alt.svg'
import pencil from './pencil.svg'
import logoPart from './logo-part.svg'
import arrowLeft from './arrow-left.svg'
import company from './company.svg'
import certificate from './certificate.svg'
import check from './check.svg'
import crossOutAddressBook from './cross-out-address-book.svg'
import ex from './x.svg'
import notification from './notification.svg'
import deal from './deal.svg'
import xls from './xls.svg'
import chatBlue from './chat-blue.svg'
import chatOrange from './chat-orange.svg'
import docum from './docum.svg'
import videocall2 from './videocall2.svg'
import phoneclear from './phoneclear.svg'
import upload from './upload.svg'
import bars from './bars.svg'
import comments from './comments.svg'
import reverse from './reverse.svg'
import comment from './comment.svg'
import download from './download.svg'

import bridge from './bridge.png'
import hands from './hands.png'
import people from './people.png'
import smileman from './smileman.png'
import whyIdn from './why-idn.png'
import attentionman from './attentionman.png'
import manwithsmile from './manwithsmile.png'
import handspeople from './handspeople.png'
import handspaper from './handspaper.png'
import handscross from './handscross.png'
import notebook from './notebook.png'
import mannotebook from './mannotebook.png'
import mannotephone from './mannotephone.png'
import businessmeeting from './businessmeeting.png'
import handshake from './handshake.png'
import manbigsmile from './manbigsmile.png'
import peopleconnect from './peopleconnect.png'
import pagetop from './pagetwotop.png'
import handsandnote from './handsandnote.png'
import citymap from './citymap.png'
import ways from './ways.png'
import infra from './infra.png'
import track from './track.png'
import powerlines from './powerlines.png'
import sunbettery from './sunbettery.png'
import antena from './antena.png'
import citysunset from './citysunset.png'
import otherfield from './otherfield.png'
import topfivephoto from './topfivephoto.png'
import whoweare from './whoweare.png'
import gautiersegouin from './gautiersegouin.png'
import enricocattabiani from './enricocattabiani.png'
import vasiliysoshnikov from './vasiliysoshnikov.png'
import attach from './attach.png'
import building1 from './building1.svg'
import exclamation from './exclamation.svg'
import bell from './bell.svg'
import downloadtop from './downloadtop.svg'
import downloadtop_blue from './downloadtop_blue.svg'
import word_icon from './word_icon.png'
import pencil_white from './pencil-white.svg'
import target1 from './target1.png'
import url from './url.png'

const Images = {
  logo: logo,
  logoWhite: logoWhite,

  // backgraunds
  bridge: bridge,
  hands: hands,
  people: people,
  smileman: smileman,
  whyIdn: whyIdn,
  antena: antena,
  attentionman: attentionman,
  businessmeeting: businessmeeting,
  citymap: citymap,
  citysunset: citysunset,
  handsandnote: handsandnote,
  handscross: handscross,
  handshake: handshake,
  handspeople: handspeople,
  handspaper: handspaper,
  ways: ways,
  infra: infra,
  track: track,
  powerlines: powerlines,
  sunbettery: sunbettery,
  otherfield: otherfield,
  manbigsmile: manbigsmile,
  peopleconnect: peopleconnect,
  pagetop: pagetop,
  manwithsmile: manwithsmile,
  notebook: notebook,
  mannotebook: mannotebook,
  mannotephone: mannotephone,
  topfivephoto: topfivephoto,
  whoweare: whoweare,
  gautiersegouin: gautiersegouin,
  enricocattabiani: enricocattabiani,
  vasiliysoshnikov: vasiliysoshnikov,

  // icons
  angleDown: angleDown,
  angleDownLightBlue: angleDownLightBlue,
  angleUp: angleUp,
  checkCircle: checkCircle,
  envelope: envelope,
  globe: globe,
  globeSm: globeSm,
  industry: industry,
  linkedin: linkedin,
  lock: lock,
  mobile: mobile,
  pdf: pdf,
  user: user,
  userEdit: userEdit,
  key: key,
  profile: profile,
  file: file,
  mandate: mandate,
  mandateSidebar: mandateSidebar,
  clock: clock,
  briefcase: briefcase,
  briefcaseSm: briefcaseSm,
  signOut: signOut,
  question: question,
  questionWhite: questionWhite,
  edit: edit,
  addressBook: addressBook,
  addressBookLg: addressBookLg,
  addressBookSm: addressBookSm,
  crosshairs: crosshairs,
  coins: coins,
  puzzle: puzzle,
  bullseye: bullseye,
  userTie: userTie,
  folderOpen: folderOpen,
  userPlus: userPlus,
  mailBox: mailBox,
  arrowdown: arrowdown,
  chartLine: chartLine,
  apple: apple,
  mailTemplates: mailTemplates,
  focusManager: focusManager,
  search: search,
  close: close,
  closeGrey: closeGrey,
  accManager: accManager,
  role: role,
  editTab:editTab,
  removeTab: removeTab,
  monitor: monitor,
  phoneOk: phoneOk,
  phoneCancel: phoneCancel,
  blankOk: blankOk,
  filmoscope: filmoscope,
  handShake: handShake,
  pen: pen,
  logoPart: logoPart,
  arrowLeft: arrowLeft,
  company: company,
  certificate: certificate,
  check: check,
  crossOutAddressBook: crossOutAddressBook,
  ex: ex,
  notification: notification,
  deal: deal,
  xls: xls,
  chatBlue: chatBlue,
  chatOrange: chatOrange,
  videocall2: videocall2,
  docum: docum,
  phoneclear: phoneclear,
  upload: upload,
  bars: bars,
  comments: comments,
  comment: comment,
  attach: attach,
  reverse: reverse,
  download: download,
  pencil: pencil,
  building1: building1,
  exclamation: exclamation,
  bell: bell,
  downloadtop: downloadtop,
  downloadtop_blue: downloadtop_blue,
  word_icon: word_icon,
  pencil_white: pencil_white,
  target1: target1,
  url: url,
}

export default Images
