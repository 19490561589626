const AppUtils = {

  toCamelCase(str, firstUpper) {
    const camelCase = str.replace(/_([a-z])/g, (m, w) => w.toUpperCase());
    if (!firstUpper) return camelCase;
    return camelCase.charAt(0).toUpperCase() + camelCase.slice(1);
  },

  toPascalCase(str) {
    if (!str) return;
    let arr = str.split('_');
    arr = arr.map(i => (
      this.toUpperLatter(i.toLocaleLowerCase())
    ));
    return arr.join('');
  },

  toUpperLatter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  tryToBool(str) {
    if (str === 'false') return false;
    if (str === 'true') return true;
    return str;
  },

  random() {
    return Math.floor(Math.random() * 0xFFFF);
  },

  cutUrlToPrevious(str) {
    if (!str || typeof str !== 'string') return;
    let index = str.lastIndexOf('/');
    return str.slice(0, index);
  },

  formatValue(val) {
    if (val == null || isNaN(val)) {
      return;
    }
    val = Math.round(val)
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },

  checkEmptyValue(arr) {
    if (Array.isArray(arr) && arr.length === 2) {
      return arr.every(el => el != null);
    }
    return false;
  },

  clearSpaces(e, field, fn) {
    if (e?.target?.value?.includes(' ')) {
      const newValue = e.target.value.split('').filter(el => el !== ' ').join('');
      fn && fn({ [field]: newValue });
    }
  }
};

export default AppUtils;

export const openHideLink = (url) => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', 'fileName.pdf');
  // link.setAttribute('target', '_blank');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
