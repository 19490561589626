import React, { useState, useEffect } from 'react'
import { Layout, Spin, message } from 'antd'
import { connect } from 'react-redux'

import AuthActions from '../Redux/AuthRedux'
import AdvisorPreviewStep1 from '../Components/AdvisorPreview/AdvisorPreviewStep1'
import AdvisorPreviewStep2 from '../Components/AdvisorPreview/AdvisorPreviewStep2'
import AdvisorPreviewFinish from '../Components/AdvisorPreview/AdvisorPreviewFinish'
import errorHandler from '../Utils/errorHandler'

function AdvisorPreviewPage(props) {
  const { result, error, fetching, introResult } = props;

  const [previewData, setPreviewData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [step, setStep] = useState(1);

  function changeStep(data) {
    if (Number(step) === 1) {
      setPreviewData(data);
      props.checkEmail(data.email);
    } else if (Number(step) === 2) {
      const newData = {...userData, ...data};
      props.sendForm(newData);
    }
  }

  useEffect(() => {
    if (error) {
      if (error.is_registered) {
        message.error(`User with this Email already exists`, 5);
        props.resetError();
      } else {
        errorHandler(error, props.resetError);
      }
    }
  }, [error]);

  useEffect(() => {
    if (result) {
      setUserData(previewData);
      setPreviewData(null);
      setStep(step + 1);
    }
  }, [result]);

  useEffect(() => {
    if (introResult) {
      setStep(step + 1);
    }
  }, [introResult]);

  return (
    <Layout>
      {
        step === 1 &&
          <AdvisorPreviewStep1
            fetching={fetching}
            onChangeStep={changeStep}
            onClose={props.onClose}
          />
      }
      {
        step === 2 &&
          <AdvisorPreviewStep2
            fetching={fetching}
            onChangeStep={changeStep}
            onClose={props.onClose}
          />
      }
      {
        step === 3 &&
          <AdvisorPreviewFinish
            onClose={props.onClose}
          />
      }
    </Layout>
  )
}


const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    result: state.auth.result,
    error: state.auth.error,
    fetching: state.auth.fetching,
    introResult: state.auth.introResult,

  }
}

const mapDispatchToProps = (dispatch) => ({
  checkEmail: (email) => dispatch(AuthActions.checkEmailExistRequest(email)),
  resetError: () => dispatch(AuthActions.authErrorReset()),

  sendForm: (data) => dispatch(AuthActions.advisorIntroRequest(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorPreviewPage)
