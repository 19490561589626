import React from 'react'
import { Space } from 'antd'
import { useHistory } from 'react-router-dom'

import Images from '../../Images'


function AdvisorSignUpFinish(props) {
  const history = useHistory();

  return (
    <div className="sign-up-step with-bg">
      <div className="with-bg-top">
        <img src={Images.logoWhite} alt="" onClick={()=>history.push('/')} />
      </div>
      <div className="modal-container" style={{width: 360, padding: '32px 40px'}}>
        <Space style={{marginBottom: 24}}>
          <img src={Images.phoneOk} alt="" />
        </Space>
        <div className="step-finish-title">Sign up completed!</div>
        <div className="step-finish-divider"></div>
        <div className="step-finish-text" style={{marginBottom: 0}}>We have sent you an email to your address with a verification link. Please click on it in order to log in to your account and access your dashboard</div>
      </div>
    </div>
  )
}

export default AdvisorSignUpFinish
