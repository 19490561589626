import React, { useState, useEffect } from 'react'
import { Button, Radio, Input, Spin, message } from 'antd'
import { useHistory } from 'react-router-dom'

import { connect } from 'react-redux'

import DealsActions from '../../Redux/DealsRedux'

import PopupWrapper from './PopupWrapper';

import './PopupPage.less'

const { TextArea } = Input;

const radioStyle = {
  display: 'block',
  lineHeight: '30px',
  marginBottom: 20,
  font: 'normal normal 500 15px/24px Poppins'
};

const radioOptions = [
  'The investor is too slow to respond',
  'I found an alternative investor',
  'Other (please specify)'
];

function WithdrawMandateDealPopupPage(props) {

  const { match, result, isAdmin, location, fetching } = props;

  const { dealId } = match.params;

  const { state } = location;

  const history = useHistory();

  const [value, setValue] = useState(null);
  const [other, setOther] = useState(null);

  function handleClose() {
    if (isAdmin) {
      // props.getDeal(dealId);
      history.push(`/home/deal-pipeline`);
    } else {
      props.getDeal(dealId);
      history.push(`/home/mandate-deal/${dealId}/reshare-mandate-deal`);
    }
  }

  useEffect(() => {
    if (result) {
      handleClose();
    }
  }, [result]);

  const checkRequired = () => {
    if (value == null || (value === 2 && !other)) {
      message.error('Choose a reason', 3);
    } else {
      const data = {
        withdraw_reason: value === 2 ? other : radioOptions[value]
      };
      props.withdrawDeal(dealId, data);
    }
  }

  return (
    <PopupWrapper
      width={460}
      path={isAdmin ?
          `/home/deal-details/${dealId}/info`
        :
          `/home/mandate-deal/${dealId}`
      }
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content" style={{ padding: '24px 48px' }}>
          <div className="dialog-title deal">Withdraw Deal</div>
          <div className="deal-text">We are sorry to hear that you want to withdraw this deal. Please provide us with the reason for the withdrawn:</div>
          <br />
          <Radio.Group
            onChange={(e) => setValue(e.target.value)}
          >
            {
              radioOptions.map((el, i) => (
                <Radio style={radioStyle} value={i} key={i}>
                  {el}
                </Radio>
              ))
            }
          </Radio.Group>
          <div className="area-block">
            <span>Insert reason</span>
            <TextArea
              className={value === 2 && !other && 'error-input'}
              rows={4}
              // disabled={value !== 2}
              onBlur={(e) => setOther(e.target.value)}
            />
          </div>
          <div className="btn-footer" style={{ marginTop: 24 }}>
            <Button type="primary white" className="btn-primary" onClick={checkRequired}>
              Confirm
            </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    result: state.deals.dealWithdrawResult,
    fetching: state.deals.fetching,
    isAdmin: state.auth.isAdmin,
  }
}

const mapDispatchToProps = (dispatch) => ({
  withdrawDeal: (dealId, data) => dispatch(DealsActions.dealWithdrawRequest(dealId, data)),
  getDeal: (dealId) => dispatch(DealsActions.dealRequest(dealId)),
})
export default connect(mapStateToProps, mapDispatchToProps)(WithdrawMandateDealPopupPage)
