import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import DealsActions from '../../../Redux/DealsRedux'
import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function ReshareDealPopupPage(props) {
  const { result, fetching, dealId_, dealId, match, location, isAdmin, dealTargetStatus } = props;
  const { state } = location;
  const { dashboardId } = match.params;

  const history = useHistory();
  const [targetId, setTargetId] = useState(null);

  useEffect(() => {
    props.dealDashboardSyncToggle(false);
    props.getDealTargetStatus(dealId ? dealId : dealId_);
    return function cleanup() {
      if (isAdmin) {
        props.dealDashboardSyncToggle(true);
      }
    }
  }, [0]);

  useEffect(() => {
    if (dealTargetStatus) {
      setTargetId(dealTargetStatus.id);
    }
  }, [dealTargetStatus]);

  useEffect(() => {
    if (result) {
      if (isAdmin) {
        props.getDealDashboardInfo(dashboardId);
        history.push(`/home/match-company/${dashboardId}`);
      } else {
        // props.resetDealDashboard();
        history.push({
          pathname: `/home/deal-sharing/step/start`,
          state: {
            dealId: dealId ? dealId : dealId_,
            targetId: targetId,
          }
        });
      }
    }
  }, [result]);

  const cancelResharing = () => {
    if (isAdmin) {
      history.push(`/home/match-company/${dashboardId}`)
    } else if (state?.withdrawn) {
      history.push(`/home/deal/${dealId_}/summary`);
    } else if (state?.terminated) {
      props.dealDashboardSyncToggle(true);
      history.push(`/deal-dashboard/deal-details/${dealId}`);
    } else {
      props.resetDealDashboard();
      history.push(`/home/deals`);
    }
  }

  const generatePath = () => {
    if (state?.withdrawn) {
      return `/home/deal/${dealId_}/summary`;
    } else if (state?.terminated) {
      return `/deal-dashboard/deal-details/${dealId}`;
    }
  }

  return (
    <PopupWrapper
      width={360}
      path={generatePath()}
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content" style={{ padding: 30, paddingTop: 12, paddingBottom: 30 }}>
          <img src={Images.phoneOk} alt="" />
          <div className="dialog-title welcome" style={{ margin: 6 }}>Re-share Deal</div>
          <div className="deal-text engagment" style={{ marginTop: 14 }}>Would you like to re-share the deal on <br /> IDN's platform and connect to new <br /> investors? If so, we will be in touch <br /> shortly to discuss next steps. If not, the <br /> deal will be officially closed.</div>
          <div className="btn-footer" style={{ marginTop: 16 }}>
            <Button type="primary white"
              className="btn-primary welcome"
              style={{ width: 91, minWidth: 'auto' }}
              onClick={cancelResharing}
            >
              No
            </Button>
            <Button type="primary white"
              className="btn-primary welcome"
              style={{ width: 91, minWidth: 'auto' }}
              onClick={() => props.reshareDealRequestByAdvisor(state?.withdrawn ? dealId_ : dealId)}
            >
              Yes
            </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    result: state.deals.dealReshareResult,
    fetching: state.deals.fetching,
    dealId_: state.deals.dealId,
    dealId: state.dealDashboard.dealId,
    dealTargetStatus: state.deals.dealTargetStatus,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
  reshareDealRequestByAdvisor: (id) => dispatch(DealsActions.dealReshareRequestByAdvisorRequest(id)),
  dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
  resetDealDashboard: () => dispatch(DealDashboardActions.dealDashboardReset()),
  getDealTargetStatus: (dealId) => dispatch(DealsActions.dealTargetStatusRequest(dealId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReshareDealPopupPage)
