import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'

import Images from '../../Images'


function ExpertisePage(props) {
  const { role } = props;

  const link = 'https://docs.google.com/forms/d/1jdNYHCwnTHXXvQ9zVnIrTkxmN0MneKlvfjvIKNUoz4Y/edit'

  return (
    <div className="main-container expertise">
      {
        role === 0 ?
          <>
            <div className="section-title">Expertise request</div>
            <div className="section-content info">You don’t have any active expertise request. To increase your chances of receiving requests, please update your work experience in your "Experience" section</div>
          </>
          :
          <>
            <div className="section-title">Expert Consulting</div>
            <div className="section-content" style={{ marginBottom: 'auto' }}>
              <div className="section-text">
                <span>Looking for expert support on a due diligence?</span>
                <span>Place an <b>expertise request</b> to find an industry advisor</span>
                <span className="blue">Be supported by IDN’s key executives</span>
              </div>
              <Button type="primary orange-margin" className="btn-primary deal-btn">
                <a className="share-now-link" href={link} target="_blank" rel="noopener noreferrer">
                  <span>Share now</span>
                </a>
              </Button>
            </div>

            <div className="section-content bottom">
              <div className="section-title">How it works</div>
              <div className="section-text">
                <span>IDN makes it easy and transparent to engage Senior Advisors as industry experts for long-term consulting work typically as part of due diligence and portfolio work. <br/> By sharing an Expert Consulting request, you can confidentially connect and select <br/> Senior Advisors with no upfront costs. <br/> Get  in touch with your dedicated point of contact at IDN to learn more.</span>
              </div>
            </div>
          </>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    role: state.auth.role
  }
}

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpertisePage)
