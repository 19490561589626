import React, { useEffect } from 'react'
import { Spin, Button } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import PopupWrapper from './PopupWrapper'
import UsersActions from '../../Redux/UsersRedux'
import FirmsActions from '../../Redux/FirmsRedux'
import AddInvestorPopupForm from '../../Components/Forms/AddInvestorPopupForm'

import './PopupPage.less'

function AddInvestorPopupPage(props) {
  const { firms, roles, result, fetching } = props;

  const history = useHistory();

  function handleClose() {
    history.push('/home/investors');
  }

  function handleSubmit(data) {
    props.createUser('investor', data);
  }

  useEffect(() => {
    props.getFirms();
    props.getRoles();
  }, [0]);

  useEffect(() => {
    if (result) {
      handleClose();
    }
  }, [result]);

  return (
    <PopupWrapper
      width={600}
      path={'/home/investors'}
      hasDropdown={true}
    >
      <Spin spinning={fetching} size="large">
        <div style={{ padding: 40 }}>
          <div className="dialog-content">
            <div className="dialog-title">New Investor</div>
            <AddInvestorPopupForm
              firms={firms}
              roles={roles}
              onSubmit={handleSubmit}
            />
            <div className="btn-footer">
              <Button type="primary white" className="btn-primary" onClick={handleClose}>
                Cancel
                </Button>
              <Button type="primary" htmlType="submit" form="add-investor-popup-form" className="btn-primary">
                Save
                </Button>
            </div>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}


const mapStateToProps = (state) => {
  return {
    firms: state.firms.list,
    roles: state.users.investorRoles,
    result: state.users.saveResult,
    fetching: state.users.saveFetching
  }
}

const mapDispatchToProps = (dispatch) => ({
  getFirms: () => dispatch(FirmsActions.firmsRequest()),
  getRoles: () => dispatch(UsersActions.investorRolesRequest()),
  createUser: (role, data) => dispatch(UsersActions.userSaveRequest(role, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddInvestorPopupPage)
