import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Select, TreeSelect } from 'antd'
import _ from 'lodash'

import Images from '../../Images'

const { Option } = Select;

const initial = {
  sectors: [],
  geography: [],
  adv_status: null,
  account_manager: null,
}

function ShareAdvisorsFilterPanel(props) {
  const { active, sectors, continents, choices, initialFilters } = props;

  const [data, setData] = useState(null);
  const [sectorTree, setSectorTree] = useState(null);
  const [continentTree, setcontinentTree] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [managerList, setManagerList] = useState([]);

  useEffect(() => {
    const newData = initialFilters ? _.cloneDeep(initialFilters) : _.cloneDeep(initial);
    applyFilters(newData, true);
    setData(newData);
  }, [0]);

  useEffect(() => {
    if (sectors) {
      const tree = sectors.map(sector => ({
        title: sector.name,
        value: sector.id,
        children: sector.children.map(child => ({
          title: child.name,
          value: child.id,
          children: child.children.map(el => ({
            title: el.name,
            value: el.id,
          }))
        }))
      }));
      setSectorTree(tree);
    }
  }, [sectors]);

  useEffect(() => {
    if (continents) {
      const tree = continents.map(continent => ({
        title: continent.name,
        value: continent.id,
        children: continent.children.map(child => ({
          title: child.name,
          value: child.id,
          children: child.children.map(el => ({
            title: el.name,
            value: el.id,
          }))
        }))
      }));
      setcontinentTree(tree);
    }
  }, [continents]);

  useEffect(()=>{
    if (choices) {
      if (choices.statuses) {
        const res = [];
        for (let i = 0; i < choices.statuses.length; i++) {
          res.push(<Option key={choices.statuses[i].id}>{choices.statuses[i].name}</Option>);
        }
        setStatusList(res);
      }
      if (choices.managers) {
        const res = [];
        for (let i = 0; i < choices.managers.length; i++) {
          res.push(<Option key={choices.managers[i].id}>{choices.managers[i].name}</Option>);
        }
        setManagerList(res);
      }
    }
  }, [choices]);

  const changeFilter = (field, value) => {
    const newData = _.cloneDeep(data);
    if (field === 'investment_type') {
      newData[field] = [value];
    } else {
      newData[field] = value;
    }
    setData(newData);
  }

  const clearFilter = (fields, isArray) => {
    const newData = _.cloneDeep(data);
    fields.forEach(field => {
      newData[field] = isArray ? [] : null;
      if (field === 'investment_type') {
        newData.comment = null;
      }
    })
    setData(newData);
    applyFilters(newData);
  }

  const applyFilters = (data, start) => {
    let count = 0;
    for (let key in data) {
      if ((!Array.isArray(data[key]) && data[key] != null) || (Array.isArray(data[key]) && data[key].length)) {
        count += 1;
      }
    }
    props.changeCount(count, data, start);
  }

  const clearAll = () => {
    setData(_.cloneDeep(initial));
    applyFilters(null);
  }

  const apply = data => {
    applyFilters(data);
    props.closeFilter();
  }

  return (
    <div className="filter-panel" style={{ display: active ? 'block' : 'none' }}>
      {
        data &&
        <>
          <Row gutter={[16, 24]} style={{ marginBottom: 24 }}>
            <Col span={12} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Sectors</div>
              <TreeSelect
                value={data.sectors}
                showSearch
                multiple
                style={{ minWidth: 'auto', width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={sectorTree}
                placeholder="Please select"
                treeDefaultExpandAll
                onChange={(value) => changeFilter('sectors', value)}
              />
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['sectors'], true)} />
            </Col>
            <Col span={12} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Geography</div>
              <TreeSelect
                value={data.geography}
                showSearch
                multiple
                style={{ minWidth: 'auto', width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={continentTree}
                placeholder="Please select"
                treeDefaultExpandAll
                onChange={(value) => changeFilter('geography', value)}
              />
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['geography'], true)} />
            </Col>
          </Row>
          <Row gutter={[16, 24]} style={{ marginBottom: 24 }}>
            <Col span={12} className="flex-center-vertical">
            <div className="flex-center-vertical filter-title">Status</div>
              <Select
                value={data.adv_status}
                style={{ minWidth: 'auto', width: '100%' }}
                placeholder="Please select"
                onChange={(value) => changeFilter('adv_status', value)}
              >
                {statusList}
              </Select>
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={()=>clearFilter(['adv_status'])} />
            </Col>
            <Col span={12} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Account manager</div>
              <Select
                value={data.account_manager}
                style={{ minWidth: 'auto', width: '100%' }}
                placeholder="Please select"
                onChange={(value) => changeFilter('account_manager', value)}
              >
                {managerList}
              </Select>
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={()=>clearFilter(['account_manager'])} />
            </Col>
          </Row>
        </>
      }
      <Row gutter={[16, 24]} style={{ marginTop: 0, marginBottom: 0 }} justify="end">
        <div className="filter-actions flex-center-vertical">
          <span className="clear-all" onClick={clearAll}>Reset</span>
          <Button type="primary orange" className="btn-primary" onClick={() => apply(data)}>
            Apply
          </Button>
        </div>
      </Row>
    </div>
  )
}

export default ShareAdvisorsFilterPanel
