import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from '../../Redux/DealDashboardRedux'
import DealSharesActions from '../../Redux/DealSharesRedux'
import PopupWrapper from './PopupWrapper'
import Images from '../../Images'
import './PopupPage.less'

function ConfirmingMatchMandatePopupPage(props) {
  const { result, fetching, matchResult, fetching_, match, location } = props;
  const { dealId, shareId } = match.params;
  const { state } = location;

  const history = useHistory();

  useEffect(() => {
    if (!state) {
      history.push('/home/deals');
    }
    props.getDealMatchInfo(shareId);
  }, [0]);

  useEffect(() => {
    if (matchResult && matchResult.dashboard) {
      history.push('/home/deals');
    }
  }, [matchResult]);

  useEffect(() => {
    if (result) {
      history.push({
        pathname: '/home/target-company-disclosed',
        state: {
          ...state,
          targetCompanyId: result.id
        }
      });
    }
  }, [result]);

  const onConfirm = () => {
    const data = {...state?.data};
    if (data) {
      delete data.advisor;
      props.saveTargetCompany(data, dealId);
    }
  }

  return (
    <PopupWrapper
      width={420}
      path={`/home/deal-for-mandate/${dealId}${shareId ? '/' + shareId : ''}`}
    >
      <Spin size="large" spinning={fetching || fetching_}>
      <div className="dialog-content" style={{ padding: '40px 56px' }}>
        <img src={Images.phoneOk} alt="" />
        <div className="dialog-title deal">Confirming Match</div>
        <div className="deal-text">By confirming this match, the name of the target company as well as of the Senior Advisor will be disclosed.</div>
        <br />
        <br />
        <div className="deal-text sm">Please review IDN's Platform Agreement to remember your rights and obligations in the context of this engagement</div>
        <div className="btn-footer" style={{ marginTop: 20 }}>
          <Button type="primary white" className="btn-primary" onClick={onConfirm}>
            Continue
          </Button>
        </div>
      </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    result: state.dealDashboard.saveResult,
    fetching: state.dealDashboard.saveFetching,
    matchResult: state.dealShares.matchResult,
    fetching_: state.dealShares.fetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveTargetCompany: (data, dealId) => dispatch(DealDashboardActions.targetCompanySaveRequest(data, dealId)),

  getDealMatchInfo: (shareId) => dispatch(DealSharesActions.dealShareMatchRequest(shareId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmingMatchMandatePopupPage)
