import React from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function AgreementExtensionSharedPopupPage({ match }) {
  const {dashboardId} = match.params

  const history = useHistory();

  return (
    <PopupWrapper
      width={420}
      path={`/deal-dashboard/overview/${dashboardId}`}
      hasDropdown={true}
    >
      <div className="dialog-content" style={{ padding: 20, paddingTop: 30, paddingBottom: 30}}>
        <img src={Images.phoneOk} alt="" />
        <div className="dialog-title bold" style={{ margin: 6 }}>Agreement extension shared</div>
        <div className="deal-text" style={{ marginTop: 34 }}>The extension of the Expert Consulting <br/> Agreement has been successfully shared <br/> with the Senior Advisor. You will be <br/> notified when the Senior Advisor agrees to it.</div>
        <div className="btn-footer" style={{marginTop: 40}}>
        <Button type="primary white"
        className="btn-primary flat"
        style={{ width: 144 }}
        onClick={() => history.push({ pathname: `/deal-dashboard/overview/${dashboardId}`})}>
          Confirm
        </Button>
        </div>
      </div>
    </PopupWrapper>
  )
}

export default AgreementExtensionSharedPopupPage
