import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Button } from 'antd'
import moment from 'moment'

import PaginationItemRender from '../PaginationItemRender'
import CustomIcon from '../../Icon'
import Images from '../../Images'

import './Table.less'

function DealsAdvisorTable(props){
  const {
    page,
    pageSize,
    data,
    fetching,
    tab
  } = props;

  const [dataSource, setDataSource] = useState(null);

  const history = useHistory();

  useEffect(() => {
    let arr = (data && data.results) ? data.results.asMutable({deep:true}) : [];
    arr = arr.map(el => ({
      id: el.id,
      statusName: el.status_obj?.name,
      shared_on: el.shared,
      subsector: el.subsector,
      geography: el.geography,
      ev_range: el.ev_range,
      status: el.status_obj?.id,
      isNotification: el.advisor_notification,
      dashboardId: el.dashboard,
      mandate: el.mandate,
      check: el.reshared
    }))
    setDataSource(arr);
  }, [data]);

  function getColumns() {
    return [
      {
        title: 'Deal code',
        dataIndex: 'id',
        key: 'id',
        className: 'cell-center',
      },
      {
        title: 'Status',
        dataIndex: 'statusName',
        key: 'statusName',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <div className={`deal-status${record.isNotification ? ' orange' : ''}`}>{text}</div>
          )
        },
      },
      {
        title: 'Shared on',
        dataIndex: 'shared_on',
        key: 'shared_on',
        className: 'cell-center',
        render: (text, record) => {
          return (
              <span>{text && moment(text).format('DD/MM/YYYY')}</span>
          )
        },
      },
      {
        title: 'Sector',
        dataIndex: 'subsector',
        key: 'subsector',
        render: (text, record) => {
          return (
            text?.map((el, i) => (
              <div key={i}>{el}</div>
            ))
          )
        },
      },
      {
        title: 'Geography',
        dataIndex: 'geography',
        key: 'geography',
        render: (text, record) => {
          return (
            text?.map((el, i) => (
              <div key={i}>{el}</div>
            ))
          )
        },
      },
      {
        title: 'EV range',
        dataIndex: 'ev_range',
        key: 'ev_range',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <span style={{whiteSpace: 'nowrap'}}>{text ? `${text}ml` : 'N/A'}</span>
          )
        },
      },
      {
        title: 'Mandate',
        dataIndex: 'mandate',
        key: 'mandate',
        className: 'cell-center',
        render: (text) => {
          return (
            <span>{text ? `YES` : 'NO'}</span>
          )
        },
      },
      {
        title: '',
        dataIndex: 'status',
        key: 'status',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <div className="deal-action">
              {
                record.isNotification &&
                  <img src={Images.notification} alt="" />
              }
              <Button type="primary" className="btn-primary" onClick={()=>generateBtnAction(record)}>
                {generateBtnText(record)}
              </Button>
            </div>
          )
        },
      }
    ];
  }

  function handleTableChange(pagination) {
    props.onChangePage(pagination.current, pagination.pageSize)
  }

  const totalCount = data ? data.count : 0;

  const generateBtnText = (record) => {
    if (record?.status) {
      switch(record.status) {
        case 'resharing':
        case 'm_deal_in_progress':
        case 'in_progress': return 'Continue';
        case 'won':
        case 'engaged': return 'Go to dashboard';
        case 'closed': {
          if (record.dashboardId) {
            return 'Go to dashboard';
          } else {
            return 'View'
          }
        }
        default: return 'View'
      }
    }
  }

  const generateBtnAction = record => {
    if (record?.status) {
      switch(record.status) {
        case 'm_deal_in_progress':
        case 'in_progress': {
          props.getDeal(record.id);
          break;
        }
        case 'sharing_in_progress': {
          // history.push({
          //   pathname: `/home/mandate-deal-sharing/step/review`,
          //   state: {
          //     dealId: record.id,
          //   },
          // })
          props.getDeal(record.id);
          break;
        }
        case 'won':
        case 'engaged': {
          if (record.isNotification) {
            props.deleteNotification(record.id);
          } else {
            history.push({
              pathname: `/deal-dashboard/overview/${record.dashboardId}`,
              state: {backPath: `/home/deals/${tab}`, abortUpdate: true}
            });
          }
          break;
        }
        case 'closed': {
          if (record.isNotification) {
            props.deleteNotification(record.id);
          } else {
            if (record.dashboardId) {
              history.push({
                pathname: `/deal-dashboard/overview/${record.dashboardId}`,
                state: {backPath: `/home/deals/${tab}`, abortUpdate: true}
              });
            } else {
              record.mandate ?
                history.push({
                  pathname: `/home/mandate-deal/${record.id}`,
                  state: {backPath: '/home/deals/closed'}
                })
              :
                history.push({
                  pathname: `/home/deal/${record.id}`,
                  state: {tab: 'closed'}
                });
            }
          }
          break;
        }
        case 'resharing': {
          if (record.isNotification) {
            props.deleteNotification(record.id);
          } else {
            if (record.check) {
              history.push({
                pathname: `/home/deal-sharing/step/start`,
                state: {
                  dealId: record.id,
                  fromMandate: true
                }
              });
            } else {
              props.getDealTargetStatus(record.id);
            }
          }
          break;
        }
        default: {
          if (record.isNotification) {
            props.deleteNotification(record.id);
          } else {
            record.mandate ?
              history.push({
                pathname: `/home/mandate-deal/${record.id}`,
                state: {backPath: `/home/deals/${tab ? tab : ''}`}
              })
            :
              history.push({
                pathname: `/home/deal/${record.id}`,
                state: {tab}
              });
          }
          break;
        }
      }
    }
  }

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount, showSizeChanger: true}}
      className="deal-table"
      rowKey={row => row.id}
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
      showSorterTooltip={false}
    />
   )


}

export default DealsAdvisorTable
