import React from 'react'
import { Form, Input } from 'antd'

const { TextArea } = Input;

const layout = {
  labelCol: { span: 5, offset: 3 },
  wrapperCol: { span: 13 },
};

function DiscloseCompanyInvestorForm(props) {
  const { data, mandateDeal } = props;

  const message = mandateDeal ? 'Send an introductory message to the Senior Advisor' : 'Message From Advisor';

  const onFinish = values => {
    const data = { ...values };

    props.onSubmit(data);
  }

  return (
    data &&
      <Form
        id="disclose-company-form"
        {...layout}
        onFinish={onFinish}
        requiredMark={false}
        initialValues={data}
      >

        <Form.Item
          name="name"
          label="Target Company:"
          className="input-block"
        >
          <Input
            placeholder="Target Company"
            disabled={true}
          />
        </Form.Item>
        <Form.Item
          name="website"
          label="Link to Website:"
          className="input-block"
        >
          <Input
            placeholder="Link"
            disabled={true}
          />
        </Form.Item>
        <Form.Item
          name="advisor"
          label="Advisor Name:"
          className="input-block"
        >
          <Input
            placeholder="Advisor Name"
            disabled={true}
          />
        </Form.Item>
        <Form.Item
          name="comment"
          label={message}
          className="area-block"
          labelCol={{ span: 0, offset: 1 }}
          wrapperCol={24}
        >
          <TextArea
            disabled={!mandateDeal}
          />
        </Form.Item>
      </Form>
  );
}

export default DiscloseCompanyInvestorForm
