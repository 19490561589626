import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Button } from 'antd'
// import moment from 'moment'

import PaginationItemRender from '../PaginationItemRender'
import CustomIcon from '../../Icon'
import Images from '../../Images'

import './Table.less'

function DealsInvestorTable(props) {
  const {
    page,
    pageSize,
    data,
    tab,
    fetching,
    hideCode,
    allDeal
  } = props;

  const [dataSource, setDataSource] = useState(null);
  const [visibleData, setVisibleData] = useState(null);
  const [start, setStart] = useState(false);

  const history = useHistory();

  useEffect(() => {
    let arr = (data && data.results) ? data.results.asMutable({ deep: true }) : [];
    if (allDeal) {
      arr = arr.map(el => ({
        dealId: el.id,
        status: el.investor_status_obj?.name,
        //received: el.received,
        subsector: el.subsector,
        geography: el.geography,
        ev_range: el.ev_range,
        ev_ticket: el.ev_ticket,
        equity_stake: el.ev_stake,
        action: el.investor_status_obj?.id,
        isNotification: el.investor_notification,
        mandate: el.mandate,
        visible: false,
        overview: el.deal_overview
      }))
    } else {
      arr = arr.map(el => ({
        id: el.id,
        dealId: el.deal?.id,
        status: el.investor_status_obj?.name,
        //received: el.received,
        subsector: el.deal?.subsector,
        geography: el.deal?.geography,
        ev_range: el.deal?.ev_range,
        ev_ticket: el.deal?.ev_ticket,
        equity_stake: el.deal?.ev_stake,
        action: el.investor_status_obj?.id,
        isNotification: el.investor_notification,
        dashboardId: el.dashboard,
        isClosed: !!el.closed,
        mandate: el.deal?.mandate,
        visible: false,
        overview: el.deal?.deal_overview
      }))
    }
    if (!start) {
      const obj = {};
      arr.forEach(el => {
        obj[el.dealId] = false;
      });
      setVisibleData(obj);
      setStart(true);
    }
    setDataSource(arr);
  }, [data]);

  function getColumns() {
    return [
      {
        title: 'Deal code',
        dataIndex: 'dealId',
        key: 'dealId',
        className: hideCode ? 'hidden-column' : 'cell-center',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        className: hideCode ? 'cell-center bordered' : 'cell-center',
        render: (text, record) => {
          return (
            <div className={`deal-status${record.isNotification ? ' orange' : ''}`}>{text}</div>
          )
        },
      },
      // {
      //   title: allDeal ? 'Shared on' : 'Received on',
      //   dataIndex: 'received',
      //   key: 'received',
      //   className: 'cell-center',
      //   render: (text, record) => {
      //     return (
      //         <span>{text && moment(text).format('DD/MM/YYYY')}</span>
      //     )
      //   },
      // },
      {
        title: 'Sector',
        dataIndex: 'subsector',
        key: 'subsector',
        render: (text, record) => {
          return (
            text?.map((el, i) => (
              <div key={i}>{el}</div>
            ))
          )
        },
      },
      {
        title: 'Geography',
        dataIndex: 'geography',
        key: 'geography',
        render: (text, record) => {
          return (
            text?.map((el, i) => (
              <div key={i}>{el}</div>
            ))
          )
        },
      },
      {
        title: 'EV range',
        dataIndex: 'ev_range',
        key: 'ev_range',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <span style={{ whiteSpace: 'nowrap' }}>{text ? `${text}ml` : 'N/A'}</span>
          )
        },
      },
      {
        title: 'Equity ticket',
        dataIndex: 'ev_ticket',
        key: 'ev_ticket',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <span style={{ whiteSpace: 'nowrap' }}>{text ? `${text}ml` : 'N/A'}</span>
          )
        },
      },
      {
        title: 'Equity stake',
        dataIndex: 'equity_stake',
        key: 'equity_stake',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <span style={{ whiteSpace: 'nowrap' }}>{text ? `${text}%` : 'N/A'}</span>
          )
        },
      },
      // {
      //   title: 'Mandate',
      //   dataIndex: 'mandate',
      //   key: 'mandate',
      //   className: 'cell-center',
      //   render: (text) => {
      //     return (
      //       <span>{text ? `YES` : 'NO'}</span>
      //     )
      //   },
      // },
      Table.EXPAND_COLUMN
    ];
  }

  function handleTableChange(pagination) {
    setStart(false);
    props.onChangePage(pagination.current, pagination.pageSize)
  }

  const totalCount = data ? data.count : 0;

  const generateBtnText = status => {
    switch (status?.toLowerCase()) {
      case 'engaged': return 'Go to dashboard';
      default: return 'View'
    }
  }

  const generateBtnAction = record => {
    if (record.action) {
      switch (record.action) {
        case 'engaged': {
          if (record.isNotification) {
            props.deleteNotification(record.id, record.mandate);
          } else {
            props.getDealDashboardInfo(record.dashboardId);
          }
          break;
        }
        default: {
          if (record.id) {
            if (record.isNotification) {
              props.deleteNotification && props.deleteNotification(record.id, record.mandate);
            } else {
              (record.isClosed && record.dashboardId) ?
                history.push({
                  pathname: `/deal-dashboard/overview/${record.dashboardId}`,
                  state: { backPath: `/home/deals/${tab}`, abortUpdate: true }
                })
                :
                record.mandate ?
                  history.push({
                    pathname: `/home/deal-for-mandate/${record.dealId}${record.id ? '/' + record.id : ''}`,
                    state: { backPath: allDeal ? `/home/all-deals` : `/home/deals/${tab}` }
                  })
                  :
                  history.push({
                    pathname: `/home/deal-share-info/${record.id}`,
                    state: { tab }
                  });
            }
          } else {
            if (record.isNotification) {
              props.deleteNotification && props.deleteNotification(record.dealId, record.mandate);
            } else {
              record.mandate ?
                history.push({
                  pathname: `/home/deal-for-mandate/${record.dealId}`,
                  state: { backPath: allDeal ? `/home/all-deals` : `/home/deals/${tab}` }
                })
                :
                history.push(`/home/deal-share-info-all/${record.dealId}`);
            }
          }
          break;
        }
      }
    }
  }

  const handleExpand = (expanded, record) => {
    const data = {...visibleData};
    data[record.dealId] = expanded;
    setVisibleData(data);
  }

  const generateRowClassName = record => {
    const isExpand = record.status === 'Open-Apply' || record.status === 'Applied';
    if (isExpand) {
      return visibleData[record.dealId] ? 'exp-row-action active' : 'exp-row-action';
    } else {
      return '';
    }
  }

  return (
    <Table
      loading={{ spinning: fetching, indicator: <div className="loader"><CustomIcon type="loader" /></div> }}
      pagination={{ itemRender: PaginationItemRender, current: page, pageSize: pageSize, total: totalCount, showSizeChanger: true }}
      className="deal-table exp"
      rowClassName={record => generateRowClassName(record)}
      rowKey={row => row.dealId}
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
      showSorterTooltip={false}
      expandable={{
        expandedRowRender: record => (
          <div className="exp-content">
            {
              record.overview &&
              <div>
                <div>{record.overview}</div>
              </div>
            }
            <Button type="primary" className="btn-primary" onClick={() => generateBtnAction(record)}>
              See full teaser
            </Button>
          </div>
        ),
        rowExpandable: record => (record.status === 'Open-Apply' || record.status === 'Applied'),
        expandedRowClassName: () => "exp-row",
        expandIcon: ({ record, onExpand }) => {
          const isExpand = record.status === 'Open-Apply' || record.status === 'Applied';
          return (
            <div className="deal-action">
              {
                record.isNotification &&
                <img src={Images.notification} alt="" />
              }
              <Button type={visibleData[record.dealId] ? 'primary white' : 'primary'} className="btn-primary" onClick={e => isExpand ? onExpand(record, e) : generateBtnAction(record)}>
                {generateBtnText(record.status)}
              </Button>
            </div>
          )
        },
        onExpand: (expanded, record) => handleExpand(expanded, record)
      }}
    />
  )


}

export default DealsInvestorTable
