import React from 'react'
import { Form, Input } from 'antd'
import Utils from '../../Utils/utils'

import Images from '../../Images'

function AddAdvisorPopupForm(props) {
  const [form] = Form.useForm();

  const onFinish = values => {
    const data = { ...values };

    props.onSubmit(data);
  }

  return (
    <Form id="add-advisor-popup-form" form={form} onFinish={onFinish} className="login-form" layout="vertical" style={{ padding: '0 40px' }}>

      <Form.Item
        name="name"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Input
          placeholder="Name"
          maxLength={100}
          prefix={<img src={Images.user} alt="" />}
          suffix={'*'}
        />
      </Form.Item>
      <Form.Item
        name="surname"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Input
          placeholder="Surname"
          maxLength={100}
          prefix={<img src={Images.user} alt="" />}
          suffix={'*'}
        />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: 'This field is required' },
          { type: 'email', message: 'Invalid email format' },
          { min: 5, message: 'Field must contain at least 5 characters' },
        ]}
        onKeyPress={e=>{if(e.charCode === 32) e.preventDefault()}}
        onBlur={e=>Utils.clearSpaces(e, 'email', form.setFieldsValue)}
      >
        <Input
          placeholder="Email"
          maxLength={100}
          prefix={<img src={Images.envelope} alt="" />}
          suffix={'*'}
        />
      </Form.Item>
    </Form>
  );
}

export default AddAdvisorPopupForm
