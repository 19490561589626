import React, { useState, useEffect } from 'react'
import { Collapse, Button, Checkbox, Input, message, Space, Radio } from 'antd'
import { useHistory, Link } from 'react-router-dom'

import CollapseHeader from '../CollapseHeader'
import Images from '../../Images'

const { Panel } = Collapse;
const { TextArea } = Input;


function DealSharingStart(props) {

  const [required, setRequired] = useState({
    active: false,
    proprietary_bilateral: null,
    introduction: null,
    confidentiality: null,

    opportunity_related: null,
    public_linked: null,
    inside_info: null,
    obligations_aware: null,
    under_obligations: null,
    share_conf_info: null,
    comment: null
  });
  const [showQuestion, setShowQuestion] = useState({
    opportunity_related: true,
    public_linked: false,
    inside_info: false,
    obligations_aware: false,
    under_obligations: false,
    share_conf_info: false,
  });
  const [targetCompany, setTargetCompany] = useState(false);

  const history = useHistory();

  const checkRequiredFields = () => {
    setRequired({...required, active: true});
    if (
      required.proprietary_bilateral &&
      required.introduction &&
      required.confidentiality &&
      targetCompany) {
      if (required.under_obligations === false && !required.comment) {
        return;
      }
      const data = {
        opportunity_related: showQuestion.opportunity_related ? required.opportunity_related : null,
        public_linked: showQuestion.public_linked ? required.public_linked : null,
        inside_info: showQuestion.inside_info ? required.inside_info : null,
        obligations_aware: showQuestion.obligations_aware ? required.obligations_aware : null,
        under_obligations: showQuestion.under_obligations ? required.under_obligations : null,
        share_conf_info: showQuestion.share_conf_info ? required.share_conf_info : null,
        comment: required.under_obligations === false ? required.comment : null,
      }
      props.onChangeStep(data);
    } else {
      props.onChangeStep(null, true);
    }
  }

  const setQuestionData = reverse => {
    const newData = {...showQuestion};
    for (let i in newData) {
      newData[i] = reverse ? true : false;
    }
    newData['opportunity_related'] = true;
    return newData;
  }

  const changeValue = (field, value) => {
    switch(field) {
      case 'opportunity_related': {
        if (value) {
          setShowQuestion({...showQuestion, public_linked: true});
          setTargetCompany(false);
        } else {
          const data = setQuestionData();
          setShowQuestion(data);
          setTargetCompany(true);
        }
        break;
      }
      case 'public_linked': {
        if (value) {
          const data = setQuestionData();
          data['public_linked'] = true;
          data['inside_info'] = true;
          setShowQuestion(data);
          setTargetCompany(false);
        } else {
          const data = setQuestionData();
          data['public_linked'] = true;
          data['under_obligations'] = true;
          setShowQuestion(data);
          setTargetCompany(false);
        }
        break;
      }
      case 'inside_info': {
        const data = setQuestionData();
        data['public_linked'] = true;
        data['inside_info'] = true;
        data['obligations_aware'] = true;
        setShowQuestion(data);
        setTargetCompany(false);
        break;
      }
      case 'obligations_aware': {
        const data = setQuestionData();
        data['public_linked'] = true;
        data['inside_info'] = true;
        data['obligations_aware'] = true;
        if (value) {
          data['under_obligations'] = true;
        }
        setShowQuestion(data);
        setTargetCompany(false);
        break;
      }
      case 'under_obligations': {
        if (value) {
          if (!required.public_linked) {
            const data = setQuestionData();
            data['public_linked'] = true;
            data['under_obligations'] = true;
            data['share_conf_info'] = true;
            setShowQuestion(data);
          } else {
            const data = setQuestionData(true);
            setShowQuestion(data);
          }
          setTargetCompany(false);
        } else {
          if (!required.public_linked) {
            const data = setQuestionData();
            data['public_linked'] = true;
            data['under_obligations'] = true;
            setShowQuestion(data);
          } else {
            const data = setQuestionData(true);
            data['share_conf_info'] = false;
            setShowQuestion(data);
          }
          setTargetCompany(true);
        }
        break;
      }
      case 'share_conf_info': {
        if (value) {
          setTargetCompany(true);
        } else {
          setTargetCompany(false);
        }
        break;
      }
      default: break;
    }
    setRequired({...required, [field]: value});
  }

  return (
    <div className="sign-up-step white filters-edit">
      <img src={Images.logo} alt="" onClick={() => history.push('/home/deals')} />
      <div className="step-investor-content">
        <Space>
          <img src={Images.blankOk} alt="" />
        </Space>
        <div className="step-number">Congratulations<br />for originating a new deal opportunity</div>
        <div className="step-text">Let us first confirm that your deal qualifies for being shared on IDN’s platform</div>
      </div>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ?
            <img src={Images.angleUp} alt="" />
            :
            <img src={Images.angleDown} alt="" />
        }
        expandIconPosition="right"
        className="site-collapse-custom-collapse"
        accordion
      >
        <Panel
          header={<CollapseHeader text="Proprietary & Bilateral" imageType="filmoscope" />} key="proprietary"
          className="site-collapse-custom-panel"
        >
          <div className="collapse-checkbox-block">
            <div className="collapse-checkbox-title">All the deals shared on IDN’s platform must be proprietary and bilateral</div>
            <div className="collapse-checkbox-text">Can you confirm that, at the best of your knowledge, the deal you are sharing is not currently on the market as part of a structured, competitive bidding process, in which the desired end result is for the Target to be either: (i) sold in its entirety; (ii) receiving an investment from a third party?  IDN would disqualify the deal if the Target of the Opportunity, either directly or through third parties (Financial Advisors)  has already contacted/invited multiple potential investors to participate in such a competitive process.</div>
          </div>
          <Space className="collapse-checkbox">
            <Checkbox onChange={e => changeValue('proprietary_bilateral', e.target.checked)}>I Confirm</Checkbox>
          </Space>
        </Panel>

        <Panel
          header={<CollapseHeader text="Introduction" imageType="handShake" />} key="introduction"
          className="site-collapse-custom-panel"
        >
          <div className="collapse-checkbox-block">
            <div className="collapse-checkbox-title">You must always be able to introduce the investor to the target company</div>
            <div className="collapse-checkbox-text" style={{marginTop: 10}}>Can you confirm that you will be able to introduce the investors we will match you with to the Target company?</div>
          </div>
          <Space className="collapse-checkbox">
            <Checkbox onChange={e => changeValue('introduction', e.target.checked)}>I Confirm</Checkbox>
          </Space>
        </Panel>

        <Panel
          header={<CollapseHeader text="Confidentiality" imageType="pen" />} key="confidentiality"
          className="site-collapse-custom-panel"
        >
          <div className="collapse-checkbox-block">
            <div className="collapse-checkbox-title">You must not breach any confidentiality agreement</div>
            <div className="collapse-checkbox-text" style={{marginTop: 10}}>Can you confirm that you do not infringe any contractual obligation by sharing this investment opportunity and any information related to it on the platform?</div>
          </div>
          <Space className="collapse-checkbox">
            <Checkbox onChange={e => changeValue('confidentiality', e.target.checked)}>I Confirm</Checkbox>
          </Space>
        </Panel>

        <Panel
          header={<CollapseHeader text="Target Company Status" imageType="puzzle" />} key="status"
          className={`site-collapse-custom-panel${(required.active && required.under_obligations === false && !required.comment) ? ' error-block' : ''}`}
        >
          <div className="collapse-checkbox-block">
            <div className="collapse-checkbox-text">Is any of the information relating to the opportunity you are sharing of a confidential nature?</div>
          </div>
          <Space className="collapse-checkbox">
            <Radio.Group
              onChange={e => changeValue('opportunity_related', e.target.value)}
            >
              <Radio value={false}>No</Radio>
              <Radio value={true}>Yes</Radio>
            </Radio.Group>
          </Space>
          {
            showQuestion.public_linked &&
            <>
              <div className="collapse-checkbox-block" style={{ marginTop: 32 }}>
                <div className="collapse-checkbox-text">Is the opportunity you are sharing linked to a public listed company?</div>
              </div>
              <Space className="collapse-checkbox">
                <Radio.Group
                  onChange={e => changeValue('public_linked', e.target.value)}
                >
                  <Radio value={false}>No</Radio>
                  <Radio value={true}>Yes</Radio>
                </Radio.Group>
              </Space>
            </>
          }

          {
            showQuestion.inside_info &&
            <>
              <div className="collapse-checkbox-block" style={{ marginTop: 32 }}>
                <div className="collapse-checkbox-text">You acknowledge that the existence of discussions between you and any potential investors and the terms of those discussions may (in whole or in part) constitute “inside information” for the purposes of Part V of the Criminal Justice Act 1993 (“CJA”) and/or the Market Abuse Regulations (EU 596/2014) which has effect (as amended) in English law by virtue of the European Union (Withdrawal) Act 2018 (“UK MAR”)”, and that any parties who are in, or acquire, possession of confidential information may have information as an “insider” for the purposes of the CJA and inside information for the purposes of UK MAR</div>
              </div>
              <Space className="collapse-checkbox">
                <Radio onChange={e => changeValue('inside_info', e.target.checked)}>Confirm</Radio>
              </Space>
            </>
          }

          {
            showQuestion.obligations_aware &&
            <>
              <div className="collapse-checkbox-block" style={{ marginTop: 32 }}>
                <div className="collapse-checkbox-text">Are you aware of your obligations under all applicable law and regulations relating to unpublished, price-sensitive information and are you compliant with all applicable laws and regulations of any relevant jurisdiction?</div>
              </div>
              <Space className="collapse-checkbox">
                <Radio.Group
                  onChange={e => changeValue('obligations_aware', e.target.value)}
                >
                  <Radio value={false}>No</Radio>
                  <Radio value={true}>Yes</Radio>
                </Radio.Group>
              </Space>
            </>
          }

          {
            showQuestion.under_obligations &&
            <>
              <div className="collapse-checkbox-block" style={{ marginTop: 32 }}>
                <div className="collapse-checkbox-text">
                  <div>Are you under any confidentiality obligations in respect of the confidential information, pursuant to a non-disclosure agreement or otherwise?</div>
                  <Space className="collapse-checkbox">
                    <Radio.Group
                      onChange={e => changeValue('under_obligations', e.target.value)}
                    >
                      <Radio value={false}>No</Radio>
                      <Radio value={true}>Yes</Radio>
                    </Radio.Group>
                  </Space>
                  {
                    required.under_obligations === false &&
                      <>
                        <div>Please provide details explaining why you are not under any confidentiality obligation regarding this opportunity which contains confidential information</div>
                        <TextArea
                          className={required.active && required.under_obligations === false && !required.comment && 'error-input'}
                          rows={5}
                          onBlur={(e) => changeValue('comment', e.target.value)}
                        />
                      </>
                  }
                </div>
              </div>
            </>
          }

          {
            showQuestion.share_conf_info &&
            <>
              <div className="collapse-checkbox-block" style={{ marginTop: 32 }}>
                <div className="collapse-checkbox-text">Notwithstanding such confidentiality obligations that you are subject to, are you permitted and/or have you been specifically authorised by the relevant company to share the confidential information relating to the opportunity to third parties via the IDN platform?</div>
              </div>
              <Space className="collapse-checkbox">
                <Radio.Group
                  onChange={e => changeValue('share_conf_info', e.target.value)}
                >
                  <Radio value={false}>No</Radio>
                  <Radio value={true}>Yes</Radio>
                </Radio.Group>
              </Space>
            </>
          }

        </Panel>

      </Collapse>
      <Button type="primary" className="btn-primary" style={{ width: 217 }} onClick={checkRequiredFields}>
        Continue
      </Button>
      <Space className="return-link" style={{ marginTop: 24 }}>
        <Link to="/home/deals">
          <img src={Images.arrowLeft} alt="" />
          <span>Return to main dashboard</span>
        </Link>
      </Space>
    </div>
  )
}

export default DealSharingStart
