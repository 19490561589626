import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, message, Spin } from 'antd'
import { useHistory } from 'react-router-dom'
import { Input } from 'antd';

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

const { TextArea } = Input;

function TerminateEngagementAdvisorPopupPage(props) {
  const { result, fetching, match, isAdmin, location } = props;
  const { dashboardId } = match.params;
  const { state } = location;

  const [value, setValue] = useState(null);
  const [active, setActive] = useState(false);

  const history = useHistory();

  useEffect(() => {
    props.dealDashboardSyncToggle(false);
    return function cleanup() {
      props.dealDashboardSyncToggle(true);
    }
  }, [0]);

  useEffect(() => {
    if (result) {
      isAdmin ?
        // result.mandate_deal ? 
          // history.push({ pathname:`/home/match-company/${dashboardId}/reshare-mandate-deal`, state: { 'isAdmin': isAdmin }}) :
          // history.push( `/home/match-company/${dashboardId}/reshare-deal`)
          history.push(`/home/match-company/${dashboardId}`)
			:
        result.mandate_deal ? 
          history.push(`/deal-dashboard/overview/${dashboardId}/reshare-mandate-deal`) :
          history.push(`/deal-dashboard/overview/${dashboardId}/reshare-deal`)
    }
  }, [result]);

  const changeValue = value => {
    if (!active) {
      setActive(true);
    }
    setValue(value);
  }

  const checkRequired = () => {
    if (state?.isHistory) {
			history.push(`/deal-dashboard/overview/${dashboardId}`);
			return;
		}
    if (!value) {
      message.error('Indicate the reason', 3);
      if (!active) {
        setActive(true);
      }
    } else {
      const data = {
        reason: value
      };
      props.terminateDealByAdvisor(dashboardId, data);
    }
  }

  return (
    <PopupWrapper
      width={540}
      path={isAdmin ?
				`/home/match-company/${dashboardId}`
				:
				`/deal-dashboard/overview/${dashboardId}`
			}
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content" style={{ padding: 20, paddingTop: 30, paddingBottom: 30 }}>
          <img src={Images.phoneCancel} alt="" />
          <div className="dialog-title welcome" style={{ margin: 8 }}>Terminate Engagement</div>
          <div className="deal-text engagment" style={{ marginTop: 6 }}>We are sorry to hear that you wish to terminate the engagement.<br />Please provide the reason for terminating the engagement.</div>
          <TextArea
            disabled={state?.isHistory}
            defaultValue={state?.add_info?.reason}
            style={{ margin: 20, width: 402 }}
            className={(active && !value) && 'error-input'}
            rows={4}
            onBlur={e => changeValue(e.target.value)}
          />
          <div className="deal-text sm" style={{width: '90%'}}>Please review IDN's Platform Agreement to remember your<br />rights and obligations in the context of the termination of this<br />engagement</div>
          <div className="btn-footer">
            <Button type="primary white" className="btn-primary welcome" style={{ width: 144 }} onClick={checkRequired}>
              {state?.isHistory ? 'Go back' : 'Confirm'}
          </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    result: state.dealDashboard.result,
    fetching: state.dealDashboard.fetching
  }
}

const mapDispatchToProps = (dispatch) => ({
  terminateDealByAdvisor: (dashboardId, data) => dispatch(DealDashboardActions.advisorTerminateDealRequest(dashboardId, data)),
  dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TerminateEngagementAdvisorPopupPage)
