import React, { useState, useEffect } from 'react'
import { Layout, Spin, Space, message } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

import AuthActions from '../Redux/AuthRedux'
import InvestorInviteColleaguesForm from '../Components/Forms/InvestorInviteColleaguesForm'
import Images from '../Images'
import errorHandler from '../Utils/errorHandler'

function InvestorInviteColleaguesPage(props) {
  const { user, error, fetching, result, subRole, location } = props;
  const { state } = location;

  const history = useHistory();

  useEffect(() => {
    if (error) {
      errorHandler(error, props.resetError);
    }
  }, [error]);

  useEffect(() => {
    if (result) {
      history.push({pathname: '/invite-colleagues/success', state});
    }
  }, [result]);

  function handleSubmit(data) {
    props.sendFirmInvite(user.company.id, data);
  }

  function skipNavigate() {
    if (state) {
      history.push(`/home/firm's-profile/${state?.tab}`);
    } else {
      history.push('/home/deals');
    }
  }

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <div className="sign-up-step with-bg">
          <div className="with-bg-top">
            <img src={Images.logoWhite} alt="" />
          </div>
          <div className="modal-container" style={{ width: 620, padding: '24px 72px' }}>
            <Space style={{ marginBottom: 24 }}>
              <img src={Images.phoneOk} alt="" />
            </Space>
            <div className="login-title">Invite Colleagues</div>
            <div className="sign-up-start-text" style={{ marginBottom: 24 }}>You should invite on IDN’s platform only Senior colleagues directly responsible for origination.
              {
                subRole !== 2 &&
                  <>
                    <br /><br />
                    <span><i>Please note that you can appoint any of your colleague as Co-Lead Investors. Co-Lead Investors can also edit your firm's profile. It is recommended to appoint at least one Co-Lead Investor.</i></span>
                  </>
              }
            </div>
            <InvestorInviteColleaguesForm
              subRole={subRole}
              onSubmit={handleSubmit}
            />
            <Space style={{ marginTop: 16 }}>
              <span className="link-tag underline" onClick={skipNavigate}>{state ? 'Go back' : 'Skip'}</span>
            </Space>
          </div>
        </div>
      </Spin>
    </Layout>
  )
}


const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    error: state.auth.error,
    fetching: state.auth.fetching,
    result: state.auth.firmInviteResult,
    subRole: state.auth.investorSubRole,
  }
}

const mapDispatchToProps = (dispatch) => ({
  sendFirmInvite: (firmId, data) => dispatch(AuthActions.sendFirmInviteRequest(firmId, data)),
  resetError: () => dispatch(AuthActions.authErrorReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvestorInviteColleaguesPage)
