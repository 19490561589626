import React from 'react'
import { Form, Input } from 'antd'

const { TextArea } = Input;

const layout = {
  labelCol: { span: 5, offset: 3 },
  wrapperCol: {span: 13},
};

function DiscloseCompanyAdvisorForm(props) {

  const onFinish = values => {
    const data = { ...values };

    props.onSubmit(data);
  }

  return (
    <Form
      id="disclose-company-form"
      {...layout}
      onFinish={onFinish}
      requiredMark={false}
    >

      <Form.Item
        name="name"
        label="Target Company:"
        className="input-block"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Input
          placeholder="Target Company"
          suffix={'*'}
        />
      </Form.Item>
      <Form.Item
        name="website"
        label="Link to Website:"
        className="input-block"
      >
        <Input
          placeholder="Link"
        />
      </Form.Item>
      <Form.Item
        name="target_description"
        label="Target description"
        className="input-block target_description"
      >
        <TextArea
          autoSize={{ minRows: 2, maxRows: 4 }}
          placeholder='In the event that the Target company is yet to be incorporated, or the Target
          is a greenfield project, please provide details of it and indicate who the main shareholders are. Please note, this section is not compulsary'
        />
      </Form.Item>
      {/* <Form.Item
        name="comment"
        label="Write Intro Message"
        className="area-block"
        labelCol={{span: 0, offset: 1}}
        wrapperCol={24}
      >
        <TextArea
          placeholder="You can write here an introductory message to the investor and provide more context on the deal if you wish"
        />
      </Form.Item> */}
    </Form>
  );
}

export default DiscloseCompanyAdvisorForm
