import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  dealDashboardRequest: ['dashboardId'],
  dealDashboardSuccess: ['dashboardInfo'],

  seenByAdvisorRequest: ['dashboardId'],
  seenByAdvisorSuccess: ['seenByAdvisorResult'],

  targetCompanySaveRequest: ['data', 'dealId'],
  targetCompanySaveSuccess: ['saveResult'],

  targetCompanyRequest: ['targetCompanyId'],
  targetCompanySuccess: ['targetCompanyResult'],

  targetCompanyEditRequest: ['targetCompanyId', 'data'],
  targetCompanyEditSuccess: ['editResult'],

  inviteDashboardRequest: ['dashboardId', 'data'],
  inviteDashboardSuccess: ['result'],

  investorTerminateDealRequest: ['dashboardId', 'data'],
  investorTerminateDealSuccess: ['result'],

  advisorTerminateDealRequest: ['dashboardId', 'data'],
  advisorTerminateDealSuccess: ['result'],

  advisorProceedPartiesRequest: ['dashboardId', 'data'],
  advisorProceedPartiesSuccess: ['result'],

  investorProceedPartiesRequest: ['dashboardId'],
  investorProceedPartiesSuccess: ['result'],

  investorRequestIntroRequest: ['dashboardId', 'data'],
  investorRequestIntroSuccess: ['result'],

  advisorOccuredIntroRequest: ['dashboardId', 'data'],
  advisorOccuredIntroSuccess: ['result'],

  advisorSendUltimatumRequest: ['dashboardId'],
  advisorSendUltimatumSuccess: ['result'],

  investorAcceptUltimatumRequest: ['dashboardId'],
  investorAcceptUltimatumSuccess: ['result'],

  investorRejectUltimatumRequest: ['dashboardId'],
  investorRejectUltimatumSuccess: ['result'],

  investorSubmittedNBORequest: ['dashboardId', 'data'],
  investorSubmittedNBOSuccess: ['result'],

  investorAcceptedNBORequest: ['dashboardId', 'data'],
  investorAcceptedNBOSuccess: ['result'],

  investorAcceptedBindingOfferRequest: ['dashboardId', 'data'],
  investorAcceptedBindingOfferSuccess: ['result'],

  investorSuccessCloseRequest: ['dashboardId', 'data'],
  investorSuccessCloseSuccess: ['result'],

  dealChangePublicRequest: ['dashboardId'],
  dealChangePublicSuccess: ['result'],

  notificationChangeStatusRequest: ['notificationId'],
  notificationChangeStatusSuccess: ['result'],

  adminRevertActionRequest: ['dashboardId'],
  adminRevertActionSuccess: ['result'],

  advisorSeenClosedDealRequest: ['dashboardId'],
  advisorSeenClosedDealSuccess: ['dealClosedResult'],

  investorSeenClosedDealRequest: ['dashboardId'],
  investorSeenClosedDealSuccess: ['dealClosedResult'],

  advisorUploadBilateralRequest: ['dashboardId', 'data'],
  advisorUploadBilateralSuccess: ['result'],

  setBackPath: ['backPath'],
  setUpdateDashboard: ['updateDashboardId'],

  dealDashboardReset: null,

  resultsReset: null,
  dealDashboardFetchingReset: null,

  dealDashboardSyncToggle: ['syncIsStart'],
})

export const DealDashboardTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  dashboardInfo: null,
  result: null,
  fetching: false,
  toggleFetching: false,

  dashboardId: null,
  firmId: null,
  advisorId: null,
  dealId: null,

  targetCompany: null,
  targetCompanyResult: null,

  saveFetching: false,
  saveResult: null,
  editResult: null,
  seenByAdvisorResult: null,
  dealClosedResult: null,

  syncIsStart: false,
  backPath: '/home/deals',
  updateDashboardId: false

})

/* ------------- Selectors ------------- */
export const DealDashboardSelectors = {
  getState: (state) => state.dealDashboard
}

/* ------------- Reducers ------------- */

export const dealDashboardRequest = (state, { dashboardId }) => {
  return state.merge({ toggleFetching: true })
}

export const dealDashboardSuccess = (state, { dashboardInfo }) => {
  return state.merge({
    toggleFetching: false,
    dashboardInfo,
    dashboardId: dashboardInfo?.id,
    firmId: dashboardInfo?.invest_company?.id,
    advisorId: dashboardInfo?.advisor?.id,
    dealId: dashboardInfo?.deal
  })
}

export const seenByAdvisorRequest = (state, { dashboardId }) => {
  return state.merge({ fetching: true })
}

export const seenByAdvisorSuccess = (state, { seenByAdvisorResult }) => {
  return state.merge({ fetching: false, seenByAdvisorResult })
}

export const targetCompanySaveRequest = (state, { data, dealId }) => {
  return state.merge({ saveFetching: true })
}

export const targetCompanySaveSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const targetCompanyRequest = (state, { targetCompanyId }) => {
  return state.merge({ fetching: true })
}

export const targetCompanySuccess = (state, { targetCompanyResult }) => {
  return state.merge({ fetching: false, targetCompanyResult })
}

export const targetCompanyEditRequest = (state, { targetCompanyId, data }) => {
  return state.merge({ saveFetching: true })
}

export const targetCompanyEditSuccess = (state, { editResult }) => {
  return state.merge({ saveFetching: false, editResult })
}

export const inviteDashboardRequest = (state, { dashboardId, data }) => {
  return state.merge({ fetching: true })
}

export const inviteDashboardSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const investorTerminateDealRequest = (state, { dashboardId, data }) => {
  return state.merge({ fetching: true })
}

export const investorTerminateDealSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const advisorTerminateDealRequest = (state, { dashboardId, data }) => {
  return state.merge({ fetching: true })
}

export const advisorTerminateDealSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const advisorProceedPartiesRequest = (state, { dashboardId, data }) => {
  return state.merge({ fetching: true })
}

export const advisorProceedPartiesSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const investorProceedPartiesRequest = (state, { dashboardId }) => {
  return state.merge({ fetching: true })
}

export const investorProceedPartiesSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const investorRequestIntroRequest = (state, { dashboardId, data }) => {
  return state.merge({ fetching: true })
}

export const investorRequestIntroSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const advisorOccuredIntroRequest = (state, { dashboardId, data }) => {
  return state.merge({ fetching: true })
}

export const advisorOccuredIntroSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const advisorSendUltimatumRequest = (state, { dashboardId }) => {
  return state.merge({ fetching: true })
}

export const advisorSendUltimatumSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const investorAcceptUltimatumRequest = (state, { dashboardId }) => {
  return state.merge({ fetching: true })
}

export const investorAcceptUltimatumSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const investorRejectUltimatumRequest = (state, { dashboardId }) => {
  return state.merge({ fetching: true })
}

export const investorRejectUltimatumSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const investorSubmittedNBORequest = (state, { dashboardId, data }) => {
  return state.merge({ fetching: true })
}

export const investorSubmittedNBOSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const investorAcceptedNBORequest = (state, { dashboardId, data }) => {
  return state.merge({ fetching: true })
}

export const investorAcceptedNBOSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const investorAcceptedBindingOfferRequest = (state, { dashboardId, data }) => {
  return state.merge({ fetching: true })
}

export const investorAcceptedBindingOfferSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const investorSuccessCloseRequest = (state, { dashboardId, data }) => {
  return state.merge({ fetching: true })
}

export const investorSuccessCloseSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const dealChangePublicRequest = (state, { dashboardId }) => {
  return state.merge({ fetching: true })
}

export const dealChangePublicSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const notificationChangeStatusRequest = (state, { notificationId }) => {
  return state.merge({ fetching: true })
}

export const notificationChangeStatusSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const adminRevertActionRequest = (state, { dashboardId }) => {
  return state.merge({ fetching: true })
}

export const adminRevertActionSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const advisorSeenClosedDealRequest = (state, { dashboardId }) => {
  return state.merge({ fetching: true })
}

export const advisorSeenClosedDealSuccess = (state, { dealClosedResult }) => {
  return state.merge({ fetching: false, dealClosedResult })
}

export const investorSeenClosedDealRequest = (state, { dashboardId }) => {
  return state.merge({ fetching: true })
}

export const investorSeenClosedDealSuccess = (state, { dealClosedResult }) => {
  return state.merge({ fetching: false, dealClosedResult })
}

export const advisorUploadBilateralRequest = (state, { dashboardId, data }) => {
  return state.merge({ fetching: true })
}

export const advisorUploadBilateralSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const setBackPath = (state, { backPath }) => {
  return state.merge({ backPath })
}

export const setUpdateDashboard = (state, { updateDashboardId }) => {
  return state.merge({ updateDashboardId })
}

export const dealDashboardReset = (state) =>{
  return INITIAL_STATE
}

export const resultsReset = (state) => {
  return state.merge({
    result: null,
    saveResult: null,
    editResult: null,
    seenByAdvisorResult: null,
    dealClosedResult: null,
    targetCompanyResult: null
  })
}

export const dealDashboardFetchingReset = (state) => {
  return state.merge({ fetching:false, toggleFetching: false, saveFetching: false })
}

export const dealDashboardSyncToggle = (state, { syncIsStart }) => {
  return state.merge({ syncIsStart })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.DEAL_DASHBOARD_REQUEST]: dealDashboardRequest,
  [Types.DEAL_DASHBOARD_SUCCESS]: dealDashboardSuccess,

  [Types.SEEN_BY_ADVISOR_REQUEST]: seenByAdvisorRequest,
  [Types.SEEN_BY_ADVISOR_SUCCESS]: seenByAdvisorSuccess,

  [Types.TARGET_COMPANY_SAVE_REQUEST]: targetCompanySaveRequest,
  [Types.TARGET_COMPANY_SAVE_SUCCESS]: targetCompanySaveSuccess,

  [Types.TARGET_COMPANY_REQUEST]: targetCompanyRequest,
  [Types.TARGET_COMPANY_SUCCESS]: targetCompanySuccess,

  [Types.TARGET_COMPANY_EDIT_REQUEST]: targetCompanyEditRequest,
  [Types.TARGET_COMPANY_EDIT_SUCCESS]: targetCompanyEditSuccess,

  [Types.INVITE_DASHBOARD_REQUEST]: inviteDashboardRequest,
  [Types.INVITE_DASHBOARD_SUCCESS]: inviteDashboardSuccess,

  [Types.INVESTOR_TERMINATE_DEAL_REQUEST]: investorTerminateDealRequest,
  [Types.INVESTOR_TERMINATE_DEAL_SUCCESS]: investorTerminateDealSuccess,

  [Types.ADVISOR_TERMINATE_DEAL_REQUEST]: advisorTerminateDealRequest,
  [Types.ADVISOR_TERMINATE_DEAL_SUCCESS]: advisorTerminateDealSuccess,

  [Types.ADVISOR_PROCEED_PARTIES_REQUEST]: advisorProceedPartiesRequest,
  [Types.ADVISOR_PROCEED_PARTIES_SUCCESS]: advisorProceedPartiesSuccess,

  [Types.INVESTOR_PROCEED_PARTIES_REQUEST]: investorProceedPartiesRequest,
  [Types.INVESTOR_PROCEED_PARTIES_SUCCESS]: investorProceedPartiesSuccess,

  [Types.INVESTOR_REQUEST_INTRO_REQUEST]: investorRequestIntroRequest,
  [Types.INVESTOR_REQUEST_INTRO_SUCCESS]: investorRequestIntroSuccess,

  [Types.ADVISOR_OCCURED_INTRO_REQUEST]: advisorOccuredIntroRequest,
  [Types.ADVISOR_OCCURED_INTRO_SUCCESS]: advisorOccuredIntroSuccess,

  [Types.ADVISOR_SEND_ULTIMATUM_REQUEST]: advisorSendUltimatumRequest,
  [Types.ADVISOR_SEND_ULTIMATUM_SUCCESS]: advisorSendUltimatumSuccess,

  [Types.INVESTOR_ACCEPT_ULTIMATUM_REQUEST]: investorAcceptUltimatumRequest,
  [Types.INVESTOR_ACCEPT_ULTIMATUM_SUCCESS]: investorAcceptUltimatumSuccess,

  [Types.INVESTOR_REJECT_ULTIMATUM_REQUEST]: investorRejectUltimatumRequest,
  [Types.INVESTOR_REJECT_ULTIMATUM_SUCCESS]: investorRejectUltimatumSuccess,

  [Types.INVESTOR_SUBMITTED_NBO_REQUEST]: investorSubmittedNBORequest,
  [Types.INVESTOR_SUBMITTED_NBO_SUCCESS]: investorSubmittedNBOSuccess,

  [Types.INVESTOR_ACCEPTED_NBO_REQUEST]: investorAcceptedNBORequest,
  [Types.INVESTOR_ACCEPTED_NBO_SUCCESS]: investorAcceptedNBOSuccess,

  [Types.INVESTOR_ACCEPTED_BINDING_OFFER_REQUEST]: investorAcceptedBindingOfferRequest,
  [Types.INVESTOR_ACCEPTED_BINDING_OFFER_SUCCESS]: investorAcceptedBindingOfferSuccess,

  [Types.INVESTOR_SUCCESS_CLOSE_REQUEST]: investorSuccessCloseRequest,
  [Types.INVESTOR_SUCCESS_CLOSE_SUCCESS]: investorSuccessCloseSuccess,

  [Types.DEAL_CHANGE_PUBLIC_REQUEST]: dealChangePublicRequest,
  [Types.DEAL_CHANGE_PUBLIC_SUCCESS]: dealChangePublicSuccess,

  [Types.NOTIFICATION_CHANGE_STATUS_REQUEST]: notificationChangeStatusRequest,
  [Types.NOTIFICATION_CHANGE_STATUS_SUCCESS]: notificationChangeStatusSuccess,

  [Types.ADMIN_REVERT_ACTION_REQUEST]: adminRevertActionRequest,
  [Types.ADMIN_REVERT_ACTION_SUCCESS]: adminRevertActionSuccess,

  [Types.ADVISOR_SEEN_CLOSED_DEAL_REQUEST]: advisorSeenClosedDealRequest,
  [Types.ADVISOR_SEEN_CLOSED_DEAL_SUCCESS]: advisorSeenClosedDealSuccess,

  [Types.INVESTOR_SEEN_CLOSED_DEAL_REQUEST]: investorSeenClosedDealRequest,
  [Types.INVESTOR_SEEN_CLOSED_DEAL_SUCCESS]: investorSeenClosedDealSuccess,

  [Types.ADVISOR_UPLOAD_BILATERAL_REQUEST]: advisorUploadBilateralRequest,
  [Types.ADVISOR_UPLOAD_BILATERAL_SUCCESS]: advisorUploadBilateralSuccess,

  [Types.SET_BACK_PATH]: setBackPath,
  [Types.SET_UPDATE_DASHBOARD]: setUpdateDashboard,

  [Types.DEAL_DASHBOARD_RESET]: dealDashboardReset,

  [Types.RESULTS_RESET]: resultsReset,

  [Types.DEAL_DASHBOARD_FETCHING_RESET]: dealDashboardFetchingReset,

  [Types.DEAL_DASHBOARD_SYNC_TOGGLE]: dealDashboardSyncToggle,
})
