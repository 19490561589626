import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Tooltip, Button } from 'antd'
import moment from 'moment'

import PaginationItemRender from '../PaginationItemRender'
import CustomIcon from '../../Icon'
import Images from '../../Images'

import './Table.less'

function FirmDealsTable(props){
  const {
    page,
    pageSize,
    data,
    fetching,
    userId
  } = props;

  const [dataSource, setDataSource] = useState(null);

  const history = useHistory();
  useEffect(() => {
    let arr = (data && data.results) ? data.results.asMutable({deep:true}) : [];
    arr = arr.map(el => ({
      shareId: el.id,
      dealId: el.deal?.id,
      colleague: el.colleague?.name,
      statusName: el.investor_status_obj?.name,
      received: el.received,
      subsector: el.deal?.subsector,
      geography: el.deal?.geography,
      ev_range: el.deal?.ev_range,
      status: el.investor_status_obj?.id,
      mandate: el.deal?.mandate,
      isOwner: el.colleague?.id === userId,
      dashboardId: el.dashboard
    }))
    setDataSource(arr);
  }, [data]);

  function getColumns() {
    return [
      {
        title: 'Colleague',
        dataIndex: 'colleague',
        key: 'colleague',
      },
      {
        title: 'Status',
        dataIndex: 'statusName',
        key: 'statusName',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <div className={`deal-status${record.isNotification ? ' orange' : ''}`}>{text}</div>
          )
        },
      },
      {
        title: 'Received on',
        dataIndex: 'received',
        key: 'received',
        className: 'cell-center',
        render: (text, record) => {
          return (
              <span>{text && moment(text).format('DD/MM/YYYY')}</span>
          )
        },
      },
      {
        title: 'Sector',
        dataIndex: 'subsector',
        key: 'subsector',
        render: (text, record) => {
          return (
            text?.map((el, i) => (
              <div key={i}>{el}</div>
            ))
          )
        },
      },
      {
        title: 'Geography',
        dataIndex: 'geography',
        key: 'geography',
        render: (text, record) => {
          return (
            text?.map((el, i) => (
              <div key={i}>{el}</div>
            ))
          )
        },
      },
      {
        title: 'EV range',
        dataIndex: 'ev_range',
        key: 'ev_range',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <span style={{whiteSpace: 'nowrap'}}>{text ? `${text}ml` : 'N/A'}</span>
          )
        },
      },
      {
        title: 'Mandate',
        dataIndex: 'mandate',
        key: 'mandate',
        className: 'cell-center',
        render: (text) => {
          return (
            <span>{text ? `YES` : 'NO'}</span>
          )
        },
      },
      {
        title: '',
        dataIndex: 'status',
        key: 'status',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <div className="deal-action">
              {
                record.isNotification &&
                  <img src={Images.notification} alt="" />
              }
              <Button type="primary" className="btn-primary" onClick={()=>generateBtnAction(record)}>
                View
              </Button>
            </div>
          )
        },
      }
    ];
  }

  function handleTableChange(pagination) {
    props.onChangePage(pagination.current, pagination.pageSize)
  }

  const totalCount = data ? data.count : 0;

  // const generateBtnText = status => {
  //   switch(status) {
  //     case 'engaged': return 'Go to deal dashboard';
  //     default: return 'View'
  //   }
  // }

  const generateBtnAction = record => {
    let path;
    if (record.dashboardId && record.isOwner) {
      path = {
        pathname: `/deal-dashboard/overview/${record.dashboardId}`,
        state: {backPath: "/home/firm's-activities", abortUpdate: true}
      }
    } else if (record.mandate) {
      path = {
        pathname: `/home/deal-for-mandate/${record.dealId}${record.shareId ? '/' + record.shareId : ''}`,
        state: {
          backPath: "/home/firm's-activities",
          hideAll: true,
          isOwnDeal: record.isOwner
        }
      }
    } else {
      path = {
        pathname: `/home/deal-share-info/${record.shareId}`,
        state: {
          backPath: "/home/firm's-activities",
          hideAll: true
        }
      }
    }
    history.push(path);
  }

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount, showSizeChanger: true}}
      className="deal-table"
      rowKey={row => row.shareId}
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
      showSorterTooltip={false}
    />
   )


}

export default FirmDealsTable
