import React, { useState, useEffect } from 'react'
import {Form, Input, Radio, Row, Col} from 'antd'

import Images from '../../Images'

function AdvisorPreviewForm2(props) {
  const [active, setActive] = useState(false);

  const onFinish = values => {
    props.onSubmit(values);
  }

  const onChange = e => {
    if (e.target.value !== 'linkedin') {
      setActive(true);
    } else {
      setActive(false);
    }
  }

  return (
    <Form id="advisor-preview-form" onFinish={onFinish} className="login-form" requiredMark={false} style={{width: '100%'}}>

      <Row gutter={16}>
        <Form.Item
          name="equity_firms_exp"
          label="Do you have experience working with Private Equity firms?"
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </Row>

      <Row gutter={16}>
        <Form.Item
          name="deal_organisations_exp"
          label="Do you have direct experience in deal origination?"
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </Row>

      <Row gutter={16}>
        <Form.Item
          name="share_deal"
          label="Do you already have a deal that you would like to share?"
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </Row>

      <div className="divider"></div>

      <Row gutter={16}>
        <Form.Item
          name="heard_from"
          label="How did you hear about us?"
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Radio.Group onChange={onChange}>
            <Radio value="linkedin">linkedin</Radio>
            <Radio value="referral">referral</Radio>
            <Radio value="other">other </Radio>
          </Radio.Group>
        </Form.Item>
      </Row>

      {
        active &&
          <Row gutter={16}>
            <Form.Item
              name="heard_from_note"
              rules={[{required: true, message: 'This field is required'}]}
            >
              <Input placeholder="Please specify" />
            </Form.Item>
          </Row>
      }
    </Form>
  )
}

export default AdvisorPreviewForm2
