import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Space } from 'antd'

import Images from '../../Images'


function AdvisoryRequestsPage(props) {
  const { role } = props;

  return (
    <div className="main-container">
      <div className="section-title">Advisory Requests</div>
      <div className="section-content info">You don’t have any active advisory requests</div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    role: state.auth.role
  }
}

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvisoryRequestsPage)
