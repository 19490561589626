import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function EngagementTerminatedInvestorPopupPage(props) {
  const { result, dealClosedResult, fetching, match, location } = props;
  const { dashboardId } = match.params;
  const { state } = location;

  const history = useHistory();

  useEffect(() => {
    props.dealDashboardSyncToggle(false);
    return function cleanup() {
      // props.dealDashboardSyncToggle(true);
    }
  }, [0]);

  useEffect(() => {
    if (result) {
      props.viewDealClosedInvestor(dashboardId);
    }
  }, [result]);

  useEffect(() => {
    if (dealClosedResult) {
      props.resetDealDashboard();
      history.push(`/home/deals`);
    }
  }, [dealClosedResult]);

  const removeNotification = () => {
    if (state?.isHistory) {
			history.push(`/deal-dashboard/overview/${dashboardId}`);
		} else {
      props.changeNotificationStatus(state?.notification);
    }
  }

  return (
    <div className="popup-dialog">
      <div className="dialog-container" style={{ width: 420 }}>
        <Spin spinning={fetching} size="large">
          <div className="dialog-content" style={{ padding: 40 }}>
            <img src={Images.phoneCancel} alt="" />
            <div className="dialog-title welcome" style={{ margin: 8 }}>Engagement Terminated</div>
            <div className="deal-text engagment" style={{ marginTop: 6 }}>We are sorry to inform you that the Senior <br /> Advisor has terminated the engagement <br /> with your firm.</div>
            <br />
            <div className="deal-text sm" style={{ width: '90%' }}>Please review IDN's Platform Agreement to remember your<br />rights and obligations in the context of the termination<br />of this engagement</div>
            <div className="btn-footer" style={{ marginTop: 12 }}>
              <Button type="primary white"
                className="btn-primary welcome"
                style={{ width: 144 }}
                onClick={removeNotification}
              >
                {state?.isHistory ? 'Go back' : 'Return'}
              </Button>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    result: state.dealDashboard.result,
    dealClosedResult: state.dealDashboard.dealClosedResult,
    fetching: state.dealDashboard.fetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
  changeNotificationStatus: (notificationId) => dispatch(DealDashboardActions.notificationChangeStatusRequest(notificationId)),
  viewDealClosedInvestor: (dashboardId) => dispatch(DealDashboardActions.investorSeenClosedDealRequest(dashboardId)),
  dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
  resetDealDashboard: () => dispatch(DealDashboardActions.dealDashboardReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EngagementTerminatedInvestorPopupPage)
