import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  dealsRequest: ['page', 'page_size', 'sort_field', 'sort_order', 'search', 'filter'],
  dealsSuccess: ['list'],

  dealRequest: ['dealId'],
  dealSuccess: ['deal'],
  dealReset: null,

  dealSaveRequest: ['data'],
  dealSaveSuccess: ['saveResult'],

  dealDeleteRequest: ['dealId'],
  dealDeleteSuccess: ['deleteResult'],

  dealChangeStatusRequest: ['dealId', 'status'],
  dealChangeStatusSuccess: ['dealChangeStatusResult'],

  dealNotificationDeleteRequest: ['dealId', 'data'],
  dealNotificationDeleteSuccess: ['dealNotificationResult'],

  dealNotificationDeleteRequest_: ['dealId'],
  dealNotificationDeleteSuccess_: ['dealNotificationResult'],

  dealOverviewSaveRequest: ['data'],
  dealOverviewSaveSuccess: ['dealOverviewResult'],

  dealRoleInfoSaveRequest: ['data'],
  dealRoleInfoSaveSuccess: ['dealRoleInfoResult'],

  dealInvestorSoughtSaveRequest: ['data'],
  dealInvestorSoughtSaveSuccess: ['dealInvestorSoughtResult'],

  dealGroupDataSaveRequest: ['dealId', 'data'],
  dealGroupDataSaveSuccess: ['dealGroupDataResult'],

  dealWithdrawRequest: ['dealId', 'data'],
  dealWithdrawSuccess: ['dealWithdrawResult'],

  setDealNotification: ['dealNotification'],

  dealsSyncToggle: ['syncIsStart'],

  dealStatusesRequest: null,
  dealStatusesSuccess: ['dealStatuses'],

  dealInvestorsSharedRequest: ['data'],
  dealInvestorsSharedSuccess: ['investorSharedList'],
 
  dealAdvisorsSharedRequest: ['data', 'page', 'page_size'],
  dealAdvisorsSharedSuccess: ['advisorSharedList'],

  dealAdvisorsSharedHistoryRequest: ['data', 'page', 'page_size'],
  dealAdvisorsSharedHistorySuccess: ['advisorSharedHistoryList'],

  dealShareWithSpecificInvestorRequest: ['investor_id','data'],
  dealShareWithSpecificInvestorSuccess: ['dealShareSpecificInvestorResult'],

  dealInvestorShareStatusesRequest: null,
  dealInvestorShareStatusesSuccess: ['investorShareStatuses'],

  dealShareWithInvestorsRequest: ['dealId', 'data'],
  dealShareWithInvestorsSuccess: ['dealShareWithInvestorsResult'],

  dealReshareRequestByAdvisorRequest: ['dealId'],
  dealReshareRequestByAdvisorSuccess: ['dealReshareResult'],

  dealReshareByAdminRequest: ['dealId'],
  dealReshareByAdminSuccess: ['dealReshareResult'],

  dealReshareByAdvisorRequest: ['dealId'],
  dealReshareByAdvisorSuccess: ['dealReshareResult'],

  dealShareByAdvisorRequest: ['dealId'],
  dealShareByAdvisorSuccess: ['dealShareResult'],

  dealInvestorApplyRequest_: ['dealId', 'data'],
  dealInvestorApplySuccess_: ['dealInvestorApplyResult'],

  dealInvestorSendColleaguesRequest_: ['dealId', 'data'],
  dealInvestorSendColleaguesSuccess_: ['dealInvestorSendColleaguesResult'],

  dealTargetStatusRequest: ['dealId'],
  dealTargetStatusSuccess: ['response'],
  dealTargetStatusReset: null,

  dealTargetStatusSaveRequest: ['data'],
  dealTargetStatusSaveSuccess: ['dealTargetStatusSaveResult'],

  dealTargetStatusUpdateRequest: ['targetId', 'data'],
  dealTargetStatusUpdateSuccess: ['dealTargetStatusUpdateResult'],

  mandateDealRejectRequest: ['dealId'],
  mandateDealRejectSuccess: ['mandateDealRejectResult'],

  adminShareMandateDealRequest: ['dealId'],
  adminShareMandateDealSuccess: ['adminShareMandateDealResult'],

  notificationChangeDealStatusRequest: ['notificationId'],
  notificationChangeDealStatusSuccess: ['notificationDealResult'],

  setDealId: ['dealId'],

  dealTableSettingsReset: null,

  dealsReset: null,

  resultsReset: null,
  dealsFetchingReset: null
})

export const DealsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  deal: null,
  dealId: null,
  fetching: false,
  reshareFetching: false,

  investorSharedList: null,
  advisorSharedList: null,
  advisorSharedHistoryList: null,
  dealShareSpecificInvestorResult: null,
  page_active_deals: 1,
  page_history_deals: 1,


  saveResult: null,
  saveFetching: false,

  dealOverviewResult: null,
  dealRoleInfoResult: null,
  dealInvestorSoughtResult: null,
  dealChangeStatusResult: null,
  dealNotificationResult: null,
  dealGroupDataResult: null,
  dealWithdrawResult: null,
  dealShareWithInvestorsResult: null,
  dealReshareResult: null,
  dealShareResult: null,
  dealInvestorApplyResult: null,
  dealInvestorSendColleaguesResult: null,
  dealTargetStatusSaveResult: null,
  dealTargetStatusUpdateResult: null,
  mandateDealRejectResult: null,
  adminShareMandateDealResult: null,
  notificationDealResult: null,

  deleteResult: null,
  deleteFetching: false,

  page: 1,
  page_size: 20,
  sort_field:'',
  sort_order: 1,
  search:'',

  dealNotification: false,

  syncIsStart: false,

  filter: {
    ev_range: [],
    sectors: [],
    equity_range: [],
    equity_stake: [],
    geography: [],
    investment_type: [],
    governance: [],
    shared: [],
    is_public: null,
    admin_status: [],
    comment: null,
    mandate: null,
    group: ''
  },

  filter_is: {
    page: 1,
    page_size: 20,
    search: '',
    deal: '',
    equity_governace: [],
    investment_type: [],
    enterprise_value_from: null,
    enterprise_value_to: null,
    equity_investment_from: null,
    equity_investment_to: null,
    equity_stake_from: null,
    equity_stake_to: null,
    sectors: [],
    geography: [],
    comment: '',
    status: ''
  },

  dealStatuses: null,
  investorShareStatuses: null,
  dealTargetStatus: null,

})

/* ------------- Selectors ------------- */
export const DealsSelectors = {
  getState: (state) => state.deals
}

/* ------------- Reducers ------------- */

export const dealsRequest = (state, { page, page_size, sort_field, sort_order, search, filter }) => {
  const newState = {
    fetching: true
  };
  page && (newState.page = page);
  page_size && (newState.page_size = page_size);
  newState.sort_field = sort_field ? sort_field : '';
  newState.sort_order = sort_order ? sort_order : 1;
  newState.search = search ? search : '';
  filter && (newState.filter = state.filter.merge(filter));
  return state.merge(newState)
}

export const dealsSuccess = (state, { list }) => {
  return state.merge({ fetching: false, list })
}

export const dealRequest = (state, { dealId }) => {
  return state.merge({ fetching: true })
}

export const dealSuccess = (state, { deal }) => {
  return state.merge({ fetching: false, deal })
}

export const dealReset = (state) => {
  return state.merge({ deal: null })
}

export const dealSaveRequest = (state, { data }) => {
  return state.merge({ saveFetching: true })
}

export const dealSaveSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const dealDeleteRequest = (state, { dealId }) => {
  return state.merge({ deleteFetching: true})
}

export const dealDeleteSuccess = (state, { deleteResult }) => {
  return state.merge({ deleteFetching: false, deleteResult })
}

export const dealChangeStatusRequest = (state, { dealId, status }) => {
  return state.merge({ fetching: true})
}

export const dealChangeStatusSuccess = (state, { dealChangeStatusResult }) => {
  return state.merge({ fetching: false, dealChangeStatusResult })
}

export const dealNotificationDeleteRequest = (state, { dealId, data }) => {
  return state.merge({ fetching: true})
}

export const dealNotificationDeleteSuccess = (state, { dealNotificationResult }) => {
  return state.merge({ fetching: false, dealNotificationResult })
}

export const dealNotificationDeleteRequest_ = (state, { dealId }) => {
  return state.merge({ fetching: true})
}

export const dealNotificationDeleteSuccess_ = (state, { dealNotificationResult }) => {
  return state.merge({ fetching: false, dealNotificationResult })
}

export const dealOverviewSaveRequest = (state, { data }) => {
  return state.merge({ saveFetching: true })
}

export const dealOverviewSaveSuccess = (state, { dealOverviewResult }) => {
  return state.merge({ saveFetching: false, dealOverviewResult })
}

export const dealRoleInfoSaveRequest = (state, { data }) => {
  return state.merge({ saveFetching: true })
}

export const dealRoleInfoSaveSuccess = (state, { dealRoleInfoResult }) => {
  return state.merge({ saveFetching: false, dealRoleInfoResult })
}

export const dealInvestorSoughtSaveRequest = (state, { data }) => {
  return state.merge({ saveFetching: true })
}

export const dealInvestorSoughtSaveSuccess = (state, { dealInvestorSoughtResult }) => {
  return state.merge({ saveFetching: false, dealInvestorSoughtResult })
}

export const dealGroupDataSaveRequest = (state, { dealId, data }) => {
  return state.merge({ saveFetching: true })
}

export const dealGroupDataSaveSuccess = (state, { dealGroupDataResult }) => {
  return state.merge({ saveFetching: false, dealGroupDataResult })
}

export const dealWithdrawRequest = (state, { dealId, data }) => {
  return state.merge({ fetching: true })
}

export const dealWithdrawSuccess = (state, { dealWithdrawResult }) => {
  return state.merge({ fetching: false, dealWithdrawResult })
}

export const setDealNotification = (state, { dealNotification }) => {
  return state.merge({ dealNotification })
}

export const dealsSyncToggle = (state, { syncIsStart }) => {
  return state.merge({ syncIsStart })
}

export const dealStatusesRequest = (state) => {
  return state.merge({ fetching: true })
}

export const dealStatusesSuccess = (state, { dealStatuses }) => {
  return state.merge({ fetching: false, dealStatuses })
}

export const dealInvestorsSharedRequest = (state, { data }) => {
  const newState = {
    fetching: true
  };
  newState.filter_is = state.filter_is.merge(data);
  return state.merge(newState)
}

export const dealInvestorsSharedSuccess = (state, { investorSharedList }) => {
  return state.merge({ fetching: false, investorSharedList })
}

export const dealAdvisorsSharedRequest = (state, { data, page, page_size }) => {
  const newState = {
    fetching: true
  };
  page && (newState.page_active_deals = page);
  page_size && (newState.page_size = page_size);
  return state.merge(newState)
}

export const dealAdvisorsSharedSuccess = (state, { advisorSharedList }) => {
  return state.merge({ fetching: false, advisorSharedList })
}

export const dealAdvisorsSharedHistoryRequest = (state, { data, page, page_size }) => {
  const newState = {
    fetching: true
  };
  page && (newState.page_history_deals = page);
  page_size && (newState.page_size = page_size);
  return state.merge(newState)
}

export const dealAdvisorsSharedHistorySuccess = (state, { advisorSharedHistoryList }) => {
  return state.merge({ fetching: false, advisorSharedHistoryList })
}

export const dealShareWithSpecificInvestorRequest = (state, { investor_id, data }) => {
  return state.merge({ fetching: true })
}

export const dealShareWithSpecificInvestorSuccess = (state, { dealShareSpecificInvestorResult }) => {
  return state.merge({ fetching: false, dealShareSpecificInvestorResult })
}

export const dealInvestorShareStatusesRequest = (state) => {
  return state.merge({ fetching: true })
}

export const dealInvestorShareStatusesSuccess = (state, { investorShareStatuses }) => {
  return state.merge({ fetching: false, investorShareStatuses })
}

export const dealShareWithInvestorsRequest = (state, { dealId, data }) => {
  return state.merge({ fetching: true })
}

export const dealShareWithInvestorsSuccess = (state, { dealShareWithInvestorsResult }) => {
  return state.merge({ fetching: false, dealShareWithInvestorsResult })
}

export const dealReshareRequestByAdvisorRequest = (state, { dealId }) => {
  return state.merge({ reshareFetching: true })
}

export const dealReshareRequestByAdvisorSuccess = (state, { dealReshareResult }) => {
  return state.merge({ reshareFetching: false, dealReshareResult })
}

export const dealReshareByAdminRequest = (state, { dealId }) => {
  return state.merge({ fetching: true })
}

export const dealReshareByAdminSuccess = (state, { dealReshareResult }) => {
  return state.merge({ fetching: false, dealReshareResult })
}

export const dealReshareByAdvisorRequest = (state, { dealId }) => {
  return state.merge({ fetching: true })
}

export const dealReshareByAdvisorSuccess = (state, { dealReshareResult }) => {
  return state.merge({ fetching: false, dealReshareResult })
}

export const dealShareByAdvisorRequest = (state, { dealId }) => {
  return state.merge({ fetching: true })
}

export const dealShareByAdvisorSuccess = (state, { dealShareResult }) => {
  return state.merge({ fetching: false, dealShareResult })
}

export const dealInvestorApplyRequest_ = (state, { dealId, data }) => {
  return state.merge({ fetching: true})
}

export const dealInvestorApplySuccess_ = (state, { dealInvestorApplyResult }) => {
  return state.merge({ fetching: false, dealInvestorApplyResult })
}

export const dealInvestorSendColleaguesRequest_ = (state, { dealId, data }) => {
  return state.merge({ fetching: true})
}

export const dealInvestorSendColleaguesSuccess_ = (state, { dealInvestorSendColleaguesResult }) => {
  return state.merge({ fetching: false, dealInvestorSendColleaguesResult })
}

export const dealTargetStatusRequest = (state, { dealId }) => {
  return state.merge({ fetching: true })
}

export const dealTargetStatusSuccess = (state, { response }) =>
{
  const data = response?.results?.length ? response.results[0] : null;
  return state.merge({ fetching: false, dealTargetStatus: data })
}

export const dealTargetStatusReset = (state) =>
{
  return state.merge({ dealTargetStatus: null })
}

export const dealTargetStatusSaveRequest = (state, { data }) => {
  return state.merge({ fetching: true })
}

export const dealTargetStatusSaveSuccess = (state, { dealTargetStatusSaveResult }) => {
  return state.merge({ fetching: false, dealTargetStatusSaveResult })
}

export const dealTargetStatusUpdateRequest = (state, { targetId, data }) => {
  return state.merge({ fetching: true })
}

export const dealTargetStatusUpdateSuccess = (state, { dealTargetStatusUpdateResult }) => {
  return state.merge({ fetching: false, dealTargetStatusUpdateResult })
}

export const mandateDealRejectRequest = (state, { dealId }) => {
  return state.merge({ fetching: true})
}

export const mandateDealRejectSuccess = (state, { mandateDealRejectResult }) => {
  return state.merge({ fetching: false, mandateDealRejectResult })
}

export const adminShareMandateDealRequest = (state, { dealId }) => {
  return state.merge({ fetching: true})
}

export const adminShareMandateDealSuccess = (state, { adminShareMandateDealResult }) => {
  return state.merge({ fetching: false, adminShareMandateDealResult })
}

export const notificationChangeDealStatusRequest = (state, { notificationId }) => {
  return state.merge({ fetching: true })
}

export const notificationChangeDealStatusSuccess = (state, { notificationDealResult }) => {
  return state.merge({ fetching: false, notificationDealResult })
}

export const setDealId = (state, {dealId}) => {
  return state.merge({ dealId })
}

export const dealTableSettingsReset = (state) =>{
  const newState = {
    page:1,
    sort_field:'',
    sort_order: 1,
    search:'',
    page_is:1,
    search_is:'',
    filter: {
      ev_range: [],
      sectors: [],
      equity_range: [],
      equity_stake: [],
      geography: [],
      investment_type: [],
      governance: [],
      shared: [],
      is_public: null,
      admin_status: [],
      comment: null,
      mandate: null,
      group: ''
    },
    filter_is: {
      page: 1,
      page_size: 20,
      search: '',
      deal: '',
      equity_governace: [],
      investment_type: [],
      enterprise_value_from: null,
      enterprise_value_to: null,
      equity_investment_from: null,
      equity_investment_to: null,
      equity_stake_from: null,
      equity_stake_to: null,
      sectors: [],
      geography: [],
      comment: '',
      status: ''
    }
  };
  return state.merge(newState)
}

export const dealsReset = (state) => {
  return INITIAL_STATE
}

export const resultsReset = (state) => {
  return state.merge({
    saveResult: null,
    deleteResult: null,
    dealOverviewResult: null,
    dealRoleInfoResult: null,
    dealInvestorSoughtResult: null,
    dealChangeStatusResult: null,
    dealNotificationResult: null,
    dealGroupDataResult: null,
    dealWithdrawResult: null,
    dealShareWithInvestorsResult: null,
    dealReshareResult: null,
    dealShareResult: null,
    dealInvestorApplyResult: null,
    dealInvestorSendColleaguesResult: null,
    dealTargetStatusSaveResult: null,
    dealTargetStatusUpdateResult: null,
    mandateDealRejectResult: null,
    adminShareMandateDealResult: null,
    notificationDealResult: null,
    dealShareSpecificInvestorResult: null,
  })
}

export const dealsFetchingReset = (state) => {
  return state.merge({
    fetching:false,
    saveFetching:false,
    deleteFetching:false,
    reshareFetching: false
  })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.DEALS_REQUEST]: dealsRequest,
  [Types.DEALS_SUCCESS]: dealsSuccess,

  [Types.DEAL_REQUEST]: dealRequest,
  [Types.DEAL_SUCCESS]: dealSuccess,
  [Types.DEAL_RESET]: dealReset,

  [Types.DEAL_SAVE_REQUEST]: dealSaveRequest,
  [Types.DEAL_SAVE_SUCCESS]: dealSaveSuccess,

  [Types.DEAL_DELETE_REQUEST]: dealDeleteRequest,
  [Types.DEAL_DELETE_SUCCESS]: dealDeleteSuccess,

  [Types.DEAL_CHANGE_STATUS_REQUEST]: dealChangeStatusRequest,
  [Types.DEAL_CHANGE_STATUS_SUCCESS]: dealChangeStatusSuccess,

  [Types.DEAL_NOTIFICATION_DELETE_REQUEST]: dealNotificationDeleteRequest,
  [Types.DEAL_NOTIFICATION_DELETE_SUCCESS]: dealNotificationDeleteSuccess,

  [Types.DEAL_NOTIFICATION_DELETE_REQUEST_]: dealNotificationDeleteRequest_,
  [Types.DEAL_NOTIFICATION_DELETE_SUCCESS_]: dealNotificationDeleteSuccess_,

  [Types.DEAL_OVERVIEW_SAVE_REQUEST]: dealOverviewSaveRequest,
  [Types.DEAL_OVERVIEW_SAVE_SUCCESS]: dealOverviewSaveSuccess,

  [Types.DEAL_ROLE_INFO_SAVE_REQUEST]: dealRoleInfoSaveRequest,
  [Types.DEAL_ROLE_INFO_SAVE_SUCCESS]: dealRoleInfoSaveSuccess,

  [Types.DEAL_INVESTOR_SOUGHT_SAVE_REQUEST]: dealInvestorSoughtSaveRequest,
  [Types.DEAL_INVESTOR_SOUGHT_SAVE_SUCCESS]: dealInvestorSoughtSaveSuccess,

  [Types.DEAL_GROUP_DATA_SAVE_REQUEST]: dealGroupDataSaveRequest,
  [Types.DEAL_GROUP_DATA_SAVE_SUCCESS]: dealGroupDataSaveSuccess,

  [Types.DEAL_WITHDRAW_REQUEST]: dealWithdrawRequest,
  [Types.DEAL_WITHDRAW_SUCCESS]: dealWithdrawSuccess,

  [Types.SET_DEAL_NOTIFICATION]: setDealNotification,

  [Types.DEALS_SYNC_TOGGLE]: dealsSyncToggle,

  [Types.DEAL_STATUSES_REQUEST]: dealStatusesRequest,
  [Types.DEAL_STATUSES_SUCCESS]: dealStatusesSuccess,

  [Types.DEAL_INVESTORS_SHARED_REQUEST]: dealInvestorsSharedRequest,
  [Types.DEAL_INVESTORS_SHARED_SUCCESS]: dealInvestorsSharedSuccess,

  [Types.DEAL_ADVISORS_SHARED_REQUEST]: dealAdvisorsSharedRequest,
  [Types.DEAL_ADVISORS_SHARED_SUCCESS]: dealAdvisorsSharedSuccess,
 
  [Types.DEAL_ADVISORS_SHARED_HISTORY_REQUEST]: dealAdvisorsSharedHistoryRequest,
  [Types.DEAL_ADVISORS_SHARED_HISTORY_SUCCESS]: dealAdvisorsSharedHistorySuccess,
 
  [Types.DEAL_SHARE_WITH_SPECIFIC_INVESTOR_REQUEST]: dealShareWithSpecificInvestorRequest,
  [Types.DEAL_SHARE_WITH_SPECIFIC_INVESTOR_SUCCESS]: dealShareWithSpecificInvestorSuccess,

  [Types.DEAL_INVESTOR_SHARE_STATUSES_REQUEST]: dealInvestorShareStatusesRequest,
  [Types.DEAL_INVESTOR_SHARE_STATUSES_SUCCESS]: dealInvestorShareStatusesSuccess,

  [Types.DEAL_SHARE_WITH_INVESTORS_REQUEST]: dealShareWithInvestorsRequest,
  [Types.DEAL_SHARE_WITH_INVESTORS_SUCCESS]: dealShareWithInvestorsSuccess,

  [Types.DEAL_RESHARE_REQUEST_BY_ADVISOR_REQUEST]: dealReshareRequestByAdvisorRequest,
  [Types.DEAL_RESHARE_REQUEST_BY_ADVISOR_SUCCESS]: dealReshareRequestByAdvisorSuccess,

  [Types.DEAL_RESHARE_BY_ADMIN_REQUEST]: dealReshareByAdminRequest,
  [Types.DEAL_RESHARE_BY_ADMIN_SUCCESS]: dealReshareByAdminSuccess,

  [Types.DEAL_RESHARE_BY_ADVISOR_REQUEST]: dealReshareByAdvisorRequest,
  [Types.DEAL_RESHARE_BY_ADVISOR_SUCCESS]: dealReshareByAdvisorSuccess,

  [Types.DEAL_SHARE_BY_ADVISOR_REQUEST]: dealShareByAdvisorRequest,
  [Types.DEAL_SHARE_BY_ADVISOR_SUCCESS]: dealShareByAdvisorSuccess,

  [Types.DEAL_INVESTOR_APPLY_REQUEST_]: dealInvestorApplyRequest_,
  [Types.DEAL_INVESTOR_APPLY_SUCCESS_]: dealInvestorApplySuccess_,

  [Types.DEAL_INVESTOR_SEND_COLLEAGUES_REQUEST_]: dealInvestorSendColleaguesRequest_,
  [Types.DEAL_INVESTOR_SEND_COLLEAGUES_SUCCESS_]: dealInvestorSendColleaguesSuccess_,

  [Types.DEAL_TARGET_STATUS_REQUEST]: dealTargetStatusRequest,
  [Types.DEAL_TARGET_STATUS_SUCCESS]: dealTargetStatusSuccess,
  [Types.DEAL_TARGET_STATUS_RESET]: dealTargetStatusReset,

  [Types.DEAL_TARGET_STATUS_SAVE_REQUEST]: dealTargetStatusSaveRequest,
  [Types.DEAL_TARGET_STATUS_SAVE_SUCCESS]: dealTargetStatusSaveSuccess,

  [Types.DEAL_TARGET_STATUS_UPDATE_REQUEST]: dealTargetStatusUpdateRequest,
  [Types.DEAL_TARGET_STATUS_UPDATE_SUCCESS]: dealTargetStatusUpdateSuccess,

  [Types.MANDATE_DEAL_REJECT_REQUEST]: mandateDealRejectRequest,
  [Types.MANDATE_DEAL_REJECT_SUCCESS]: mandateDealRejectSuccess,

  [Types.ADMIN_SHARE_MANDATE_DEAL_REQUEST]: adminShareMandateDealRequest,
  [Types.ADMIN_SHARE_MANDATE_DEAL_SUCCESS]: adminShareMandateDealSuccess,

  [Types.NOTIFICATION_CHANGE_DEAL_STATUS_REQUEST]: notificationChangeDealStatusRequest,
  [Types.NOTIFICATION_CHANGE_DEAL_STATUS_SUCCESS]: notificationChangeDealStatusSuccess,

  [Types.SET_DEAL_ID]: setDealId,

  [Types.DEAL_TABLE_SETTINGS_RESET]: dealTableSettingsReset,

  [Types.DEALS_RESET]: dealsReset,

  [Types.RESULTS_RESET]: resultsReset,

  [Types.DEALS_FETCHING_RESET]: dealsFetchingReset,
})
