import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { Button, Input, Tabs } from 'antd'
import { useHistory } from 'react-router-dom'

import SettingsActions from '../../Redux/SettingsRedux'
import Images from '../../Images'
import AdvisorSettingsForm from '../../Components/Forms/AdvisorSettingsForm'
import InvestorSettingsForm from '../../Components/Forms/InvestorSettingsForm'

const { TabPane } = Tabs;

function SettingsPage(props) {
  const { filters, user, role, subRole, saveResult, match } = props;
  const {tab} = match.params;

  const [countries, setCountries] = useState(null);
  const [sectors, setSectors] = useState(null);
  const [active, setActive] = useState(false);
  const [activeKey, setActiveKey] = useState('profile');

  const history = useHistory();

  function handleNavigate(path) {
    history.push(path);
  }

  useEffect(() => {
    props.resetGeography();
    if (role === 0) {
      props.getFilters(user.id, 'advisor');
    } else if (role === 2) {
      props.getFilters(user.id, 'investor');
    }
  }, [0]);

  useEffect(() => {
    if (role === 2) {
      setActiveKey(tab ? tab : 'profile');
    }
  }, [tab]);

  useEffect(() => {
    if (saveResult) {
      props.getFilters(user.id, 'investor');
    }
  }, [saveResult]);

  useEffect(() => {
    if (filters) {
      let arr = [];
      filters.continents.forEach(continent => {
        arr = arr.concat(continent.children);
      });
      setCountries(arr);
      setSectors(filters.industry_focus_sub_sectors);
    }
  }, [filters]);

  function updateField(field, value) {
    if (filters && filters[field] !== value) {
      const currentRole = (role === 0) ? 'advisor' : 'investor';
      props.updateFilters(user.id, currentRole, { [field]: value })
    }
  }

  function setRole() {
    switch(subRole) {
      case 0: return 'Lead Investor';
      case 1: return 'Co-Lead Investor';
      default: return 'Investor';
    }
  }

  const onTabChange = key => {
    history.push(`/home/settings/${key}`);
    setActiveKey(key);
  }

  return (
    <div className="main-container">
      {
        role === 0 ?
          <>
            <div className="section-title">Matching filters</div>
            <div className="matching-filters">
              <div className="matching-filters-header">
                <span>Country</span>
                <span>Sector</span>
                <span>Sub-sector</span>
                <div>
                  <img src={Images.edit} alt="" onClick={() => handleNavigate(`/home/advisor/filters-edit/${user.id}`)} />
                </div>
              </div>
              <div className="matching-filters-content">
                <div className="matching-filters-main">
                  <div className="matching-filters-data">
                    {
                      countries &&
                      <div>
                        <span>{countries.map(el => el.name).join(', ')}</span>
                      </div>
                    }
                  </div>
                  <div className="matching-filters-data-double">
                    {
                      sectors && sectors.map(sector => (
                        <div className="matching-filters-subdata" key={sector.id}>
                          <div className="subdata-item" style={{ textAlign: 'center' }}>
                            <span>{sector.name}</span>
                          </div>
                          <div className="subdata-item sub-sector">
                            <span>{sector.children.map(el => el.name).join(', ')}</span>
                          </div>
                          {
                            sector.additional_specification &&
                            <div className="sector-add-spec">
                              <div>
                                <span>Additional specifications:</span>
                                <div>{sector.additional_specification}</div>
                              </div>
                            </div>
                          }
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="section-divider">&nbsp;</div>

            <div className="details-section">
              <div className="details-block">
                <div className="flex-center-vertical" style={{maxWidth: 1342}}>
                  <div className="section-title">Your details</div>
                </div>
                <div className="details-info advisor-settings">
                  {
                    filters &&
                    <AdvisorSettingsForm
                      data={filters}
                      active={active}
                      updateField={updateField}
                    />
                  }
                </div>
                <Button type="primary white" className="btn-primary" style={{ width: 'auto', margin: '16px auto' }} onClick={()=>history.push('/home/settings/change-password')}>
                  Change password
                </Button>
              </div>
              {/* <div className="details-divider">&nbsp;</div>
              <div className="details-block">
                <div className="section-title">Bank details</div>
                <div className="details-info">
                  <Input
                    placeholder="Account Holder Name"
                    prefix={<span />}
                    disabled={true}
                    style={{ marginBottom: 12 }}
                  />
                  <Input
                    placeholder="Bank details"
                    prefix={<span />}
                    disabled={true}
                    style={{ marginBottom: 12 }}
                  />
                  <Input
                    placeholder="Account Number"
                    prefix={<span />}
                    disabled={true}
                    style={{ marginBottom: 12 }}
                  />
                  <Input
                    placeholder="Sort Code"
                    prefix={<span />}
                    disabled={true}
                    style={{ marginBottom: 12 }}
                  />
                  <Input
                    placeholder="IBAN"
                    prefix={<span />}
                    disabled={true}
                    style={{ marginBottom: 12 }}
                  />
                </div>
              </div> */}
            </div>
          </>
          :
          <Tabs
            activeKey={activeKey}
            style={{ marginTop: 32 }}
            onChange={onTabChange}
          >
            <TabPane tab="Profile" key="profile">
              <div className="investor-details">
                <div className="section-title">Your details</div>
                <div className="section-switcher">
                  <span>Role on IDN</span>
                  <span>{setRole()}</span>
                </div>
                <div className="details-info">
                  <img src={Images.edit} alt="" onClick={() => setActive(!active)} />
                  {
                    filters &&
                    <InvestorSettingsForm
                      data={filters}
                      active={active}
                      updateField={updateField}
                    />
                  }
                </div>
                <Button type="primary white" className="btn-primary" style={{ width: 'auto', margin: '16px auto' }} onClick={()=>history.push('/home/settings/change-password')}>
                  Change password
                </Button>
              </div>
            </TabPane>
            <TabPane tab="Matching filters" key="filters">
              <div className="firm-profile-block">
                <div className="firm-filter-section">
                  <div className="firm-filter-header flex-center-vertical">
                    <img src={Images.coins} alt="" />
                    <span>Investment focus</span>
                    <img src={Images.edit} alt="" onClick={() => handleNavigate(`/home/investor/filters-edit/${user.id}`)} />
                  </div>

                  <div className="firm-filter-content">
                    <div className="investment-range">
                      <div className="investment-range-title">Enterprise value</div>
                      <div className="investment-range-data">
                        <span>Range (ml)</span>
                        <div className="investment-range-label">From</div>
                        <Input value={filters?.investment_focus?.enterprise_value_from} disabled={true} />

                        <div className="investment-range-label">To</div>
                        <Input value={filters?.investment_focus?.enterprise_value_to} disabled={true} />
                        <span>[in £]</span>
                      </div>
                    </div>
                    <div className="investment-range">
                      <div className="investment-range-title">Equity ticket</div>
                      <div className="investment-range-data">
                        <span>Range (ml)</span>
                        <div className="investment-range-label">From</div>
                        <Input value={filters?.investment_focus?.equity_investment_from} disabled={true} />

                        <div className="investment-range-label">To</div>
                        <Input value={filters?.investment_focus?.equity_investment_to} disabled={true} />
                        <span>[in £]</span>
                      </div>
                    </div>
                    <div className="investment-range">
                      <div className="investment-range-title">Equity stake</div>
                      <div className="investment-range-data">
                        <span className="percent-span">Range (%)</span>
                        <div className="investment-range-label">From</div>
                        <Input value={filters?.investment_focus?.equity_stake_from} disabled={true} />

                        <div className="investment-range-label">To</div>
                        <Input value={filters?.investment_focus?.equity_stake_to} disabled={true} />
                      </div>
                    </div>

                    <div className="investment-range base" style={{ alignItems: 'center', paddingBottom: 16 }}>
                      <div className="investment-range-title">Governance of the targeted equity</div>
                      <div className="investment-range-value flex-center-vertical" style={{display: 'block'}}>
                        <span>{filters?.investment_focus?.governace_of_the_targeted_equity.map(el => el.name).join(', ')}</span>
                        {
                          filters?.investment_focus?.specified_governace &&
                            filters?.investment_focus?.governace_of_the_targeted_equity?.length ?
                              <span>{`, ${filters?.investment_focus?.specified_governace}`}</span>
                            :
                              <span>{filters?.investment_focus?.specified_governace}</span>
                        }
                      </div>
                    </div>

                    <div className="investment-range base" style={{ alignItems: 'center' }}>
                      <div className="investment-range-title">Type of investment</div>
                      <div className="investment-range-value flex-center-vertical" style={{display: 'block'}}>
                        <span>{filters?.investment_focus?.investment_type.filter(el => el.id !== 3).map(el => el.name).join(', ')}</span>
                        {
                          filters?.investment_focus?.comment &&
                            filters?.investment_focus?.investment_type?.length > 1 ?
                              <span>{`, ${filters?.investment_focus?.comment}`}</span>
                            :
                              <span>{filters?.investment_focus?.comment}</span>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="firm-filter-section">
                  <div className="firm-filter-header flex-center-vertical">
                    <img src={Images.puzzle} alt="" />
                    <span>Sector Focus</span>
                    <img src={Images.edit} alt="" onClick={() => handleNavigate(`/home/investor/filters-edit/${user.id}`)} />
                  </div>
                  <div className="firm-filter-content" style={{ paddingLeft: 40 }}>
                    <div className="firm-filter-line flex-center-vertical">
                      <div className="firm-filter-left">
                        <span className="flex-center-vertical">Sectors</span>
                      </div>
                      <div className="firm-filter-right">
                        <span className="flex-center-vertical">Sub Sectors</span>
                      </div>
                    </div>
                    {
                      filters?.industry_focus_sub_sectors.map(sector => (
                        <Fragment key={sector.id}>
                          <div className="firm-filter-line flex-center-vertical">
                            <div className="firm-filter-left">
                              <div className="firm-filter-item-name flex-center-vertical">{sector.name}</div>
                            </div>
                            <div className="firm-filter-right">
                              <div className="firm-filter-item-value flex-center-vertical">
                                {
                                  sector.children.map(child => (
                                    <span key={child.id}>{child.name},&nbsp;</span>
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                          {
                            sector.additional_specification &&
                            <div className="firm-filter-line flex-center-vertical">
                              <div className="firm-filter-left">
                                <div className="firm-filter-add-spec flex-center-vertical">Additional specifications:</div>
                              </div>
                              <div className="firm-filter-right">
                                <div className="firm-filter-add-spec-value flex-center-vertical">{sector.additional_specification}</div>
                              </div>
                            </div>
                          }
                        </Fragment>
                      ))
                    }
                  </div>
                </div>

                <div className="firm-filter-section">
                  <div className="firm-filter-header flex-center-vertical">
                    <img src={Images.globe} alt="" />
                    <span>Geographic Focus</span>
                    <img src={Images.edit} alt="" onClick={() => handleNavigate(`/home/investor/filters-edit/${user.id}`)} />
                  </div>
                  <div className="firm-filter-content" style={{ paddingLeft: 40 }}>
                    <div className="firm-filter-line flex-center-vertical">
                      <div className="firm-filter-left">
                        <span className="flex-center-vertical">Continents</span>
                      </div>
                      <div className="firm-filter-right">
                        <span className="flex-center-vertical">Country</span>
                      </div>
                    </div>
                    {
                      filters?.continents.map(continent => (
                        <div className="firm-filter-line flex-center-vertical" key={continent.id}>
                          <div className="firm-filter-left">
                            <div className="firm-filter-item-name flex-center-vertical">{continent.name}</div>
                          </div>
                          <div className="firm-filter-right">
                            <div className="firm-filter-item-value flex-center-vertical">
                              {
                                continent.children.map(child => (
                                  <span key={child.id}>{child.name},&nbsp;</span>
                                ))
                              }
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </TabPane>
          </Tabs>
      }
    </div >
  )
}

const mapStateToProps = (state) => {
  return {
    filters: state.settings.filters,
    user: state.auth.userInfo,
    role: state.auth.role,
    subRole: state.auth.investorSubRole,
    saveResult: state.settings.saveResult
  }
}

const mapDispatchToProps = (dispatch) => ({
  getFilters: (userId, role) => dispatch(SettingsActions.filtersRequest(userId, role)),
  updateFilters: (userId, role, data) => dispatch(SettingsActions.filtersSaveRequest(userId, role, data)),
  resetGeography: () => dispatch(SettingsActions.geographyFocusReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage)
