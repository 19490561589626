import React, { useState, useEffect } from 'react'
import { Button, Tabs, Spin, Space, Input, message } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'

import SettingsActions from '../../Redux/SettingsRedux'
import MandatesActions from '../../Redux/MandatesRedux'
import DealSharesActions from '../../Redux/DealSharesRedux'
import DealDashboardActions from '../../Redux/DealDashboardRedux'
import MandateSharingReview from '../../Components/MandateSharing/MandateSharingReview'
import MandateMatchesTable from '../../Components/Tables/MandateMatchesTable'
import Images from '../../Images'

const { TabPane } = Tabs;
const { TextArea } = Input;

function MandateInfoPage(props) {
  const { mandate, mandates, fetching, mandateAnswerQuestionResult, investmentTypes, dealSizeList, mandateDealsList, firmMandateDealsList, mandateStats, firmMandateStats, firmMandate, dealSharesNotificationResult, match, location } = props;
  const { mandateId, tab } = match.params;
  const { state } = location;

  const [mandateData, setMandateData] = useState(null);
  const [activeKey, setActiveKey] = useState('summary');
  const [questionData, setQuestionData] = useState(null);
  const [status, setStatus] = useState(null);
  const [backPath, setBackPath] = useState('');
  const [hide, setHide] = useState(false);
  const [dealList, setDealList] = useState(null);
  const [stats, setStats] = useState(null);
  const [isOwnMandate, setIsOwnMandate] = useState(null);
  const [dealId, setDealId] = useState(null);
  const [fromActivities, setFromActivities] = useState(false);

  const history = useHistory();

  useEffect(() => {
    props.resetDealDashboard();
    props.getInvestmentTypes();
    props.getDealSize();
    let path = '/home/mandates';
    if (state?.backPath) {
      path = state.backPath;
    }
    if (state?.isOwnMandate != null) {
      path = "/home/firm's-activities/mandate"
    }
    setBackPath(path);
    if (state?.hideAll) {
      setHide(true);
    }
    if (state?.isOwnMandate != null) {
      setIsOwnMandate(state?.isOwnMandate);
    }
    if (state?.fromActivities) {
      setFromActivities(true);
    }
  }, [0]);

  useEffect(() => {
    setActiveKey(tab ? tab : 'summary');
  }, [tab]);

  useEffect(() => {
    if (mandate || firmMandate) {
      const data = mandate || firmMandate;
      setMandateData(data);
      setQuestionData(data.mandate_questions?.map(el => ({
        id: el.id,
        question: el.question,
        answer: el.answer,
        date: moment(el.question_time).format('DD/MM/YYYY'),
        active: !el.answer,
        isNew: !el.answer
      })));
      setStatus(data.status_obj?.id);
    }
  }, [mandate, firmMandate]);

  useEffect(() => {
    if (mandateDealsList || firmMandateDealsList) {
      if (state && state.isOwnMandate === false) {
        setDealList(firmMandateDealsList);
      } else {
        setDealList(mandateDealsList);
      }
    }
  }, [mandateDealsList, firmMandateDealsList]);

  useEffect(() => {
    if (mandateStats || firmMandateStats) {
      if (state && state.isOwnMandate === false) {
        setStats(firmMandateStats);
      } else {
        setStats(mandateStats);
      }
    }
  }, [mandateStats, firmMandateStats]);

  useEffect(() => {
    if (mandateId) {
      if (state && state.isOwnMandate === false) {
        props.getFirmMandate(mandateId);
        props.getFirmMandateDeals(mandateId, mandates.page_fmd, mandates.page_size_fmd);
        props.getFirmMandateStats(mandateId);
      } else {
        props.getMandate(mandateId);
        props.getMandateDeals(mandateId, mandates.page_md, mandates.page_size_md);
        props.getMandateStats(mandateId);
      }
    }
  }, [mandateId]);

  useEffect(() => {
    if (status) {
      if (!state) {
        setBackPath(`/home/mandates/${status === 'closed' ? 'closed' : 'your'}`);
      }
    }
  }, [status]);

  useEffect(() => {
    if (mandateAnswerQuestionResult) {
      props.getMandate(mandateId);
    }
  }, [mandateAnswerQuestionResult]);

  useEffect(() => {
    if (dealSharesNotificationResult) {
      const item = dealList.results.find(el => el.id === dealId);
      const dashboardId = item.dashboard;
      if (dashboardId) {
        history.push({
          pathname: `/deal-dashboard/overview/${dashboardId}`,
          state: {backPath: `/home/mandate/${mandateId}/matches`}
        });
      } else {
        history.push(`/home/deal-for-mandate/${item.id}${item.share_id ? '/' + item.share_id : ''}`);
      }
    }
  }, [dealSharesNotificationResult]);

  const deleteNotification = (shareId, dealId) => {
    setDealId(dealId);
    props.deleteDealSharesNotification(shareId, { investor_notification: false });
  }

  const handleChangePage = (page, page_size) => {
    props.getMandateDeals(mandateId, page, page_size);
  }

  const onTabChange = key => {
    history.push(`/home/mandate/${mandateId}/${key}`);
    setActiveKey(key);
  }

  function setValue(index, value) {
    let newData = _.cloneDeep(questionData);
    newData[index].answer = value;
    setQuestionData(newData);
  }

  function setActive(index) {
    let newData = _.cloneDeep(questionData);
    if (newData[index].active) {
      if (!newData[index].answer) {
        message.error('The field cannot be empty', 3);
        return;
      } else {
        const data = {
          id: newData[index].id,
          answer: newData[index].answer
        };
        props.answerMandateQuestion(mandateId, data);
      }
    }
    newData[index].active = !newData[index].active;
    setQuestionData(newData);
  }

  const generateTabTitle = key => {
    let title, showNote;
    switch(key) {
      case 'matches': {
        title = 'Matches';
        showNote = dealList?.results?.some(el => el.investor_notification);
        break;
      }
      case 'questions': {
        title = 'Questions';
        showNote = questionData?.some(el => el.isNew);
        break;
      }
      default: {
        title = 'Summary';
        break;
      }
    }
    return (
      <div className="tab-title">
        <span>{title}</span>
        {
          showNote &&
            <img src={Images.notification} alt="" />
        }
      </div>
    )
  }

  return (
    <div className="sign-up-step filters-edit wo-padding">
      <div className="absolute-header">
        <div className="flex-center-vertical">
          <div className="logo-block">
            <img src={Images.logoWhite} alt="" />
          </div>
          <div className="title-block bottom">{mandateId}</div>
        </div>
      </div>

      <div className="return-block">
        <Link to={backPath}>
          <img src={Images.arrowLeft} alt="" />
          <span>Return to Dashboard</span>
        </Link>
      </div>
      <Tabs
        activeKey={activeKey}
        onChange={onTabChange}
      >
        <TabPane tab={generateTabTitle('summary')} key="summary">
          {
            status !== 'closed' && !hide &&
            <div className="tab-btn-block">
              <Button type="primary orange"
                className="btn-primary"
                onClick={() => history.push(`/home/mandate/${mandateId}/${activeKey}/close`)}
              >
                Terminate
              </Button>
              <Button type={status === 'on_hold' ? 'primary' : 'primary white'}
                className="btn-primary"
                onClick={() => history.push(`/home/mandate/${mandateId}/${activeKey}/${status === 'on_hold' ? 'unpause' : 'pause'}`)}
              >
                {status === 'on_hold' ? 'Unpause' : 'Pause'}
              </Button>
              <Button type="primary"
                className="btn-primary"
                disabled={status !== 'not_shared'}
                onClick={() => history.push(`/home/mandate-edit/${mandateId}`)}
              >
                Edit
              </Button>
            </div>
          }
          <MandateSharingReview
            mandateData={mandateData}
            investmentTypes={investmentTypes}
            dealSizeList={dealSizeList}
            own={true}
          />
          {
            status !== 'closed' && !hide &&
            <Space className="bottom-link" onClick={() => history.push(`/home/mandate/${mandateId}/${activeKey}/invite-colleagues`)}>Invite colleagues to Mandate</Space>
          }
        </TabPane>
        {
          ((status && status !== 'closed' && status !== 'on_hold') || isOwnMandate != null) &&
          <TabPane tab={generateTabTitle('questions')} key="questions" style={{ width: '100%', padding: '0 32px' }}>
            <Spin size="large" spinning={fetching}>
              <div className="question-title">Please find below Senior Advisors' enquiries related to your mandate. All answers you provide will be published on the platform and made visible to all Senior Advisors</div>
              {
                questionData?.map((el, i) => (
                  <div className={`question-block${el.isNew ? ' orange' : ''}`} key={el.id}>
                    {
                      el.isNew &&
                      <span>NEW</span>
                    }
                    <div className="question-item">{el.question}</div>
                    <TextArea
                      defaultValue={el.answer}
                      autoSize={true}
                      maxLength={5000}
                      disabled={!el.active || isOwnMandate != null}
                      onBlur={(e) => setValue(i, e.target.value)}
                    />
                    <div className="question-footer">
                      <span className="question-received">{`Received on: ${el.date}`}</span>
                      {
                        isOwnMandate == null &&
                        <span className="question-action" onClick={() => setActive(i)}>
                          {el.active ? 'Save' : 'Edit'}
                        </span>
                      }
                    </div>
                  </div>
                ))
              }
            </Spin>
          </TabPane>
        }
        <TabPane tab={generateTabTitle('matches')} key="matches" className="margin-auto">
          <div className="section-content-mandate">
            <div className="section-count">
              <span>Deals Received</span>
              <div>{stats?.deals_received}</div>
            </div>
            <div className="section-count">
              <span>Deals Engaged</span>
              <div>{stats?.deals_engaged}</div>
            </div>
            <div className="section-count">
              <span>Closed Successfully</span>
              <div>{stats?.closed_successfully}</div>
            </div>
          </div>
          <div className="divider"></div>

          <MandateMatchesTable
            data={dealList}
            mandateId={mandateId}
            page={mandates.page_md}
            fetching={mandates.fetching}
            pageSize={mandates.page_size_md}
            onChangePage={handleChangePage}
            hideAll={hide}
            isOwnMandate={isOwnMandate}
            fromActivities={fromActivities}
            deleteNotification={deleteNotification}
          />

        </TabPane>

      </Tabs>

    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    mandate: state.mandates.mandate,
    mandates: state.mandates,
    fetching: state.mandates.fetching,
    mandateAnswerQuestionResult: state.mandates.mandateAnswerQuestionResult,
    dealSizeList: state.mandates.dealSizeList,
    mandateDealsList: state.mandates.mandateDealsList,
    firmMandateDealsList: state.mandates.firmMandateDealsList,
    mandateStats: state.mandates.mandateStats,
    firmMandateStats: state.mandates.firmMandateStats,
    firmMandate: state.mandates.firmMandate,
    // dealShares: state.dealShares,
    dealSharesNotificationResult: state.dealShares.dealSharesNotificationResult,
    investmentTypes: state.settings.investmentTypes,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getMandate: (mandateId) => dispatch(MandatesActions.mandateRequest(mandateId)),
  answerMandateQuestion: (mandateId, data) => dispatch(MandatesActions.mandateAnswerQuestionRequest(mandateId, data)),
  getMandateDeals: (mandateId, page, page_size) => dispatch(MandatesActions.mandateDealsMatchesRequest(mandateId, page, page_size)),
  getMandateStats: (mandateId) => dispatch(MandatesActions.mandateStatsRequest(mandateId)),
  getDealSize: () => dispatch(MandatesActions.dealSizeRequest()),
  getFirmMandate: (mandateId) => dispatch(MandatesActions.firmMandateRequest(mandateId)),
  getFirmMandateStats: (mandateId) => dispatch(MandatesActions.firmMandateStatsRequest(mandateId)),
  getFirmMandateDeals: (mandateId, page, page_size) => dispatch(MandatesActions.firmMandateDealsMatchesRequest(mandateId, page, page_size)),

  deleteDealSharesNotification: (shareId, data) => dispatch(DealSharesActions.dealSharesNotificationDeleteRequest(shareId, data)),

  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),

  resetDealDashboard: () => dispatch(DealDashboardActions.dealDashboardReset()),

})

export default connect(mapStateToProps, mapDispatchToProps)(MandateInfoPage)
