import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import MandatesActions from '../../Redux/MandatesRedux'
import PopupWrapper from './PopupWrapper'
import Images from '../../Images'
import './PopupPage.less'

function UnpauseMandatePopupPage(props) {
  const { isAdmin, result, match, fetching } = props;
  const { mandateId, tab } = match.params;

  const history = useHistory();

  useEffect(() => {
    if (result) {
      isAdmin ?
        history.push(`/home/mandate-details/${mandateId}/${tab}`)
      :
        history.push(`/home/mandate/${mandateId}/${tab}`);
    }
  }, [result]);

  return (
    <PopupWrapper
      width={420}
      path={isAdmin ?
        `/home/mandate-details/${mandateId}/${tab}`
      :
        `/home/mandate/${mandateId}/${tab}`
    }
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content" style={{ padding: '40px 30px' }}>
          <img src={Images.phoneOk} alt="" />
          <div className="dialog-title deal">Unpause mandate</div>
          <div className="deal-text" style={{ padding: '0 10px' }}>
            By unpausing an Origination Mandate, Senior Advisors will be able to submit new investment opportunities in response to your mandate.
          </div>
          <div className="btn-footer">
            <Button type="primary white" className="btn-primary" onClick={() => props.unpauseMandate(mandateId)}>
              Continue
            </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    result: state.mandates.pauseMandateResult,
    fetching: state.mandates.fetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  unpauseMandate: (mandateId) => dispatch(MandatesActions.unpauseMandateRequest(mandateId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UnpauseMandatePopupPage)
