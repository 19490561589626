import React from 'react'
import {Button} from 'antd'
import {useHistory} from 'react-router-dom'

import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function IntroductionRequestedPopupPage(props) {
	const { match, location } = props;
	const {dashboardId} = match.params;
	const { state } = location;

	const history = useHistory()

	return (
		<PopupWrapper
			width={420}
			path={`/deal-dashboard/overview/${dashboardId}`}
		>
			<div className='dialog-content' style={{padding: 40, paddingTop: 20}}>
				<img src={Images.phoneOk} alt='' />
				<div className='dialog-title welcome' style={{marginTop: 10}}>
          Introduction requested
				</div>
				<div className='deal-text'>Introduction successfully requested.</div>
				<br />
				<div className='deal-subtext intro' style={{width: '85%'}}>The Senior Advisor will now review the agreement and, if signed, will proceed to introduce you to the target company.</div>
				<div className='btn-footer'>
					<Button
						type='primary white'
						className='btn-primary welcome'
						style={{width: 144}}
						onClick={() => history.push(`/deal-dashboard/overview/${dashboardId}`)}
					>
						{state?.isHistory ? 'Go back' : 'Continue'}
					</Button>
				</div>
			</div>
		</PopupWrapper>
	)
}

export default IntroductionRequestedPopupPage
