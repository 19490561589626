import React, { useEffect } from 'react'
import { Layout, Spin, Space, message } from 'antd'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import AuthActions from '../Redux/AuthRedux'
import LoginForm from '../Components/Forms/LoginForm'
import Images from '../Images'


function LoginPage(props) {
  const { error, fetching, location } = props;
  const { state } = location;

  const history = useHistory();

  useEffect(() => {
    if (document.body.clientWidth < 768) {
      message.info('Access must be done via desktop to have proper resolution', 10);
    }
  }, [0]);

  function handleSubmit(data) {
    props.signIn(data.email, data.password, state?.pathname, state?.hash);
  }

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <div className="sign-up-step with-bg">
          <div className="with-bg-top">
            <img src={Images.logoWhite} alt="" onClick={()=>history.push('/')} />
          </div>
          <div className="modal-container" style={{ width: 360, padding: '24px 32px' }}>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>history.push('/')}>
              <path d="M1.66663 1.66667L11 11M11 11L20.3333 20.3333M11 11L20.3333 1.66667M11 11L1.66663 20.3333" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <Space style={{marginBottom: 24}}>
              <img src={Images.phoneOk} alt="" />
            </Space>
            <div className="login-title">Log in to your account</div>
            <LoginForm
              error={error}
              resetError={props.resetError}
              onSubmit={handleSubmit} />
            <Space style={{ marginTop: 17 }}>
              <Link to="/reset-password">Forgot Password?</Link>
            </Space>
          </div>
        </div>
      </Spin>
    </Layout>
  )
}


const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    fetching: state.auth.fetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  signIn: (email, password, pathname, hash) => dispatch(AuthActions.signInRequest(email, password, pathname, hash)),
  resetError: () => dispatch(AuthActions.authErrorReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
