import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  mandatesRequest: ['page', 'page_size', 'sort_field', 'sort_order', 'search', 'filter'],
  mandatesSuccess: ['list'],

  mandateRequest: ['mandateId'],
  mandateSuccess: ['mandate'],
  mandateReset: null,

  mandateSaveRequest: ['data'],
  mandateSaveSuccess: ['saveResult'],

  mandateNotificationDeleteRequest: ['mandateId', 'data'],
  mandateNotificationDeleteSuccess: ['mandateNotificationResult'],

  dealSizeRequest: null,
  dealSizeSuccess: ['dealSizeList'],

  mandateGroupDataSaveRequest: ['mandateId', 'data'],
  mandateGroupDataSaveSuccess: ['mandateGroupDataResult'],

  dealsSubmittedRequest: ['page', 'page_size', 'mandateId'],
  dealsSubmittedSuccess: ['dealsSubmittedList'],

  investorSharedMandateRequest: ['mandateId'],
  investorSharedMandateSuccess: ['investorSharedMandateResult'],

  pauseMandateRequest : ['mandateId'],
  pauseMandateSuccess : ['pauseMandateResult'],

  unpauseMandateRequest : ['mandateId'],
  unpauseMandateSuccess : ['pauseMandateResult'],

  closeMandateRequest : ['mandateId'],
  closeMandateSuccess : ['closeMandateResult'],

  mandateAnswerQuestionRequest : ['mandateId', 'data'],
  mandateAnswerQuestionSuccess : ['mandateAnswerQuestionResult'],

  mandateInviteColleaguesRequest: ['mandateId', 'data'],
  mandateInviteColleaguesSuccess: ['mandateInviteColleaguesResult'],

  mandateStatusesRequest: null,
  mandateStatusesSuccess: ['choices'],

  mandateAdvisorsSharedRequest: ['data'],
  mandateAdvisorsSharedSuccess: ['advisorSharedList'],

  mandateAdvisorShareStatusesRequest: null,
  mandateAdvisorShareStatusesSuccess: ['advisorShareStatuses'],

  mandateShareWithAdvisorsRequest: ['mandateId', 'data'],
  mandateShareWithAdvisorsSuccess: ['mandateShareWithAdvisorsResult'],

  mandateDealsMatchesRequest: ['mandateId', 'page', 'page_size', 'filter'],
  mandateDealsMatchesSuccess: ['mandateDealsList'],

  mandateStatsRequest: ['mandateId'],
  mandateStatsSuccess: ['mandateStats'],

  mandateAskQuestionRequest: ['mandateId', 'data'],
  mandateAskQuestionSuccess: ['mandateAskQuestionResult'],

  mandateAddByAdvisorRequest: ['mandateId'],
  mandateAddByAdvisorSuccess: ['mandateAddByAdvisorResult'],

  notificationMandateStatusRequest: ['notificationId'],
  notificationMandateStatusSuccess: ['mandateResult'],

  firmMandatesRequest: ['page', 'page_size'],
  firmMandatesSuccess: ['firmMandates'],

  firmMandateRequest: ['mandateId'],
  firmMandateSuccess: ['firmMandate'],
  firmMandateReset: null,

  firmActivitiesMandateStatsRequest: null,
  firmActivitiesMandateStatsSuccess: ['firmActivitiesMandateStats'],

  firmMandateStatsRequest: ['mandateId'],
  firmMandateStatsSuccess: ['firmMandateStats'],

  firmMandateDealsMatchesRequest: ['mandateId', 'page', 'page_size'],
  firmMandateDealsMatchesSuccess: ['firmMandateDealsList'],

  mandateQuestionEditRequest: ['mandateId', 'data'],
  mandateQuestionEditSuccess: ['mandateQuestionResult'],

  mandateQuestionDeleteRequest: ['mandateId', 'data'],
  mandateQuestionDeleteSuccess: ['mandateQuestionResult'],

  mandateQuestionPublishRequest: ['mandateId', 'questionId'],
  mandateQuestionPublishSuccess: ['questionPublishResult'],

  setMandateNotification: ['mandateNotification'],

  mandatesSyncToggle: ['syncIsStart'],

  setMandateId: ['mandateId'],

  setFromDealPipeline: ['fromDP'],
 
  setFromInvestorHistory: ['fromInvestorHistory', 'investorId'],

  mandateTableSettingsReset: null,

  mandatesReset: null,

  resultsReset: null,
  mandatesFetchingReset: null
})

export const MandatesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  mandate: null,
  mandateId: null,
  fetching: false,

  advisorSharedList: null,
  mandateDealsList: null,
  dealsSubmittedList: null,
  firmMandate: null,
  firmMandateDealsList: null,

  saveResult: null,
  saveFetching: false,

  dealSizeList: null,

  mandateNotificationResult: null,
  mandateGroupDataResult: null,
  investorSharedMandateResult: null,
  pauseMandateResult: null,
  closeMandateResult: null,
  mandateAskQuestionResult: null,
  mandateAnswerQuestionResult: null,
  mandateInviteColleaguesResult: null,
  mandateShareWithAdvisorsResult: null,
  mandateAddByAdvisorResult: null,
  mandateResult: null,
  mandateQuestionResult: null,
  questionPublishResult: null,

  page:1,
  page_size:20,
  sort_field:'',
  sort_order: 1,
  search:'',

  filter: {
    sectors: [],
    equity_range: [],
    equity_stake_range: [],
    geography: [],
    investment_type: [],
    dealSize: [],
    shared: [],
    status: [],
    comment: null,
    group: ''
  },

  filter_as: {
    page: 1,
    page_size: 20,
    search: '',
    mandate: '',
    sectors: [],
    geography: [],
  },

  page_md:1,
  page_size_md:20,
  filter_md: {},

  page_fm:1,
  page_size_fm:20,

  page_ds:1,
  page_size_ds:20,

  page_fmd:1,
  page_size_fmd:20,

  mandateNotification: false,

  syncIsStart: false,

  advisorShareStatuses: null,
  choices: null,
  mandateStats: null,

  firmMandates: null,
  firmActivitiesMandateStats: null,
  firmMandateStats: null,
  fromDP: false,
  fromInvestorHistory: false,
  investorId: null,

})

/* ------------- Selectors ------------- */
export const MandatesSelectors = {
  getState: (state) => state.mandates
}

/* ------------- Reducers ------------- */

export const mandatesRequest = (state, { page, page_size, sort_field, sort_order, search, filter }) => {
  const newState = {
    fetching: true
  };
  page && (newState.page = page);
  page_size && (newState.page_size = page_size);
  newState.sort_field = sort_field ? sort_field : '';
  newState.sort_order = sort_order ? sort_order : 1;
  newState.search = search ? search : '';
  filter && (newState.filter = state.filter.merge(filter));
  return state.merge(newState)
}

export const mandatesSuccess = (state, { list }) => {
  return state.merge({ fetching: false, list })
}

export const mandateRequest = (state, { mandateId }) =>{
  return state.merge({ fetching: true })
}

export const mandateSuccess = (state, { mandate }) => {
  return state.merge({ fetching: false, mandate })
}

export const mandateReset = (state) => {
  return state.merge({ mandate: null })
}

export const mandateSaveRequest = (state, { data }) => {
  return state.merge({ saveFetching: true })
}

export const mandateSaveSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const mandateGroupDataSaveRequest = (state, { mandateId, data }) => {
  return state.merge({ saveFetching: true })
}

export const mandateGroupDataSaveSuccess = (state, { mandateGroupDataResult }) => {
  return state.merge({ saveFetching: false, mandateGroupDataResult })
}

export const mandateNotificationDeleteRequest = (state, { mandateId, data }) => {
  return state.merge({ fetching: true})
}

export const mandateNotificationDeleteSuccess = (state, { mandateNotificationResult }) => {
  return state.merge({ fetching: false, mandateNotificationResult })
}

export const dealSizeRequest = (state) => {
  return state.merge({ fetching: true})
}

export const dealSizeSuccess = (state, { dealSizeList }) => {
  return state.merge({ fetching: false, dealSizeList })
}

export const dealsSubmittedRequest = (state, { page, page_size, mandateId }) => {
  const newState = {
    fetching: true
  };
  page && (newState.page_ds = page);
  page_size && (newState.page_size_ds = page_size);
  return state.merge(newState)
}

export const dealsSubmittedSuccess = (state, { dealsSubmittedList }) => {
  return state.merge({ fetching: false, dealsSubmittedList })
}

export const investorSharedMandateRequest = (state, { mandateId }) => {
  return state.merge({ fetching: true })
}

export const investorSharedMandateSuccess = (state, { investorSharedMandateResult }) => {
  return state.merge({ fetching: false, investorSharedMandateResult })
}

export const pauseMandateRequest = (state, { mandateId }) => {
  return state.merge({ fetching: true })
}

export const pauseMandateSuccess = (state, { pauseMandateResult }) => {
  return state.merge({ fetching: false, pauseMandateResult })
}

export const unpauseMandateRequest = (state, { mandateId }) => {
  return state.merge({ fetching: true })
}

export const unpauseMandateSuccess = (state, { pauseMandateResult }) => {
  return state.merge({ fetching: false, pauseMandateResult })
}

export const closeMandateRequest = (state, { mandateId }) => {
  return state.merge({ fetching: true })
}

export const closeMandateSuccess = (state, { closeMandateResult }) => {
  return state.merge({ fetching: false, closeMandateResult })
}

export const mandateAnswerQuestionRequest = (state, { mandateId, data }) => {
  return state.merge({ fetching: true })
}

export const mandateAnswerQuestionSuccess = (state, { mandateAnswerQuestionResult }) => {
  return state.merge({ fetching: false, mandateAnswerQuestionResult })
}

export const mandateInviteColleaguesRequest = (state, { mandateId, data }) => {
  return state.merge({ fetching: true })
}

export const mandateInviteColleaguesSuccess = (state, { mandateInviteColleaguesResult }) => {
  return state.merge({ fetching: false, mandateInviteColleaguesResult })
}

export const mandateStatusesRequest = (state) => {
  return state.merge({ fetching: true })
}

export const mandateStatusesSuccess = (state, { choices }) => {
  return state.merge({ fetching: false, choices })
}

export const mandateAdvisorsSharedRequest = (state, { data }) => {
  const newState = {
    fetching: true
  };
  newState.filter_as = state.filter_as.merge(data);
  return state.merge(newState)
}

export const mandateAdvisorsSharedSuccess = (state, { advisorSharedList }) => {
  return state.merge({ fetching: false, advisorSharedList })
}

export const mandateAdvisorShareStatusesRequest = (state) => {
  return state.merge({ fetching: true })
}

export const mandateAdvisorShareStatusesSuccess = (state, { advisorShareStatuses }) => {
  return state.merge({ fetching: false, advisorShareStatuses })
}

export const mandateShareWithAdvisorsRequest = (state, { mandateId, data }) => {
  return state.merge({ fetching: true })
}

export const mandateShareWithAdvisorsSuccess = (state, { mandateShareWithAdvisorsResult }) => {
  return state.merge({ fetching: false, mandateShareWithAdvisorsResult })
}

export const mandateDealsMatchesRequest = (state, { mandateId, page, page_size, filter }) => {
  const newState = {
    fetching: true
  };
  page && (newState.page_md = page);
  page_size && (newState.page_size_md = page_size);
  filter && (newState.filter_md = state.filter_md.merge(filter));
  return state.merge(newState)
}

export const mandateDealsMatchesSuccess = (state, { mandateDealsList }) => {
  return state.merge({ fetching: false, mandateDealsList })
}

export const mandateStatsRequest = (state, { mandateId }) => {
  return state.merge({ fetching: true })
}

export const mandateStatsSuccess = (state, { mandateStats }) => {
  return state.merge({ fetching: false, mandateStats })
}

export const mandateAskQuestionRequest = (state, { mandateId, data }) => {
  return state.merge({ fetching: true })
}

export const mandateAskQuestionSuccess = (state, { mandateAskQuestionResult }) => {
  return state.merge({ fetching: false, mandateAskQuestionResult })
}

export const mandateAddByAdvisorRequest = (state, { mandateId }) => {
  return state.merge({ fetching: true })
}

export const mandateAddByAdvisorSuccess = (state, { mandateAddByAdvisorResult }) => {
  return state.merge({ fetching: false, mandateAddByAdvisorResult })
}

export const notificationMandateStatusRequest = (state, { notificationId }) => {
  return state.merge({ fetching: true })
}

export const notificationMandateStatusSuccess = (state, { mandateResult }) => {
  return state.merge({ fetching: false, mandateResult })
}

export const firmMandatesRequest = (state, { page, page_size }) => {
  const newState = {
    fetching: true
  };
  page && (newState.page_fm = page);
  page_size && (newState.page_size_fm = page_size);
  return state.merge(newState)
}

export const firmMandatesSuccess = (state, { firmMandates }) => {
  return state.merge({ fetching: false, firmMandates })
}

export const firmMandateRequest = (state, { mandateId }) => {
  return state.merge({ fetching: true})
}

export const firmMandateSuccess = (state, { firmMandate }) => {
  return state.merge({ fetching: false, firmMandate })
}

export const firmMandateReset = (state) => {
  return state.merge({ firmMandate: null })
}

export const firmActivitiesMandateStatsRequest = (state) => {
  return state.merge({ fetching: true})
}

export const firmActivitiesMandateStatsSuccess = (state, { firmActivitiesMandateStats }) => {
  return state.merge({ fetching: false, firmActivitiesMandateStats })
}

export const firmMandateStatsRequest = (state, {mandateId}) => {
  return state.merge({ fetching: true})
}

export const firmMandateStatsSuccess = (state, { firmMandateStats }) => {
  return state.merge({ fetching: false, firmMandateStats })
}

export const firmMandateDealsMatchesRequest = (state, { mandateId, page, page_size }) => {
  const newState = {
    fetching: true
  };
  page && (newState.page_fmd = page);
  page_size && (newState.page_size_fmd = page_size);
  return state.merge(newState)
}

export const firmMandateDealsMatchesSuccess = (state, { firmMandateDealsList }) => {
  return state.merge({ fetching: false, firmMandateDealsList })
}

export const mandateQuestionEditRequest = (state, {mandateId, data}) => {
  return state.merge({ fetching: true})
}

export const mandateQuestionEditSuccess = (state, { mandateQuestionResult }) => {
  return state.merge({ fetching: false, mandateQuestionResult })
}

export const mandateQuestionDeleteRequest = (state, {mandateId, data}) => {
  return state.merge({ fetching: true})
}

export const mandateQuestionDeleteSuccess = (state, { mandateQuestionResult }) => {
  return state.merge({ fetching: false, mandateQuestionResult })
}

export const mandateQuestionPublishRequest = (state, {mandateId, questionId}) => {
  return state.merge({ fetching: true})
}

export const mandateQuestionPublishSuccess = (state, { questionPublishResult }) => {
  return state.merge({ fetching: false, questionPublishResult })
}

export const setMandateNotification = (state, { mandateNotification }) => {
  return state.merge({ mandateNotification })
}

export const mandatesSyncToggle = (state, { syncIsStart }) => {
  return state.merge({ syncIsStart })
}

export const setMandateId = (state, {mandateId}) => {
  return state.merge({ mandateId })
}

export const setFromDealPipeline = (state, {fromDP}) => {
  return state.merge({ fromDP })
}

export const setFromInvestorHistory = (state, {fromInvestorHistory, investorId}) => {
  return state.merge({ fromInvestorHistory, investorId })
}

export const mandateTableSettingsReset = (state) =>{
  const newState = {
    page:1,
    sort_field:'',
    sort_order: 1,
    search:'',
    page_as:1,
    search_as:'',
    page_md:1,
    page_fm:1,
    page_ds:1,
    page_fmd:1,
    filter: {
      sectors: [],
      equity_range: [],
      equity_stake_range: [],
      geography: [],
      investment_type: [],
      dealSize: [],
      shared: [],
      status: [],
      comment: null,
      group: ''
    },
    filter_as: {
      page: 1,
      page_size: 20,
      search: '',
      mandate: '',
      sectors: [],
      geography: [],
    },
    filter_md: {},
  };
  return state.merge(newState)
}

export const mandatesReset = (state) => {
  return INITIAL_STATE
}

export const resultsReset = (state) => {
  return state.merge({
    saveResult: null,
    mandateNotificationResult: null,
    mandateGroupDataResult: null,
    investorSharedMandateResult: null,
    pauseMandateResult: null,
    closeMandateResult: null,
    mandateAskQuestionResult: null,
    mandateAnswerQuestionResult: null,
    mandateInviteColleaguesResult: null,
    mandateShareWithAdvisorsResult: null,
    mandateAddByAdvisorResult: null,
    mandateResult: null,
    mandateQuestionResult: null,
    questionPublishResult: null
  })
}

export const mandatesFetchingReset = (state) => {
  return state.merge({
    fetching:false,
    saveFetching:false,
  })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.MANDATES_REQUEST]: mandatesRequest,
  [Types.MANDATES_SUCCESS]: mandatesSuccess,

  [Types.MANDATE_REQUEST]: mandateRequest,
  [Types.MANDATE_SUCCESS]: mandateSuccess,
  [Types.MANDATE_RESET]: mandateReset,

  [Types.MANDATE_SAVE_REQUEST]: mandateSaveRequest,
  [Types.MANDATE_SAVE_SUCCESS]: mandateSaveSuccess,

  [Types.MANDATE_GROUP_DATA_SAVE_REQUEST]: mandateGroupDataSaveRequest,
  [Types.MANDATE_GROUP_DATA_SAVE_SUCCESS]: mandateGroupDataSaveSuccess,

  [Types.MANDATE_NOTIFICATION_DELETE_REQUEST]: mandateNotificationDeleteRequest,
  [Types.MANDATE_NOTIFICATION_DELETE_SUCCESS]: mandateNotificationDeleteSuccess,

  [Types.DEAL_SIZE_REQUEST]: dealSizeRequest,
  [Types.DEAL_SIZE_SUCCESS]: dealSizeSuccess,

  [Types.DEALS_SUBMITTED_REQUEST]: dealsSubmittedRequest,
  [Types.DEALS_SUBMITTED_SUCCESS]: dealsSubmittedSuccess,

  [Types.INVESTOR_SHARED_MANDATE_REQUEST]: investorSharedMandateRequest,
  [Types.INVESTOR_SHARED_MANDATE_SUCCESS]: investorSharedMandateSuccess,

  [Types.PAUSE_MANDATE_REQUEST]: pauseMandateRequest,
  [Types.PAUSE_MANDATE_SUCCESS]: pauseMandateSuccess,

  [Types.UNPAUSE_MANDATE_REQUEST]: unpauseMandateRequest,
  [Types.UNPAUSE_MANDATE_SUCCESS]: unpauseMandateSuccess,

  [Types.CLOSE_MANDATE_REQUEST]: closeMandateRequest,
  [Types.CLOSE_MANDATE_SUCCESS]: closeMandateSuccess,

  [Types.MANDATE_ANSWER_QUESTION_REQUEST]: mandateAnswerQuestionRequest,
  [Types.MANDATE_ANSWER_QUESTION_SUCCESS]: mandateAnswerQuestionSuccess,

  [Types.MANDATE_INVITE_COLLEAGUES_REQUEST]: mandateInviteColleaguesRequest,
  [Types.MANDATE_INVITE_COLLEAGUES_SUCCESS]: mandateInviteColleaguesSuccess,

  [Types.MANDATE_STATUSES_REQUEST]: mandateStatusesRequest,
  [Types.MANDATE_STATUSES_SUCCESS]: mandateStatusesSuccess,

  [Types.MANDATE_ADVISORS_SHARED_REQUEST]: mandateAdvisorsSharedRequest,
  [Types.MANDATE_ADVISORS_SHARED_SUCCESS]: mandateAdvisorsSharedSuccess,

  [Types.MANDATE_ADVISOR_SHARE_STATUSES_REQUEST]: mandateAdvisorShareStatusesRequest,
  [Types.MANDATE_ADVISOR_SHARE_STATUSES_SUCCESS]: mandateAdvisorShareStatusesSuccess,

  [Types.MANDATE_SHARE_WITH_ADVISORS_REQUEST]: mandateShareWithAdvisorsRequest,
  [Types.MANDATE_SHARE_WITH_ADVISORS_SUCCESS]: mandateShareWithAdvisorsSuccess,

  [Types.MANDATE_DEALS_MATCHES_REQUEST]: mandateDealsMatchesRequest,
  [Types.MANDATE_DEALS_MATCHES_SUCCESS]: mandateDealsMatchesSuccess,

  [Types.MANDATE_STATS_REQUEST]: mandateStatsRequest,
  [Types.MANDATE_STATS_SUCCESS]: mandateStatsSuccess,

  [Types.MANDATE_ASK_QUESTION_REQUEST]: mandateAskQuestionRequest,
  [Types.MANDATE_ASK_QUESTION_SUCCESS]: mandateAskQuestionSuccess,

  [Types.MANDATE_ADD_BY_ADVISOR_REQUEST]: mandateAddByAdvisorRequest,
  [Types.MANDATE_ADD_BY_ADVISOR_SUCCESS]: mandateAddByAdvisorSuccess,

  [Types.NOTIFICATION_MANDATE_STATUS_REQUEST]: notificationMandateStatusRequest,
  [Types.NOTIFICATION_MANDATE_STATUS_SUCCESS]: notificationMandateStatusSuccess,

  [Types.FIRM_MANDATES_REQUEST]: firmMandatesRequest,
  [Types.FIRM_MANDATES_SUCCESS]: firmMandatesSuccess,

  [Types.FIRM_MANDATE_REQUEST]: firmMandateRequest,
  [Types.FIRM_MANDATE_SUCCESS]: firmMandateSuccess,
  [Types.FIRM_MANDATE_RESET]: firmMandateReset,

  [Types.FIRM_ACTIVITIES_MANDATE_STATS_REQUEST]: firmActivitiesMandateStatsRequest,
  [Types.FIRM_ACTIVITIES_MANDATE_STATS_SUCCESS]: firmActivitiesMandateStatsSuccess,

  [Types.FIRM_MANDATE_STATS_REQUEST]: firmMandateStatsRequest,
  [Types.FIRM_MANDATE_STATS_SUCCESS]: firmMandateStatsSuccess,

  [Types.FIRM_MANDATE_DEALS_MATCHES_REQUEST]: firmMandateDealsMatchesRequest,
  [Types.FIRM_MANDATE_DEALS_MATCHES_SUCCESS]: firmMandateDealsMatchesSuccess,

  [Types.MANDATE_QUESTION_EDIT_REQUEST]: mandateQuestionEditRequest,
  [Types.MANDATE_QUESTION_EDIT_SUCCESS]: mandateQuestionEditSuccess,

  [Types.MANDATE_QUESTION_DELETE_REQUEST]: mandateQuestionDeleteRequest,
  [Types.MANDATE_QUESTION_DELETE_SUCCESS]: mandateQuestionDeleteSuccess,

  [Types.MANDATE_QUESTION_PUBLISH_REQUEST]: mandateQuestionPublishRequest,
  [Types.MANDATE_QUESTION_PUBLISH_SUCCESS]: mandateQuestionPublishSuccess,

  [Types.SET_MANDATE_NOTIFICATION]: setMandateNotification,

  [Types.MANDATES_SYNC_TOGGLE]: mandatesSyncToggle,

  [Types.SET_MANDATE_ID]: setMandateId,

  [Types.SET_FROM_DEAL_PIPELINE]: setFromDealPipeline,
  
  [Types.SET_FROM_INVESTOR_HISTORY]: setFromInvestorHistory,

  [Types.MANDATE_TABLE_SETTINGS_RESET]: mandateTableSettingsReset,

  [Types.MANDATES_RESET]: mandatesReset,

  [Types.RESULTS_RESET]: resultsReset,

  [Types.MANDATES_FETCHING_RESET]: mandatesFetchingReset,
})
