import React, {useState, useEffect} from 'react'
import {Table} from 'antd'
import moment from 'moment'

import './Table.less'

function ExpertCActionsTable(props) {
	const {data} = props;

	const [dataSource, setDataSource] = useState(null);

	useEffect(() => {
		let arr = data ? data.asMutable({deep: true}) : [];
		arr = arr
			.map(el => ({
				id: el.id,
				title: el.action_title,
				created: el.created,
        days: el.days,
				// userDate: el.user_date,
			}))
		setDataSource(arr)
	}, [data]);


	function getColumns() {
		return [
			{
				title: 'Action title',
				dataIndex: 'title',
				key: 'title',
			},
			{
				title: 'Date created',
				dataIndex: 'created',
				key: 'created',
				render: (text, record) => {
					return record.created ? (
						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
							<span>{text && moment(text).format('DD/MM/YYYY')}</span>
							<span>{text && moment(text).format('HH:mm')}</span>
						</div>
					) : null
				},
			},
			{
				title: 'Days',
				dataIndex: 'days',
				key: 'days',
				render: (days, record) => {
					return <span>{days ? days : ''}</span>
				},
			},
		]
	}

	return (
		<Table
			className='deal-table actions'
			rowKey={row => row.id}
			dataSource={dataSource}
			columns={getColumns()}
			scrollToFirstRowOnChange
			pagination={false}
		/>
	)
}

export default ExpertCActionsTable;
