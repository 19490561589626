import React, { useState, useEffect } from 'react'
import { Layout, Progress, Button, Space } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Switch, Route, Link } from 'react-router-dom'

import SettingsActions from '../../Redux/SettingsRedux'
import MandatesActions from '../../Redux/MandatesRedux'
import NotificationsActions from '../../Redux/NotificationsRedux'

import MandateSharingStart from '../../Components/MandateSharing/MandateSharingStart'
import MandateSharingReview from '../../Components/MandateSharing/MandateSharingReview'
import Images from '../../Images'

const initial = {
  start: {
    title: `Targets' criteria`,
    progress: 50,
    text: `Please provide detailed specifications about the investment opportunities you are looking for.
    Our Senior Advisors will respond to this mandate by sharing proprietary opportunities matching the criteria you indicate`
  },
  review: {
    title: 'Review',
    progress: 100,
    text: 'Please review the information you inserted before issuing the origination mandate'
  }
}

function MandateSharingPage(props) {
  const { isAdmin, user, mandate, saveResult, match, location, sectorFocus, geographyFocus, investmentTypes, filters, dealSizeList } = props;
  const { step } = match.params;
  const { state } = location;

  //const [mandateId, setMandateId] = useState(null);
  const [checkRequired, setCheckRequired] = useState(false);
  const [mandateData, setMandateData] = useState(null);
  const [titleData, setTitleData] = useState(initial);
  const [mandateId, setMandateId] = useState(null);

  const history = useHistory();

  function changeStep(data) {
    if (step === 'start') {
      data.investor_id = isAdmin ? user?.id : null;
      props.createMandate(data);
    } else if (step === 'review') {
      history.push({
        pathname: '/home/mandate-sharing/step/review/mandate-shared',
        state: mandateId
      });
    }
  }

  useEffect(() => {
    if (!user?.all_agreements) {
      history.push('/home/mandates');
      return;
    }
    !isAdmin && props.notificationsSyncToggle(false);
    props.resetMandate();
    props.getSectors();
    props.getContinents({ regions: true });
    props.getInvestmentTypes();
    props.getDealSize();
    props.getFirmFilters(user?.company?.id);
    return function cleanup() {
      !isAdmin && props.notificationsSyncToggle(true);
    }
  }, [0]);

  useEffect(() => {
    if (saveResult) {
      setMandateId(saveResult.id);
    }
  }, [saveResult]);

  useEffect(() => {
    if (state?.mandateId) {
      setMandateId(state.mandateId);
    }
  }, [state]);

  useEffect(() => {
    if (mandateId) {
      props.getMandate(mandateId);
    }
  }, [mandateId]);

  useEffect(() => {
    if (mandate) {
      setMandateData(mandate);
      history.push('/home/mandate-sharing/step/review');
    }
  }, [mandate]);

  const checkRequiredFields = () => {
    setCheckRequired(true);
    setTimeout(()=> {
      setCheckRequired(false);
    })
  }

  const generateReturnLink = () => {
    return isAdmin ? `/home/investor-details/${user?.id}` : `/home/mandates`
  }

  return (
    <Layout>
      <div className="sign-up-step white filters-edit mandateSharing">
        <img src={Images.logo} alt="" />
        {
          step !== 'edit' &&
          <div className="step-investor-content">
            <div className="step-title">ORIGINATION MANDATE</div>
            <div className="step-number" style={{margin: '8px 0'}}>{titleData[step].title}</div>
            <Progress
              className="progress-bar"
              percent={titleData[step].progress}
              showInfo={false}
              strokeWidth={13}
              trailColor="rgba(31, 73, 228, 0.12)"
              strokeColor="#3680F6"
              status="active"
            />
            <div className="step-text">{titleData[step].text}</div>
          </div>
        }
        <Switch>
          <Route path="/home/mandate-sharing/step/start" render={
            props => (
              <MandateSharingStart
                checkRequired={checkRequired}
                isAccordion={true}
                mandateData={true}
                sectorFocus={sectorFocus}
                geographyFocus={geographyFocus}
                investmentTypes={investmentTypes}
                dealSizeList={dealSizeList}
                firmOverview={filters?.overview}
                onChangeStep={changeStep}
              />
            )} />
          <Route path="/home/mandate-sharing/step/review" render={
            props => (
              <MandateSharingReview
                mandateId={mandateId}
                mandateData={mandateData}
                investmentTypes={investmentTypes}
                dealSizeList={dealSizeList}
                isSharing={true}
                own={true}
                returnLink={generateReturnLink()}
                onChangeStep={changeStep}
              />
            )} />
        </Switch>
        {
          step !== 'review' &&
          <>
            <Button type="primary" className="btn-primary" style={{ width: 217 }} onClick={checkRequiredFields}>
              Continue
            </Button>
            <Space className="return-link" style={{marginTop: 24}}>
              <Link to={generateReturnLink()}>
                <img src={Images.arrowLeft} alt="" />
                <span>Return to main dashboard</span>
              </Link>
            </Space>
          </>
        }
      </div>
    </Layout>
  )
}


const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    //user: state.auth.userInfo,
    mandate: state.mandates.mandate,
    saveResult: state.mandates.saveResult,
    user: state.settings.filters,
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    investmentTypes: state.settings.investmentTypes,
    filters: state.settings.firmFilters,
    dealSizeList: state.mandates.dealSizeList,
  }
}

const mapDispatchToProps = (dispatch) => ({
  createMandate: (data) => dispatch(MandatesActions.mandateSaveRequest(data)),
  getMandate: (mandateId) => dispatch(MandatesActions.mandateRequest(mandateId)),
  resetMandate: () => dispatch(MandatesActions.mandatesReset()),

  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: (filter) => dispatch(SettingsActions.geographyFocusRequest(filter)),
  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),
  getFirmFilters: (firmId) => dispatch(SettingsActions.firmFiltersRequest(firmId)),
  getDealSize: () => dispatch(MandatesActions.dealSizeRequest()),

  notificationsSyncToggle: (syncIsStart) => dispatch(NotificationsActions.notificationsSyncToggle(syncIsStart)),


})

export default connect(mapStateToProps, mapDispatchToProps)(MandateSharingPage)
