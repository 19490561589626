import React, { useState, useEffect } from 'react'
import { Form, Input, Space, Button, Row, Col } from 'antd'

import Images from '../../Images'

const { TextArea } = Input;

function DncInvestorForm(props) {
  const { checkForm, defaultData } = props;

  const [start, setStart] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (checkForm != null) {
      form.validateFields()
        .then(values => {
          const data = { ...values };
          props.onSubmit(data.users);
        })
        .catch(error => {
          props.onSubmit();
        })
    }
  }, [checkForm]);

  return (
    <Form
      id="dnc-investor-form"
      initialValues={{ users: defaultData ? defaultData : [] }}
      form={form}
      className="login-form"
      layout="vertical">
      <Form.List name="users">
        {(fields, { add, remove }) => {
          if (!defaultData.length) {
            if (!start) {
              add();
              setStart(true);
            }
          }
          return (
            <>
              {fields.map((field, i) => (
                <div key={field.key} className="dnc-form-wrapper">
                  <Row>
                    <Col span={6} offset={2}>
                      <Form.Item>
                        <Space className="dnc-title">
                          <div>
                            <img src={Images.user} alt="" />
                            {`DNC Investor ${i + 1}`}
                          </div>
                          <img src={Images.ex} alt="" onClick={() => remove(field.name)} />
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Row gutter={24}>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'firm_name']}
                            fieldKey={[field.fieldKey, 'firm_name']}
                            rules={[{ required: true, message: 'This field is required' }]}
                          >
                            <Input
                              placeholder="Name of Firm"
                              maxLength={100}
                              suffix={'*'}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'website']}
                            fieldKey={[field.fieldKey, 'website']}
                            rules={[{ required: true, message: 'This field is required' }]}
                          >
                            <Input
                              placeholder="Website (main page)"
                              maxLength={100}
                              suffix={'*'}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'comment']}
                            fieldKey={[field.fieldKey, 'comment']}
                          >
                            <TextArea
                              placeholder="Comments or specifications"
                              rows={3}
                              maxLength={3000}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                </div>
              ))}
              <Form.Item>
                <div className="btn-footer invite">
                  <Button type="primary white" className="btn-primary" style={{ width: 217 }} onClick={() => fields.length < 20 && add()} disabled={fields.length > 19}>
                    Add Another One
                    </Button>
                </div>
              </Form.Item>
            </>
          )
        }}
      </Form.List>
    </Form>
  )
}

export default DncInvestorForm
