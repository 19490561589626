import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Space, message } from 'antd'
import Utils from '../../Utils/utils'

import Images from '../../Images'

function LoginForm(props) {
  const { error, resetError, onSubmit } = props;

  const [active, setActive] = useState(false);

  const [form] = Form.useForm();

  const onFinish = values => {
    onSubmit(values);
  };

  useEffect(() => {
    if (error && error.status_code !== 404 && !error.is_blocked) {
      setActive(true);
    } else if (error?.status_code === 404) {
      message.error('The link has been already used or expired. Please login again.', 7);
    }
    resetError();
  }, [error])

  useEffect(() => {
    if (active) {
      setTimeout(() => {
        setActive(false);
      }, 3000);
    }
  }, [active])

  return (
    <Form onFinish={onFinish} form={form} className="login-form" layout="vertical">

      <Form.Item
        name="email"
        validateTrigger="onBlur"
        rules={[
          { type: 'email', message: 'Invalid email format' },
          { required: true, message: 'This field is required' },
          { min: 5, message: 'Field must contain at least 5 characters' },
        ]}
        onKeyPress={e=>{if(e.charCode === 32) e.preventDefault()}}
        onBlur={e=>Utils.clearSpaces(e, 'email', form.setFieldsValue)}
      >
        <Input
          placeholder="Email address"
          maxLength={100}
          prefix={<img src={Images.envelope} alt="" />}
        />
      </Form.Item>

      <Form.Item
        name="password"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: "This field is required" },
          { pattern: /^[a-zA-Z0-9!@#$]+$/, message: 'Invalid character' },
          { min: 8, message: 'Field must contain at least 8 characters' },
        ]}
      >
        <Input.Password
          placeholder="Password"
          maxLength={100}
          autoComplete="new-password"
          prefix={<img src={Images.key} alt="" />}
        />
      </Form.Item>
      {
        active &&
          <Space>
            <span className="error-message">Incorrect login or password</span>
          </Space>
      }
      <Button type="primary white" htmlType="submit" className="btn-primary" style={{width: 217, marginTop: 24}}>
        Log in
      </Button>

    </Form>
  )
}

export default LoginForm
