import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  usersRequest: ['role', 'page', 'page_size', 'sort_field', 'sort_order', 'search', 'filter', 'forFirm'],
  usersSuccess: ['list'],

  userSaveRequest: ['role', 'data'],
  userSaveSuccess: ['saveResult'],

  userDeleteRequest: ['role', 'id'],
  userDeleteSuccess: ['deleteResult'],

  usersReset: null,
  userSearchReset: null,

  investorRolesRequest: null,
  investorRolesSuccess: ['investorRoles'],

  investorColleaguesRequest: ['filter'],
  investorColleaguesSuccess: ['investorColleagues'],

  userLockHistoryRequest: ['userId'],
  userLockHistorySuccess: ['lockHistory'],

  userToggleLockRequest: ['userId', 'action'],
  userToggleLockSuccess: ['result'],

  userChoicesRequest: ['role'],
  userChoicesSuccess: ['choices'],

  userChangePasswordRequest: ['userId', 'data'],
  userChangePasswordSuccess: ['result'],

  resultsReset: null,

  userTableSettingsReset: null,

  userFetchingReset: null
})

export const UsersTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  fetching: false,
  result: null,

  saveResult: null,
  saveFetching: false,

  deleteResult: null,
  deleteFetching: false,

  page_firm:1,
  page_size_firm:20,
  sort_field_firm:'',
  sort_order_firm: 1,

  page_inv:1,
  page_size_inv:20,
  sort_field_inv:'',
  sort_order_inv: 1,
  search_inv: '',
  filter_inv: {
    ev_range: [],
    sectors: [],
    open_to_new_sectors: null,
    equity_range: [],
    geography: [],
    open_to_greenfield: null,
    governance: [],
    role: null,
    account_manager: null,
    invest_company: null
  },

  page_adv:1,
  page_size_adv:20,
  sort_field_adv:'',
  sort_order_adv: 1,
  search_adv: '',
  filter_adv: {
    sectors: [],
    geography: [],
    status: null,
    account_manager: null,
  },

  investorRoles: null,
  investorColleagues: null,

  lockHistory: null,
  choices: null
})

/* ------------- Selectors ------------- */
export const UsersSelectors = {
  getState: state => state.users,
}

/* ------------- Reducers ------------- */


export const usersRequest = (state, { role, page, page_size, sort_field, sort_order, search, filter, forFirm }) => {
  const newState = {
    fetching: true
  };
  if (forFirm) {
    page && (newState.page_firm = page);
    page_size && (newState.page_size_firm = page_size);
    newState.sort_field_firm = sort_field ? sort_field : '';
    newState.sort_order_firm = sort_order ? sort_order : 1;
  } else if (role === 'investor') {
    page && (newState.page_inv = page);
    page_size && (newState.page_size_inv = page_size);
    newState.sort_field_inv = sort_field ? sort_field : '';
    newState.sort_order_inv = sort_order ? sort_order : 1;
    newState.search_inv = search ? search : '';
    filter && (newState.filter_inv = state.filter_inv.merge(filter));
  } else if (role === 'advisor'){
    page && (newState.page_adv = page);
    page_size && (newState.page_size_adv = page_size);
    newState.sort_field_adv = sort_field ? sort_field : '';
    newState.sort_order_adv = sort_order ? sort_order : 1;
    newState.search_adv = search ? search : '';
    filter && (newState.filter_adv = state.filter_adv.merge(filter));
  }
  return state.merge(newState)
}

export const usersSuccess = (state, { list }) => {
  return state.merge({ fetching: false, list })
}

export const userSaveRequest = (state, { role, data }) => {
  return state.merge({ saveFetching: true})
}

export const userSaveSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const userDeleteRequest = (state, { role, id }) => {
  return state.merge({ deleteFetching: true})
}

export const userDeleteSuccess = (state, { deleteResult }) => {
  return state.merge({ deleteFetching: false, deleteResult })
}

export const usersReset = (state) =>{
  return INITIAL_STATE
}

export const userSearchReset = (state) => {
  return state.merge({ search: '' })
}

export const investorRolesRequest = (state) =>{
  return state.merge({ fetching: true })
}

export const investorRolesSuccess = (state, { investorRoles }) =>{
  return state.merge({ fetching: false, investorRoles })
}

export const investorColleaguesRequest = (state) =>{
  return state.merge({ fetching: true })
}

export const investorColleaguesSuccess = (state, { investorColleagues }) =>{
  return state.merge({ fetching: false, investorColleagues })
}

export const userLockHistoryRequest = (state, { userId }) => {
  return state.merge({ fetching: true})
}

export const userLockHistorySuccess = (state, { lockHistory }) => {
  return state.merge({ fetching: false, lockHistory })
}

export const userToggleLockRequest = (state, { userId, action }) => {
  return state.merge({ fetching: true})
}

export const userToggleLockSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const userChoicesRequest = (state, { role }) =>{
  return state.merge({ fetching: true })
}

export const userChoicesSuccess = (state, { choices }) =>{
  return state.merge({ fetching: false, choices })
}

export const userChangePasswordRequest = (state, { userId, data }) =>{
  return state.merge({ fetching: true })
}

export const userChangePasswordSuccess = (state, { result }) =>{
  return state.merge({ fetching: false, result })
}

export const resultsReset = (state) => {
  return state.merge({ result: null, saveResult: null, deleteResult: null })
}

export const userTableSettingsReset = (state) =>{
  const newState = {
    page_firm:1,
    sort_field_firm:'',
    sort_order_firm: 1,

    page_inv:1,
    sort_field_inv:'',
    sort_order_inv: 1,
    search_inv: '',
    filter_inv: {
      ev_range: [],
      sectors: [],
      open_to_new_sectors: null,
      equity_range: [],
      geography: [],
      open_to_greenfield: null,
      governance: [],
      role: null,
      account_manager: null,
      invest_company: null
    },

    page_adv:1,
    sort_field_adv:'',
    sort_order_adv: 1,
    search_adv: '',
    filter_adv: {
      sectors: [],
      geography: [],
      status: null,
      account_manager: null,
    },
  };
  return state.merge(newState)
}

export const userFetchingReset = (state) => {
  return state.merge({ fetching:false, saveFetching:false, deleteFetching: false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.USERS_REQUEST]: usersRequest,
  [Types.USERS_SUCCESS]: usersSuccess,

  [Types.USER_SAVE_REQUEST]: userSaveRequest,
  [Types.USER_SAVE_SUCCESS]: userSaveSuccess,

  [Types.USER_DELETE_REQUEST]: userDeleteRequest,
  [Types.USER_DELETE_SUCCESS]: userDeleteSuccess,

  [Types.USERS_RESET]: usersReset,
  [Types.USER_SEARCH_RESET]: userSearchReset,

  [Types.INVESTOR_ROLES_REQUEST]: investorRolesRequest,
  [Types.INVESTOR_ROLES_SUCCESS]: investorRolesSuccess,

  [Types.INVESTOR_COLLEAGUES_REQUEST]: investorColleaguesRequest,
  [Types.INVESTOR_COLLEAGUES_SUCCESS]: investorColleaguesSuccess,

  [Types.USER_LOCK_HISTORY_REQUEST]: userLockHistoryRequest,
  [Types.USER_LOCK_HISTORY_SUCCESS]: userLockHistorySuccess,

  [Types.USER_TOGGLE_LOCK_REQUEST]: userToggleLockRequest,
  [Types.USER_TOGGLE_LOCK_SUCCESS]: userToggleLockSuccess,

  [Types.USER_CHOICES_REQUEST]: userChoicesRequest,
  [Types.USER_CHOICES_SUCCESS]: userChoicesSuccess,

  [Types.USER_CHANGE_PASSWORD_REQUEST]: userChangePasswordRequest,
  [Types.USER_CHANGE_PASSWORD_SUCCESS]: userChangePasswordSuccess,

  [Types.RESULTS_RESET]: resultsReset,

  [Types.USER_TABLE_SETTINGS_RESET]: userTableSettingsReset,

  [Types.USER_FETCHING_RESET]: userFetchingReset,
})
