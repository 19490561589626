import React, { useState, useEffect } from 'react'
import { Layout, Spin, message } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Switch, Route } from 'react-router-dom'

import AuthActions from '../Redux/AuthRedux'
import SettingsActions from '../Redux/SettingsRedux'
import InvestorSignUpLeadConfirm from '../Components/InvestorSignUp/InvestorSignUpLeadConfirm'
import InvestorSignUpCreateAccount from '../Components/InvestorSignUp/InvestorSignUpCreateAccount'
import InvestorSignUpOnboarding from '../Components/InvestorSignUp/InvestorSignUpOnboarding'
import InvestorSignUpStep1 from '../Components/InvestorSignUp/InvestorSignUpStep1'
import InvestorSignUpStep2 from '../Components/InvestorSignUp/InvestorSignUpStep2'
import InvestorSignUpProfileSetup from '../Components/InvestorSignUp/InvestorSignUpProfileSetup'
import InvestorSignUpPreferences from '../Components/InvestorSignUp/InvestorSignUpPreferences'
import InvestorSignUpProfileCompleted from '../Components/InvestorSignUp/InvestorSignUpProfileCompleted'
import errorHandler from '../Utils/errorHandler'
import _ from 'lodash'

function InvestorSignUpPage(props) {
  const { user, subRole, result, error, fetching, firmFiltersResult, saveResult, saveFetching, match, location, sectorFocus, geographyFocus, equityGovernanceList, investmentTypes, countries, euCountries, filters, getSectors, getContinents, getEuCountries } = props;
  const {step} = match.params;
  const {state} = location;

  const [previewData, setPreviewData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [firmFilters, setFirmFilters] = useState(null);
  const [matchingFilters, setMatchingFilters] = useState(null);
  const [firmOverview, setFirmOverview] = useState(null);

  const [hash, setHash] = useState(null);
  const [preview, setPreview] = useState(null);
  const [firm, setFirm] = useState(null);

  const history = useHistory();

  function changeStep(data, isComplete) {
    if (step === 'create-account') {
      setFirm({...firm, name: data.company_name});
      delete data.company_name;
      setPreviewData(data);
      props.checkEmail(data.email);
    } else if (step === 'onboarding') {
      history.push('/investor/sign-up/step/1');
    } else if (step === '1') {
      setMatchingFilters(_.cloneDeep(data));
      data.hash = hash;
      data.name = firm.name;
      props.preUpdateFirmFilters(firm.id, data);
    } else if (step === '2') {
      if (Object.keys(data).some(key => data[key])) {
        setFirmOverview(data);
      }
      history.push('/investor/sign-up/step/profile-setup');
    } else if (step === 'profile-setup' && isComplete) {
      props.signUpInvestor(userData, 'investor');
    } else if (step === 'profile-setup' && !isComplete) {
      history.push('/investor/sign-up/step/preferences');
    } else if (step === 'preferences') {
      setMatchingFilters(data);
      props.signUpInvestor(userData, 'investor');
    }
  }

  useEffect(()=>{
    if (!state) {
      history.push('/');
    } else {
      props.resetFilters();
      setHash(state?.hash);
      setPreview(state?.preview);
      setFirm(state?.firm);
      props.getEquityGovernance();
      props.getInvestmentTypes();
      props.getCountries();
      if (subRole !== 0) {
        props.getFirmFilters(state?.hash);
      }
    }
  }, [0]);

  useEffect(() => {
    if (error) {
      if (error.is_registered) {
        message.error(`User with this Email already exists`, 5);
        props.resetError();
      } else {
        errorHandler(error, props.resetError);
      }
    }
  }, [error]);

  useEffect(()=>{
    if (result) {
      setUserData(previewData);
      setPreviewData(null);
      if (subRole !== 0) {
        history.push('/investor/sign-up/step/preferences');
      } else {
        history.push('/investor/sign-up/step/onboarding');
      }
    }
  }, [result]);

  useEffect(()=>{
    if (firmFiltersResult) {
      setFirmFilters(firmFiltersResult);
      history.push('/investor/sign-up/step/2');
    }
  }, [firmFiltersResult]);

  useEffect(()=>{
    if (user) {
      if (subRole !== 0) {
        props.updateFilters(user.id, 'investor', matchingFilters);
        setMatchingFilters(null);
      } else if (firmOverview) {
        props.updateFirmFilters(firm.id, firmOverview);
        setFirmOverview(null);
      } else if (matchingFilters) {
        props.updateFilters(user.id, 'investor', matchingFilters);
        setMatchingFilters(null);
      } else {
        history.push('/investor/sign-up/step/profile-completed');
      }
    }
  }, [user]);

  useEffect(()=>{
    if (saveResult) {
      if (matchingFilters && subRole === 0) {
        const data = {...matchingFilters};
        delete data.name;
        props.updateFilters(user.id, 'investor', data);
        setMatchingFilters(null);
      } else {
        history.push('/investor/sign-up/step/profile-completed');
      }
    }
  }, [saveResult]);

  return (
    <Layout>
      <Spin size="large" spinning={fetching || saveFetching}>
        <Switch>
          <Route path="/investor/sign-up/step/lead-confirm" render={
            props => (
              <InvestorSignUpLeadConfirm
                firm={firm}
                fetching={fetching}
                onChangeStep={changeStep}
              />
            )}/>
          <Route path="/investor/sign-up/step/create-account" render={
            props => (
              <InvestorSignUpCreateAccount
                hash={hash}
                fetching={fetching}
                preview={preview}
                subRole={subRole}
                onChangeStep={changeStep}
              />
            )}/>
          <Route path="/investor/sign-up/step/onboarding" render={
            props => (
              <InvestorSignUpOnboarding
                onChangeStep={changeStep}
              />
            )}/>
          <Route path="/investor/sign-up/step/1" render={
            props => (
              <InvestorSignUpStep1
                sectorFocus={sectorFocus}
                geographyFocus={geographyFocus}
                equityGovernanceList={equityGovernanceList}
                investmentTypes={investmentTypes}
                countries={countries}
                euCountries={euCountries}
                onChangeStep={changeStep}
                getSectors={getSectors}
                getContinents={getContinents}
                getEuCountries={getEuCountries}
              />
            )}/>
          <Route path="/investor/sign-up/step/2" render={
            props => (
              <InvestorSignUpStep2
                onChangeStep={changeStep}
              />
            )}/>
          <Route path="/investor/sign-up/step/profile-setup" render={
            props => (
              <InvestorSignUpProfileSetup
                onChangeStep={changeStep}
              />
            )}/>
          <Route path="/investor/sign-up/step/preferences" render={
            props => (
              <InvestorSignUpPreferences
                previewData={filters ? filters : firmFilters}
                sectorFocus={sectorFocus}
                geographyFocus={geographyFocus}
                equityGovernanceList={equityGovernanceList}
                investmentTypes={investmentTypes}
                countries={countries}
                euCountries={euCountries}
                onChangeStep={changeStep}
                getSectors={getSectors}
                getContinents={getContinents}
                getEuCountries={getEuCountries}
              />
            )}/>
          <Route path="/investor/sign-up/step/profile-completed" render={
            props => (
              <InvestorSignUpProfileCompleted />
            )}/>
        </Switch>
      </Spin>
    </Layout>
  )
}


const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    subRole: state.auth.investorSubRole,
    result: state.auth.result,
    error: state.auth.error,
    fetching: state.auth.fetching,
    firmFiltersResult: state.auth.firmFiltersResult,
    filters: state.settings.filters,
    saveResult: state.settings.saveResult,
    saveFetching: state.settings.saveFetching,
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    equityGovernanceList: state.settings.equityGovernanceList,
    investmentTypes: state.settings.investmentTypes,
    countries: state.settings.countries,
    euCountries: state.settings.euCountries
  }
}

const mapDispatchToProps = (dispatch) => ({
  checkEmail: (email) => dispatch(AuthActions.checkEmailExistRequest(email)),
  resetError: () => dispatch(AuthActions.authErrorReset()),

  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: (filter) => dispatch(SettingsActions.geographyFocusRequest(filter)),
  getEquityGovernance: () => dispatch(SettingsActions.equityGovernanceRequest()),
  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),
  getFirmFilters: (hash) => dispatch(SettingsActions.investorFirmFiltersRequest(hash)),
  resetFilters: () => dispatch(SettingsActions.settingsReset()),

  signUpInvestor: (data, role) => dispatch(AuthActions.signUpRequest(data, role)),
  preUpdateFirmFilters: (firmId, data) => dispatch(AuthActions.firmPreFiltersRequest(firmId, data)),

  updateFilters: (investorId, role, data) => dispatch(SettingsActions.filtersSaveRequest(investorId, role, data)),
  updateFirmFilters: (firmId, data) => dispatch(SettingsActions.firmFiltersSaveRequest(firmId, data)),
  getCountries: () => dispatch(SettingsActions.countriesRequest()),
  getEuCountries: (filter) => dispatch(SettingsActions.euCountriesRequest(filter)),

})

export default connect(mapStateToProps, mapDispatchToProps)(InvestorSignUpPage)
