import React from 'react'
import { Layout, Spin, Space, Button } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import AuthActions from '../Redux/AuthRedux'
import SettingsActions from '../Redux/SettingsRedux'
import Images from '../Images'


function LogoutPage(props) {
  const history = useHistory();

  function handleNavigate(path) {
    history.push(path);
  }

  function onNavigate() {
    props.logout();
    props.resetTableSettings();
    handleNavigate('/login')
  }

  return (
    <Layout>
      <Spin size="large" spinning={false}>
        <div className="sign-up-step with-bg">
          <div className="with-bg-top">
            <img src={Images.logoWhite} alt="" />
          </div>
          <div className="modal-container" style={{ width: 360, padding: '32px 64px' }}>
            <Space style={{ marginBottom: 16 }}>
              <img src={Images.phoneOk} alt="" />
            </Space>
            <div className="locked-title">Log out</div>
            <div className="logout-text">Are you sure you want to log out?</div>
            <div className="logout-action">
              <Button type="primary white" className="btn-primary" style={{minWidth: 90}} onClick={()=>history.goBack()}>
                No
              </Button>
              <Button type="primary white" className="btn-primary"  style={{minWidth: 50}} onClick={onNavigate}>
                Yes
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Layout>
  )
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.logout()),
  resetTableSettings: () => dispatch(SettingsActions.tableSettingsReset()),
})

export default connect(null, mapDispatchToProps)(LogoutPage)
