import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Input, Button } from 'antd'
import { useHistory, Link } from 'react-router-dom'
import _ from 'lodash'

import DealSharesActions from '../../Redux/DealSharesRedux'
import Images from '../../Images'

const { TextArea } = Input;

function YourApplicationPage(props) {
  const { matchResult, dealShareUpdateResult, answerResult, location } = props;
  const { state } = location;

  const [data, setData] = useState(null);
  const [comment, setComment] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [dealData, setDealData] = useState(null);
  const [shareId, setShareId] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (!state) {
      history.push('/home/deals');
    } else {
      state.dealData && setDealData(state.dealData);
      state.answers && setAnswers(state.answers);
      state.comment && setComment(state.comment);
      state.shareId && setShareId(state.shareId);
      let newData;
      if (state.data) {
        newData = {
          ...state.data,
          active: true,
        };
      } else {
        const deal = _.cloneDeep(state.dealData);
        newData = {
          active: false,
          answers: deal?.questions?.map(el => ({
            question: el.id,
            answer: ''
          })),
          investor_comment: state.comment || ''
        };
        if (state.answers) {
          newData.answers.forEach((answer, i, arr) => {
            const item = state.answers.find(el => el.question_id === answer.question);
            if (item) {
              arr[i].answer = item.answer;
            }
          })
        }
      }
      setData(newData);
    }
  }, [0]);

  useEffect(() => {
    if (dealShareUpdateResult || answerResult) {
      props.getDealMatchInfo(shareId);
    }
  }, [dealShareUpdateResult, answerResult]);

  useEffect(() => {
    if (matchResult) {
      setDealData(matchResult.deal);
      setAnswers(matchResult.answers);
      setComment(matchResult.investor_comment);
    }
  }, [matchResult]);

  const changeValue = (index, value, questionId) => {
    const newData = {...data};
    newData.answers[index].answer = value;
    setData(newData);
    if (state?.dealStatus === 'reconfirm' && value) {
      const item = state?.answers?.find(el => el.question_id === questionId);
      const data = {
        match: shareId,
        question: questionId,
        answer: value
      };
      if (item) {
        props.updateMatchAnswer(item.id, data);
      } else {
        props.createMatchAnswer(data);
      }
    }
  };

  const changeComment = value => {
    const newData = {...data};
    newData.investor_comment = value;
    setData(newData);
    if (state?.dealStatus === 'reconfirm' && value) {
      props.updateDealShareData(shareId, {investor_comment: value});
    }
  };

  const checkRequired = () => {
    setData({...data, active: true});
    let check = data.answers?.every(el => el.answer);
    if (check) {
      history.push({
        pathname: '/home/review-profile',
        state: {
          shareId,
          dealStatus: state?.dealStatus,
          dealData,
          answers,
          comment,
          tab: state?.tab,
          data: {
            answers: data.answers,
            investor_comment: data.investor_comment
          }
        }
      });
    }
  }

  const setBackPath = () => {
    let path;
    if (state) {
      if (state.shareId) {
        path = `/home/deal-share-info/${state.shareId}/${state.tab}`;
      } else {
        path = `/home/deal-share-info-all/${state.dealData?.id}/${state.tab}`;
      }
    }
    return path;
  }

  const generateAnswer = (index, questionId) => {
    if (state?.data?.answers) {
      return state.data.answers[index]?.answer;
    } else if (state?.dealStatus === 'reconfirm') {
      const item = answers?.find(el => el.question_id === questionId);
      if (item) {
        return item.answer;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  const generateComment = () => {
    if (state?.data) {
      return state.data.investor_comment;
    } else if (state?.dealStatus === 'reconfirm') {
      return state?.comment;
    } else {
      return '';
    }
  }

  return (
    <div className="sign-up-step filters-edit wo-padding" style={{ padding: '133px 80px 32px' }}>
      <div className="absolute-header">
        <div className="logo-block">
          <img src={Images.logoWhite} alt="" />
        </div>
      </div>
      <div className="return-block" style={{ padding: '16px 0' }}>
        <Link to={setBackPath()}>
          <img src={Images.arrowLeft} alt="" />
          <span>Go back</span>
        </Link>
      </div>

      <div className="firm-profile-block match">
        <div className="section-title" style={{ alignSelf: 'self-start', marginTop: 0 }}>Your Application</div>

        <div className="firm-profile-section-middle">
          <div className="firm-profile-title your-app">
            <span>Preferred Investor's Criteria</span>
          </div>
          <div className="firm-profile-subtitle">The Senior Advisor outlines in this section any specific preference in the investor's sought</div>
          <div className="firm-profile-area">
            <TextArea
              value={state?.dealData?.advisor_criteria}
              autoSize={true}
              disabled={true}
            />
          </div>
        </div>

        <div className="firm-profile-section-middle">
          <div className="firm-profile-title your-app">
            <span>Screening questionnaire</span>
          </div>
          <div className="firm-profile-subtitle" style={{ marginBottom: 24 }}>The Senior Advisor has inserted some questions which you have to answer in order to express interest in this deal.</div>
          {
            dealData?.questions?.map((el, i) => (
              <div key={el.id}>
                <div className="firm-profile-title match">
                  <span>{el.text}</span>
                </div>

                <div className={`firm-profile-area${(data && data.active && data.answers && !data.answers[i]?.answer) ? ' error-block' : ''}`}>
                  <TextArea
                    defaultValue={generateAnswer(i, el.id)}
                    autoSize={true}
                    onBlur={e => changeValue(i, e.target.value, el.id)}
                  />
                </div>
              </div>
            ))
          }
        </div>

        <div className="firm-profile-section-middle">
          <div className="firm-profile-title your-app">
            <span>Additional Comments and Notes</span>
          </div>
          <div className="firm-profile-subtitle">Anything else you want to add to support your expression of interest to this deal</div>
          <div className="firm-profile-area" style={{ marginBottom: 0 }}>
            <TextArea
              defaultValue={generateComment()}
              autoSize={true}
              onBlur={e => changeComment(e.target.value)}
            />
          </div>
        </div>

        <div className="btn-footer" style={{ marginTop: 58 }}>
          <Button type="primary" className="btn-primary" style={{ width: 217 }} onClick={checkRequired}>
            Continue
          </Button>
        </div>
      </div>

    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    matchResult: state.dealShares.matchResult,
    dealShareUpdateResult: state.dealShares.dealShareUpdateResult,
    answerResult: state.dealShares.answerResult,

  }
}

const mapDispatchToProps = (dispatch) => ({
  updateDealShareData: (shareId, data) => dispatch(DealSharesActions.dealShareUpdateRequest(shareId, data)),
  createMatchAnswer: (data) => dispatch(DealSharesActions.matchAnswerSaveRequest(data)),
  updateMatchAnswer: (answerId, data) => dispatch(DealSharesActions.matchAnswerUpdateRequest(answerId, data)),
  getDealMatchInfo: (shareId) => dispatch(DealSharesActions.dealShareMatchRequest(shareId)),

})

export default connect(mapStateToProps, mapDispatchToProps)(YourApplicationPage)
