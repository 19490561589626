import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, message, Spin, Input } from 'antd'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

const { TextArea } = Input

function TerminateEngagementInvestorPopupPage(props) {
	const { result, fetching, match, isAdmin, location } = props;
	const { dashboardId } = match.params;
	const { state } = location;

	const [value, setValue] = useState(null);
	const [active, setActive] = useState(false);

	const history = useHistory();

	useEffect(() => {
    props.dealDashboardSyncToggle(false);
    return function cleanup() {
      if (isAdmin) {
        props.dealDashboardSyncToggle(true);
      }
    }
  }, [0]);

	useEffect(() => {
		if (result) {
			if (isAdmin) {
				props.getDealDashboardInfo(dashboardId);
				history.push(`/home/match-company/${dashboardId}`);
			} else {
				props.resetDealDashboard();
				history.push(`/home/deals`);
			}
		}
	}, [result]);

	const changeValue = value => {
		if (!active) {
			setActive(true);
		}
		setValue(value);
	}

	const checkRequired = () => {
		if (state?.isHistory) {
			history.push(`/deal-dashboard/overview/${dashboardId}`);
			return;
		}
		if (!value) {
			message.error('Indicate the reason', 3);
			if (!active) {
				setActive(true);
			}
		} else {
			const data = {
				reason: value
			};
			props.terminateDealByInvestor(dashboardId, data);
		}
	}

	return (
		<PopupWrapper
			width={480}
			path={isAdmin ?
				`/home/match-company/${dashboardId}`
				:
				{
          pathname: `/deal-dashboard/overview/${dashboardId}`,
          state: {
            isToggle: true
          }
        }

			}
		>
			<Spin spinning={fetching} size="large">
				<div className='dialog-content' style={{ padding: 20, paddingTop: 30, paddingBottom: 30 }}>
					<img src={Images.phoneCancel} alt='' />
					<div className='dialog-title welcome' style={{ margin: 8 }}>
						Terminate Engagement
				</div>
					<div className='deal-text engagment' style={{ marginTop: 6 }}>
						We are sorry to hear that you wish to <br /> terminate the engagement. Please provide <br /> the reason for terminating the engagement:
				</div>
					<TextArea
						disabled={state?.isHistory}
            defaultValue={state?.add_info?.reason}
						style={{ margin: 20, width: 402 }}
            className={(active && !value) && 'error-input'}
            rows={4}
            onBlur={e => changeValue(e.target.value)}
					/>
					<div className="deal-text sm" style={{width: '90%'}}>Please review IDN's Platform Agreement to remember your<br />rights and obligations in the context of the termination<br />of this engagement</div>
					<div className='btn-footer'>
						<Button
							type='primary white'
							className='btn-primary welcome'
							style={{ width: 144 }}
							onClick={checkRequired}
						>
							{state?.isHistory ? 'Go back' : 'Confirm'}
					</Button>
					</div>
				</div>
			</Spin>
		</PopupWrapper>
	)
}

const mapStateToProps = (state) => {
	return {
		isAdmin: state.auth.isAdmin,
		result: state.dealDashboard.result,
		fetching: state.dealDashboard.fetching
	}
}

const mapDispatchToProps = (dispatch) => ({
	getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
	terminateDealByInvestor: (dashboardId, data) => dispatch(DealDashboardActions.investorTerminateDealRequest(dashboardId, data)),
	dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
	resetDealDashboard: () => dispatch(DealDashboardActions.dealDashboardReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TerminateEngagementInvestorPopupPage)
