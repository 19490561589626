import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import configureStore from './CreateStore'
import rootSaga from '../Sagas/'
import { createBrowserHistory  } from "history";
import ReduxPersist from '../Config/ReduxPersist'
import { connectRouter } from 'connected-react-router'
export const history = createBrowserHistory()

/* ------------- Assemble The Reducers ------------- */

export const reducers = combineReducers({
  auth: require('./AuthRedux').reducer,
  startup: require('./StartupRedux').reducer,
  router: connectRouter(history),
  errors: require('./ErrorsRedux').reducer,
  settings: require('./SettingsRedux').reducer,
  experience: require('./ExperienceRedux').reducer,
  users: require('./UsersRedux').reducer,
  firms: require('./FirmsRedux').reducer,
  mailTemplates: require('./MailTemplatesRedux').reducer,
  deals: require('./DealsRedux').reducer,
  dealShares: require('./DealSharesRedux').reducer,
  dealDashboard: require('./DealDashboardRedux').reducer,
  chat: require('./ChatRedux').reducer,
  notifications: require('./NotificationsRedux').reducer,
  expertConsult: require('./ExpertConsultRedux').reducer,
  mandates: require('./MandatesRedux').reducer,
  mandateShares: require('./MandateSharesRedux').reducer,
})

export default (preloadedState) => {
  let finalReducers = reducers

  if (ReduxPersist.active) {
    const persistConfig = ReduxPersist.storeConfig
    finalReducers = persistReducer(persistConfig, reducers)
  }

  let { store, sagasManager, sagaMiddleware } = configureStore(finalReducers, rootSaga, history, preloadedState)

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('./').reducers
      store.replaceReducer(nextRootReducer)

      const newYieldedSagas = require('../Sagas').default
      sagasManager.cancel()
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware.run(newYieldedSagas)
      })
    })
  }

  return store
}
