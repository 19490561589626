import React, { useEffect } from 'react'
import { Layout } from 'antd'

import Images from '../Images'

const { Sider } = Layout;

const endedStatuses = [5, 6, 7, 10];

function SidebarDealDashboard(props) {
  const { path, onNavigate, role, dashboardId, firmId, advisorId, dealId, statusDD, ddNotifications, backPath } = props;

  return (
    <Sider className="sidebar" width="auto">
      <div className="logo-section">
        <img src={Images.logo} alt="" />
      </div>
      <div className="nav-content">
        {
          role === 0 &&
          <>
            <div className="nav-section">
              <div className="nav-title">DEAL DASHBOARD</div>
              <div className="nav-divider"></div>
              <div
                className={'nav-item' + ((path && path.includes('/overview')) ? ' active' : '')}
                onClick={() => onNavigate && onNavigate(`/deal-dashboard/overview/${dashboardId}`)}
              >
                <div>
                  <img src={Images.bars} alt="" />
                </div>
                <span>Overview</span>
                {
                  ddNotifications?.overview &&
                  <img src={Images.notification} alt="" />
                }
              </div>
              {
                !endedStatuses.includes(statusDD) &&
                <div
                  className={'nav-item' + ((path && path.includes('/chat')) ? ' active' : '')}
                  onClick={() => onNavigate && onNavigate(`/deal-dashboard/chat/${dashboardId}`)}
                >
                  <div>
                    <img src={Images.comments} alt="" />
                  </div>
                  <span>Deal Chat</span>
                  {
                    ddNotifications?.deal_chat &&
                    <img src={Images.notification} alt="" />
                  }
                </div>
              }
            </div>
            <div className="nav-section">
              <div className="nav-title">DEAL INFORMATION</div>
              <div className="nav-divider"></div>
              <div
                className={'nav-item' + ((path && path.includes('/investor-profile')) ? ' active' : '')}
                onClick={() => onNavigate && onNavigate(`/deal-dashboard/investor-profile/${firmId}`)}
              >
                <div>
                  <img src={Images.user} alt="" />
                </div>
                <span>Investor’s Profile</span>
              </div>
              <div
                className={'nav-item' + ((path && path.includes('/deal-details')) ? ' active' : '')}
                onClick={() => onNavigate && onNavigate(`/deal-dashboard/deal-details/${dealId}`)}
              >
                <div>
                  <img src={Images.file} alt="" />
                </div>
                <span>Deal Details</span>
              </div>
            </div>
          </>
        }
        {
          role === 2 &&
          <>
            <div className="nav-section">
              <div className="nav-title">DEAL DASHBOARD</div>
              <div className="nav-divider"></div>
              <div
                className={'nav-item' + ((path && path.includes('/overview')) ? ' active' : '')}
                onClick={() => onNavigate && onNavigate(`/deal-dashboard/overview/${dashboardId}`)}
              >
                <div>
                  <img src={Images.bars} alt="" />
                </div>
                <span>Overview</span>
                {
                  ddNotifications?.overview &&
                  <img src={Images.notification} alt="" />
                }
              </div>
              {
                !endedStatuses.includes(statusDD) &&
                  <div
                    className={'nav-item' + ((path && path.includes('/chat')) ? ' active' : '')}
                    onClick={() => onNavigate && onNavigate(`/deal-dashboard/chat/${dashboardId}`)}
                  >
                    <div>
                      <img src={Images.comments} alt="" />
                    </div>
                    <span>Deal Chat</span>
                    {
                      ddNotifications?.deal_chat &&
                      <img src={Images.notification} alt="" />
                    }
                  </div>
                }
            </div>
            <div className="nav-section">
              <div className="nav-title">DEAL INFORMATION</div>
              <div className="nav-divider"></div>
              <div
                className={'nav-item' + ((path && path.includes('/advisor-profile')) ? ' active' : '')}
                onClick={() => onNavigate && onNavigate(`/deal-dashboard/advisor-profile/${advisorId}`)}
              >
                <div>
                  <img src={Images.user} alt="" />
                </div>
                <span>Advisor’s Profile</span>
              </div>
              <div
                className={'nav-item' + ((path && path.includes('/deal-details')) ? ' active' : '')}
                onClick={() => onNavigate && onNavigate(`/deal-dashboard/deal-details/${dealId}`)}
              >
                <div>
                  <img src={Images.file} alt="" />
                </div>
                <span>Deal Details</span>
              </div>
            </div>
          </>
        }

        <div className="nav-section">
          <div
            className='nav-item'
            onClick={() => onNavigate && onNavigate(backPath, true)}
          >
            <div>
              <img src={Images.signOut} alt="" />
            </div>
            <span className="return-dashboard">Return to Main Dashboard</span>
          </div>
        </div>
      </div>
    </Sider>
  )
}

export default SidebarDealDashboard
