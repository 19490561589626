import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function AcceptUltimatumPopupPage(props) {
  const { result, fetching, match, isAdmin } = props;
  const { dashboardId } = match.params;


  const history = useHistory();

  useEffect(() => {
    props.dealDashboardSyncToggle(false);
    return function cleanup() {
      props.dealDashboardSyncToggle(true);
    }
  }, [0]);

  useEffect(() => {
    if (result) {
      props.getDealDashboardInfo(dashboardId);
      isAdmin ?
        history.push(`/home/match-company/${dashboardId}`)
        :
        history.push(`/deal-dashboard/overview/${dashboardId}`);
    }
  }, [result]);

  return (
    <PopupWrapper
      width={420}
      path={isAdmin ?
        `/home/match-company/${dashboardId}`
        :
        `/deal-dashboard/overview/${dashboardId}`
      }
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content" style={{ padding: 40, paddingTop: 20, paddingBottom: 30 }}>
          <img src={Images.phoneclear} alt="" />
          <div className="dialog-title welcome" style={{ margin: 6 }}>Accept Ultimatum</div>
          <div className="deal-text engagment" style={{ marginTop: 14 }}>By clicking “Confirm”, you accept the <br /> ultimatum and continue the <br /> engagement.</div>
          <br />
          <br />
          <div className="deal-text sm" style={{ width: '90%' }}>Please review IDN's Platform Agreement to remember your<br />rights and obligations in the context of this engagement</div>
          <div className="btn-footer">
            <Button type="primary white"
              className="btn-primary welcome"
              style={{ width: 144 }}
              onClick={() => props.acceptUltimatumByInvestor(dashboardId)}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    result: state.dealDashboard.result,
    fetching: state.dealDashboard.fetching
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
  acceptUltimatumByInvestor: (dashboardId) => dispatch(DealDashboardActions.investorAcceptUltimatumRequest(dashboardId)),
  dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AcceptUltimatumPopupPage)
