import React, { useState, useEffect, Fragment } from 'react'
import { Button, Input, Space, Row, Col } from 'antd'
import { useHistory, Link } from 'react-router-dom'
import _ from 'lodash'
import Images from '../../Images'
const { TextArea } = Input;
const dealSizeRange = [
  '£1-£50ml',
  '£50-£550ml',
  '£550ml+'
];
function MandateSharingReview(props) {
  const { mandateId, mandateData, investmentTypes, dealSizeList, isSharing, styleObj, own, returnLink } = props;

  const [data, setData] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (mandateData) {
      const mandate = _.cloneDeep(mandateData);
      const newData = {
        background: mandate.background,
        addFinReq: mandate.additional_financial_requirements,
        addReq: mandate.additional_requirements,
        firm_profile: mandate.firm_profile,
        sector: mandate.industry_focus_sub_sectors[0]?.name,
        subSectors: mandate.industry_focus_sub_sectors[0]?.children.map(el => el.name),
        addSpec: mandate.industry_focus_sub_sectors[0]?.additional_specification,
        regions: mandate.geography.map(el => el.regions).flat(),
        countries: mandate.geography.map(el => el.regions).flat().map(el => el.countries).flat(),
        dealSize: mandate.deal_size_range,
        equity_ticket_from: mandate.investment_focus?.equity_ticket_from,
        equity_ticket_to: mandate.investment_focus?.equity_ticket_to,
        equity_stake_from: mandate.investment_focus?.equity_stake_from,
        equity_stake_to: mandate.investment_focus?.equity_stake_to,
        investment_type: mandate.investment_focus?.investment_type,
        comment: mandate.investment_focus?.comment,
      }
      setData(newData);
    }
  }, [mandateData]);

  const extractValues = (data, values) => {
    if (!data || !values) return [];

    return values.map(value => ({
      id: value,
      name: data?.find(el => el.id === value).name,
      span: dealSizeRange[value-1]
    }))
  }
  return (
    <>
      <div className="review-block" style={styleObj}>
        <div className="review-subblock">
          <div className="overview-title mandate">
            <span><img src={Images.company} alt="" /></span>
            <div>Background Information</div>
          </div>
          <div className="area-text">
            <TextArea
              value={data?.background}
              disabled={true}
              autoSize={true}
            />
          </div>
        </div>
        <div className="review-subblock">
          <span><img src={Images.puzzle} alt="" /></span>
          <div className="sector-title">
            <div className="sector-title-name mandate">Sector:</div>
            <span className="sector-title-subname">{data?.sector}</span>
          </div>
          <div className="sector-title mandate">
            <div className="sector-title-name mandate margin-left">Sub-sector (s):</div>
            <span className="sector-title-subname margin-left">{data?.subSectors.join(', ')}</span>
            <div className="add-spec-block mandate">
              <div className="sector-add-spec">Additional specifications</div>
              <div className="sector-add-spec-value">{data?.addSpec}</div>
            </div>
          </div>
        </div>
        <div className="review-subblock">
          <span><img src={Images.globe} alt="" /></span>
          <div className="sector-title">
            <div className="sector-title-name mandate">Geography:</div>
            <span className="sector-title-subname">{data?.regions.map(el => el.name).join(', ')}</span>
          </div>
          <div className="sector-title mandate">
            <div className="sector-title-name mandate margin-left">Countries:</div>
              <span className="sector-title-subname margin-left-2">{data?.countries.map(el => el.name).join(', ')}</span>
          </div>
        </div>
        <div className="review-subblock">
          <span><img src={Images.coins} alt="" /></span>
          <div className="sector-title">
            <div>Financial requirements</div>
          </div>
          <div className="fin-info-block">
            <div>
              <div className="sector-subtitle">
                <div className="subtitle-range-name">Deal size range:</div>
                <div>
                  {data?.dealSize && extractValues(dealSizeList, data.dealSize).map(el => (
                    <div key={el.name}>
                      <span className="md-text">{el.name}&nbsp;</span>
                      <span className="blue">({el.span})</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <div className="sector-subtitle">
                <div className="subtitle-range-name">Type of investment:</div>
                <div >
                <span className="md-text">{data?.investment_type && extractValues(investmentTypes, data.investment_type.map(el => el.id)).filter(el => el.id !== 3).map(el => el.name).join(', ')}</span>
                {
                    data?.comment &&
                      data?.investment_type?.length > 1 ?
                        <span className="md-text">{`, ${data?.comment}`}</span>
                      :
                        <span className="md-text">{data?.comment}</span>
                  }
                  </div>
              </div>
            </div>
          </div>
          <div className="fin-info-block">
            <div>
              <div className="sector-subtitle">
                <div className="subtitle-range-name">Equity ticket range (£):</div>
                {
                  data?.equity_ticket_from == null ?
                    <div className="subtitle-na" style={{textAlign: 'left'}}>N/A</div>
                  :
                    <>
                      <div className="subtitle-range-value">{data?.equity_ticket_from}</div>
                      <div className="subtitle-range-value">{data?.equity_ticket_to}</div>
                    </>
                }
              </div>
            </div>
            <div>
              <div className="sector-subtitle">
                <div className="subtitle-range-name">Equity stake range (%):</div>
                {
                  data?.equity_stake_from == null ?
                    <div className="subtitle-na" style={{textAlign: 'left'}}>N/A</div>
                  :
                    <>
                      <div className="subtitle-range-value">{data?.equity_stake_from}</div>
                      <div className="subtitle-range-value">{data?.equity_stake_to}</div>
                    </>
                }
              </div>
            </div>
          </div>
          <div className="sector-subtitle">
            <div className="subtitle-range-name">Additional Financial information</div>
          </div>
          <div className="area-text" style={{marginTop: 0}}>
            <TextArea
              value={data?.addFinReq}
              disabled={true}
              autoSize={true}
            />
          </div>
        </div>
        <div className="review-subblock">
          <div className="overview-title mandate">
            <span><img src={Images.company} alt="" /></span>
            <div>Additional requirements</div>
          </div>
          <div className="area-text">
            <TextArea
              value={data?.addReq}
              disabled={true}
              autoSize={true}
            />
          </div>
        </div>
        <div className="review-subblock">
          <div className="overview-title mandate">
            <span><img src={Images.pen} alt="" /></span>
            <div>{`${own ? "Your firm's" : "Firm"} profile`}</div>
          </div>
          <div className="area-text">
            <TextArea
              value={data?.firm_profile}
              disabled={true}
              autoSize={true}
            />
          </div>
        </div>
      </div>
      {
        isSharing &&
          <>
            <div className="btn-footer invite" style={{ marginTop: 24 }}>
              <Button type="primary white" className="btn-primary" style={{ width: 217, margin: '0 32px' }} onClick={() => history.push({
                pathname: `/home/mandate-edit/${mandateId}`,
                state: {
                  backPath: `/home/mandate-sharing/step/review`,
                  mandateId
                }
              })}>
                Edit Mandate
                </Button>
              <Button type="primary" className="btn-primary" style={{ width: 217, margin: '0 32px' }} onClick={props.onChangeStep}>
                Continue
                </Button>
            </div>
            <Space className="return-link" style={{ marginTop: 32 }}>
              <Link to={returnLink}>
                <img src={Images.arrowLeft} alt="" />
                <span>Return to main dashboard</span>
              </Link>
            </Space>
          </>
      }
    </>
  )
}
export default MandateSharingReview
