import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {Button} from 'antd'
import {useHistory} from 'react-router-dom'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import DealSharesActions from '../../../Redux/DealSharesRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function WelcomeDDInvestorPopupPage(props) {
	const { match, location } = props;
	const {dashboardId} = match.params;
	const {state} = location;

	const history = useHistory()

	useEffect(() => {
    props.dealDashboardSyncToggle(false);
    return function cleanup() {
			props.updateSeenByInvestor(state);
      props.getDealDashboardInfo(dashboardId);
      props.dealDashboardSyncToggle(true);
    }
  }, [0]);

	return (
		<PopupWrapper
			width={420}
			path={`/deal-dashboard/overview/${dashboardId}`}
		>
			<div className='dialog-content' style={{padding: 40, paddingTop: 20}}>
				<img src={Images.phoneOk} alt='' />
				<div className='dialog-title welcome' style={{marginTop: 10}}>
					Welcome to your <br/> Deal Dashboard
				</div>
				<div className='deal-text'>You are now directly connected with the Senior Advisors on the deal opportunity <br/> you applied for.</div>
				<br />
				<div className='deal-subtext' style={{width: '85%'}}>
        Via your Deal Dashboard, you can trigger <br/> binding actions to progress on the deal <br/> opportunity.
				</div>
				<div className='btn-footer'>
					<Button
						type='primary white'
						className='btn-primary welcome'
						style={{width: 144}}
						onClick={() => history.push(`/deal-dashboard/overview/${dashboardId}`)}
					>
						Continue
					</Button>
				</div>
			</div>
		</PopupWrapper>
	)
}

const mapDispatchToProps = (dispatch) => ({
	getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
  updateSeenByInvestor: (shareId) => dispatch(DealSharesActions.seenByInvestorRequest(shareId)),
	dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(null, mapDispatchToProps)(WelcomeDDInvestorPopupPage)
