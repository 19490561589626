import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Tooltip, Button } from 'antd'
import moment from 'moment'

import PaginationItemRender from '../PaginationItemRender'
import CustomIcon from '../../Icon'
import Images from '../../Images'

import './Table.less'

function DealMatchesTable(props){
  const {
    page,
    pageSize,
    data,
    fetching,
  } = props;

  const [dataSource, setDataSource] = useState(null);

  const history = useHistory();

  useEffect(() => {
    let arr = (data && data.results) ? data.results.asMutable({deep:true}) : [];
    arr = arr.map(el => ({
      shareId: el.id,
      invest_company: el.invest_company,
      statusName: el.advisor_status_obj?.name,
      matched_on: el.matched,
      status: el.advisor_status_obj?.id,
      isNotification: el.advisor_notification,
      dashboardId: el.dashboard,
      dealId: el.deal
    }))
    setDataSource(arr);
  }, [data]);

  function getColumns() {
    return [
      {
        title: 'Investment Firm',
        dataIndex: 'invest_company',
        key: 'invest_company',
      },
      {
        title: 'Status',
        dataIndex: 'statusName',
        key: 'statusName',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <div className={`deal-status${record.isNotification ? ' orange' : ''}`}>{text}</div>
          )
        },
      },
      {
        title: 'Matched on',
        dataIndex: 'matched_on',
        key: 'matched_on',
        className: 'cell-center',
        render: (text, record) => {
          return (
              <span>{text && moment(text).format('DD/MM/YYYY')}</span>
          )
        },
      },
      {
        title: '',
        dataIndex: 'status',
        key: 'status',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <div className="deal-action">
              {
                record.isNotification &&
                  <img src={Images.notification} alt="" />
              }
              <Button type="primary"
                className="btn-primary"
                disabled={text === 're-confirming'}
                onClick={()=>generateBtnAction(record)}
              >
                {generateBtnText(record)}
              </Button>
            </div>
          )
        },
      }
    ];
  }

  function handleTableChange(pagination) {
    props.onChangePage(pagination.current, pagination.pageSize)
  }

  const totalCount = data ? data.count : 0;

  const generateBtnText = (record) => {
    if (record.dashboardId) {
      return 'Go to dashboard';
    } else {
      return 'View'
    }
  }

  const generateBtnAction = record => {
    if (record.isNotification) {
      props.deleteNotification(record.shareId);
    } else {
      if (record.dashboardId) {
        history.push({
          pathname: `/deal-dashboard/overview/${record.dashboardId}`,
          state: {backPath: `/home/deal/${record.dealId}/matches`}
        });
      } else {
        history.push(`/home/deal-investor-firm/${record.shareId}`);
      }
    }
  }

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount, showSizeChanger: true}}
      className="deal-table no-vw"
      rowKey={row => row.shareId}
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
      showSorterTooltip={false}
    />
   )


}

export default DealMatchesTable
