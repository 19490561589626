import React, { useState, useEffect } from 'react'
import { Button, message, Spin } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

import SettingsActions from '../../Redux/SettingsRedux'
import DealsActions from '../../Redux/DealsRedux'

import MandateDealSharingStep1 from '../../Components/MandateDealSharing/MandateDealSharingStart'
import DealSharingStep2 from '../../Components/DealSharing/DealSharingStep2'
import DealSharingStep3 from '../../Components/DealSharing/DealSharingStep3'
import Images from '../../Images'


function MandateDealSharingEditPage(props) {
  const { deal, match, location, sectorFocus, geographyFocus, investmentTypes, dealGroupDataResult, isAdmin, fetching, equityGovernanceList } = props;
  const { dealId } = match.params;
  const { state } = location;

  const [dealData, setDealData] = useState(null);
  // const [data, setData] = useState(null);
  const [checkRequired, setCheckRequired] = useState(false);
  const [backPath, setBackPath] = useState('');
  const [status, setStatus] = useState(null);

  const [step1Data, setStep1Data] = useState(null);
  const [step2Data, setStep2Data] = useState(null);
  const [step3Data, setStep3Data] = useState(null);

  const history = useHistory();

  useEffect(() => {
    props.getSectors();
    props.getContinents({ regions: true });
    props.getInvestmentTypes();
    props.getEquityGovernance();
  }, [0]);

  useEffect(() => {
    if (dealId) {
      props.getDeal(dealId);
    }
  }, [dealId]);

  useEffect(() => {
    if (deal) {
      setDealData(deal);
      setStatus(deal.status_obj?.id)
      if (deal.status_obj?.id === 'm_deal_in_progress') {
        setBackPath({
          pathname: '/home/mandate-deal-sharing/step/review',
          state: {
            dealId: deal.id,
            mandateId: deal.mandate?.id
          }
        });
      } else {
        isAdmin ?
          setBackPath(`/home/deal-details/${deal.id}`)
          :
          setBackPath(`/home/mandate-deal/${deal.id}`);
      }
    }
  }, [deal]);

  useEffect(() => {
    if (step1Data && step2Data && step3Data) {
      const totalData = {
        ...step1Data,
        investment_overview: step2Data,
        advisor_role: step3Data,
      }
      props.saveDealGroupData(dealId, totalData);
    }
  }, [step1Data, step2Data, step3Data]);

  // useEffect(() => {
  //   if (data) {
  //     props.saveDealGroupData(dealId, data);
  //   }
  // }, [data]);

  useEffect(() => {
    if (dealGroupDataResult) {
      props.getDeal(dealId);
      if (state) {
        history.push({
          pathname: state.backPath,
        });
      }
    }
  }, [dealGroupDataResult]);

  // const changeStep = (data, field) => {
  //   if (!data) {
  //     message.error('All fields must be filled in to proceed', 3);
  //   }
  //   setData(data);
  // }
  const changeStep = (data, field) => {
    if (!data) {
      message.error('All fields must be filled in to proceed', 3);
    }
    switch(field) {
      case 'matching_criteria': {
        setStep1Data(data);
        break;
      }
      case 'investment_overview': {
        setStep2Data(data);
        break;
      }
      case 'advisor_role': {
        setStep3Data(data);
        break;
      }
      default: break;
    }
  }

  const checkRequiredFields = () => {
    setCheckRequired(true);
    setTimeout(() => {
      setCheckRequired(false);
    })
  }

  return (
    <div className="sign-up-step white filters-edit" >
      <div className="absolute-header">
        <div className="flex-center-vertical">
          <div className="logo-block">
            <img src={Images.logoWhite} alt="" />
          </div>
          <div className="title-block bottom">{dealId}</div>
        </div>
      </div>
      <Spin size="large" spinning={fetching}>
        {
          backPath &&
          <div className="return-block" style={{ padding: '24px 0' }}>
            <Link to={backPath}>
              <img src={Images.arrowLeft} alt="" />
              <span>Back</span>
            </Link>
          </div>
        }
        <div className="section-title" style={{ alignSelf: 'self-start', marginTop: 0 }}>EDIT THE DEAL</div>
        <MandateDealSharingStep1
          checkRequired={checkRequired}
          dealData={dealData}
          sectorFocus={sectorFocus}
          geographyFocus={geographyFocus}
          investmentTypes={investmentTypes}
          setContinents={dealData?.mandate?.geography?.map(el => el.name)}
          setRegions={dealData?.mandate?.geography?.map(el => el.regions).flat().map(el => el.name)}
          setCountries={dealData?.mandate?.geography?.map(el => el.regions).flat().map(el => el.countries).flat().map(el => el.id)}
          subsectors={dealData?.mandate?.industry_focus_sub_sectors[0]}
          equityGovernanceList={equityGovernanceList}
          onChangeStep={changeStep}
          isAdmin={isAdmin}
        />
        <DealSharingStep2
          checkRequired={checkRequired}
          dealData={dealData}
          onChangeStep={changeStep}
          isAdmin={isAdmin}
        />
        <DealSharingStep3
          checkRequired={checkRequired}
          dealData={dealData}
          onChangeStep={changeStep}
        />
        <div className="btn-footer invite" style={{ marginTop: 58 }}>
          <Button type="primary" className="btn-primary" style={{ width: 217, margin: '0 32px' }} onClick={checkRequiredFields}>
            Save
          </Button>
        </div>
      </Spin>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    deal: state.deals.deal,
    dealGroupDataResult: state.deals.dealGroupDataResult,
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    investmentTypes: state.settings.investmentTypes,
    isAdmin: state.auth.isAdmin,
    fetching: state.deals.saveFetching,
    role: state.auth.userInfo.role_obj.id,
    equityGovernanceList: state.settings.equityGovernanceList,
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveDealGroupData: (dealId, data) => dispatch(DealsActions.dealGroupDataSaveRequest(dealId, data)),
  getDeal: (id) => dispatch(DealsActions.dealRequest(id)),
  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: (filter) => dispatch(SettingsActions.geographyFocusRequest(filter)),
  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),
  getEquityGovernance: () => dispatch(SettingsActions.equityGovernanceRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MandateDealSharingEditPage)
