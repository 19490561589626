import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Input, InputNumber } from 'antd'

import ExperienceActions from '../../Redux/ExperienceRedux'

const { TextArea } = Input;

function AdvisorProfilePage(props) {
  const { list, match } = props;
  const { advisorId } = match.params;

  const [data, setData] = useState(null);

  useEffect(() => {
    props.getExperience(advisorId);
  }, [0]);

  useEffect(() => {
    if (list) {
      let newData = {};
      Object.keys(list).forEach(key => {
        newData[key] = {
          value: list[key],
          active: false,
        }
      });
      newData.jobs = list.jobs;
      setData(newData);
    }
  }, [list]);

  return (
    <div className="main-container">
      <div className="section-title">Advisor's Profile</div>
      <div className="section-divider">&nbsp;</div>
      {
        data &&
        <div className="experience-block">
          <div className="experience-left">
            <div className="experience-title">
              <span>Experience - Summary</span>
            </div>
            <TextArea
              defaultValue={data.executive_summary.value}
              autoSize={true}
              maxLength={5000}
              disabled={true}
            />
            <div className="experience-title">
              <span>Experience - Deal origination</span>
            </div>
            <TextArea
              defaultValue={data.deal_origination_experience.value}
              autoSize={true}
              maxLength={5000}
              disabled={true}
            />
            <div className="experience-title">
              <span>Experience - Working with Private Equity</span>
            </div>
            <TextArea
              defaultValue={data.private_equity_experience.value}
              autoSize={true}
              maxLength={5000}
              disabled={true}
            />
          </div>
          <div className="experience-right">
            <div className="day-rate">
              <div className="experience-title">
                <span>Day rate</span>
              </div>
              <div className="flex-center-vertical">
                <InputNumber
                  defaultValue={data.day_rate_for_advisory_work.value}
                  min={0}
                  max={99999}
                  parser={value => Number(value).toFixed()}
                  disabled={true}
                />
                <span className="rate-currency">[in £]</span>
              </div>
            </div>
            <div className="not-active">This is your benchmark day rate (in Pounds) for any due diligence advisory work undertaken via the platform. The rate is customizable every time you enter into an engagement. Please remember that, per our Terms and Conditions, IDN will retain 20 percent of the total amount. As such, feel free to incorporate our mark up in your rate.</div>

            <div className="experience-title">
              <span style={{ margin: '0 auto' }}>Work experience</span>
            </div>
            <div className="not-active">Please enter only your current or previous Board/C-level/Director level experiences, leaving aside any other roles you have covered in the past. Please start from the most recent role. It is pivotal to keep your work experiences up to date as investors will considered them when assigning advisory placement opportunities, as well as in the context of the deal matching process.</div>
            <div className="experience-list">
              <div className="list-header">
                <div>Date</div>
                <div>Position</div>
                <div>Company</div>
              </div>
              {
                data.jobs?.map(job => (
                  <div className="list-item" key={job.id}>
                    <div className="no-wrap">{`${moment(job.start_date).format('MM/YYYY')} - ${job.end_date ? moment(job.end_date).format('MM/YYYY') : 'current'}`}</div>
                    <div>{job.position}</div>
                    <div>{job.company}</div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    list: state.experience.list,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getExperience: (advisorId) => dispatch(ExperienceActions.experienceRequest(advisorId)),

})

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorProfilePage)
