import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function WelcomeDDAdvisorPopupPage(props) {
  const { match } = props;
	const {dashboardId} = match.params;

  const history = useHistory();

  useEffect(() => {
    props.dealDashboardSyncToggle(false);
    props.updateSeenByAdvisor(dashboardId);
    return function cleanup() {
      props.getDealDashboardInfo(dashboardId);
      props.dealDashboardSyncToggle(true);
    }
  }, [0]);

  return (
    <PopupWrapper
      width={420}
      path={`/deal-dashboard/overview/${dashboardId}`}
    >
      <div className="dialog-content" style={{ padding: 40, paddingTop: 20 }}>
        <img src={Images.phoneOk} alt="" />
        <div className="dialog-title welcome" style={{ marginTop: 10 }}>Welcome to your <br/> Deal Dashboard</div>
        <div className="deal-text">You are now directly connected with the Investor on the deal opportunity you shared.</div>
        <div className="deal-subtext">Via your Deal Dashboard, you can trigger binding actions to progress on the deal opportunity, chat and exchange documents.
        </div>
        <div className="btn-footer">
          <Button type="primary white" className="btn-primary welcome" style={{ width: 144 }} onClick={() => history.push(`/deal-dashboard/overview/${dashboardId}`)}>
            Continue
            </Button>
        </div>
      </div>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    dashboardInfo: state.dealDashboard.dashboardInfo,
    seenByInvestorResult: state.dealDashboard.seenByInvestorResult,
    role: state.auth.role,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
  updateSeenByAdvisor: (dashboardId) => dispatch(DealDashboardActions.seenByAdvisorRequest(dashboardId)),
  dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeDDAdvisorPopupPage)
