import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import moment from 'moment'

import PaginationItemRender from '../PaginationItemRender'
import CustomIcon from '../../Icon'

import './Table.less'

const closedStatuses = ['terminated', 'rejected', 'DNC'];
const inProgressStatuses = ['shared', 'matched'];

function AdminInvestorsHistoryDealsTable(props){
  const {
    page,
    pageSize,
    data,
    // isReshare,
    adminStatus,
    fetching,
    investorId,
  } = props;

  const [dataSource, setDataSource] = useState(null);

  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     const filterData = selectedRows.filter(row => (!closedStatuses.includes(row.status) && !(((row.status === 'shared' && !row.fromReshare) || (row.status === 'matched' && !row.fromReshare)) && inProgressStatuses.includes(adminStatus)))).map(el => el.id);
  //     props.setAdvisors(filterData);
  //   },
  //   renderCell: (checked, record, index, originNode) => {
  //     if (closedStatuses.includes(record.status) ||
  //     // (((record.status === 'shared' && !record.fromReshare) || (record.status === 'matched' && !record.fromReshare) || (record.status === 'contact investor' && record.shared)) && inProgressStatuses.includes(adminStatus)) ||
  //     // isReshare) {
  //     (((record.status === 'shared' && !record.fromReshare) || (record.status === 'matched' && !record.fromReshare) ||
  //      (record.status === 'contact investor' && record.shared)) && inProgressStatuses.includes(adminStatus))) {
  //       return null;
  //     } else {
  //       return originNode;
  //     }
  //   },
  //   // hideSelectAll: isReshare
  // };

  useEffect(() => {
    let arr = (data && data.results) ? data.results.asMutable({deep:true}) : [];
    arr = arr.map(el => ({
      id: el.id,
      name: el.advisor,
      account_manager: el.account_manager,
      status: el.investor_status_obj.name,
      shared: el.shared_on,
      fromReshare: el.checkbox_share,
      mandate: el.mandate,
      dashboardId: el.dashboard
    }))
    setDataSource(arr);
  }, [data]);

  function getColumns() {
    return [
      {
        title: ' ',
        dataIndex: 'x',
        key: 'x',
        className: 'cell-small',
      },
      {
        title: 'DealID',
        dataIndex: 'id',
        key: 'id',
        render: (text, record) => {
          return (record.mandate && record.dashboardId) ?
          <Link to={{
            pathname: `/home/match-company/${record.dashboardId}`,
            state: {fromInvestorHistory: true, investorId: investorId}
          }}>{text}</Link>
        :
          <Link to={`/home/deal-details/${record.id}`}>{text}</Link>
        },
      },
      {
        title: 'Advisor name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => {
          return (
            <Link to={'/home/firm-details/' + record.firmId}>{text}</Link>
          )
        },
      },
      {
        title: 'Account Manager',
        dataIndex: 'account_manager',
        key: 'account_manager',
      },
      {
        title: 'Deal Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => {
          return (
            <div>{text}</div>
          )
        },
      },
    ];
  }

  function handleTableChange(pagination, filters, sorter){

    var sort_field = null;
    var sort_order = null;

    if(sorter && sorter.field && sorter.order){
      sort_field = sorter.field;
      sort_order = sorter.order //=== "ascend" ? 1 : 0;
    }

    props.onChangePage(pagination.current, pagination.pageSize, sort_field, sort_order)

  }

  const totalCount = data ? data.count : 0;

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount, showSizeChanger: true}}
      className="main-table share"
      rowKey={row => row.id}
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
      showSorterTooltip={false}
      // rowSelection={{
      //   type: 'checkbox',
      //   ...rowSelection,
      // }}
      rowClassName={row => {
        switch(row?.status) {
          case 'contact investor': return 'bold-row';
          case 'DNC': return 'red-bold-row';
          case 'Matched': return 'green-row';
          case 'Rejected':
          case 'Terminated': return 'red-row';
          default: return '';
        }
      }}
    />
   )
}

export default AdminInvestorsHistoryDealsTable
