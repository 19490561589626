import React, { useState, useEffect } from 'react'
import { Layout, Progress, Button, Space } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Switch, Route, Link } from 'react-router-dom'

import SettingsActions from '../../Redux/SettingsRedux'
import DealsActions from '../../Redux/DealsRedux'
import MandatesActions from '../../Redux/MandatesRedux'
import ExperienceActions from '../../Redux/ExperienceRedux'
import MandateSharesActions from '../../Redux/MandateSharesRedux'

import DealSharingStart from '../../Components/DealSharing/DealSharingStart'
import DealSharingReview from '../../Components/DealSharing/DealSharingReview'
import MandateDealSharingStep1 from '../../Components/MandateDealSharing/MandateDealSharingStart'
import DealSharingStep2 from '../../Components/DealSharing/DealSharingStep2'
import DealSharingStep3 from '../../Components/DealSharing/DealSharingStep3'
import Images from '../../Images'
import MandateDealSharingReview from '../../Components/MandateDealSharing/MandateDealSharingReview'

const initial = {
  // start: {
  //   title: 'Step 1/4 - Deal Matching Criteria',
  //   progress: 16,
  //   text: 'The fields outlined below are the foundations of our matching algorithm. Please try to fill them in at the best of your knowledge as this will enable us to match you with relevant investors'
  // },
  1: {
    title: `Step 1/3 - Confirm Mandate Criteria`,
    progress: 25,
    text: `Please confirm that the investment opportunity you originated is aligned to the mandate criteria
    and provide information on the target you identified`
  },
  2: {
    title: 'Step 2/3 - Investment Overview',
    progress: 50,
    text: 'Please provide an overview of the asset, context on the transaction background and rationale, and the expected timeline'
  },
  3: {
    title: 'Step 3/3 - Your Role',
    progress: 75,
    text: 'Please elaborate on your relationship with the target company and on your potential role in this transaction.'
  },
  review: {
    title: 'Deal Review',
    progress: 100,
    text: 'Please review all the information you have inserted and confirm they are correct'
  }
}

function MandateDealSharingPage(props) {
  const { deal, mandate, saveResult, match, location, sectorFocus, geographyFocus, investmentTypes, matchResult, user, experience, 
    dealShareResult, fetching, equityGovernanceList, dealTargetStatusSaveResult, dealOverviewResult, dealRoleInfoResult, role } = props;
  const { step } = match.params;
  const { state } = location;

  const [dealData, setDealData] = useState(null);
  const [mandateData, setMandateData] = useState(null);
  const [dealId, setDealId] = useState(null);
  const [checkRequired, setCheckRequired] = useState(false);
  const [titleData, setTitleData] = useState(initial);
  const [targetStatus, setTargetStatus] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const history = useHistory();

  function changeStep(data) {
    if (step === 'start') {
        setTargetStatus(data);
        history.push('/home/mandate-deal-sharing/step/1');
    } else if (step === '1') {
        props.saveDeal(data);
    } else if (step === '2') {
      data.deal = dealId;
      props.saveDealOverview(data);
    } else if (step === '3') {
      data.deal = dealId;
      props.saveDealRoleInfo(data);
    } else if (step === 'review') {
      props.shareDealByAdvisor(dealId);
      // props.resetExperience();
      // history.push('/home/deal-sharing/step/profile');
    }
  }
  // function changeStep(data) {
  //   if (step === 'start') {
  //     props.saveDeal(data);
  //     setTargetStatus(data);
  //   } else if (step === '1') {
  //     data.deal = dealId;
  //     props.saveDealOverview(data);
  //   } else if (step === '2') {
  //     data.deal = dealId;
  //     props.saveDealOverview(data);
  //   } else if (step === '3') {
  //     data.deal = dealId;
  //     props.saveDealRoleInfo(data);
  //   } else if (step === 'review') {
  //     props.shareDealByAdvisor(dealId);
  //   }
  // }

  useEffect(() => {
    if (!user?.signed_platform_agreement) {
      history.push('/home/mandates');
      return;
    }
    if (state?.mandateShareId) {
      props.getMandateMatchInfo(state.mandateShareId);
    }
    if (state?.mandateId) {
      props.getMandate(state.mandateId);
    }
    if (state?.dealId) {
      setDealId(state.dealId);
    }
    props.getSectors();
    props.getContinents({ regions: true });
    props.getInvestmentTypes();
    props.getExperience(user?.id);
    props.getEquityGovernance();
  }, [0]);

  useEffect(() => {
    switch(step) {
      case '1':
      case '2':
      case '3':
      case '4': {
        if (isFinite(currentStep) && (step > currentStep)) {
          setCurrentStep(step);
        } else if (currentStep !== 'start') {
          history.push('/home/mandates');
        } else {
          setCurrentStep(step);
        }
        break;
      }
      case 'start':
      case 'review': {
        setCurrentStep(step);
        break;
      }
      default: break;
    }
  }, [step]);

  useEffect(() => {
    if (saveResult) {
      setDealId(saveResult.id);
      const data = {...targetStatus};
      data.deal = saveResult.id;
      props.saveDealTargetStatus(data);
    }
  }, [saveResult]);

  // useEffect(() => {
  //   if (dealGroupDataResult && targetStatus) {
  //     const data = {...targetStatus};
  //     data.deal = dealId;
  //     props.saveDealTargetStatus(data);
  //   }
  // }, [dealGroupDataResult]);

  useEffect(() => {
    if (dealTargetStatusSaveResult) {
      // if (fromMandate) {
      //   props.changeDealStatus(dealId, 'in_progress');
      // } else {
      //   history.push('/home/deal-sharing/step/2');
      // }
      history.push('/home/mandate-deal-sharing/step/2');
    }
  }, [dealTargetStatusSaveResult]);

  useEffect(() => {
    if (dealOverviewResult) {
      history.push('/home/mandate-deal-sharing/step/3');
    }
  }, [dealOverviewResult]);

  // useEffect(() => {
  //   if (dealTargetStatusUpdateResult) {
  //     history.push('/home/mandate-deal-sharing/step/review');
  //   }
  // }, [dealTargetStatusUpdateResult]);

  useEffect(() => {
    if (dealRoleInfoResult) {
      props.getDeal(dealId);
      history.push('/home/mandate-deal-sharing/step/review');
    }
  }, [dealRoleInfoResult]);

  useEffect(() => {
    if (matchResult) {
      setMandateData(matchResult.mandate);
    }
  }, [matchResult]);

  useEffect(() => {
    if (dealId) {
      props.getDeal(dealId);
    }
  }, [dealId]);

  useEffect(() => {
    if (deal && !state?.resharing) {
      setDealData(deal);
      setDealId(deal.id);
      if (state?.endSection) {
        history.push('/home/mandate-deal-sharing/step/review/deal-submited');
        return;
      }
      if (deal.advisor_shared) {
        history.push(`/home/mandate-deal-sharing/step/review/confirming-submission`);
      } else {
        // history.push('/home/mandate-deal-sharing/step/review');
      }
    }
    if (deal && state?.resharing) {
      setDealData(deal);
      setDealId(deal.id);
    }
  }, [deal]);

  useEffect(() => {
    if (mandate) {
      setMandateData(mandate);
    }
  }, [mandate]);

  useEffect(() => {
    if (saveResult) {
      setDealId(saveResult.id);
    }
    if (dealShareResult) {
      history.push(`/home/mandate-deal-sharing/step/review/confirming-submission`);
    }
  }, [saveResult, dealShareResult]);

  const checkRequiredFields = () => {
    setCheckRequired(true);
    setTimeout(() => {
      setCheckRequired(false);
    })
  }

  return (
    <Layout>
      <div className="sign-up-step white filters-edit">
        <img src={Images.logo} alt="" onClick={() => history.push('/home/mandates')} />
        {
          step !== 'start' && step !== 'edit' &&
            <div className="step-investor-content">
              <div className="step-title">MANDATE DEAL SHARING</div>
              <div className="step-number">{titleData[step].title}</div>
              <Progress
                className="progress-bar"
                percent={titleData[step]?.progress}
                showInfo={false}
                strokeWidth={13}
                trailColor="rgba(31, 73, 228, 0.12)"
                strokeColor="#3680F6"
                status="active"
              />
              <div className="step-text">{titleData[step]?.text}</div>
            </div>
        }
        <Switch>
          <Route path="/home/mandate-deal-sharing/step/start" render={
            props => (
              <DealSharingStart
                onChangeStep={changeStep}
              />
            )} />
          <Route path="/home/mandate-deal-sharing/step/1" render={
            props => (
              <MandateDealSharingStep1
                checkRequired={checkRequired}
                isAccordion={true}
                dealData={true}
                sectorFocus={sectorFocus}
                geographyFocus={geographyFocus}
                investmentTypes={investmentTypes}
                mandateId={mandateData?.id}
                setContinents={mandateData?.geography?.map(el => el.name)}
                setRegions={mandateData?.geography?.map(el => el.regions).flat().map(el => el.name)}
                setCountries={mandateData?.geography?.map(el => el.regions).flat().map(el => el.countries).flat().map(el => el.id)}
                subsectors={mandateData?.industry_focus_sub_sectors[0]}
                profileSummary={experience?.executive_summary}
                equityGovernanceList={equityGovernanceList}
                onChangeStep={changeStep}
              />
            )} />
          <Route path="/home/mandate-deal-sharing/step/2" render={
            props => (
              <DealSharingStep2
                checkRequired={checkRequired}
                isAccordion={true}
                dealData={dealData}
                onChangeStep={changeStep}
              />
            )} />
          <Route path="/home/mandate-deal-sharing/step/3" render={
            props => (
              <DealSharingStep3
                checkRequired={checkRequired}
                isAccordion={true}
                dealData={dealData}
                onChangeStep={changeStep}
              />
            )} />
          

          <Route path="/home/mandate-deal-sharing/step/review" render={
            props => (
              <MandateDealSharingReview
                dealId={dealId}
                dealData={dealData}
                // investmentTypes={investmentTypes}
                isSharing={true}
                resharing={state?.resharing}
                onChangeStep={changeStep}
                own={true}
                fetching={fetching}
                disableSought={true}
                role={role}
              />
            //   <DealSharingReview
            //   dealId={dealId}
            //   dealData={dealData}
            //   isSharing={true}
            //   onChangeStep={changeStep}
            //   role={role}
            //   disableSought={true}
            // />
            )} />
        </Switch>
        {
          step !== 'start' && step !== 'review' && step !== 'edit' &&
          <>
            <Button
              type="primary"
              className="btn-primary"
              style={{ width: 217, margin: '40px 32px 0 32px' }}
              onClick={checkRequiredFields}
            >
              Continue
            </Button>
            <Space className="return-link" style={{ marginTop: 24 }}>
              <Link to="/home/deals">
                <img src={Images.arrowLeft} alt="" />
                <span>Return to main dashboard</span>
              </Link>
            </Space>
          </>
        }
      </div>
    </Layout>
  )
}


const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    deal: state.deals.deal,
    saveResult: state.deals.saveResult,
    dealShareResult: state.deals.dealShareResult,
    mandate: state.mandates.mandate,
    matchResult: state.mandateShares.matchResult,
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    investmentTypes: state.settings.investmentTypes,
    experience: state.experience.list,
    fetching: state.deals.fetching,
    equityGovernanceList: state.settings.equityGovernanceList,
    dealTargetStatusSaveResult: state.deals.dealTargetStatusSaveResult,
    dealOverviewResult: state.deals.dealOverviewResult,
    dealRoleInfoResult: state.deals.dealRoleInfoResult,
    role: state.auth.role,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDeal: (dealId) => dispatch(DealsActions.dealRequest(dealId)),
  saveDeal: (data) => dispatch(DealsActions.dealSaveRequest(data)),
  saveDealOverview: (data) => dispatch(DealsActions.dealOverviewSaveRequest(data)),
  saveDealRoleInfo: (data) => dispatch(DealsActions.dealRoleInfoSaveRequest(data)),
  shareDealByAdvisor: (dealId) => dispatch(DealsActions.dealShareByAdvisorRequest(dealId)),

  getMandate: (mandateId) => dispatch(MandatesActions.mandateRequest(mandateId)),
  getMandateMatchInfo: (mandateShareId) => dispatch(MandateSharesActions.mandateShareMatchRequest(mandateShareId)),

  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: (filter) => dispatch(SettingsActions.geographyFocusRequest(filter)),
  getCountries: (filter) => dispatch(SettingsActions.countriesRequest(filter)),
  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),

  getExperience: (advisorId) => dispatch(ExperienceActions.experienceRequest(advisorId)),

  getEquityGovernance: () => dispatch(SettingsActions.equityGovernanceRequest()),
  saveDealTargetStatus: (data) => dispatch(DealsActions.dealTargetStatusSaveRequest(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MandateDealSharingPage)
