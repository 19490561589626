import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Spin } from 'antd'

import AuthActions from '../Redux/AuthRedux'
import errorHandler from '../Utils/errorHandler'

function MandateDealSubmittedCheckPage (props) {
  const { error, fetching, match } = props;
  const { hash } = match.params;

  useEffect(()=>{
    props.checkMandateDealHash(hash);
  }, [0]);

  useEffect(() => {
    if (error) {
      errorHandler(error, props.resetError);
    }
  }, [error])

  return (
    <Spin spinning={fetching} size="large">
      <div className="page-full-wrapper"/>
    </Spin>
  )
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    fetching: state.auth.fetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  checkMandateDealHash: (hash) => dispatch(AuthActions.mandateDealSubmittedCheckRequest(hash)),
  resetError: () => dispatch(AuthActions.authErrorReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MandateDealSubmittedCheckPage)
