import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Button } from 'antd'
import moment from 'moment'

import PaginationItemRender from '../PaginationItemRender'
import CustomIcon from '../../Icon'
import Images from '../../Images'

import './Table.less'

function MandatesAdvisorDealsSumbittedTable(props){
  const {
    page,
    pageSize,
    data,
    fetching,
    mandateShareId,
    mandateId,
  } = props;

  const [dataSource, setDataSource] = useState(null);

  const history = useHistory();

  useEffect(() => {
    let arr = (data && data.results) ? data.results.asMutable({deep:true}) : [];
    arr = arr.map(el => ({
      deal_id: el.id,
      issued_on: el.updated,
      shared_on: el.shared,
      status_name: el.status_obj?.name,
      subsector: el.subsector,
      geography: el.geography,
      eq_ticket: el.eq_ticket,
      isNotification: el.advisor_notification,
      dashboardId: el.dashboard,
      status_id: el.status_obj?.id,
    }))
    setDataSource(arr);
  }, [data]);

  function getColumns() {
    return [
      {
        title: 'Deal ID',
        dataIndex: 'deal_id',
        key: 'deal_id',
        className: 'cell-center',
          render: (text, record) => {
          return (
            <div>{`${text}`}</div>
          )
        },
      },
      {
        title: 'Status',
        dataIndex: 'status_name',
        key: 'status_name',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <div className={`deal-status${record.isNotification ? ' orange' : ''}`}>{text}</div>
          )
        },
      },
      {
        title: 'Shared on',
        dataIndex: 'shared_on',
        key: 'shared_on',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <span>{text && moment(text).format('DD/MM/YYYY')}</span>
          )
        },
      },
      {
        title: 'Sector',
        dataIndex: 'subsector',
        key: 'subsector',
        render: (text, record) => {
          return (
            text?.map((el, i) => (
              <div key={i}>{el}</div>
            ))
          )
        },
      },
      {
        title: 'Geography',
        dataIndex: 'geography',
        key: 'geography',
        render: (text, record) => {
          return (
            text?.map((el, i) => (
              <div key={i}>{el}</div>
            ))
          )
        },
      },
      {
        title: 'Equity ticket',
        dataIndex: 'eq_ticket',
        key: 'eq_ticket',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <span style={{whiteSpace: 'nowrap'}}>{text ? `${text}ml` : 'N/A'}</span>
          )
        },
      },
      {
        title: '',
        dataIndex: 'status_btn',
        key: 'status_btn',
        className: 'cell-center',
        render: (text, record) => {
          return (
            <div className="deal-action">
              {
                record.isNotification &&
                  <img src={Images.notification} alt="" />
              }
              <Button
              type="primary" className="btn-primary"
              onClick={()=>generateBtnAction(record)}
              >
                {generateBtnText(record)}
              </Button>
            </div>
          )
        },
      }
    ];
  }

  function handleTableChange(pagination) {
    props.onChangePage(pagination.current, pagination.pageSize)
  }

  const totalCount = data ? data.count : 0;

  const generateBtnText = (record) => {
    if (record?.status_name) {
      switch(record.status_name) {
        case 'Re-sharing': return 'Continue'
        default: return 'View'
      }
    }
  }

  const generateBtnAction = record => {
    if (record?.status_id) {
      switch(record.status_id) {
        case 'sharing_in_progress': {
          history.push({
            pathname: `/home/mandate-deal-sharing/step/review`,
            state: {
              dealId: record.deal_id,
            },
          })
          break;
        }
        case 'closed':
        case 'won':
        case 'engaged': {
          if (record.isNotification) {
            props.deleteNotification(record.deal_id);
          } else
            history.push({
              pathname: `/deal-dashboard/overview/${record.dashboardId}`,
              state: {backPath: `/home/mandate-share-info/${mandateShareId}/deals-submitted`}
            });
          break;
        }
        default: {
          if (record.isNotification) {
            props.deleteNotification(record.deal_id);
          } else {
            history.push({
              pathname: `/home/mandate-deal/${record.deal_id}`,
            });
          }
          break;
        }
      }
    }
  }

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender: PaginationItemRender, current: page, pageSize: pageSize, total: totalCount, showSizeChanger: true}}
      className="deal-table"
      rowKey={row => row.deal_id}
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
      showSorterTooltip={false}
    />
   )
}

export default MandatesAdvisorDealsSumbittedTable
