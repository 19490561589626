import React, { useEffect } from 'react'
import { Spin, Button } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import PopupWrapper from './PopupWrapper'
import UsersActions from '../../Redux/UsersRedux'
import AddAdvisorPopupForm from '../../Components/Forms/AddAdvisorPopupForm'

import './PopupPage.less'

function AddAdvisorPopupPage(props) {
  const { result, fetching } = props;

  const history = useHistory();

  function handleClose() {
    history.push('/home/advisors');
  }

  function handleSubmit(data) {
    props.createUser('advisor', data);
  }

  useEffect(() => {
    if (result) {
      handleClose();
    }
  }, [result]);

  return (
    <PopupWrapper
      width={600}
      path={'/home/advisors'}
    >
      <Spin spinning={fetching} size="large">
        <div style={{ padding: 40 }}>
          <div className="dialog-content">
            <div className="dialog-title">New Advisor</div>
            <AddAdvisorPopupForm onSubmit={handleSubmit} />
            <div className="btn-footer">
              <Button type="primary white" className="btn-primary" onClick={handleClose}>
                Cancel
                </Button>
              <Button type="primary" htmlType="submit" form="add-advisor-popup-form" className="btn-primary" >
                Save
                </Button>
            </div>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}


const mapStateToProps = (state) => {
  return {
    result: state.users.saveResult,
    fetching: state.users.saveFetching
  }
}

const mapDispatchToProps = (dispatch) => ({
  createUser: (role, data) => dispatch(UsersActions.userSaveRequest(role, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddAdvisorPopupPage)
