import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import moment from 'moment'

import PaginationItemRender from '../PaginationItemRender'
import { TitleWithSortFilters } from '../TitleWithSortFilters'
import CustomIcon from '../../Icon'

import './Table.less'

function FirmsTable(props){
  const {
    page,
    pageSize,
    data,
    fetching
  } = props;

  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    let arr = (data && data.results) ? data.results.asMutable({deep:true}) : [];
    arr = arr.map(el => ({
      id: el.id,
      name: el.name,
      account_manager: el.account_manager?.name,
      status: el.status_obj?.name,
      lead_user: el.lead_user?.name,
      created: el.created,
      converted: el.converted
    }))
    setDataSource(arr);
  }, [data]);

  function getColumns() {
    return [
      {
        title: (stateData)=>TitleWithSortFilters("Investment firm", true, false, stateData, 'name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        className: 'cell-left',
        render: (text, record) => {
          return (
            <Link to={'/home/firm-details/' + record.id}>{text}</Link>
          )
        },
      },
      {
        title: (stateData)=>TitleWithSortFilters("Account Manager", true, false, stateData, 'account_manager'),
        dataIndex: 'account_manager',
        key: 'account_manager',
        sorter:true,
      },
      {
        title: (stateData)=>TitleWithSortFilters("Status", true, false, stateData, 'status'),
        dataIndex: 'status',
        key: 'status',
        sorter:true,
      },
      {
        title: (stateData)=>TitleWithSortFilters("Lead Investor", true, false, stateData, 'lead_user'),
        dataIndex: 'lead_user',
        key: 'lead_user',
        sorter:true,
      },
      {
        title: (stateData)=>TitleWithSortFilters("Created on", true, false, stateData, 'created'),
        dataIndex: 'created',
        key: 'created',
        sorter: true,
        className: 'cell-left',
        render: (text, record) => {
          return (
              <span>{text && moment(text).format('DD/MM/YYYY')}</span>
          )
        },
      },
      {
        title: (stateData)=>TitleWithSortFilters("Converted on", true, false, stateData, 'converted'),
        dataIndex: 'converted',
        key: 'converted',
        sorter: true,
        className: 'cell-left',
        render: (text, record) => {
          return (
              <span>{text && moment(text).format('DD/MM/YYYY')}</span>
          )
        },
      }
    ];
  }

  function handleTableChange(pagination, filters, sorter){

    var sort_field = null;
    var sort_order = null;

    if(sorter && sorter.field && sorter.order){
      sort_field = sorter.field;
      sort_order = sorter.order //=== "ascend" ? 1 : 0;
    }

    props.onChangePage(pagination.current, pagination.pageSize, sort_field, sort_order)

  }

  const totalCount = data ? data.count : 0;

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount, showSizeChanger: true}}
      className="main-table"
      rowKey={row => row.id}
      rowClassName="process-table-row"
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
      showSorterTooltip={false}
    />
   )


}

export default FirmsTable
