import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Space, Button } from 'antd'
import { useHistory } from 'react-router-dom'

import Images from '../../Images'


function InvestorSignUpProfileSetup({ subRole }) {
  const history = useHistory();

  return (
    <div className="sign-up-step with-bg">
      <div className="with-bg-top">
        <img src={Images.logoWhite} alt="" onClick={()=>history.push('/')} />
      </div>
      <div className="modal-container" style={{ width: 420, padding: '32px 72px' }}>
        <Space style={{ marginBottom: 24 }}>
          <img src={Images.phoneOk} alt="" />
        </Space>
        <div className="login-title">Profile completed</div>
        <div className="step-finish-text" style={{ marginBottom: 32 }}>As a final step you can invite your colleagues to join IDN’s platform, or you can skip this passage for now and access directly your dashboard.</div>
        <Button type="primary white" className="btn-primary" style={{ width: 217 }} onClick={() => history.push('/invite-colleagues')}>
          Invite Colleagues
            </Button>
        <Space style={{ marginTop: 16 }}>
          <span className="link-tag underline" onClick={() => history.push('/home/deals')}>Skip</span>
        </Space>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    subRole: state.auth.investorSubRole,
  }
}

export default connect(mapStateToProps)(InvestorSignUpProfileSetup)
