import React, { useState, useEffect } from 'react'
import { Button, Input, Row, Col, Select, InputNumber, TreeSelect } from 'antd'
import _ from 'lodash'

import Images from '../../Images'

const { Option } = Select;

const initialFirms = {
  ev_range: [],
  sectors: [],
  open_to_new_sectors: null,
  equity_range: [],
  geography: [],
  open_to_greenfield: null,
  governance: [],
}

const initialUsers = {
  ev_range: [],
  sectors: [],
  open_to_new_sectors: null,
  equity_range: [],
  geography: [],
  open_to_greenfield: null,
  governance: [],
  role: null,
  account_manager: null,
  invest_company: null
}

function InvestorFilterPanel(props) {
  const { filterData, active, sectors, continents, governance, choices, forInvestors } = props;

  const [data, setData] = useState(null);
  const [sectorTree, setSectorTree] = useState(null);
  const [continentTree, setcontinentTree] = useState(null);

  const statusList = [];
  if (choices && choices.statuses) {
    for (let i = 0; i < choices.statuses.length; i++) {
      statusList.push(<Option key={choices.statuses[i].id}>{choices.statuses[i].name}</Option>);
    }
  }

  const managerList = [];
  if (choices && choices.managers) {
    for (let i = 0; i < choices.managers.length; i++) {
      managerList.push(<Option key={choices.managers[i].id}>{choices.managers[i].name}</Option>);
    }
  }

  const firmList = [];
  if (choices && choices.companies) {
    for (let i = 0; i < choices.companies.length; i++) {
      firmList.push(<Option key={choices.companies[i].id}>{choices.companies[i].name}</Option>);
    }
  }

  useEffect(() => {
    const newData = _.cloneDeep(filterData);
    setData(newData);
    applyFilters(newData, true);
  }, [0]);

  useEffect(() => {
    if (sectors) {
      const tree = sectors.map(sector => ({
        title: sector.name,
        value: sector.id,
        children: sector.children.map(child => ({
          title: child.name,
          value: child.id,
          children: child.children.map(el => ({
            title: el.name,
            value: el.id,
          }))
        }))
      }));
      setSectorTree(tree);
    }
  }, [sectors]);

  useEffect(() => {
    if (continents) {
      const tree = continents.map(continent => ({
        title: continent.name,
        value: continent.id,
        children: continent.children.map(child => ({
          title: child.name,
          value: child.id,
        }))
      }));
      setcontinentTree(tree);
    }
  }, [continents]);

  const changeFilter = (field, value, index) => {
    const newData = _.cloneDeep(data);
    if (index != null) {
      newData[field][index] = value;
    } else {
      newData[field] = value;
    }
    setData(newData);
  }

  const clearFilter = (fields, isArray) => {
    const newData = _.cloneDeep(data);
    fields.forEach(field => {
      newData[field] = isArray ? [] : null;
    })
    setData(newData);
    applyFilters(newData);
  }

  const applyFilters = (data, start) => {
    let count = 0;
    for (let key in data) {
      if ((!Array.isArray(data[key]) && data[key] != null) || (Array.isArray(data[key]) && data[key].length)) {
        count += 1;
      }
    }
    props.changeCount(count, data, start);
  }

  const clearAll = () => {
    forInvestors ?
      setData(_.cloneDeep(initialUsers))
    :
      setData(_.cloneDeep(initialFirms));
    applyFilters(null);
  }

  const apply = data => {
    applyFilters(data);
    props.closeFilter();
  }

  return (
    <div className="filter-panel" style={{ display: active ? 'block' : 'none' }}>
      {
        data &&
        <>
          <Row gutter={[16, 24]} style={{ marginBottom: 24 }}>
            <Col span={8} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">EV range</div>
              <Input.Group style={{ width: 'auto', display: 'flex' }}>
                <InputNumber
                  value={data.ev_range[0]}
                  style={{ width: '45%', textAlign: 'center' }}
                  placeholder="From"
                  onChange={(value) => changeFilter('ev_range', value, 0)}
                />
                <Input
                  className="site-input-split"
                  style={{
                    width: '10%',
                  }}
                  placeholder="~"
                  disabled
                />
                <InputNumber
                  value={data.ev_range[1]}
                  className="site-input-right"
                  style={{
                    width: '45%',
                    textAlign: 'center',
                  }}
                  placeholder="To"
                  onChange={(value) => changeFilter('ev_range', value, 1)}
                />
              </Input.Group>
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['ev_range'], true)} />
            </Col>
            <Col span={8} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Sectors</div>
              <TreeSelect
                value={data.sectors}
                showSearch
                multiple
                style={{ minWidth: 'auto', width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={sectorTree}
                placeholder="Please select"
                treeDefaultExpandAll
                onChange={(value) => changeFilter('sectors', value)}
              />
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['sectors'], true)} />
            </Col>
            <Col span={8} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Open to new sectors</div>
              <Select
                value={data.open_to_new_sectors}
                style={{ minWidth: 'auto', width: '100%' }}
                placeholder="Please select"
                onChange={(value) => changeFilter('open_to_new_sectors', value)}
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['open_to_new_sectors'])} />
            </Col>
          </Row>

          <Row gutter={[16, 24]} style={{ marginBottom: 24 }}>
            <Col span={8} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Equity range</div>
              <Input.Group style={{ width: 'auto', display: 'flex' }}>
                <InputNumber
                  value={data.equity_range[0]}
                  style={{ width: '45%', textAlign: 'center' }}
                  placeholder="From"
                  onChange={(value) => changeFilter('equity_range', value, 0)}
                />
                <Input
                  className="site-input-split"
                  style={{
                    width: '10%',
                  }}
                  placeholder="~"
                  disabled
                />
                <InputNumber
                  value={data.equity_range[1]}
                  className="site-input-right"
                  style={{
                    width: '45%',
                    textAlign: 'center',
                  }}
                  placeholder="To"
                  onChange={(value) => changeFilter('equity_range', value, 1)}
                />
              </Input.Group>
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['equity_range'], true)} />
            </Col>
            <Col span={8} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Geography</div>
              <TreeSelect
                value={data.geography}
                showSearch
                multiple
                style={{ minWidth: 'auto', width: '100%'  }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={continentTree}
                placeholder="Please select"
                treeDefaultExpandAll
                onChange={(value) => changeFilter('geography', value)}
              />
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['geography'], true)} />
            </Col>
            <Col span={8} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Open to greenfield</div>
              <Select
                value={data.open_to_greenfield}
                style={{ minWidth: 'auto', width: '100%' }}
                placeholder="Please select"
                onChange={(value) => changeFilter('open_to_greenfield', value)}
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['open_to_greenfield'])} />
            </Col>
          </Row>

          {
            forInvestors &&
            <Row gutter={[16, 24]} style={{ marginBottom: 24 }}>
              <Col span={8} className="flex-center-vertical">
                <div className="flex-center-vertical filter-title">Status</div>
                <Select
                  value={data.role}
                  style={{ minWidth: 'auto', width: '100%' }}
                  placeholder="Please select"
                  onChange={(value) => changeFilter('role', value)}
                >
                  {statusList}
                </Select>
                <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['role'])} />
              </Col>

              <Col span={8} className="flex-center-vertical">
                <div className="flex-center-vertical filter-title">Account manager</div>
                <Select
                  value={data.account_manager}
                  style={{ minWidth: 'auto', width: '100%' }}
                  placeholder="Please select"
                  onChange={(value) => changeFilter('account_manager', value)}
                >
                  {managerList}
                </Select>
                <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['account_manager'])} />
              </Col>
              <Col span={8} className="flex-center-vertical">
                <div className="flex-center-vertical filter-title">Investment firm</div>
                <Select
                  value={data.invest_company}
                  style={{ minWidth: 'auto', width: '100%' }}
                  placeholder="Please select"
                  onChange={(value) => changeFilter('invest_company', value)}
                >
                  {firmList}
                </Select>
                <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['invest_company'])} />
              </Col>
            </Row>
          }

          <Row gutter={[16, 24]} style={{ marginBottom: 24 }}>
            <Col span={8} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Governance</div>
              <Select
                value={data.governance}
                mode="multiple"
                style={{ minWidth: 'auto', width: '100%' }}
                placeholder="Please select"
                onChange={(value) => changeFilter('governance', value)}
              >
                {governance?.map(item => {
                  return (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  )
                })}
              </Select>
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['governance'], true)} />
            </Col>
          </Row>
        </>
      }
      <Row gutter={[16, 24]} style={{ marginTop: 0, marginBottom: 0 }} justify="end">
        <div className="filter-actions flex-center-vertical">
          <span className="clear-all" onClick={clearAll}>Reset</span>
          <Button type="primary orange" className="btn-primary" onClick={() => apply(data)}>
            Apply
          </Button>
        </div>
      </Row>
    </div>
  )
}

export default InvestorFilterPanel
