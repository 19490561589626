import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from './../../Redux/DealDashboardRedux'
import DealsActions from './../../Redux/DealsRedux'
import PopupWrapper from './PopupWrapper'
import Images from './../../Images'
import './PopupPage.less'

function ReshareMandateDealPopupPage(props) {
  const { match, fetching, result, dealId_state, dashboardId, location, dealTargetStatus } = props;
  const { dealId } = match.params;
  const { state } = location;

  const [targetId, setTargetId] = useState(null);

  const history = useHistory();

  useEffect(() => {
    props.getDealTargetStatus(dealId);
    // props.dealDashboardSyncToggle(false);
    // props.dealsSyncToggle(false);
    return function cleanup() {
      // props.dealDashboardSyncToggle(true);
      // props.dealsSyncToggle(true);
    }
  }, [0]);

  useEffect(() => {
    if (dealTargetStatus) {
      setTargetId(dealTargetStatus.id);
    }
  }, [dealTargetStatus]);

  useEffect(() => {
    if (result) {
      // history.push(`/home/deals`);
      if (state?.reshareByAdmin) {
        history.push(`/home/deal-pipeline`)
      } else {
        history.push({
          pathname: `/home/deal-sharing/step/start`,
          state: {
            dealId: result.new_id,
            fromMandate: true,
            targetId: targetId,
          }
        });
      }
    }
  }, [result]);

  const cancelResharing = () => {
    if (dealId) {
      state && state?.fromDealDeatailsPage 
        ? history.push(`/deal-dashboard/deal-details/${dealId}`) : 
        state?.reshareByAdmin ? history.push(`/home/deal-details/${dealId}`)
        : history.push(`/home/mandate-deal/${dealId}`)
    } else {
      history.push(`/deal-dashboard/overview/${dashboardId}`)
      // state.isAdmin 
      //   ? history.push(`/home/match-company/${dashboardId}`)
      //   : history.push(`/deal-dashboard/overview/${dashboardId}`)
    }
  }

  return (
    <PopupWrapper
      width={360}
      path={state?.reshareByAdmin ? `/home/deal-details/${dealId}` : `/home/mandate-deal/${dealId}`}
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content" style={{ padding: 30, paddingTop: 12, paddingBottom: 30 }}>
          <img src={Images.phoneOk} alt="" />
          <div className="dialog-title welcome" style={{ margin: 6 }}>Re-share Deal</div>
          <div className="deal-text engagment" style={{ marginTop: 14 }}>Would you like to re-share the deal on <br /> IDN's platform and connect to new <br /> investors? If so, we will be in touch <br /> shortly to discuss next steps. If not, the <br /> deal will be officially closed.</div>
          <div className="btn-footer" style={{ marginTop: 16 }}>
            <Button type="primary white"
              className="btn-primary welcome"
              style={{ width: 91, minWidth: 'auto' }}
              onClick={cancelResharing}
            >
              No
            </Button>
            <Button type="primary white"
              className="btn-primary welcome"
              style={{ width: 91, minWidth: 'auto' }}
              onClick={() => props.reshareDealRequestByAdvisor(dealId ? dealId : dealId_state)}
            >
              Yes
            </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    result: state.deals.dealReshareResult,
    fetching: state.deals.fetching,
    dealId_state: state.dealDashboard.dealId,
    dashboardId: state.dealDashboard.dashboardId,
    isAdmin: state.auth.isAdmin,
    dealTargetStatus: state.deals.dealTargetStatus,
  }
}

const mapDispatchToProps = (dispatch) => ({
  reshareDealRequestByAdvisor: (dealId) => dispatch(DealsActions.dealReshareRequestByAdvisorRequest(dealId)),
  dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
  dealsSyncToggle: (syncIsStart) => dispatch(DealsActions.dealsSyncToggle(syncIsStart)),
  getDealTargetStatus: (dealId) => dispatch(DealsActions.dealTargetStatusRequest(dealId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReshareMandateDealPopupPage)
