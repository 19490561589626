import React, { useState, useEffect } from 'react'
import { Input, Button, Spin, Upload, Tooltip, Row, message } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { FileIcon, defaultStyles } from 'react-file-icon'

import DealsActions from '../../Redux/DealsRedux'
import DealSharesActions from '../../Redux/DealSharesRedux'
import SettingsActions from '../../Redux/SettingsRedux'
import Images from '../../Images'

const { TextArea } = Input;

const closedStatuses = ['withdrawn', 'closed_reshare'];

function DealFirmPage(props) {
  const { fetching, matchResult, statusResult, addBilateralByAdminResult, keepMatchReviewResult, advisorMatchConfirmResult,
    deal, match, location, isAdmin, saveFetching } = props;

  const { shareId } = match.params;
  const { state } = location;

  const [data, setData] = useState(null);
  const [inactive, setInactive] = useState(false);
  const [dealStatus, setDealStatus] = useState(null);
  const [adminStatus, setAdminStatus] = useState(null);

  const history = useHistory();

  const backPath = state || isAdmin ?
    `/home/deal-details/${data?.deal_id}/matches`
  :
    `/home/deal/${data?.deal_id}`;

  useEffect(() => {
    props.getDealMatchInfo(shareId);
  }, [0]);

  useEffect(() => {
    if (matchResult) {
      setData(matchResult);
      setAdminStatus(matchResult.advisor_status_obj?.id);
      props.getDeal(matchResult.deal_id);
    }
  }, [matchResult]);

  useEffect(() => {
    if (deal) {
      setDealStatus(deal.status_obj?.id);
      if (deal.status_obj?.id === 'closed_reshare' || deal.status_obj?.id === 'resharing') {
        setInactive(true);
      }
    }
  }, [deal]);

  // useEffect(() => {
  //   if (statusResult) {
  //     history.push(`/home/deal/${data?.deal_id}`)
  //   }
  // }, [statusResult]);

  useEffect(() => {
    if (keepMatchReviewResult) {
      history.push(`/home/deal/${data?.deal_id}`)
    }
  }, [keepMatchReviewResult]);

  useEffect(() => {
    if (advisorMatchConfirmResult) {
      history.push(`/home/deal-investor-firm/${shareId}/confirming-match`);
    }
  }, [advisorMatchConfirmResult]);

  let fileUid = null;

  const beforeUpload = (file, fileList) => {
    //this need to work func onChangeBilateralContract once time
    return false;
  }

  const onChangeBilateralContractByAdmin = ({ file, fileList }) => {
    if (fileUid && fileUid === file.uid) {
      return;
    }
    fileUid = file.uid;
    let formData = new FormData();
    if (fileList && fileList.length) {
      formData.set('file', fileList[0].originFileObj, fileList[0].originFileObj.name);
      props.addBilateralContractByAdmin(shareId, formData);
    }
  }

  useEffect(() => {
    if (addBilateralByAdminResult) {
        message.success('Bilateral contract successfully uploaded', 3);
        props.getDealMatchInfo(shareId);
    }
  }, [addBilateralByAdminResult]);

  return (
    <div className="sign-up-step filters-edit wo-padding" style={{ padding: '133px 80px 32px' }}>
      <div className="absolute-header">
        <div className="logo-block">
          <img src={Images.logoWhite} alt="" />
        </div>
      </div>
      <div className="return-block" style={{ padding: '16px 0' }}>
        <Link to={backPath}>
          <img src={Images.arrowLeft} alt="" />
          <span>Go back</span>
        </Link>
      </div>
      <Spin size="large" spinning={fetching || saveFetching}>
      <div className="firm-profile-block match">
        {
          data &&
          <>
            <div className="section-title" style={{ alignSelf: 'self-start', marginTop: 0 }}>Profile</div>
              <div className="firm-profile-section-top">
                <div className="firm-profile-input" style={{ marginRight: 40 }}>
                  <span>Firm name</span>
                  <Input
                    defaultValue={data.invest_company?.name}
                    disabled={true}
                  />
                </div>
                <div className="firm-profile-input">
                  <span>Website</span>
                  <Input
                    defaultValue={data.invest_company?.website}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="firm-profile-section-middle">
                <div className="firm-profile-title">
                  <span>Firm Overview</span>
                </div>

                <div className="firm-profile-area">
                  <TextArea
                    defaultValue={data.invest_company?.overview}
                    autoSize={true}
                    disabled={true}
                  />
                </div>
                <div className="firm-profile-title">
                  <span>Investment preferences</span>
                </div>
                <div className="firm-profile-area">
                  <TextArea
                    defaultValue={data.invest_company?.investment_preferences}
                    autoSize={true}
                    disabled={true}
                  />
                </div>
                <div className="firm-profile-title">
                  <span>Asset Management Style</span>
                </div>
                <div className="firm-profile-area">
                  <TextArea
                    defaultValue={data.invest_company?.management_style}
                    autoSize={true}
                    disabled={true}
                  />
                </div>
                <div className="firm-profile-input-bottom row">
                  <span>Portfolio companies</span>
                  <div>
                    <Input
                      defaultValue={data.invest_company?.portfolio}
                      placeholder="Link to Website"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>

              <div className="section-title" style={{ alignSelf: 'self-start', marginTop: 0 }}>Answer to Screening Questions</div>
              <div className="firm-profile-section-middle">
                {
                  data.answers?.map(el => (
                    <div key={el.id}>
                      <div className="firm-profile-title match">
                        <span>{el.question}</span>
                      </div>

                      <div className="firm-profile-area">
                        <TextArea
                          defaultValue={el.answer}
                          autoSize={true}
                          disabled={true}
                        />
                      </div>
                    </div>
                  ))
                }
              </div>

              <div className="section-title" style={{ alignSelf: 'self-start', marginTop: 0 }}>Additional Comments and Notes</div>
                <div className="firm-profile-section-middle">
                  <div className="firm-profile-area" style={{marginBottom: 0}}>
                    <TextArea
                      defaultValue={data.investor_comment}
                      autoSize={true}
                      disabled={true}
                    />
                </div>
              </div>

             {isAdmin &&
                <>
                  <div className="section-title" style={{ alignSelf: 'self-start', marginTop: 0 }}>Bilateral Agreement</div>
                  <div className="firm-profile-section-middle1">
                    <div className="firm-profile-area" style={{marginBottom: 0}}>

                      <div className='documents-admin' style={{ width: '50%'}}>
                        <Spin spinning={saveFetching}>
                          <div className='documents-admin clone'>
                            {data?.signed_bilateral_contract &&
                              <div className='document-admin' style={{ marginLeft: 40}}>
                                <a href={data?.signed_bilateral_contract} target='_blank' rel='noreferrer'
                                  style={{ marginTop: 5, display: 'flex', alignItems: 'center', flexDirection: 'column' }}
                                >
                                  <div className='document-admin-icon'>
                                    <FileIcon
                                      extension={'pdf'}
                                      {...defaultStyles['pdf']}
                                    />
                                  </div>
                                  <Tooltip
                                    placement="bottom"
                                    title={'Bilateral contract'}
                                  >
                                    <div className='document-admin-title'>Bilateral contract</div>
                                  </Tooltip>
                                </a>
                              </div>
                            }
                          </div>
                        </Spin>
                      </div>

                      <div className="firm-profile-input-bottom row">
                        <Row>
                          <Upload
                            method="get"
                            accept="application/pdf"
                            maxCount={1}
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={onChangeBilateralContractByAdmin}
                          >
                            <Button
                              type='primary white'
                              className='btn-primary'
                              style={{ width: 'auto', margin: '0 12px' }}
                            >
                              Upload
                            </Button>
                          </Upload>


                          {data?.signed_bilateral_contract &&
                            <a
                              href={data?.signed_bilateral_contract}
                              target='_blank'
                              rel='noreferrer'
                              download
                            >
                              <Button
                                type='primary white'
                                className='btn-primary'
                                style={{ width: 'auto', margin: '0 12px' }}
                              >
                                Download
                              </Button>
                            </a>
                          }
                        </Row>
                      </div>

                    </div>
                  </div>
                </>
              }

              {!isAdmin &&
                <>
                  <div
                    className="divider"
                    style={{width: 'auto', height: 7, margin: '24px 0 34px', background: '#bec1c4', borderRadius: 5}}
                  ></div>

                  <div className="firm-profile-section-middle bilateral">
                    <div className="section-title bilateral" style={{ alignSelf: 'self-start', marginTop: 0, width: 'fit-content' }}>Bilateral Contract of Engagement</div>
                      <div className="firm-profile-area bilateral" style={{marginBottom: 0}}>
                      {/* Please review carefully the attached form of the Bilateral contract agreed between the Investor and IDN. By confirming this match, you accept
                      and acknowledge that, if an introduction to the target company is requested by the Investor, the attached form of Bilateral contract will be
                      submitted to you. You must read it carefully before confirming any match because this can have important implications concerning any
                      eventual termination of the engagement with the investor, as outlined in clause 7(a)(ii) of the Platform Agreement */}
                      Please download and review carefully {data?.invest_company?.name} Bilateral Contract of engagement.
                      The Bilateral Contract contains all the legal and commercial terms of engagement that you will have
                      to be entering to if you select {data?.invest_company?.name} in relation to this deal.
                    </div>
                    <div className="download-btn main" style={{width: 'fit-content', margin: '30px 0 20px 0'}}>
                      <a href={data?.invest_company?.bilateral_contract}
                      target="_blank" rel="noopener noreferrer">
                        <img src={Images.pdf} alt="" />
                        <span>Download</span>
                      </a>
                    </div>
                  </div>
                </>
              }

            {/* <div className="section-title bilateral" style={{ alignSelf: 'self-start', marginTop: 0 }}>Special provisions</div>
              <div className="firm-profile-section-middle">
                <div className="firm-profile-area" style={{marginBottom: 0}}>
                  <TextArea
                    defaultValue={data?.invest_company?.special_provisions}
                    autoSize={true}
                    disabled={true}
                  />
                </div>
              </div> */}
          </>
        }
        {
          (!state && !closedStatuses.includes(dealStatus) && adminStatus !== 'closed' && !data?.advisor_status_obj?.id?.includes('withdrawn')) &&
            <div className="btn-footer" style={{ marginTop: 58 }}>
              <div className="btn-text-block">
                <Button type="primary white"
                  className="btn-primary"
                  style={{ width: 217 }}
                  disabled={inactive}
                  onClick={() => props.keepMatchUnderReview(shareId)}
                >
                  Review
                </Button>
                <span>[Keep the match under review]</span>
              </div>
              <div className="btn-text-block">
                <Button type="primary"
                className="btn-primary"
                style={{ width: 217 }}
                disabled={inactive}
                onClick={() => props.confirmMatchByAdvisor(shareId)}
                >
                  Confirm
                </Button>
                <span>[confirm the match]</span>
              </div>
            </div>
        }
      </div>
      </Spin>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    fetching: state.dealShares.fetching,
    matchResult: state.dealShares.matchResult,
    statusResult: state.dealShares.statusResult,
    keepMatchReviewResult: state.dealShares.keepMatchReviewResult,
    advisorMatchConfirmResult: state.dealShares.advisorMatchConfirmResult,
    deal: state.deals.deal,
    filters: state.settings.firmFilters,
    isAdmin: state.auth.isAdmin,
    addBilateralByAdminResult: state.settings.addBilateralByAdminResult,
    saveFetching: state.settings.saveFetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDeal: (dealId) => dispatch(DealsActions.dealRequest(dealId)),
  getDealMatchInfo: (shareId) => dispatch(DealSharesActions.dealShareMatchRequest(shareId)),
  keepMatchUnderReview: (shareId) => dispatch(DealSharesActions.advisorKeepMatchReviewRequest(shareId)),
  changeDealShareStatus: (id, status) => dispatch(DealSharesActions.dealShareChangeStatusRequest(id, status)),
  confirmMatchByAdvisor: (shareId) => dispatch(DealSharesActions.advisorMatchConfirmRequest(shareId)),
  addBilateralContractByAdmin: (shareId, data) => dispatch(SettingsActions.addBilateralContractByAdminRequest(shareId, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DealFirmPage)
