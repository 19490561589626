import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Input } from 'antd'

import DealFilterPanel from '../../Components/FilterPanels/DealFilterPanel'
import DealPipelineTable from '../../Components/Tables/DealPipelineTable'
import DealsActions from '../../Redux/DealsRedux'
import MandatesActions from '../../Redux/MandatesRedux'
import SettingsActions from '../../Redux/SettingsRedux'
import Images from '../../Images'

const { Search } = Input;

function DealPipelinePage(props) {
  const { deals, sectorFocus, geographyFocus, equityGovernanceList, investmentTypes } = props;

  const [active, setActive] = useState(false);
  const [count, setCount] = useState(null);

  useEffect(() => {
    props.setDealId(null);
    props.setMandateId(null);
    props.getDeals(deals.page, deals.page_size, deals.sort_field, deals.sort_order, deals.search, {...deals.filter, group: 'all'});
    props.getSectors();
    props.getContinents();
    props.getEquityGovernance();
    props.getInvestmentTypes();
    props.getDealStatuses();
  }, [0]);

  function handleSearch(val) {
    if (deals.search === val) {
      return;
    }
    props.getDeals(1, deals.page_size, deals.sort_field, deals.sort_order, val, deals.filter);
  }

  function changeCount(value, data, start) {
    setCount(value);
    !start && props.getDeals(1, deals.page_size, deals.sort_field, deals.sort_order, deals.search, {...data, group: 'all'});
  }

  function handleChangePage(page, page_size, sort_field, sort_order) {
    props.getDeals(page, page_size, sort_field, sort_order, deals.search, deals.filter);
  }

  function closeFilter() {
    setActive(!active);
  }

  return (
    <div className="main-container admin-container">
      <div className="action-panel">
        <Search
          placeholder="Search"
          allowClear
          className="content-search"
          suffix={<img src={Images.search} alt="" />}
          defaultValue={deals.search}
          onSearch={handleSearch}
        />
        <Button type={`primary${count ? ' orange' : ' white'}`} className="btn-primary" onClick={closeFilter}>
          {'Filter' + (count ? ` (${count})` : '')}
        </Button>
        <DealFilterPanel
          filterData={deals.filter}
          active={active}
          sectors={sectorFocus}
          continents={geographyFocus}
          governance={equityGovernanceList}
          investmentTypes={investmentTypes}
          choices={deals.dealStatuses}
          changeCount={changeCount}
          closeFilter={closeFilter}
        />
      </div>
      <div className="table-container">
        <DealPipelineTable
          data={deals.list}
          page={deals.page}
          fetching={deals.fetching}
          pageSize={deals.page_size}
          onChangePage={handleChangePage}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    deals: state.deals,
    deal: state.deals.deal,
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    equityGovernanceList: state.settings.equityGovernanceList,
    investmentTypes: state.settings.investmentTypes,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDeals: (page, page_size, sort_field, sort_order, search, filter) => dispatch(DealsActions.dealsRequest(page, page_size, sort_field, sort_order, search, filter)),
  getDeal: (id) => dispatch(DealsActions.dealRequest(id)),
  getDealStatuses: () => dispatch(DealsActions.dealStatusesRequest()),
  setDealId: (dealId) => dispatch(DealsActions.setDealId(dealId)),

  setMandateId: (mandateId) => dispatch(MandatesActions.setMandateId(mandateId)),

  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: (filter) => dispatch(SettingsActions.geographyFocusRequest(filter)),
  getEquityGovernance: () => dispatch(SettingsActions.equityGovernanceRequest()),
  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DealPipelinePage)
