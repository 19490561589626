import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import { Button } from 'antd'
import {useHistory} from 'react-router-dom'

import ExpertConsultActions from '../../../Redux/ExpertConsultRedux'
import DealDashboardActions from '../../../Redux/DealDashboardRedux'

import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function InvestorAgreementEndedPopupPage(props) {
  const {dashboardId} = props.match.params
  const {state} = props.location
  const {result} = props
	const history = useHistory()

	const data = {
		is_viewed: true,
	}

  useEffect(() => {
    if (result) {
      props.getDealDashboardInfo(dashboardId)
      history.push({pathname: `/deal-dashboard/overview/${dashboardId}`})
    }
	}, [result])

	const onContinue = () => {
    props.notificationViewedRequest(state?.notification, dashboardId, data)
	}

	return (
		<PopupWrapper
			width={420}
			path={`/deal-dashboard/overview/${dashboardId}`}
			hasDropdown={true}
		>
			<div className='dialog-content' style={{padding: '24px 32px'}}>
				<img src={Images.phoneOk} alt='' />
				<div className='dialog-title bold' style={{margin: 6}}>
					Agreement ended
				</div>
				<div className='deal-text' style={{marginTop: 34}}>
				The Expert Consulting Agreement has ended. If you wish to re-engage the Senior Advisor, you can do so via your Expert Consulting box.
				</div>
				<div className='btn-footer' style={{marginTop: 40}}>
					<Button
						type='primary white'
						className='btn-primary flat'
						style={{width: 144}}
						onClick={onContinue}
					>
						Continue
					</Button>
				</div>
			</div>
		</PopupWrapper>
	)
}

const mapStateToProps = state => {
	return {
    result: state.expertConsult.result,
	}
}

const mapDispatchToProps = dispatch => ({
	notificationViewedRequest: (notifId, dashboardId, data) =>
		dispatch(ExpertConsultActions.notificationViewedRequest(notifId, dashboardId, data)),
	getDealDashboardInfo: dashboardId => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
  resetDealDashboard: () => dispatch(DealDashboardActions.dealDashboardReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvestorAgreementEndedPopupPage)
