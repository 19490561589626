import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Input, Tabs, Button, message, Spin, Radio, Space, Modal } from 'antd'
import { useHistory } from 'react-router-dom'


import DealsActions from '../../Redux/DealsRedux'
import AuthActions from '../../Redux/AuthRedux'
import UsersActions from '../../Redux/UsersRedux'
import FirmsActions from '../../Redux/FirmsRedux'
import SettingsActions from '../../Redux/SettingsRedux'
import Images from '../../Images'
import errorHandler from '../../Utils/errorHandler'
import InvestorDetailsProfileForm from '../../Components/Forms/InvestorDetailsProfileForm'

import AdminInvestorsActiveDealsTable from '../../Components/Tables/AdminInvestorsActiveDealsTable'
import AdminInvestorsHistoryDealsTable from '../../Components/Tables/AdminInvestorsHistoryDealsITable'

const { TabPane } = Tabs;
const { TextArea } = Input;

const okButtonProps = {
  type: "primary",
  className: "btn-primary",
  style: { width: 172 }
};

const deleteButtonProps = {
  type: "primary red",
  className: "btn-primary",
  style: { width: 172 }
};

const cancelButtonProps = {
  type: "primary white",
  className: "btn-primary",
  style: { width: 172 }
};

function InvestorDetailsPage(props) {
  const { filters, result, error, inviteDataResult, admins, roles, firms, lockHistory, userResult, userFetching, page_active_deals, page_history_deals,
    page_size, userDeleteResult, userDeleteFetching, settingsFetching, settingsSaveFetching, authFetching, match, deals, advisorSharedList,
    advisorSharedHistoryList, dealShareSpecificInvestorResult } = props;
  const { investorId, tab } = match.params;

  const [active, setActive] = useState(false);
  const [isLock, setIsLock] = useState(null);
  const [operation, setOperation] = useState(null);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [sendVisible, setSendVisible] = useState(false);
  const [inviteTitle, setInviteTitle] = useState(null);
  const [inviteBody, setInviteBody] = useState(null);
  const [activeKey, setActiveKey] = useState('profile');

  const [dataActiveDeals, setDataActiveDeals] = useState(null);
  const [dataHistoryDeals, setDataHistoryDeals] = useState(null);
  const [activeDealsList, setActiveDealsList] = useState(null);

  const history = useHistory();

  function handleNavigate(path) {
    history.push(path);
  }

  useEffect(() => {
    props.resetData();
    props.getFilters(investorId, 'investor');
    props.getUsers('admin');
    props.getRoles();
    props.getFirms();
    props.getHistory(investorId);
    props.getActiveDealAdvisorsShared({ investor: investorId }, 1, page_size);
    props.getHistoryDealAdvisorsShared({ investor: investorId }, 1, page_size);
  }, [0]);

  useEffect(() => {
    setActiveKey(tab ? tab : 'profile');
  }, [tab]);

  useEffect(() => {
    if (advisorSharedList) {
      setDataActiveDeals(advisorSharedList);
    }
    if (advisorSharedHistoryList) {
      setDataHistoryDeals(advisorSharedHistoryList);
    }
  }, [advisorSharedList, advisorSharedHistoryList]);

  useEffect(() => {
    if (result && result.success) {
      message.success(result.success, 3);
      setSendVisible(false);
    }
  }, [result])

  useEffect(() => {
    if (dealShareSpecificInvestorResult && dealShareSpecificInvestorResult.result === 'Success') {
      message.success(`Deal(s) successfully shared`);
      setActiveDealsList(null);
      props.getActiveDealAdvisorsShared({ investor: investorId }, page_active_deals, page_size);
    }
  }, [dealShareSpecificInvestorResult])

  useEffect(() => {
    if (userResult && userResult.success) {
      message.success(userResult.success, 3);
      if (userResult.success.includes('locked')) {
        setIsLock(!isLock);
      }
    }
  }, [userResult])

  useEffect(() => {
    if (userDeleteResult && userDeleteResult.success) {
      message.success(userDeleteResult.success, 3);
      setDeleteVisible(false);
    }
  }, [userDeleteResult])

  useEffect(() => {
    if (error) {
      errorHandler(error, props.resetError);
    }
  }, [error]);

  useEffect(() => {
    if (filters) {
      setIsLock(filters.is_blocked);
      const operation = (filters.register_status_obj?.id !== 'registered') ?
          <Button
            type="primary"
            className="btn-primary"
            onClick={getUserInviteData}>
            Send invitation
          </Button>
        :
          activeKey === 'profile' ?
            <Button
              type="primary"
              className="btn-primary"
              disabled={!filters.all_agreements}
              onClick={() => history.push(`/home/mandate-sharing/step/start`)}
            >
              New Mandate
            </Button>
          : activeKey === 'active-deals' ?
            <Button type="primary"
              className="btn-primary"
              // disabled={isResharing || adminStatus === 'engaged' || adminStatus === 'withdrawn'}
              onClick={shareDealToSpesificInvestor}
            >
              Share
            </Button>
          :
            null
      setOperation(operation);
    }
  }, [filters, activeKey, activeDealsList]);

  useEffect(() => {
    if (inviteDataResult) {
      setInviteTitle(inviteDataResult.title);
      setInviteBody(inviteDataResult.body);
      setSendVisible(true);
    }
  }, [inviteDataResult])

  function updateField(field, value) {
    if (filters && filters[field] !== value) {
      props.updateFilters(investorId, 'investor', { [field]: value })
    }
  }

  function getUserInviteData() {
    if (filters) {
      props.getInviteData(filters.email, 'investor');
    }
  }

  function sendUserInvite() {
    const data = {
      email: filters.email,
      title: inviteTitle,
      body: inviteBody
    }
    props.sendInvite(data, 'investor');
  }

  function toggleBlock() {
    const action = isLock ? 'unlock' : 'lock';
    props.toggleUserLock(investorId, action);
  }

  function sendPassword() {
    props.toggleUserLock(investorId, 'send_password');
  }

  function deleteUser() {
    props.deleteUser('investor', investorId);
  }

  const onTabChange = key => {
    history.push(`/home/investor-details/${investorId}/${key}`);
    setActiveKey(key);
  }

  function handleChangeActiveDealsTable(page, page_size) {
    // setShareData({ ...shareData, page, page_size });
    props.getActiveDealAdvisorsShared({ investor: investorId }, page, page_size);
  }

  function handleChangeHistoryDealsTable(page, page_size) {
    // setShareData({ ...shareData, page, page_size });
    props.getHistoryDealAdvisorsShared({ investor: investorId }, page, page_size);
  }

  const shareDealToSpesificInvestor = () => {
    if (activeDealsList && activeDealsList.length) {
      props.shareDealWithSpecificInvestor(investorId, { deals: activeDealsList });
    }
  }

  return (
    <div className="main-container">
      <Spin spinning={userFetching || settingsFetching || settingsSaveFetching || authFetching || userDeleteFetching} size="large">
        <Tabs
          activeKey={activeKey}
          tabBarExtraContent={operation}
          style={{ marginTop: 32 }}
          onChange={onTabChange}
        >
          <TabPane tab="Profile" key="profile">
            <div className="investor-details">
              <div className="details-info">
                <img src={Images.edit} alt="" onClick={() => setActive(!active)} />
                {
                  filters &&
                  <InvestorDetailsProfileForm
                    data={filters}
                    active={active}
                    admins={admins}
                    firms={firms}
                    roles={roles}
                    updateField={updateField}
                  />
                }
                <Space className="flex-center-horizontal" style={{ marginTop: 32 }}>
                  <Button type="primary red" className="btn-primary" onClick={() => setDeleteVisible(true)}>
                    Delete
                  </Button>
                </Space>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Matching filters" key="filters">
            <div className="firm-profile-block">
              <div className="firm-filter-section">
                <div className="firm-filter-header flex-center-vertical">
                  <img src={Images.coins} alt="" />
                  <span>Investment focus</span>
                  <img src={Images.edit} alt="" onClick={() => handleNavigate(`/home/investor/filters-edit/${investorId}`)} />
                </div>

                <div className="firm-filter-content">
                  <div className="investment-range">
                    <div className="investment-range-title">Enterprise value</div>
                    <div className="investment-range-data">
                      <span>Range (ml)</span>
                      <div className="investment-range-label">From</div>
                      <Input value={filters?.investment_focus?.enterprise_value_from} disabled={true} />

                      <div className="investment-range-label">To</div>
                      <Input value={filters?.investment_focus?.enterprise_value_to} disabled={true} />
                      <span>[in £]</span>
                    </div>
                  </div>
                  <div className="investment-range">
                    <div className="investment-range-title">Equity ticket</div>
                    <div className="investment-range-data">
                      <span>Range (ml)</span>
                      <div className="investment-range-label">From</div>
                      <Input value={filters?.investment_focus?.equity_investment_from} disabled={true} />

                      <div className="investment-range-label">To</div>
                      <Input value={filters?.investment_focus?.equity_investment_to} disabled={true} />
                      <span>[in £]</span>
                    </div>
                  </div>
                  <div className="investment-range">
                    <div className="investment-range-title">Equity stake</div>
                    <div className="investment-range-data">
                      <span className="percent-span">Range (%)</span>
                      <div className="investment-range-label">From</div>
                      <Input value={filters?.investment_focus?.equity_stake_from} disabled={true} />

                      <div className="investment-range-label">To</div>
                      <Input value={filters?.investment_focus?.equity_stake_to} disabled={true} />
                    </div>
                  </div>

                  <div className="investment-range base" style={{ alignItems: 'center', paddingBottom: 16 }}>
                    <div className="investment-range-title">Governance of the targeted equity</div>
                    <div className="investment-range-value flex-center-vertical">
                      <span>{filters?.investment_focus?.governace_of_the_targeted_equity.map(el => el.name).join(', ')}</span>
                      {
                        filters?.investment_focus?.specified_governace &&
                          filters?.investment_focus?.governace_of_the_targeted_equity?.length ?
                            <span>{`, ${filters?.investment_focus?.specified_governace}`}</span>
                          :
                            <span>{filters?.investment_focus?.specified_governace}</span>
                      }
                    </div>
                  </div>

                  <div className="investment-range base" style={{ alignItems: 'center' }}>
                    <div className="investment-range-title">Type of investment</div>
                    <div className="investment-range-value flex-center-vertical">
                      <span>{filters?.investment_focus?.investment_type.filter(el => el.id !== 3).map(el => el.name).join(', ')}</span>
                      {
                        filters?.investment_focus?.comment &&
                          filters?.investment_focus?.investment_type?.length > 1 ?
                            <span>{`, ${filters?.investment_focus?.comment}`}</span>
                          :
                            <span>{filters?.investment_focus?.comment}</span>
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="firm-filter-section">
                <div className="firm-filter-header flex-center-vertical">
                  <img src={Images.puzzle} alt="" />
                  <span>Sector Focus</span>
                  <img src={Images.edit} alt="" onClick={() => handleNavigate(`/home/investor/filters-edit/${investorId}`)} />
                </div>
                <div className="firm-filter-content" style={{ paddingLeft: 40 }}>
                  <div className="firm-filter-line flex-center-vertical">
                    <div className="firm-filter-left">
                      <span className="flex-center-vertical">Sectors</span>
                    </div>
                    <div className="firm-filter-right">
                      <span className="flex-center-vertical">Sub Sectors</span>
                    </div>
                  </div>
                  {
                    filters?.industry_focus_sub_sectors.map(sector => (
                      <Fragment key={sector.id}>
                        <div className="firm-filter-line flex-center-vertical">
                          <div className="firm-filter-left">
                            <div className="firm-filter-item-name flex-center-vertical">{sector.name}</div>
                          </div>
                          <div className="firm-filter-right">
                            <div className="firm-filter-item-value flex-center-vertical">
                              <span>{sector.children.map(el => el.name).join(', ')}</span>
                            </div>
                          </div>
                        </div>
                        {
                          sector.additional_specification &&
                          <div className="firm-filter-line flex-center-vertical">
                            <div className="firm-filter-left">
                              <div className="firm-filter-add-spec flex-center-vertical">Additional specifications:</div>
                            </div>
                            <div className="firm-filter-right">
                              <div className="firm-filter-add-spec-value flex-center-vertical">{sector.additional_specification}</div>
                            </div>
                          </div>
                        }
                      </Fragment>
                    ))
                  }
                </div>
              </div>

              <div className="firm-filter-section">
                <div className="firm-filter-header flex-center-vertical">
                  <img src={Images.globe} alt="" />
                  <span>Geographic Focus</span>
                  <img src={Images.edit} alt="" onClick={() => handleNavigate(`/home/investor/filters-edit/${investorId}`)} />
                </div>
                <div className="firm-filter-content" style={{ paddingLeft: 40 }}>
                  <div className="firm-filter-line flex-center-vertical">
                    <div className="firm-filter-left">
                      <span className="flex-center-vertical">Continents</span>
                    </div>
                    <div className="firm-filter-right">
                      <span className="flex-center-vertical">Country</span>
                    </div>
                  </div>
                  {
                    filters?.continents.map(continent => (
                      <div className="firm-filter-line flex-center-vertical" key={continent.id}>
                        <div className="firm-filter-left">
                          <div className="firm-filter-item-name flex-center-vertical">{continent.name}</div>
                        </div>
                        <div className="firm-filter-right">
                          <div className="firm-filter-item-value flex-center-vertical">
                            <span>{continent.children.map(el => el.name).join(', ')}</span>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Lock history" key="history">
            <div className="top-btn-section">
              <Button type="primary white" className="btn-primary" onClick={sendPassword}>
                Send password
              </Button>
              <Button type={`primary${isLock ? '' : ' orange'}`} className="btn-primary" style={{ minWidth: 160 }} onClick={toggleBlock}>
                {isLock ? 'Unlock' : 'Lock'}
              </Button>
            </div>
            <div className="locked-list">
              <div className="locked-list-header">
                <div>Locked</div>
                <div>Unlocked</div>
              </div>
              <div className="locked-list-content">
                <div>
                  {
                    lockHistory && lockHistory?.locked.map(el => (
                      <div className="locked-list-item" key={el.id}>
                        <div className="locked-title">{el.initiator}</div>
                        <div className="locked-time">
                          <span>{moment(el.created).format('DD/MM/YYYY')}</span>
                          <span>{moment(el.created).format('hh:mm:ss a')}</span>
                        </div>
                      </div>
                    ))
                  }
                </div>
                <div className="unlocked">
                  {
                    lockHistory && lockHistory?.unlocked.map(el => (
                      <div className="locked-list-item" key={el.id}>
                        <div className="locked-title">{el.initiator}</div>
                        <div className="locked-time">
                          <span>{moment(el.created).format('DD/MM/YYYY')}</span>
                          <span>{moment(el.created).format('hh:mm:ss a')}</span>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Active deals" key="active-deals">
          <div className="firm-profile-block">
            <div className="table-container">
              <AdminInvestorsActiveDealsTable
                data={dataActiveDeals}
                fetching={deals.fetching}
                page={deals.page_active_deals}
                pageSize={deals.page_size}
                onChangePage={handleChangeActiveDealsTable}
                setActiveDeals={setActiveDealsList}
                // isReshare={isReshare}
                // adminStatus={adminStatus}
              />
            </div>
            </div>
          </TabPane>
          <TabPane tab="History deals" key="history-deals">
            <div className="firm-profile-block">
              <div className="table-container">
                <AdminInvestorsHistoryDealsTable
                  data={dataHistoryDeals}
                  fetching={deals.fetching}
                  page={deals.page_history_deals}
                  pageSize={deals.page_size}
                  onChangePage={handleChangeHistoryDealsTable}
                  investorId={investorId}
                />
              </div>
            </div>
          </TabPane>
        </Tabs>

        <Modal
          centered={true}
          closable={false}
          destroyOnClose={true}
          width={500}
          visible={deleteVisible}
          onOk={deleteUser}
          okText="Delete"
          okButtonProps={deleteButtonProps}
          onCancel={() => setDeleteVisible(false)}
          cancelButtonProps={cancelButtonProps}
        >
          <div className="delete-title">
            <span>Delete this user?</span>
          </div>
        </Modal>

        <Modal
          centered={true}
          closable={false}
          destroyOnClose={true}
          width={650}
          visible={sendVisible}
          onOk={sendUserInvite}
          okText="Send"
          okButtonProps={okButtonProps}
          onCancel={() => setSendVisible(false)}
          cancelButtonProps={cancelButtonProps}
        >
          <div className="modal-title">
            <span style={{ borderBottom: 'none' }}>You are sending invitation</span>
            <br />
            <span>for <b>{`${filters?.name} ${filters?.surname}`}</b></span>
          </div>
          <div className="title-block">
            <Input
              value={inviteTitle}
              onChange={e => setInviteTitle(e.target.value)}
            />
          </div>
          <div className="body-block">
            <TextArea
              rows={13}
              value={inviteBody}
              onChange={e => setInviteBody(e.target.value)}
            />
          </div>
        </Modal>

      </Spin>
    </div >
  )
}

const mapStateToProps = (state) => {
  return {
    filters: state.settings.filters,
    result: state.auth.result,
    error: state.auth.error,
    inviteDataResult: state.auth.inviteDataResult,
    admins: state.users.list,
    roles: state.users.investorRoles,
    firms: state.firms.list,
    lockHistory: state.users.lockHistory,
    userResult: state.users.result,
    userFetching: state.users.fetching,
    userDeleteResult: state.users.deleteResult,
    userDeleteFetching: state.users.deleteFetching,
    settingsFetching: state.settings.fetching,
    settingsSaveFetching: state.settings.saveFetching,
    authFetching: state.auth.fetching,
    deals: state.deals,
    advisorSharedList: state.deals.advisorSharedList,
    advisorSharedHistoryList: state.deals.advisorSharedHistoryList,
    page_active_deals: state.deals.page_active_deals,
    page_history_deals: state.deals.page_history_deals,
    page_size: state.deals.page_size,
    dealShareSpecificInvestorResult: state.deals.dealShareSpecificInvestorResult,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getFilters: (userId, role) => dispatch(SettingsActions.filtersRequest(userId, role)),
  updateFilters: (investorId, role, data) => dispatch(SettingsActions.filtersSaveRequest(investorId, role, data)),
  resetData: () => dispatch(SettingsActions.settingsReset()),

  getInviteData: (email, role) => dispatch(AuthActions.getInviteDataRequest(email, role)),
  sendInvite: (data, role) => dispatch(AuthActions.sendInviteRequest(data, role)),
  resetError: () => dispatch(AuthActions.authErrorReset()),

  getUsers: (role) => dispatch(UsersActions.usersRequest(role)),
  getRoles: () => dispatch(UsersActions.investorRolesRequest()),
  getFirms: () => dispatch(FirmsActions.firmsRequest()),
  getHistory: (userId) => dispatch(UsersActions.userLockHistoryRequest(userId)),
  toggleUserLock: (userId, action) => dispatch(UsersActions.userToggleLockRequest(userId, action)),
  deleteUser: (role, id) => dispatch(UsersActions.userDeleteRequest(role, id)),

  getActiveDealAdvisorsShared: (data, page, page_size) => dispatch(DealsActions.dealAdvisorsSharedRequest(data, page, page_size)),
  getHistoryDealAdvisorsShared: (data, page, page_size) => dispatch(DealsActions.dealAdvisorsSharedHistoryRequest(data, page, page_size)),
  shareDealWithSpecificInvestor: (investor_id, data) => dispatch(DealsActions.dealShareWithSpecificInvestorRequest(investor_id, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvestorDetailsPage)
