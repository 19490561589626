import React, { useState, useEffect } from 'react'
import { Collapse, Tabs, Checkbox, Row, Col, Input, InputNumber, message } from 'antd'
import _ from 'lodash'

import Utils from '../../Utils/utils'
import CountryGrid from '../CountryGrid'
import SectorGrid from '../SectorGrid'
import CollapseHeader from '../CollapseHeader'
import Images from '../../Images'

const { Panel } = Collapse;
const { TabPane } = Tabs;
const { TextArea } = Input;

const investment = {
  dealSize: [],
  equity_ticket: [null, null],
  equity_stake: [null, null],
  etNaActive: false,
  esNaActive: false,
  investment_type: [],
  comment: '',
  addFinReq: ''
}

const dealSizeRange = [
  '£1-£50ml',
  '£50-£550ml',
  '£550ml+'
];

function MandateSharingStart(props) {
  const { mandateData, sectorFocus, geographyFocus, investmentTypes, dealSizeList, checkRequired, isAccordion, firmOverview } = props;

  const [start, setStart] = useState(false);

  const [sectorCheckedList, setSectorCheckedList] = useState(null);
  const [sectorActiveTab, setSectorActiveTab] = useState(null);
  const [sectorOptions, setSectorOptions] = useState(null);
  const [group, setGroup] = useState(null);

  const [continentCheckedList, setContinentCheckedList] = useState(null);
  const [continentActiveTab, setContinentActiveTab] = useState(null);
  const [continentOptions, setContinentOptions] = useState(null);
  const [regionActiveTab, setRegionActiveTab] = useState(null);
  const [regionCheckAll, setRegionCheckAll] = useState(null);

  const [otherSectorText, setOtherSectorText] = useState(null);
  const [otherSectorValue, setOtherSectorValue] = useState(null);
  const [otherSectorList, setOtherSectorList] = useState(null);

  const [investmentSector, setInvestmentSector] = useState(null);
  const [investmentTypeOptions, setInvestmentTypeOptions] = useState(null);
  const [dealSizeOptions, setDealSizeOptions] = useState(null);
  const [backgroundData, setBackgroundData] = useState(null);
  const [requirementsData, setRequirementsData] = useState(null);
  const [firmProfileData, setFirmProfileData] = useState(null);

  const [required, setRequired] = useState({
    active: false,
    investment_focus: false,
    sector_focus: false,
    geographic_focus: false,
    background: false,
    add_requirements: false,
    firm_profile: false
  })

  const [filterData, setFilterData] = useState(null);

  useEffect(() => {
    setInvestmentSector(_.cloneDeep(investment));
  }, [0]);

  useEffect(() => {
    if (checkRequired) {
      checkRequiredFields();
    }
  }, [checkRequired]);

  useEffect(() => {
    if (mandateData && mandateData !== true) {
      const deal = _.cloneDeep(mandateData);
      setFilterData(deal);
    }
  }, [mandateData]);

  useEffect(() => {
    if (firmOverview) {
      setFirmProfileData(firmOverview);
    }
  }, [firmOverview]);

  useEffect(() => {
    if (filterData) {
      const investment = {
        equity_ticket: [filterData.investment_focus?.equity_ticket_from, filterData.investment_focus?.equity_ticket_to],
        equity_stake: [filterData.investment_focus?.equity_stake_from, filterData.investment_focus?.equity_stake_to],
        investment_type: filterData.investment_focus?.investment_type ? filterData.investment_focus?.investment_type.map(el => el.id) : [],
        comment: filterData.investment_focus?.comment,
        etNaActive: false,
        esNaActive: false,
        dealSize: filterData.deal_size_range,
        addFinReq: filterData.additional_financial_requirements
      };
      if (filterData.investment_focus?.equity_ticket_from == null) {
        investment.equity_ticket = [];
        investment.etNaActive = true;
      }
      if (filterData.investment_focus?.equity_stake_from == null) {
        investment.equity_stake = [];
        investment.esNaActive = true;
      }
      setInvestmentSector(investment);
      setBackgroundData(filterData.background);
      setRequirementsData(filterData.additional_requirements);
      setFirmProfileData(filterData.firm_profile);
    }
  }, [filterData]);

  useEffect(() => {
    if (sectorFocus) {
      let sectorsListInit = {};
      let otherSectorsInit = {};
      let options = {};
      sectorFocus.forEach(sector => {
        sectorsListInit[sector.name] = [];
        otherSectorsInit[sector.name] = {
          active: false,
          text: '',
          parent: sector.id
        };
        options[sector.name] = sector.children;
      });
      if (filterData) {
        filterData.industry_focus_sub_sectors.forEach(el => {
          sectorsListInit[el.name] = el.children.map(child => child.id);
          otherSectorsInit[el.name].text = el.additional_specification;
        });
        setOtherSectorList(otherSectorsInit);
        setSectorCheckedList(sectorsListInit);
        setSectorActiveTab(filterData.industry_focus_sub_sectors[0]?.name);
      } else {
        setOtherSectorList(otherSectorsInit);
        setSectorCheckedList(sectorsListInit);
        setSectorActiveTab(sectorFocus[0]?.name);
      }
      setSectorOptions(options);
    }
  }, [sectorFocus, filterData]);

  useEffect(() => {
    if (sectorActiveTab) {
      setOtherSectorValue(null);
      let text = sectorFocus.find(sector => sector.name === sectorActiveTab).other_sector;
      setOtherSectorText(text);
    }
  }, [sectorActiveTab]);

  useEffect(() => {
    if (geographyFocus) {
      let continentsListInit = {};
      let regionsInit = {};
      let regionsCountInit = {};
      let options = {};
      geographyFocus.forEach(continent => {
        continentsListInit[continent.name] = [];
        options[continent.name] = [];
        for (let i = 0; i < continent.children.length; i++) {
          continentsListInit[continent.name].push(
            {
              region: continent.children[i].name,
              countries: [],
            }
          )
          options[continent.name].push(
            { [continent.children[i].name]: [] },
          );
          regionsInit[continent.children[i].name] = {
            id: continent.children[i].id,
            value: false
          };
          regionsCountInit[continent.children[i].name] = continent.children[i].children?.length;
          ;
          if (regionsCountInit[continent.children[i].name] != null) {
            for (let j = 0; j < continent.children[i].children.length; j++) {
              options[continent.name][i][continent.children[i].name].push(
                { label: continent.children[i].children[j].name, value: continent.children[i].children[j].id },
              );
            }
          }
        }
      });
      if (filterData) {
        filterData.geography.forEach(el => {
          el.regions.forEach(region => {
            const index = continentsListInit[el.name].findIndex(item => item.region === region.name);
            continentsListInit[el.name][index].countries = region.countries.map(country => country.id);

            regionsInit[region.name]['value'] = true;

          })
        });
        setContinentCheckedList(continentsListInit);
        setRegionCheckAll(regionsInit);
      } else {
        setContinentCheckedList(continentsListInit);
        setRegionCheckAll(regionsInit);
      }
      setContinentOptions(options);
      setContinentActiveTab(geographyFocus[0]?.name);
    }
  }, [geographyFocus, filterData]);

  useEffect(() => {
    if (investmentTypes) {
      const options = [];
      for (let i = 0; i < investmentTypes.length; i++) {
        options.push(
          { label: investmentTypes[i].name, value: investmentTypes[i].id },
        );
      }
      setInvestmentTypeOptions(options);
    }
  }, [investmentTypes]);

  useEffect(() => {
    if (dealSizeList) {
      const options = [];
      for (let i = 0; i < dealSizeList.length; i++) {
        options.push(
          { label: dealSizeList[i].name, value: dealSizeList[i].id },
        );
      }
      setDealSizeOptions(options);
    }
  }, [dealSizeList]);

  useEffect(() => {
    if (sectorCheckedList && regionCheckAll && investmentSector && otherSectorList) {
      if (filterData && !start) {
        const continentId = filterData?.industry_focus_sub_sectors[0]?.id;
        const subsectors = filterData?.industry_focus_sub_sectors[0]?.children?.map(el => el.id);
        let groupId;
        sectorFocus.find(el => el.id === continentId).children.forEach(el => {
          if (el.children.some(item => item.id === subsectors[0])) {
            groupId = el.id;
          }
        })
        setGroup(groupId);
        setStart(true);
      }
      let checkSectors = Object.values(sectorCheckedList).some(el => el.length);
      let checkOtherSectorsList = Object.values(otherSectorList).filter(el => el.active).every(el => el.text);
      let checkContinents = Object.values(regionCheckAll).some(el => el.value);
      let checkInvestment =
        investmentSector.dealSize.length &&
        (investmentSector.etNaActive || Utils.checkEmptyValue(investmentSector.equity_ticket)) &&
        (investmentSector.esNaActive || (Utils.checkEmptyValue(investmentSector.equity_stake) && investmentSector.equity_stake.every(el => el > 0 && el < 101))) &&
        investmentSector.investment_type.length > 0;
      if (investmentSector.investment_type.includes(3) && !investmentSector.comment) {
        checkInvestment = false;
      }

      const newData = {
        investment_focus: checkInvestment,
        sector_focus: checkSectors && checkOtherSectorsList,
        geographic_focus: checkContinents,
        background: !!backgroundData,
        add_requirements: !!requirementsData,
        firm_profile: !!firmProfileData
      };
      setRequired({ ...required, ...newData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sectorCheckedList,
    regionCheckAll,
    investmentSector,
    otherSectorList,
    backgroundData,
    requirementsData,
    firmProfileData
  ]);

  const onChange = (list, isSector) => {
    if (isSector) {
      const newData = { ...sectorCheckedList };
      newData[sectorActiveTab] = list;
      setSectorCheckedList(newData);
    } else {
      const data = { ...continentCheckedList };
      const checkAllList = { ...regionCheckAll };
      const index = data[continentActiveTab].findIndex(el => el.region === regionActiveTab);
      data[continentActiveTab][index].countries = list;
      checkAllList[regionActiveTab]['value'] = checkAllList[regionActiveTab] || !!list.length;

      setContinentCheckedList(data);
      setRegionCheckAll(checkAllList);
    }
  };

  const onCheckAllChange = (region, e) => {
    const data = { ...continentCheckedList };
    const checkAllList = { ...regionCheckAll };

    checkAllList[region]['value'] = e.target.checked;
    const index = data[continentActiveTab].findIndex(el => el.region === region);

    if (!e.target.checked) {
      data[continentActiveTab][index].countries = [];
      setContinentCheckedList(data);
    }

    setRegionCheckAll(checkAllList);
  }

  const changeOtherSector = e => {
    const newData = { ...otherSectorList };
    newData[sectorActiveTab].active = e.target.checked;
    setOtherSectorList(newData);
  }

  const changeSpecValue = e => {
    const newData = { ...otherSectorList };
    newData[sectorActiveTab].text = e.target.value;
    setOtherSectorList(newData);
  }

  const changeInvestmentRange = (e, position, field) => {
    if (e.target.value !== '') {
      const newData = {...investmentSector};
      newData[field][position] = Number(e.target.value);
      if (typeof newData[field][1] === 'number' && newData[field][0] > newData[field][1]) {
        newData[field][0] = newData[field][1];
      }
      setInvestmentSector(newData);
    }
  }

  const changeNaActive = (e, field) => {
    const newData = { ...investmentSector };
    newData[field] = e.target.checked;
    setInvestmentSector(newData);
  }

  const changeInvestmentType = list => {
    const newData = { ...investmentSector };
    newData.investment_type = list;
    setInvestmentSector(newData);
  }

  const changeDealSize = list => {
    const newData = { ...investmentSector };
    newData.dealSize = list;
    setInvestmentSector(newData);
  }

  const changeComment = value => {
    const newData = { ...investmentSector };
    newData.comment = value;
    setInvestmentSector(newData);
  }

  const changeFinReq = value => {
    const newData = { ...investmentSector };
    newData.addFinReq = value;
    setInvestmentSector(newData);
  }

  const generateTabTitle = region => {
    return (
      <div className="tab-checkbox-block">
        <Checkbox checked={regionCheckAll[region]['value']} onChange={(e) => onCheckAllChange(region, e)}></Checkbox>
        <span>{region}</span>
      </div>
    )
  }

  const checkRequiredFields = () => {
    let checkSectors = Object.values(sectorCheckedList).some(el => el.length);
    let checkOtherSectorsList = Object.values(otherSectorList).filter(el => el.active).every(el => el.text);
    let checkContinents = Object.values(regionCheckAll).some(el => el.value);
    let checkInvestment =
        investmentSector.dealSize.length &&
        (investmentSector.etNaActive || Utils.checkEmptyValue(investmentSector.equity_ticket)) &&
        (investmentSector.esNaActive || (Utils.checkEmptyValue(investmentSector.equity_stake) && investmentSector.equity_stake.every(el => el > 0 && el < 101))) &&
        investmentSector.investment_type.length > 0;
    if (investmentSector.investment_type.includes(3) && !investmentSector.comment) {
      checkInvestment = false;
    }

    const newData = {
      active: true,
      investment_focus: checkInvestment,
      sector_focus: checkSectors && checkOtherSectorsList,
      geographic_focus: checkContinents,
      background: !!backgroundData,
      add_requirements: !!requirementsData,
      firm_profile: !!firmProfileData
    };
    setRequired({ ...required, ...newData });

    if (checkSectors && checkContinents && true && checkOtherSectorsList && checkInvestment && backgroundData && requirementsData && firmProfileData) {
      const totalData = {
        industry_focus_sub_sectors: Object.values(sectorCheckedList).flat(),
        countries: Object.values(continentCheckedList).flat().map(el => el.countries).flat(),
        regions: Object.values(regionCheckAll).filter(el => el.value).map(el => el.id),
        additional_specification: Object.entries(otherSectorList).filter(el => sectorCheckedList[el[0]].length).map(el => el[1]).filter(el => el.text).map(el => ({
          name: el.text,
          industry_focus_sector: el.parent
        })),
        investment_focus: {
          equity_ticket_from: investmentSector.etNaActive ? null : investmentSector.equity_ticket[0],
          equity_ticket_to: investmentSector.etNaActive ? null : investmentSector.equity_ticket[1],
          equity_stake_from: investmentSector.esNaActive ? null : investmentSector.equity_stake[0],
          equity_stake_to: investmentSector.esNaActive ? null : investmentSector.equity_stake[1],
          investment_type: investmentSector.investment_type,
          comment: investmentSector.investment_type.includes(3) ? investmentSector.comment : null
        },
        background: backgroundData,
        deal_size_range: investmentSector.dealSize,
        additional_financial_requirements: investmentSector.addFinReq,
        additional_requirements: requirementsData,
        firm_profile: firmProfileData,
      };
      props.onChangeStep(totalData);
    } else if (isAccordion) {
      message.error('All fields must be filled in to proceed', 3);
    } else {
      props.onChangeStep(null);
    }
  }

  const setParent = (checked, sectorName, parentId) => {
    const newData = { ...sectorCheckedList };
    if (checked) {
      if (newData[sectorName].length) {
        return;
      } else {
        setGroup(parentId);
      }
    } else {
      if (newData[sectorName].length === 1) {
        setGroup(null);
      } else {
        return;
      }
    }
  }

  const handleInput = (event) => {
    const previousLength = requirementsData?.length || 0;
    const bullet = "\u2022";
    const newLength = event.target.value.length;
    const characterCode = event.target.value.substr(-1).charCodeAt(0);
    if (newLength > previousLength) {
      if (characterCode === 10) {
        event.target.value = `${event.target.value}${bullet} `;
      } else if (newLength === 1) {
        event.target.value = `${bullet} ${event.target.value}`;
      }
    }
  }

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) =>
        isActive ?
          <img src={Images.angleUp} alt="" />
          :
          <img src={Images.angleDown} alt="" />
      }
      expandIconPosition="right"
      className="site-collapse-custom-collapse"
      accordion={isAccordion}
      defaultActiveKey={isAccordion ? [] : ['background', 'sector', 'geography', 'investment', 'add_requirements', 'firm_profile']}
    >
      <Panel
        header={<CollapseHeader text="Background" imageType="certificate" />} key="background"
        className={`site-collapse-custom-panel${(required.active && !required.background) ? ' error-block' : ''}`}
      >
        {
          mandateData &&
          <div>
            <div className="collapse-content-title">{`Provide some background context on the reason why you are actively looking for specific investment opportunities, and the type of support you are looking for.
          For instance: "we have invested in a business in sector X in Y region, which we are now looking to expand via a roll-up acquisition strategy...in order to....".
          Please include any relevant background information.`}</div>
            <div className="collapse-content-area">
              <TextArea
                defaultValue={mandateData.background}
                rows={10}
                maxLength={5000}
                onBlur={(e) => setBackgroundData(e.target.value)}
              />
            </div>
          </div>
        }
      </Panel>
      <Panel
        header={<CollapseHeader text="Sector" imageType="puzzle" />} key="sector"
        className={`site-collapse-custom-panel${(required.active && !required.sector_focus) ? ' error-block' : ''}`}
      >
        <div>
          <div className="collapse-content-title">{`Please select the relevant sector and subsectors related to the opportunities you are looking for.
          Please note: you can select only one Sector and related subsectors' groups per mandate (for instance, Transport & Logistics -> "Air" subsectors).`}</div>
          <div className="tabs-content">
            <div className="tabs-header">
              <div style={{width: 300}}>Sectors</div>
              <div style={{width: 'auto'}}>Sub Sectors</div>
            </div>
            <Tabs tabPosition="left" onChange={(key) => setSectorActiveTab(key)} activeKey={sectorActiveTab}>
              {
                sectorCheckedList && sectorFocus.map(sector => (
                  <TabPane
                    tab={<div style={{ color: (!group  || (group && sectorCheckedList[sector.name].length)) ? '#232D53' : 'gray' }}>{sector.name}</div>}
                    key={sector.name}
                  >
                    <div className="vertical-tab-line"></div>
                    <Checkbox.Group
                      value={sectorCheckedList[sector.name]}
                      onChange={(list) => onChange(list, true)}
                    >
                      <SectorGrid
                        data={sectorOptions[sector.name]}
                        setParent={setParent}
                        group={group}
                        sectorName={sector.name}
                      />
                    </Checkbox.Group>
                  </TabPane>
                ))
              }
            </Tabs>
            <div className="divider" style={{ height: 7, margin: '24px 0 16px' }}></div>
            <Row gutter={[16, 24]}>
              <Col span={12} offset={12}>
                <Checkbox
                  disabled={sectorCheckedList && sectorCheckedList[sectorActiveTab].length === 0}
                  checked={otherSectorList && otherSectorList[sectorActiveTab].active}
                  onChange={e => changeOtherSector(e)}
                >
                  {otherSectorText}
                </Checkbox>
              </Col>
            </Row>
            {
              ((sectorCheckedList && sectorCheckedList[sectorActiveTab].length > 0) || (otherSectorList && otherSectorList[sectorActiveTab].active)) &&
              <Row gutter={[16, 24]} style={{marginTop: 16}}>
                <Col span={12}>
                  <div className="add-spec">Additional sector specification</div>
                </Col>
                <Col span={12} className="flex-center-vertical">
                  <Input
                    className={otherSectorList[sectorActiveTab].active && !otherSectorList[sectorActiveTab].text ? 'error-input' : ''}
                    placeholder="Please include any additional specification"
                    maxLength={180}
                    value={otherSectorValue != null ? otherSectorValue : otherSectorList[sectorActiveTab].text}
                    onChange={e => setOtherSectorValue(e.target.value)}
                    onBlur={e => changeSpecValue(e)}
                  />
                </Col>
              </Row>
            }
          </div>
        </div>
      </Panel>
      <Panel
        header={<CollapseHeader text="Geography" imageType="globe" />} key="geography"
        className={`site-collapse-custom-panel${(required.active && !required.geographic_focus) ? ' error-block' : ''}`}
      >
        <div>
          <div className="collapse-content-title">Please indicate all the specific regions and related countries in which you would like to originate opportunities</div>
          <div className="tabs-content">
            <div className="tabs-header">
              <div>Area</div>
              <div>Region</div>
              <div>Countries</div>
            </div>
            <Tabs tabPosition="left" onChange={(key) => setContinentActiveTab(key)} activeKey={continentActiveTab}>
              {
                continentCheckedList && geographyFocus.map(continent => (
                  <TabPane tab={continent.name} key={continent.name}>
                    <div className="vertical-tab-line"></div>

                    <Tabs tabPosition="left" onChange={(key) => setRegionActiveTab(key)} activeKey={regionActiveTab}>
                      {
                        continentCheckedList[continent.name].map((el, i) => (
                          <TabPane tab={generateTabTitle(el.region)} key={el.region}>
                            <div className="vertical-tab-line"></div>
                            <Checkbox.Group
                              value={el.countries}
                              onChange={onChange}
                            >
                              <CountryGrid
                                data={continentOptions[continent.name][i][el.region]}
                                region={3}
                              />
                            </Checkbox.Group>
                          </TabPane>
                        ))
                      }
                    </Tabs>

                  </TabPane>
                ))
              }
            </Tabs>
          </div>
        </div>
      </Panel>
      <Panel
        header={<CollapseHeader text="Financial requirements" imageType="coins" />} key="investment"
        className={`site-collapse-custom-panel${(required.active && !required.investment_focus) ? ' error-block' : ''}`}
      >
        <div>
          <div className="collapse-content-title">Provide detailed information on the key financial requirements of the investment opportunities you are targeting, and add any additional or more specific information in the box below</div>
          <div className="investment-range-up">
            <div>Enterprise value (Deal size range)</div>
            <span>Please indicate the EV range of the targets sought. </span>
          </div>
          <div className="investment-range base">
            <div className="investment-range-title">
              <div>Deal size range:</div>
            </div>
            <div className="investment-range-check">
              <Checkbox.Group
                style={{ width: '100%' }}
                value={investmentSector?.dealSize}
                onChange={changeDealSize}
              >
                <Row gutter={[16, 30]}>
                  {
                    dealSizeOptions?.map((option, i) => (
                      <Col span={8} key={option.value}>
                        <Checkbox
                          value={option.value}
                        >
                          {option.label}&nbsp;
                          <span className="blue">{dealSizeRange[i]}</span>
                        </Checkbox>
                      </Col>
                    ))
                  }
                </Row>
              </Checkbox.Group>
            </div>
          </div>

          <div className="investment-range">
            <div className="investment-range-title">Equity ticket range (£)</div>
            <div className="investment-range-data">
              <div className="investment-range-label" style={{ marginLeft: 0 }}>From</div>
              <InputNumber
                min={0}
                max={99999}
                placeholder="...ml"
                parser={value => Number(value).toFixed()}
                value={investmentSector?.equity_ticket[0]}
                disabled={investmentSector?.etNaActive}
                onBlur={(e) => changeInvestmentRange(e, 0, 'equity_ticket')}
              />

              <div className="investment-range-label">To</div>
              <InputNumber
                min={0}
                max={99999}
                placeholder="...ml"
                parser={value => Number(value).toFixed()}
                value={investmentSector?.equity_ticket[1]}
                disabled={investmentSector?.etNaActive}
                onBlur={(e) => changeInvestmentRange(e, 1, 'equity_ticket')}
              />
              <div className="na-block">
                <span>or</span>
                <Checkbox
                  checked={investmentSector?.etNaActive}
                  onChange={(e) => changeNaActive(e, 'etNaActive')}
                >
                  N/A
                </Checkbox>
              </div>
            </div>
          </div>
          <div className="investment-range">
            <div className="investment-range-title">Equity stake range (%)</div>
            <div className="investment-range-data">
              <div className="investment-range-label" style={{ marginLeft: 0 }}>From</div>
              <InputNumber
                min={1}
                max={100}
                placeholder="%"
                parser={value => Number(value).toFixed()}
                value={investmentSector?.equity_stake[0]}
                disabled={investmentSector?.esNaActive}
                onBlur={(e) => changeInvestmentRange(e, 0, 'equity_stake')}
              />

              <div className="investment-range-label">To</div>
              <InputNumber
                min={1}
                max={100}
                placeholder="%"
                parser={value => Number(value).toFixed()}
                value={investmentSector?.equity_stake[1]}
                disabled={investmentSector?.esNaActive}
                onBlur={(e) => changeInvestmentRange(e, 1, 'equity_stake')}
              />
              <div className="na-block">
                <span>or</span>
                <Checkbox
                  checked={investmentSector?.esNaActive}
                  onChange={(e) => changeNaActive(e, 'esNaActive')}
                >
                  N/A
                </Checkbox>
              </div>
            </div>
          </div>

          <div className="investment-range base">
            <div className="investment-range-title">
              <div>Type of investment</div>
            </div>
            <div className="investment-range-check">
              <Checkbox.Group
                style={{ width: '100%' }}
                value={investmentSector?.investment_type}
                onChange={changeInvestmentType}
              >
                <Row gutter={[16, 30]}>
                  {
                    investmentTypeOptions?.map(option => (
                      <Col span={8} key={option.value}>
                        <Checkbox value={option.value}>{option.label}</Checkbox>
                      </Col>
                    ))
                  }
                </Row>
              </Checkbox.Group>

              {
                (investmentSector && investmentSector.investment_type.includes(3)) &&
                <div className="investment-range base" style={{ marginTop: 16, padding: 0 }}>
                  <Row gutter={[16, 0]} style={{ width: '100%' }}>
                    <Col span={24}>
                      <Input
                        className={investmentSector && investmentSector.investment_type.includes(3) && !investmentSector.comment ? 'error-input' : ''}
                        placeholder="Please Specify…"
                        maxLength={100}
                        defaultValue={investmentSector.comment}
                        onBlur={e => changeComment(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
              }
            </div>

          </div>
          {
            mandateData &&
            <div className="investment-range base" style={{ flexDirection: 'column' }}>
              <div className="investment-range-title">
                <div>Additional financial requirements</div>
                <span style={{ fontSize: 13 }}>Please list any additional financial and governance-related requirement of the opportunities you are looking for. For instance, minimum EBITDA, open to JV, etc</span>
              </div>
              <div className="comments-block">
                <TextArea
                  rows={5}
                  maxLength={5000}
                  defaultValue={mandateData?.additional_financial_requirements}
                  onBlur={e => changeFinReq(e.target.value)}
                />
              </div>
            </div>
          }
        </div>
      </Panel>
      <Panel
        header={<CollapseHeader text="Additional requirements" imageType="company" />} key="add_requirements"
        className={`site-collapse-custom-panel${(required.active && !required.add_requirements) ? ' error-block' : ''}`}
      >
        {
          mandateData &&
          <div>
            <div className="collapse-content-title">{`Please provide Senior Advisors with detailed specifications related to the opportunities you'd like to receive or not to receive (for instance, business model details or specific characteristics of the ideal targets), at which stage and to which extent your portfolio company (if any) will be involved, what is the model you intend to implement post-acquisition (merged with portco), etc.`}</div>
            <div className="collapse-content-area">
              <TextArea
                defaultValue={mandateData.additional_requirements}
                rows={11}
                maxLength={5000}
                onBlur={(e) => setRequirementsData(e.target.value)}
                onInput={e => handleInput(e)}
              />
            </div>
          </div>
        }
      </Panel>
      <Panel
        header={<CollapseHeader text="Your firm's profile" imageType="pen" />} key="firm_profile"
        className={`site-collapse-custom-panel${(required.active && !required.firm_profile) ? ' error-block' : ''}`}
      >
        {
          mandateData &&
          <div>
            <div className="collapse-content-title">{`Please note: your firm's profile below is taken directly from your firm's profile on IDN. Any edit you will make here will be applied to your IDN's profile as well.
            Make sure to sanitise your profile as this will be reviewed by the Senior Advisors on IDN's platform.`}</div>
            <div className="collapse-content-area">
              <TextArea
                defaultValue={firmProfileData || mandateData.firm_profile}
                rows={4}
                maxLength={5000}
                onBlur={(e) => setFirmProfileData(e.target.value)}
              />
            </div>
          </div>
        }
      </Panel>
    </Collapse>
  )
}

export default MandateSharingStart
