import React, { useState, useEffect } from 'react'
import { List, Button, Space, Checkbox, Spin } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import ShareDealWithColleaguesForm from '../../Components/Forms/ShareDealWithColleaguesForm'
import PopupWrapper from './PopupWrapper'
import UsersActions from '../../Redux/UsersRedux'
import MandatesActions from '../../Redux/MandatesRedux'
import Images from '../../Images'
import './PopupPage.less'

function InviteColleaguesToMandatePopupPage(props) {
  const { colleagues, result, fetching, match } = props;
  const { mandateId, tab } = match.params;

  const [data, setData] = useState(null);
  const [users, setUsers] = useState([]);
  const [active, setActive] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setData({
      existent: [],
      new: []
    });
    props.getColleagues(true);
  }, [0]);

  useEffect(() => {
    if (colleagues) {
      setUsers(colleagues.map(el => ({ id: el.id, name: el.name })));
    }
  }, [colleagues]);

  useEffect(() => {
    if (result) {
      history.push(`/home/mandate/${mandateId}/${tab}`);
    }
  }, [result]);

  const handleSubmit = formData => {
    const newData = { ...data };
    newData.new = formData;
    props.inviteColleaguesToMandate(mandateId, newData);
  }

  const changeView = () => {
    setActive(!active);
  }

  const selectUser = (value, checked) => {
    const newData = { ...data };
    if (checked) {
      newData.existent.push(value);
    } else {
      const index = newData.existent.findIndex(el => el === value);
      newData.existent.splice(index, 1);
    }
    setData(newData);
  }

  const sendInvite = () => {
    if (active) {
      return;
    } else if (data.existent.length) {
      props.inviteColleaguesToMandate(mandateId, data);
    }
  }

  return (
    <PopupWrapper
      width={420}
      path={`/home/mandate/${mandateId}/${tab}`}
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content dynamic" style={{ padding: '24px 48px' }}>
          <img src={Images.phoneOk} alt="" />
          <div className="dialog-title deal">Invite colleague(s)</div>
          <List
            itemLayout="horizontal"
            style={{ marginBottom: 24 }}
            dataSource={users}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  avatar={<img src={Images.user} alt="" />}
                  title={<span className="list-text">{item.name}</span>}
                />
                <div className="checkbox-wrap">
                  <Checkbox onChange={(e) => selectUser(item.id, e.target.checked)} />
                </div>
              </List.Item>
            )}
          />
          {
            active ?
              <ShareDealWithColleaguesForm
                onSubmit={handleSubmit}
                changeView={changeView}
              />
              :
              <Space style={{ margin: '24px 0' }}>
                <span className="link-tag underline" onClick={changeView}>Invite new Colleagues</span>
              </Space>
          }
          <div className="btn-footer" style={{ marginTop: 0 }}>
            <Button type="primary white" htmlType="submit" className="btn-primary welcome" form="share-deal-with-colleagues-form" style={{ width: 144 }} onClick={sendInvite}>
              Send Invite
          </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    colleagues: state.users.investorColleagues,
    result: state.mandates.mandateInviteColleaguesResult,
		fetching: state.mandates.fetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getColleagues: (filter) => dispatch(UsersActions.investorColleaguesRequest(filter)),
  inviteColleaguesToMandate: (mandateId, data) => dispatch(MandatesActions.mandateInviteColleaguesRequest(mandateId, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InviteColleaguesToMandatePopupPage)
