import React from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

import Images from '../../Images'
import './PopupPage.less'

function DealSubmittedPopupPage(props) {

  const history = useHistory();

  function handleClose() {
    history.push('/home/mandates');
  }

  return (
    <div className="popup-dialog">
      <div className="dialog-container" style={{ width: 420 }}>
        <div className="dialog-content" style={{ padding: '40px 36px' }}>
          <img src={Images.checkCircle} alt="" />
          <div className="dialog-title deal">Deal submitted</div>
          <div className="deal-text">Congratulations for originating this deal!</div>
          <br />
          <div className="deal-subtext">As a next step, you will be notified of the Investor's response. In the meantime, please feel free to get in touch with IDN's team in case you have any question or require any assistance.</div>
          <div className="btn-footer">
            <Button type="primary white" className="btn-primary" onClick={handleClose}>
              Continue
              </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DealSubmittedPopupPage
