import React from 'react'
import { Space, Button } from 'antd'
import { useHistory } from 'react-router-dom'

import Images from '../Images'


function UpdatesAgreedPage(props) {
  const history = useHistory();

  return (
    <div className="sign-up-step with-bg">
      <div className="with-bg-top">
        <img src={Images.logoWhite} alt="" />
      </div>
      <div className="modal-container" style={{width: 360, padding: '32px 40px'}}>
        <Space style={{marginBottom: 24}}>
          <img src={Images.phoneOk} alt="" />
        </Space>
        <div className="locked-title">Updates agreed</div>
        <div className="step-finish-text" style={{marginBottom: 0}}>You can now proceed to<br />access your dashboard</div>
        <Button type="primary" className="btn-primary" style={{ width: 217, marginTop: 40 }} onClick={()=>history.push('/home/deals')}>
          Continue
        </Button>
      </div>
    </div>
  )
}

export default UpdatesAgreedPage
