import React, { useState, useEffect } from 'react'
import { Collapse, Button, Input, Space, message, Radio, Popconfirm } from 'antd'
import _ from 'lodash'

import CollapseHeader from '../CollapseHeader'
import DncInvestorForm from '../Forms/DncInvestorForm'
import ContactInvestorForm from '../Forms/ContactInvestorForm'
import Images from '../../Images'

const { Panel } = Collapse;
const { TextArea } = Input;

const okButtonProps = {
  type: "primary red",
  size: 'small',
  className: "btn-primary",
  style: { width: 76 }
};

const cancelButtonProps = {
  type: "primary white",
  size: 'small',
  className: "btn-primary",
  style: { width: 76 }
};

const defaultQuestions = [
  {
    text: 'What is your rationale for applying and pursuing this investment opportunity?',
    active: false
  },
  {
    text: 'Can you confirm you have enough capital to pursue the opportunity?',
    active: false
  },
  {
    text: 'What is your experience investing in the sector and geography associated with this investment opportunity?',
    // text: 'What is their direct experience investing in ',
    // transform: true,
    active: false
  },
  {
    text: 'What is the average time to submit a Non-Binding Offer (NBO) and a Binding Offer (BO) for deals of this size / in this sector?',
    active: false
  },
  {
    text: 'Why the Advisor should select you as opposed to other investors?',
    active: false
  }
];

const initial = {
  active: false,
  criteria: '',
  dnc_investors: [],
  specific_investors: [],
  questions: defaultQuestions
}

function DealSharingStep4(props) {
  const { dealData, checkRequired, isAccordion } = props;

  const [start, setStart] = useState(false);
  const [data, setData] = useState(null);
  const [dnc, setDnc] = useState(null);
  const [specific, setSpecific] = useState(null);
  const [checkDnc, setCheckDnc] = useState(null);
  const [checkSpecific, setCheckSpecific] = useState(null);
  const [formCheckDnc, setFormCheckDnc] = useState(null);
  const [formCheckSpecific, setFormCheckSpecific] = useState(null);
  const [isValidate, setIsValidate] = useState({
    dnc: false,
    specific: false,
    isCheck: false
  });

  useEffect(() => {
    setData(_.cloneDeep(initial));
  }, [0]);

  useEffect(() => {
    if (dealData) {
      const deal = _.cloneDeep(dealData);
      if (isAccordion) {
        // const content = {
        //   sectors: deal.industry_focus_sub_sectors.map(el =>
        //     el.children.map(child => child.name).flat()
        //   ).flat(),
        //   geography: deal.geography.map(el =>
        //     el.regions.map(child => child.countries).flat()
        //   ).flat().map(item => item.name),
        // };
        // if (data && !start) {
        //   setStart(true);
        //   const newData = { ...data };
        //   const index = newData.questions.findIndex(el => el.transform);
        //   newData.questions[index].text += `[Sectors: ${content.sectors.join(', ')}] and/or [Countries: ${content.geography.join(', ')}]?`;
        //   setData(newData);
        // }
      } else if (dealData.investor_sought) {
        const newData = _.cloneDeep(initial);
        newData.criteria = deal.investor_sought.criteria;
        newData.dnc_investors = deal.investor_sought.dnc_investors?.map(el => ({
          id: el.id,
          firm_name: el.firm_name,
          website: el.website,
          comment: el.comment,
        }));
        newData.specific_investors = deal.investor_sought.specific_investors?.map(el => ({
          id: el.id,
          firm_name: el.firm_name,
          website: el.website,
          person_name: el.person_name,
          job: el.job,
          email: el.email,
          message: el.message,
        }));
        newData.questions = deal.investor_sought.questions?.map(el => ({
          id: el.id,
          text: el.text,
          active: false
        }))
        setData(newData);
        setDnc(!!deal.investor_sought.dnc_investors?.length);
        setSpecific(!!deal.investor_sought.specific_investors?.length);
        setFormCheckDnc(!!deal.investor_sought.dnc_investors?.length);
        setFormCheckSpecific(!!deal.investor_sought.specific_investors?.length);
      }
    }
  }, [dealData]);

  useEffect(() => {
    if (checkRequired) {
      if (!data.active) {
        setData({...data, active: true});
      }
      checkRequiredFields();
    }
  }, [checkRequired]);

  const changeArea = (field, e) => {
    const newData = { ...data };
    newData[field] = e.target.value;
    setData(newData);
  }

  useEffect(() => {
    if (dnc === false) {
      const newData = { ...data };
      newData.dnc_investors = [];
      setData(newData);
    }
  }, [dnc]);

  useEffect(() => {
    if (specific === false) {
      const newData = { ...data };
      newData.specific_investors = [];
      setData(newData);
    }
  }, [specific]);

  useEffect(() => {
    if (checkDnc != null) {
      setCheckDnc(null);
    }
  }, [checkDnc]);

  useEffect(() => {
    if (checkSpecific != null) {
      setCheckSpecific(null);
    }
  }, [checkSpecific]);

  useEffect(() => {
    if (!start) {
      setStart(true);
    } else if (isValidate.isCheck) {
      checkFn();
    }
  }, [isValidate]);

  const checkRequiredFields = () => {
    if (dnc === true || specific === true) {
      setIsValidate({
        dnc: dnc ? false : true,
        specific: specific ? false : true,
        isCheck: false
      })
      if (dnc) {
        setCheckDnc(!checkDnc);
      } else {
        setCheckSpecific(!checkSpecific);
      }
    } else {
      setIsValidate({
        dnc: true,
        specific: true,
        isCheck: true
      })
    }
  }

  const checkFn = () => {
    setIsValidate({...isValidate, isCheck: false});
    const newData = { ...data };
    const dncCheck = (dnc === false || (dnc === true && formCheckDnc));
    const specificCheck = (specific === false || (specific === true && formCheckSpecific));
    if (dncCheck && specificCheck) {
      delete newData.active;
      newData.questions = newData.questions.filter(el => el.text).map(el => ({
        id: el.id ? el.id : null,
        text: el.text
      }));
      props.onChangeStep(newData, 'investor_sought');
    } else if (isAccordion) {
      message.error('All fields must be filled in to proceed', 3);
    } else {
      message.error('All fields must be filled in to proceed', 3);
      // props.onChangeStep(null, 'investor_sought');
    }
  }

  const handleDncSubmit = formData => {
    if (formData && formData[0]) {
      const newData = { ...data };
      newData.dnc_investors = formData;
      setData(newData);
      setFormCheckDnc(true);
      setIsValidate({...isValidate, dnc: true, isCheck: specific ? false : true});
    } else {
      setFormCheckDnc(false);
      setIsValidate({...isValidate, dnc: false, isCheck: specific ? false : true});
    }
    if (specific) {
      setCheckSpecific(!checkSpecific);
    }
  }

  const handleSpecificSubmit = formData => {
    if (formData && formData[0]) {
      const newData = { ...data };
      newData.specific_investors = formData;
      setData(newData);
      setFormCheckSpecific(true);
      setIsValidate({...isValidate, specific: true, isCheck: true});
    } else {
      setFormCheckSpecific(false);
      setIsValidate({...isValidate, specific: false, isCheck: true});
    }
  }

  const setQuestionActive = index => {
    const newData = { ...data };
    newData.questions[index].active = true;
    setData(newData);
  }

  const changeQuestion = (index, value) => {
    const newData = { ...data };
    if (value) {
      newData.questions[index] = {
        id: newData.questions[index].id,
        text: value,
        active: false
      };
    } else if (newData.questions[index].text) {
      newData.questions[index].active = false;
    } else {
      newData.questions.splice(index, 1);
    }
    setData(newData);
  }

  const deleteQuestion = index => {
    const newData = { ...data };
    newData.questions.splice(index, 1);
    setData(newData);
  }

  const addQuestion = () => {
    const newData = { ...data };
    newData.questions.push({
      text: '',
      active: true
    });
    setData(newData);
  }

  return (
    (data && dealData) &&
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ?
            <img src={Images.angleUp} alt="" />
            :
            <img src={Images.angleDown} alt="" />
        }
        expandIconPosition="right"
        className="site-collapse-custom-collapse"
        accordion={isAccordion}
        defaultActiveKey={isAccordion ?  [] : ['criteria', 'dnc_investors', 'specific_investors', 'questions']}
      >
        <Panel
          header={<CollapseHeader text="Preferred Investor's Criteria" imageType="check" />} key="criteria"
          className="site-collapse-custom-panel"
        >
          <div>
            <div className="collapse-content-title">Please indicate what are the ideal characteristics of the investor’s sought (if any)</div>
            <div className="collapse-content-area">
              <TextArea
                placeholder="For instance, you might be looking for a hands-on/hands-off investor, experienced in investing in Sector X, country Y, etc"
                defaultValue={dealData.investor_sought?.criteria}
                rows={10}
                maxLength={5000}
                onBlur={(e) => changeArea('criteria', e)}
              />
            </div>
          </div>
        </Panel>

        <Panel
          header={<CollapseHeader text="Do Not Contact Investors (DNC)" imageType="crossOutAddressBook" />} key="dnc_investors"
          className={`site-collapse-custom-panel${(data.active && (dnc == null || (dnc === true && !formCheckDnc))) ? ' error-block' : ''}`}
        >
          <div>
            <div className="collapse-content-title">Do you want us NOT to forward your deal opportunity to any specific investor?</div>
            <Space className="radio-group-space" style={{ marginBottom: 24 }}>
              <Radio.Group
                value={dnc}
                onChange={e => setDnc(e.target.value)}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Space>
            {
              dnc &&
              <DncInvestorForm
                defaultData={data.dnc_investors}
                checkForm={checkDnc}
                onSubmit={handleDncSubmit}
              />
            }
          </div>
        </Panel>

        <Panel
          header={<CollapseHeader text="Contact Specific Investors" imageType="addressBook" />} key="specific_investors"
          className={`site-collapse-custom-panel${(data.active && (specific == null || (specific === true && !formCheckSpecific))) ? ' error-block' : ''}`}
        >
          <div>
            <div className="collapse-content-title">Do you want us to forward your deal opportunity only to a specific investor(s)?</div>
            <Space className="radio-group-space" style={{ marginBottom: 24 }}>
              <Radio.Group
                value={specific}
                onChange={e => setSpecific(e.target.value)}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Space>
            {
              specific &&
              <ContactInvestorForm
                defaultData={data.specific_investors}
                checkForm={checkSpecific}
                onSubmit={handleSpecificSubmit}
              />
            }
          </div>
        </Panel>

        <Panel
          header={<CollapseHeader text="Investors’ Screening Questionnaire" imageType="question" />} key="questions"
          className="site-collapse-custom-panel"
        >
          <div>
            <div className="collapse-content-title">By default, when matching a new deal opportunity investors will have to answer the questions below, which will be your main tool for your screening and selecting investors. Feel free to include or exclude any additional screening question</div>
            <div className="question-block">
              {
                data.questions?.map((question, i) => (
                  <div className="question-item" key={i}>
                    {
                      question.active ?
                        <Input
                          defaultValue={question.text}
                          maxLength={150}
                          onBlur={e => changeQuestion(i, e.target.value)}
                        />
                        :
                        <div className="question-text">{question.text}</div>
                    }
                    <div className="question-action">
                      <img src={Images.edit} alt="" onClick={() => setQuestionActive(i)} />
                      <Popconfirm
                        title="Are you sure you want to delete the question?"
                        onConfirm={() => deleteQuestion(i)}
                        okText="yes"
                        okButtonProps={okButtonProps}
                        cancelText="no"
                        cancelButtonProps={cancelButtonProps}
                      >
                        <img src={Images.close} alt="" />
                      </Popconfirm>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="btn-footer invite">
              <Button type="primary white" className="btn-primary" disabled={data?.questions?.length > 9} style={{ width: 217 }} onClick={addQuestion}>
                Add Question
                </Button>
            </div>
          </div>
        </Panel>
      </Collapse>
  )
}

export default DealSharingStep4
