import React, { useEffect } from 'react'
import { Progress } from 'antd'
import { useHistory } from 'react-router-dom'

import SignUpForm from '../Forms/SignUpForm'
import Images from '../../Images'


function AdvisorSignUpStep1(props) {
  const {preview, countries, geographyFocus} = props;

  const history = useHistory();

  useEffect(()=>{
    //props.getCountries();
    //props.getContinents();
  }, [0]);

  return (
    <div className="sign-up-step step-1">
      <img src={Images.logo} alt="" onClick={()=>history.push('/')} />
      <div className="modal-container" style={{width: 650, padding: '48px 64px'}}>
        <div className="step-number">Step 1/2</div>
        <div className="step-title">Sign up</div>
        <Progress
          percent={50}
          showInfo={false}
          strokeWidth={13}
          trailColor="rgba(31, 73, 228, 0.12)"
          strokeColor="#3680F6"
          status="active"
        />
        <div className="step-text">As a first step of the sign up process,<br />please insert your details and set your password</div>
        <SignUpForm
          preview={preview}
          countries={countries}
          geographyFocus={geographyFocus}
          onSubmit={props.onChangeStep}
        />
      </div>
    </div>
  )
}

export default AdvisorSignUpStep1
