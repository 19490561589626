import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, message } from 'antd'
import { useHistory } from 'react-router-dom'

import ExpertConsultActions from '../../../Redux/ExpertConsultRedux'
import DealDashboardActions from '../../../Redux/DealDashboardRedux'

import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function AgreementRecievedPopupPage(props) {
  const { dashboardInfo, expertConsultInfo, result, match, location } = props;
  const { dashboardId } = match.params;
  const { state } = location;

  const history = useHistory();

  const data = {
    is_viewed: true,
  };

  const [download, setDownload] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (result) {
      props.getDealDashboardInfo(dashboardId)
      history.push({ pathname: `/deal-dashboard/overview/${dashboardId}` })
    }
  }, [result])

  const onContinue = () => {
    if (state?.isHistory) {
      history.push(`/deal-dashboard/overview/${dashboardId}`);
      return;
    }
    if (download) {
      props.notificationViewedRequest(state?.notification, dashboardId, data)
    } else {
      setIsError(true);
      message.error("You should download the file", 3);
    }
  }

  const downloadFile = () => {
    setDownload(true);
    setIsError(false);
  }

  return (
    <PopupWrapper
      width={420}
      path={state?.isHistory && `/deal-dashboard/overview/${dashboardId}`}
      hasDropdown={true}
    >
      <div className="dialog-content" style={{ padding: 30, paddingTop: 20, paddingBottom: 30 }}>
        <img src={Images.phoneOk} alt="" />
        <div className="dialog-title bold" style={{ margin: 6 }}>Agreement received</div>
        <div className="deal-text" style={{ marginTop: 14 }}>
          The Investor has shared a Consulting <br /> Agreement to engage you for paid due <br /> diligence or related support. Please review it carefully and sign it if you wish to proceed.
          <a
            className={isError ? 'download-link red' : 'download-link'}
            target="_blank" rel="noopener noreferrer"
            onClick={downloadFile}
            href={state?.add_info?.file}
            style={{ margin: 'auto', marginTop: 16 }}
          >
            <img src={Images.download} alt="" />
          </a>
          <br />
          <br />
          <div className="deal-text" style={{ fontSize: 10, marginTop: -16 }}>Please review IDN's Platform Agreement to remember your <br /> rights and obligations in the context of consulting work</div>
        </div>
        <div className="btn-footer" style={{ marginTop: 0 }}>
          <Button type="primary white"
            className="btn-primary flat"
            style={{ width: 144, marginTop: 30 }}
            onClick={onContinue}
          >
            {state?.isHistory ? 'Go back' : 'Continue'}
        </Button>
        </div>
      </div>
    </PopupWrapper>
  )
}

const mapStateToProps = state => {
  return {
    dashboardInfo: state.dealDashboard.dashboardInfo,
    result: state.expertConsult.result,
  }
}

const mapDispatchToProps = dispatch => ({
  notificationViewedRequest: (notifId, dashboardId, data) => dispatch(ExpertConsultActions.notificationViewedRequest(notifId, dashboardId, data)),
  getDealDashboardInfo: dashboardId => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AgreementRecievedPopupPage)
