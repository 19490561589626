import React from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

import PopupWrapper from './PopupWrapper'
import Images from '../../Images'
import './PopupPage.less'

function MatchComfirmedInvestorPopupPage(props) {

  const history = useHistory();

  return (
    <PopupWrapper
      width={500}
      path={`/home/deal-disclose-company-investor`}
    >
      <div className="dialog-content" style={{ padding: 40 }}>
        <img src={Images.phoneOk} alt="" />
        <div className="dialog-title deal">Match Confirmed</div>
        <div className="deal-text">Congratulations, the Senior Advisor has reviewed<br />your application and selected your firm to discuss<br />this investment opportunity.</div>
        <br />
        <br />
        <div className="deal-subtext">As a next step, the name of the target company<br />(if applicable) and of the Senior Advisor will be disclosed.</div>
        <br />
        <br />
        <div className="deal-text sm" style={{width: '90%'}}>Please review IDN's Platform Agreement to remember your<br />rights and obligations in the context of this engagement</div>
        <div className="btn-footer">
          <Button type="primary white" className="btn-primary" onClick={() => history.push('/home/deal-disclose-company-investor')}>
            Continue
            </Button>
        </div>
      </div>
    </PopupWrapper>
  )
}

export default MatchComfirmedInvestorPopupPage
