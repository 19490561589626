import React, { useState, useEffect } from 'react'
import {Form, Input, Button, Row, Col, Space, Checkbox, message, Select, TreeSelect} from 'antd'
import Utils from '../../Utils/utils'

import Images from '../../Images'

const { Option } = Select;

function SignUpForm(props) {
  const {preview, countries, geographyFocus} = props;

  const [active, setActive] = useState(false);
  //const [countryList, setCountryList] = useState([]);
  //const [tree, setTree] = useState(null);

  const [form] = Form.useForm();

  // useEffect(()=>{
  //   if (countries) {
  //     const res = [];
  //     for (let i = 0; i < countries.length; i++) {
  //       res.push(<Option key={countries[i].id}>{countries[i].name}</Option>);
  //     }
  //     setCountryList(res);
  //   }
  // }, [countries]);

  // useEffect(() => {
  //   if (geographyFocus) {
  //     const tree = geographyFocus.map(continent => ({
  //       title: continent.name,
  //       value: continent.id,
  //       children: continent.children.map(child => ({
  //         title: child.name,
  //         value: child.id,
  //       }))
  //     }));
  //     setTree(tree);
  //   }
  // }, [geographyFocus]);

  useEffect(() => {
    if (preview) {
      form.setFieldsValue({
        name: preview.name,
        surname: preview.surname,
        linkedin_url: preview.linkedin_url,
        phone: preview.phone,
        email: preview.email,
      })
    }
  }, [preview])

  const onFinish = values => {
    if (!active) {
      message.error("You should agree to IDN's Terms of Use & Privacy Policy", 3);
    } else {
      const data = {...values};
      delete data.confirm;
      props.onSubmit(data);
    }
  }

  return (
    <Form form={form} onFinish={onFinish} className="login-form" layout="vertical">

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="name"
            validateTrigger="onBlur"
            rules={[{required: true, message: 'This field is required'}]}
          >
            <Input
              placeholder="Name"
              maxLength={100}
              prefix={<img src={Images.user} alt="" />}
              suffix={'*'}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="surname"
            validateTrigger="onBlur"
            rules={[{required: true, message: 'This field is required'}]}
          >
            <Input
              placeholder="Surname"
              maxLength={100}
              prefix={<img src={Images.user} alt="" />}
              suffix={'*'}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="linkedin_url"
        validateTrigger="onBlur"
        rules={[
          {required: true, message: 'This field is required'},
          {pattern: /linkedin.com/i, message: 'Field must contain "linkedin.com"'},
          { min: 12, message: 'Field must contain at least 12 characters' },
        ]}
      >
        <Input
          placeholder="URL Linkedin Profile"
          maxLength={100}
          prefix={<img src={Images.linkedin} alt="" />}
          suffix={'*'}
        />
      </Form.Item>

      {/* <Form.Item
        name="location"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <div className="custom-select">
          <span className="flex-center-horizontal">
            <img src={Images.globeSm} alt="" />
          </span>
          <Select
            showSearch
            optionFilterProp="children"
            style={{ width: '100%' }}
            placeholder="Location"
            onChange={(e)=>form.setFieldsValue({ location: e })}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {countryList}
          </Select>
          <TreeSelect
            showSearch
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={tree}
            placeholder="Location"
            treeDefaultExpandAll
            onChange={value => form.setFieldsValue({ location: value })}
          />
        </div>
      </Form.Item> */}

      <Form.Item
        name="phone"
        validateTrigger="onBlur"
        rules={[
          {required: true, message: 'This field is required'},
          {pattern: /^\+?[0-9]{7,14}$/, message: 'Invalid format'},
          { min: 7, message: 'Field must contain at least 7 characters' },
        ]}
      >
        <Input
          placeholder="+447911123456"
          type="tel"
          maxLength={20}
          prefix={<img src={Images.mobile} alt="" />}
          suffix={'*'}
        />
      </Form.Item>

      <div className="separator"></div>

      <Form.Item
        name="email"
        validateTrigger="onBlur"
        rules={[
          {required: true, message: "This field is required"},
          { type: 'email', message: 'Invalid email format' },
          { min: 5, message: 'Field must contain at least 5 characters' },
        ]}
        onKeyPress={e=>{if(e.charCode === 32) e.preventDefault()}}
        onBlur={e=>Utils.clearSpaces(e, 'email', form.setFieldsValue)}
      >
        <Input
          placeholder="Email (same as username)"
          maxLength={100}
          prefix={<img src={Images.envelope} alt="" />}
          suffix={'*'}
        />
      </Form.Item>

      <Form.Item
        name="password"
        validateTrigger="onBlur"
        rules={[
          {required: true, message: "This field is required"},
          {pattern: /^[a-zA-Z0-9!@#$]+$/, message: 'Invalid character'},
          { min: 8, message: 'Field must contain at least 8 characters' },
        ]}
      >
        <Input.Password
          placeholder="Password"
          maxLength={100}
          autoComplete="new-password"
          prefix={<img src={Images.lock} alt="" />}
          suffix={'*'}
        />
      </Form.Item>

      <Form.Item
        dependencies={['password']}
        name="confirm"
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: "This field is required",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('The passwords don’t match');
            },
          }),
        ]}
      >
        <Input.Password
          placeholder="Re-type password"
          maxLength={100}
          autoComplete="new-password"
          prefix={<img src={Images.lock} alt="" />}
          suffix={'*'}
        />
      </Form.Item>

      <Space className="terms-conditions-bottom links">
        <Checkbox onChange={(e)=>setActive(e.target.checked)}>I confirm I have read and agree to IDN's<br /><a href="/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a> & <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></Checkbox>
      </Space>

      <Button type="primary" htmlType="submit" className="btn-primary" style={{width: 217, marginTop: 32}}>
        Next
      </Button>

    </Form>
  )
}

export default SignUpForm
