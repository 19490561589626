import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Radio, message, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function DisengagePopupPage(props) {
  const { result, fetching, match, isAdmin } = props;
  const { dashboardId } = match.params;

  const history = useHistory();

  const [value, setValue] = useState(null)

  useEffect(() => {
    props.dealDashboardSyncToggle(false);
    return function cleanup() {
      props.dealDashboardSyncToggle(true);
    }
  }, [0]);

  useEffect(() => {
    if (result) {
			props.getDealDashboardInfo(dashboardId);
			isAdmin ?
				history.push(`/home/match-company/${dashboardId}`)
			:
				history.push(`/deal-dashboard/overview/${dashboardId}`);
    }
  }, [result]);

  function onChange(e) {
    setValue(e.target.value)
  }

  const checkRequired = () => {
    if (!value) {
      message.error('Make a choice', 3);
    } else {
      const data = {
        part: value
      };
      props.proceedAdvisorParties(dashboardId, data);
    }
  }

  return (
    <PopupWrapper
      width={460}
      path={isAdmin ?
				`/home/match-company/${dashboardId}`
				:
				`/deal-dashboard/overview/${dashboardId}`
			}
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content" style={{ padding: 40, paddingTop: 20, paddingBottom: 30 }}>
          <img src={Images.phoneCancel} alt="" style={{ marginTop: 8 }} />
          <div className="dialog-title welcome" style={{ margin: 6 }}>Disengage</div>
          <div className="deal-text" style={{ marginTop: 14 }}>Per our Terms and Conditions, you can disengage <br /> only if one of the parties has terminated the <br /> negotiations on this deal opportunity. <br /> Please confirm which party has terminated the negotiations:</div>
          <div className="checkbox-wrapper">
            <Radio.Group
              onChange={onChange}
            >
              <Radio value="Investor" className="radio">Investor</Radio>
              <Radio value="Target Company" className="radio">Target Company</Radio>
            </Radio.Group>
          </div>
          <div className="deal-subtext intro" style={{ marginTop: 14, marginBottom: 0, width: 400 }}>Please note that, before officially terminating the <br /> engagement, the Investor will have to provide confirmation <br /> that the negotiations have effectively been terminated <br /> within the next three business days. You shall not share this <br /> deal with any third party and respect any related provisions <br /> of our Terms and Conditions until confirmation is received.</div>
          <div className="btn-footer">
            <Button type="primary white"
              className="btn-primary welcome"
              style={{ width: 144 }}
              onClick={checkRequired}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    result: state.dealDashboard.result,
    fetching: state.dealDashboard.fetching
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
  proceedAdvisorParties: (dashboardId, data) => dispatch(DealDashboardActions.advisorProceedPartiesRequest(dashboardId, data)),
  dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DisengagePopupPage)
