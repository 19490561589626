import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import './PopupPage.less'

function PopupWrapper(props) {
  const { width, path, hasDropdown, children } = props;

  const [target, setTarget] = useState(null);

  const history = useHistory();

  useEffect(() => {
    const popup = document.querySelector('.dialog-container');
    setTarget(popup);
  }, [0]);

  const closePopup = e => {
    const elPath = e.nativeEvent?.path || composedPath(e.target);
    if (!hasDropdown) {
      if (!elPath.includes(target)) {
        history.push(path);
      }
    } else {
      const selectList = document.querySelectorAll('.ant-select-dropdown');
      const pickerList = document.querySelectorAll('.ant-picker-dropdown');
      let check = Array.from(selectList).some(el => elPath.includes(el)) || Array.from(pickerList).some(el => elPath.includes(el));

      if (!elPath.includes(target) && !check) {
        history.push(path);
      }
    }
  }

  function composedPath(el) {
    const path = [];
    while (el) {
      path.push(el);
      if (el.tagName === 'HTML') {
          path.push(document);
          path.push(window);
          return path;
      }
      el = el.parentElement;
    }
  }

  return (
    <div className="popup-dialog" onClick={e => closePopup(e)}>
      <div className="dialog-container" style={{ width }}>
        {children}
      </div>
    </div>
  )
}

export default PopupWrapper
