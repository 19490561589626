import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Space, Checkbox, message } from 'antd'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import PopupWrapper from './PopupWrapper'

import SettingsActions from '../../Redux/SettingsRedux'
import AuthActions from '../../Redux/AuthRedux'
import Images from '../../Images'

function PlatformAgreementPopupPage(props) {
  const { user, linkResult, filters, confirmAgreementResult, match, location } = props;
  const { state } = location;

  const [active, setActive] = useState(false);
  const [start, setStart] = useState(false);
  const [link, setLink] = useState(null);

  const history = useHistory();

  useEffect(() => {
    props.getAgreementLink(user.id);
  }, [0]);

  useEffect(() => {
    if (linkResult) {
      setLink(linkResult.url);
      props.getFilters(user?.id, 'advisor');
    }
  }, [linkResult]);


  useEffect(() => {
    if (confirmAgreementResult) {
      props.updatePlatformAgreement({ ...user, signed_platform_agreement: true });
    }
  }, [confirmAgreementResult]);

  useEffect(() => {
    if (user) {
      if (!start) {
        setStart(true);
        return;
      }
      state?.isMandate ?
        state?.allMandate ?
          history.push({
            pathname: `/home/mandate-deal-sharing/step/start`,
            state: { mandateId: state?.mandateId }
          })
          :
          history.push({
            pathname: `/home/mandate-deal-sharing/step/start`,
            state: { mandateShareId: state?.mandateShareId }
          })
        :
        // history.push('/home/deal-sharing/step/profile');
        history.push({
          pathname: `/home/deal-sharing/step/profile`,
          state: { step_4_submited: true }
        })
    }
  }, [user]);

  const onSubmit = () => {
    if (active) {
      props.confirmAgreementByAdvisor(user?.id);
    } else {
      message.error("You should agree to IDN's Platform Agreement", 3);
    }
  }

  const navigateBack = () => {
    state?.isMandate ?
      state?.allMandate ?
        history.push(`/home/mandate-share-info-all/${state?.mandateId}`)
        :
        history.push(`/home/mandate-share-info/${state?.mandateShareId}${state?.tab ? '/' + state?.tab : ''}`)
      :
      history.push(`/home/deals${state?.tab ? '/' + state?.tab : ''}`)
  }

  return (
    <PopupWrapper
      width={'100%'}
      // path={backPath}
    >
    <div className="sign-up-step white p-a">
      <img src={Images.logo} alt="" onClick={navigateBack} />
      <div className="t-cs-container">
        <div className="step-title">Platform Agreement</div>
        <div className="terms-conditions" style={{ flex: 1 }}>
          <iframe src={link} width="100%" height="100%" style={{display: 'flex'}} title="Platform Agreement"></iframe>
        </div>
        <Space className="terms-conditions-bottom">
          <Checkbox onChange={(e) => setActive(e.target.checked)}>{`I, ${filters?.name} ${filters?.surname}, hereby confirm that I agree to IDN's Platform Agreement`}</Checkbox>
        </Space>
        <Space className="terms-conditions-bottom">
          <span><b>Date:</b></span>
          <span>{moment().format('DD/MM/YYYY')}</span>
        </Space>
        <Button type="primary" className="btn-primary" style={{ width: 217, margin: '0 32px' }} onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </div>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    linkResult: state.auth.advisorAgreementLinkResult,
    filters: state.settings.filters,
    confirmAgreementResult: state.settings.confirmAgreementResult,
  }
}

const mapDispatchToProps = (dispatch) => ({
  confirmAgreementByAdvisor: (advisorId) => dispatch(SettingsActions.advisorConfirmAgreementRequest(advisorId)),
  getFilters: (userId, role) => dispatch(SettingsActions.filtersRequest(userId, role)),

  updatePlatformAgreement: (userInfo) => dispatch(AuthActions.updatePlatformAgreement(userInfo)),
  getAgreementLink: (advisorId) => dispatch(AuthActions.advisorAgreementLinkRequest(advisorId)),

})

export default connect(mapStateToProps, mapDispatchToProps)(PlatformAgreementPopupPage)
