import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Button, Checkbox, message, Space} from 'antd';
import {useHistory, Link} from 'react-router-dom';
import moment from 'moment';

import ExpertConsultActions from '../../Redux/ExpertConsultRedux';

import Images from '../../Images';
import './ECAgreement.less';

function ReviewShareAgreement(props) {
	const {role, expertConsultInfo, dashboardInfo, agreementData, editAgreement, match, location} = props;
	const {dashboardId} = match.params;
	const {state} = location;

	const [active, setActive] = useState(false);
	const [redBorder, setRedBorder] = useState(false);
	const [consultId, setConsultId] = useState(null);

	const history = useHistory();

	useEffect(() => {
		if (!state?.ec) {
			history.push(`/deal-dashboard/overview/${dashboardId}`);
		}
		props.getDataForAgreementRequest(dashboardId);
	}, [0]);

	useEffect(() => {
		if (expertConsultInfo) {
			setConsultId(expertConsultInfo.id);
		}
	}, [expertConsultInfo]);

	const onSubmit = () => {
		if (active) {
			setRedBorder(false);
			if (role === 2) {
				if (editAgreement) {
					if (
						dashboardInfo?.actions?.filter(el => el.location_obj?.id === 1).map(el => el.text_obj?.id === 18)[0] ||
						dashboardInfo?.actions?.filter(el => el.location_obj?.id === 1).map(el => el.text_obj?.id === 19)[0]
					) {
						props.investorEditExtensionRequest(consultId, dashboardId);
						props.setEditAgreemenFalse();
					} else {
						props.investorEditAgreement(consultId, dashboardId);
						props.setEditAgreementFalse();
					}
				} else {
					if (
						dashboardInfo?.actions?.filter(el => el.location_obj?.id === 1).map(el => el.text_obj?.id === 18)[0] ||
						dashboardInfo?.actions?.filter(el => el.location_obj?.id === 1).map(el => el.text_obj?.id === 19)[0]
					) {
						props.investorExtendAgreementRequest(consultId, dashboardId);
					} else {
						props.investorAngageAdvisorRequest(consultId, dashboardId);
					}
				}
			}
			if (role === 0) {
				if (dashboardInfo?.actions?.filter(el => el.location_obj?.id === 1).map(el => el.text_obj?.id === 20)[0]) {
					props.advisorSignExtensionRequest(consultId, dashboardId);
				} else {
					props.advisorSignedAgreement(consultId, dashboardId);
				}
			}
		} else {
			message.error('You have missing field - check the box to confirm the agreement', 3);
			setRedBorder(true);
		}
	};

	const onEditAgreement = () => {
		history.push({pathname:`/deal-dashboard/${dashboardId}/expert-consulting-agreement`, state: {ec: true}});
	};

	return (
		<div className='sign-up-step white filters-edit'>
			<div className='absolute-header'>
				<div className='logo-block'>
					<img src={Images.logoWhite} alt='' />
				</div>
				<div className='title-block'></div>
			</div>
			<div className='return-block'>
				<Link to={`/deal-dashboard/overview/${dashboardId}`}>
					<img src={Images.arrowLeft} alt='' />
					<span>Return to Dashboard</span>
				</Link>
			</div>
			<div className='step-investor-content' style={{width: 1350}}>
				<div className='step-number underline'>
					{dashboardInfo?.actions?.filter(el => el.location_obj?.id === 1).map(el => el.text_obj?.id === 19)[0] ||
					dashboardInfo?.actions?.filter(el => el.location_obj?.id === 1).map(el => el.text_obj?.id === 18)[0] ||
					dashboardInfo?.actions?.filter(el => el.location_obj?.id === 1).map(el => el.text_obj?.id === 20)[0]
						? 'Expert Consulting Agreement - Extension'
						: 'Expert Consulting Agreement'}
				</div>

				{role === 2
					?
					// !dashboardInfo?.actions?.filter(el => el.location_obj?.id === 1).map(el => el.text_obj?.id === 18)[0] &&
					 (
							<div className='edit-agreement' onClick={onEditAgreement}>
								Edit Agreement
								<img src={Images.pencil} alt='' style={{margin: 10, width: 22, marginTop: -2}} />
							</div>
					  )
					: null}

				{role === 0 && (
					<div className='agreement-review-title'>
						<div>Please review carefully the below Expert Consulting Agreement before signing it.</div>
						In case you have any question or doubt about the terms set herein, please get in touch directly with the Investor to discuss and
						clarify.
					</div>
				)}

				<div className='agreement-wrapper'>
					<div className='agreement-text'>
						<iframe
							className='iframePdf'
							title='docPDF'
							src={agreementData && agreementData[0]?.file}
							style={{borderRadius: 15}}
							frameBorder='0'
						/>
					</div>
				</div>
				{/* <img src={Images.pdf} alt='' className='downloadPDF' /> */}
				<Space className='terms-conditions-bottom appendix' style={{marginTop: 20}}>
					<Checkbox
						onChange={e => {
							setActive(e.target.checked);
							setRedBorder(false);
						}}
						className={!redBorder ? '' : 'error'}
					>
						{role === 0 &&
							`I, ${agreementData && agreementData[0]?.advisor_name} ${agreementData && agreementData[0]?.advisor_surname},
            hereby agree to this Expert Consulting Agreement`}
						{role === 2 &&
							`I, ${agreementData && agreementData[0]?.investor_name} ${agreementData && agreementData[0]?.investor_surname},
            hereby confirm that I agree to engage ${agreementData && agreementData[0]?.advisor_name} ${
								agreementData && agreementData[0]?.advisor_surname
							}`}
						<span style={{fontSize: 12, marginLeft: 12}}>{moment().format('DD/MM/YYYY')}</span>
					</Checkbox>
				</Space>
			</div>

			<Button type='primary' className='btn-primary' style={{width: 217, marginTop: 24}} onClick={onSubmit}>
				{role === 2 && 'Confirm & Share'}
				{role === 0 && 'Confirm'}
			</Button>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		dashboardInfo: state.dealDashboard.dashboardInfo,
		role: state.auth.role,
		expertConsultInfo: state.expertConsult.expertConsultInfo,
		agreementData: state.expertConsult.agreementData,
		editAgreement: state.expertConsult.editAgreement,
	};
};

const mapDispatchToProps = dispatch => ({
	investorEditAgreement: (consultId, dashboardId) => dispatch(ExpertConsultActions.investorEditAgreementRequest(consultId, dashboardId)),
	investorEditExtensionRequest: (consultId, dashboardId) =>
		dispatch(ExpertConsultActions.investorEditExtensionRequest(consultId, dashboardId)),
	investorExtendAgreementRequest: (consultId, dashboardId) =>
		dispatch(ExpertConsultActions.investorExtendAgreementRequest(consultId, dashboardId)),
	advisorSignedAgreement: (consultId, dashboardId) => dispatch(ExpertConsultActions.advisorSignAgreementRequest(consultId, dashboardId)),
	advisorSignExtensionRequest: (consultId, dashboardId) =>
		dispatch(ExpertConsultActions.advisorSignExtensionRequest(consultId, dashboardId)),
	getAgreementRequest: (consultId, dashboardId) => dispatch(ExpertConsultActions.getAgreementRequest(consultId, dashboardId)),
	getDataForAgreementRequest: dashboardId => dispatch(ExpertConsultActions.getDataForAgreementRequest(dashboardId)),
	investorAngageAdvisorRequest: (consultId, dashboardId) =>
		dispatch(ExpertConsultActions.investorAngageAdvisorRequest(consultId, dashboardId)),
	setEditAgreementFalse: () => dispatch(ExpertConsultActions.setEditAgreementFalse()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewShareAgreement);
