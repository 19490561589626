import React, { useState, useEffect } from 'react'
import { Button, Spin, message } from 'antd'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import PopupWrapper from './PopupWrapper'
import Images from '../../Images'
import DealSharesActions from '../../Redux/DealSharesRedux'
import './PopupPage.less'

function ApplicationUnsuccessfulPopupPage(props) {
  const { result, fetching, match } = props;
  const { shareId, tab } = match.params;

  const history = useHistory();

  useEffect(() => {
    if (result) {
      history.push('/home/deals');
    }
  }, [result]);

  return (
    <PopupWrapper
      width={420}
      path={`/home/deal-share-info/${shareId}/${tab}`}
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content" style={{ padding: '24px 40px' }}>
          <img src={Images.phoneCancel} alt="" />
          <div className="dialog-title deal">Application unsuccessful</div>
          <div className="deal-text">We are sorry to inform you that the Senior Advisor has selected another investor. We will notify you if the Senior Advisor re-shares the opportunity at a later stage.</div>
          <div className="btn-footer" style={{ marginTop: 24 }}>
            <Button type="primary white" className="btn-primary" onClick={() => props.changeDealShareStatus(shareId, {investor_status: 'closed '})}>
              Continue
          </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    result: state.dealShares.statusResult,
    fetching: state.dealShares.fetching
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeDealShareStatus: (id, status) => dispatch(DealSharesActions.dealShareChangeStatusRequest(id, status)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationUnsuccessfulPopupPage)
