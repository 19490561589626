import React, { useState, useEffect } from 'react'
import { Space } from 'antd'

import Images from '../../Images'


function FaqsPage(props) {

  return (
    <div className="main-container">
      <div className="section-title">FAQs</div>
      <div className="section-content info" style={{maxWidth: 1125, justifyContent: 'center', margin: 'auto 0'}}>FAQ coming soon</div>
    </div>
  )
}

export default FaqsPage
