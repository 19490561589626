import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function NBOAcceptedAdvisorPopupPage(props) {
  const { result, fetching, match, location } = props;
  const { dashboardId } = match.params;
  const { state } = location;

  const history = useHistory();

  useEffect(() => {
    props.dealDashboardSyncToggle(false);
    return function cleanup() {
      props.dealDashboardSyncToggle(true);
    }
  }, [0]);

  useEffect(() => {
    if (result) {
      props.getDealDashboardInfo(dashboardId);
      history.push(`/deal-dashboard/overview/${dashboardId}`);
    }
  }, [result]);

  const removeNotification = () => {
    if (state?.isHistory) {
      history.push(`/deal-dashboard/overview/${dashboardId}`);
    } else {
      props.changeNotificationStatus(state?.notification)
    }
  }

  return (
    <PopupWrapper
      width={420}
      path={`/deal-dashboard/overview/${dashboardId}`}
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content" style={{ padding: 40, paddingTop: 20, paddingBottom: 30 }}>
          <img src={Images.phoneOk} alt="" />
          <div className="dialog-title welcome" style={{ margin: 6 }}>NBO Accepted</div>
          <div className="deal-text engagment" style={{ marginTop: 14 }}>The Investor has informed us that an NBO <br /> sent to the Target has been accepted</div>
          <div className="btn-footer" style={{ marginBottom: 20 }}>
            <Button type="primary white"
              className="btn-primary welcome"
              style={{ width: 144 }}
              onClick={removeNotification}
            >
              {state?.isHistory ? 'Go back' : 'Continue'}
            </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    result: state.dealDashboard.result,
    fetching: state.dealDashboard.fetching
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
  changeNotificationStatus: (notificationId) => dispatch(DealDashboardActions.notificationChangeStatusRequest(notificationId)),
  dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NBOAcceptedAdvisorPopupPage)
