import React, { useEffect } from 'react'
import { Spin, Button, message } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import PopupWrapper from './PopupWrapper'
import UsersActions from '../../Redux/UsersRedux'
import ChangePasswordPopupForm from '../../Components/Forms/ChangePasswordPopupForm'

import './PopupPage.less'

function ChangePasswordPopupPage(props) {
  const { user, result, fetching } = props;

  const history = useHistory();

  function handleSubmit(data) {
    props.changeUserPassword(user?.id, data);
  }

  useEffect(() => {
    if (result && result.success) {
      message.success(result.success, 3);
      history.push('/home/settings');
    }
  }, [result]);

  return (
    <PopupWrapper
      width={360}
      path={'/home/settings'}
    >
      <Spin spinning={fetching} size="large">
        <div style={{ padding: 40 }}>
          <div className="dialog-content">
            <div className="dialog-title">Change password</div>
            <ChangePasswordPopupForm onSubmit={handleSubmit} />
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}


const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    result: state.users.result,
    fetching: state.users.fetching
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeUserPassword: (userId, data) => dispatch(UsersActions.userChangePasswordRequest(userId, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPopupPage)
