import React, { useEffect } from 'react'
import { Layout, Spin, message, Space } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import AuthActions from '../Redux/AuthRedux'
import ResetPasswordForm from '../Components/Forms/ResetPasswordForm'
import Images from '../Images'
import errorHandler from '../Utils/errorHandler'


function ResetPasswordPage(props) {
  const { result, error, fetching } = props;

  const history = useHistory();

  function handleSubmit(data) {
    props.resetPassword(data.email);
  }

  useEffect(() => {
    if (result && result.success) {
      message.success(result.success, 3);
      history.push('/');
    }
  }, [result])

  useEffect(() => {
    if (error) {
      errorHandler(error, props.resetError);
    }
  }, [error])

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <div className="sign-up-step with-bg">
          <div className="with-bg-top">
            <img src={Images.logoWhite} alt="" onClick={()=>history.push('/')} />
          </div>
          <div className="modal-container" style={{ width: 360, padding: 32 }}>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>history.push('/login')}>
              <path d="M1.66663 1.66667L11 11M11 11L20.3333 20.3333M11 11L20.3333 1.66667M11 11L1.66663 20.3333" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <Space style={{ marginBottom: 24 }}>
              <img src={Images.phoneOk} alt="" />
            </Space>
            <div className="login-title">Reset your password</div>
            <ResetPasswordForm
              onSubmit={handleSubmit} />
          </div>
        </div>
      </Spin>
    </Layout>
  )
}


const mapStateToProps = (state) => {
  return {
    result: state.auth.result,
    error: state.auth.error,
    fetching: state.auth.fetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (email) => dispatch(AuthActions.resetPasswordRequest(email)),
  resetError: () => dispatch(AuthActions.authErrorReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage)
