import { call, put, delay } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import UsersActions from '../Redux/UsersRedux'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'

export function * getUsers(api, authApi, { role, page, page_size, sort_field, sort_order, search, filter }) {
  try {
    const response = yield call(api.getUsers, role, page, page_size, sort_field, sort_order, search, filter)
    if (response && response.ok) {
      yield put(UsersActions.usersSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(UsersActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getUsers(api, authApi, { role, page, page_size, sort_field, sort_order, search, filter })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * createUser(api, authApi, { role, data }) {
  try {
    const response = yield call(api.createUser, role, data)
    if (response && response.ok) {
      yield put(UsersActions.usersRequest(role))
      yield delay(500)
      yield put(UsersActions.userSaveSuccess(response.data))
      yield put(UsersActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(UsersActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * createUser(api, authApi, { role, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * deleteUser(api, authApi, { role, id }) {
  try {
    const response = yield call(api.deleteUser, role, id)
    if (response && response.ok) {
      yield put(UsersActions.userDeleteSuccess(response.data))
      yield put(UsersActions.resultsReset())
      yield put(push(`/home/${role}s`))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(UsersActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * deleteUser(api, authApi, { role, id })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getInvestorRoles(api, authApi) {
  try {
    const response = yield call(api.getInvestorRoles)
    if (response && response.ok) {
      yield put(UsersActions.investorRolesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(UsersActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getInvestorRoles(api, authApi)
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getInvestorColleagues(api, authApi, {filter}) {
  try {
    const response = yield call(api.getInvestorColleagues, filter)
    if (response && response.ok) {
      yield put(UsersActions.investorColleaguesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(UsersActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getInvestorColleagues(api, authApi, {filter})
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getUserLockHistory(api, authApi, { userId }) {
  try {
    const response = yield call(api.getUserLockHistory, userId)
    if (response && response.ok) {
      yield put(UsersActions.userLockHistorySuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(UsersActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getUserLockHistory(api, authApi, { userId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * toggleUserLock(api, authApi, { userId, action }) {
  try {
    const response = yield call(api.toggleUserLock, userId, action)
    if (response && response.ok) {
      yield put(UsersActions.userToggleLockSuccess(response.data))
      yield put(UsersActions.resultsReset())
      yield put(UsersActions.userLockHistoryRequest(userId))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(UsersActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * toggleUserLock(api, authApi, { userId, action })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getUserChoices(api, authApi, { role }) {
  try {
    const response = yield call(api.getUserChoices, role)
    if (response && response.ok) {
      yield put(UsersActions.userChoicesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(UsersActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getUserChoices(api, authApi, { role })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * changeUserPassword(api, authApi, { userId, data }) {
  try {
    const response = yield call(api.changeUserPassword, userId, data)
    if (response && response.ok) {
      yield put(UsersActions.userChangePasswordSuccess(response.data))
      yield put(UsersActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(UsersActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * changeUserPassword(api, authApi, { userId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}
