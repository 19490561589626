import React, { useState, useEffect } from 'react'
import { Spin, Button, Upload } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import PopupWrapper from './PopupWrapper'
import FirmsActions from '../../Redux/FirmsRedux'
import SettingsActions from '../../Redux/SettingsRedux'
import AddFirmPopupForm from '../../Components/Forms/AddFirmPopupForm'
import Images from '../../Images'

import './PopupPage.less'

function AddFirmPopupPage(props) {
  const { countries, result, fetching, saveResult, saveFetching } = props;

  const [fileUid, setFileUid] = useState(null);
  const [fileData, setFileData] = useState(null);

  const history = useHistory();

  function handleClose() {
    history.push('/home/investment-firms');
  }

  useEffect(() => {
    props.getCountries();
  }, [0]);

  useEffect(() => {
    if (result) {
      if (fileData) {
        props.updateFirmFilters(result.id, fileData);
      } else {
        handleClose();
      }
    }
  }, [result]);

  useEffect(() => {
    if (saveResult) {
      handleClose();
    }
  }, [saveResult]);

  function handleSubmit(data) {
    props.createFirm(data);
  }


	function onChange({ file, fileList }) {
		if (fileUid && fileUid === file.uid) {
      return;
    }
    setFileUid(file.uid);

    let formData = new FormData();

    if (fileList && fileList.length) {
      formData.set('platform_agreement', fileList[0].originFileObj, fileList[0].originFileObj.name);
    }
    setFileData(formData);
  }

  return (
    <PopupWrapper
      width={600}
      path={'/home/investment-firms'}
      hasDropdown={true}
    >
      <Spin spinning={fetching || saveFetching} size="large">
        <div style={{ padding: 40 }}>
          <div className="dialog-content">
            <div className="dialog-title">New company</div>
            <AddFirmPopupForm
              countries={countries}
              onSubmit={handleSubmit}
            />
            <br />
            <div className="upload-text">Upload Platform Agreement:</div>
            <Upload
              method="get"
              accept="application/pdf"
              maxCount={1}
              onChange={onChange}
              className="upload-block"
            >
              <Button
                type='primary white'
                className='btn-primary upload'
                style={{ width: 62, borderRadius: 5, margin: 6 }}
              >
                <img src={Images.upload} alt='' style={{ height: 20 }} />
              </Button>
            </Upload>
            <div className="btn-footer">
              <Button type="primary white" className="btn-primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" form="add-firm-popup-form" className="btn-primary" >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}


const mapStateToProps = (state) => {
  return {
    countries: state.settings.countries,
    saveResult: state.settings.saveResult,
    saveFetching: state.settings.saveFetching,
    result: state.firms.saveResult,
    fetching: state.firms.saveFetching
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCountries: () => dispatch(SettingsActions.countriesRequest()),
  updateFirmFilters: (firmId, data) => dispatch(SettingsActions.firmFiltersSaveRequest(firmId, data)),

  createFirm: (data) => dispatch(FirmsActions.firmSaveRequest(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddFirmPopupPage)
