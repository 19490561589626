import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {Button, DatePicker, Spin, message} from 'antd'
import {useHistory} from 'react-router-dom'
import moment from 'moment'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

const dateFormat = 'DD/MM/YYYY';

function IntroTargetOccuredPopupPage(props) {
	const { result, fetching, match, isAdmin, location } = props;
  const { dashboardId } = match.params;
	const { state } = location;

	const [value, setValue] = useState(null);
  const [active, setActive] = useState(false);

	const history = useHistory()

	useEffect(() => {
    props.dealDashboardSyncToggle(false);
    return function cleanup() {
      props.dealDashboardSyncToggle(true);
    }
  }, [0]);

	useEffect(() => {
    if (result) {
			props.getDealDashboardInfo(dashboardId);
			isAdmin ?
				history.push(`/home/match-company/${dashboardId}`)
			:
				history.push(`/deal-dashboard/overview/${dashboardId}`);
    }
  }, [result]);

  function onChange(date, dateString) {
    setValue(dateString);
  }

	const checkRequired = () => {
		if (state?.isHistory) {
			history.push(`/deal-dashboard/overview/${dashboardId}`);
			return;
		}
    if (!value) {
      message.error('Indicate the date', 3);
      if (!active) {
        setActive(true);
      }
    } else {
      const data = {
        date: value
      };
      props.occuredIntroByAdvisor(dashboardId, data);
    }
  }

	return (
		<PopupWrapper
			width={420}
			path={isAdmin ?
				`/home/match-company/${dashboardId}`
				:
				`/deal-dashboard/overview/${dashboardId}`
			}
			hasDropdown={true}
		>
			<Spin spinning={fetching} size="large">
			<div className='dialog-content' style={{padding: 30}}>
				<img src={Images.phoneOk} alt='' />
				<div className='dialog-title welcome' style={{marginTop: 10}}>Intro to Target - Occurred</div>
				<div className='deal-text'>Congratulations for introducing the parties.</div>
				<br />
				<div className='deal-subtext'>Please indicate the date in which <br/> the introduction occurred.</div>
				<br />
				<div className='deal-input'>
					Insert the date:
          <span>
					  <DatePicker
							disabled={state?.isHistory}
							defaultValue={state?.add_info?.date && moment(state.add_info.date, dateFormat)}
							format={dateFormat}
							dropdownClassName="bottomCenter"
							className={`deal-input${(active && !value) ? ' error-input' : ''}`}
							placeholder=""
							allowClear={false}
							onChange={onChange}
            />
          </span>
				</div>
				<br />
        <div className="deal-text sm" style={{width: '90%'}}>Please review IDN's Platform Agreement to remember your rights and obligations in the context of this engagement</div>
				<div className='btn-footer'>
					<Button
						type='primary white'
						className='btn-primary welcome'
						style={{width: 144}}
						onClick={checkRequired}
					>
						{state?.isHistory ? 'Go back' : 'Confirm'}
					</Button>
				</div>
			</div>
			</Spin>
		</PopupWrapper>
	)
}

const mapStateToProps = (state) => {
  return {
		isAdmin: state.auth.isAdmin,
    result: state.dealDashboard.result,
    fetching: state.dealDashboard.fetching
  }
}

const mapDispatchToProps = (dispatch) => ({
	getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
  occuredIntroByAdvisor: (dashboardId, data) => dispatch(DealDashboardActions.advisorOccuredIntroRequest(dashboardId, data)),
	dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(IntroTargetOccuredPopupPage)
