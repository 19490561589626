import React, { useState, useEffect } from 'react'
import { Space,Button } from 'antd'
import { useHistory } from 'react-router-dom'

import Images from '../../Images'

function InvestorSignUpProfileSetup(props) {
  const history = useHistory();

  return (
    <div className="sign-up-step with-bg">
      <div className="with-bg-top">
        <img src={Images.logoWhite} alt="" onClick={()=>history.push('/')} />
      </div>
      <div className="modal-container" style={{width: 420, padding: '32px 40px'}}>
        <Space style={{marginBottom: 24}}>
          <img src={Images.phoneOk} alt="" />
        </Space>
        <div className="login-title">Firm’s profile set up</div>
        <div className="step-finish-text" style={{marginBottom: 32}}>You can now click "Continue" to set up your personal matching filters, or skip this passage for now</div>
        <Button type="primary white" className="btn-primary" style={{width: 217}} onClick={props.onChangeStep}>
          Continue
        </Button>
        <Space style={{ marginTop: 16 }}>
          <span className="link-tag underline" onClick={() => props.onChangeStep(null, true)}>Skip</span>
        </Space>
      </div>
    </div>
  )
}

export default InvestorSignUpProfileSetup
