import {call, put, delay, take, select} from 'redux-saga/effects'
import {push} from 'connected-react-router'
import ExpertConsultActions, {ExpertConsultTypes, ExpertConsultSelectors} from '../Redux/ExpertConsultRedux'
import NotificationsActions from '../Redux/NotificationsRedux'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'

export function* getExpertConsultingInfo(api, authApi, {dashboardId}) {
	try {
		const response = yield call(api.getExpertConsultingInfo, dashboardId)
		if (response && response.ok) {
			const data = response.data?.length ? response.data[0] : null;
			yield put(ExpertConsultActions.expertConsultingSuccess(data))
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* getExpertConsultingInfo(api, authApi, {dashboardId})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: ' + e))
	}
}

export function* saveAgreementDataByInvestor(api, authApi, {dashboardId, data}) {
	try {
		const response = yield call(api.saveAgreementDataByInvestor, dashboardId, data)
		if (response && response.ok) {
			yield put(ExpertConsultActions.agreementDataSaveSuccess(response.data))
			yield put(push({pathname:`/deal-dashboard/${dashboardId}/agreement-review`, state: {ec: true}}))
			// yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* saveAgreementDataByInvestor(api, authApi, {dashboardId, data})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: ' + e))
	}
}

export function* agreementDataUpdateRequest(api, authApi, {agreementId, dashboardId, data}) {
	try {
		const response = yield call(api.agreementDataUpdateRequest, agreementId, data)
		if (response && response.ok) {
			yield put(ExpertConsultActions.agreementDataSaveSuccess(response.data))
			yield put(push({pathname:`/deal-dashboard/${dashboardId}/agreement-review`, state: {ec: true}}))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* agreementDataUpdateRequest(api, authApi, {agreementId, dashboardId, data})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: ' + e))
	}
}

export function* getDataForAgreementRequest(api, authApi, {dashboardId}) {
	try {
		const response = yield call(api.getDataForAgreementRequest, dashboardId)
		if (response && response.ok) {
			yield put(ExpertConsultActions.getDataForAgreementSuccess(response.data))

		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* getDataForAgreementRequest(api, authApi, {dashboardId})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: ' + e))
	}
}

export function* triggerActionEngageAdvisor(api, authApi, {consultId, dashboardId}) {
	try {
		const response = yield call(api.triggerActionEngageAdvisor, consultId)
		if (response && response.ok) {
			yield put(ExpertConsultActions.investorAngageAdvisorSuccess(response.data))
      yield put(push(`/deal-dashboard/overview/${dashboardId}/agreement-shared`))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* triggerActionEngageAdvisor(api, authApi, {consultId, dashboardId})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: ' + e))
	}
}

export function* getAgreementRequest(api, authApi, {consultId, dashboardId}) {
	try {
		const response = yield call(api.getAgreementRequest, consultId)
		if (response && response.ok) {
			yield put(ExpertConsultActions.getAgreementSuccess(response.data))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* getAgreementRequest(api, authApi, {consultId, dashboardId})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: ' + e))
	}
}

export function* triggerActionInvestorEditAgreement(api, authApi, {consultId, dashboardId}) {
	try {
		const response = yield call(api.triggerActionInvestorEditAgreement, consultId)
		if (response && response.ok) {
			yield put(ExpertConsultActions.investorEditAgreementSuccess(response.data))
			yield put(push(`/deal-dashboard/overview/${dashboardId}/agreement-shared`))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* triggerActionInvestorEditAgreement(api, authApi, {consultId, dashboardId})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: ' + e))
	}
}



export function* triggerActionAdvisorSignAgreement(api, authApi, {consultId, dashboardId}) {
	try {
		const response = yield call(api.triggerActionAdvisorSignAgreement, consultId)
		if (response && response.ok) {
			yield put(ExpertConsultActions.advisorSignAgreementSuccess(response.data))
      yield put(push(`/deal-dashboard/overview/${dashboardId}/advisor-agreement-signed`))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* triggerActionAdvisorSignAgreement(api, authApi, {consultId, dashboardId})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: ' + e))
	}
}

export function* hoursUploadRequest(api, authApi, {dashboardId, consultId, uploadHours}) {
	try {
		const response = yield call(api.hoursUploadRequest, consultId, uploadHours)
		if (response && response.ok) {
			yield put(ExpertConsultActions.hoursUploadSuccess(response.data))
      // yield put(push(`/deal-dashboard/overview/${dashboardId}/`))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* hoursUploadRequest(api, authApi, {dashboardId, consultId, uploadHours})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: ' + e))
	}
}

export function* hoursUpDateRequest(api, authApi, {dashboardId, consultId, uploadHours}) {
	try {
		const response = yield call(api.hoursUpDateRequest, consultId, uploadHours)
		if (response && response.ok) {
			yield put(ExpertConsultActions.hoursUpDateSuccess(response.data))
      // yield put(push(`/deal-dashboard/overview/${dashboardId}/`))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* hoursUpDateRequest(api, authApi, {dashboardId, consultId, uploadHours})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: ' + e))
	}
}

//not used
export function* getHoursRequest(api, authApi, {consultId}) {
	try {
		const response = yield call(api.getHoursRequest, consultId)
		if (response && response.ok) {
			yield put(ExpertConsultActions.getHoursSuccess(response.data))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* getHoursRequest(api, authApi, {consultId})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: ' + e))
	}
}


export function* investorImproveHoursRequest(api, authApi, {consultId, dashboardId}) {
	try {
		const response = yield call(api.investorImproveHoursRequest, consultId)
		if (response && response.ok) {
			yield put(ExpertConsultActions.investorImproveHoursSuccess(response.data))
      // yield put(push(`/deal-dashboard/overview/${dashboardId}`))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* investorImproveHoursRequest(api, authApi, {consultId, dashboardId})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: ' + e))
	}
}

export function* investorExtendAgreementRequest(api, authApi, {consultId, dashboardId}) {
	try {
		const response = yield call(api.investorExtendAgreementRequest, consultId)
		if (response && response.ok) {
			yield put(ExpertConsultActions.investorExtendAgreementSuccess(response.data))
      yield put(push(`/deal-dashboard/overview/${dashboardId}/agreement-extension-shared`))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}
		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* investorExtendAgreementRequest(api, authApi, {consultId, dashboardId})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: ' + e))
	}
}

export function* investorEditExtensionRequest(api, authApi, {consultId, dashboardId}) {
	try {
		const response = yield call(api.investorEditExtensionRequest, consultId)
		if (response && response.ok) {
			yield put(ExpertConsultActions.investorEditExtensionSuccess(response.data))
      yield put(push(`/deal-dashboard/overview/${dashboardId}/agreement-extension-shared`))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* investorEditExtensionRequest(api, authApi, {consultId, dashboardId})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: ' + e))
	}
}

export function* advisorSignExtensionRequest(api, authApi, {consultId, dashboardId}) {
	try {
		const response = yield call(api.advisorSignExtensionRequest, consultId)
		if (response && response.ok) {
			yield put(ExpertConsultActions.advisorSignExtensionSuccess(response.data))
      yield put(push(`/deal-dashboard/overview/${dashboardId}/advisor-agreement-extension-signed`))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
				yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* advisorSignExtensionRequest(api, authApi, {consultId, dashboardId})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: ' + e))
	}
}

export function* notificationViewedRequest(api, authApi, {notifId, dashboardId, data}) {
	try {
		const response = yield call(api.notificationViewedRequest, notifId, data)
		if (response && response.ok) {
			yield put(ExpertConsultActions.advisorSignExtensionSuccess(response.data))
			yield put(ExpertConsultActions.resultsReset())
			yield put(NotificationsActions.dealDashboardNotificationsRequest(dashboardId))
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
				yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* notificationViewedRequest(api, authApi, {notifId, dashboardId, data})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: sagaEC576' + e))
	}
}

export function* expertConsultActionHistoryRequest(api, authApi, {dashboardId}) {
	try {
		const response = yield call(api.expertConsultActionHistoryRequest, dashboardId)
		if (response && response.ok) {
			yield put(ExpertConsultActions.expertConsultActionHistorySuccess(response.data))

		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* expertConsultActionHistoryRequest(api, authApi, {dashboardId})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: ' + e))
	}
}

export function* uploadAgreementByInvestor(api, authApi, {consultId, data}) {
	try {
		const response = yield call(api.uploadAgreementByInvestor, consultId, data)
		if (response && response.ok) {
			yield put(ExpertConsultActions.investorUploadAgreementSuccess(response.data))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* uploadAgreementByInvestor(api, authApi, {consultId, data})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: sagaEC576' + e))
	}
}

export function* reuploadAgreementByInvestor(api, authApi, {consultId, data}) {
	try {
		const response = yield call(api.reuploadAgreementByInvestor, consultId, data)
		if (response && response.ok) {
			yield put(ExpertConsultActions.investorReuploadAgreementSuccess(response.data))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
				yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* reuploadAgreementByInvestor(api, authApi, {consultId, data})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: sagaEC576' + e))
	}
}

export function* uploadAgreementByAdvisor(api, authApi, {consultId, data}) {
	try {
		const response = yield call(api.uploadAgreementByAdvisor, consultId, data)
		if (response && response.ok) {
			yield put(ExpertConsultActions.advisorUploadAgreementSuccess(response.data))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* uploadAgreementByAdvisor(api, authApi, {consultId, data})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: sagaEC773' + e))
	}
}

export function* advisorEnterOptionsRequest(api, authApi, {consultId, data}) {
	try {
		const response = yield call(api.advisorEnterOptionsRequest, consultId, data)
		if (response && response.ok) {
			yield put(ExpertConsultActions.advisorEnterOptionsSuccess(response.data))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* advisorEnterOptionsRequest(api, authApi, {consultId, data})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: ' + e))
	}
}

export function* getWorkDoneData(api, authApi, {consultId}) {
	try {
		const response = yield call(api.getWorkDoneData, consultId)
		if (response && response.ok) {
			yield put(ExpertConsultActions.investorWorkDoneDataSuccess(response.data))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* getWorkDoneData(api, authApi, {consultId})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: sagaEC773' + e))
	}
}

export function* editDataByAdmin(api, authApi, {consultId, data}) {
	try {
		const response = yield call(api.editDataByAdmin, consultId, data)
		if (response && response.ok) {
			yield put(ExpertConsultActions.adminEditDataSuccess(response.data))
			yield put(ExpertConsultActions.resultsReset())
		} else {
			if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
				yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
			yield put(ExpertConsultActions.expertConsultFetchingReset())
		}

		if (response && response.status === 401) {
			const res = yield call(authApi.tokenRefresh)
			if (res && res.access) {
				yield put(AuthActions.updateTokens(res.access, res.refresh))
				yield* editDataByAdmin(api, authApi, {consultId, data})
			} else {
				yield put(AuthActions.logout())
				yield put(push('/login'))
			}
		}
	} catch (e) {
		yield put(ExpertConsultActions.expertConsultFetchingReset())
		yield put(ErrorsActions.errorSave('Server response error: sagaEC773' + e))
	}
}

export function * expertConsultSyncStartMonitor() {
  while (true) {
    const payload = yield take(ExpertConsultTypes.EXPERT_CONSULT_SYNC_TOGGLE);
    let doWork = payload.syncIsStart;
    while (doWork) {
      const {expertConsultInfo, syncIsStart} = yield select(ExpertConsultSelectors.getState);
      if (!syncIsStart) {
        break;
      }
      yield put(ExpertConsultActions.expertConsultingRequest(expertConsultInfo?.dashboard));
      doWork = syncIsStart;
      yield delay(25000);
    }
  }
}
