import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  chatMembersRequest: ['dashboardId'],
  chatMembersSuccess: ['members'],

  chatRemoveNotificationRequest: ['dashboardId'],
  chatRemoveNotificationSuccess: ['notificationResult'],

  chatMessagesRequest: ['page', 'page_size', 'chat'],
  chatMessagesSuccess: ['messages'],

  chatMessageSaveRequest: ['data'],
  chatMessageSaveSuccess: ['saveResult'],

  chatUserStatusRequest: ['dashboardId', 'user_active'],
  chatUserStatusSuccess: ['userStatusResult'],

  chatReset: null,

  resultsReset: null,
  chatFetchingReset: null,
})

export const ChatTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  members: null,
  messages: null,

  fetching: false,
  saveFetching: false,

  saveResult: null,
  notificationResult: null,
  userStatusResult: null,

  page:1,
  page_size:20,

})

/* ------------- Selectors ------------- */
export const ChatSelectors = {
  getState: (state) => state.chat
}

/* ------------- Reducers ------------- */

export const chatMembersRequest = (state, { dashboardId }) => {
  return state.merge({ fetching: true })
}

export const chatMembersSuccess = (state, { members }) => {
  return state.merge({ fetching: false, members })
}

export const chatRemoveNotificationRequest = (state, { dashboardId }) => {
  return state.merge({ fetching: true })
}

export const chatRemoveNotificationSuccess = (state, { notificationResult }) => {
  return state.merge({ fetching: false, notificationResult })
}

export const chatMessagesRequest = (state, { page, page_size, chat }) => {
  const newState = {
    fetching: true
  };
  page && (newState.page = page);
  page_size && (newState.page_size = page_size);
  return state.merge(newState)
}

export const chatMessagesSuccess = (state, { messages }) => {
  return state.merge({ fetching: false, messages })
}

export const chatMessageSaveRequest = (state, { data }) => {
  return state.merge({ saveFetching: true })
}

export const chatMessageSaveSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const chatUserStatusRequest = (state, { dashboardId, user_active }) => {
  return state.merge({ fetching: true })
}

export const chatUserStatusSuccess = (state, { userStatusResult }) => {
  return state.merge({ fetching: false, userStatusResult })
}

export const chatReset = (state) =>{
  return INITIAL_STATE
}

export const resultsReset = (state) => {
  return state.merge({
    saveResult: null,
    notificationResult: null,
    userStatusResult: null
  })
}

export const chatFetchingReset = (state) => {
  return state.merge({ fetching:false, saveFetching: false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHAT_MEMBERS_REQUEST]: chatMembersRequest,
  [Types.CHAT_MEMBERS_SUCCESS]: chatMembersSuccess,

  [Types.CHAT_REMOVE_NOTIFICATION_REQUEST]: chatRemoveNotificationRequest,
  [Types.CHAT_REMOVE_NOTIFICATION_SUCCESS]: chatRemoveNotificationSuccess,

  [Types.CHAT_MESSAGES_REQUEST]: chatMessagesRequest,
  [Types.CHAT_MESSAGES_SUCCESS]: chatMessagesSuccess,

  [Types.CHAT_MESSAGE_SAVE_REQUEST]: chatMessageSaveRequest,
  [Types.CHAT_MESSAGE_SAVE_SUCCESS]: chatMessageSaveSuccess,

  [Types.CHAT_USER_STATUS_REQUEST]: chatUserStatusRequest,
  [Types.CHAT_USER_STATUS_SUCCESS]: chatUserStatusSuccess,

  [Types.CHAT_RESET]: chatReset,

  [Types.RESULTS_RESET]: resultsReset,

  [Types.CHAT_FETCHING_RESET]: chatFetchingReset,
})
