import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { message, Collapse, Button, Tabs, Checkbox, Row, Col, Input, Space, Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'

import SettingsActions from '../../Redux/SettingsRedux'
import CountryGrid from '../../Components/CountryGrid'
import SectorGrid from '../../Components/SectorGrid'
import CollapseHeader from '../../Components/CollapseHeader'
import Images from '../../Images'

const { Panel } = Collapse;
const { TabPane } = Tabs;

function AdvisorFiltersEditPage(props) {
  const { sectorFocus, geographyFocus, filters, saveResult, saveFetching, isAdmin, match } = props;
  const { id } = match.params;

  const [sectorCheckedList, setSectorCheckedList] = useState(null);
  const [sectorCheckAll, setSectorCheckAll] = useState(null);
  const [sectorActiveTab, setSectorActiveTab] = useState(null);
  const [sectorOptions, setSectorOptions] = useState(null);

  const [continentCheckedList, setContinentCheckedList] = useState(null);
  const [continentCheckAll, setContinentCheckAll] = useState(null);
  const [continentActiveTab, setContinentActiveTab] = useState(null);
  const [continentOptions, setContinentOptions] = useState(null);

  const [otherSectorText, setOtherSectorText] = useState(null);
  const [otherSectorValue, setOtherSectorValue] = useState(null);
  const [otherSectorList, setOtherSectorList] = useState(null);

  const [required, setRequired] = useState({
    active: false,
    industry_focus: false,
    geographic_focus: false,
  })

  const [initialized, setInitialized] = useState({
    sectors: false,
    continents: false
  });

  const history = useHistory();

  function backNavigate() {
    isAdmin ?
      history.push(`/home/advisor-details/${id}/filters`)
      :
      history.push('/home/settings')
  }

  useEffect(() => {
    props.getSectors();
    props.getContinents();
    props.getFilters(id, 'advisor');
  }, [0]);

  useEffect(() => {
    if (sectorFocus && !initialized.sectors) {
      let sectorsInit = {};
      let sectorsListInit = {};
      let otherSectorsInit = {};
      let options = {};
      sectorFocus.forEach(sector => {
        sectorsInit[sector.name] = false;
        sectorsListInit[sector.name] = [];
        otherSectorsInit[sector.name] = {
          active: false,
          text: '',
          parent: sector.id
        };
        options[sector.name] = sector.children;
      });
      setSectorActiveTab(sectorFocus[0]?.name);
      setOtherSectorList(otherSectorsInit);
      setSectorCheckAll(sectorsInit);
      setSectorCheckedList(sectorsListInit);
      setSectorOptions(options);
    }
  }, [sectorFocus]);

  useEffect(() => {
    if (sectorActiveTab) {
      setOtherSectorValue(null);
      let text = sectorFocus.find(sector => sector.name === sectorActiveTab).other_sector;
      setOtherSectorText(text);
    }
  }, [sectorActiveTab]);

  useEffect(() => {
    if (filters && sectorCheckedList && sectorFocus && otherSectorList && !initialized.sectors) {
      setInitialized({ ...initialized, sectors: true });
      const sectorList = { ...sectorCheckedList };
      const otherList = { ...otherSectorList };
      filters.industry_focus_sub_sectors.forEach(sector => {
        sectorList[sector.name] = sector.children.map(child => child.id);
        if (sector.additional_specification) {
          otherList[sector.name].active = true;
          otherList[sector.name].text = sector.additional_specification;
        }
      });
      setSectorCheckedList(sectorList);
      setOtherSectorList(otherList);
    }
  }, [sectorCheckedList]);

  useEffect(() => {
    if (geographyFocus && !initialized.continents) {
      let continentsInit = {};
      let continentsListInit = {};
      let options = {};
      geographyFocus.forEach(continent => {
        continentsInit[continent.name] = false;
        continentsListInit[continent.name] = [];
        options[continent.name] = [];
        for (let i = 0; i < continent.children.length; i++) {
          options[continent.name].push(
            { label: continent.children[i].name, value: continent.children[i].id },
          );
        }
      });
      setContinentActiveTab(geographyFocus[0]?.name);
      setContinentCheckAll(continentsInit);
      setContinentCheckedList(continentsListInit);
      setContinentOptions(options);
    }
  }, [geographyFocus]);

  useEffect(() => {
    if (filters && continentCheckedList && geographyFocus && !initialized.continents) {
      setInitialized({ ...initialized, continents: true });
      const arr = { ...continentCheckedList };
      filters.continents.forEach(continent => {
        arr[continent.name] = continent.children.map(child => child.id);
      });
      setContinentCheckedList(arr);
    }
  }, [continentCheckedList]);

  useEffect(() => {
    if (saveResult) {
      backNavigate();
    }
  }, [saveResult]);

  useEffect(() => {
    if (sectorCheckedList && continentCheckedList && otherSectorList) {
      let checkSectors = Object.values(sectorCheckedList).some(el => el.length);
      let checkOtherSectorsList = Object.values(otherSectorList).filter(el => el.active).every(el => el.text);
      let checkContinents = Object.values(continentCheckedList).some(el => el.length);

      const newData = {
        industry_focus: checkSectors && checkOtherSectorsList,
        geographic_focus: checkContinents,
      };
      setRequired({ ...required, ...newData });
    }
  }, [sectorCheckedList, continentCheckedList, otherSectorList]);

  const onChange = (list, isSector) => {
    if (isSector) {
      let data = { ...sectorCheckedList };
      data[sectorActiveTab] = list;
      setSectorCheckedList(data);
    } else {
      let data = { ...continentCheckedList };
      data[continentActiveTab] = list;
      setContinentCheckedList(data);
    }
  };

  const updateData = () => {
    let checkSectors = Object.values(sectorCheckedList).some(el => el.length);
    let checkOtherSectorsList = Object.values(otherSectorList).filter(el => el.active).every(el => el.text);
    let checkContinents = Object.values(continentCheckedList).some(el => el.length);

    const newData = {
      active: true,
      industry_focus: checkSectors && checkOtherSectorsList,
      geographic_focus: checkContinents,
    };
    setRequired(newData);

    if (checkSectors && checkContinents && checkOtherSectorsList) {
      const totalData = {
        industry_focus_sub_sectors: Object.values(sectorCheckedList).flat(),
        countries: Object.values(continentCheckedList).flat(),
        additional_specification: Object.values(otherSectorList).flat().map(el => ({
          name: el.text,
          parent: el.parent
        }))
      }
      props.updateFilters(id, 'advisor', totalData);
    } else {
      message.error('All fields must be filled in to proceed', 3);
    }
  }

  const changeOtherSector = e => {
    let data = { ...otherSectorList };
    data[sectorActiveTab].active = e.target.checked;
    setOtherSectorList(data);
  }

  const changeSpecValue = e => {
    let data = { ...otherSectorList };
    data[sectorActiveTab].text = e.target.value;
    setOtherSectorList(data);
  }

  return (
    <div className="sign-up-step white filters-edit">
      <img src={Images.logo} alt="" />
      <div className="step2-content">
        <div className="step-number"></div>
        <div className="step-title" style={{ marginTop: 96, marginBottom: 232 }}>Edit Matching Filters</div>
      </div>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ?
            <img src={Images.angleUp} alt="" />
            :
            <img src={Images.angleDown} alt="" />
        }
        expandIconPosition="right"
        className="site-collapse-custom-collapse"
        accordion
      >
        <Panel
          header={<CollapseHeader text="Industry Focus" imageType="industry" />} key="industry"
          className={`site-collapse-custom-panel${(required.active && !required.industry_focus) ? ' error-block' : ''}`}
        >
          <div>
            <div className="collapse-content-title">Please tick only the boxes related to sectors in which you have had direct work experience, and in which you would be well-placed to originate deals if engaged by investors.</div>
            <div className="tabs-content">
              <div className="tabs-header">
                <div style={{width: 300}}>Sectors</div>
                <div style={{width: 'auto'}}>Sub Sectors</div>
                {/* <span onClick={() => onCheckAllChange(true)}>{sectorCheckAll && sectorCheckAll[sectorActiveTab] ? 'Unselect all' : 'Select all'}</span> */}
              </div>
              <Tabs tabPosition="left" onChange={(key) => setSectorActiveTab(key)}>
                {
                  sectorCheckedList && sectorFocus?.map(sector => (
                    <TabPane tab={sector.name} key={sector.name}>
                      <div className="vertical-tab-line"></div>
                      <Checkbox.Group value={sectorCheckedList[sector.name]} onChange={(list) => onChange(list, true)}>
                        <SectorGrid
                          data={sectorOptions[sector.name]}
                        />
                      </Checkbox.Group>
                    </TabPane>
                  ))
                }
              </Tabs>
              <div className="divider" style={{ width: 'auto', height: 7, margin: '24px 0 16px' }}></div>
              <Row gutter={[16, 24]}>
                <Col span={12} offset={12}>
                  <Checkbox
                    checked={otherSectorList && otherSectorList[sectorActiveTab].active}
                    onChange={e => changeOtherSector(e)}
                  >
                    {otherSectorText}
                  </Checkbox>
                </Col>
              </Row>
              {
                ((sectorCheckedList && sectorCheckedList[sectorActiveTab].length > 0) || (otherSectorList && otherSectorList[sectorActiveTab].active)) &&
                <Row gutter={[16, 24]} style={{marginTop: 16}}>
                  <Col span={12}>
                    <div className="add-spec">Additional sector specification</div>
                  </Col>
                  <Col span={12} className="flex-center-vertical">
                    <Input
                      className={otherSectorList[sectorActiveTab].active && !otherSectorList[sectorActiveTab].text ? 'error-input' : ''}
                      placeholder="Please include any additional specification"
                      maxLength={180}
                      value={otherSectorValue != null ? otherSectorValue : otherSectorList[sectorActiveTab].text}
                      onChange={e => setOtherSectorValue(e.target.value)}
                      onBlur={e => changeSpecValue(e)}
                    />
                  </Col>
                </Row>
              }
            </div>
          </div>
        </Panel>
        <Panel
          header={<CollapseHeader text="Geographic Focus" imageType="globe" />} key="geography"
          className={`site-collapse-custom-panel${(required.active && !required.geographic_focus) ? ' error-block' : ''}`}
        >
          <div>
            <div className="collapse-content-title">Please tick only the boxes related to regions/countries in which you have ideally operated directly, and in which you would be well-placed to originate deal opportunities if engaged by investors.</div>
            <div className="tabs-content">
              <div className="tabs-header">
                <div>Area</div>
                <div>Countries</div>
                {/* <span onClick={onCheckAllChange}>{continentCheckAll && continentCheckAll[continentActiveTab] ? 'Unselect all' : 'Select all'}</span> */}
              </div>
              <Tabs tabPosition="left" onChange={(key) => setContinentActiveTab(key)}>
                {
                  continentCheckedList && geographyFocus.map(continent => (
                    <TabPane tab={continent.name} key={continent.name}>
                      <div className="vertical-tab-line"></div>
                      <Checkbox.Group value={continentCheckedList[continent.name]} onChange={onChange}>
                        <CountryGrid
                          data={continentOptions[continent.name]}
                        />
                      </Checkbox.Group>
                    </TabPane>
                  ))
                }
              </Tabs>
            </div>
          </div>
        </Panel>
      </Collapse>
      <Space size="large" style={{ marginTop: 160 }}>
        <Button type="primary" className="btn-primary" style={{ width: 217 }} onClick={updateData}>
          Save
          </Button>
        <Button type="primary white" className="btn-primary" style={{ width: 217 }} onClick={backNavigate}>
          Cancel
          </Button>
      </Space>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    filters: state.settings.filters,
    saveResult: state.settings.saveResult,
    saveFetching: state.settings.saveFetching,
    isAdmin: state.auth.isAdmin
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: () => dispatch(SettingsActions.geographyFocusRequest()),
  getFilters: (advisorId, role) => dispatch(SettingsActions.filtersRequest(advisorId, role)),
  updateFilters: (advisorId, role, data) => dispatch(SettingsActions.filtersSaveRequest(advisorId, role, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorFiltersEditPage)
