import React, { useState, useEffect } from 'react'
import { Layout, Spin, message } from 'antd'
import { connect } from 'react-redux'

import AuthActions from '../Redux/AuthRedux'
import errorHandler from '../Utils/errorHandler'
import InvestorIntroForm from '../Components/Forms/InvestorIntroForm'

function InvestorIntroPage(props) {
  const { error, introError, fetching, result, introResult } = props;

  const [userData, setUserData] = useState(null);

  function handleSubmit(data) {
    setUserData(data);
    props.checkEmail(data.email);
  }

  useEffect(() => {
    if (error) {
      message.error(`User with this Email already exists`, 5);
      setUserData(null);
      props.resetError();
    }
  }, [error])

  useEffect(() => {
    if (introError) {
      errorHandler(introError, props.resetError);
    }
  }, [introError]);

  useEffect(() => {
    if (result) {
      props.sendIntroData(userData);
    }
  }, [result])

  useEffect(() => {
    if (introResult) {
      props.openIntroSuccess();
    }
  }, [introResult])

  return (
    <Layout>
      <div className="sign-up-step intro-bg">
        <div className="modal-container" style={{ width: 720, padding: 40 }}>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.onClose}>
            <path d="M1.66663 1.66667L11 11M11 11L20.3333 20.3333M11 11L20.3333 1.66667M11 11L1.66663 20.3333" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <Spin size="large" spinning={fetching}>
            <div className="step-title intro">Intro form</div>
            <div className="intro-text">Please fill in the Intro form below.<br />A member of our team will soon be in touch to schedule a call<br />and guide you through the sign-up process.</div>
            <InvestorIntroForm
              onSubmit={handleSubmit}
            />
          </Spin>
        </div>
      </div>
    </Layout>
  )
}


const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    introError: state.auth.introError,
    fetching: state.auth.fetching,
    result: state.auth.result,
    introResult: state.auth.introResult,
  }
}

const mapDispatchToProps = (dispatch) => ({
  checkEmail: (email) => dispatch(AuthActions.checkEmailExistRequest(email)),
  resetError: () => dispatch(AuthActions.authErrorReset()),
  sendIntroData: (data) => dispatch(AuthActions.investorIntroRequest(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvestorIntroPage)
