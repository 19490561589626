import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import MandatesActions from '../../Redux/MandatesRedux'
import PopupWrapper from './PopupWrapper'
import Images from '../../Images'
import './PopupPage.less'

function CloseMandatePopupPage(props) {
  const { isAdmin, result, match, fetching } = props;
  const { mandateId, tab } = match.params;

  const history = useHistory();

  useEffect(() => {
    if (result) {
      isAdmin ?
        history.push(`/home/mandate-pipeline`)
      :
        history.push(`/home/mandates/closed`);
    }
  }, [result]);

  return (
    <PopupWrapper
      width={420}
      path={isAdmin ?
          `/home/mandate-details/${mandateId}/${tab}`
        :
          `/home/mandate/${mandateId}/${tab}`
      }
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content" style={{ padding: '40px 30px' }}>
          <img src={Images.phoneCancel} alt="" />
          <div className="dialog-title deal">Terminate mandate</div>
          <div className="deal-text" style={{ padding: '0 8px' }}>
            By closing an Origination Mandate, Senior Advisors will not be able to submit new investment opportunities in response to your mandate. Also, all deals submitted to you that you have not engaged with yet will be automatically closed.
          </div>
          <br />
          <div className="deal-subtext">
            Please note: you cannot close an Origination Mandate if you are currently engaged on any related deal.
          </div>
          <div className="btn-footer" style={{ marginTop: 30 }}>
            <Button type="primary white" className="btn-primary" onClick={() => props.closeMandate(mandateId)}>
              Confirm
            </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    result: state.mandates.closeMandateResult,
    fetching: state.mandates.fetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  closeMandate: (mandateId) => dispatch(MandatesActions.closeMandateRequest(mandateId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CloseMandatePopupPage)
