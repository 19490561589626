import React from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'
import {useSelector} from 'react-redux'

import PopupWrapper from './PopupWrapper'
import Images from '../../Images'
import './PopupPage.less'

function PlatformAgreementInfoPopupPage(props) {
  const {match, location} = props;
  const {tab, mandateShareId, mandateId} = match.params;
  const {state} = location;
  const has_PA = useSelector(state => state.settings?.filters?.platform_agreement)

  const history = useHistory();

  const backPath = state?.isMandate ?
    state?.allMandate ?
      `/home/mandate-share-info-all/${mandateId}`
    :
      `/home/mandate-share-info/${mandateShareId}${tab ? '/' + tab : ''}`
  :
    `/home/deals${tab ? '/' + tab : ''}`;

  const onNavigate = () => {
    history.push({
      pathname: `/home/deal-sharing/step/profile/sign-platform-agreement`,
      state: {...state, tab, mandateShareId, mandateId}
    })
  }

  return (
    <PopupWrapper
      width={420}
      path={backPath}
    >
      <div className="dialog-content" style={{ padding: 32 }}>
        <img src={Images.phoneOk} alt="" />
        <div className="dialog-title deal">
          {has_PA ? 'Platform Agreement Update' : 'Platform Agreement'}
        </div>
        <div className="deal-text">
          {has_PA ?
            'We have updated our Platform Agreement to reflect changes in the framework of interactions with investors. Please click continue to review the updated agreement before sharing the deal.'
          :
            'In order to share your first deal and engage in transactions with our investors, you should first agree to our Platform agreement.'
          }
        </div>
        <br />
        <div className="deal-subtext">
          {/* Our standardised Platform agreement regulates the relationship between you and the investment firms
          throughout the deal matching and engagement process, ensuring the confidentiality of the information shared,
          protecting you via non-circumvention clauses, and confirming the fees. Please review it carefully and agree to
          it in order to proceed with sharing your first deal. */}

          {!has_PA &&
            'Our standardise Platform Agreement covers the terms of the relationship between you and IDN, and sets out the framework of interactions with investors. Please click continue to review and accept our Platform Agreement to share the deal.'
          }
        </div>
        <div className="btn-footer" style={{marginTop: 24}}>
          <Button type="primary white" className="btn-primary" onClick={onNavigate}>
            Continue
            </Button>
        </div>
      </div>
    </PopupWrapper>
  )
}

export default PlatformAgreementInfoPopupPage
