import React, { useState, useEffect } from 'react'
import { Button, Tabs, Input, message } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

import DealSharesActions from '../../Redux/DealSharesRedux'
import ExperienceActions from '../../Redux/ExperienceRedux'

import DealSharingReview from '../../Components/DealSharing/DealSharingReview'
import Images from '../../Images'

const { TabPane } = Tabs;
const { TextArea } = Input;

const closedStatuses = ['closed', 'rejected', 'terminated', 'closed_success', 'inv_withdrawn', 'adv_withdrawn', 'not_available'];
const yourAppStatuses = ['applied', 'adv_withdrawn', 'inv_withdrawn', 'not_available'];

function DealShareInfoPage(props) {
  const { match, matchResult, firmDealResult, experience, filters, location } = props;
  const { shareId, tab } = match.params;
  const { state } = location;

  const status = filters?.company?.status?.id;

  const [dealData, setDealData] = useState(null);
  const [dealStatus, setDealStatus] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [comment, setComment] = useState(null);
  const [activeKey, setActiveKey] = useState('summary');
  const [isClosed, setIsClosed] = useState(true);
  const [isMatched, setIsMatched] = useState(null);
  const [hide, setHide] = useState(false);
  const [backPath, setBackPath] = useState('');

  const history = useHistory();

  useEffect(() => {
    if (state?.hideAll) {
      props.getFirmDeal(shareId);
      setHide(true);
    } else {
      props.getDealMatchInfo(shareId);
      if (state?.tab) {
        setBackPath(state.tab === 'all' ? `/home/all-deals` : `/home/deals/${state.tab}`);
      } else {
        setBackPath('/home/deals');
      }
    }
    if (state?.backPath) {
      setBackPath(state.backPath);
    }
  }, [0]);

  useEffect(() => {
    setActiveKey(tab ? tab : 'summary');
  }, [tab]);

  useEffect(() => {
    if (matchResult || firmDealResult) {
      const data = matchResult || firmDealResult;
      setDealData(data.deal);
      if (closedStatuses.includes(data.investor_status_obj?.id)) {
        setIsClosed(true);
      } else {
        setIsClosed(false);
      }
      setIsMatched(data.matched);
      setDealStatus(data.investor_status_obj?.id);
      setAnswers(data.answers);
      setComment(data.investor_comment);
      props.getExperience(data.deal?.owner_obj?.id);
    }
  }, [matchResult, firmDealResult]);

  useEffect(() => {
    if (dealStatus && !hide) {
      switch(dealStatus) {
        case 'applied': {
          setActiveKey('app');
          break;
        }
        case 'not_selected': {
          history.push(`/home/deal-share-info/${shareId}/${activeKey}/application-unsuccessful`)
          break;
        }
        case 'reconfirm': {
          history.push(`/home/deal-share-info/${shareId}/${activeKey}/deal-reopenned`)
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [dealStatus]);

  const applyDeal = () => {
    if (!filters.all_agreements) {
      message.error('Legal agreement not approved - please get in touch with IDN', 3);
    } else {
      history.push({
        pathname: '/home/your-application',
        state: { shareId, dealData, dealStatus, answers, comment, tab: activeKey }
      });
    }
  }

  const onTabChange = key => {
    history.push(`/home/deal-share-info/${shareId}/${key}`);
    setActiveKey(key);
  }

  return (
    <div className="sign-up-step filters-edit wo-padding" style={{paddingBottom: 0}}>
      <div className="absolute-header">
        <div className="flex-center-vertical">
          <div className="logo-block">
            <img src={Images.logoWhite} alt="" />
          </div>
          <div className="title-block bottom">{dealData?.id}</div>
        </div>
      </div>
      <div className="return-block">
        <Link to={backPath}>
          <img src={Images.arrowLeft} alt="" />
          <span>Return to Dashboard</span>
        </Link>
      </div>
      <Tabs activeKey={activeKey} onChange={onTabChange}>
        <TabPane tab="Deal Summary" key="summary">
          <DealSharingReview
            dealData={dealData}
            disableSought={true}
          />
        </TabPane>
        <TabPane tab="Advisor profile" key="profile">
          <div className="firm-profile-block match deal-share-info">
            <div className="firm-profile-section-middle">
              <div className="firm-profile-title">
                <span>Experience - Summary</span>
              </div>

              <div className="firm-profile-area">
                <TextArea
                  value={experience?.executive_summary}
                  autoSize={true}
                  disabled={true}
                />
              </div>
              <div className="firm-profile-title">
                <span>Experience - Deal origination</span>
              </div>
              <div className="firm-profile-area">
                <TextArea
                  value={experience?.deal_origination_experience}
                  autoSize={true}
                  disabled={true}
                />
              </div>
              <div className="firm-profile-title">
                <span>Experience - Working with Private Equity</span>
              </div>
              <div className="firm-profile-area" style={{ marginBottom: 0 }}>
                <TextArea
                  value={experience?.private_equity_experience}
                  autoSize={true}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </TabPane>
        {
          (yourAppStatuses.includes(dealStatus) && isMatched && !hide) &&
            <TabPane tab="Your Application" key="app">
              <div className="firm-profile-block match deal-share-info">
                <div className="firm-profile-section-middle">
                  <div className="firm-profile-title your-app">
                    <span>Screening questionnaire</span>
                  </div>
                  <div className="firm-profile-subtitle" style={{ marginBottom: 24 }}>The Senior Advisor has inserted some questions which you have to answer in order to express interest in this deal.</div>
                  {
                    answers?.map((el, i) => (
                      <div key={el.id}>
                        <div className="firm-profile-title match">
                          <span>{el.question}</span>
                        </div>

                        <div className="firm-profile-area">
                          <TextArea
                            autoSize={true}
                            value={el.answer}
                            disabled={true}
                          />
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="firm-profile-block match deal-share-info">
                <div className="firm-profile-section-middle">
                  <div className="firm-profile-title your-app">
                    <span>Additional Comments and Notes</span>
                  </div>
                  <div className="firm-profile-subtitle">Anything else you want to add to support your expression of interest to this deal</div>
                  <div className="firm-profile-area" style={{ marginBottom: 0 }}>
                    <TextArea
                      autoSize={true}
                      value={comment}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
        }
      </Tabs>
      {
        (isClosed || hide) ?
          null
        :
          dealStatus === 'applied' ?
            <div className="withdraw-btn" style={{margin: '24px 0'}}>
              <Button type="primary white"
                className="btn-primary"
                onClick={() => history.push(`/home/deal-share-info/${shareId}/${activeKey}/withdraw`)}
              >
                Withdraw
              </Button>
              <span>[Withdraw your application to this deal]</span>
            </div>
            :
            <div className="review-block deal-share-info">
              {
                status === 1 &&
                  <div>
                    <Button type="primary red"
                      className="btn-primary"
                      onClick={() => history.push(`/home/deal-share-info/${shareId}/${activeKey}/reject-deal`)}
                    >
                      Reject
                    </Button>
                    <span>[Reject this deal opportunity]</span>
                  </div>
              }
              <div>
                <Button type="primary white"
                  className="btn-primary"
                  onClick={() => history.push(`/home/deal-share-info/${shareId}/${activeKey}/share-deal`)}
                >
                  Share
                </Button>
                <span>[Send it to a colleague]</span>
              </div>
              {
                status === 1 &&
                  <div>
                    <Button type="primary"
                      className="btn-primary"
                      onClick={applyDeal}
                    >
                      {dealStatus === 'reconfirm' ? 'Re-apply' : 'Apply'}
                    </Button>
                    <span>[Express interested in this deal]</span>
                  </div>
              }
            </div>
      }
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    matchResult: state.dealShares.matchResult,
    firmDealResult: state.dealShares.firmDealResult,
    experience: state.experience.list,
    filters: state.settings.filters,
    deal: state.deals.deal,
    dealShares: state.dealShares,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDealMatchInfo: (shareId) => dispatch(DealSharesActions.dealShareMatchRequest(shareId)),
  getFirmDeal: (shareId) => dispatch(DealSharesActions.firmDealRequest(shareId)),

  getExperience: (advisorId) => dispatch(ExperienceActions.experienceRequest(advisorId)),

})

export default connect(mapStateToProps, mapDispatchToProps)(DealShareInfoPage)
