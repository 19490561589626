import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, DatePicker, Checkbox, Spin, message } from 'antd'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

const range = {
	sm: {
		range_from: 1,
		range_to: 50
	},
	md: {
		range_from: 50,
		range_to: 550
	},
	lg: {
		range_from: 550,
		range_to: null
	}
};

const dateFormat = 'DD/MM/YYYY';

function NBOSubmittedInvestorPopupPage(props) {
	const { result, fetching, match, isAdmin, location } = props;
	const { dashboardId } = match.params;
	const { state } = location;

	const [dateValue, setDateValue] = useState(null);
	const [rangeValue, setRangeValue] = useState(null);
  const [active, setActive] = useState(false);

	const history = useHistory();

	useEffect(() => {
		if (state?.isHistory && state?.add_info?.range_from) {
			switch(state.add_info.range_from) {
				case 1: {
					setRangeValue('sm');
					break;
				}
				case 50: {
					setRangeValue('md');
					break;
				}
				default: {
					setRangeValue('lg');
					break;
				}
			}
		}
		props.dealDashboardSyncToggle(false);
		return function cleanup() {
			props.dealDashboardSyncToggle(true);
		}
	}, [0]);

	useEffect(() => {
    if (result) {
			props.getDealDashboardInfo(dashboardId);
			isAdmin ?
				history.push(`/home/match-company/${dashboardId}`)
			:
				history.push(`/deal-dashboard/overview/${dashboardId}`);
    }
  }, [result]);

	function onChange(date, dateString) {
    setDateValue(dateString);
  }

	const changeRange = e => {
		if (e.target.checked) {
			setRangeValue(e.target.value);
		} else {
			setRangeValue(null);
		}
	}

	const checkRequired = () => {
		if (state?.isHistory) {
			history.push(`/deal-dashboard/overview/${dashboardId}`);
			return;
		}
    if (!dateValue) {
      message.error('Indicate the date', 3);
      if (!active) {
        setActive(true);
      }
    } else if (!rangeValue) {
			message.error('Select range', 3);
		} else {
      const data = {
				...range[rangeValue],
        date: dateValue
      };
      props.submittedNBOByInvestor(dashboardId, data);
    }
  }

	return (
		<PopupWrapper
			width={520}
			path={isAdmin ?
				`/home/match-company/${dashboardId}`
				:
				`/deal-dashboard/overview/${dashboardId}`
			}
			hasDropdown={true}
		>
			<Spin spinning={fetching} size="large">
				<div className='dialog-content' style={{ padding: 30 }}>
					<img src={Images.phoneOk} alt='' />
					<div className='dialog-title welcome' style={{ marginTop: 10, marginBottom: 20 }}>
						NBO submitted
				</div>
					<div className='deal-text'>Congratulations for submitting a Non-Binding Offer.</div>
					<div className='deal-subtext engagment' style={{ marginTop: 5 }}>
						Please insert below the Deal Size Range for which the <br /> Enterprise Value, either expressly stated in, or derived from <br /> the offer amount in the Non-Binding Offer, falls within (in <br /> millions of Pounds Sterling £), and the date in which the <br /> NBO was submitted to the Target.
				</div>
					<div className='dealsizerange' style={{ marginTop: 15 }}>
						<div className='dealsizerange title'>
							<span>Deal size range</span>
							<span>EV</span>
							<span className='orange'>Select</span>
						</div>
						<div className='divider'></div>
						<div className='dealsizerange content'>
							<span>Small cap</span>
							<span className='blue'>£1-£50ml</span>
							<span className='checkbox'>
								<Checkbox
									disabled={state?.isHistory}
									checked={rangeValue === 'sm'}
									value="sm"
									onChange={changeRange}
								/>
							</span>
						</div>
						<div className='dealsizerange content'>
							<span>Medium cap</span>
							<span className='blue'>£50-£550ml</span>
							<span className='checkbox'>
								<Checkbox
									disabled={state?.isHistory}
									checked={rangeValue === 'md'}
									value="md"
									onChange={changeRange}
								/>
							</span>
						</div>
						<div className='dealsizerange content'>
							<span>Large cap</span>
							<span className='blue'>£550ml +</span>
							<span className='checkbox'>
								<Checkbox
									disabled={state?.isHistory}
									checked={rangeValue === 'lg'}
									value="lg"
									onChange={changeRange}
								/>
							</span>
						</div>
						<div className='divider'></div>
					</div>
					<div className='deal-input' style={{ marginTop: 10 }}>
						Insert Date:
						<span>
							<DatePicker
								disabled={state?.isHistory}
								defaultValue={state?.add_info?.date && moment(state.add_info.date, dateFormat)}
								format={dateFormat}
								dropdownClassName="bottomCenter"
								className={`deal-input nbo${(active && !dateValue) ? ' error-input' : ''}`}
								placeholder=""
								allowClear={false}
								onChange={onChange}
								suffixIcon={<img src={Images.clock} alt='' className='deal-input clock' />}
							/>
						</span>
					</div>
					<br />
					<div className="deal-text sm" style={{width: '90%'}}>Please review IDN's Platform Agreement to remember your<br />rights and obligations in the context of this engagement</div>
					<div className='btn-footer'>
						<Button
							type='primary white'
							className='btn-primary welcome'
							style={{ width: 144 }}
							onClick={checkRequired}
						>
							{state?.isHistory ? 'Go back' : 'Confirm'}
					</Button>
					</div>
				</div>
			</Spin>
		</PopupWrapper>
	)
}

const mapStateToProps = (state) => {
	return {
		isAdmin: state.auth.isAdmin,
		result: state.dealDashboard.result,
		fetching: state.dealDashboard.fetching
	}
}

const mapDispatchToProps = (dispatch) => ({
	getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
	submittedNBOByInvestor: (dashboardId, data) => dispatch(DealDashboardActions.investorSubmittedNBORequest(dashboardId, data)),
	dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NBOSubmittedInvestorPopupPage)
