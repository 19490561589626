import React from 'react'
import { Form, Input, Select } from 'antd'

import Images from '../../Images'

const { Option } = Select;

function AddFirmPopupForm(props) {
  const {countries} = props;

  const [form] = Form.useForm();

  const countryList = [];
  if (countries) {
    for (let i = 0; i < countries.length; i++) {
      countryList.push(<Option key={countries[i].id}>{countries[i].name}</Option>);
    }
  }

  const onFinish = values => {
    const data = { ...values };

    props.onSubmit(data);
  }

  return (
    <Form id="add-firm-popup-form" form={form} onFinish={onFinish} className="login-form" layout="vertical" style={{ padding: '0 40px' }}>
      <Form.Item
        name="name"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Input
          placeholder="Company name"
          maxLength={100}
          prefix={<img src={Images.briefcaseSm} alt="" />}
          suffix={'*'}
        />
      </Form.Item>
      <Form.Item
        name="website"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Input
          placeholder="Company website"
          maxLength={100}
          prefix={<img src={Images.monitor} alt="" />}
        />
      </Form.Item>
      <Form.Item
        name="headquarter"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <div className="custom-select">
          <span className="flex-center-horizontal">
            <img src={Images.globeSm} alt="" />
          </span>
          <Select
            showSearch
            optionFilterProp="children"
            style={{ width: '100%' }}
            placeholder="Headquarter"
            onChange={(e)=>form.setFieldsValue({ headquarter: e })}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {countryList}
          </Select>
        </div>
      </Form.Item>
    </Form>
  );
}

export default AddFirmPopupForm
