import React, { useState, useEffect } from 'react'
import { Layout, Spin, message } from 'antd'
import {
  useLocation,
  useHistory,
  Switch,
  Route
} from 'react-router-dom'
import { connect } from 'react-redux'

import WelcomePage from './MainDashboard/WelcomePage'
import Sidebar from '../Components/Sidebar'
import HeaderBlock from '../Components/HeaderBlock'

import PrivateRoute from '../Components/PrivateRoute'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'
import SettingsActions from '../Redux/SettingsRedux'
import DealsActions from '../Redux/DealsRedux'
import DealSharesActions from '../Redux/DealSharesRedux'
import MandatesActions from '../Redux/MandatesRedux'
import MandateSharesActions from '../Redux/MandateSharesRedux'
import NotificationsActions from '../Redux/NotificationsRedux'

import StartupPage from './StartupPage'
import DealsPage from './MainDashboard/DealsPage'
import MandatesPage from './MainDashboard/MandatesPage'
import AdvisoryRequestsPage from './MainDashboard/AdvisoryRequestsPage'
import ExperiencePage from './MainDashboard/ExperiencePage'
import SettingsPage from './MainDashboard/SettingsPage'
import FirmProfilePage from './MainDashboard/FirmProfilePage'
import FirmActivitiesPage from './MainDashboard/FirmActivitiesPage'
import FaqsPage from './MainDashboard/FaqsPage'
import PlatformAgreementPage from './MainDashboard/PlatformAgreementPage'
import InvestmentFirmsPage from './AdminPanel/InvestmentFirmsPage'
import FirmDetailsPage from './AdminPanel/FirmDetailsPage'
import InvestorsPage from './AdminPanel/InvestorsPage'
import InvestorDetailsPage from './AdminPanel/InvestorDetailsPage'
import AdvisorsPage from './AdminPanel/AdvisorsPage'
import AdvisorDetailsPage from './AdminPanel/AdvisorDetailsPage'
import AdvisorFiltersEditPage from './AdminPanel/AdvisorFiltersEditPage'
import InvestorFiltersEditPage from './AdminPanel/InvestorFiltersEditPage'
import MailTemplatesPage from './AdminPanel/MailTemplatesPage'
import FocusManagerPage from './AdminPanel/FocusManagerPage'
import NotFoundPage from './NotFoundPage'
import DealSharingPage from './MainDashboard/DealSharingPage'
import ExpertisePage from './MainDashboard/ExpertisePage'
import DealInfoPage from './MainDashboard/DealInfoPage'
import DealEditPage from './MainDashboard/DealEditPage'
import DealFirmPage from './MainDashboard/DealFirmPage'
import DiscloseCompanyAdvisorPage from './MainDashboard/DiscloseCompanyAdvisorPage'
import DiscloseCompanyInvestorPage from './MainDashboard/DiscloseCompanyInvestorPage'
import DealPipelinePage from './AdminPanel/DealPipelinePage'
import DealDetailsAdminPage from './AdminPanel/DealDetailsAdminPage'
import MatchCompanyPage from './AdminPanel/MatchCompanyPage'
import DealShareInfoPage from './MainDashboard/DealShareInfoPage'
import DealShareInfoAllPage from './MainDashboard/DealShareInfoAllPage'
import YourApplicationPage from './MainDashboard/YourApplicationPage'
import ReviewProfilePage from './MainDashboard/ReviewProfilePage'
import MandateSharingPage from './MainDashboard/MandateSharingPage'
import MandateEditPage from './MainDashboard/MandateEditPage'
import MandateDealSharingPage from './MainDashboard/MandateDealSharingPage'
import MandateDealSharingEditPage from './MainDashboard/MandateDealSharingEditPage'
import MandateInfoPage from './MainDashboard/MandateInfoPage'
import MandateShareInfoPage from './MainDashboard/MandateShareInfoPage'
import MandateShareInfoAllPage from './MainDashboard/MandateShareInfoAllPage'
import MandateDealPage from './MainDashboard/MandateDealPage'
import DealForMandatePage from './MainDashboard/DealForMandatePage'
import MandatePipelinePage from './AdminPanel/MandatePipelinePage'
import MandateDetailsPage from './AdminPanel/MandateDetailsPage'
// import DealAdvisoryPage from './DealAdvisoryPage'
// import PlacementsPage from './PlacementsPage'
import AllDealsPage from './MainDashboard/AllDealsPage'
// import NedSaPage from './NedSaPage'

import AddWorkPopupPage from './PopupPages/AddWorkPopupPage'
import AddInvestorPopupPage from './PopupPages/AddInvestorPopupPage'
import AddAdvisorPopupPage from './PopupPages/AddAdvisorPopupPage'
import AddFirmPopupPage from './PopupPages/AddFirmPopupPage'
import DealSharedPopupPage from './PopupPages/DealSharedPopupPage'
import DealNotQualifiedPopupPage from './PopupPages/DealNotQualifiedPopupPage'
import WithDrawAdvisorPopupPage from './PopupPages/WithDrawAdvisorPopupPage'
import WithDrawInvestorPopupPage from './PopupPages/WithDrawInvestorPopupPage'
import ConfirmingMatchDealPopupPage from './PopupPages/ConfirmingMatchDealPopupPage'
import MatchComfirmedAdvisorPopupPage from './PopupPages/MatchComfirmedAdvisorPopupPage'
import MatchComfirmedInvestorPopupPage from './PopupPages/MatchComfirmedInvestorPopupPage'
import EditFirmProfilePopupPage from './PopupPages/EditFirmProfilePopupPage'
import ApplicationSubmittedPopup from './PopupPages/ApplicationSubmittedPopup'
import ShareDealWithColleaguesPopupPage from './PopupPages/ShareDealWithColleaguesPopupPage'
import RejectDealPopupPage from './PopupPages/RejectDealPopupPage'
import ApplicationUnsuccessfulPopupPage from './PopupPages/ApplicationUnsuccessfulPopupPage'
import DealReopennedPopupPage from './PopupPages/DealReopennedPopupPage'
import ChangePasswordPopupPage from './PopupPages/ChangePasswordPopupPage'

import AcceptUltimatumPopupPage from './PopupPages/DealDashboardPopups/AcceptUltimatumPopupPage'
import BindingOfferAcceptedInvestorPopupPage from './PopupPages/DealDashboardPopups/BindingOfferAcceptedInvestorPopupPage'
import CompetitiveProcessLaunchedInvestorPopupPage from './PopupPages/DealDashboardPopups/CompetitiveProcessLaunchedInvestorPopupPage'
import DealSuccessfullyClosedPopupPage from './PopupPages/DealDashboardPopups/DealSuccessfullyClosedPopupPage'
import DisengagePopupPage from './PopupPages/DealDashboardPopups/DisengagePopupPage'
import IntroTargetOccuredPopupPage from './PopupPages/DealDashboardPopups/IntroTargetOccuredPopupPage'
import NBOAcceptedInvestorPopupPage from './PopupPages/DealDashboardPopups/NBOAcceptedInvestorPopupPage'
import NBOSubmittedInvestorPopupPage from './PopupPages/DealDashboardPopups/NBOSubmittedInvestorPopupPage'
import RejectUltimatumPopupPage from './PopupPages/DealDashboardPopups/RejectUltimatumPopupPage'
import RequestIntroductionPopupPage from './PopupPages/DealDashboardPopups/RequestIntroductionPopupPage'
import SendUltimatumPopupPage from './PopupPages/DealDashboardPopups/SendUltimatumPopupPage'
import TerminateEngagementAdvisorPopupPage from './PopupPages/DealDashboardPopups/TerminateEngagementAdvisorPopupPage'
import TerminateEngagementInvestorPopupPage from './PopupPages/DealDashboardPopups/TerminateEngagementInvestorPopupPage'
import ReshareDealPopupPage from './PopupPages/DealDashboardPopups/ReshareDealPopupPage'
import UploadAgreementPopupPage from './PopupPages/DealDashboardPopups/UploadAgreementPopupPage'

import AdvisorAgreementSignedPopupPage from './PopupPages/AdvisoryWorkPopups/AdvisorAgreementSignedPopupPage'
import EngageAdvisorPopupPage from './PopupPages/AdvisoryWorkPopups/EngageAdvisorPopupPage'
import UploadSignedAgreementPopupPage from './PopupPages/AdvisoryWorkPopups/UploadSignedAgreementPopupPage'
// import EditAgreementPopupPage from './PopupPages/AdvisoryWorkPopups/EditAgreementPopupPage'
// import ReviewShareAgreement from './DealDashboard/ReviewShareAgreement'
// import ExpertConsultingAgreement from './DealDashboard/ExpertConsultingAgreement'
import MandateSharedPopupPage from './PopupPages/MandateSharedPopupPage'
import PauseMandatePopupPage from './PopupPages/PauseMandatePopupPage'
import UnpauseMandatePopupPage from './PopupPages/UnpauseMandatePopupPage'
import CloseMandatePopupPage from './PopupPages/CloseMandatePopupPage'
import ConfirmingMatchMandatePopupPage from './PopupPages/ConfirmingMatchMandatePopupPage'
import CloseOutMandatePopupPage from './PopupPages/CloseOutMandatePopupPage'
import CloseOutMandateAdvisorPopupPage from './PopupPages/CloseOutMandateAdvisorPopupPage'
import AskQuestionPopupPage from './PopupPages/AskQuestionPopupPage'
import WithdrawMandateDealPopupPage from './PopupPages/WithdrawMandateDealPopupPage'
import DealRejectedPopupPage from './PopupPages/DealRejectedPopupPage'
import ConfirmingSubmissionPopupPage from './PopupPages/ConfirmingSubmissionPopupPage'
import DealSubmittedPopupPage from './PopupPages/DealSubmittedPopupPage'
import ReshareMandateDealPopupPage from './PopupPages/ReshareMandateDealPopupPage'
import InviteColleaguesToMandatePopupPage from './PopupPages/InviteColleaguesToMandatePopupPage'
import PlatformAgreementInfoPopupPage from './PopupPages/PlatformAgreementInfoPopupPage'
import PlatformAgreementPopupPage from './PopupPages/PlatformAgreementPopupPage'

import errorHandler from '../Utils/errorHandler'

const { Content } = Layout;

function HomePage(props) {
  const { auth, isAdmin, role, subRole, error, startup, currentUser, currentFirm, filters, exportData, tableSettingsFetching, 
    dealNotification, dealId, mandateNotification, mandateId, fromDP, dealSharesNotification, mandateSharesNotification, notifications,
    fromInvestorHistory, investorId } = props;

  const [welcome, setWelcome] = useState(false);
  const [start, setStart] = useState(false);

  const location = useLocation();
  const path = location.pathname;

  const history = useHistory();

  function handleNavigate(path) {
    history.push(path);
  }

  useEffect(() => {
    if (tableSettingsFetching === true) {
      props.setTableSettingsFetching(false);
    }
    props.resetNames();
    !location?.state && props.resetTableSettings();
    props.setDealNotification(false);
    props.setDealSharesNotification(false);
    props.setMandateNotification(false);
    props.setMandateSharesNotification(false);
    if (!isAdmin) {
      props.notificationsSyncToggle(true);
      props.getFilters(auth?.userInfo?.id, role === 0 ? 'advisor' : 'investor');
    }
    return function cleanup() {
      props.notificationsSyncToggle(false);
    }
  }, [0]);

  useEffect(() => {
    if (tableSettingsFetching === false) {
      setStart(true);
    }
  }, [tableSettingsFetching]);

  useEffect(() => {
    if (filters && !isAdmin) {
      if (filters.register_status_obj?.id !== 'registered') {
        setWelcome(true);
        props.updateFilters(auth?.userInfo?.id, role === 0 ? 'advisor' : 'investor', { register_status: 'registered' });
      }
    }
  }, [filters]);

  useEffect(() => {
    if (exportData) {
      const link = document.createElement('a');
      link.setAttribute('href', exportData.url);
      link.setAttribute('download', 'products.xls');
      link.setAttribute('target', '_blank');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      props.resetExportData();
    }
  }, [exportData])

  useEffect(() => {
    if (error) {
      errorHandler(error, props.resetError);
    }
  }, [error]);

  function onClose() {
    setWelcome(false);
  }

  if (auth.userInfo === null || startup.success === false) {
    return (
      <Layout>
        <Route path="*" component={StartupPage} />
      </Layout>
    )
  }

  return (
    start &&
    <Layout style={{ overflowY: 'hidden' }}>
      <Layout>
        {
          welcome &&
          <WelcomePage role={role} onClose={onClose} />
        }
        <Sidebar
          isAdmin={isAdmin}
          role={role}
          subRole={subRole}
          path={path}
          hasDealNotification={dealNotification || dealSharesNotification}
          hasMandateNotification={mandateNotification || mandateSharesNotification}
          notifications={notifications}
          onNavigate={handleNavigate}
          getExportData={props.getExportData}
        />
        <Layout>
          <Content>
            <HeaderBlock
              path={path}
              isAdmin={isAdmin}
              currentUser={currentUser}
              currentFirm={currentFirm}
              dealId={dealId}
              mandateId={mandateId}
              fromDP={fromDP}
              fromInvestorHistory={fromInvestorHistory}
              investorId={investorId}
            />
            <Switch>
              <PrivateRoute path="/home/deals/:tab?" isAllowed={!isAdmin} component={DealsPage} />
              <PrivateRoute path="/home/mandates/:tab?" isAllowed={!isAdmin} component={MandatesPage} />
              <PrivateRoute path="/home/advisory-requests" isAllowed={!isAdmin} component={AdvisoryRequestsPage} />
              <PrivateRoute path="/home/experience" isAllowed={role === 0} component={ExperiencePage} />
              <PrivateRoute path="/home/settings/:tab?" isAllowed={!isAdmin} component={SettingsPage} />
              <PrivateRoute path="/home/firm's-profile/:tab?" isAllowed={role === 2} component={FirmProfilePage} />
              <PrivateRoute path="/home/firm's-activities/:tab?" isAllowed={role === 2 && subRole !== 2} component={FirmActivitiesPage} />
              <PrivateRoute path="/home/faqs" isAllowed={!isAdmin} component={FaqsPage} />
              <PrivateRoute path="/home/platform-agreement" isAllowed={!isAdmin} component={PlatformAgreementPage} />
              <PrivateRoute path="/home/expertise" isAllowed={!isAdmin} component={ExpertisePage} />
              <PrivateRoute path="/home/all-deals" isAllowed={role===2} component={AllDealsPage} />

              {/* <PrivateRoute path="/home/deal-advisory" isAllowed={role===0} component={DealAdvisoryPage} />
              <PrivateRoute path="/home/placements" isAllowed={!isAdmin} component={PlacementsPage} />
              <PrivateRoute path="/home/ned-sa" isAllowed={role===2} component={NedSaPage} /> */}

              <PrivateRoute path="/home/deal-pipeline" isAllowed={isAdmin} component={DealPipelinePage} />
              <PrivateRoute path="/home/deal-details/:dealId/:tab?" isAllowed={isAdmin} component={DealDetailsAdminPage} />
              <PrivateRoute path="/home/match-company/:dashboardId" isAllowed={isAdmin} component={MatchCompanyPage} />
              <PrivateRoute path="/home/mandate-pipeline" isAllowed={isAdmin} component={MandatePipelinePage} />
              <PrivateRoute path="/home/mandate-details/:mandateId/:tab?" isAllowed={isAdmin} component={MandateDetailsPage} />
              <PrivateRoute path="/home/investment-firms" isAllowed={isAdmin} component={InvestmentFirmsPage} />
              <PrivateRoute path="/home/firm-details/:firmId/:tab?" isAllowed={isAdmin} component={FirmDetailsPage} />
              <PrivateRoute path="/home/investors" isAllowed={isAdmin} component={InvestorsPage} />
              <PrivateRoute path="/home/investor-details/:investorId/:tab?" isAllowed={isAdmin} component={InvestorDetailsPage} />
              <PrivateRoute path="/home/advisors" isAllowed={isAdmin} component={AdvisorsPage} />
              <PrivateRoute path="/home/advisor-details/:advisorId/:tab?" isAllowed={isAdmin} component={AdvisorDetailsPage} />
              <PrivateRoute path="/home/mail-templates" isAllowed={isAdmin} component={MailTemplatesPage} />
              <PrivateRoute path="/home/focus-manager" isAllowed={isAdmin} component={FocusManagerPage} />
              <PrivateRoute path="/home/deal-sharing/step/:step" isAllowed={role === 0} component={DealSharingPage} />
              <PrivateRoute path="/home/deal/:dealId/:tab?" isAllowed={role === 0} component={DealInfoPage} />
              <PrivateRoute path="/home/deal-edit/:dealId" isAllowed={isAdmin || role === 0} component={DealEditPage} />
              <PrivateRoute path="/home/deal-investor-firm/:shareId" isAllowed={role === 0 || isAdmin} component={DealFirmPage} />
              <PrivateRoute path="/home/deal-disclose-company-advisor/:hash" isAllowed={role === 0} component={DiscloseCompanyAdvisorPage} />
              <PrivateRoute path="/home/deal-disclose-company-investor" isAllowed={role === 2} component={DiscloseCompanyInvestorPage} />
              <PrivateRoute path="/home/deal-share-info/:shareId/:tab?" isAllowed={role === 2} component={DealShareInfoPage} />
              <PrivateRoute path="/home/deal-share-info-all/:dealId/:tab?" isAllowed={role === 2} component={DealShareInfoAllPage} />
              <PrivateRoute path="/home/your-application" isAllowed={role === 2} component={YourApplicationPage} />
              <PrivateRoute path="/home/review-profile" isAllowed={role === 2} component={ReviewProfilePage} />
              <PrivateRoute path="/home/mandate/:mandateId/:tab?" isAllowed={role === 2} component={MandateInfoPage} />
              <PrivateRoute path="/home/target-company-disclosed" isAllowed={role === 2} component={DiscloseCompanyInvestorPage} />

              <PrivateRoute path="/home/mandate-share-info/:mandateShareId/:tab?" isAllowed={role === 0} component={MandateShareInfoPage} />
              <PrivateRoute path="/home/mandate-share-info-all/:mandateId" isAllowed={role === 0} component={MandateShareInfoAllPage} />
              <PrivateRoute path="/home/mandate-deal/:dealId" isAllowed={role === 0} component={MandateDealPage} />

              <PrivateRoute path="/home/mandate-sharing/step/:step/" isAllowed={isAdmin || role === 2} component={MandateSharingPage} />
              <PrivateRoute path="/home/mandate-edit/:mandateId?" isAllowed={isAdmin || role === 2} component={MandateEditPage} />
              <PrivateRoute path="/home/deal-for-mandate/:dealId/:shareId?" isAllowed={isAdmin || role === 2} component={DealForMandatePage} />

              <PrivateRoute path="/home/mandate-deal-sharing/step/:step" isAllowed={role === 0} component={MandateDealSharingPage} />
              <PrivateRoute path="/home/mandate-deal-sharing-edit/:dealId" isAllowed={isAdmin || role === 0} component={MandateDealSharingEditPage} />

              <Route path="/home/*" component={NotFoundPage} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
      <PrivateRoute path="/home/experience/add-work" isAllowed={role === 0} component={AddWorkPopupPage} />
      <PrivateRoute path="/home/settings/change-password" isAllowed={!isAdmin} component={ChangePasswordPopupPage} />
      <PrivateRoute path="/home/deal-sharing/step/profile/add-work" isAllowed={role === 0} component={AddWorkPopupPage} />
      <PrivateRoute path="/home/advisor-details/:advisorId/add-work" isAllowed={isAdmin} component={AddWorkPopupPage} />
      <PrivateRoute path="/home/advisor/filters-edit/:id" isAllowed={role === 0 || isAdmin} component={AdvisorFiltersEditPage} />
      <PrivateRoute path="/home/investor/filters-edit/:id" isAllowed={role === 2 || isAdmin} component={InvestorFiltersEditPage} />
      <PrivateRoute path="/home/investor-firm/filters-edit/:id" isAllowed={role === 2 || isAdmin} component={InvestorFiltersEditPage} />
      <PrivateRoute path="/home/investors/add-investor" isAllowed={isAdmin} component={AddInvestorPopupPage} />
      <PrivateRoute path="/home/advisors/add-advisor" isAllowed={isAdmin} component={AddAdvisorPopupPage} />
      <PrivateRoute path="/home/investment-firms/add-company" isAllowed={isAdmin} component={AddFirmPopupPage} />
      <PrivateRoute path="/home/deals/:tab?/deal-shared" isAllowed={role === 0} component={DealSharedPopupPage} />
      <PrivateRoute path="/home/mandate-sharing/step/:step/mandate-shared" isAllowed={isAdmin || role === 2} component={MandateSharedPopupPage} />
      <PrivateRoute path="/home/deal-sharing/step/start/not-qualified" isAllowed={role === 0} component={DealNotQualifiedPopupPage} />
      <PrivateRoute path="/home/deal/:dealId/:tab?/withdraw" isAllowed={role === 0} component={WithDrawAdvisorPopupPage} />
      <PrivateRoute path="/home/deal-investor-firm/:shareId/confirming-match" isAllowed={role === 0} component={ConfirmingMatchDealPopupPage} />
      {/* <PrivateRoute path="/home/deal-disclose-company-advisor/:hash/match-confirmed" isAllowed={role === 0} component={MatchComfirmedAdvisorPopupPage} /> */}
      <PrivateRoute path="/home/review-profile/edit-firm-profile" isAllowed={role === 2 && subRole === 2} component={EditFirmProfilePopupPage} />
      <PrivateRoute path="/home/review-profile/application-submitted" isAllowed={role === 2} component={ApplicationSubmittedPopup} />
      <PrivateRoute path="/home/deal-share-info/:shareId/:tab?/share-deal" isAllowed={role === 2} component={ShareDealWithColleaguesPopupPage} />
      <PrivateRoute path="/home/deal-share-info-all/:dealId/:tab?/share-deal" isAllowed={role === 2} component={ShareDealWithColleaguesPopupPage} />
      <PrivateRoute path="/home/deal-share-info/:shareId/:tab?/reject-deal" isAllowed={role === 2} component={RejectDealPopupPage} />
      <PrivateRoute path="/home/deal-share-info/:shareId/:tab?/withdraw" isAllowed={role === 2} component={WithDrawInvestorPopupPage} />
      <PrivateRoute path="/home/deal-share-info/:shareId/:tab?/application-unsuccessful" isAllowed={role === 2} component={ApplicationUnsuccessfulPopupPage} />
      <PrivateRoute path="/home/deal-share-info/:shareId/:tab?/deal-reopenned" isAllowed={role === 2} component={DealReopennedPopupPage} />
      <PrivateRoute path="/home/deal-disclose-company-investor/match-confirmed" isAllowed={role === 2} component={MatchComfirmedInvestorPopupPage} />
      <PrivateRoute path="/home/deal/:dealId/:tab?/reshare-deal" isAllowed={role === 0} component={ReshareDealPopupPage} />
      <PrivateRoute path="/home/deal/:dealId/:tab?/reshare-mandate-deal" isAllowed={role === 0} component={ReshareMandateDealPopupPage} />
      <PrivateRoute path="/home/mandate/:mandateId/:tab?/close" isAllowed={role === 2} component={CloseMandatePopupPage} />
      <PrivateRoute path="/home/mandate/:mandateId/:tab?/pause" isAllowed={role === 2} component={PauseMandatePopupPage} />
      <PrivateRoute path="/home/mandate/:mandateId/:tab?/unpause" isAllowed={role === 2} component={UnpauseMandatePopupPage} />
      <PrivateRoute path="/home/mandate/:mandateId/:tab?/invite-colleagues" isAllowed={role === 2} component={InviteColleaguesToMandatePopupPage} />
      <PrivateRoute path="/home/deal-for-mandate/:dealId/:shareId?/reject" isAllowed={role === 2} component={RejectDealPopupPage} />
      <PrivateRoute path="/home/deal-for-mandate/:dealId/:shareId?/confirming-match" isAllowed={role === 2} component={ConfirmingMatchMandatePopupPage} />

      <PrivateRoute path="/home/match-company/:dashboardId/request-introduction" isAllowed={isAdmin} component={RequestIntroductionPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/intro-target-occured" isAllowed={isAdmin} component={IntroTargetOccuredPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/nbo-submitted" isAllowed={isAdmin} component={NBOSubmittedInvestorPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/nbo-accepted" isAllowed={isAdmin} component={NBOAcceptedInvestorPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/binding-offer-accepted" isAllowed={isAdmin} component={BindingOfferAcceptedInvestorPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/deal-successfully-closed" isAllowed={isAdmin} component={DealSuccessfullyClosedPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/terminate-engagement-advisor" isAllowed={isAdmin} component={TerminateEngagementAdvisorPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/terminate-engagement-investor" isAllowed={isAdmin} component={TerminateEngagementInvestorPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/disengage" isAllowed={isAdmin} component={DisengagePopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/send-ultimatum" isAllowed={isAdmin} component={SendUltimatumPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/accept-ultimatum" isAllowed={isAdmin} component={AcceptUltimatumPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/reject-ultimatum" isAllowed={isAdmin} component={RejectUltimatumPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/competitive-process-launched" isAllowed={isAdmin} component={CompetitiveProcessLaunchedInvestorPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/reshare-deal" isAllowed={isAdmin} component={ReshareDealPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/reshare-mandate-deal" isAllowed={isAdmin} component={ReshareMandateDealPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/upload-agreement" isAllowed={isAdmin} component={UploadAgreementPopupPage} />
      <PrivateRoute path="/home/mandate-details/:mandateId/:tab?/pause" isAllowed={isAdmin} component={PauseMandatePopupPage} />
      <PrivateRoute path="/home/mandate-details/:mandateId/:tab?/unpause" isAllowed={isAdmin} component={UnpauseMandatePopupPage} />
      <PrivateRoute path="/home/mandate-details/:mandateId/:tab?/close" isAllowed={isAdmin} component={CloseMandatePopupPage} />


      <PrivateRoute path="/home/match-company/:dashboardId/admin-edit-options" isAllowed={isAdmin} component={AdvisorAgreementSignedPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/engage-advisor" isAllowed={isAdmin} component={EngageAdvisorPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/engage-advisor" isAllowed={isAdmin} component={EngageAdvisorPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/upload-signed-agreement" isAllowed={isAdmin} component={UploadSignedAgreementPopupPage} />

      <PrivateRoute path="/home/mandate-deal-sharing/step/:step/confirming-submission" isAllowed={role === 0} component={ConfirmingSubmissionPopupPage} />
      <PrivateRoute path="/home/mandate-deal-sharing/step/:step/deal-submited" isAllowed={role === 0} component={DealSubmittedPopupPage} />

      <PrivateRoute path="/home/mandate-share-info/:mandateShareId/:tab?/close-out-mandate" isAllowed={role === 0 || isAdmin} component={CloseOutMandatePopupPage} />
      <PrivateRoute path="/home/mandate-share-info/:mandateShareId/:tab?/ask-question" isAllowed={role === 0 || isAdmin} component={AskQuestionPopupPage} />
      <PrivateRoute path="/home/mandate-share-info/:mandateShareId/:tab?/mandate-closed-out" isAllowed={role === 0 || isAdmin} component={CloseOutMandateAdvisorPopupPage} />
      <PrivateRoute path="/home/mandate-share-info/:mandateShareId/:tab?/platform-agreement-info" isAllowed={role === 0} component={PlatformAgreementInfoPopupPage} />
      {/* <PrivateRoute path="/home/sign-platform-agreement" isAllowed={role === 0} component={PlatformAgreementPopupPage} /> */}

      <PrivateRoute path="/home/mandate-share-info-all/:mandateId/ask-question" isAllowed={role === 0 || isAdmin} component={AskQuestionPopupPage} />
      <PrivateRoute path="/home/mandate-share-info-all/:mandateId/platform-agreement-info" isAllowed={role === 0} component={PlatformAgreementInfoPopupPage} />

      <PrivateRoute path="/home/mandate-deal/:dealId/withdraw-deal" isAllowed={role === 0} component={WithdrawMandateDealPopupPage} />
      <PrivateRoute path="/home/mandate-deal/:dealId/reshare-mandate-deal" isAllowed={role === 0} component={ReshareMandateDealPopupPage} />
      <PrivateRoute path="/home/mandate-deal/:dealId/deal-rejected" isAllowed={role === 0} component={DealRejectedPopupPage} />
      {/* <PrivateRoute path="/home/deals/:tab?/platform-agreement-info" isAllowed={role === 0} component={PlatformAgreementInfoPopupPage} /> */}
      <PrivateRoute path="/home/deal-sharing/step/profile/platform-agreement-info" isAllowed={role === 0} component={PlatformAgreementInfoPopupPage} />
      <PrivateRoute path="/home/deal-sharing/step/profile/sign-platform-agreement" isAllowed={role === 0} component={PlatformAgreementPopupPage} />

      <PrivateRoute path="/home/deal-details/:dealId/:tab?/withdraw" isAllowed={isAdmin} component={WithDrawAdvisorPopupPage} />
      <PrivateRoute path="/home/deal-details/:dealId/:tab?/withdraw-deal" isAllowed={isAdmin} component={WithdrawMandateDealPopupPage} />
      <PrivateRoute path="/home/deal-details/:dealId/reshare-mandate-deal" isAllowed={isAdmin} component={ReshareMandateDealPopupPage} />

      {/* <PrivateRoute path="/home/match-company/:dashboardId/edit-agreement" isAllowed={isAdmin} component={EditAgreementPopupPage} />
      <PrivateRoute path="/home/match-company/:dashboardId/agreement-review" isAllowed={isAdmin} component={ReviewShareAgreement} />
      <PrivateRoute path="/home/match-company/:dashboardId/expert-consulting-agreement" isAllowed={isAdmin} component={ExpertConsultingAgreement} /> */}
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    isAdmin: state.auth.isAdmin,
    role: state.auth.role,
    subRole: state.auth.investorSubRole,
    startup: state.startup,
    error: state.errors.data,
    currentUser: state.settings.userName,
    currentFirm: state.settings.firmName,
    filters: state.settings.filters,
    exportData: state.settings.exportData,
    tableSettingsFetching: state.settings.tableSettingsFetching,
    dealNotification: state.deals.dealNotification,
    dealId: state.deals.dealId,
    mandateNotification: state.mandates.mandateNotification,
    mandateId: state.mandates.mandateId,
    fromDP: state.mandates.fromDP,
    fromInvestorHistory: state.mandates.fromInvestorHistory,
    investorId: state.mandates.investorId,
    dealSharesNotification: state.dealShares.dealSharesNotification,
    mandateSharesNotification: state.mandateShares.mandateSharesNotification,
    notifications: state.notifications.notifications,
  }
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.logout()),

  resetError: () => dispatch(ErrorsActions.errorReset()),

  resetNames: () => dispatch(SettingsActions.resetNames()),
  getFilters: (userId, role) => dispatch(SettingsActions.filtersRequest(userId, role)),
  updateFilters: (userId, role, data) => dispatch(SettingsActions.filtersSaveRequest(userId, role, data)),
  getExportData: () => dispatch(SettingsActions.exportDataRequest()),
  resetExportData: () => dispatch(SettingsActions.exportDataReset()),
  resetTableSettings: () => dispatch(SettingsActions.tableSettingsReset()),
  setTableSettingsFetching: (value) => dispatch(SettingsActions.setTableSettingsFetching(value)),

  notificationsSyncToggle: (syncIsStart) => dispatch(NotificationsActions.notificationsSyncToggle(syncIsStart)),

  setDealNotification: (value) => dispatch(DealsActions.setDealNotification(value)),

  setDealSharesNotification: (value) => dispatch(DealSharesActions.setDealSharesNotification(value)),

  setMandateNotification: (value) => dispatch(MandatesActions.setMandateNotification(value)),

  setMandateSharesNotification: (value) => dispatch(MandateSharesActions.setMandateSharesNotification(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
