import React from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

import PopupWrapper from './PopupWrapper'
import Images from '../../Images'
import './PopupPage.less'

function DealReopennedPopupPage(props) {
  const { match } = props;
  const { shareId, tab } = match.params;

  const history = useHistory();

  return (
    <PopupWrapper
      width={420}
      path={`/home/deal-share-info/${shareId}/${tab}`}
    >
      <div className="dialog-content" style={{ padding: '24px 80px' }}>
        <img src={Images.phoneOk} alt="" />
        <div className="dialog-title deal">Deal reopened</div>
        <div className="deal-text">The deal has now reopened for applications. Please review your previous application and submit it in case you are still interested in this opportunity</div>
        <div className="btn-footer" style={{ marginTop: 24 }}>
          <Button type="primary white" className="btn-primary" onClick={() => history.push(`/home/deal-share-info/${shareId}/${tab}`)}>
            Continue
          </Button>
        </div>
      </div>
    </PopupWrapper>
  )
}

export default DealReopennedPopupPage
