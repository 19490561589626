import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import MandatesActions from '../../Redux/MandatesRedux'
import PopupWrapper from './PopupWrapper'
import Images from '../../Images'
import './PopupPage.less'

function PauseMandatePopupPage(props) {
  const { isAdmin, result, match, fetching } = props;
  const { mandateId, tab } = match.params;


  const history = useHistory();

  useEffect(() => {
    if (result) {
      isAdmin ?
        history.push(`/home/mandate-details/${mandateId}/${tab}`)
      :
        history.push(`/home/mandate/${mandateId}/${tab}`);
    }
  }, [result]);

  return (
    <PopupWrapper
      width={420}
      path={isAdmin ?
          `/home/mandate-details/${mandateId}/${tab}`
        :
          `/home/mandate/${mandateId}/${tab}`
      }
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content" style={{ padding: '40px 30px' }}>
          <img src={Images.phoneCancel} alt="" />
          <div className="dialog-title deal">Pause Mandate</div>
          <div className="deal-text" style={{ padding: '0px' }}>
            By pausing an Origination Mandate, Senior Advisors will not be able to submit new investment opportunities in response to your mandate. However, you can keep working on opportunities that have already been submitted to you.
          </div>
          {/* <div className="deal-subtext">As a next step, you will receive email notifications anytime Senior Advisors share a deal in response to your mandate. In the meantime, please feel free to contact us if you have any query.</div> */}
          <div className="btn-footer">
            <Button type="primary white" className="btn-primary" onClick={() => props.pauseMandate(mandateId)}>
              Confirm
            </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    result: state.mandates.pauseMandateResult,
    fetching: state.mandates.fetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  pauseMandate: (mandateId) => dispatch(MandatesActions.pauseMandateRequest(mandateId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PauseMandatePopupPage)
