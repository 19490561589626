import React from 'react'
import { Form, Input, Space, Checkbox, Button } from 'antd'
import Utils from '../../Utils/utils'

import Images from '../../Images'

function InvestorInviteColleaguesForm(props) {
  const {subRole} = props;

  const [form] = Form.useForm();

  const onFinish = values => {
    const data = { ...values };
    data.users.forEach(user => {
      user.role = user.co_lead ? 1 : 2;
      delete user.co_lead;
    })

    props.onSubmit(data.users);
  }

  return (
    <Form id="investor-invite-form" form={form} onFinish={onFinish} className="login-form" layout="vertical">
      <Form.List name="users">
        {(fields, { add, remove }) => {
          if (!fields.length) {
            setTimeout(() => {
              add();
            })
          }
          return (
            <>
              {fields.map((field, i) => (
                <div key={field.key} className="form-wrapper" style={{padding: '16px 24px 0'}}>
                  <svg width="16" height="16" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => remove(field.name)}>
                    <path d="M1.66663 1.66667L11 11M11 11L20.3333 20.3333M11 11L20.3333 1.66667M11 11L1.66663 20.3333" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <Form.Item>
                    <Space className="invite-form-title flex-center-horizontal">{`Colleague ${i + 1}`}</Space>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, 'name']}
                    fieldKey={[field.fieldKey, 'name']}
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <Input
                      placeholder="Name"
                      maxLength={100}
                      prefix={<img src={Images.user} alt="" />}
                      suffix={'*'}
                    />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, 'surname']}
                    fieldKey={[field.fieldKey, 'surname']}
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <Input
                      placeholder="Surname"
                      maxLength={100}
                      prefix={<img src={Images.user} alt="" />}
                      suffix={'*'}
                    />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, 'email']}
                    fieldKey={[field.fieldKey, 'email']}
                    rules={[
                      { required: true, message: 'This field is required' },
                      { type: 'email', message: 'Invalid email format' },
                      { min: 5, message: 'Field must contain at least 5 characters' },
                    ]}
                    onKeyPress={e=>{if(e.charCode === 32) e.preventDefault()}}
                    onBlur={e=>Utils.clearSpaces(e, 'email', form.setFieldsValue)}
                  >
                    <Input
                      placeholder="Work Email"
                      maxLength={100}
                      prefix={<img src={Images.envelope} alt="" />}
                      suffix={'*'}
                    />
                  </Form.Item>

                  {
                    subRole !== 2 &&
                      <Form.Item
                        {...field}
                        name={[field.name, 'co_lead']}
                        fieldKey={[field.fieldKey, 'co_lead']}
                        valuePropName="checked"
                        style={{ textAlign: 'center' }}
                      >
                        <Checkbox>Add as a Co-Lead Investor</Checkbox>
                      </Form.Item>
                  }
                </div>
              ))}
              <Form.Item>
                <div className="btn-footer invite">
                  <Button type="primary white" className="btn-primary" style={{ width: 217 }} onClick={() => fields.length < 25 && add()} disabled={fields.length > 24}>
                    Add Another One
                  </Button>
                  <Button type="primary" form="investor-invite-form" htmlType="submit" className="btn-primary" style={{ width: 217 }}>
                    Send Invite
                  </Button>
                </div>
              </Form.Item>
            </>
          )
        }}
      </Form.List>
    </Form>
  )
}

export default InvestorInviteColleaguesForm
