import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Progress, Button, Space, Checkbox, message, Spin } from 'antd'
import moment from 'moment'
import { useHistory, Link } from 'react-router-dom'

import SettingsActions from '../Redux/SettingsRedux'
import Images from '../Images'

function ImportantUpdatePage(props) {
  const { updateAgreementResult, confirmAgreementResult, fetching, user } = props;

  const [link, setLink] = useState(null);
  const [name, setName] = useState(null);
  const [surname, setSurname] = useState(null);
  const [active, setActive] = useState({
    first: false,
    second: false
  });

  const history = useHistory();

  useEffect(() => {
    props.getUpdateAgreement(user?.id);
  }, [0]);

  useEffect(() => {
    if (updateAgreementResult) {
      setLink(updateAgreementResult.url);
      setName(updateAgreementResult.name);
      setSurname(updateAgreementResult.surname);
    }
  }, [updateAgreementResult]);

  useEffect(() => {
    if (confirmAgreementResult) {
      history.push('/updates-agreed');
    }
  }, [confirmAgreementResult]);

  const onSubmit = () => {
    const check = active.first && active.second;
    if (check) {
      props.confirmAgreementByAdvisor(user?.id);
    } else {
      message.error("You should agree to IDN's Platform Agreement and Terms of Use", 3);
    }
  }

  const onChange = (field, value) => {
    const data = { ...active };
    data[field] = value;
    setActive(data);
  }

  return (
    <Spin size="large" spinning={fetching}>
      <div className="sign-up-step white" style={{ padding: '40px 40px 24px', minHeight: 'auto' }}>
        <img src={Images.logo} alt="" onClick={() => history.push('/')} />
        <div className="t-cs-container">
          <div className="step-title">IMPORTANT UPDATE</div>
          <div style={{ width: 520 }}>
            <Progress
              percent={100}
              showInfo={false}
              strokeWidth={13}
              trailColor="rgba(31, 73, 228, 0.12)"
              strokeColor="#3680F6"
              status="active"
            />
          </div>
          <div className="step-text update" style={{ marginBottom: 0 }}>
            Dear users,
            <br /><br />
            To proceed to access your dashboard and utilise IDN's services, we need to ask you to agree to our updated Platform Agreement and Terms of Use.
            <br /><br />
            For those of you who have <b>signed up on IDN's platform from May 26th, 2021 onwards</b> (thus, those who have agreed to our Platform Agreement in the form as presented below), the changes are minimal. Concerning the Platform Agreement, we have added only clause 7(g), which we moved here from clause 9.2 of the Terms of Use (from which it has been removed), and corrected a few typos. We have also simplified the process linked to origination mandate agreements. Concerning the Terms of Use, we have made non-material amendments on a few clauses, per the request of investment firms ( 4.2, 5.4, 7.5, 13.3)
            <br /><br />
            For those of you who <b>signed up on IDN's platform prior May 26th, 2021</b> (thus, those who have agreed to our pre-existing, 30 pages' long "Terms & Conditions"), please note that we have restructured our legal agreements based upon the feedback of many of you and investment firms alike. In particular, we have split our pre-existing Terms & Conditions into two separate documents: the "Terms of Use", which are typical boilerplate legal language present on any online website and that can be found now on our main webpage (https://www.idn.ltd/terms-of-use), and our Platform Agreement, which contains the key legal and commercial terms regulating the engagement between you, IDN, and the investors. Although the vast majority of the legal and commercial content included in our pre-existing Terms & Conditions has been reflected in the new documents, please read them carefully before agreeing to them.
            <br /><br />
            We look forward to receiving your confirmation and please do not hesitate to reach out to your point of contact at IDN in case you have any questions.
            <br /><br />
            Kind regards,
            <br /><br />
            IDN Team
          </div>
          <div className="terms-conditions" style={{ height: 320 }}>
            {
              link &&
                <iframe src={link} width="100%" height="95%" title="Platform Agreement"></iframe>
            }
          </div>
          <Space className="terms-conditions-bottom links update">
            <Checkbox style={{ marginBottom: 8 }} onChange={e => onChange('first', e.target.checked)}>{`I, ${name ? name : ''} ${surname ? surname : ''}, hereby confirm that I agree to IDN's updated Platform Agreement`}</Checkbox>
            <Checkbox onChange={e => onChange('second', e.target.checked)}>I confirm I have read and agree to IDN's updated <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a></Checkbox>
          </Space>
          <Button type="primary" className="btn-primary" style={{ width: 217 }} onClick={onSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </Spin>
  )
}

const mapStateToProps = (state) => {
  return {
    updateAgreementResult: state.settings.updateAgreementResult,
    confirmAgreementResult: state.settings.confirmAgreementResult,
    fetching: state.settings.fetching,
    user: state.auth.userInfo,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUpdateAgreement: (advisorId) => dispatch(SettingsActions.advisorUpdateAgreementRequest(advisorId)),
  confirmAgreementByAdvisor: (advisorId) => dispatch(SettingsActions.advisorConfirmAgreementRequest(advisorId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ImportantUpdatePage)
