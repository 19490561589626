import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import MandatesActions from '../../Redux/MandatesRedux'
import Images from '../../Images'
import './PopupPage.less'

function MandateSharedPopupPage(props) {
  const { result, fetching, isAdmin, user, location } = props;
  const { state } = location;

  const history = useHistory();

  useEffect(() => {
    if (result) {
      isAdmin ?
        history.push(`/home/investor-details/${user?.id}`)
      :
        history.push('/home/mandates');
    }
  }, [result]);

  return (
    <div className="popup-dialog">
      <div className="dialog-container" style={{ width: 420 }}>
        <Spin spinning={fetching} size="large">
          <div className="dialog-content" style={{ padding: '40px 56px' }}>
            <img src={Images.checkCircle} alt="" />
            <div className="dialog-title deal">Mandate shared</div>
            <div className="deal-text">Congratulations for sharing this mandate</div>
            <br />
            <div className="deal-subtext">As a next step, you will receive email notifications anytime Senior Advisors share a deal in response to your mandate. In the meantime, please feel free to contact us if you have any query.</div>
            <div className="btn-footer">
              <Button type="primary white" className="btn-primary" onClick={() => props.sharedMandate(state)}>
                Continue
              </Button>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    result: state.mandates.investorSharedMandateResult,
    fetching: state.mandates.fetching,
    isAdmin: state.auth.isAdmin,
    user: state.settings.filters,
  }
}

const mapDispatchToProps = (dispatch) => ({
  sharedMandate: (mandateId) => dispatch(MandatesActions.investorSharedMandateRequest(mandateId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MandateSharedPopupPage)
