import React, { useState, useEffect } from 'react'
import { Input, Button, Spin, message, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import {FileIcon, defaultStyles} from 'react-file-icon'

import SettingsActions from '../../Redux/SettingsRedux'
import DealSharesActions from '../../Redux/DealSharesRedux'
import DealsActions from '../../Redux/DealsRedux'
import Images from '../../Images'

const { TextArea } = Input;

function ReviewProfilePage(props) {
  const { filters, user, subRole, result, result_, reapplyResult, fetching, fetching_, location } = props;
  const { state } = location;

  const [data, setData] = useState(null);
  const [inputData, setInputData] = useState(null);
  const [active, setActive] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!state) {
      history.push('/home/deals');
    } else {
      setInputData(state);
      props.getFirmFilters(user?.company?.id);
    }
  }, [0]);

  useEffect(() => {
    if (filters) {
      let newData = {
        overview: filters.overview,
        investment_preferences: filters.investment_preferences,
        management_style: filters.management_style,
        portfolio: filters.portfolio
      };
      setData(newData);
    }
  }, [filters]);

  useEffect(() => {
    if (result || result_ || reapplyResult) {
      history.push('/home/review-profile/application-submitted');
    }
  }, [result, result_, reapplyResult]);

  const changeActiveMode = () => {
    if (subRole !== 2) {
      setActive(!active);
    } else {
      history.push('/home/review-profile/edit-firm-profile');
    }
  }

  const changeValue = (field, value) => {
    if (data[field] === value) {
      return;
    }
    let newData = { ...data };
    newData[field] = value;
    setData(newData);
    props.updateFirmFilters(user?.company?.id, { [field]: value });
  }

  const applyDeal = () => {
    if (inputData) {
      if (inputData.shareId) {
        if (inputData.dealStatus === 'reconfirm') {
          props.reapplyDealByInvestor(inputData.shareId);
        } else {
          props.applyDealByInvestor(inputData.shareId, inputData.data);
        }
      } else {
        props.applyDealByInvestor_(inputData.dealData?.id, inputData.data);
      }
    }
  }

  const checkRequired = () => {
    if (
      data?.overview &&
      data?.investment_preferences &&
      data?.management_style
    ) {
      applyDeal();
    } else {
      message.error('All fields must be filled in to proceed', 3);
    }
  }

  return (
    <div className="sign-up-step filters-edit wo-padding" style={{ padding: '133px 80px 0' }}>
      <div className="absolute-header">
        <div className="logo-block">
          <img src={Images.logoWhite} alt="" />
        </div>
      </div>
      <Spin size="large" spinning={fetching || fetching_}>
        <div className="return-block" style={{ padding: '16px 0' }}>
          <Link to={{ pathname: '/home/your-application', state: inputData }}>
            <img src={Images.arrowLeft} alt="" />
            <span>Go back</span>
          </Link>
        </div>
        {
          data &&
          <div className="firm-profile-block match" style={{ margin: '0 auto' }}>
            <div className="section-title" style={{ alignSelf: 'self-start', margin: '0 0 16px' }}>Review profile</div>
            <span className="section-title-bottom" style={{marginBottom: 16}}>Please double check that the information related to your firm are updated as they will be reviewed by the Senior Advisors</span>

            <div className="firm-profile-section-middle" style={{marginBottom: 0}}>
              <img src={Images.edit} alt="" onClick={changeActiveMode} />
              <div className="firm-profile-title">
                <span>Firm Overview</span>
              </div>

              <div className="firm-profile-area">
                <TextArea
                  defaultValue={filters?.overview}
                  autoSize={true}
                  maxLength={5000}
                  disabled={!active}
                  onBlur={(e) => changeValue('overview', e.target.value)}
                />
              </div>
              <div className="firm-profile-title">
                <span>Investment preferences</span>
              </div>
              <div className="firm-profile-area">
                <TextArea
                  defaultValue={filters?.investment_preferences}
                  autoSize={true}
                  maxLength={5000}
                  disabled={!active}
                  onBlur={(e) => changeValue('investment_preferences', e.target.value)}
                />
              </div>
              <div className="firm-profile-title">
                <span>Asset Management Style</span>
              </div>
              <div className="firm-profile-area">
                <TextArea
                  defaultValue={filters?.management_style}
                  autoSize={true}
                  maxLength={5000}
                  disabled={!active}
                  onBlur={(e) => changeValue('management_style', e.target.value)}
                />
              </div>
              <div className="firm-profile-input-bottom row" style={{ padding: 0, marginBottom: 16 }}>
                <span>Portfolio companies</span>
                <div>
                  <Input
                    defaultValue={filters?.portfolio}
                    placeholder="Link to Website"
                    disabled={!active}
                    onBlur={(e) => changeValue('portfolio', e.target.value)}
                  />
                </div>
              </div>
              <div className="firm-profile-input-bottom row" style={{ padding: 0 }}>
                <span style={{width: 'fit-content'}}>Approved form of Bilateral contract</span>
                <div style={{width: 'fit-content'}}>
                  {
                    filters?.bilateral_contract ?
                      <div className='document-admin' style={{marginLeft: 30 }}>
                        <a href={filters?.bilateral_contract} target='_blank' rel='noreferrer'
                          style={{marginTop: 5, display: 'flex', alignItems: 'center', flexDirection: 'column'}}
                        >
                          <div className='document-admin-icon' >
                          <FileIcon
                            extension={'pdf'}
                            {...defaultStyles[`pdf`]}
                          />
                          </div>
                          <Tooltip
                            placement="bottom"
                            title={'Bilateral contract'}
                          >
                            <div className='document-admin-title'>Bilateral contract</div>
                          </Tooltip>
                        </a>
                      </div>
                    :
                      null
                  }
                  </div>
              </div>
            </div>

            <div className="btn-footer">
              <Button type="primary" className="btn-primary" style={{ width: 217, margin: '16px 0' }} onClick={checkRequired}>
                Submit
              </Button>
            </div>
          </div>
        }
      </Spin>
    </div>

  )
}


const mapStateToProps = (state) => {
  return {
    filters: state.settings.firmFilters,
    user: state.auth.userInfo,
    subRole: state.auth.investorSubRole,
    result: state.dealShares.dealInvestorApplyResult,
    result_: state.deals.dealInvestorApplyResult,
    reapplyResult: state.dealShares.dealInvestorReapplyResult,
    fetching: state.dealShares.fetching,
    fetching_: state.deals.fetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getFirmFilters: (firmId) => dispatch(SettingsActions.firmFiltersRequest(firmId)),
  updateFirmFilters: (firmId, data) => dispatch(SettingsActions.firmFiltersSaveRequest(firmId, data)),

  applyDealByInvestor: (shareId, data) => dispatch(DealSharesActions.dealInvestorApplyRequest(shareId, data)),
  applyDealByInvestor_: (dealId, data) => dispatch(DealsActions.dealInvestorApplyRequest_(dealId, data)),
  reapplyDealByInvestor: (shareId) => dispatch(DealSharesActions.dealInvestorReapplyRequest(shareId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewProfilePage)
