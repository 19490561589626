import React, { useState, useEffect } from 'react'
import { Button, Spin } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

import MandateDealSharingReview from '../../Components/MandateDealSharing/MandateDealSharingReview'
import DealsActions from '../../Redux/DealsRedux'
import DealSharesActions from '../../Redux/DealSharesRedux'
import Images from '../../Images'

const closedStatuses = ['withdrawn', 'rejected'];

function DealForMandatePage(props) {
  const { isAdmin, fetching, matchResult, firmDealResult, deal, mandateId, investmentTypes, match, location, role } = props;
  const { dealId, shareId } = match.params;
  const { state } = location;

  const [dealData, setDealData] = useState(null);
  const [dealStatus, setDealStatus] = useState(null);
  const [backPath, setBackPath] = useState('');
  const [hide, setHide] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (shareId) {
      (state?.isOwnDeal === false || state?.isOwnMandate === false) ?
        props.getFirmDeal(shareId)
      :
        props.getDealMatchInfo(shareId);
    } else {
      props.getDeal(dealId);
    }
    const path = isAdmin ?
        `/home/mandate-details/${mandateId}/deals`
      :
        state?.backPath ?
          state?.backPath
        :
          state?.hideAll ?
            {
              pathname: `/home/mandate/${mandateId}/matches`,
              state: {
                backPath: "/home/firm's-activities/mandate",
                hideAll: state?.hideAll,
                isOwnMandate: state?.isOwnMandate
              }
            }
          :
            `/home/mandate/${mandateId}/matches`;

    setBackPath(path);
    if (state?.hideAll) {
      setHide(true);
    }
  }, [0]);

  useEffect(() => {
    if (deal) {
      setDealData(deal);
      setDealStatus(deal.status_obj?.id);
    }
  }, [deal]);

  useEffect(() => {
    if (matchResult || firmDealResult) {
      if (matchResult && matchResult.dashboard) {
        history.push({
          pathname: `/deal-dashboard/overview/${matchResult.dashboard}`,
          state: {backPath: `/home/deal-for-mandate/${dealId}${shareId ? '/' + shareId : ''}`}
        });
        return;
      }
      const data = matchResult || firmDealResult;
      setDealData(data.deal);
      setDealStatus(data.deal?.status_obj?.id);
    }
  }, [matchResult, firmDealResult]);

  const onConfirm = () => {
    const data = {
      name: dealData?.target_information?.target_name,
      website: dealData?.target_information?.target_information_url,
      advisor: dealData?.owner_obj?.name,
      mandate_share: dealData?.mandate_share_id,
    };
    history.push({
      pathname: `/home/deal-for-mandate/${dealId}/${shareId ? shareId + '/': ''}confirming-match`,
      state: {data}
    })
  }

  return (
    <div className="sign-up-step filters-edit wo-padding" style={{ padding: '133px 80px 0' }}>
      <div className="absolute-header">
        <div className="flex-center-vertical">
          <div className="logo-block">
            <img src={Images.logoWhite} alt="" />
          </div>
          <div className="title-block bottom">{dealId}</div>
        </div>
      </div>
      <div className="return-block" style={{ padding: '16px 0' }}>
        <Link to={backPath}>
          <img src={Images.arrowLeft} alt="" />
          <span>Go back</span>
        </Link>
      </div>
      <Spin size="large" spinning={fetching}>
        <div className="deal-top-info" style={{justifyContent: 'center', maxWidth: 'max-content', margin: '0 auto 32px'}}>
          <div className="deal-top-info-item">
          <span>Mandate code</span>
          <div>
            {
              (state?.isOwnDeal === false || state?.isOwnMandate === false) ?
                dealData?.mandate?.id
              :
                <Link to={`/home/mandate/${dealData?.mandate?.id}/summary`}>{dealData?.mandate?.id}</Link>
            }
          </div>
          </div>
        </div>
        <MandateDealSharingReview
          dealData={dealData}
          investmentTypes={investmentTypes}
          role={role}
        />
        {
          (!isAdmin && !closedStatuses.includes(dealStatus) && !hide) &&
          <div className="review-block deal-share-info">
            <div>
              <Button type="primary red"
                className="btn-primary"
                style={{ width: 217 }}
                onClick={() =>  history.push({
                  pathname: `/home/deal-for-mandate/${dealId}/${shareId ? shareId + '/': ''}reject`,
                  state: {mandateId: dealData?.mandate?.id}
                })}
              >
                Reject
              </Button>
              <span>[Reject this opportunity]</span>
            </div>
            <div className="btn-text-block">
              <Button type="primary"
                className="btn-primary"
                style={{ width: 217 }}
                onClick={onConfirm}
              >
                Confirm
              </Button>
              <span>[Confirm the match with the Senior Advisor]</span>
            </div>
          </div>
        }
      </Spin>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    fetching: state.dealShares.fetching,
    matchResult: state.dealShares.matchResult,
    firmDealResult: state.dealShares.firmDealResult,
    deal: state.deals.deal,
    mandateId: state.mandates.mandateId,
    filters: state.settings.firmFilters,
    investmentTypes: state.settings.investmentTypes,
    role: state.auth.role,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDeal: (dealId) => dispatch(DealsActions.dealRequest(dealId)),

  getFirmDeal: (shareId) => dispatch(DealSharesActions.firmDealRequest(shareId)),
  getDealMatchInfo: (shareId) => dispatch(DealSharesActions.dealShareMatchRequest(shareId)),
  keepMatchUnderReview: (shareId) => dispatch(DealSharesActions.advisorKeepMatchReviewRequest(shareId)),
  changeDealShareStatus: (id, status) => dispatch(DealSharesActions.dealShareChangeStatusRequest(id, status)),
  confirmMatchByAdvisor: (shareId) => dispatch(DealSharesActions.advisorMatchConfirmRequest(shareId)),

})

export default connect(mapStateToProps, mapDispatchToProps)(DealForMandatePage)
