import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import Images from '../../../Images'
import '../PopupPage.less'

function CongratulationsPopupPage(props) {

	useEffect(() => {
    props.dealDashboardSyncToggle(false);
  }, [0]);

	const history = useHistory()

	const closePopup = () => {
		props.resetDealDashboard();
		history.push('/home/deals');
	}

	return (
		<div className="popup-dialog">
			<div className="dialog-container" style={{ width: 420 }}>
				<div className='dialog-content' style={{ padding: 40, paddingTop: 20 }}>
					<img src={Images.phoneOk} alt='' />
					<div className='dialog-title welcome' style={{ marginTop: 10 }}>Congratulations!</div>
					<div className='deal-text'>The entire team at IDN would like to congratulate you for closing this deal.</div>
					<br />
					<div className='deal-subtext intro'>You can now return to <br /> the main dashboard</div>
					<br />
					<div className='btn-footer' style={{ margin: 0 }}>
						<Button
							type='primary white'
							className='btn-primary welcome'
							style={{ width: 144 }}
							onClick={closePopup}
						>
							Return
					</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => ({
	dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
  resetDealDashboard: () => dispatch(DealDashboardActions.dealDashboardReset()),
})

export default connect(null, mapDispatchToProps)(CongratulationsPopupPage)
