import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import ExperienceActions from '../Redux/ExperienceRedux'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'

export function * getExperience(api, authApi, {advisorId}) {
  try {
    const response = yield call(api.getExperience, advisorId);
    if (response && response.ok) {
      yield put(ExperienceActions.experienceSuccess(response.data))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(ExperienceActions.experienceFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getExperience(api, authApi, {advisorId});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(ExperienceActions.experienceFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * deleteExperience(api, authApi, { advisorId }) {
  try {
    const response = yield call(api.deleteExperience, advisorId);
    if (response && response.ok) {
      yield put(ExperienceActions.experienceDeleteSuccess(response.data ? response.data : true))
      yield put(ExperienceActions.experienceRequest(advisorId));

    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(ExperienceActions.experienceFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * deleteExperience(api, authApi, { advisorId });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(ExperienceActions.experienceFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * saveExperience(api, authApi, { advisorId, data }) {
  try {
    //const saveFunc = data.id ? api.updateExperience : api.addExperience;
    const response = yield call(api.updateExperience, advisorId, data);
    if (response && response.ok) {
      yield put(ExperienceActions.experienceSaveSuccess(response.data ? response.data : true))
      yield put(ExperienceActions.experienceRequest(advisorId));

    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(ExperienceActions.experienceFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * saveExperience(api, authApi, { advisorId, data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(ExperienceActions.experienceFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getExperienceJobs(api, authApi, {advisorId}) {
  try {
    const response = yield call(api.getExperienceJobs, advisorId);
    if (response && response.ok) {
      yield put(ExperienceActions.experienceJobsSuccess(response.data))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(ExperienceActions.experienceFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getExperienceJobs(api, authApi, {advisorId});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(ExperienceActions.experienceFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * deleteExperienceJob(api, authApi, { advisorId, jobId }) {
  try {
    const response = yield call(api.deleteExperienceJob, advisorId, jobId);
    if (response && response.ok) {
      yield put(ExperienceActions.experienceJobDeleteSuccess(response.data ? response.data : true))
      yield put(ExperienceActions.experienceRequest(advisorId));

    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(ExperienceActions.experienceFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * deleteExperienceJob(api, authApi, { advisorId, jobId });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(ExperienceActions.experienceFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * saveExperienceJob(api, authApi, { advisorId, jobId, data }) {
  try {
    const saveFunc = data.id ? api.updateExperienceJob : api.addExperienceJobs;
    const response = yield call(saveFunc, advisorId, jobId, data);
    if (response && response.ok) {
      yield put(ExperienceActions.experienceJobSaveSuccess(response.data ? response.data : true))
      yield put(ExperienceActions.experienceRequest(advisorId));

    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(ExperienceActions.experienceFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * saveExperienceJob(api, authApi, { advisorId, jobId, data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(ExperienceActions.experienceFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}
