import React from 'react'
import { Form, Input, Select } from 'antd'
import Utils from '../../Utils/utils'

import Images from '../../Images'

const { Option } = Select;

function AddInvestorPopupForm(props) {
  const { firms, roles } = props;

  const [form] = Form.useForm();

  const firmList = [];
  if (firms && firms.results) {
    for (let i = 0; i < firms.results.length; i++) {
      firmList.push(<Option key={firms.results[i].id}>{firms.results[i].name}</Option>);
    }
  }

  const roleList = [];
  if (roles) {
    for (let i = 0; i < roles.length; i++) {
      roleList.push(<Option key={roles[i].id}>{roles[i].name}</Option>);
    }
  }

  const onFinish = values => {
    const data = { ...values };

    props.onSubmit(data);
  }

  return (
    <Form id="add-investor-popup-form" form={form} onFinish={onFinish} className="login-form" layout="vertical" style={{ padding: '0 40px' }}>
       <Form.Item
        name="company"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <div className="custom-select">
          <span className="flex-center-horizontal">
            <img src={Images.briefcaseSm} alt="" />
          </span>
          <Select
            style={{ width: '100%' }}
            placeholder="Company"
            onChange={(e)=>form.setFieldsValue({ company: e })}
          >
            {firmList}
          </Select>
        </div>
      </Form.Item>
      <Form.Item
        name="role"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <div className="custom-select">
          <span className="flex-center-horizontal">
            <img src={Images.role} alt="" />
          </span>
          <Select
            style={{ width: '100%' }}
            placeholder="Role"
            onChange={(e)=>form.setFieldsValue({ role: e })}
          >
            {roleList}
          </Select>
        </div>
      </Form.Item>
      <Form.Item
        name="name"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Input
          placeholder="Name"
          maxLength={100}
          prefix={<img src={Images.user} alt="" />}
          suffix={'*'}
        />
      </Form.Item>
      <Form.Item
        name="surname"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Input
          placeholder="Surname"
          maxLength={100}
          prefix={<img src={Images.user} alt="" />}
          suffix={'*'}
        />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: 'This field is required' },
          { type: 'email', message: 'Invalid email format' },
          { min: 5, message: 'Field must contain at least 5 characters' },
        ]}
        onKeyPress={e=>{if(e.charCode === 32) e.preventDefault()}}
        onBlur={e=>Utils.clearSpaces(e, 'email', form.setFieldsValue)}
      >
        <Input
          placeholder="Email"
          maxLength={100}
          prefix={<img src={Images.envelope} alt="" />}
          suffix={'*'}
        />
      </Form.Item>
    </Form>
  );
}

export default AddInvestorPopupForm
