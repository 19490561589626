import { call, put, delay, take, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import DealDashboardActions, {DealDashboardTypes, DealDashboardSelectors} from '../Redux/DealDashboardRedux'
import NotificationsActions from '../Redux/NotificationsRedux'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'

export function * getDealDashboardInfo(api, authApi, { dashboardId }) {
  try {
    const response = yield call(api.getDealDashboardInfo, dashboardId)
    if (response && response.ok) {
      yield put(DealDashboardActions.dealDashboardSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getDealDashboardInfo(api, authApi, { dashboardId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * updateSeenByAdvisor(api, authApi, { dashboardId }) {
  try {
    const response = yield call(api.updateSeenByAdvisor, dashboardId)
    if (response && response.ok) {
      yield put(DealDashboardActions.seenByAdvisorSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * updateSeenByAdvisor(api, authApi, { dashboardId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}


export function * saveTargetCompany(api, authApi, { data, dealId }) {
  try {
    const response = yield call(api.saveTargetCompany, data, dealId)
    if (response && response.ok) {
      yield put(DealDashboardActions.targetCompanySaveSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * saveTargetCompany(api, authApi, { data, dealId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getTargetCompany(api, authApi, { targetCompanyId }) {
  try {
    const response = yield call(api.getTargetCompany, targetCompanyId)
    if (response && response.ok) {
      yield put(DealDashboardActions.targetCompanySuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getTargetCompany(api, authApi, { targetCompanyId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * editTargetCompany(api, authApi, { targetCompanyId, data }) {
  try {
    const response = yield call(api.editTargetCompany, targetCompanyId, data)
    if (response && response.ok) {
      yield put(DealDashboardActions.targetCompanyEditSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * editTargetCompany(api, authApi, { targetCompanyId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * inviteColleaguesToDashboard(api, authApi, { dashboardId, data }) {
  try {
    const response = yield call(api.inviteColleaguesToDashboard, dashboardId, data)
    if (response && response.ok) {
      yield put(DealDashboardActions.inviteDashboardSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * inviteColleaguesToDashboard(api, authApi, { dashboardId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * terminateDealByInvestor(api, authApi, { dashboardId, data }) {
  try {
    const response = yield call(api.terminateDealByInvestor, dashboardId, data)
    if (response && response.ok) {
      yield put(DealDashboardActions.investorTerminateDealSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * terminateDealByInvestor(api, authApi, { dashboardId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * terminateDealByAdvisor(api, authApi, { dashboardId, data }) {
  try {
    const response = yield call(api.terminateDealByAdvisor, dashboardId, data)
    if (response && response.ok) {
      yield put(DealDashboardActions.advisorTerminateDealSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * terminateDealByAdvisor(api, authApi, { dashboardId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * proceedAdvisorParties(api, authApi, { dashboardId, data }) {
  try {
    const response = yield call(api.proceedAdvisorParties, dashboardId, data)
    if (response && response.ok) {
      yield put(DealDashboardActions.advisorProceedPartiesSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * proceedAdvisorParties(api, authApi, { dashboardId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * proceedInvestorParties(api, authApi, { dashboardId }) {
  try {
    const response = yield call(api.proceedInvestorParties, dashboardId)
    if (response && response.ok) {
      yield put(DealDashboardActions.investorProceedPartiesSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * proceedInvestorParties(api, authApi, { dashboardId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * requestIntroByInvestor(api, authApi, { dashboardId, data }) {
  try {
    const response = yield call(api.requestIntroByInvestor, dashboardId, data)
    if (response && response.ok) {
      yield put(DealDashboardActions.investorRequestIntroSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * requestIntroByInvestor(api, authApi, { dashboardId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * occuredIntroByAdvisor(api, authApi, { dashboardId, data }) {
  try {
    const response = yield call(api.occuredIntroByAdvisor, dashboardId, data)
    if (response && response.ok) {
      yield put(DealDashboardActions.advisorOccuredIntroSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * occuredIntroByAdvisor(api, authApi, { dashboardId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * sendUltimatumByAdvisor(api, authApi, { dashboardId }) {
  try {
    const response = yield call(api.sendUltimatumByAdvisor, dashboardId)
    if (response && response.ok) {
      yield put(DealDashboardActions.advisorSendUltimatumSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * sendUltimatumByAdvisor(api, authApi, { dashboardId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * acceptUltimatumByInvestor(api, authApi, { dashboardId }) {
  try {
    const response = yield call(api.acceptUltimatumByInvestor, dashboardId)
    if (response && response.ok) {
      yield put(DealDashboardActions.investorAcceptUltimatumSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * acceptUltimatumByInvestor(api, authApi, { dashboardId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * rejectUltimatumByInvestor(api, authApi, { dashboardId }) {
  try {
    const response = yield call(api.rejectUltimatumByInvestor, dashboardId)
    if (response && response.ok) {
      yield put(DealDashboardActions.investorRejectUltimatumSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * rejectUltimatumByInvestor(api, authApi, { dashboardId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * submittedNBOByInvestor(api, authApi, { dashboardId, data }) {
  try {
    const response = yield call(api.submittedNBOByInvestor, dashboardId, data)
    if (response && response.ok) {
      yield put(DealDashboardActions.investorSubmittedNBOSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * submittedNBOByInvestor(api, authApi, { dashboardId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * acceptedNBOByInvestor(api, authApi, { dashboardId, data }) {
  try {
    const response = yield call(api.acceptedNBOByInvestor, dashboardId, data)
    if (response && response.ok) {
      yield put(DealDashboardActions.investorAcceptedNBOSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * acceptedNBOByInvestor(api, authApi, { dashboardId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * acceptedBindingOfferByInvestor(api, authApi, { dashboardId, data }) {
  try {
    const response = yield call(api.acceptedBindingOfferByInvestor, dashboardId, data)
    if (response && response.ok) {
      yield put(DealDashboardActions.investorAcceptedBindingOfferSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * acceptedBindingOfferByInvestor(api, authApi, { dashboardId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * closeSuccessByInvestor(api, authApi, { dashboardId, data }) {
  try {
    const response = yield call(api.closeSuccessByInvestor, dashboardId, data)
    if (response && response.ok) {
      yield put(DealDashboardActions.investorSuccessCloseSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * closeSuccessByInvestor(api, authApi, { dashboardId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * changeDealToPublicByInvestor(api, authApi, { dashboardId }) {
  try {
    const response = yield call(api.changeDealToPublicByInvestor, dashboardId)
    if (response && response.ok) {
      yield put(DealDashboardActions.dealChangePublicSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * changeDealToPublicByInvestor(api, authApi, { dashboardId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * changeNotificationStatus(api, authApi, { notificationId }) {
  try {
    const response = yield call(api.changeNotificationStatus, notificationId)
    if (response && response.ok) {
      yield put(DealDashboardActions.notificationChangeStatusSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
      const {dashboardId} = yield select(DealDashboardSelectors.getState);
      yield put(NotificationsActions.dealDashboardNotificationsRequest(dashboardId))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * changeNotificationStatus(api, authApi, { notificationId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * revertActionByAdmin(api, authApi, { dashboardId }) {
  try {
    const response = yield call(api.revertActionByAdmin, dashboardId)
    if (response && response.ok) {
      yield put(DealDashboardActions.adminRevertActionSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * revertActionByAdmin(api, authApi, { dashboardId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * viewDealClosedAdvisor(api, authApi, { dashboardId }) {
  try {
    const response = yield call(api.viewDealClosedAdvisor, dashboardId)
    if (response && response.ok) {
      yield put(DealDashboardActions.advisorSeenClosedDealSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * viewDealClosedAdvisor(api, authApi, { dashboardId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * viewDealClosedInvestor(api, authApi, { dashboardId }) {
  try {
    const response = yield call(api.viewDealClosedInvestor, dashboardId)
    if (response && response.ok) {
      yield put(DealDashboardActions.investorSeenClosedDealSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * viewDealClosedInvestor(api, authApi, { dashboardId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * uploadBilateralByAdvisor(api, authApi, { dashboardId, data }) {
  try {
    const response = yield call(api.uploadBilateralByAdvisor, dashboardId, data)
    if (response && response.ok) {
      yield put(DealDashboardActions.advisorUploadBilateralSuccess(response.data))
      yield put(DealDashboardActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealDashboardActions.dealDashboardFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * uploadBilateralByAdvisor(api, authApi, { dashboardId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealDashboardActions.dealDashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * dealDashboardSyncStartMonitor() {
  while (true) {
    const payload = yield take(DealDashboardTypes.DEAL_DASHBOARD_SYNC_TOGGLE);
    let doWork = payload.syncIsStart;
    while (doWork) {
      const {dashboardId, syncIsStart} = yield select(DealDashboardSelectors.getState);
      if (!syncIsStart) {
        break;
      }
      yield put(DealDashboardActions.dealDashboardRequest(dashboardId));
      doWork = syncIsStart;
      yield delay(10000);
    }
  }
}
