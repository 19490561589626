import React, { useState, useEffect } from 'react'
import { Button, Input, Row, Col, Select, InputNumber, TreeSelect, DatePicker } from 'antd'
import moment from 'moment'
import _ from 'lodash'

import Images from '../../Images'

const { Option } = Select;
const { RangePicker } = DatePicker;

const initial = {
  ev_range: [],
  sectors: [],
  equity_range: [],
  geography: [],
  investment_type: [],
  governance: [],
  shared: [],
  is_public: null,
  admin_status: [],
  comment: null,
  mandate: null,
}

function DealFilterPanel(props) {
  const { filterData, active, sectors, continents, governance, investmentTypes, choices } = props;

  const [data, setData] = useState(null);
  const [sectorTree, setSectorTree] = useState(null);
  const [continentTree, setContinentTree] = useState(null);

  const invTypesList = [];
  if (investmentTypes) {
    for (let i = 0; i < investmentTypes.length; i++) {
      invTypesList.push(<Option key={investmentTypes[i].id}>{investmentTypes[i].name}</Option>);
    }
  }

  useEffect(() => {
    const newData = {
      ev_range: filterData?.ev_range,
      sectors: filterData?.sectors,
      equity_range: filterData?.equity_range,
      geography: filterData?.geography,
      investment_type: filterData?.investment_type,
      governance: filterData?.governance,
      shared: filterData?.shared,
      is_public: filterData?.is_public,
      admin_status: filterData?.admin_status,
      comment: filterData?.comment,
      mandate: filterData?.mandate,
    };
    newData.shared = newData.shared.map(el => moment(el));
    setData(newData);
    applyFilters(newData, true)
  }, [0]);

  useEffect(() => {
    if (sectors) {
      const tree = sectors.map(sector => ({
        title: sector.name,
        value: sector.id,
        children: sector.children.map(child => ({
          title: child.name,
          value: child.id,
          children: child.children.map(el => ({
            title: el.name,
            value: el.id,
          }))
        }))
      }));
      setSectorTree(tree);
    }
  }, [sectors]);

  useEffect(() => {
    if (continents) {
      const tree = continents.map(continent => ({
        title: continent.name,
        value: continent.id,
        children: continent.children.map(child => ({
          title: child.name,
          value: child.id,
        }))
      }));
      setContinentTree(tree);
    }
  }, [continents]);

  const changeFilter = (field, value, index) => {
    const newData = _.cloneDeep(data);
    if (index != null) {
      newData[field][index] = value;
    } else if (field === 'investment_type') {
      newData[field] = [value];
    } else {
      newData[field] = value;
    }
    setData(newData);
  }

  const clearFilter = (fields, isArray) => {
    const newData = _.cloneDeep(data);
    fields.forEach(field => {
      newData[field] = isArray ? [] : null;
      if (field === 'investment_type') {
        newData.comment = null;
      }
    })
    setData(newData);
    applyFilters(newData);
  }

  const applyFilters = (data, start) => {
    let count = 0;
    for (let key in data) {
      if ((!Array.isArray(data[key]) && data[key] != null && key !== 'comment') || (Array.isArray(data[key]) && data[key].length)) {
        count += 1;
      }
    }
    props.changeCount(count, data, start);
  }

  const clearAll = () => {
    setData(_.cloneDeep(initial));
    applyFilters(initial);
  }

  const apply = data => {
    applyFilters(data);
    props.closeFilter();
  }

  return (
    <div className="filter-panel" style={{ display: active ? 'block' : 'none' }}>
      {
        data &&
        <>
          <Row gutter={[16, 24]} style={{ marginTop: 0, marginBottom: 0 }}>
            <Col span={12} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Sectors</div>
              <TreeSelect
                value={data.sectors}
                showSearch
                multiple
                style={{ minWidth: 'auto', width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={sectorTree}
                placeholder="Please select"
                treeDefaultExpandAll
                onChange={(value) => changeFilter('sectors', value)}
              />
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['sectors'], true)} />
            </Col>
            <Col span={12} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Geography</div>
              <TreeSelect
                value={data.geography}
                showSearch
                multiple
                style={{ minWidth: 'auto', width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={continentTree}
                placeholder="Please select"
                treeDefaultExpandAll
                onChange={(value) => changeFilter('geography', value)}
              />
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['geography'], true)} />
            </Col>

            <Col span={8} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">EV range</div>
              <Input.Group style={{ width: 'auto', display: 'flex' }}>
                <InputNumber
                  value={data.ev_range[0]}
                  style={{ width: '45%', textAlign: 'center' }}
                  placeholder="From"
                  onChange={(value) => changeFilter('ev_range', value, 0)}
                />
                <Input
                  className="site-input-split"
                  style={{
                    width: '10%',
                  }}
                  placeholder="~"
                  disabled
                />
                <InputNumber
                  value={data.ev_range[1]}
                  className="site-input-right"
                  style={{
                    width: '45%',
                    textAlign: 'center',
                  }}
                  placeholder="To"
                  onChange={(value) => changeFilter('ev_range', value, 1)}
                />
              </Input.Group>
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['ev_range'], true)} />
            </Col>
            <Col span={8} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Equity range</div>
              <Input.Group style={{ width: 'auto', display: 'flex' }}>
                <InputNumber
                  value={data.equity_range[0]}
                  style={{ width: '45%', textAlign: 'center' }}
                  placeholder="From"
                  onChange={(value) => changeFilter('equity_range', value, 0)}
                />
                <Input
                  className="site-input-split"
                  style={{
                    width: '10%',
                  }}
                  placeholder="~"
                  disabled
                />
                <InputNumber
                  value={data.equity_range[1]}
                  className="site-input-right"
                  style={{
                    width: '45%',
                    textAlign: 'center',
                  }}
                  placeholder="To"
                  onChange={(value) => changeFilter('equity_range', value, 1)}
                />
              </Input.Group>
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['equity_range'], true)} />
            </Col>
            <Col span={8} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Deal public</div>
              <Select
                value={data.is_public}
                style={{ minWidth: 'auto', width: '100%' }}
                placeholder="Please select"
                onChange={(value) => changeFilter('is_public', value)}
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['is_public'])} />
            </Col>

            <Col span={8} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Status</div>
              <Select
                value={data.admin_status}
                mode="multiple"
                style={{ minWidth: 'auto', width: '100%' }}
                placeholder="Please select"
                onChange={(value) => changeFilter('admin_status', value)}
              >
                {choices?.statuses?.map(item => {
                  return (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  )
                })}
              </Select>
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['admin_status'], true)} />
            </Col>
            <Col span={8} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Governance</div>
              <Select
                value={data.governance}
                mode="multiple"
                style={{ minWidth: 'auto', width: '100%' }}
                placeholder="Please select"
                onChange={(value) => changeFilter('governance', value)}
              >
                {governance?.map(item => {
                  return (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  )
                })}
              </Select>
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['governance'], true)} />
            </Col>
            <Col span={8} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Date</div>
              <RangePicker
                placeholder={["From", "To"]}
                allowClear={false}
                value={data.shared}
                onChange={(value) => changeFilter('shared', value)}
              />
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['shared'], true)} />
            </Col>

            <Col span={8} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Mandate</div>
              <Select
                value={data.mandate}
                style={{ minWidth: 'auto', width: '100%' }}
                placeholder="Please select"
                onChange={(value) => changeFilter('mandate', value)}
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['mandate'])} />
            </Col>
            <Col span={8} className="flex-center-vertical">
              <div className="flex-center-vertical filter-title">Type of investment</div>
              <Select
                value={data.investment_type[0]}
                style={{ minWidth: 'auto', width: '100%' }}
                placeholder="Please select"
                onChange={(value) => changeFilter('investment_type', value)}
              >
                {invTypesList}
              </Select>
              <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter(['investment_type'], true)} />
            </Col>
            {
              data.investment_type.includes('3') &&
                <Col span={8} className="flex-center-vertical">
                  <Input
                    value={data.comment}
                    placeholder="Please Specify…"
                    onChange={(e) => changeFilter('comment', e.target.value)}
                  />
                   <img className="filter-clear hidden" src={Images.closeGrey} alt="" />
                </Col>
            }
          </Row>
        </>
      }
      <Row gutter={[16, 24]} style={{ marginTop: 24, marginBottom: 0 }} justify="end">
        <div className="filter-actions flex-center-vertical">
          <span className="clear-all" onClick={clearAll}>Reset</span>
          <Button type="primary orange" className="btn-primary" onClick={() => apply(data)}>
            Apply
          </Button>
        </div>
      </Row>
    </div>
  )
}

export default DealFilterPanel
