import React, { useState, useEffect } from 'react'
import { Space, Button } from 'antd'
import { useHistory } from 'react-router-dom'

import Images from '../../Images'


function WelcomePage(props) {
  const {role} = props;

  const history = useHistory();

  return (
    <div className="sign-up-step intro-bg">
      <div className="modal-container" style={{ width: 420, padding: '24px 48px 32px' }}>
        <Space style={{ marginBottom: 24 }}>
          <img src={Images.phoneOk} alt="" />
        </Space>
        <div className="welcome-title">Welcome to<br />your dashboard</div>
        {
          role === 0 &&
            <>
              <div className="locked-text">Through your dashboard you can share deals, receive engagement opportunities from investors, and edit your profile.</div>
              <div className="welcome-note"><u>Please note:</u><br />your profile is incomplete. Please go to the Section "Experience" to fill in the missing details to enhance your chances to receive opportunities matching your expertise.</div>
            </>
        }
        {
          role === 2 &&
            <div className="locked-text">Through your dashboard you can assign origination mandates to Senior Advisors, engage them for due diligence work, and receive investment opportunities matching your filters.</div>
        }
        <Button type="primary white" className="btn-primary" style={{ width: 217, marginTop: 24 }} onClick={props.onClose}>
          Continue
        </Button>
      </div>
    </div>
  )
}

export default WelcomePage
