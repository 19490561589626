import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  firmsRequest: ['page', 'page_size', 'sort_field', 'sort_order', 'search', 'filter'],
  firmsSuccess: ['list'],

  firmRequest: ['firmId'],
  firmSuccess: ['firmData'],

  firmSaveRequest: ['data'],
  firmSaveSuccess: ['saveResult'],
  firmSaveReset: null,

  firmDeleteRequest: ['id'],
  firmDeleteSuccess: ['deleteResult'],
  firmDeleteReset: null,

  firmTableSettingsReset: null,

  firmsReset: null,
  firmSearchReset: null,

  firmFetchingReset: null
})

export const FirmsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  fetching: false,

  firmData: null,

  saveResult: null,
  saveFetching: false,

  deleteResult: null,
  deleteFetching: false,

  page:1,
  page_size:20,
  sort_field:'',
  sort_order: 1,
  search:'',
  filter: {
    ev_range: [],
    sectors: [],
    open_to_new_sectors: null,
    equity_range: [],
    geography: [],
    open_to_greenfield: null,
    governance: [],
  },
})

/* ------------- Selectors ------------- */
export const FirmsSelectors = {
  getState: state => state.firms,
}

/* ------------- Reducers ------------- */


export const firmsRequest = (state, { page, page_size, sort_field, sort_order, search, filter }) => {
  const newState = {
    fetching: true
  };
  page && (newState.page = page);
  page_size && (newState.page_size = page_size);
  newState.sort_field = sort_field ? sort_field : '';
  newState.sort_order = sort_order ? sort_order : 1;
  newState.search = search ? search : '';
  filter && (newState.filter = state.filter.merge(filter));
  return state.merge(newState)
}

export const firmsSuccess = (state, { list }) => {
  return state.merge({ fetching: false, list })
}

export const firmRequest = (state, { firmId }) => {
  return state
}

export const firmSuccess = (state, { firmData }) => {
  return state.merge({ firmData })
}

export const firmSaveRequest = (state, { data }) => {
  return state.merge({ saveFetching: true})
}

export const firmSaveSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const firmSaveReset = (state) =>{
  return state.merge({ saveFetching: false,  saveResult:null })
}

export const firmDeleteRequest = (state, { id }) => {
  return state.merge({ deleteFetching: true})
}

export const firmDeleteSuccess = (state, { deleteResult }) => {
  return state.merge({ deleteFetching: false, deleteResult })
}

export const firmDeleteReset = (state) =>{
  return state.merge({ deleteFetching: false,  deleteResult:null })
}

export const firmTableSettingsReset = (state) =>{
  const newState = {
    page:1,
    sort_field:'',
    sort_order: 1,
    search:'',
    filter: {
      ev_range: [],
      sectors: [],
      open_to_new_sectors: null,
      equity_range: [],
      geography: [],
      open_to_greenfield: null,
      governance: [],
    },
  };
  return state.merge(newState)
}

export const firmsReset = (state) =>{
  return INITIAL_STATE
}

export const firmSearchReset = (state) => {
  return state.merge({ search: '' })
}

export const firmFetchingReset = (state) => {
  return state.merge({ fetching:false, saveFetching:false, deleteFetching:false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.FIRMS_REQUEST]: firmsRequest,
  [Types.FIRMS_SUCCESS]: firmsSuccess,

  [Types.FIRM_REQUEST]: firmRequest,
  [Types.FIRM_SUCCESS]: firmSuccess,

  [Types.FIRM_SAVE_REQUEST]: firmSaveRequest,
  [Types.FIRM_SAVE_SUCCESS]: firmSaveSuccess,
  [Types.FIRM_SAVE_RESET]: firmSaveReset,

  [Types.FIRM_DELETE_REQUEST]: firmDeleteRequest,
  [Types.FIRM_DELETE_SUCCESS]: firmDeleteSuccess,
  [Types.FIRM_DELETE_RESET]: firmDeleteReset,

  [Types.FIRM_TABLE_SETTINGS_RESET]: firmTableSettingsReset,

  [Types.FIRMS_RESET]: firmsReset,
  [Types.FIRM_SEARCH_RESET]: firmSearchReset,

  [Types.FIRM_FETCHING_RESET]: firmFetchingReset,
})
