import React from 'react'
import { Form, Input, Button } from 'antd'
import Utils from '../../Utils/utils'

import Images from '../../Images'

function ResetPasswordForm(props) {
  const [form] = Form.useForm();

  const onFinish = values => {
    props.onSubmit(values);
  };

  return (
    <Form form={form} onFinish={onFinish} className="login-form" layout="vertical">

      <Form.Item
        name="email"
        rules={[
          { type: 'email', message: 'Invalid email format' },
          { required: true, message: 'This field is required' },
          { min: 5, message: 'Field must contain at least 5 characters' },
        ]}
        onKeyPress={e=>{if(e.charCode === 32) e.preventDefault()}}
        onBlur={e=>Utils.clearSpaces(e, 'email', form.setFieldsValue)}
      >
        <Input
          placeholder="Email (same as username)"
          maxLength={100}
          prefix={<img src={Images.envelope} alt="" />}
        />
      </Form.Item>

      <Button type="primary" htmlType="submit" className="btn-primary" style={{width: 217, marginTop: 24}}>
        Submit
      </Button>

    </Form>
  )
}

export default ResetPasswordForm
