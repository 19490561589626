import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import AuthApi from '../Services/AuthApi'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  signInRequest: ['email', 'password', 'pathname', 'hash'],
  signInSuccess: ['userInfo', 'accessToken', 'refreshToken'],
  signInFailure: ['error'],

  authErrorReset: null,

  updateTokens: ['accessToken', 'refreshToken'],

  logout: null,

  checkEmailExistRequest: ['email'],
  checkEmailExistSuccess: ['result'],

  inviteHashRequest: ['hash', 'role'],
  confirmEmailHashRequest: ['hash', 'role'],

  signUpRequest: ['data', 'role'],
  signUpSuccess: ['userInfo', 'accessToken', 'refreshToken'],

  confirmEmailRequest: ['hash'],
  confirmEmailSuccess: null,

  unlockRequest: ['userId'],
  unlockSuccess: ['result'],

  resetPasswordRequest: ['email'],
  resetPasswordSuccess: ['result'],

  restorePasswordRequest: ['data'],
  restorePasswordSuccess: ['result'],

  investorIntroRequest: ['data'],
  investorIntroSuccess: ['introResult'],

  advisorIntroRequest: ['data'],
  advisorIntroSuccess: ['introResult'],

  sendFirmInviteRequest: ['firmId', 'data'],
  sendFirmInviteSuccess: ['firmInviteResult'],

  investorLoginRequest: ['token'],
  investorLoginSuccess: ['userInfo', 'accessToken', 'refreshToken'],

  setInvestorSubRole: ['investorSubRole'],

  getInviteDataRequest: ['email', 'role'],
  getInviteDataSuccess: ['inviteDataResult'],

  sendInviteRequest: ['data', 'role'],
  sendInviteSuccess: ['result'],

  advisorFiltersRequest: ['advisorId', 'data'],
  advisorFiltersSuccess: ['advisorFiltersResult'],

  firmPreFiltersRequest: ['firmId', 'data'],
  firmPreFiltersSuccess: ['firmFiltersResult'],

  advisorAgreementLinkRequest: ['advisorId'],
  advisorAgreementLinkSuccess: ['advisorAgreementLinkResult'],

  mandateDealSubmittedCheckRequest: ['hash'],
  mandateDealSubmittedCheckSuccess: ['userInfo', 'accessToken', 'refreshToken'],

  updatePlatformAgreement: ['userInfo'],

  setLoginUpdate: ['loginUpdate'],

  setCurrentState: ['currentState'],

  resultsReset: null,
})

export const AuthTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  userInfo: null,
  accessToken: null,
  refreshToken: null,
  error: null,
  fetching: false,

  result: null,
  firmInviteResult: null,
  advisorFiltersResult: null,
  firmFiltersResult: null,
  inviteDataResult: null,
  advisorAgreementLinkResult: null,

  introError: null,
  introResult: null,

  isAdmin: false,
  role: null,

  investorSubRole: null,

  loginUpdate: false,

  currentState: null
})

/* ------------- Selectors ------------- */

export const AuthSelectors = {
  getUserInfo: state => state.auth.userInfo,
  getAccessToken: state => state.auth.accessToken,
  getRefreshToken: state => state.auth.refreshToken,
  getRole: state => state.auth.role
}

/* ------------- Reducers ------------- */

export const signInRequest = (state, { email, password, pathname, hash }) => {
  return state.merge({ fetching: true, error:null })
}

export const signInSuccess = (state, { userInfo, accessToken, refreshToken }) => {
  const isAdmin = userInfo.role_obj.id === 1,
        role = userInfo.role_obj.id;
  return state.merge({ fetching: false, error:null, userInfo , accessToken, refreshToken, isAdmin, role })
}

export const signInFailure = (state, { error }) => {
  return state.merge({ error, fetching: false })
}

export const authErrorReset = (state) => {
  return state.merge({ error:null, introError: null, fetching: false })
}

export const updateTokens = (state, {accessToken, refreshToken}) =>{
  return state.merge({ accessToken, refreshToken })
}

export const logout = (state) => {
  AuthApi.updateAccessToken(null, null);
  return INITIAL_STATE
}

export const checkEmailExistRequest = (state, { email }) => {
  return state.merge({ fetching: true, error:null })
}

export const checkEmailExistSuccess = (state, { result }) => {
  return state.merge({ fetching: false, error:null, result })
}

export const inviteHashRequest = (state, { hash, role }) => {
  return state.merge({ fetching: true, error:null })
}

export const confirmEmailHashRequest = (state, { hash, role }) => {
  return state.merge({ fetching: true, error:null })
}

export const signUpRequest = (state, { data, role }) => {
  return state.merge({ fetching: true, error:null })
}

export const signUpSuccess = (state, { userInfo, accessToken, refreshToken }) => {
  const isAdmin = false,
        role = userInfo.role_obj.id;
  return state.merge({ fetching: false, error:null, userInfo , accessToken, refreshToken, isAdmin, role, loginUpdate: true })
}

export const confirmEmailRequest = (state, { hash }) => {
  return state.merge({ fetching: true, error:null })
}

export const confirmEmailSuccess = (state) => {
  return state.merge({ fetching: false, error:null })
}

export const unlockRequest = (state, { userId }) => {
  return state.merge({ fetching: true, error:null })
}

export const unlockSuccess = (state, { result }) => {
  return state.merge({ fetching: false, error:null, result })
}

export const resetPasswordRequest = (state, { email }) => {
  return state.merge({ fetching: true, error:null })
}

export const resetPasswordSuccess = (state, { result }) => {
  return state.merge({ fetching: false, error:null, result })
}

export const restorePasswordRequest = (state, { data }) => {
  return state.merge({ fetching: true, error:null })
}

export const restorePasswordSuccess = (state, { result }) => {
  return state.merge({ fetching: false, error:null, result })
}

export const investorIntroRequest = (state, { data }) => {
  return state.merge({ fetching: true, introError:null })
}

export const investorIntroSuccess = (state, introResult) => {
  return state.merge({ fetching: false, introError:null, introResult })
}

export const advisorIntroRequest = (state, { data }) => {
  return state.merge({ fetching: true, introError:null })
}

export const advisorIntroSuccess = (state, introResult) => {
  return state.merge({ fetching: false, introError:null, introResult })
}

export const sendFirmInviteRequest = (state, { firmId, data }) => {
  return state.merge({ fetching: true, error:null })
}

export const sendFirmInviteSuccess = (state, { firmInviteResult }) => {
  return state.merge({ fetching: false, error:null, firmInviteResult })
}

export const investorLoginRequest = (state, { token }) => {
  return state.merge({ fetching: true, error:null })
}

export const investorLoginSuccess = (state, { userInfo, accessToken, refreshToken }) => {
  const isAdmin = userInfo.role_obj.id === 1,
        role = userInfo.role_obj.id;
  return state.merge({ fetching: false, error:null, userInfo , accessToken, refreshToken, isAdmin, role, loginUpdate: true })
}

export const setInvestorSubRole = (state, { investorSubRole }) => {
  return state.merge({ investorSubRole })
}

export const getInviteDataRequest = (state, { email, role }) => {
  return state.merge({ fetching: true, error:null })
}

export const getInviteDataSuccess = (state, { inviteDataResult }) => {
  return state.merge({ fetching: false, error:null, inviteDataResult })
}

export const sendInviteRequest = (state, { data, role }) => {
  return state.merge({ fetching: true, error:null })
}

export const sendInviteSuccess = (state, { result }) => {
  return state.merge({ fetching: false, error:null, result })
}

export const advisorFiltersRequest = (state, { advisorId, data }) => {
  return state.merge({ fetching: true, error:null })
}

export const advisorFiltersSuccess = (state, { advisorFiltersResult }) => {
  return state.merge({ fetching: false, error:null, advisorFiltersResult })
}

export const firmPreFiltersRequest = (state, { firmId, data }) => {
  return state.merge({ fetching: true, error:null })
}

export const firmPreFiltersSuccess = (state, { firmFiltersResult }) => {
  return state.merge({ fetching: false, error:null, firmFiltersResult })
}

export const advisorAgreementLinkRequest = (state, { advisorId }) => {
  return state.merge({ fetching: true, error:null })
}

export const advisorAgreementLinkSuccess = (state, { advisorAgreementLinkResult }) => {
  return state.merge({ fetching: false, error:null, advisorAgreementLinkResult })
}

export const mandateDealSubmittedCheckRequest = (state, { hash }) => {
  return state.merge({ fetching: true, error:null })
}

export const mandateDealSubmittedCheckSuccess = (state, {userInfo, accessToken, refreshToken}) => {
  const isAdmin = userInfo.role_obj.id === 1,
        role = userInfo.role_obj.id;
  return state.merge({ fetching: false, error:null, userInfo , accessToken, refreshToken, isAdmin, role, loginUpdate: true })
}

export const updatePlatformAgreement = (state, {userInfo}) => {
  return state.merge({ userInfo })
}

export const setLoginUpdate = (state, { loginUpdate }) => {
  return state.merge({ loginUpdate })
}

export const setCurrentState = (state, { currentState }) => {
  return state.merge({ currentState })
}

export const resultsReset = (state) => {
  return state.merge({
    result: null,
    firmInviteResult: null,
    advisorFiltersResult: null,
    firmFiltersResult: null,
    introResult: null,
    inviteDataResult: null,
    advisorAgreementLinkResult: null
   })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGN_IN_REQUEST]: signInRequest,
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.SIGN_IN_FAILURE]: signInFailure,

  [Types.AUTH_ERROR_RESET]: authErrorReset,

  [Types.UPDATE_TOKENS]: updateTokens,

  [Types.LOGOUT]: logout,

  [Types.CHECK_EMAIL_EXIST_REQUEST]: checkEmailExistRequest,
  [Types.CHECK_EMAIL_EXIST_SUCCESS]: checkEmailExistSuccess,

  [Types.INVITE_HASH_REQUEST]: inviteHashRequest,
  [Types.CONFIRM_EMAIL_HASH_REQUEST]: confirmEmailHashRequest,

  [Types.SIGN_UP_REQUEST]: signUpRequest,
  [Types.SIGN_UP_SUCCESS]: signUpSuccess,

  [Types.CONFIRM_EMAIL_REQUEST]: confirmEmailRequest,
  [Types.CONFIRM_EMAIL_SUCCESS]: confirmEmailSuccess,

  [Types.UNLOCK_REQUEST]: unlockRequest,
  [Types.UNLOCK_SUCCESS]: unlockSuccess,

  [Types.RESET_PASSWORD_REQUEST]: resetPasswordRequest,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,

  [Types.RESTORE_PASSWORD_REQUEST]: restorePasswordRequest,
  [Types.RESTORE_PASSWORD_SUCCESS]: restorePasswordSuccess,

  [Types.INVESTOR_INTRO_REQUEST]: investorIntroRequest,
  [Types.INVESTOR_INTRO_SUCCESS]: investorIntroSuccess,

  [Types.ADVISOR_INTRO_REQUEST]: advisorIntroRequest,
  [Types.ADVISOR_INTRO_SUCCESS]: advisorIntroSuccess,

  [Types.SEND_FIRM_INVITE_REQUEST]: sendFirmInviteRequest,
  [Types.SEND_FIRM_INVITE_SUCCESS]: sendFirmInviteSuccess,

  [Types.INVESTOR_LOGIN_REQUEST]: investorLoginRequest,
  [Types.INVESTOR_LOGIN_SUCCESS]: investorLoginSuccess,

  [Types.SET_INVESTOR_SUB_ROLE]: setInvestorSubRole,

  [Types.GET_INVITE_DATA_REQUEST]: getInviteDataRequest,
  [Types.GET_INVITE_DATA_SUCCESS]: getInviteDataSuccess,

  [Types.SEND_INVITE_REQUEST]: sendInviteRequest,
  [Types.SEND_INVITE_SUCCESS]: sendInviteSuccess,

  [Types.ADVISOR_FILTERS_REQUEST]: advisorFiltersRequest,
  [Types.ADVISOR_FILTERS_SUCCESS]: advisorFiltersSuccess,

  [Types.FIRM_PRE_FILTERS_REQUEST]: firmPreFiltersRequest,
  [Types.FIRM_PRE_FILTERS_SUCCESS]: firmPreFiltersSuccess,

  [Types.ADVISOR_AGREEMENT_LINK_REQUEST]: advisorAgreementLinkRequest,
  [Types.ADVISOR_AGREEMENT_LINK_SUCCESS]: advisorAgreementLinkSuccess,

  [Types.MANDATE_DEAL_SUBMITTED_CHECK_REQUEST]: mandateDealSubmittedCheckRequest,
  [Types.MANDATE_DEAL_SUBMITTED_CHECK_SUCCESS]: mandateDealSubmittedCheckSuccess,

  [Types.UPDATE_PLATFORM_AGREEMENT]: updatePlatformAgreement,

  [Types.SET_LOGIN_UPDATE]: setLoginUpdate,

  [Types.SET_CURRENT_STATE]: setCurrentState,

  [Types.RESULTS_RESET]: resultsReset,
})
