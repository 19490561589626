import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from '../../Redux/DealDashboardRedux'

import PopupWrapper from './PopupWrapper';

import Images from '../../Images'
import './PopupPage.less'

function DealMatchedPopupPage(props) {

  const { match, result, location } = props;
  const { dashboardId } = match.params;
  const { state } = location;
  const history = useHistory();

  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (state?.notification) {
      setNotification(state?.notification);
    }
	}, [state]);

  useEffect(() => {
    props.dealDashboardSyncToggle(false);
    return function cleanup() {
      props.dealDashboardSyncToggle(true);
    }
  }, [0]);

  useEffect(() => {
    if (result) {
      // props.resetDealDashboard();
      props.getDealDashboardInfo(dashboardId);
      history.push(`/deal-dashboard/overview/${dashboardId}`);
    }
	}, [result]);

	const handleClose = () => {
    props.changeNotificationStatus(notification);
	}

  return (
    <PopupWrapper
      width={460}
      // path={`/deal-dashboard/overview/${dashboardId}`}
    >
      <div className="dialog-content" style={{ padding: '40px 40px' }}>
        <img src={Images.phoneOk} alt="" />
        <div className="dialog-title deal">Deal Matched</div>
        <div className="deal-text" style={{ padding: '0 10px' }}>
        Congratulations, the investor has matched the deal you submitted in response to the origination mandate.
        </div>
        <div className="btn-footer" style={{ marginTop: 20 }}>
          <Button type="primary white" className="btn-primary" onClick={handleClose}>
            Continue
          </Button>
        </div>
      </div>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    result: state.dealDashboard.result,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeNotificationStatus: (notificationId) => dispatch(DealDashboardActions.notificationChangeStatusRequest(notificationId)),
  getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
  dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
  // resetDealDashboard: () => dispatch(DealDashboardActions.dealDashboardReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DealMatchedPopupPage)
