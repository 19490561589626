import React, { useState, useEffect } from 'react'
import { Progress, Collapse, Button, Tabs, Checkbox, Row, Col, Input, message, InputNumber } from 'antd'
import { useHistory } from 'react-router-dom'

import CountryGrid from '../CountryGrid'
import SectorGrid from '../SectorGrid'
import CollapseHeader from '../CollapseHeader'
import Images from '../../Images'

const { Panel } = Collapse;
const { TabPane } = Tabs;

// const addInfo = [
//   {
//     field: 'deals_expect_to_originate',
//     value: null
//   },
//   {
//     field: 'average_enterprise_value_of_the_deals',
//     value: null
//   }
// ];

function AdvisorSignUpStep2(props) {
  const {sectorFocus, geographyFocus} = props;

  const [sectorCheckedList, setSectorCheckedList] = useState(null);
  const [sectorCheckAll, setSectorCheckAll] = useState(null);
  const [sectorActiveTab, setSectorActiveTab] = useState(null);
  const [sectorOptions, setSectorOptions] = useState(null);

  const [continentCheckedList, setContinentCheckedList] = useState(null);
  const [continentCheckAll, setContinentCheckAll] = useState(null);
  const [continentActiveTab, setContinentActiveTab] = useState(null);
  const [continentOptions, setContinentOptions] = useState(null);

  // const [addInfoValues, setAddInfoValues] = useState(null);
  const [otherSectorText, setOtherSectorText] = useState(null);
  const [otherSectorValue, setOtherSectorValue] = useState(null);
  const [otherSectorList, setOtherSectorList] = useState(null);

  const [required, setRequired] = useState({
    active: false,
    industry_focus: false,
    geographic_focus: false,
    deal_origination: false
  })

  const history = useHistory();

  useEffect(()=>{
    props.getSectors();
    props.getContinents();

    // let addInfoInit = [];
    // for (let i=0; i<addInfo.length; i++) {
    //   addInfoInit.push(addInfo[i]);
    // }
    // setAddInfoValues(addInfoInit);
  }, [0]);

  useEffect(() => {
    if (sectorFocus) {
      let sectorsInit = {};
      let sectorsListInit = {};
      let otherSectorsInit = {};
      let options = {};
      sectorFocus.forEach(sector => {
        sectorsInit[sector.name] = false;
        sectorsListInit[sector.name] = [];
        otherSectorsInit[sector.name] = {
          active: false,
          text: '',
          parent: sector.id
        };
        options[sector.name] = sector.children;
      });
      setSectorActiveTab(sectorFocus[0]?.name);
      setOtherSectorList(otherSectorsInit);
      setSectorCheckAll(sectorsInit);
      setSectorCheckedList(sectorsListInit);
      setSectorOptions(options);
    }
  }, [sectorFocus]);

  useEffect(() => {
    if (sectorActiveTab) {
      setOtherSectorValue(null);
      let text = sectorFocus.find(sector => sector.name === sectorActiveTab).other_sector;
      setOtherSectorText(text);
    }
  }, [sectorActiveTab]);

  useEffect(() => {
    if (geographyFocus) {
      let continentsInit = {};
      let continentsListInit = {};
      let options = {};
      geographyFocus.forEach(continent => {
        continentsInit[continent.name] = false;
        continentsListInit[continent.name] = [];
        options[continent.name] = [];
        for (let i = 0; i < continent.children.length; i++) {
          options[continent.name].push(
            { label: continent.children[i].name, value: continent.children[i].id },
          );
        }
      });
      setContinentActiveTab(geographyFocus[0]?.name);
      setContinentCheckAll(continentsInit);
      setContinentCheckedList(continentsListInit);
      setContinentOptions(options);
    }
  }, [geographyFocus]);

  useEffect(()=>{
    if (sectorCheckedList && continentCheckedList 
      // && addInfoValues 
      && otherSectorList) {
      let checkSectors = Object.values(sectorCheckedList).some(el => el.length);
      let checkOtherSectorsList = Object.values(otherSectorList).filter(el => el.active).every(el => el.text);
      let checkContinents = Object.values(continentCheckedList).some(el => el.length);
      // let checkAddInfo = addInfoValues.every(el=> el.value != null);

      const newData = {
        industry_focus: checkSectors && checkOtherSectorsList,
        geographic_focus: checkContinents,
        // deal_origination: checkAddInfo
      };
      setRequired({...required, ...newData});
    }
  }, [sectorCheckedList, continentCheckedList, otherSectorList]);

  const onChange = (list, isSector) => {
    if (isSector) {
      let data = {...sectorCheckedList};
      data[sectorActiveTab] = list;
      setSectorCheckedList(data);
    } else {
      let data = {...continentCheckedList};
      data[continentActiveTab] = list;
      setContinentCheckedList(data);
    }
  };

  // const changeAddInfoValue = (index, value) => {
  //   let data = [...addInfoValues];
  //   data[index].value = value;
  //   setAddInfoValues(data);
  // }

  const changeOtherSector = e => {
    let data = {...otherSectorList};
    data[sectorActiveTab].active = e.target.checked;
    setOtherSectorList(data);
  }

  const changeSpecValue = e => {
    let data = {...otherSectorList};
    data[sectorActiveTab].text = e.target.value;
    setOtherSectorList(data);
  }

  const checkRequiredFields = () => {
    let checkSectors = Object.values(sectorCheckedList).some(el => el.length);
    let checkOtherSectorsList = Object.values(otherSectorList).filter(el => el.active).every(el => el.text);
    let checkContinents = Object.values(continentCheckedList).some(el => el.length);
    // let checkAddInfo = addInfoValues.every(el=> el.value != null);

    const newData = {
      active: true,
      industry_focus: checkSectors && checkOtherSectorsList,
      geographic_focus: checkContinents,
      // deal_origination: checkAddInfo
    };
    setRequired(newData);

    if (checkSectors && checkContinents && checkOtherSectorsList
      // && checkAddInfo 
      ) {
      const totalData = {
        industry_focus_sub_sectors: Object.values(sectorCheckedList).flat(),
        countries: Object.values(continentCheckedList).flat(),
        // additional_information: addInfoValues.map(el => ({
        //   [el.field]: el.value
        // })),
        additional_specification: Object.values(otherSectorList).flat().map(el => ({
          name: el.text,
          parent: el.parent
        }))
      }
      props.onChangeStep(totalData)
    } else {
      message.error('All fields must be filled in to proceed', 3);
    }
  }

  return (
    <div className="sign-up-step white">
      <img src={Images.logo} alt="" onClick={() => history.push('/')} />
      <div className="step2-content">
        <div className="step-number">Step 2/2</div>
          <div className="step-title">Sign up</div>
          <Progress
            percent={100}
            showInfo={false}
            strokeWidth={13}
            trailColor="rgba(31, 73, 228, 0.12)"
            strokeColor="#3680F6"
            status="active"
          />
          <div className="step-text">Please indicate your industry and geographical expertise by selecting the appropriate filters, and provide us with a few additional information. </div>
        </div>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) =>
            isActive ?
              <img src={Images.angleUp} alt="" />
              :
              <img src={Images.angleDown} alt="" />
          }
          expandIconPosition="right"
          className="site-collapse-custom-collapse"
          accordion
        >
          <Panel
            header={<CollapseHeader text="Industry focus" imageType="industry"/>} key="industry"
            className={`site-collapse-custom-panel${(required.active && !required.industry_focus) ? ' error-block' : ''}`}
          >
            <div>
              <div className="collapse-content-title">Please tick only the boxes related to sectors in which you have had direct work experience, and in which you would be well-placed to originate deals if engaged by investors.</div>
              <div className="tabs-content">
                <div className="tabs-header">
                  <div style={{width: 300}}>Sectors</div>
                  <div style={{width: 'auto'}}>Sub Sectors</div>
                  {/* <span onClick={()=>onCheckAllChange(true)}>{sectorCheckAll && sectorCheckAll[sectorActiveTab] ? 'Unselect all' : 'Select all'}</span> */}
                </div>
                <Tabs tabPosition="left" onChange={(key)=>setSectorActiveTab(key)} activeKey={sectorActiveTab}>
                  {
                    sectorCheckedList && sectorFocus?.map(sector =>(
                      <TabPane tab={sector.name} key={sector.name}>
                        <div className="vertical-tab-line"></div>
                        <Checkbox.Group value={sectorCheckedList[sector.name]} onChange={(list)=>onChange(list, true)}>
                          <SectorGrid
                            data={sectorOptions[sector.name]}
                          />
                        </Checkbox.Group>
                      </TabPane>
                    ))
                  }
                </Tabs>
                <div className="divider" style={{width: 'auto', height: 7, margin: '24px 0 16px'}}></div>
                <Row gutter={[16, 24]}>
                  <Col span={12} offset={12}>
                    <Checkbox
                      checked={otherSectorList && otherSectorList[sectorActiveTab].active}
                      onChange={e=>changeOtherSector(e)}
                    >
                      {otherSectorText}
                    </Checkbox>
                  </Col>
                </Row>
                  {
                    ((sectorCheckedList && sectorCheckedList[sectorActiveTab].length > 0) || (otherSectorList && otherSectorList[sectorActiveTab].active)) &&
                      <Row gutter={[16, 24]} style={{marginTop: 16}}>
                        <Col span={12}>
                          <div className="add-spec">Additional sector specification</div>
                        </Col>
                        <Col span={12} className="flex-center-vertical">
                          <Input
                            className={otherSectorList[sectorActiveTab].active && !otherSectorList[sectorActiveTab].text ? 'error-input' : ''}
                            placeholder="Please include any additional specification"
                            maxLength={180}
                            value={otherSectorValue != null ? otherSectorValue : otherSectorList[sectorActiveTab].text}
                            onChange={e=>setOtherSectorValue(e.target.value)}
                            onBlur={e=>changeSpecValue(e)}
                          />
                        </Col>
                      </Row>
                  }
              </div>
            </div>
          </Panel>
          <Panel
            header={<CollapseHeader text="Geographic focus" imageType="globe"/>} key="geography"
            className={`site-collapse-custom-panel${(required.active && !required.geographic_focus) ? ' error-block' : ''}`}
          >
            <div>
              <div className="collapse-content-title">Please tick only the boxes related to regions/countries in which you have ideally operated directly, and in which you would be well-placed to originate deal opportunities if engaged by investors.</div>
              <div className="tabs-content">
                <div className="tabs-header">
                  <div>Area</div>
                  <div>Countries</div>
                  {/* <span onClick={onCheckAllChange}>{continentCheckAll && continentCheckAll[continentActiveTab] ? 'Unselect all' : 'Select all'}</span> */}
                </div>
                <Tabs tabPosition="left" onChange={(key)=>setContinentActiveTab(key)} activeKey={continentActiveTab}>
                  {
                    continentCheckedList && geographyFocus.map(continent =>(
                      <TabPane tab={continent.name} key={continent.name}>
                        <div className="vertical-tab-line"></div>
                        <Checkbox.Group value={continentCheckedList[continent.name]} onChange={onChange}>
                          <CountryGrid
                            data={continentOptions[continent.name]}
                          />
                        </Checkbox.Group>
                      </TabPane>
                    ))
                  }
                </Tabs>
              </div>
            </div>
          </Panel>
          {/* <Panel
            header={<CollapseHeader text="Deal origination" imageType="userEdit"/>} key="add-info"
            className={`site-collapse-custom-panel${(required.active && !required.deal_origination) ? ' error-block' : ''}`}
          >
            <div>
              <div className="collapse-content-title">Let us know about your expectation on the deals you think you will be able to originate over the next 12 months. We will use this data points to make forecast on the expected volume and value of deals on the platform.</div>
              <div className="add-info-content"> */}
                {/* <div className="add-info-title">Day rate for advisory work</div>
                <div className="divider"></div>
                <div className="add-info-text">Please insert your benchmark day rate (in £) for any future due diligence advisory work undertaken via the platform. The rate will be customizable every time you enter into an engagement and is editable via your profile's section onto the platform .Please note that, per our Terms and Conditions, IDN will retain 20 percent of the total amount. As such, feel free to incorporate our mark up in your rate.</div>
                <Space className="flex-center-horizontal">
                  <InputNumber
                    min={0}
                    placeholder="Insert amount"
                    onChange={(e)=>changeAddInfoValue(0, e)}
                  />
                  <span className="enterprise-value">in £</span>
                </Space>
                <div className="add-info-title">Deal origination potential</div>
                <div className="divider"></div>
                <div className="add-info-text">Let us know about your expectation on the deals you think you will be able to originate over the next 12 months. We will use this data points to make forecast on the expected volume and value of deals on the platform.</div> */}
                {/* <div className="add-info-questions">
                  <Row gutter={[16, 24]} style={{marginBottom: 24}}>
                    <Col span={16} className="flex-center-vertical">How many deals do you expect to originate in the next 12 months?</Col>
                    <Col span={8} className="flex-center-vertical">
                      <InputNumber
                        min={0}
                        placeholder="Insert number"
                        onChange={(e)=>changeAddInfoValue(0, e)}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 24]}>
                    <Col span={16} className="flex-center-vertical">What is the average enterprise value of the deals that you expect to originate over the next 12 months?</Col>
                    <Col span={8} className="flex-center-vertical flex-wrap">
                      <InputNumber
                        min={0}
                        placeholder="Insert number"
                        onChange={(e)=>changeAddInfoValue(1, e)}
                      />
                      <span className="enterprise-value">in million £ (i.e. 100)</span>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Panel> */}
        </Collapse>
        <Button type="primary" className="btn-primary" style={{width: 217, marginTop: 58}} onClick={checkRequiredFields}>
          Confirm
        </Button>
    </div>
  )
}

export default AdvisorSignUpStep2
