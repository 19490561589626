import React from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function UltimatumSentPopupPage({ match }) {
  const { dashboardId } = match.params;

  const history = useHistory();

  return (
    <PopupWrapper
      width={320}
      path={`/deal-dashboard/overview/${dashboardId}`}
    >
      <div className="dialog-content" style={{ padding: 30 }}>
        <img src={Images.phoneOk} alt="" />
        <div className="dialog-title welcome" style={{ margin: 10 }}>Ultimatum Sent</div>
        <div
          className="deal-text"
          style={{ marginTop: 10, marginBottom: 40 }}
        >
          You can now return to <br/> your deal dashboard
        </div>
        <Button
        type="primary white" className="btn-primary welcome"
        style={{ width: 144 }}
        onClick={() => history.push(`/deal-dashboard/overview/${dashboardId}`)}>
          Continue
        </Button>
      </div>
    </PopupWrapper>
  )
}

export default UltimatumSentPopupPage
