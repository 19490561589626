import React from 'react'
import { Route } from 'react-router-dom'
import NotFoundPage from '../Containers/NotFoundPage'

function PrivateRoute({isAllowed, component: Component, ...rest}) {
  return (
    <Route {...rest} render={
      props => {
        if (isAllowed) {
          return (
            <Component {...props} />
          );
        } else {
          return (
            <NotFoundPage />
          );
        }
      }
    } />
  )
}

export default PrivateRoute
