import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import {Button, Radio, DatePicker, InputNumber, Row, Col, message} from 'antd';
import {useHistory} from 'react-router-dom';
import moment from 'moment';

import ExpertConsultActions from '../../../Redux/ExpertConsultRedux';
import DealDashboardActions from '../../../Redux/DealDashboardRedux';

import PopupWrapper from '../PopupWrapper';
import Images from '../../../Images';
import '../PopupPage.less';

const dateFormat = 'DD/MM/YYYY';

const initialState = {
	timeline_stop: null,
	remuneration: null,
	remuneration_price: null,
};

function AdvisorAgreementSignedPopupPage(props) {
	const {isAdmin, result, expertConsultInfo, match, location} = props;
	const {dashboardId} = match.params;
	const {state} = location;

  const [consultId, setConsultId] = useState(null);

	const history = useHistory();

	useEffect(() => {
		if (expertConsultInfo) {
			setConsultId(expertConsultInfo.id);
		}
	}, [expertConsultInfo]);

	const [saveData, setSaveData] = useState(initialState);
	const [priceOption1, setPriceOption1] = useState('');
	const [priceOption2, setPriceOption2] = useState('');
	const [active, setActive] = useState(false);
	const [start, setStart] = useState(false);

	useEffect(() => {
		setStart(true);
    props.dealDashboardSyncToggle(false);
		if (state) {
			const newData = {
				remuneration: state.add_info?.remuneration,
				remuneration_price: state.add_info?.daily_rate,
				timeline_stop: state?.add_info?.ends_at,
			};
			setSaveData(newData);
			if (state.add_info?.remuneration === 0) {
				setPriceOption1(state.add_info?.daily_rate);
			} else {
				setPriceOption2(state.add_info?.daily_rate);
			}
		}
    return function cleanup() {
      props.dealDashboardSyncToggle(true);
    }
  }, [0]);

  useEffect(() => {
		if (result && start) {
			props.getDealDashboardInfo(dashboardId);
			props.getExpertConsultInfo(dashboardId);
      isAdmin ?
				history.push({pathname: `/home/match-company/${dashboardId}`})
      :
				history.push({pathname: `/deal-dashboard/overview/${dashboardId}`});
    }
	}, [result])

	const changeActive = (field, value) => {
		const newData = {...saveData};
		newData[field] = value;
		setSaveData(newData);
	};

	const setPrice = e => {
		if (e?.target?.name === 'price_option1') {
			setPriceOption2('')
			setSaveData({...saveData, remuneration_price: Math.abs(Math.floor(e.target.value))});
			setPriceOption1(Math.abs(Math.floor(e.target.value)));
		}
		if (e?.target?.name === 'price_option2') {
			setPriceOption1('')
			setSaveData({...saveData, remuneration_price: Math.abs(Math.floor(e.target.value))});
			setPriceOption2(Math.abs(Math.floor(e.target.value)));
		}
	};

	const checkRequired = () => {
		if (state?.isHistory) {
			history.push(`/deal-dashboard/overview/${dashboardId}`);
			return;
    	}
		setActive(true);
		let price;
		if (saveData.remuneration != null) {
			switch(saveData.remuneration) {
				case 0: {
					price = priceOption1;
					break;
				}
				case 1: {
					price = priceOption2;
					break;
				}
				default: {
					break;
				}
			};
		}
		if (price && saveData.timeline_stop) {
			props.advisorEnterOptionsRequest(consultId, {
			  ...saveData,
				remuneration_price: price,
			  timeline_stop: moment(saveData.timeline_stop, 'DD/MM/YYYY').format('YYYY-MM-DD'),
			});
		} else {
			message.error('All fields must be filled to continue', 3);
		}
	};

	return (
		<PopupWrapper
			width={420}
			path={state?.isHistory && `/deal-dashboard/overview/${dashboardId}`}
			hasDropdown={true}
		>
			<div className='dialog-content' style={{padding: 30, paddingTop: 20, paddingBottom: 30}}>
				<img src={Images.phoneOk} alt='' />
				<div className='dialog-title bold' style={{margin: 6}}>
					Agreement signed
				</div>
				<div className='deal-text' style={{marginTop: 14}}>
					Congratulations for signing <br /> this Consulting Agreement. Please <br /> provide details on the agreed <br /> compensation and
					on the contract's end date:
				</div>

				<div
					className={`options-wrapper${
						active &&
						!(
							(saveData.remuneration === 0 || saveData.remuneration === 1 ? true : false) &&
							saveData.remuneration_price &&
							saveData.timeline_stop
						)
							? ' error-block'
							: ''
					}`}
				>
					<Radio.Group
						defaultValue={state?.add_info?.remuneration}
						onChange={e => changeActive('remuneration', e.target.value)}
					>
						<Row>
							<Col flex='248px'>
								<Radio value={0} style={{margin: 10}} disabled={state?.isHistory}>
									<span className='timeline-checkboxes' style={{fontSize: 15}}>
										Option 1<span style={{fontWeight: 'bold'}}> - day rate</span>
									</span>
								</Radio>
							</Col>
							<Col flex='auto'>
								<InputNumber
									value={priceOption1}
									name='price_option1'
									className="option-input"
									placeholder='£0'
									disabled={saveData?.remuneration !== 0 || state?.isHistory}
									maxLength='7'
									onBlur={e => setPrice(e)}
								/>
							</Col>
						</Row>
						<Row>
							<Col flex='248px'>
								<Radio value={1} style={{margin: 10}} disabled={state?.isHistory}>
									<span className='timeline-checkboxes' style={{fontSize: 15}}>
										Option 2<span style={{fontWeight: 'bold'}}> - fixed amount</span>
										<span className='timeline-startdate-text' style={{marginLeft: 10}}></span>
									</span>
								</Radio>
							</Col>
							<Col flex='auto'>
								<InputNumber
									value={priceOption2}
									name='price_option2'
									className="option-input"
									placeholder='£0'
									disabled={saveData?.remuneration !== 1 || state?.isHistory}
									maxLength='7'
									onBlur={e => setPrice(e)}
								/>
							</Col>
						</Row>
					</Radio.Group>

					<Row>
						<Col>
							<span style={{marginLeft: 30, marginRight: 10, color: '#232D53'}}>End Date</span>

							<DatePicker
								defaultValue={state?.add_info?.ends_at && moment(state.add_info.ends_at)}
								format={dateFormat}
								style={{width: 135, height: 37}}
								disabled={state?.isHistory}
								onChange={(date, dateString) => setSaveData({...saveData, timeline_stop: dateString})}
							/>
						</Col>
					</Row>
				</div>
				<div className='btn-footer' style={{marginTop: 12}}>
					<Button
						type='primary white'
						className='btn-primary flat'
						style={{width: 144, marginTop: 30}}
						onClick={checkRequired}
					>
						{state?.isHistory ? 'Go back' : 'Continue'}
					</Button>
				</div>
			</div>
		</PopupWrapper>
	);
}

const mapStateToProps = state => {
  return {
    // dashboardInfo: state.dealDashboard.dashboardInfo,
    expertConsultInfo: state.expertConsult.expertConsultInfo,
    result: state.expertConsult.result,
    isAdmin: state.auth.isAdmin,
  }
}

const mapDispatchToProps = dispatch => ({
  advisorEnterOptionsRequest: (consultId, data) => dispatch(ExpertConsultActions.advisorEnterOptionsRequest(consultId, data)),
	getExpertConsultInfo: dashboardId => dispatch(ExpertConsultActions.expertConsultingRequest(dashboardId)),
  getDealDashboardInfo: dashboardId => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
	dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorAgreementSignedPopupPage)
