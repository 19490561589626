// a library to wrap and simplify api calls
import apisauce from 'apisauce'
import moment from 'moment'
import _ from 'lodash'

// our 'constructor'
const create = (baseURL = process.env.REACT_APP_BASE_API_ROUTE) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const accessData = {accessToken:null};

  const api = apisauce.create({
    // base URL is read from the 'constructor'
    baseURL,
    // here are some default headers
    headers: {
      'Content-Type': 'application/json',

    },
    // 10 second timeout...
    timeout: 10000
  })

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than 'get', 'post' and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //

  const serialize = (obj, prefix) => {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "." + p  : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  }

  const updateAccessToken = (accessToken, refreshToken) => {
    accessData.accessToken = accessToken;
    accessData.refreshToken = refreshToken;
    if(accessToken){
      api.setHeader('Authorization', 'JWT ' + accessToken);
    } else{
      api.setHeader('Authorization', null);
    }
  }

  const getFilters = (id, role) => {
    return api.get(`/${role}/${id}/`)
  }

  const updateFilters = (id, role, data) => {
    return api.put(`/${role}/${id}/`, data)
  }

  const getFirmFilters = firmId => {
    return api.get(`/investor/company/${firmId}/`)
  }

  const updateFirmFilters = (firmId, data) => {
    return api.put(`/investor/company/${firmId}/`, data, {timeout: 30000})
  }

  const updateBilateralContract = (firmId, data) => {
    return api.put(`/investor/company/${firmId}/bilateral/`, data, {timeout: 30000})
  }

  const addBilateralContractByAdmin = (shareId, data) => {
    return api.post(`/share/share/${shareId}/add_bilateral_by_admin/`, data, {timeout: 30000})
  }

  const getSectorFocus = () => {
    return api.get(`/focus_manager/industry_focus_sector/`)
  }

  const createSectorItem = data => {
    return api.post(`/focus_manager/industry_focus_sector/`, data)
  }

  const updateSectorItem = (itemId, data) => {
    return api.put(`/focus_manager/industry_focus_sector/${itemId}/`, data)
  }

  const deleteSectorItem = itemId => {
    return api.delete(`/focus_manager/industry_focus_sector/${itemId}/`)
  }

  const changeSectorOrder = data => {
    return api.post(`/focus_manager/industry_focus_sector/change_ordering/`, data)
  }

  const getGeographyFocus = filter => {
    return api.get(`/focus_manager/geographic_focus/?` + serialize(filter))
  }

  const getEquityGovernance = () => {
    return api.get(`/focus_manager/governace_of_the_targeted_equity/`)
  }

  const getInvestmentTypes = () => {
    return api.get(`/focus_manager/investment_type/`)
  }

  const getCountries = filter => {
    const query = {};
    if (filter) {
      query.union = 'european';
    }
    return api.get(`/focus_manager/country/?` + serialize(query))
  }

  const getRegions = () => {
    return api.get(`/focus_manager/region/`)
  }

  const getInvestorFilters = hash => {
    return api.post(`/investor/company/filter/`, {hash})
  }

  const getExportData = () => {
    return api.get(`/export/export/`, null, {timeout: 30000})
  }

/*---------------*/

  const getExperience = advisorId => {
    return api.get(`/advisor/${advisorId}/experience/`)
  }

  const addExperience = (advisorId, data) => {
    return api.post(`/advisor/${advisorId}/experience/`, data)
  }

  const updateExperience = (advisorId, data) => {
    const newData = {...data};
    delete newData.id;
    return api.put(`/advisor/${advisorId}/experience/`, newData)
  }

  const deleteExperience = advisorId => {
    return api.delete(`/advisor/${advisorId}/experience/`)
  }

  const getExperienceJobs = advisorId => {
    return api.get(`/advisor/${advisorId}/experience/job/`)
  }

  const addExperienceJobs = (advisorId, jobId, data) => {
    return api.post(`/advisor/${advisorId}/experience/job/`, data)
  }

  const updateExperienceJob = (advisorId, jobId, data) => {
    const newData = {...data};
    delete newData.id;
    return api.put(`/advisor/${advisorId}/experience/job/${jobId}/`, newData)
  }

  const deleteExperienceJob = (advisorId, jobId) => {
    return api.delete(`/advisor/${advisorId}/experience/job/${jobId}/`)
  }

  const getUpdateAgreement = advisorId => {
    return api.post(`/advisor/update_agreement/${advisorId}/`)
  }

  const confirmAgreementByAdvisor = advisorId => {
    return api.post(`/advisor/confirm_agreement/${advisorId}/`)
  }

  const getAgreementBlank = () => {
    return api.get(`/advisor/platform_agreement/`)
  }

// __________________________________________
  const getUsers = (role, page, page_size, sort_field, sort_order, search, filter) => {
    const query = {};
    if (page) {
      query.page = page;
    }

    if (page_size) {
      query.page_size = page_size;
    }

    if (sort_field) {
      query.ordering = ( sort_order === "descend" ? "-" : "" ) + sort_field;
    }

    if (search && search.trim() !== "") {
      query.search = search;
    }

    if (filter) {
      if (filter.open_to_new_sectors != null) {
        query.open_to_being_matched = filter.open_to_new_sectors;
      }
      if (filter.open_to_greenfield != null) {
        query.open_to_greenfield = filter.open_to_greenfield;
      }
      if (filter.open_to_mandates != null) {
        query.open_to_origination_mandates = filter.open_to_mandates;
      }
      if (filter.status != null) {
        query.status = filter.status;
      }
      if (filter.account_manager != null) {
        query.account_manager = filter.account_manager;
      }
      if (filter.role != null) {
        query.role = filter.role;
      }
      if (filter.invest_company != null) {
        query.invest_company = filter.invest_company;
      }
      if (filter.ev_range && filter.ev_range.length) {
        if (filter.ev_range[0] != null) {
          query.enterprise_value_from = filter.ev_range[0];
        }
        if (filter.ev_range[1] != null) {
          query.enterprise_value_to = filter.ev_range[1];
        }
      }
      if (filter.equity_range && filter.equity_range.length) {
        if (filter.equity_range[0] != null) {
          query.equity_investment_from = filter.equity_range[0];
        }
        if (filter.equity_range[1] != null) {
          query.equity_investment_to = filter.equity_range[1];
        }
      }
      if (filter.sectors && filter.sectors.length) {
        query.sectors = filter.sectors.join();
      }
      if (filter.geography && filter.geography.length) {
        query.geography = filter.geography.join();
      }
      if (filter.governance && filter.governance.length) {
        query.equity_governance = filter.governance.join();
      }
      if (filter.invest_company) {
        query.invest_company = filter.invest_company;
      }
    }
    return api.get(`/user/${role}/?` + serialize(query))
  }

  //create user
  const createUser = (role, data) => {
    return api.post(`/user/${role}/`, data)
  }

  //delete user
  const deleteUser = (role, id) => {
    return api.delete(`/${role}/${id}/`)
  }

  const getInvestorRoles = () => {
    return api.get(`/investor/roles/`)
  }

  const getInvestorColleagues = filter => {
    const query = {};

    if (filter) {
      query.all = true;
    }
    return api.get(`/investor/colleagues_list/?` + serialize(query))
  }

  const getUserLockHistory = userId => {
    return api.get(`/user/lock-history/${userId}/`)
  }

  const toggleUserLock = (userId, action) => {
    return api.post(`/user/lock-history/${userId}/`, {action})
  }

  const getUserChoices = role => {
    return api.get(`/user/${role}/choices/`)
  }

  const changeUserPassword = (userId, data) => {
    return api.post(`/change_password/${userId}/`, data)
  }

  // __________________________________________
  const getFirms = (page, page_size, sort_field, sort_order, search, filter) => {
    const query = {};
    if (page) {
      query.page = page;
    }

    if (page_size) {
      query.page_size = page_size;
    }

    if (sort_field) {
      query.ordering = ( sort_order === "descend" ? "-" : "" ) + sort_field;
    }

    if (search && search.trim() !== "") {
      query.search = search;
    }

    if (filter) {
      if (filter.open_to_new_sectors != null) {
        query.open_to_being_matched = filter.open_to_new_sectors;
      }
      if (filter.open_to_greenfield != null) {
        query.open_to_greenfield = filter.open_to_greenfield;
      }
      if (filter.open_to_mandates != null) {
        query.open_to_origination_mandates = filter.open_to_mandates;
      }
      if (filter.ev_range && filter.ev_range.length) {
        if (filter.ev_range[0] != null) {
          query.enterprise_value_from = filter.ev_range[0];
        }
        if (filter.ev_range[1] != null) {
          query.enterprise_value_to = filter.ev_range[1];
        }
      }
      if (filter.equity_range && filter.equity_range.length) {
        if (filter.equity_range[0] != null) {
          query.equity_investment_from = filter.equity_range[0];
        }
        if (filter.equity_range[1] != null) {
          query.equity_investment_to = filter.equity_range[1];
        }
      }
      if (filter.sectors && filter.sectors.length) {
        query.sectors = filter.sectors.join();
      }
      if (filter.geography && filter.geography.length) {
        query.geography = filter.geography.join();
      }
      if (filter.governance && filter.governance.length) {
        query.equity_governance = filter.governance.join();
      }
    }

    return api.get('/investor/company/?' + serialize(query))
  }

  const getFirm = firmId => {
    return api.get(`/investor/company/${firmId}/`)
  }

  //create firm
  const addFirm = (data) => {
    return api.post(`/investor/company/`, data, {timeout: 30000})
  }
  //update firm
  const updateFirm = (data) => {
    const newData = {...data};
    delete newData.id;
    return api.put(`/investor/company/${data.id}/`, newData)
  }
  //delete firm
  const deleteFirm = firmId => {
    return api.delete(`/investor/company/${firmId}/`)
  }
  //________________________________________________________

  const getMailTemplates = () => {
    return api.get('/mail_template/template/')
  }

  const getMailTemplate = templateId => {
    return api.get(`/mail_template/template/${templateId}/`)
  }

  const updateMailTemplate = (templateId, data) => {
    return api.put(`/mail_template/template/${templateId}/`, data)
  }
  // ___________________________________________________________

  const getDeals = (page, page_size, sort_field, sort_order, search, filter) => {
    const query = {};
    if (page) {
      query.page = page;
    }
    if (page_size) {
      query.page_size = page_size;
    }
    if (sort_field) {
      query.ordering = ( sort_order === "descend" ? "-" : "" ) + sort_field;
    }
    if (search && search.trim() !== "") {
      query.search = search;
    }
    if (filter) {
      if (filter.group) {
        query.group = filter.group;
      }
      if (filter.open_to_new_sectors != null) {
        query.open_to_being_matched = filter.open_to_new_sectors;
      }
      if (filter.comment != null) {
        query.comment = filter.comment;
      }
      if (filter.is_public != null) {
        query.is_public = filter.is_public;
      }
      if (filter.ev_range && filter.ev_range.length) {
        if (filter.ev_range[0] != null) {
          query.enterprise_value_from = filter.ev_range[0];
        }
        if (filter.ev_range[1] != null) {
          query.enterprise_value_to = filter.ev_range[1];
        }
      }
      if (filter.equity_range && filter.equity_range.length) {
        if (filter.equity_range[0] != null) {
          query.equity_investment_from = filter.equity_range[0];
        }
        if (filter.equity_range[1] != null) {
          query.equity_investment_to = filter.equity_range[1];
        }
      }
      if (filter.equity_stake && filter.equity_stake.length) {
        if (filter.equity_stake[0] != null) {
          query.equity_stake_from = filter.equity_stake[0];
        }
        if (filter.equity_stake[1] != null) {
          query.equity_stake_to = filter.equity_stake[1];
        }
      }
      if (filter.sectors && filter.sectors.length) {
        query.sectors = filter.sectors.join();
      }
      if (filter.geography && filter.geography.length) {
        query.geography = filter.geography.join();
      }
      if (filter.governance && filter.governance.length) {
        query.equity_governance = filter.governance.join();
      }
      if (filter.admin_status && filter.admin_status.length) {
        query.admin_status = filter.admin_status.join();
      }
      if (filter.shared && filter.shared.length) {
        const data = _.cloneDeep(filter.shared).map(el => moment(el).valueOf());
        const date = data.map(el => moment(el).format('YYYY-MM-DD'));
        query.shared_after = date[0];
        query.shared_before = date[1];
      }
      if (filter.investment_type && filter.investment_type.length) {
        query.investment_type = filter.investment_type.join();
      }
      if (filter.mandate != null) {
        query.mandate = filter.mandate;
      }
    }
    return api.get('/deal/deal/?' + serialize(query))
  }

  const getDeal = dealId => {
    return api.get(`/deal/deal/${dealId}/`)
  }

  const createDeal = data => {
    return api.post(`/deal/deal/`, data)
  }

  const deleteDeal = dealId => {
    return api.delete(`/deal/deal/${dealId}/`)
  }

  const changeDealStatus = (dealId, status) => {
    return api.patch(`/deal/deal/${dealId}/`, {status})
  }

  const deleteDealNotification = (dealId, data) => {
    return api.patch(`/deal/deal/${dealId}/`, data)
  }

  const deleteDealNotification_ = dealId => {
    return api.get(`/deal/deal/${dealId}/investor_notification_off/`)
  }

  const saveDealOverview = data => {
    return api.post(`/deal/investment_overview/`, data)
  }

  const saveDealRoleInfo = data => {
    return api.post(`/deal/advisor_role/`, data)
  }

  const saveDealInvestorSought = data => {
    return api.post(`/deal/investor_sought/`, data)
  }

  const saveDealGroupData = (dealId, data) => {
    return api.put(`/deal/deal/${dealId}/group_update/`, data)
  }

  const withdrawDeal = (dealId, data) => {
    return api.post(`/deal/deal/${dealId}/withdraw/`, data)
  }

  const getDealStatuses = () => {
    return api.get(`/deal/deal/choices/`)
  }

  const getInvestorShareStatuses = () => {
    return api.get(`/deal/investors_shared/`)
  }

  const getDealInvestorsShared = data => {
    return api.post(`/deal/investors_shared/`, data)
  }

  // const getDealAdvisorShared = (page, page_size) => {
  //   const query = {};
  //   if (page) {
  //     query.page = page;
  //   }
  //   if (page_size) {
  //     query.page_size = page_size;
  //   }
  //   return api.get(`/deal/advisors_shared_active/` + serialize(query))
  // }

  const getDealAdvisorShared = (data, page, page_size) => {
    const query = {};
    if (page) {
      query.page = page;
    }
    if (page_size) {
      query.page_size = page_size;
    }
    return api.post(`/user/investor/investor_deals/?`+ serialize(query), data)
  }

  const getDealAdvisorSharedHistory = (data, page, page_size) => {
    const query = { sector: 'history' };
    if (page) {
      query.page = page;
    }
    if (page_size) {
      query.page_size = page_size;
    }
    return api.post(`user/investor/investor_deals/?` + serialize(query), data)
  }

  const shareDealWithSpecificInvestor = (investor_id, data) => {
    return api.post(`/investor/${investor_id}/admin_share/`, data)
  }

  const shareDealWithInvestors = (dealId, data) => {
    return api.post(`/deal/deal/${dealId}/admin_share/`, data, {timeout: 20000})
  }

  const reshareDealRequestByAdvisor = dealId => {
    return api.get(`/deal/deal/${dealId}/advisor_request_reshare/`)
  }

  const reshareDealByAdmin = dealId => {
    return api.get(`/deal/deal/${dealId}/admin_reshare/`)
  }

  const reshareDealByAdvisor = dealId => {
    return api.get(`/deal/deal/${dealId}/advisor_reshare/`)
  }

  const shareDealByAdvisor = dealId => {
    return api.get(`/deal/deal/${dealId}/advisor_share/`)
  }

  const applyDealByInvestor_ = (dealId, data) => {
    return api.post(`/deal/deal/${dealId}/investor_apply/`, data)
  }

  const shareDealWithColleagues_ = (dealId, data) => {
    return api.post(`/deal/deal/${dealId}/investor_send_colleagues/`, data)
  }

  const saveDealTargetStatus = data => {
    return api.post(`/deal/target_status/`, data)
  }

  const updateDealTargetStatus = (targetId, data) => {
    return api.put(`/deal/target_status/${targetId}/`, data)
  }

  const getDealTargetStatus = dealId => {
    const query = {};
    if (dealId) {
      query.deal = dealId;
    }
    return api.get(`/deal/target_status/?` + serialize(query))
  }

  const rejectMandateDeal = dealId => {
    return api.get(`/deal/deal/${dealId}/investor_reject/`)
  }

  const changeReshareDealStatus = dealId => {
    return api.get(`/deal/deal/${dealId}/to_deal_sharing/`)
  }

  const shareMandateDealByAdmin = dealId => {
    return api.get(`/deal/deal/${dealId}/admin_share_mandate_deal/`)
  }

  const changeNotificationDealStatus = notificationId => {
    return api.put(`/deal/notification/${notificationId}/`, {is_viewed: true})
  }

  // ___________________________________________________________

  const getDealShares = (page, page_size, sort_field, sort_order, filter) => {
    const query = {};
    if (page) {
      query.page = page;
    }
    if (page_size) {
      query.page_size = page_size;
    }
    if (sort_field) {
      query.ordering = ( sort_order === "descend" ? "-" : "" ) + sort_field;
    }
    if (filter) {
      if (filter.deal != null) {
        query.deal = filter.deal;
      }
      if (filter.investor_group != null) {
        query.investor_group = filter.investor_group;
      }
    }
    return api.get('/share/share/?' + serialize(query))
  }

  const getDealMatchInfo = shareId => {
    return api.get(`/share/share/${shareId}/`)
  }

  const changeDealShareStatus = (shareId, status) => {
    return api.patch(`/share/share/${shareId}/`, status)
  }

  const deleteDealSharesNotification = (shareId, data) => {
    return api.patch(`/share/share/${shareId}/`, data)
  }

  const updateDealShareData = (shareId, data) => {
    return api.put(`/share/share/${shareId}/`, data)
  }

  const getInvestorStats = () => {
    return api.get(`/share/share/investor_stats/`)
  }

  const applyDealByInvestor = (shareId, data) => {
    return api.post(`/share/share/${shareId}/investor_apply/`, data)
  }

  const reapplyDealByInvestor = shareId => {
    return api.get(`/share/share/${shareId}/investor_reconfirm/`)
  }

  const shareDealWithColleagues = (shareId, data) => {
    return api.post(`/share/share/${shareId}/investor_send_colleagues/`, data)
  }

  const getFirmDeals = (page, page_size) => {
    const query = {};
    if (page) {
      query.page = page;
    }

    if (page_size) {
      query.page_size = page_size;
    }

    return api.get('/share/firm_activities/?' + serialize(query))
  }

  const getFirmDeal = (id) => {
    return api.get(`/share/firm_activities/${id}/`)
  }

  const getFirmStats = () => {
    return api.get(`/share/firm_activities/investor_stats/`)
  }

  const withdrawApplication = (shareId, data) => {
    return api.post(`/share/share/${shareId}/investor_withdraw/`, data)
  }

  const rejectDealByInvestor = shareId => {
    return api.get(`/share/share/${shareId}/investor_reject/`)
  }

  const createMatchAnswer = data => {
    return api.post(`/share/match_answer/`, data)
  }

  const updateMatchAnswer = (answerId, data) => {
    return api.put(`/share/match_answer/${answerId}/`, data)
  }

  const keepMatchUnderReview = shareId => {
    return api.get(`/share/share/${shareId}/advisor_review/`)
  }

  const confirmMatchByAdvisor = shareId => {
    return api.get(`/share/share/${shareId}/send_mail_confirm_match/`)
  }

  const checkConfirmMatchHash = hash => {
    return api.post(`/share/share/check_hash/`, {hash})
  }

  // ___________________________________________________________

  const getDealDashboardInfo = dashboardId => {
    return api.get(`/deal_dashboard/dashboard/${dashboardId}/`)
  }

  const updateSeenByAdvisor = dashboardId => {
    return api.patch(`/deal_dashboard/dashboard/${dashboardId}/`, {seen_by_advisor: true})
  }

  const saveTargetCompany = (data, dealId) => {
    const query = {};
    if (dealId) {
      query.deal = dealId;
    }
    return api.post(`/deal_dashboard/target_company/?` + serialize(query), data)
  }

  const getTargetCompany = targetCompanyId => {
    return api.get(`/deal_dashboard/target_company/${targetCompanyId}/`)
  }

  const editTargetCompany = (targetCompanyId, data) => {
    return api.patch(`/deal_dashboard/target_company/${targetCompanyId}/`, data)
  }

  const updateSeenByInvestor = shareId => {
    return api.patch(`/share/share/${shareId}/`, {seen_by_investor: true})
  }

  const inviteColleaguesToDashboard = (dashboardId, data) => {
    return api.post(`/deal_dashboard/dashboard/${dashboardId}/investor_invite_colleagues/`, data)
  }

  const terminateDealByInvestor = (dashboardId, data) => {
    return api.post(`/deal_dashboard/dashboard/${dashboardId}/investor_terminate/`, data)
  }

  const terminateDealByAdvisor = (dashboardId, data) => {
    return api.post(`/deal_dashboard/dashboard/${dashboardId}/advisor_terminate/`, data)
  }

  const proceedAdvisorParties = (dashboardId, data) => {
    return api.post(`/deal_dashboard/dashboard/${dashboardId}/advisor_parties_proceed/`, data)
  }

  const proceedInvestorParties = (dashboardId) => {
    return api.get(`/deal_dashboard/dashboard/${dashboardId}/investor_parties_proceed/`)
  }

  const requestIntroByInvestor = (dashboardId, data) => {
    return api.post(`/deal_dashboard/dashboard/${dashboardId}/investor_request_intro/`, data, {timeout: 30000})
  }

  const occuredIntroByAdvisor = (dashboardId, data) => {
    return api.post(`/deal_dashboard/dashboard/${dashboardId}/advisor_intro_occured/`, data)
  }

  const sendUltimatumByAdvisor = (dashboardId) => {
    return api.get(`/deal_dashboard/dashboard/${dashboardId}/advisor_send_ultimatum/`)
  }

  const acceptUltimatumByInvestor = (dashboardId) => {
    return api.get(`/deal_dashboard/dashboard/${dashboardId}/investor_accept_ultimatum/`)
  }

  const rejectUltimatumByInvestor = (dashboardId) => {
    return api.get(`/deal_dashboard/dashboard/${dashboardId}/investor_reject_ultimatum/`)
  }

  const submittedNBOByInvestor = (dashboardId, data) => {
    return api.post(`/deal_dashboard/dashboard/${dashboardId}/investor_nbo_submitted/`, data)
  }

  const acceptedNBOByInvestor = (dashboardId, data) => {
    return api.post(`/deal_dashboard/dashboard/${dashboardId}/investor_nbo_accepted/`, data)
  }

  const acceptedBindingOfferByInvestor = (dashboardId, data) => {
    return api.post(`/deal_dashboard/dashboard/${dashboardId}/investor_bo_accepted/`, data, {timeout: 30000})
  }

  const closeSuccessByInvestor = (dashboardId, data) => {
    return api.post(`/deal_dashboard/dashboard/${dashboardId}/investor_close_success/`, data)
  }

  const changeDealToPublicByInvestor = (dashboardId) => {
    return api.get(`/deal_dashboard/dashboard/${dashboardId}/investor_deal_public/`)
  }

  const changeNotificationStatus = (notificationId) => {
    return api.put(`/deal_dashboard/notification/${notificationId}/`, {is_viewed: true})
  }

  const revertActionByAdmin = (dashboardId) => {
    return api.get(`/deal_dashboard/dashboard/${dashboardId}/revert_last_action/`)
  }

  const viewDealClosedAdvisor = (dashboardId) => {
    return api.get(`/deal_dashboard/dashboard/${dashboardId}/advisor_viewed_closed/`)
  }

  const viewDealClosedInvestor = (dashboardId) => {
    return api.get(`/deal_dashboard/dashboard/${dashboardId}/investor_viewed_closed/`)
  }

  const uploadBilateralByAdvisor = (dashboardId, data) => {
		return api.post(`/deal_dashboard/dashboard/${dashboardId}/advisor_upload_signed_bilateral/`, data, {timeout: 30000})
	}

  // ___________________________________________________________

  const getChatMembers = (dashboardId) => {
    return api.get(`/deal_chat/chat/${dashboardId}/`)
  }

  const removeChatNotification = (dashboardId) => {
    return api.get(`/deal_chat/chat/${dashboardId}/deactivate_notification/`)
  }

  const getChatMessages = (page, page_size, chat) => {
    const query = {};
    if (page) {
      query.page = page;
    }
    if (page_size) {
      query.page_size = page_size;
    }
    if (chat) {
      query.chat = chat;
    }
    return api.get(`/deal_chat/message/?` + serialize(query))
  }

  const sendChatMessage = (data) => {
    return api.post(`/deal_chat/message/`, data)
  }

  const setChatUserStatus = (dashboardId, user_active) => {
    return api.put(`/deal_chat/chat/${dashboardId}/user_active/`, {user_active})
  }

  // ___________________________________________________________

  const getNotifications = () => {
    return api.get(`/core/side_notifications/`)
  }

  const getInvestorDealGroupNotifications = () => {
    return api.get(`/share/share/investor_group_notifications/`)
  }

  const getAdvisorDealGroupNotifications = () => {
    return api.get(`/deal/deal/advisor_group_notifications/`)
  }

  const getDealDashboardNotifications = dashboardId => {
    return api.get(`/deal_dashboard/dashboard/${dashboardId}/side_notifications/`)
  }

  const getInvestorMandateGroupNotifications = () => {
    return api.get(`/mandate/mandate/investor_group_notifications/`)
  }

  const getAdvisorMandateGroupNotifications = () => {
    return api.get(`/mandate_share/share/advisor_group_notifications/`)
  }

  // -------------------------------

  const getExpertConsultingInfo = dashboardId => {
		return api.get(`/deal_dashboard/expert_consulting/?dashboard=${dashboardId}`)
	}

	const saveAgreementDataByInvestor = (dashboardId, data) => {
		return api.post(`/deal_dashboard/consulting_agreement/?dashboard=${dashboardId}`, data, {timeout: 30000})
	}

  const agreementDataUpdateRequest = (agreementId, data) => {
		return api.put(`/deal_dashboard/consulting_agreement/${agreementId}/`, data, {timeout: 30000})
	}

  const getDataForAgreementRequest = (dashboardId) => {
		return api.get(`/deal_dashboard/consulting_agreement/?dashboard=${dashboardId}`)
	}

  const getAgreementRequest = (consultId) => {
		return api.get(`/deal_dashboard/consulting_agreement/${consultId}/`)
	}

	const triggerActionEngageAdvisor = consultId => {
		return api.get(`/deal_dashboard/expert_consulting/${consultId}/investor_engage_advisor/`)
	}

	const triggerActionInvestorEditAgreement = consultId => {
		return api.get(`/deal_dashboard/expert_consulting/${consultId}/investor_edit_agreement/`)
	}

	const triggerActionAdvisorSignAgreement = consultId => {
		return api.get(`/deal_dashboard/expert_consulting/${consultId}/advisor_sign_agreement/`)
	}

	const hoursUploadRequest = (consultId, uploadHours) => {
		return api.post(`/deal_dashboard/expert_consulting/${consultId}/advisor_update_days/`, uploadHours, {timeout: 30000})
	}

  const hoursUpDateRequest = (consultId, uploadHours) => {
		return api.post(`/deal_dashboard/expert_consulting/${consultId}/advisor_edit_days/`, uploadHours, {timeout: 30000})
	}

  const getHoursRequest = (consultId, uploadHours) => {
		return api.get(`/deal_dashboard/expert_consulting/${consultId}/advisor_edit_days/`, uploadHours)
	}

  const investorImproveHoursRequest = consultId => {
		return api.get(`/deal_dashboard/expert_consulting/${consultId}/investor_approve_days/`)
	}

  const investorExtendAgreementRequest = consultId => {
		return api.get(`/deal_dashboard/expert_consulting/${consultId}/investor_extend_agreement/`)
	}

  const investorEditExtensionRequest = consultId => {
		return api.get(`/deal_dashboard/expert_consulting/${consultId}/investor_edit_extension/`)
	}

  const advisorSignExtensionRequest = consultId => {
		return api.get(`/deal_dashboard/expert_consulting/${consultId}/advisor_sign_extension/`)
	}

  const notificationViewedRequest = (notifId, data) => {
		return api.put(`/deal_dashboard/notification/${notifId}/`, data)
	}

  const expertConsultActionHistoryRequest = (dashboardId) => {
		return api.get(`/deal_dashboard/dashboard/${dashboardId}/consulting_actions_history/`)
	}

  const uploadAgreementByInvestor = (consultId, data) => {
		return api.post(`/deal_dashboard/expert_consulting/${consultId}/investor_upload_agreement/`, data, {timeout: 30000})
	}

  const reuploadAgreementByInvestor = (consultId, data) => {
    return api.post(`/deal_dashboard/expert_consulting/${consultId}/investor_changed_agreement/`, data, {timeout: 30000})
	}

  const uploadAgreementByAdvisor = (consultId, data) => {
    return api.post(`/deal_dashboard/expert_consulting/${consultId}/advisor_upload_signed_agreement/`, data, {timeout: 30000})
  }

  const advisorEnterOptionsRequest = (consultId, data) => {
		return api.post(`/deal_dashboard/expert_consulting/${consultId}/advisor_enter_options/`, data)
	}

  const getWorkDoneData = consultId => {
		return api.get(`/deal_dashboard/expert_consulting/${consultId}/days_to_approve/`)
	}

  const editDataByAdmin = (consultId, data) => {
		return api.post(`/deal_dashboard/expert_consulting/${consultId}/admin_edit_data/`, data)
	}

  // -------------------------------

  const getMandates = (page, page_size, sort_field, sort_order, search, filter) => {
    const query = {};
    if (page) {
      query.page = page;
    }
    if (page_size) {
      query.page_size = page_size;
    }
    if (sort_field) {
      query.ordering = ( sort_order === "descend" ? "-" : "" ) + sort_field;
    }
    if (search && search.trim() !== "") {
      query.search = search;
    }
    if (filter) {
      if (filter.group) {
        query.group = filter.group;
      }
      if (filter.comment != null) {
        query.comment = filter.comment;
      }
      if (filter.ev_range && filter.ev_range.length) {
        if (filter.ev_range[0] != null) {
          query.enterprise_value_from = filter.ev_range[0];
        }
        if (filter.ev_range[1] != null) {
          query.enterprise_value_to = filter.ev_range[1];
        }
      }
      if (filter.equity_range && filter.equity_range.length) {
        if (filter.equity_range[0] != null) {
          query.equity_investment_from = filter.equity_range[0];
        }
        if (filter.equity_range[1] != null) {
          query.equity_investment_to = filter.equity_range[1];
        }
      }
      if (filter.equity_stake_range && filter.equity_stake_range.length) {
        if (filter.equity_stake_range[0] != null) {
          query.equity_stake_from = filter.equity_stake_range[0];
        }
        if (filter.equity_stake_range[1] != null) {
          query.equity_stake_to = filter.equity_stake_range[1];
        }
      }
      if (filter.sectors && filter.sectors.length) {
        query.sectors = filter.sectors.join();
      }
      if (filter.geography && filter.geography.length) {
        query.geography = filter.geography.join();
      }
      if (filter.status && filter.status.length) {
        query.status = filter.status.join();
      }
      if (filter.shared && filter.shared.length) {
        const data = _.cloneDeep(filter.shared).map(el => moment(el).valueOf());
        const date = data.map(el => moment(el).format('YYYY-MM-DD'));
        query.shared_after = date[0];
        query.shared_before = date[1];
      }
      if (filter.investment_type && filter.investment_type.length) {
        query.investment_type = filter.investment_type.join();
      }
      if (filter.dealSize && filter.dealSize.length) {
        query.deal_size = filter.dealSize.join();
      }
    }
    return api.get('/mandate/mandate/?' + serialize(query))
  }

  const getDealsSubmitted = (page, page_size, mandateId) => {
    const query = {};
    if (page) {
      query.page = page;
    }
    if (page_size) {
      query.page_size = page_size;
    }
    return api.get(`/mandate/mandate/${mandateId}/submitted_deals/?` + serialize(query))
  }

  const getMandate = mandateId => {
    return api.get(`/mandate/mandate/${mandateId}/`)
  }

  const createMandate = data => {
    return api.post(`/mandate/mandate/`, data)
  }

  const deleteMandateNotification = (mandateId, data) => {
    return api.patch(`/mandate/mandate/${mandateId}/`, data)
  }

  const getDealSize = () => {
    return api.get(`/mandate/deal_size/`)
  }

  const saveMandateGroupData = (mandateId, data) => {
    return api.put(`/mandate/mandate/${mandateId}/group_update/`, data)
  }

  const sharedMandateByInvestor = mandateId => {
    return api.get(`/mandate/mandate/${mandateId}/investor_share/`)
  }

  const pauseMandate = mandateId => {
    return api.get(`/mandate/mandate/${mandateId}/pause_mandate/`)
  }

  const unpauseMandate = mandateId => {
    return api.get(`/mandate/mandate/${mandateId}/unpause_mandate/`)
  }

  const closeMandate = mandateId => {
    return api.get(`/mandate/mandate/${mandateId}/close_mandate/`)
  }

  const mandateAskQuestionRequest = (mandateId, data) => {
    return api.post(`/mandate/mandate/${mandateId}/mandate_question/`, data)
  }

  const answerMandateQuestion = (mandateId, data) => {
    return api.put(`/mandate/mandate/${mandateId}/mandate_answer/`, data)
  }

  const inviteColleaguesToMandate = (mandateId, data) => {
    return api.post(`/mandate/mandate/${mandateId}/invite_colleagues_to_mandate/`, data)
  }

  const getMandateStatuses = () => {
    return api.get(`/mandate/mandate/choices_mandate/`)
  }

  const getAdvisorShareStatuses = () => {
    return api.get(`/mandate/advisors_shared/`)
  }

  const getMandateAdvisorsShared = data => {
    return api.post(`/mandate/advisors_shared/`, data)
  }

  const shareMandateWithAdvisors = (mandateId, data) => {
    return api.post(`/mandate/mandate/${mandateId}/admin_share_mandate/`, data, {timeout: 20000})
  }

  const getMandateDeals = (mandateId, page, page_size, filter) => {
    const query = {};
    if (page) {
      query.page = page;
    }
    if (page_size) {
      query.page_size = page_size;
    }
    if (filter) {
      if (filter.status && filter.status.length) {
        query.deal_status = filter.status.join();
      }
      if (filter.account_manager != null) {
        query.account_manager = filter.account_manager;
      }
    }
    return api.get(`/mandate/mandate/${mandateId}/get_matches/?` + serialize(query))
  }

  const getMandateStats = mandateId => {
    return api.get(`/mandate/mandate/${mandateId}/mandate_stats/`)
  }

  const addMandateByAdvisor = mandateId => {
    return api.get(`/mandate/mandate/${mandateId}/advisor_add_mandate/`)
  }

  const changeNotificationMandateStatus = (notificationId) => {
    return api.put(`/mandate/notification/${notificationId}/`, {is_viewed: true})
  }

  const getFirmMandates = (page, page_size) => {
    const query = {};
    if (page) {
      query.page = page;
    }

    if (page_size) {
      query.page_size = page_size;
    }

    return api.get('/mandate/firm_mandate_activities/?' + serialize(query))
  }

  const getFirmMandate = mandateId => {
    return api.get(`/mandate/firm_mandate_activities/${mandateId}/`)
  }

  const getFirmActivitiesMandateStats = () => {
    return api.get(`/mandate/firm_mandate_activities/investor_stats/`)
  }

  const getFirmMandateStats = mandateId => {
    return api.get(`/mandate/firm_mandate_activities/${mandateId}/mandate_stats/`)
  }

  const getFirmMandateDeals = (mandateId, page, page_size) => {
    const query = {};
    if (page) {
      query.page = page;
    }
    if (page_size) {
      query.page_size = page_size;
    }
    return api.get(`/mandate/firm_mandate_activities/${mandateId}/get_matches/?` + serialize(query))
  }

  const editMandateQuestion = (mandateId, data) => {
    return api.put(`/mandate/mandate/${mandateId}/edit_mandate_question/`, data)
  }

  const deleteMandateQuestion = (mandateId, data) => {
    return api.post(`/mandate/mandate/${mandateId}/delete_mandate_question/`, data)
  }

  const publishMandateQuestion = (mandateId, questionId) => {
    return api.post(`/mandate/mandate/${mandateId}/publish_mandate_question/`, {id: questionId})
  }

  // -------------------------------

  const getMandateShares = (page, page_size, filter) => {
    const query = {};
    if (page) {
      query.page = page;
    }
    if (page_size) {
      query.page_size = page_size;
    }
    if (filter) {
      if (filter.advisor_group != null) {
        query.advisor_group = filter.advisor_group;
      }
    }
    return api.get('/mandate_share/share/?' + serialize(query))
  }

  const getMandateMatchInfo = mandateShareId => {
    return api.get(`/mandate_share/share/${mandateShareId}/`)
  }

  const mandateSharesDeleteRequest = mandateShareId => {
    return api.get(`/mandate_share/share/${mandateShareId}/advisor_delete_mandate/`)
  }

  const deleteMandateSharesNotification = (mandateShareId, data) => {
    return api.patch(`/mandate_share/share/${mandateShareId}/`, data)
  }

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    updateAccessToken,

    getFilters,
    updateFilters,
    getFirmFilters,
    updateFirmFilters,
    updateBilateralContract,
    addBilateralContractByAdmin,
    getSectorFocus,
    createSectorItem,
    updateSectorItem,
    deleteSectorItem,
    changeSectorOrder,
    getGeographyFocus,
    getEquityGovernance,
    getInvestmentTypes,
    getCountries,
    getRegions,
    getInvestorFilters,
    getExportData,

    getExperience,
    addExperience,
    updateExperience,
    deleteExperience,
    getExperienceJobs,
    addExperienceJobs,
    updateExperienceJob,
    deleteExperienceJob,
    getUpdateAgreement,
    confirmAgreementByAdvisor,
    getAgreementBlank,

    getUsers,
    createUser,
    deleteUser,

    getInvestorRoles,
    getInvestorColleagues,
    getUserLockHistory,
    toggleUserLock,
    getUserChoices,
    changeUserPassword,

    getFirms,
    getFirm,
    addFirm,
    updateFirm,
    deleteFirm,

    getMailTemplates,
    getMailTemplate,
    updateMailTemplate,

    getDeals,
    getDeal,
    createDeal,
    deleteDeal,
    changeDealStatus,
    deleteDealNotification,
    deleteDealNotification_,
    saveDealOverview,
    saveDealRoleInfo,
    saveDealInvestorSought,
    saveDealGroupData,
    withdrawDeal,
    getDealStatuses,
    getInvestorShareStatuses,
    getDealAdvisorShared,
    getDealAdvisorSharedHistory,
    shareDealWithSpecificInvestor,
    getDealInvestorsShared,
    shareDealWithInvestors,
    reshareDealRequestByAdvisor,
    reshareDealByAdmin,
    reshareDealByAdvisor,
    shareDealByAdvisor,
    applyDealByInvestor_,
    shareDealWithColleagues_,
    saveDealTargetStatus,
    updateDealTargetStatus,
    getDealTargetStatus,
    rejectMandateDeal,
    changeReshareDealStatus,
    shareMandateDealByAdmin,
    changeNotificationDealStatus,

    getDealShares,
    getDealMatchInfo,
    changeDealShareStatus,
    deleteDealSharesNotification,
    updateDealShareData,
    getInvestorStats,
    applyDealByInvestor,
    reapplyDealByInvestor,
    shareDealWithColleagues,
    getFirmDeals,
    getFirmDeal,
    getFirmStats,
    withdrawApplication,
    rejectDealByInvestor,
    createMatchAnswer,
    updateMatchAnswer,
    keepMatchUnderReview,
    confirmMatchByAdvisor,
    checkConfirmMatchHash,

    getDealDashboardInfo,
    updateSeenByAdvisor,
    saveTargetCompany,
    getTargetCompany,
    editTargetCompany,
    updateSeenByInvestor,
    inviteColleaguesToDashboard,
    terminateDealByInvestor,
    terminateDealByAdvisor,
    proceedAdvisorParties,
    proceedInvestorParties,
    requestIntroByInvestor,
    occuredIntroByAdvisor,
    sendUltimatumByAdvisor,
    acceptUltimatumByInvestor,
    rejectUltimatumByInvestor,
    submittedNBOByInvestor,
    acceptedNBOByInvestor,
    acceptedBindingOfferByInvestor,
    closeSuccessByInvestor,
    changeDealToPublicByInvestor,
    changeNotificationStatus,
    revertActionByAdmin,
    viewDealClosedAdvisor,
    viewDealClosedInvestor,
    uploadBilateralByAdvisor,

    getChatMembers,
    removeChatNotification,
    getChatMessages,
    sendChatMessage,
    setChatUserStatus,

    getNotifications,
    getInvestorDealGroupNotifications,
    getAdvisorDealGroupNotifications,
    getDealDashboardNotifications,
    getInvestorMandateGroupNotifications,
    getAdvisorMandateGroupNotifications,

    getExpertConsultingInfo,
    saveAgreementDataByInvestor,

    triggerActionEngageAdvisor,
    triggerActionInvestorEditAgreement,
    triggerActionAdvisorSignAgreement,
    hoursUploadRequest,
    getHoursRequest,
    hoursUpDateRequest,
    investorImproveHoursRequest,
    investorExtendAgreementRequest,
    investorEditExtensionRequest,
    advisorSignExtensionRequest,
    getAgreementRequest,
    getDataForAgreementRequest,
    agreementDataUpdateRequest,
    notificationViewedRequest,
    expertConsultActionHistoryRequest,
    uploadAgreementByInvestor,
    reuploadAgreementByInvestor,
    uploadAgreementByAdvisor,
    advisorEnterOptionsRequest,
    getWorkDoneData,
    editDataByAdmin,

    getMandates,
    getDealsSubmitted,
    getMandate,
    createMandate,
    deleteMandateNotification,
    getDealSize,
    saveMandateGroupData,
    sharedMandateByInvestor,
    pauseMandate,
    unpauseMandate,
    closeMandate,
    mandateAskQuestionRequest,
    answerMandateQuestion,
    inviteColleaguesToMandate,
    getMandateStatuses,
    getAdvisorShareStatuses,
    getMandateAdvisorsShared,
    shareMandateWithAdvisors,
    getMandateDeals,
    getMandateStats,
    addMandateByAdvisor,
    changeNotificationMandateStatus,
    getFirmMandates,
    getFirmMandate,
    getFirmActivitiesMandateStats,
    getFirmMandateStats,
    getFirmMandateDeals,
    editMandateQuestion,
    deleteMandateQuestion,
    publishMandateQuestion,

    getMandateShares,
    getMandateMatchInfo,
    mandateSharesDeleteRequest,
    deleteMandateSharesNotification
  }
}

// let's return back our create method as the default.
export default create()
