import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function IntroTargetRequestedPopupPage(props) {
  const { result, fetching, match, location } = props;
  const { dashboardId } = match.params;
  const { state } = location;

  const history = useHistory();

  useEffect(() => {
    props.dealDashboardSyncToggle(false);
    return function cleanup() {
      props.dealDashboardSyncToggle(true);
    }
  }, [0]);

  useEffect(() => {
    if (result) {
      props.getDealDashboardInfo(dashboardId);
      history.push(`/deal-dashboard/overview/${dashboardId}`);
    }
  }, [result]);

  const removeNotification = () => {
    if (state?.isHistory) {
      history.push(`/deal-dashboard/overview/${dashboardId}`);
    } else {
      props.changeNotificationStatus(state?.notification)
    }
  }

  return (
    <PopupWrapper
      width={420}
      path={`/deal-dashboard/overview/${dashboardId}`}
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content" style={{ padding: '40px' }}>
          <img src={Images.phoneclear} alt="" />
          <div className="dialog-title intro">Intro to Target Requested</div>
          <div className="deal-text intro">The investor formally requested to be introduced to the target company.</div>
          <br />
          <div className="deal-text md">Please download and review carefully the Bilateral contract shared by the Investor and make sure that it is materially consistent with the Bilateral contract form you reviewed when you matched the investor. If you agree to it, please sign it and re-upload it on the platform.</div>
          <br />
          <a href={state?.add_info?.file} className="download-link" target="_blank" rel="noopener noreferrer">
            <img src={Images.download} alt="" />
          </a>
          <br />
          <div className="deal-text sm">Please review IDN's Platform Agreement to remember your<br />rights and obligations in the context of this engagement</div>
          <div className="btn-footer">
            <Button type="primary white"
              className="btn-primary welcome"
              style={{ width: 144 }}
              onClick={removeNotification}
            >
              {state?.isHistory ? 'Go back' : 'Continue'}
            </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    result: state.dealDashboard.result,
    fetching: state.dealDashboard.fetching
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
  changeNotificationStatus: (notificationId) => dispatch(DealDashboardActions.notificationChangeStatusRequest(notificationId)),
  dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(IntroTargetRequestedPopupPage)
