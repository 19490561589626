import React, {useState, useEffect, Fragment} from 'react';
import {connect} from 'react-redux';
import {Input, Button, Upload, message, Typography } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import DealDashboardActions from '../../Redux/DealDashboardRedux'
import ChatActions from '../../Redux/ChatRedux'
import NotificationsActions from '../../Redux/NotificationsRedux'
import Images from '../../Images'

import './dealChat.less'

const {TextArea} = Input;
const { Text } = Typography;

function DealChatPage(props) {
	const {match, members, accessToken, messages, saveResult, page_size, page, ddNotifications, userStatusResult} = props;
	const {dashboardId} = match.params;

	const [stateMessages, setStateMessages] = useState(null);
	const [messageToSend, setMessageToSend] = useState('');
	const [wsChannel, setWsChannel] = useState(null);
	const [readyStatus, setReadyStatus] = useState(null);
	const [fileUid, setFileUid] = useState(null);
	const [fileData, setFileData] = useState(null);
	const [uploadList, setUploadList] = useState(true);
	const [fetchingChat, setfetchingChat] = useState(true);
	const [start, setStart] = useState(true);
	const [stateMessageCount, setStateMessageCount] = useState(0);
	const [isUpdate, setIsUpdate] = useState(false);

	const chat = +dashboardId;

	useEffect(() => {
		setIsUpdate(true);
		return () => {
			props.setChatUserStatus(dashboardId, false);
			props.chatReset();
		};
	}, [0]);

	useEffect(() => {
		if (dashboardId) {
			props.getDealDashboardInfo(dashboardId)
			props.setChatUserStatus(dashboardId, true);
			props.chatMembersRequest(dashboardId);
			props.chatMessagesRequest(1, page_size, chat);
			props.setUpdateDashboard(true);
		}
	}, [dashboardId]);

	useEffect(() => {
		if ((messages && start && isUpdate)) {
			let data = _.cloneDeep(messages.results);
			data.sort((a, b) => moment(a.sent_at).diff(moment(b.sent_at)));
			setStateMessages(data);
      setStateMessageCount(messages?.count);
			setfetchingChat(true);
			setTimeout(() => {
				goToBeginning();
			}, 150);
			setStart(false);
		}
		if (!start && messages?.count > stateMessageCount) {
      setStateMessageCount(messages?.count);
			goToBeginning();
		}
		if (!fetchingChat && messages?.count > page_size * (page - 1)) {
			let data = _.cloneDeep([...messages.results, ...stateMessages]);
			data.sort((a, b) => moment(a.sent_at).diff(moment(b.sent_at)));
			data = data.filter((el, i, arr) => (arr[i]?.sent_at !== arr[i+1]?.sent_at));
			setStateMessages(data);
			const elem = document?.getElementById('chat');
			if (elem?.scrollTop === 0 && !start) {
				elem?.scroll(0, 350);
			}
			setfetchingChat(true);
		}
	}, [stateMessages, messages]);

	useEffect(() => {
		let ws;
		const closeHandler = () => {
			setTimeout(createChannel, 3000);
		};
		function createChannel() {
			ws?.removeEventListener('close', closeHandler);
			ws?.close();
			ws = new WebSocket(`${process.env.REACT_APP_CHAT_ROUTE}/${dashboardId}/?${accessToken}`);
			ws?.addEventListener('close', closeHandler);
			setWsChannel(ws);
		}
		createChannel();
		return () => {
			ws?.removeEventListener('close', closeHandler);
			ws.close();
		};
	}, []);

	useEffect(() => {
		let messageHandler = e => {
			let newMessages = JSON.parse(e.data);
			setStateMessages(prevMessages => [...prevMessages, newMessages]);
		};
		wsChannel?.addEventListener('message', messageHandler);

		let openHandler = () => {
			setReadyStatus('OPEN WS');
		};
		wsChannel?.addEventListener('open', openHandler);

		return () => {
			wsChannel?.removeEventListener('message', messageHandler);
			wsChannel?.removeEventListener('open', openHandler);
		};
	}, [wsChannel]);

	useEffect(() => {
		if (saveResult) {
			wsChannel?.send(
				JSON.stringify({
					id: saveResult.id,
					chat: saveResult.chat,
					sender_obj: saveResult.sender_obj,
					text: saveResult.text,
					attachment: saveResult.attachment,
					sent_at: saveResult.sent_at,
				}),
			);
			props.chatMessagesRequest(1, page_size, chat);
			setFileData(null);
			setUploadList(false);
		}
	}, [saveResult]);

	useEffect(() => {
		if (dashboardId && ddNotifications?.deal_chat) {
			props.removeChatNotification(dashboardId);
		}
	}, [ddNotifications, dashboardId]);

	useEffect(() => {
		if (userStatusResult) {
			props.getDealDashboardNotifications(dashboardId);
		}
	}, [userStatusResult]);

	const sendMessage = () => {
		if (fileData !== null) {
			fileData.set('chat', dashboardId);
			fileData.set('text', messageToSend);
			props.chatMessageSaveRequest(fileData);
			props.chatMessagesRequest(1, page_size, chat);
		} else if (!messageToSend) {
			return;
		} else {
			wsChannel?.send(
				JSON.stringify({
					text: messageToSend,
				}),
			);
			props.chatMessagesRequest(1, page_size, chat);
		}
		setMessageToSend('');
	};

	const handleKeyPress = e => {
		if (e.key === 'Enter') {
			e.preventDefault();
			sendMessage();
		}
	};

	const onkeydown = e => {
		e = e || window.event;
		if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
			e.target.value = e.target.value + '\r\n';
		}
		return true;
	};

	const onChange = e => {
		setMessageToSend(e.currentTarget.value);
	};

	function onUploadFile({file, fileList}) {
		if (fileUid && fileUid === file.uid) {
			return;
		}
		setUploadList(true);
		setFileUid(file.uid);

		let formData = new FormData();

		if (fileList && fileList.length) {
			formData.set('attachment', fileList[0].originFileObj, fileList[0].originFileObj.name);
		}
		setFileData(formData);
	}

	const onScroll = e => {
		if (messages?.next && !start) {
			if (e.target.scrollTop < 300 && fetchingChat) {
				props.chatMessagesRequest(page + 1, page_size, chat);
				setfetchingChat(false);
			}
		}
	};

	function goToBeginning() {
		const elem = document?.getElementById('chat')
		elem.scroll(0, elem.scrollHeight)
	}

	let lastSender = ''

	return (
		<div className='mainchat-container'>
			<div className='chat-row'>
				<div className='link' onClick={goToBeginning}>
					Go at the beginning
				</div>
				<div className='chat-window' id='chat' onScroll={e => onScroll(e)}>
					{stateMessages?.map((el, i, arr) => {
						if (moment(arr[i].sent_at).startOf('day') > moment(arr[i - 1]?.sent_at).startOf('day') ||
							arr[0]?.sent_at === arr[i]?.sent_at) {
							el = {...el, is_first: true};
						} else {
							el = {...el, is_first: false};
						}

						if (el.sender_obj?.id !== lastSender) {
							el = {...el, name_is_first: true};
							lastSender = el.sender_obj?.id;
						} else {
							el = {...el, name_is_first: false};
						}

						return (
							<Fragment key={el.sent_at}>
								<div className={el.is_first ? 'chat-date twoborder' : 'chat-date'}>
									{el.is_first ? moment.utc(el?.sent_at).local().format('dddd, MMMM, D, YYYY') : null}
								</div>
								<div className='chat-title-wrapper'>
									<div className={el?.sender_obj?.role?.id === 2 ? 'chat-name blue' : 'chat-name'}>
										{el?.name_is_first ? el?.sender_obj?.name : null}
									</div>

									<div className={el?.name_is_first ? 'chat-time' : 'chat-time small'}>
										{moment.utc(el?.sent_at).local().format('h:mm a')}
									</div>
								</div>

								<div className={el?.name_is_first ? 'chat-text' : 'chat-text small'}>
									{el?.text}
								</div>

								{el?.attachment && (
									<a className='chat-text' href={el?.attachment} target='_blank' rel='noreferrer'>
										{el?.attachment}
									</a>
								)}
							</Fragment>
						);
					})}
				</div>
				<div className='textarea-wrapper'>
					<TextArea
						style={{margin: 4, borderRadius: 40}}
						className='input-textarea'
						autoSize={{minRows: 3, maxRows: 10}}
						onChange={e => onChange(e)}
						value={messageToSend}
						onKeyPress={handleKeyPress}
						onKeyDown={onkeydown}
					/>
				</div>
				<div className='buttonsend'>
					<Upload
						method='get'
						maxCount={1}
						showUploadList={uploadList}
						onChange={onUploadFile}
						beforeUpload={file =>
							new Promise((resolve, reject) => {
								if (file.size > 20971520) {
									message.error('File size must not exceed 20 Mbyte', 3);
									reject();
								} else {
									resolve();
								}
							})
						}
					>
						<img src={Images.attach} alt='' style={{marginRight: 20}} />
					</Upload>
					<Button type='primary blue' className='chat' onClick={sendMessage} disabled={wsChannel?.readyState !== WebSocket.OPEN}>
						Send
					</Button>
				</div>
			</div>
			<div className='aside-row'>
				<div className='main-title'>People</div>
				<div className='sub-title'>Advisor</div>
				<div className='name'>{members?.advisors[0]?.name}</div>
				<div className='sub-title' style={{color: '#3680F6'}}>
					Investor (s)
				</div>
				{members?.investors?.map(el => (
					<div className='name' key={el.id}>
						<Text ellipsis={true} >{el.name}</Text>
					</div>
				))}
			</div>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		dashboardInfo: state.dealDashboard.dashboardInfo,
		role: state.auth.role,
		accessToken: state.auth.accessToken,
		members: state.chat.members,
		messages: state.chat.messages,
		saveResult: state.chat.saveResult,
		page: state.chat.page,
		page_size: state.chat.page_size,
		fetching: state.chat.fetching,
		userStatusResult: state.chat.userStatusResult,
		ddNotifications: state.notifications.ddNotifications,
	};
};

const mapDispatchToProps = dispatch => ({
	getDealDashboardInfo: dashboardId => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
	setUpdateDashboard: (updateDashboardId) => dispatch(DealDashboardActions.setUpdateDashboard(updateDashboardId)),

	chatMembersRequest: dashboardId => dispatch(ChatActions.chatMembersRequest(dashboardId)),
	chatMessagesRequest: (page, page_size, chat) => dispatch(ChatActions.chatMessagesRequest(page, page_size, chat)),
	chatMessageSaveRequest: fileData => dispatch(ChatActions.chatMessageSaveRequest(fileData)),
	chatReset: () => dispatch(ChatActions.chatReset()),
	removeChatNotification: dashboardId => dispatch(ChatActions.chatRemoveNotificationRequest(dashboardId)),
	setChatUserStatus: (dashboardId, user_active) => dispatch(ChatActions.chatUserStatusRequest(dashboardId, user_active)),

	getDealDashboardNotifications: (dashboardId) => dispatch(NotificationsActions.dealDashboardNotificationsRequest(dashboardId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DealChatPage);
