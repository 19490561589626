import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Button  } from 'antd'
import moment from 'moment'

import './Table.less'

function ActionsTable(props){
  const { data, dashboardId } = props;

  const [dataSource, setDataSource] = useState(null);

  const history = useHistory();

  useEffect(() => {
    let arr = data ? data.asMutable({deep:true}) : [];
    arr = arr.map(el => ({
      id: el.id,
      title: el.text_obj?.name,
      status: el.is_confirmed || el.is_skipped,
      updated: el.updated,
      actionType: el.text_obj?.id,
      buttonName: el.button_text_obj?.name,
      role: el.user_role_obj?.id,
      userDate: el.user_date
    }))
    setDataSource(arr);
  }, [data]);

  function getColumns() {
    return [
      {
        title: "Action title",
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: "Status",
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => {
          return (
            text ? 'past' : 'available'
          )
        }
      },
      {
        title: "Date",
        dataIndex: 'updated',
        key: 'updated',
        render: (text, record) => {
          return (
            record.status ?
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <span>{text && moment(text).format('DD/MM/YYYY')}</span>
                <span>{text && moment(text).format('HH:mm')}</span>
              </div>
            :
              null
          )
        }
      },
      {
        title: "User date",
        dataIndex: 'userDate',
        key: 'userDate',
        render: (text, record) => {
          return (
              <span>{text ? text : ''}</span>
          )
        },
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        render: (text, record) => generateButton(record)
      }
    ];
  }

  const generateActionPath = (type, role) => {
    if (type != null) {
      switch(type) {
        case 0: return `/home/match-company/${dashboardId}/request-introduction`;
        case 1: return `/home/match-company/${dashboardId}/intro-target-occured`;
        case 2: return `/home/match-company/${dashboardId}/nbo-submitted`;
        case 3: return `/home/match-company/${dashboardId}/nbo-accepted`;
        case 4: return `/home/match-company/${dashboardId}/binding-offer-accepted`;
        case 5: return `/home/match-company/${dashboardId}/deal-successfully-closed`;
        case 6: return (
          role === 0 ?
          `/home/match-company/${dashboardId}/terminate-engagement-advisor`
          :
          `/home/match-company/${dashboardId}/terminate-engagement-investor`
        );
        case 7: return `/home/match-company/${dashboardId}/disengage`;
        case 8: return `/home/match-company/${dashboardId}/send-ultimatum`;
        case 9: return `/home/match-company/${dashboardId}/accept-ultimatum`;
        case 10: return `/home/match-company/${dashboardId}/reject-ultimatum`;
        case 11: return `/home/match-company/${dashboardId}/competitive-process-launched`;
        case 15: return `/home/match-company/${dashboardId}/engage-advisor`
				case 16: return `/home/match-company/${dashboardId}/edit-agreement`
				case 17:
        case 20: return `/home/match-company/${dashboardId}/agreement-review`;
				case 18:
				case 19: return `/home/match-company/${dashboardId}/expert-consulting-agreement`
        case 26: return `/home/match-company/${dashboardId}/upload-agreement`;
        // case 27: return `/home/match-company/${dashboardId}/reshare-deal`;
        default: break;
      }
    }
  }

  const generateButton = data => {
    const type = data.actionType === 6 || data.actionType === 7 ?
      'primary white-red'
      :
      'primary white-green';
    const path = generateActionPath(data.actionType, data.role);
    let state = null;
		// if (data.actionType === 27) {
		// 	state = {reshare: true};
		// }
    return (
      <Button type={type}
        className="btn-primary actions-btn"
        disabled={data.status}
        onClick={()=>history.push({pathname: path, state})}
      >
        {data.buttonName}
      </Button>
    )
  }

  return (
    <Table
      className="deal-table actions"
      rowKey={row => row.id}
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      pagination={false}
    />
   )


}

export default ActionsTable
