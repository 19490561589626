import React from 'react';

const PaginationNext = (props) => (
  <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 14.439L8 7.6097L0.999998 0.780428" stroke="#262F56" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default PaginationNext;
