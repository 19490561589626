import React, { useState, useEffect, useRef } from 'react'
import { Checkbox } from 'antd'
import { useResizeDetector } from 'react-resize-detector'

function CountryGrid(props) {
  const { data, region, activeItems } = props;

  const [count, setCount] = useState(null);
  const [rows, setRows] = useState(null);
  const [column, setColumn] = useState(4);
  const [result, setResult] = useState([]);
  const [colWidth, setColWidth] = useState('');
  const [choices, setChoices] = useState(null);

  const targetRef = useRef();
  const { width } = useResizeDetector({ targetRef });

  useEffect(() => {
    if (region) {
      setColumn(region);
    }
  }, [0]);

  useEffect(() => {
    if (activeItems) {
      setChoices(activeItems);
    }
  }, [activeItems]);

  useEffect(() => {
    if (width) {
      if (width > 1599) {
        setCount(column);
      } else if (width < 1200) {
        setCount(column - 2);
      } else {
        setCount(column - 1);
      }
    }
  }, [width]);

  useEffect(() => {
    if (count) {
      setColWidth(Math.floor(100 / count) + '%');
      if (data?.length) {
        setRows(Math.ceil(data.length / count));
      }
    }
  }, [count]);

  useEffect(() => {
    if (rows) {
      const newData = [];
      for (let i = 0; i < (rows < count ? count : rows); i++) {
        newData.push(data.slice(i * rows, i * rows + rows));
      }
      setResult(newData);
    }
  }, [rows]);

  return (
    <div className="grid-container">
      <div className="resize-target" ref={targetRef}></div>
      {
        data && result && result.map((res, i) => (
          <div key={i} style={{ width: colWidth }}>
            {
              res.map(el => (
                <div key={el.value} style={{ padding: 8 }}>
                  <Checkbox
                    value={el.value}
                    disabled={choices && !choices.includes(el.value)}
                  >
                    <span style={{color: (choices && !choices.includes(el.value) ? 'gray' : '#232D53')}}>{el.label}</span>
                  </Checkbox>
                </div>
              ))
            }
          </div>
        ))
      }
    </div>
  )
}

export default CountryGrid
