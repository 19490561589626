import React, { useState, useEffect } from 'react'
import { Layout, Dropdown, Menu, Breadcrumb } from 'antd'
import { useHistory } from 'react-router-dom'

import Images from '../Images'

const { Header } = Layout;

function HeaderBlock(props) {
  const {path, isAdmin, currentUser, currentFirm, dealId, mandateId, fromDP, fromInvestorHistory, investorId} = props;

  const [title, setTitle] = useState(null);
  const [chain, setChain] = useState(null);
  const [subTitle, setSubTitle] = useState(null);

  const history = useHistory();

  function handleNavigate(path) {
    history.push(path);
  }

  useEffect(()=>{
    if (path) {
      if (path.includes('deal-dashboard')) {
        setTitle('Your Deal Dashboard');
        setSubTitle(dealId);
        setChain(null);
        return;
      }
      if (path.includes('home/deals')) {
        setTitle('your deals');
        setChain(null);
        return;
      }
      if (path.includes('home/all-deals')) {
        setTitle('all deals');
        setChain(null);
        return;
      }
      if (path.includes('home/mandates')) {
        setTitle('mandates');
        setChain(null);
        return;
      }
      if (path.includes('home/expertise')) {
        setTitle('expertise');
        setChain(null);
        return;
      }
      if (path.includes('advisory-requests')) {
        setTitle('advisory requests');
        setChain(null);
        return;
      }
      if (path.includes('home/experience')) {
        setTitle('experience');
        setChain(null);
        return;
      }
      if (path.includes('home/settings')) {
        setTitle('settings & details');
        setChain(null);
        return;
      }
      // if (path.includes('all-deals')) {
      //   setTitle('all deals');
      //   setChain(null);
      // }
      // if (path.includes('ned-sa')) {
      //   setTitle('ned & sa placements');
      //   setChain(null);
      // }
      // if (path.includes('deal-advisory')) {
      //   setTitle('deal advisory');
      //   setChain(null);
      // }
      // if (path.includes('placements')) {
      //   setTitle('placements');
      //   setChain(null);
      // }
      if (path.includes("firm's-profile")) {
        setTitle("Firm's profile");
        setChain(null);
        return;
      }
      if (path.includes("firm's-activities")) {
        setTitle("Firm's activities");
        setChain(null);
        return;
      }
      if (path.includes('faqs')) {
        setTitle('FAQs');
        setChain(null);
        return;
      }
      if (path.includes('platform-agreement')) {
        setTitle('Platform Agreement');
        setChain(null);
        return;
      }
      if (path.includes('investment-firms')) {
        setTitle('Investment firms');
        setChain(null);
        return;
      }
      if (path.includes('home/investors')) {
        setTitle('Investors');
        setChain(null);
        return;
      }
      if (path.includes('home/advisors')) {
        setTitle('Advisors');
        setChain(null);
        return;
      }
      if (path.includes('mail-templates')) {
        setTitle('Mail templates');
        setChain(null);
        return;
      }
      if (path.includes('focus-manager')) {
        setTitle('Focus manager');
        setChain(null);
        return;
      }
      if (path.includes('deal-pipeline')) {
        setTitle('Deals');
        setChain(null);
        return;
      }
      if (path.includes('home/deal-details')) {
        setTitle(null);
        setChain({
          root: 'Deals',
          path: '/home/deal-pipeline',
          name: dealId
        });
        return;
      }
      if (path.includes('match-company')) {
        setTitle(null);
        setChain({
          root: 'Go back',
          path: mandateId ?
            fromDP ? `/home/deal-pipeline` : fromInvestorHistory ? `/home/investor-details/${investorId}/history-deals`
            :
              `/home/mandate-details/${mandateId}/deals`
          :
            `/home/deal-details/${dealId}/matches`,
          name: dealId
        });
        return;
      }
      if (path.includes('mandate-pipeline')) {
        setTitle('Mandates');
        setChain(null);
        return;
      }
      if (path.includes('home/mandate-details')) {
        setTitle(null);
        setChain({
          root: 'Mandates',
          path: '/home/mandate-pipeline',
          name: mandateId
        });
        return;
      }
      if (path.includes('/home/firm-details')) {
        setTitle(null);
        setChain({
          root: 'Investment firms',
          path: '/home/investment-firms',
          name: currentFirm
        });
        return;
      }
      if (path.includes('investor-details')) {
        setTitle(null);
        setChain({
          root: 'Investors',
          path: '/home/investors',
          name: currentUser
        });
        return;
      }
      if (path.includes('advisor-details')) {
        setTitle(null);
        setChain({
          root: 'Advisors',
          path: '/home/advisors',
          name: currentUser
        });
        return;
      }
    }
  }, [path, fromDP, dealId, mandateId, currentUser, currentFirm, fromInvestorHistory]);

  const helpMenu = (
    <Menu>
      <Menu.Item>
        <span onClick={()=>handleNavigate('/home/faqs')}>FAQs & Help</span>
      </Menu.Item>
    </Menu>
  );

  const profileMenu = (
    <Menu>
      {
        !isAdmin &&
          <>
            <Menu.Item>
              <span onClick={()=>handleNavigate('/home/settings')}>Settings & details</span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item>
              <span onClick={()=>handleNavigate('/home/platform-agreement')}>Platform Agreement</span>
            </Menu.Item>
            <Menu.Divider />
          </>
      }
      <Menu.Item>
        <span onClick={()=>handleNavigate('/logout')}>Log out</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="header-block">
      {
        title &&
          <div className="header-title">{title}</div>
      }
      {
        subTitle &&
          <div className="header-title" style={{marginLeft: 80, marginRight: 'auto'}}>{subTitle}</div>
      }
      {
        chain &&
          <Breadcrumb separator="/">
            <Breadcrumb.Item className="root-breadcrumb" onClick={() => handleNavigate(chain.path)}>{chain.root}</Breadcrumb.Item>
            <Breadcrumb.Item className="child-breadcrumb">{chain.name}</Breadcrumb.Item>
          </Breadcrumb>
      }
      <div className="header-action">
        {/* {
          !isAdmin &&
            <Dropdown overlayClassName="custom-dropdown" overlay={helpMenu} placement="bottomCenter" arrow trigger={['click']}>
              <img src={Images.questionWhite} alt="" />
            </Dropdown>
        } */}
      <Dropdown overlayClassName="custom-dropdown" overlay={profileMenu} placement="bottom" arrow trigger={['click']}>
        <img src={Images.profile} alt="" />
      </Dropdown>
      </div>
    </Header>
  )
}

export default HeaderBlock
