import React, { useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { Button, Input, message, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import PopupWrapper from './PopupWrapper';
import MandatesActions from '../../Redux/MandatesRedux'

import './PopupPage.less'

const { TextArea } = Input;

function AskQuestionPopupPage(props) {
  const { fetching, mandateAskQuestionResult, match, location } = props;
  const { tab, mandateShareId, mandateId } = match.params;
  const { state } = location;

  const [value, setValue] = useState(null);
  const [error, setError] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (mandateAskQuestionResult) {
      mandateId && history.push({pathname: `/home/mandate-share-info-all/${mandateId}`});
      mandateShareId && history.push({pathname: `/home/mandate-share-info/${mandateShareId}/${tab}`});
    }
  }, [mandateAskQuestionResult]);

  const checkRequired = () => {
    if (value == null) {
      message.error('Please write your question', 3);
      setError(true)
    } else {
      const data = {
        question: value
      };
      if (state) {
        props.mandateAskQuestionRequest(state, data);
      } else {
        props.mandateAskQuestionRequest(mandateId, data);
      }
    }
  }

  return (
    <PopupWrapper
      width={420}
      path={tab ? `/home/mandate-share-info/${mandateShareId}/${tab}` : `/home/mandate-share-info-all/${mandateId}`}
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content" style={{ padding: '40px 20px' }}>
          <div className="dialog-title deal">Ask a question</div>
          <div className="deal-text" style={{fontSize: 12, fontWeight: 700}}>Ask a question to the Investor in relation to this mandate.</div>
          <br/>
          <div className="deal-text" style={{fontSize: 11}}>
          Please note: all questions will be published on the Mandate Summary and made visible to all Senior Advisors
          </div>
          <br/>
          <TextArea
            className={error && 'error-input'}
            rows={4}
            maxLength={500}
            placeholder={'XXXXX'}
            onBlur={(e) => setValue(e.target.value)}
          />
          <br />
          <div className="btn-footer" style={{marginTop: 20}}>
            <Button type="primary white" className="btn-primary" onClick={checkRequired}>
              Submit
            </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    mandateAskQuestionResult: state.mandates.mandateAskQuestionResult,
    mandateId: state.mandates.mandateId,
    fetching: state.mandates.fetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  mandateAskQuestionRequest: (mandateId, data) => dispatch(MandatesActions.mandateAskQuestionRequest(mandateId, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AskQuestionPopupPage)
