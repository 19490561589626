import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  filtersRequest: ['id', 'role'],
  filtersSuccess: ['filters'],

  filtersSaveRequest: ['id', 'role', 'data'],
  filtersSaveSuccess: ['saveResult'],
  filtersSaveReset: null,

  firmFiltersRequest: ['firmId'],
  firmFiltersSuccess: ['firmFilters'],
  firmFiltersReset: null,

  firmFiltersSaveRequest: ['firmId', 'data'],
  firmFiltersSaveSuccess: ['saveResult'],

  bilateralContractSaveRequest: ['firmId', 'data'],
  bilateralContractSaveSuccess: ['bilateralUpdateResult'],

  addBilateralContractByAdminRequest: ['shareId', 'data'],
  addBilateralContractByAdminSuccess: ['addBilateralByAdminResult'],

  sectorFocusRequest: null,
  sectorFocusSuccess: ['sectorFocus'],

  sectorFocusItemCreateRequest: ['data'],
  sectorFocusItemCreateSuccess: ['saveResult'],

  sectorFocusItemUpdateRequest: ['itemId', 'data'],
  sectorFocusItemUpdateSuccess: ['saveResult'],

  sectorFocusItemDeleteRequest: ['itemId'],
  sectorFocusItemDeleteSuccess: ['deleteResult'],

  sectorFocusOrderRequest: ['data'],
  sectorFocusOrderSuccess: ['changeOrderResult'],

  geographyFocusRequest: ['filter'],
  geographyFocusSuccess: ['geographyFocus'],
  geographyFocusReset: null,

  equityGovernanceRequest: null,
  equityGovernanceSuccess: ['equityGovernanceList'],

  investmentTypesRequest: null,
  investmentTypesSuccess: ['investmentTypes'],

  countriesRequest: null,
  countriesSuccess: ['countries'],

  euCountriesRequest: ['filter'],
  euCountriesSuccess: ['euCountries'],

  regionsRequest: null,
  regionsSuccess: ['regions'],

  investorFirmFiltersRequest: ['hash'],
  investorFirmFiltersSuccess: ['filters'],

  advisorUpdateAgreementRequest: ['advisorId'],
  advisorUpdateAgreementSuccess: ['updateAgreementResult'],

  advisorConfirmAgreementRequest: ['advisorId'],
  advisorConfirmAgreementSuccess: ['confirmAgreementResult'],

  advisorAgreementBlankRequest: null,
  advisorAgreementBlankSuccess: ['advisorAgreementBlankResult'],

  exportDataRequest: null,
  exportDataSuccess: ['exportData'],
  exportDataReset: null,

  setUserName: ['name', 'surname'],
  setFirmName: ['name'],
  resetNames: null,

  tableSettingsReset: null,
  setTableSettingsFetching: ['tableSettingsFetching'],

  settingsReset: null,

  resultsReset: null,
  settingsFetchingReset: null
})

export const SettingsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  filters: null,
  firmFilters: null,
  fetching: false,

  saveResult: null,
  saveFetching: false,

  deleteResult: null,
  deleteFetching: false,

  changeOrderResult: null,
  updateAgreementResult: null,
  confirmAgreementResult: null,
  bilateralUpdateResult: null,
  addBilateralByAdminResult: null,
  advisorAgreementBlankResult: null,

  sectorFocus: null,
  geographyFocus: null,
  equityGovernanceList: null,
  investmentTypes: null,

  countries: null,
  euCountries: null,
  regions: null,

  userName: null,
  firmName: null,

  exportData: null,

  tableSettingsFetching: false
})

/* ------------- Selectors ------------- */
export const SettingsSelectors = {
  getState: (state) => state.settings
}

/* ------------- Reducers ------------- */

export const filtersRequest = (state, { id, role }) =>{
  return state.merge({ fetching: true })
}

export const filtersSuccess = (state, { filters }) => {
  return state.merge({ fetching: false, filters })
}

export const filtersSaveRequest = (state, { id, role, data }) => {
  return state.merge({ saveFetching: true })
}

export const filtersSaveSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const filtersSaveReset = (state) =>{
  return state.merge({ saveFetching: false,  saveResult:null })
}

export const firmFiltersRequest = (state, { firmId }) =>{
  return state.merge({ fetching: true })
}

export const firmFiltersSuccess = (state, { firmFilters }) => {
  return state.merge({ fetching: false, firmFilters })
}

export const firmFiltersReset = (state) =>{
  return state.merge({ fetching: false,  firmFilters: null })
}

export const firmFiltersSaveRequest = (state, { firmId, data }) => {
  return state.merge({ saveFetching: true })
}

export const firmFiltersSaveSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const bilateralContractSaveRequest = (state, { firmId, data }) => {
  return state.merge({ saveFetching: true })
}

export const bilateralContractSaveSuccess = (state, { bilateralUpdateResult }) => {
  return state.merge({ saveFetching: false, bilateralUpdateResult })
}

export const addBilateralContractByAdminRequest = (state, { shareId, data }) => {
  return state.merge({ saveFetching: true })
}
export const addBilateralContractByAdminSuccess = (state, { addBilateralByAdminResult }) => {
  return state.merge({ saveFetching: false, addBilateralByAdminResult })
}

export const sectorFocusRequest = (state) =>{
  return state.merge({ fetching: true })
}

export const sectorFocusSuccess = (state, {sectorFocus}) =>{
  return state.merge({ fetching: false, sectorFocus })
}

export const sectorFocusItemCreateRequest = (state, { data }) =>{
  return state.merge({ saveFetching: true })
}

export const sectorFocusItemCreateSuccess = (state, {saveResult}) =>{
  return state.merge({ saveFetching: false, saveResult })
}

export const sectorFocusItemUpdateRequest = (state, { itemId, data }) =>{
  return state.merge({ saveFetching: true })
}

export const sectorFocusItemUpdateSuccess = (state, {saveResult}) =>{
  return state.merge({ saveFetching: false, saveResult })
}

export const sectorFocusItemDeleteRequest = (state, { itemId }) =>{
  return state.merge({ deleteFetching: true })
}

export const sectorFocusItemDeleteSuccess = (state, {deleteResult}) =>{
  return state.merge({ deleteFetching: false, deleteResult })
}

export const sectorFocusOrderRequest = (state, { data }) =>{
  return state.merge({ saveFetching: true })
}

export const sectorFocusOrderSuccess = (state, {changeOrderResult}) =>{
  return state.merge({ saveFetching: false, changeOrderResult })
}

export const geographyFocusRequest = (state, {filter}) =>{
  return state.merge({ fetching: true })
}

export const geographyFocusSuccess = (state, {geographyFocus}) =>{
  return state.merge({ fetching: false, geographyFocus })
}

export const geographyFocusReset = (state) =>{
  return state.merge({ geographyFocus: null })
}

export const equityGovernanceRequest = (state) =>{
  return state.merge({ fetching: true })
}

export const equityGovernanceSuccess = (state, {equityGovernanceList}) =>{
  return state.merge({ fetching: false, equityGovernanceList })
}

export const investmentTypesRequest = (state) =>{
  return state.merge({ fetching: true })
}

export const investmentTypesSuccess = (state, {investmentTypes}) =>{
  return state.merge({ fetching: false, investmentTypes })
}

export const countriesRequest = (state, {}) =>{
  return state.merge({ fetching: true })
}

export const countriesSuccess = (state, {countries}) =>{
  return state.merge({ fetching: false, countries })
}

export const euCountriesRequest = (state, {filter}) =>{
  return state.merge({ fetching: true })
}

export const euCountriesSuccess = (state, {euCountries}) =>{
  return state.merge({ fetching: false, euCountries })
}

export const regionsRequest = (state, {}) =>{
  return state.merge({ fetching: true })
}

export const regionsSuccess = (state, {regions}) =>{
  return state.merge({ fetching: false, regions })
}

export const investorFirmFiltersRequest = (state, {hash}) =>{
  return state.merge({ fetching: true })
}

export const investorFirmFiltersSuccess = (state, {filters}) =>{
  return state.merge({ fetching: false, filters })
}

export const advisorUpdateAgreementRequest = (state, {advisorId}) =>{
  return state.merge({ fetching: true })
}

export const advisorUpdateAgreementSuccess = (state, {updateAgreementResult}) =>{
  return state.merge({ fetching: false, updateAgreementResult })
}

export const advisorConfirmAgreementRequest = (state, {advisorId}) =>{
  return state.merge({ fetching: true })
}

export const advisorConfirmAgreementSuccess = (state, {confirmAgreementResult}) =>{
  return state.merge({ fetching: false, confirmAgreementResult })
}

export const advisorAgreementBlankRequest = (state) =>{
  return state.merge({ fetching: true })
}

export const advisorAgreementBlankSuccess = (state, {advisorAgreementBlankResult}) =>{
  return state.merge({ fetching: false, advisorAgreementBlankResult })
}

export const exportDataRequest = (state) =>{
  return state.merge({ fetching: true })
}

export const exportDataSuccess = (state, {exportData}) =>{
  return state.merge({ fetching: false, exportData })
}

export const exportDataReset = (state) =>{
  return state.merge({ exportData: null })
}

export const setUserName = (state, {name, surname}) =>{
  return state.merge({ userName: `${name} ${surname}` })
}

export const setFirmName = (state, {name}) =>{
  return state.merge({ firmName: name })
}

export const resetNames = (state) =>{
  return state.merge({ userName: null, firmName: null })
}

export const tableSettingsReset = (state) =>{
  return state
}

export const setTableSettingsFetching = (state, {tableSettingsFetching}) =>{
  return state.merge({ tableSettingsFetching })
}

export const settingsReset = (state) =>{
  return INITIAL_STATE
}

export const resultsReset = (state) => {
  return state.merge({
    saveResult: null,
    deleteResult: null,
    changeOrderResult: null,
    updateAgreementResult: null,
    confirmAgreementResult: null,
    bilateralUpdateResult: null,
    advisorAgreementBlankResult: null,
    addBilateralByAdminResult: null,
  })
}

export const settingsFetchingReset = (state) => {
  return state.merge({ fetching:false, saveFetching:false, deleteFetching:false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.FILTERS_REQUEST]: filtersRequest,
  [Types.FILTERS_SUCCESS]: filtersSuccess,

  [Types.FILTERS_SAVE_REQUEST]: filtersSaveRequest,
  [Types.FILTERS_SAVE_SUCCESS]: filtersSaveSuccess,
  [Types.FILTERS_SAVE_RESET]: filtersSaveReset,

  [Types.FIRM_FILTERS_REQUEST]: firmFiltersRequest,
  [Types.FIRM_FILTERS_SUCCESS]: firmFiltersSuccess,
  [Types.FIRM_FILTERS_RESET]: firmFiltersReset,

  [Types.FIRM_FILTERS_SAVE_REQUEST]: firmFiltersSaveRequest,
  [Types.FIRM_FILTERS_SAVE_SUCCESS]: firmFiltersSaveSuccess,

  [Types.BILATERAL_CONTRACT_SAVE_REQUEST]: bilateralContractSaveRequest,
  [Types.BILATERAL_CONTRACT_SAVE_SUCCESS]: bilateralContractSaveSuccess,

  [Types.ADD_BILATERAL_CONTRACT_BY_ADMIN_REQUEST]: addBilateralContractByAdminRequest,
  [Types.ADD_BILATERAL_CONTRACT_BY_ADMIN_SUCCESS]: addBilateralContractByAdminSuccess,

  [Types.SECTOR_FOCUS_REQUEST]: sectorFocusRequest,
  [Types.SECTOR_FOCUS_SUCCESS]: sectorFocusSuccess,

  [Types.SECTOR_FOCUS_ITEM_CREATE_REQUEST]: sectorFocusItemCreateRequest,
  [Types.SECTOR_FOCUS_ITEM_CREATE_SUCCESS]: sectorFocusItemCreateSuccess,

  [Types.SECTOR_FOCUS_ITEM_UPDATE_REQUEST]: sectorFocusItemUpdateRequest,
  [Types.SECTOR_FOCUS_ITEM_UPDATE_SUCCESS]: sectorFocusItemUpdateSuccess,

  [Types.SECTOR_FOCUS_ITEM_DELETE_REQUEST]: sectorFocusItemDeleteRequest,
  [Types.SECTOR_FOCUS_ITEM_DELETE_SUCCESS]: sectorFocusItemDeleteSuccess,

  [Types.SECTOR_FOCUS_ORDER_REQUEST]: sectorFocusOrderRequest,
  [Types.SECTOR_FOCUS_ORDER_SUCCESS]: sectorFocusOrderSuccess,

  [Types.GEOGRAPHY_FOCUS_REQUEST]: geographyFocusRequest,
  [Types.GEOGRAPHY_FOCUS_SUCCESS]: geographyFocusSuccess,
  [Types.GEOGRAPHY_FOCUS_RESET]: geographyFocusReset,

  [Types.EQUITY_GOVERNANCE_REQUEST]: equityGovernanceRequest,
  [Types.EQUITY_GOVERNANCE_SUCCESS]: equityGovernanceSuccess,

  [Types.INVESTMENT_TYPES_REQUEST]: investmentTypesRequest,
  [Types.INVESTMENT_TYPES_SUCCESS]: investmentTypesSuccess,

  [Types.COUNTRIES_REQUEST]: countriesRequest,
  [Types.COUNTRIES_SUCCESS]: countriesSuccess,

  [Types.EU_COUNTRIES_REQUEST]: euCountriesRequest,
  [Types.EU_COUNTRIES_SUCCESS]: euCountriesSuccess,

  [Types.REGIONS_REQUEST]: regionsRequest,
  [Types.REGIONS_SUCCESS]: regionsSuccess,

  [Types.INVESTOR_FIRM_FILTERS_REQUEST]: investorFirmFiltersRequest,
  [Types.INVESTOR_FIRM_FILTERS_SUCCESS]: investorFirmFiltersSuccess,

  [Types.ADVISOR_UPDATE_AGREEMENT_REQUEST]: advisorUpdateAgreementRequest,
  [Types.ADVISOR_UPDATE_AGREEMENT_SUCCESS]: advisorUpdateAgreementSuccess,

  [Types.ADVISOR_CONFIRM_AGREEMENT_REQUEST]: advisorConfirmAgreementRequest,
  [Types.ADVISOR_CONFIRM_AGREEMENT_SUCCESS]: advisorConfirmAgreementSuccess,

  [Types.ADVISOR_AGREEMENT_BLANK_REQUEST]: advisorAgreementBlankRequest,
  [Types.ADVISOR_AGREEMENT_BLANK_SUCCESS]: advisorAgreementBlankSuccess,

  [Types.EXPORT_DATA_REQUEST]: exportDataRequest,
  [Types.EXPORT_DATA_SUCCESS]: exportDataSuccess,
  [Types.EXPORT_DATA_RESET]: exportDataReset,

  [Types.SET_USER_NAME]: setUserName,
  [Types.SET_FIRM_NAME]: setFirmName,
  [Types.RESET_NAMES]: resetNames,

  [Types.TABLE_SETTINGS_RESET]: tableSettingsReset,

  [Types.SETTINGS_RESET]: settingsReset,

  [Types.RESULTS_RESET]: resultsReset,

  [Types.SETTINGS_FETCHING_RESET]: settingsFetchingReset,
})
