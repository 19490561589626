import React, { useState, useEffect } from 'react'
import { Button, Spin, message } from 'antd'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import PopupWrapper from './PopupWrapper'
import Images from '../../Images'
import DealSharesActions from '../../Redux/DealSharesRedux'
import DealsActions from '../../Redux/DealsRedux'
import './PopupPage.less'

function RejectDealPopupPage(props) {
  const { dealInvestorRejectResult, dealSharesFetching, mandateDealRejectResult, dealsFetching, match, location } = props;
  const { shareId, tab, dealId } = match.params;
  const { state } = location;

  const history = useHistory();

  useEffect(() => {
    if (dealInvestorRejectResult) {
      history.push('/home/deals')
    }
  }, [dealInvestorRejectResult]);

  useEffect(() => {
    if (mandateDealRejectResult) {
      history.push(`/home/mandate/${state?.mandateId}/matches`);
    }
  }, [mandateDealRejectResult]);

  const onReject = () => {
    tab ?
      props.rejectDealByInvestor(shareId)
    :
      props.rejectMandateDeal(dealId);
  }

  return (
    <PopupWrapper
      width={320}
      path={tab ?
        `/home/deal-share-info/${shareId}/${tab}`
        :
        `/home/deal-for-mandate/${dealId}${shareId ? '/' + shareId : ''}`
      }
    >
      <Spin spinning={dealSharesFetching || dealsFetching}>
        <div className="dialog-content" style={{ padding: '24px 40px' }}>
          <img src={Images.phoneCancel} alt="" />
          <div className="dialog-title deal">Reject deal</div>
          <div className="deal-text">Are you sure you want to reject this deal?</div>
          <div className="btn-footer" style={{ marginTop: 24 }}>
            <Button type="primary white" className="btn-primary" onClick={onReject}>
              Confirm
          </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    dealInvestorRejectResult: state.dealShares.dealInvestorRejectResult,
    dealSharesFetching: state.dealShares.fetching,
    mandateDealRejectResult: state.deals.mandateDealRejectResult,
    dealsFetching: state.deals.fetching
  }
}

const mapDispatchToProps = (dispatch) => ({
  rejectDealByInvestor: (shareId) => dispatch(DealSharesActions.dealInvestorRejectRequest(shareId)),
  rejectMandateDeal: (dealId) => dispatch(DealsActions.mandateDealRejectRequest(dealId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RejectDealPopupPage)
