import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Input } from 'antd'

import InvestorFilterPanel from '../../Components/FilterPanels/InvestorFilterPanel'
import InvestorsTable from '../../Components/Tables/InvestorsTable'
import UsersActions from '../../Redux/UsersRedux'
import SettingsActions from '../../Redux/SettingsRedux'
import Images from '../../Images'

const { Search } = Input;

function InvestorsPage(props) {
  const { users, sectorFocus, geographyFocus, equityGovernanceList } = props;

  const [active, setActive] = useState(false);
  const [count, setCount] = useState(null);

  const history = useHistory();

  function handleNavigate(path) {
    history.push(path);
  }

  useEffect(() => {
    props.getUsers('investor', users.page_inv, users.page_size_inv, users.sort_field_inv, users.sort_order_inv, users.search_inv, users.filter_inv);
    props.getSectors();
    props.getContinents({oecd: true});
    props.getEquityGovernance();
    props.getChoices('investor');
  }, [0]);


  function handleSearch(val) {
    if (users.search === val) {
      return;
    }
    props.getUsers('investor', 1, users.page_size_inv, users.sort_field_inv, users.sort_order_inv, val, users.filter_inv);
  }

  function changeCount(value, data, start) {
    setCount(value);
    !start && props.getUsers('investor', 1, users.page_size_inv, users.sort_field_inv, users.sort_order_inv, users.search_inv, data);
  }

  function handleChangePage(page, page_size, sort_field, sort_order) {
    props.getUsers('investor', page, page_size, sort_field, sort_order, users.search_inv,  users.filter_inv);
  }

  function closeFilter() {
    setActive(!active);
  }

  return (
    <div className="main-container admin-container">
      <div className="action-panel">
        <Search
          placeholder="Search"
          allowClear
          className="content-search"
          suffix={<img src={Images.search} alt="" />}
          defaultValue={users.search_inv}
          onSearch={handleSearch}
        />
        <Button type={`primary${count ? ' orange' : ' white'}`} className="btn-primary" onClick={closeFilter}>
          {'Filter' + (count ? ` (${count})` : '')}
        </Button>
        <Button type="primary" className="btn-primary" style={{ marginLeft: 'auto'}} onClick={() => handleNavigate('/home/investors/add-investor')}>
          Add Investor
        </Button>
        <InvestorFilterPanel
          filterData={users.filter_inv}
          active={active}
          sectors={sectorFocus}
          continents={geographyFocus}
          governance={equityGovernanceList}
          choices={users.choices}
          forInvestors={true}
          changeCount={changeCount}
          closeFilter={closeFilter}
        />
      </div>
      <div className="table-container">
        <InvestorsTable
          expanded={true}
          data={users.list}
          page={users.page_inv}
          fetching={users.fetching}
          pageSize={users.page_size_inv}
          onChangePage={handleChangePage}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    equityGovernanceList: state.settings.equityGovernanceList,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUsers: (role, page, page_size, sort_field, sort_order, search, filter) => dispatch(UsersActions.usersRequest(role, page, page_size, sort_field, sort_order, search, filter)),
  getChoices: (role) => dispatch(UsersActions.userChoicesRequest(role)),

  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: (filter) => dispatch(SettingsActions.geographyFocusRequest(filter)),
  getEquityGovernance: () => dispatch(SettingsActions.equityGovernanceRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvestorsPage)
