import { call, put, delay, take, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import MandateSharesActions, {MandateSharesTypes, MandateSharesSelectors} from '../Redux/MandateSharesRedux'
import AuthActions, {AuthSelectors} from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'
import NotificationsActions from '../Redux/NotificationsRedux'

export function * getMandateShares(api, authApi, { page, page_size, filter }) {
  try {
    const response = yield call(api.getMandateShares, page, page_size, filter)
    if (response && response.ok) {
      yield put(MandateSharesActions.mandateSharesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandateSharesActions.mandateSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getMandateShares(api, authApi, { page, page_size, filter })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(MandateSharesActions.mandateSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getMandateMatchInfo(api, authApi, { mandateShareId }) {
  try {
    const response = yield call(api.getMandateMatchInfo, mandateShareId)
    if (response && response.ok) {
      yield put(MandateSharesActions.mandateShareMatchSuccess(response.data))
      yield put(MandateSharesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandateSharesActions.mandateSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getMandateMatchInfo(api, authApi, { mandateShareId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(MandateSharesActions.mandateSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * mandateSharesDeleteRequest (api, authApi, { mandateShareId }) {
  try {
    const response = yield call(api.mandateSharesDeleteRequest,  mandateShareId)
    if (response && response.ok) {
      yield put(MandateSharesActions.mandateSharesDeleteSuccess(response.data))
      yield put(push('/home/mandates/closed'))
      yield put(MandateSharesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandateSharesActions.mandateSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * mandateSharesDeleteRequest(api, authApi, { mandateShareId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(MandateSharesActions.mandateSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * deleteMandateSharesNotification(api, authApi, { mandateShareId, data }) {
  try {
    const response = yield call(api.deleteMandateSharesNotification, mandateShareId, data)
    if (response && response.ok) {
      yield put(MandateSharesActions.mandateSharesNotificationDeleteSuccess(response.data))
      yield put(MandateSharesActions.resultsReset())
      const role = yield select(AuthSelectors.getRole);
      yield put(NotificationsActions.notificationsRequest())
      if (role === 0) {
        yield put(NotificationsActions.advisorDealGroupNotificationsRequest())
      } else if (role === 2) {
        yield put(NotificationsActions.investorDealGroupNotificationsRequest())
      }
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MandateSharesActions.mandateSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * deleteMandateSharesNotification(api, authApi, { mandateShareId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MandateSharesActions.mandateSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * mandateSharesSyncStartMonitor() {
  while (true) {
    const payload = yield take(MandateSharesTypes.MANDATE_SHARES_SYNC_TOGGLE);
    let doWork = payload.syncIsStart;
    while (doWork) {
      const {page, page_size, filter, syncIsStart} = yield select(MandateSharesSelectors.getState);
      if (!syncIsStart) {
        break;
      }
      yield put(MandateSharesActions.mandateSharesRequest(page, page_size, filter));
      doWork = syncIsStart;
      yield delay(30000);
    }
  }
}
