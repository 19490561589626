import React, { useState, useEffect } from 'react'
import { Button, Spin, message } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

import DealDashboardActions from '../../Redux/DealDashboardRedux'
import DealSharesActions from '../../Redux/DealSharesRedux'
import DiscloseCompanyAdvisorForm from '../../Components/Forms/DiscloseCompanyAdvisorForm'
import Images from '../../Images'


function DiscloseCompanyAdvisorPage(props) {
  const { result, fetching, matchConfirmHashResult, match, errors} = props;
  const { hash } = match.params;

  const [shareId, setShareId] = useState(null);

  const history = useHistory();

  useEffect(() => {
    props.checkConfirmMatchHash(hash);
  }, [0]);

  useEffect(() => {
    if (errors) {
      message.error('Please reconfirm your deal to get new link to your email', 5);
      history.push(`/home/deals/your`);
    }
  }, [errors]);

  useEffect(() => {
    if (matchConfirmHashResult) {
      setShareId(matchConfirmHashResult.share_id);
      // history.push(`/home/deal-disclose-company-advisor/${hash}/match-confirmed`);
    }
  }, [matchConfirmHashResult]);

  useEffect(() => {
    if (result) {
      history.push(`/deal-dashboard/overview/${result.dashboard}`);
    }
  }, [result]);

  function handleSubmit(data) {
    data.deal_share = shareId;
    props.saveTargetCompany(data);
  }

  return (
    <div className="sign-up-step filters-edit wo-padding"
      style={{ padding: '124px 80px 32px' }}
    >
      <div className="absolute-header">
        <div className="logo-block">
          <img src={Images.logoWhite} alt="" />
        </div>
      </div>
      <Spin size="large" spinning={fetching} wrapperClassName="dtc-loader">
        <div className="return-block" style={{ padding: '19px 0 0 0' }}>
          <Link to={`/home/deals`}>
            <img src={Images.arrowLeft} alt="" />
            <span>Back to main dashboard</span>
          </Link>
        </div>

        <div className="dtc-form" >
          <div className="dtc-title">Disclose Target Company</div>
          <DiscloseCompanyAdvisorForm
            onSubmit={handleSubmit}
          />

        </div>
        <div style={{ fontSize: 20, marginBottom: 8, marginTop: 33, fontWeight: 500, }}>IMPORTANT</div>
        <div className='important-block'>
          By clicking "Submit", the Target details will be automatically shared with the investor.
          Make sure that the details you inserted are correct. If you included a "Target Description", make sure that the language is clear and formal.
        </div>

        <Button type="primary" htmlType="submit" className="btn-primary" form="disclose-company-form" style={{ width: 217, marginTop: 16 }}>
          Submit
        </Button>
      </Spin>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    result: state.dealDashboard.saveResult,
    fetching: state.dealDashboard.saveFetching,
    matchConfirmHashResult: state.dealShares.matchConfirmHashResult,
    errors: state.errors.data,
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveTargetCompany: (data) => dispatch(DealDashboardActions.targetCompanySaveRequest(data)),
  checkConfirmMatchHash: (hash) => dispatch(DealSharesActions.matchConfirmHashRequest(hash)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DiscloseCompanyAdvisorPage)
