import { call, put, delay } from 'redux-saga/effects'
import AuthActions from '../Redux/AuthRedux'
import SettingsActions from '../Redux/SettingsRedux'
import { push } from 'connected-react-router'
import AuthApi from '../Services/AuthApi'

export function* signIn(authApi, { email, password, pathname, hash }) {
  try {
    const response = yield call(authApi.signIn, email, password, hash)
    if (response && response.access && response.userInfo) {
      yield put(AuthActions.signInSuccess(response.userInfo, response.access, response.refresh))
      yield delay(500)
      if (response.userInfo.role_obj.id === 1) {
        yield put(push('/home/deal-pipeline'))
      } else {
        yield put(SettingsActions.settingsReset())
        if (response.userInfo.role_obj.id === 0) {
          yield put(push(pathname || '/home/deals'))
        } else {
          yield put(push(pathname))
        }
      }
    } else {
      if (response && response.ok) {
        yield delay(500)
        yield put(push('/investor-verification'))
        yield put(AuthActions.authErrorReset())
      } else if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(response.data))
          if (response.data.is_blocked) {
            yield delay(500)
            yield put(push({
              pathname: '/locked',
              state: response.data.user_id
            }))
          }
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* checkEmailExist(authApi, { email }) {
  try {
    AuthApi.updateAccessToken(null, null)
    const response = yield call(authApi.checkEmailExist, email)
    if (response && response.ok) {
      yield put(AuthActions.checkEmailExistSuccess(response.data))
      yield put(AuthActions.resultsReset())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* checkInviteHash(authApi, { hash, role }) {
  try {
    AuthApi.updateAccessToken(null, null)
    const response = yield call(authApi.checkInviteHash, hash, role)
    if (response && response.ok) {
      yield put(AuthActions.logout())
      if (role === 'advisor') {
        yield delay(500)
        yield put(push({
          pathname: '/advisor/sign-up/step/1',
          state: {
            hash,
            preview: response.data
          }
        }))
      } else if (role === 'investor') {
        const data = {
          name: response.data.name,
          surname: response.data.surname,
          email: response.data.email,
          company_name: response.data.invest_company?.name
        }
        yield put(AuthActions.setInvestorSubRole(response.data.role))
        yield delay(500)
        yield put(push({
          pathname: '/investor/sign-up/step/create-account',
          state: {
            hash,
            preview: data,
            firm: response.data.invest_company
          }
        }))
      }
      yield put(AuthActions.authErrorReset())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
          yield put(AuthActions.logout())
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(AuthActions.authErrorReset())
      yield put(push('/'))
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* checkConfirmEmailHash(authApi, { hash, role }) {
  try {
    AuthApi.updateAccessToken(null, null)
    const response = yield call(authApi.checkConfirmEmailHash, hash)
    if (response && response.ok) {
      if (role === 'advisor') {
        yield delay(500)
        yield put(push('/login'))
      }
      yield put(AuthActions.authErrorReset())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(push('/'))
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* signUp(authApi, { data, role }) {
  try {
    AuthApi.updateAccessToken(null, null)
    const response = yield call(authApi.signUp, data, role)
    if (response && response.ok && response.data && response.data.access) {
      yield put(AuthActions.signUpSuccess(response.data.user, response.data.access, response.data.refresh))
      if (role === 'investor') {
        AuthApi.updateAccessToken(response.data.access, response.data.refresh)
        yield put(AuthActions.setInvestorSubRole(response.data.user.investor_role.id))
      }
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* confirmEmail(authApi, {hash}) {
  try {
    AuthApi.updateAccessToken(null, null)
    const response = yield call(authApi.confirmEmail, hash)
    if (response && response.ok) {
      yield put(AuthActions.confirmEmailSuccess())
      yield put(AuthActions.logout())
      yield delay(500)
      yield put(push('/advisor/sign-up/step/finish'))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(push('/login'))
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* unlockAccount(authApi, { userId }) {
  try {
    AuthApi.updateAccessToken(null, null)
    const response = yield call(authApi.unlockAccount, userId)
    if (response && response.ok) {
      yield put(AuthActions.unlockSuccess(response.data))
      yield put(AuthActions.resultsReset())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(push('/login'))
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* resetPassword(authApi, { email }) {
  try {
    AuthApi.updateAccessToken(null, null)
    const response = yield call(authApi.resetPassword, email)
    if (response && response.ok) {
      yield put(AuthActions.resetPasswordSuccess(response.data))
      yield put(AuthActions.resultsReset())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* restorePassword(authApi, { data }) {
  try {
    AuthApi.updateAccessToken(null, null)
    const response = yield call(authApi.restorePassword, data)
    if (response && response.ok) {
      yield put(AuthActions.restorePasswordSuccess(response.data))
      yield put(AuthActions.resultsReset())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* sendIntroData(authApi, { data }) {
  try {
    AuthApi.updateAccessToken(null, null)
    const response = yield call(authApi.sendIntroData, data)
    if (response && response.ok) {
      yield put(AuthActions.investorIntroSuccess(response.data))
      yield put(AuthActions.resultsReset())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* sendApplicationForm(authApi, { data }) {
  try {
    AuthApi.updateAccessToken(null, null)
    const response = yield call(authApi.sendApplicationForm, data)
    if (response && response.ok) {
      yield put(AuthActions.advisorIntroSuccess(response.data))
      yield put(AuthActions.resultsReset())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* sendFirmInvite(authApi, { firmId, data }) {
  try {
    const response = yield call(authApi.sendFirmInvite, firmId, data)
    if (response && response.ok) {
      yield put(AuthActions.sendFirmInviteSuccess(response.data))
      yield put(AuthActions.resultsReset())
      yield delay(500)
      yield put(push('/invite-colleagues/success'))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* investorLogin(authApi, { token }) {
  try {
    AuthApi.updateAccessToken(null, null)
    const response = yield call(authApi.investorLogin, token)
    if (response && response.ok && response.data && response.data.access) {
      AuthApi.updateAccessToken(response.data.access, response.data.refresh)
      yield put(AuthActions.setInvestorSubRole(response.data.user.investor_role.id))
      yield put(AuthActions.investorLoginSuccess(response.data.user, response.data.access, response.data.refresh))
      yield delay(1000)
      yield put(SettingsActions.settingsReset())
      yield put(push('/home/deals'))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(push('/login'))
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* getInviteData(authApi, { email, role }) {
  try {
    const response = yield call(authApi.getInviteData, email, role)
    if (response && response.ok) {
      yield put(AuthActions.getInviteDataSuccess(response.data))
      yield put(AuthActions.resultsReset())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
          yield put(AuthActions.logout())
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(AuthActions.authErrorReset())
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* sendUserInvite(authApi, { data, role }) {
  try {
    const response = yield call(authApi.sendUserInvite, data, role)
    if (response && response.ok) {
      yield put(AuthActions.sendInviteSuccess(response.data))
      yield put(AuthActions.resultsReset())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
          yield put(AuthActions.logout())
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(AuthActions.authErrorReset())
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* saveAdvisorFilters(authApi, { advisorId, data }) {
  try {
    AuthApi.updateAccessToken(null, null)
    const response = yield call(authApi.saveAdvisorFilters, advisorId, data)
    if (response && response.ok) {
      yield put(AuthActions.advisorFiltersSuccess(response.data))
      yield put(AuthActions.resultsReset())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
          yield put(AuthActions.logout())
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(AuthActions.authErrorReset())
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* saveFirmFilters(authApi, { firmId, data }) {
  try {
    AuthApi.updateAccessToken(null, null)
    const response = yield call(authApi.saveFirmFilters, firmId, data)
    if (response && response.ok) {
      yield put(AuthActions.firmPreFiltersSuccess(response.data))
      yield put(AuthActions.resultsReset())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
          yield put(AuthActions.logout())
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(AuthActions.authErrorReset())
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* getAgreementLink(authApi, { advisorId }) {
  try {
    const response = yield call(authApi.getAgreementLink, advisorId)
    if (response && response.ok) {
      yield put(AuthActions.advisorAgreementLinkSuccess(response.data))
      yield put(AuthActions.resultsReset())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
          yield put(AuthActions.logout())
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(AuthActions.authErrorReset())
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* checkMandateDealHash(authApi, {hash}) {
  try {
    AuthApi.updateAccessToken(null, null)
    const response = yield call(authApi.checkMandateDealHash, hash)
    if (response && response.ok && response.data && response.data.access) {
      AuthApi.updateAccessToken(response.data.access, response.data.refresh)
      yield put(AuthActions.mandateDealSubmittedCheckSuccess(response.data.user, response.data.access, response.data.refresh))
      yield delay(500)
      yield put(SettingsActions.settingsReset())
      yield put(push({
        pathname: '/home/mandate-deal-sharing/step/review',
        state: {
          endSection: true,
          dealId: response.data?.deal_id
        }
      }))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(push('/login'))
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}
