import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Tabs, Input, Button, Spin, Modal, Select, message, Popconfirm } from 'antd'
import _ from 'lodash'

import MandatesActions from '../../Redux/MandatesRedux'
import SettingsActions from '../../Redux/SettingsRedux'
import UsersActions from '../../Redux/UsersRedux'
import MandateSharingReview from '../../Components/MandateSharing/MandateSharingReview'
import ShareAdvisorsFilterPanel from '../../Components/FilterPanels/ShareAdvisorsFilterPanel'
import ShareAdvisorsTable from '../../Components/Tables/ShareAdvisorsTable'
import MandateMatchesAdminTable from '../../Components/Tables/MandateMatchesAdminTable'
import Images from '../../Images'

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Search } = Input;
const { Option } = Select;

const styleObj = {
  maxWidth: 'auto',
  width: '100%',
};

const okButtonProps = {
  type: "primary",
  className: "btn-primary",
  style: { width: 172 }
};

const cancelButtonProps = {
  type: "primary white",
  className: "btn-primary",
  style: { width: 172 }
};

const okButtonRemoveProps = {
  type: "primary red",
  size: 'small',
  className: "btn-primary",
  style: { width: 76 }
};

const cancelButtonRemoveProps = {
  type: "primary white",
  size: 'small',
  className: "btn-primary",
  style: { width: 76 }
};

const modalProps = {
  centered: true,
  closable: false,
  destroyOnClose: true,
  width: 560
};

const initial = {
  sectors: [],
  geography: [],
};

function MandateDetailsPage(props) {
  const { mandate, mandates, mandateDealsList, dealSizeList, mandateStats, mandateShareWithAdvisorsResult, mandateQuestionResult, questionPublishResult, sectorFocus, geographyFocus, investmentTypes, users, match } = props;
  const { mandateId, tab } = match.params;

  const [start, setStart] = useState(false);
  const [mandateData, setMandateData] = useState(null);
  const [shareData, setShareData] = useState({
    ...initial,
    mandate: mandateId,
    page: mandates.filter_as.page,
    page_size: mandates.filter_as.page_size,
    search: mandates.filter_as.search
  });
  const [active, setActive] = useState(false);
  const [count, setCount] = useState(null);
  const [initialFilters, setInitialFilters] = useState(null);
  const [advisorList, setAdvisorList] = useState(null);
  const [isClosed, setIsClosed] = useState(null);
  const [isReshare, setIsReshare] = useState(null);
  const [isResharing, setIsResharing] = useState(null);
  const [status, setStatus] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [activeKey, setActiveKey] = useState('info');
  const [dealFilters, setDealFilters] = useState({
    account_manager: null,
    status: []
  });

  const history = useHistory();

  const buttonGroup = (
    <div className="tab-btn-block">
      <Button type="primary orange"
          className="btn-primary"
          onClick={() => history.push(`/home/mandate-details/${mandateId}/${activeKey}/close`)}
        >
          Terminate
        </Button>
      <Button type={status === 'on_hold' ? 'primary' : 'primary white'}
        className="btn-primary"
        style={{ width: 120 }}
        onClick={() => history.push(`/home/mandate-details/${mandateId}/${activeKey}/${status === 'on_hold' ? 'unpause' : 'pause'}`)}
      >
        {status === 'on_hold' ? 'Unpause' : 'Pause'}
      </Button>
      <Button
        type="primary"
        className="btn-primary"
        style={{ width: 120 }}
        disabled={!(mandateData?.status_obj?.id === 'not_shared' || mandateData?.status_obj?.id === 'open')}
        onClick={() => history.push(`/home/mandate-edit/${mandateId}`)}
      >
        Edit
      </Button>
    </div>
  )

  useEffect(() => {
    props.getMandate(mandateId);
    props.getSectors();
    props.getContinents({ regions: true });
    props.getInvestmentTypes();
    props.getMandateStats(mandateId);
    props.getMandateStatuses();
    props.getMandateDeals(mandateId, mandates.page_md, mandates.page_size_md, mandates.filter_md);
    props.getDealSize();
    props.getMandateStats(mandateId);
    props.getUserChoices('advisor');
  }, [0]);

  useEffect(() => {
    setActiveKey(tab ? tab : 'info');
  }, [tab]);

  useEffect(() => {
    if (mandate) {
      setMandateData(mandate);
      setIsClosed(mandate.status_obj?.id === 'closed' || mandate.status_obj?.id === 'won' || mandate.admin_status_obj?.id === 'closed');
      setIsReshare(mandate.status_obj?.id === 'closed_reshare');
      setIsResharing(mandate.status_obj?.id === 'resharing');
      setStatus(mandate.status_obj?.id);
      if (!start) {
        let newData;
        if (mandates.filter_as.mandate === mandateId) {
          newData = mandates.filter_as;
          setShareData(newData);
          setInitialFilters(_.omit(newData, ['page', 'page_size', 'search', 'mandate']));
        } else {
          newData = {};
          const mandateCopy = _.cloneDeep(mandate);
          newData.sectors = mandateCopy.industry_focus_sub_sectors?.map(el =>
            el.children.map(child => child.id).flat()
          ).flat();
          newData.geography = mandateCopy.geography?.map(el =>
            el.regions.map(child => child.countries).flat()
          ).flat().map(item => item.id);
          setInitialFilters(newData);
          setShareData({ ...shareData, ...newData, page: 1 });
        }
        setStart(true);
      }
    }
  }, [mandate]);

  useEffect(() => {
    if (shareData && start) {
      props.getMandateAdvisorsShared(shareData);
    }
  }, [shareData, start]);

  useEffect(() => {
    if (mandateShareWithAdvisorsResult) {
      props.getMandate(mandateId);
      props.getMandateAdvisorsShared(shareData);
    }
  }, [mandateShareWithAdvisorsResult]);

  useEffect(() => {
    if (mandateQuestionResult) {
      props.getMandate(mandateId);
    }
  }, [mandateQuestionResult]);

  useEffect(() => {
    if (questionPublishResult) {
      props.getMandate(mandateId);
      message.success('Question has been successfully published', 3);
    }
  }, [questionPublishResult]);

  function handleSearch(val) {
    if (mandates.filter_as.search === val) {
      return;
    }
    setShareData({ ...shareData, page: 1, search: val });
  }

  function changeCount(value, data, isStart) {
    setCount(value);
    if (isStart) return;
    if (data == null) {
      setShareData({
        ...initial,
        mandate: mandateId,
        page: 1,
        page_size: mandates.filter_as.page_size
      });
    } else {
      setShareData({ ...shareData, ...data, page: 1 });
    }
  }

  function handleChangePage(page, page_size) {
    setShareData({ ...shareData, page, page_size });
  }

  function handleChangePage_(page, page_size) {
    props.getMandateDeals(mandateId, page, page_size, mandates.filter_md);
  }

  const shareMandate = () => {
    if (advisorList && advisorList.length) {
      props.shareMandateWithAdvisors(mandateId, { advisors: advisorList });
    }
  }

  function closeFilter() {
    setActive(!active);
  }

  const onTabChange = key => {
    history.push(`/home/mandate-details/${mandateId}/${key}`);
    setActiveKey(key);
  }

  const changeFilter = (field, value) => {
    const newData = { ...dealFilters };
    newData[field] = value;
    setDealFilters(newData);
    applyFilters(newData);
  }

  const clearFilter = (field, isArray) => {
    const newData = { ...dealFilters };
    isArray ?
      newData[field] = []
      :
      newData[field] = null;
    setDealFilters(newData);
    applyFilters(newData);
  }

  const applyFilters = filter => {
    props.getMandateDeals(mandateId, 1, mandates.page_size_md, filter);
  }

  const removeQuestion = questionId => {
    props.deleteMandateQuestion(mandateId, { id: questionId });
  }

  return (
    <div className="main-container">
      <Tabs
        className="deal-deatils-tab"
        style={{ marginTop: 32, marginBottom: 32 }}
        activeKey={activeKey}
        tabBarExtraContent={activeKey === 'info' && status != null && status !== 'closed' && buttonGroup}
        onChange={onTabChange}
      >
        <TabPane tab="Mandate information" key="info">
          <Spin spinning={mandates.fetching} size="large">
            {
              mandateData &&
              <div className="deal-top-info">
                <div className="deal-top-info-item">
                  <span>Mandate code</span>
                  <div>{mandateData.id}</div>
                </div>
                <div className="deal-top-info-item">
                  <span>Investment firm</span>
                  <div>{mandateData.invest_firm}</div>
                </div>
                <div className="deal-top-info-item">
                  <span>Investor(s)</span>
                  <div>{mandateData.investors?.map(el => el.name).join(', ')}</div>
                </div>
              </div>
            }
            <MandateSharingReview
              styleObj={styleObj}
              mandateData={mandateData}
              investmentTypes={investmentTypes}
              dealSizeList={dealSizeList}
            />
            <div className="review-block">
              <div className="overview-title mandate" style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                <div style={{ fontSize: 22, alignSelf: 'flex-start' }}>Additional information - Q&A</div>
                <div className="overview-title mandate subtitle" style={{ margin: '15px 0' }}>Below are listed all queries asked by Senior Advisors to the investor in relation to the mandate, as well as the investor's answers to those</div>
              </div>
              {mandateData?.mandate_questions?.map(el =>
                <Fragment key={el.id}>
                  <div className="overview-title mandate smaller">
                    <div className="overview-title mandate smaller mandate-question">Question:</div>
                    <div className="question-text" style={{ fontWeight: 600 }}>{el.question}</div>
                    {
                      (!el.published || !el.republished) &&
                      <div className="publish-btn" onClick={()=>props.publishMandateQuestion(mandateId, el.id)}>
                        {el.republished ? 'Publish' : 'Re-publish'}
                      </div>
                    }
                    {
                      !el.answer &&
                      <Popconfirm
                        title="Are you sure you want to delete the question?"
                        onConfirm={() => removeQuestion(el.id)}
                        okText="yes"
                        okButtonProps={okButtonRemoveProps}
                        cancelText="no"
                        cancelButtonProps={cancelButtonRemoveProps}
                      >
                        <img className="remove-item" src={Images.close} alt="" />
                      </Popconfirm>
                      // <img className="remove-item" src={Images.close} alt="" onClick={() => removeQuestion(el.id)} />
                    }
                  </div>
                  <div className="overview-title mandate smaller" >
                    <div className="overview-title mandate smaller" style={{ padding: '25px 0', marginBottom: 'auto' }}>Answer:</div>
                    <div className="area-text addinfo">
                      <TextArea
                        value={el.answer}
                        disabled={true}
                        autoSize={true}
                      />
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </Spin>
        </TabPane>
        <TabPane tab="Share with advisors" key="share">
          <Spin spinning={mandates.fetching} size="large">
            <div className="action-panel">
              <Search
                placeholder="Search"
                allowClear
                className="content-search"
                suffix={<img src={Images.search} alt="" />}
                defaultValue={mandates.filter_as.search}
                onSearch={handleSearch}
              />
              <Button type={`primary${count ? ' orange' : ' white'}`} className="btn-primary" onClick={closeFilter}>
                {'Filter' + (count ? ` (${count})` : '')}
              </Button>
              {
                !isClosed ?
                  isReshare ?
                    <Button type="primary white"
                      className="btn-primary"
                      style={{ width: 'auto', marginLeft: 'auto' }}
                      onClick={() => setModalVisible(true)}
                    >
                      Enable re-sharing
                    </Button>
                    :
                    <Button type="primary"
                      className="btn-primary"
                      style={{ marginLeft: 'auto' }}
                      disabled={isResharing}
                      onClick={shareMandate}
                    >
                      Share
                    </Button>
                  :
                  null
              }
              {
                initialFilters &&
                <ShareAdvisorsFilterPanel
                  active={active}
                  sectors={sectorFocus}
                  continents={geographyFocus}
                  initialFilters={initialFilters}
                  choices={users?.choices}
                  changeCount={changeCount}
                  closeFilter={closeFilter}
                />
              }
            </div>
            <div>
              <ShareAdvisorsTable
                data={mandates.advisorSharedList}
                page={mandates.filter_as.page}
                fetching={mandates.fetching}
                pageSize={mandates.filter_as.page_size}
                onChangePage={handleChangePage}
                setAdvisors={setAdvisorList}
              />
            </div>
          </Spin>
        </TabPane>
        <TabPane tab="Deals" key="deals" style={{ marginBottom: 0 }}>
          <div className="section-content-mandate">
            <div className="section-count">
              <span>Deals Received</span>
              <div>{mandateStats?.deals_received}</div>
            </div>
            <div className="section-count">
              <span>Deals Not Matched</span>
              <div>{mandateStats?.deals_not_matched}</div>
            </div>
            <div className="section-count">
              <span>Deals Engaged</span>
              <div>{mandateStats?.deals_engaged}</div>
            </div>
            <div className="section-count">
              <span>Deals Closed</span>
              <div>{mandateStats?.closed_successfully}</div>
            </div>
          </div>
          <div className="divider"></div>
          <div className="filter-block">
            <div className="flex-center-vertical filter-title">Account Manager</div>
            <Select
              value={dealFilters.account_manager}
              style={{ minWidth: 'auto', width: 200 }}
              placeholder="Please select"
              onChange={(value) => changeFilter('account_manager', value)}
            >
              {users?.choices?.managers?.map(item => {
                return (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                )
              })}
            </Select>
            <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter('account_manager')} />
            <div className="flex-center-vertical filter-title">Status</div>
            <Select
              value={dealFilters.status}
              mode="multiple"
              style={{ minWidth: 'auto', width: 200 }}
              placeholder="Please select"
              onChange={(value) => changeFilter('status', value)}
            >
              {mandates?.choices?.deal_mandate_statuses?.map(item => {
                return (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                )
              })}
            </Select>
            <img className="filter-clear" src={Images.closeGrey} alt="" onClick={() => clearFilter('status', true)} />
          </div>
          <MandateMatchesAdminTable
            data={mandateDealsList}
            page={mandates.page_md}
            fetching={mandates.fetching}
            pageSize={mandates.page_size_md}
            onChangePage={handleChangePage_}
          />
        </TabPane>
      </Tabs>
      <Modal
        {...modalProps}
        visible={modalVisible}
        onOk={() => props.reshareDealByAdmin(mandateId)}
        okText="Confirm"
        okButtonProps={okButtonProps}
        onCancel={() => setModalVisible(false)}
        cancelButtonProps={cancelButtonProps}
      >
        <div className="delete-title">
          <span>Are you sure you want to re-activate this deal and enable re-sharing?</span>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    mandates: state.mandates,
    mandate: state.mandates.mandate,
    mandateDealsList: state.mandates.mandateDealsList,
    dealSizeList: state.mandates.dealSizeList,
    mandateShareWithAdvisorsResult: state.mandates.mandateShareWithAdvisorsResult,
    mandateQuestionResult: state.mandates.mandateQuestionResult,
    questionPublishResult: state.mandates.questionPublishResult,
    mandateStats: state.mandates.mandateStats,
    mandateShares: state.mandateShares,
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    investmentTypes: state.settings.investmentTypes,
    users: state.users,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getMandateAdvisorsShared: (data) => dispatch(MandatesActions.mandateAdvisorsSharedRequest(data)),
  getMandate: (mandateId) => dispatch(MandatesActions.mandateRequest(mandateId)),
  getMandates: (page, page_size, sort_field, sort_order, search, filter) => dispatch(MandatesActions.mandatesRequest(page, page_size, sort_field, sort_order, search, filter)),
  getMandateStatuses: () => dispatch(MandatesActions.mandateStatusesRequest()),
  shareMandateWithAdvisors: (mandateId, data) => dispatch(MandatesActions.mandateShareWithAdvisorsRequest(mandateId, data)),
  getMandateDeals: (mandateId, page, page_size, filter) => dispatch(MandatesActions.mandateDealsMatchesRequest(mandateId, page, page_size, filter)),
  getDealSize: () => dispatch(MandatesActions.dealSizeRequest()),
  getMandateStats: (mandateId) => dispatch(MandatesActions.mandateStatsRequest(mandateId)),
  deleteMandateQuestion: (mandateId, data) => dispatch(MandatesActions.mandateQuestionDeleteRequest(mandateId, data)),
  publishMandateQuestion: (mandateId, questionId) => dispatch(MandatesActions.mandateQuestionPublishRequest(mandateId, questionId)),

  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: (filter) => dispatch(SettingsActions.geographyFocusRequest(filter)),
  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),

  getUserChoices: (role) => dispatch(UsersActions.userChoicesRequest(role)),

})

export default connect(mapStateToProps, mapDispatchToProps)(MandateDetailsPage)
