import React, { useState, useEffect } from 'react'
import { Progress, Collapse, Button, Tabs, Checkbox, Row, Col, Input, Tooltip, Space, InputNumber, Radio } from 'antd'
import { useHistory } from 'react-router-dom'

import CollapseHeader from '../CollapseHeader'
import Images from '../../Images'

const { Panel } = Collapse;
const { TextArea } = Input;

const initial = {
  overview: '',
  investment_preferences: '',
  management_style: '',
  portfolio: '',
}

function InvestorSignUpStep2(props) {
  const [active, setActive] = useState(false);
  const [data, setData] = useState(null);

  const history = useHistory();

  useEffect(() => {
    setData(initial);
  }, [0]);

  useEffect(() => {
    if (data) {
      if (data.overview && data.investment_preferences && data.management_style) {
        setActive(true);
      } else {
        setActive(false);
      }
    }
  }, [data]);

  const changeArea = (field, e) => {
    const newData = {...data};
    newData[field] = e.target.value;
    setData(newData);
  }

  const changeInput = (e) => {
    const newData = {...data};
    newData.portfolio = e.target.value;
    setData(newData);
  }

  return (
    <div className="sign-up-step white">
      <img src={Images.logo} alt="" onClick={()=>history.push('/')} />
      <div className="step-investor-content">
        <div className="step-title">Company Onboarding</div>
        <div className="step-number">Step 2 - Firm overview</div>
        <div style={{width: 520}}>
          <Progress
            percent={67}
            showInfo={false}
            strokeWidth={13}
            trailColor="rgba(31, 73, 228, 0.12)"
            strokeColor="#3680F6"
            status="active"
          />
        </div>
        <div className="step-text">Please fill in the field below, which will be reviewed by Senior Advisors when your firm applies to investment opportunities.</div>
      </div>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ?
            <img src={Images.angleUp} alt="" />
            :
            <img src={Images.angleDown} alt="" />
        }
        expandIconPosition="right"
        className="site-collapse-custom-collapse"
        accordion
      >
        <Panel
          header={<CollapseHeader text="Overview" imageType="bullseye" />} key="overview"
          className="site-collapse-custom-panel"
        >
          <div>
            <div className="collapse-content-title">Please provide an introductory overview on your investment firm</div>
            <div className="collapse-content-area">
              <TextArea
                placeholder="You can copy and paste the “About” section from your company’s website, for instance (500 words max)."
                rows={15}
                maxLength={5000}
                defaultValue={data?.overview}
                onBlur={(e)=>changeArea('overview', e)}
              />
            </div>
          </div>
        </Panel>

        <Panel
          header={<CollapseHeader text="Investment Preferences" imageType="mandate" />} key="preferences"
          className="site-collapse-custom-panel"
        >
          <div>
            <div className="collapse-content-title">Please provide an overview on your investment firm’s investment mandate and preferences</div>
            <div className="collapse-content-area">
              <TextArea
                placeholder="Please include an overview on the type of opportunities your investment committee particularly looks for beyond parameters such as ticket size, sector, geography (500 words max)"
                rows={15}
                maxLength={5000}
                defaultValue={data?.investment_preferences}
                onBlur={(e)=>changeArea('investment_preferences', e)}
              />
            </div>
          </div>
        </Panel>

        <Panel
          header={<CollapseHeader text="Asset Management Style" imageType="userTie" />} key="style"
          className="site-collapse-custom-panel"
        >
          <div>
            <div className="collapse-content-title">Please provide an overview on your investment firm’s asset management style.</div>
            <div className="collapse-content-area">
              <TextArea
                placeholder="For instance, this might include the level of involvement you typically require/have with portfolio companies, etc. (500 words max)"
                rows={15}
                maxLength={5000}
                defaultValue={data?.management_style}
                onBlur={(e)=>changeArea('management_style', e)}
              />
            </div>
          </div>
        </Panel>

        <Panel
          header={<CollapseHeader text="Portfolio Companies" imageType="folderOpen" />} key="portfolio"
          className="site-collapse-custom-panel"
        >
          <div>
            <div className="collapse-content-title">Please insert the link to your website's portfolio company section (if available). Senior Advisors will value your firm’s experience investing in companies related to the opportunities they share</div>
            <div className="collapse-content-portfolio">
              <span className="portfolio-title">Paste link</span>
              <Input
                placeholder="Link to your company's website where portfolio companies are listed"
                maxLength={100}
                onBlur={(e)=>changeInput(e)}
              />
            </div>
          </div>
        </Panel>

      </Collapse>
      <Button type="primary" className="btn-primary" style={{ width: 217, marginTop: 58 }} onClick={() => props.onChangeStep(data)}>
        Continue
      </Button>
    </div>
  )
}

export default InvestorSignUpStep2
