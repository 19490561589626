import { put, call, take, select, delay } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import NotificationsActions, {NotificationsTypes, NotificationsSelectors} from '../Redux/NotificationsRedux'
import {DealDashboardSelectors} from '../Redux/DealDashboardRedux'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'

export function * getNotifications(api, authApi) {
  try {
    const response = yield call(api.getNotifications);
    if (response && response.ok) {
      yield put(NotificationsActions.notificationsSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(NotificationsActions.notificationsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getNotifications(api, authApi);
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(NotificationsActions.notificationsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getInvestorDealGroupNotifications(api, authApi) {
  try {
    const response = yield call(api.getInvestorDealGroupNotifications);
    if (response && response.ok) {
      yield put(NotificationsActions.investorDealGroupNotificationsSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(NotificationsActions.notificationsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getInvestorDealGroupNotifications(api, authApi);
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(NotificationsActions.notificationsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getAdvisorDealGroupNotifications(api, authApi) {
  try {
    const response = yield call(api.getAdvisorDealGroupNotifications);
    if (response && response.ok) {
      yield put(NotificationsActions.advisorDealGroupNotificationsSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(NotificationsActions.notificationsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getAdvisorDealGroupNotifications(api, authApi);
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(NotificationsActions.notificationsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getDealDashboardNotifications(api, authApi, { dashboardId }) {
  try {
    const response = yield call(api.getDealDashboardNotifications, dashboardId);
    if (response && response.ok) {
      yield put(NotificationsActions.dealDashboardNotificationsSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(NotificationsActions.notificationsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getDealDashboardNotifications(api, authApi, { dashboardId });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(NotificationsActions.notificationsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getInvestorMandateGroupNotifications(api, authApi) {
  try {
    const response = yield call(api.getInvestorMandateGroupNotifications);
    if (response && response.ok) {
      yield put(NotificationsActions.investorMandateGroupNotificationsSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(NotificationsActions.notificationsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getInvestorMandateGroupNotifications(api, authApi);
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(NotificationsActions.notificationsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getAdvisorMandateGroupNotifications(api, authApi) {
  try {
    const response = yield call(api.getAdvisorMandateGroupNotifications);
    if (response && response.ok) {
      yield put(NotificationsActions.advisorMandateGroupNotificationsSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(NotificationsActions.notificationsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getAdvisorMandateGroupNotifications(api, authApi);
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(NotificationsActions.notificationsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * notificationsSyncStartMonitor() {
  while (true) {
    const payload = yield take(NotificationsTypes.NOTIFICATIONS_SYNC_TOGGLE);
    let doWork = payload.syncIsStart;
    while (doWork) {
      const {syncIsStart} = yield select(NotificationsSelectors.getState);
      if (!syncIsStart) {
        break;
      }
      yield put(NotificationsActions.notificationsRequest());
      doWork = syncIsStart;
      yield delay(30000);
    }
  }
}

export function * dealDashboardNotificationsSyncStartMonitor() {
  while (true) {
    const payload = yield take(NotificationsTypes.DEAL_DASHBOARD_NOTIFICATIONS_SYNC_TOGGLE);
    let doWork = payload.ddSyncIsStart;
    while (doWork) {
      const {ddSyncIsStart} = yield select(NotificationsSelectors.getState);
      const {dashboardId} = yield select(DealDashboardSelectors.getState);
      if (!ddSyncIsStart) {
        break;
      }
      yield put(NotificationsActions.dealDashboardNotificationsRequest(dashboardId));
      doWork = ddSyncIsStart;
      yield delay(30000);
    }
  }
}
