import React from 'react'
import { Layout, Spin, Space } from 'antd'
import { useHistory } from 'react-router-dom'

import InvestorSignUpCreateAccountForm from '../Forms/InvestorSignUpCreateAccountForm'
import Images from '../../Images'

function InvestorSignUpCreateAccount(props) {
  const { fetching, hash, preview, subRole } = props;

  const history = useHistory();

  function handleSubmit(data) {
    data.hash = hash;
    props.onChangeStep(data);
  }

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <div className="sign-up-step with-bg" style={{paddingBottom: 0}}>
          <div className="with-bg-top">
            <img src={Images.logoWhite} alt="" onClick={()=>history.push('/')} />
          </div>
          <div className="modal-container" style={{ width: 650, padding: '24px 64px' }}>
            <Space>
              <span className="logo-text">Welcome to IDN</span>
            </Space>
            <div className="step-title intro" style={{ marginBottom: 24 }}>Create a personal account</div>
            <InvestorSignUpCreateAccountForm
              preview={preview}
              subRole={subRole}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </Spin>
    </Layout>
  )
}

export default InvestorSignUpCreateAccount
