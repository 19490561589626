import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, DatePicker, Spin, message } from 'antd'
import { useHistory } from 'react-router-dom'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function DealSuccessfullyClosedPopupPage(props) {
	const { result, fetching, match, isAdmin } = props;
	const { dashboardId } = match.params;

	const [value, setValue] = useState(null);
	const [active, setActive] = useState(false);

	const history = useHistory()

	useEffect(() => {
		props.dealDashboardSyncToggle(false);
		return function cleanup() {
			props.dealDashboardSyncToggle(true);
		}
	}, [0]);

	useEffect(() => {
		if (result) {
			if (isAdmin) {
				props.getDealDashboardInfo(dashboardId);
				history.push(`/home/match-company/${dashboardId}`);
			} else {
				history.push(`/deal-dashboard/overview/${dashboardId}/congratulations`);
			}
		}
	}, [result]);

	function onChange(date, dateString) {
		setValue(dateString);
	}

	const checkRequired = () => {
		if (!value) {
			message.error('Indicate the date', 3);
			if (!active) {
				setActive(true);
			}
		} else {
			const data = {
				date: value
			};
			props.closeSuccessByInvestor(dashboardId, data);
		}
	}

	return (
		<PopupWrapper
			width={420}
			path={isAdmin ?
				`/home/match-company/${dashboardId}`
				:
				`/deal-dashboard/overview/${dashboardId}`
			}
			hasDropdown={true}
		>
			<Spin spinning={fetching} size="large">
				<div className='dialog-content' style={{ padding: 30 }}>
					<img src={Images.phoneOk} alt='' />
					<div className='dialog-title welcome' style={{ marginTop: 10, marginBottom: 20 }}>
            Deal successfully closed
				</div>
					<div className='deal-text'>By clicking “Confirm”, you formally notify<br />IDN and the Senior Advisor that you have<br />successfully completed the investment in<br />the target company.</div>
          <div className='dealsizerange' style={{ marginTop: 15 }}>
						<div className='divider'></div>
					</div>
          <div className='deal-text'>Please insert the date in which<br />the transaction was closed</div>
					<div className='deal-input' style={{ marginTop: 10 }}>
						<span>
							<DatePicker
								dropdownClassName="bottomCenter"
								format="DD/MM/YYYY"
								className={`deal-input nbo${(active && !value) ? ' error-input' : ''}`}
								placeholder=""
								allowClear={false}
								onChange={onChange}
								suffixIcon={<img src={Images.clock} alt='' className='deal-input clock' />}
							/>
						</span>
					</div>
          <div className='dealsizerange' style={{ marginTop: 15 }}>
						<div className='divider'></div>
					</div>
					<br />
					<div className="deal-text sm" style={{width: '90%'}}>Please review IDN's Platform Agreement to remember your<br />rights and obligations in the context of this engagement</div>
					<div className='btn-footer'>
						<Button
							type='primary white'
							className='btn-primary welcome'
							style={{ width: 144 }}
							onClick={checkRequired}
						>
							Confirm
					</Button>
					</div>
				</div>
			</Spin>
		</PopupWrapper>
	)
}

const mapStateToProps = (state) => {
	return {
		isAdmin: state.auth.isAdmin,
		result: state.dealDashboard.result,
		fetching: state.dealDashboard.fetching
	}
}

const mapDispatchToProps = (dispatch) => ({
	getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
	closeSuccessByInvestor: (dashboardId, data) => dispatch(DealDashboardActions.investorSuccessCloseRequest(dashboardId, data)),
	dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DealSuccessfullyClosedPopupPage)
