import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'
import { useHistory, Link } from 'react-router-dom'
import _ from 'lodash'

import Images from '../../Images'

import DealsActions from '../../Redux/DealsRedux'
import SettingsActions from '../../Redux/SettingsRedux'

import MandateDealSharingReview from '../../Components/MandateDealSharing/MandateDealSharingReview'

function MandateDealPage(props) {
  const { deal, match, investmentTypes, location, notifications, role } = props;
  const { dealId } = match.params;
  const { state } = location;

  const [dealData, setDealData] = useState(null);
  const [shareId, setShareId] = useState({});
  const [backPath, setBackPath] = useState('');

  const history = useHistory();

  useEffect(() => {
    props.getDeal(dealId);
    props.getInvestmentTypes();
  }, [0]);

  useEffect(() => {
    if (deal) {
      setDealData(deal);
      setShareId(deal.mandate_share_id);
    }
  }, [deal]);

  useEffect(() => {
    if (dealData) {
      if (dealData.notifications?.length) {
        showNotification(dealData.notifications[0]);
      }
    }
  }, [dealData]);

  useEffect(() => {
    if (dealData && notifications?.mandates) {
      if (dealData && dealData?.notifications?.length) {
        showNotification(dealData.notifications[0]);
      }
    }
  }, [notifications]);

  function showNotification(notification) {
    if (notification != null) {
      const type = notification?.name_obj?.id;
      const state = {
        id: notification.id,
      };
      let path;
      switch (type) {
        case 0: {
          path = {
            pathname: `/home/mandate-deal/${dealId}/deal-rejected`,
            state
          };
          break;
        }
        default:
          break;
      }
      if (path) {
        history.push(path);
      }
    }
  };

  useEffect(() => {
    if (shareId) {
      if (state?.backPath) {
        setBackPath(state.backPath);
      } else {
        setBackPath(`/home/mandate-share-info/${shareId}/deals-submitted`);
      }
    }
  }, [shareId]);

  return (
    <div className="sign-up-step filters-edit" style={{padding: '133px 68px 0'}}>
      <div className="absolute-header" >
        <div className="logo-block">
          <img src={Images.logoWhite} alt="" />
        </div>
        <div className="title-block"></div>
      </div>
      <div className="return-block" style={{ paddingLeft: 0 }}>
        <Link
          to={backPath}
        >
          <img src={Images.arrowLeft} alt="" />
          <span>Go back</span>
        </Link>
      </div>

      {dealData &&
        <>
          <div style={{ maxWidth: 1392, width: '100%', display: 'flex', marginBottom: 32}}>
            <div className="section-title" style={{ alignSelf: 'start', margin: 0 }}>{`Deal - ${dealId}`}</div>
            <div style={{ display: 'flex', marginLeft: 'auto' }}>

              {!['withdrawn', 'closed_reshare', 'resharing', 'rejected', 'closed', 'won'].includes(dealData?.status_obj?.id) &&
                <>
                  <Button
                    type="primary white"
                    className="btn-primary"
                    style={{ marginRight: 20 }}
                    onClick={() => history.push(`/home/mandate-deal/${dealId}/withdraw-deal`)}
                  >
                    Withdraw Deal
                  </Button>
                  <Button
                    type="primary"
                    className="btn-primary"
                    // style={{ width: 217, }}
                    onClick={() => history.push({
                      pathname: `/home/mandate-deal-sharing-edit/${dealId}`,
                      state: {
                        backPath: `/home/mandate-share-info/${dealData.mandate_share_id}/deals-submitted`
                      }
                    })}
                  >
                    Edit
                  </Button>
                </>
              }

              {['withdrawn', 'rejected', 'closed'].includes(dealData?.status_obj?.id) &&
                <Button
                  type="primary"
                  className="btn-primary"
                  onClick={() => history.push({ pathname: `/home/mandate-deal/${dealId}/reshare-mandate-deal`, state: dealData?.mandate_share_id })}
                >
                  Re-share
                </Button>
              }

              {dealData?.status_obj?.id === ('closed_reshare') &&
                <div className="close-document reshare">
                  Sended request for reshare deal
                </div>
              }

            </div>
          </div>

          <div className="deal-top-info" style={{ justifyContent: 'center', maxWidth: 'max-content' }}>
            <div className="deal-top-info-item">
              <span>Mandate code</span>
              <div>
                <Link to={`/home/mandate-share-info/${dealData?.mandate_share_id}/summary`}>{dealData?.mandate?.id}</Link>
              </div>
            </div>
          </div>

          <MandateDealSharingReview
            dealData={dealData}
            investmentTypes={investmentTypes}
            disableSought={true}
            role={role}
          />
        </>
      }
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    deal: state.deals.deal,
    investmentTypes: state.settings.investmentTypes,
    dealSizeList: state.deals.dealSizeList,
    notifications: state.notifications.notifications,
    role: state.auth.role,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDeal: (id) => dispatch(DealsActions.dealRequest(id)),
  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MandateDealPage)
