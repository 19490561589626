// a library to wrap and simplify api calls
import apisauce from 'apisauce'
//import usersApi from './UsersApi'
import API from './Api'

// our 'constructor'
const create = (baseURL = process.env.REACT_APP_BASE_API_ROUTE) => {

  const accessData = { accessToken: null, refreshToken: null };
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const api = apisauce.create({
    // base URL is read from the 'constructor'
    baseURL,
    // here are some default headers
    headers: {
      'Content-Type': 'application/json'
    },

    // 10 second timeout...
    timeout: 10000
  })

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than 'get', 'post' and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //

  const serialize = (obj, prefix) => {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "." + p  : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  }

  const updateAccessToken = (accessToken, refreshToken) => {
    accessData.accessToken = accessToken;
    accessData.refreshToken = refreshToken;
    if (accessToken) {
      api.setHeader('Authorization', 'JWT ' + accessToken)

    } else {
      api.setHeader('Authorization', null)
    }
    API.updateAccessToken(accessToken, refreshToken)
  }

  const signIn = (email, password, hash) => {
    return new Promise((resolve, reject) => {
      api.post('/token/', { email, password, hash })
        .then(response => {
          if (response && response.ok && response.data && response.data.access) {
            updateAccessToken(response.data.access, response.data.refresh);
            // if (response.data.user?.signed_platform_agreement !== false) {
            //   updateAccessToken(response.data.access, response.data.refresh);
            // }
            resolve({
              userInfo: response.data.user,
              access: response.data.access,
              refresh: response.data.refresh
            })
          } else if (response && response.ok) {
            resolve(response);
          } else if (response.status === 401) {
            resolve(response);
          } else {
            reject(response);
          }
        }).catch(error => {
          reject(error)
        })
    })
  };

  const tokenRefresh = () => {
    return new Promise((resolve, reject) => {
      api.post('/token/refresh/', {
        refresh: accessData.refreshToken
      })
        .then(response => {
          if (response && response.ok && response.data && response.data.access) {
            updateAccessToken(response.data.access, response.data.refresh);
          }
          resolve({
            access: response.data.access,
            refresh: response.data.refresh
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const checkEmailExist = email => {
    return api.post(`/check_email/`, {email})
  }

  const checkInviteHash = (hash, role) => {
    return api.post(`/${role}/confirm/invitation/check_hash/`, {hash})
  }

  const checkConfirmEmailHash = hash => {
    return api.post(`/advisor/confirm/email/`, {hash})
  }

  const signUp = (data, role) => {
    return api.post(`/${role}/confirm/invitation/`, data)
  }

  const confirmEmail = hash => {
    return api.post(`/advisor/send_confirm_email/`, {hash})
  }

  const unlockAccount = userId => {
    return api.post(`/advisor/send_get_in_touch/${userId}/`)
  }

  const resetPassword = email => {
    return api.post(`/reset/password/`, {email})
  }

  const restorePassword = data => {
    return api.post(`/new_password/`, data)
  }

  const sendIntroData = data => {
    return api.post(`/investor/intro_form/`, data)
  }

  const sendApplicationForm = data => {
    return api.post(`/advisor/application_form/`, data)
  }

  const sendFirmInvite = (firmId, data) => {
    return api.post(`/investor/company/${firmId}/invite/`, data)
  }

  const investorLogin = token => {
    return api.post(`/token/confirm/`, {token})
  }

  const getInviteData = (email, role) => {
    const query = {email};
    return api.get(`/${role}/send_invite/?` + serialize(query))
  }

  const sendUserInvite = (data, role) => {
    return api.post(`/${role}/send_invite/`, data)
  }

  const saveAdvisorFilters = (advisorId, data) => {
    return api.put(`/advisor/update_registering/${advisorId}/`, data)
  }

  const saveFirmFilters = (firmId, data) => {
    return api.put(`/investor/company/update_registering/${firmId}/`, data)
  }

  const getAgreementLink = (advisorId) => {
    return api.post(`/advisor/update_registering/${advisorId}/generate_file/`)
  }

  const checkMandateDealHash = hash => {
    return api.post(`/deal/check_hash/`, {hash})
  }

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    updateAccessToken,
    signIn,
    tokenRefresh,
    checkEmailExist,
    checkInviteHash,
    checkConfirmEmailHash,
    signUp,
    confirmEmail,
    unlockAccount,
    resetPassword,
    restorePassword,
    sendIntroData,
    sendApplicationForm,
    sendFirmInvite,
    investorLogin,
    getInviteData,
    sendUserInvite,
    saveAdvisorFilters,
    saveFirmFilters,
    getAgreementLink,
    checkMandateDealHash
  }
}

// let's return back our create method as the default.
export default create();
