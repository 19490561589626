import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { Tooltip, Button, Collapse, Row, Col, Divider } from 'antd'
import moment from 'moment'
import { FileIcon, defaultStyles } from 'react-file-icon'

import DealDashboardActions from '../../Redux/DealDashboardRedux'
import ExpertConsultActions from '../../Redux/ExpertConsultRedux'
import NotificationsActions from '../../Redux/NotificationsRedux'
import AuthActions from '../../Redux/AuthRedux'
import Images from '../../Images'

import './dealDashBoard.less'

const { Panel } = Collapse

const endedStatuses = [5, 6, 7, 10];

function DealOverviewPage(props) {
	const { dashboardInfo, role, loginUpdate, expertConsultInfo, result, match, location, workDoneResult, ddNotifications } = props;
	const { dashboardId } = match.params;
	const { state } = location;

	const [data, setData] = useState(null);
	const [statusDD, setStatusDD] = useState(null);
	const [dataEC, setDataEC] = useState(null);
	const [viewDocuments, setViewDocuments] = useState(false);

	const history = useHistory();

	const handleNavigate = (pathname, state) => {
		history.push({
			pathname,
			state,
		});
	};

	useEffect(() => {
		if (dashboardId) {
			props.getDealDashboardInfo(dashboardId);
			props.getExpertConsultInfo(dashboardId);
			props.getDealDashboardNotifications(dashboardId);
			props.setUpdateDashboard(true);
		}
	}, [dashboardId]);

	useEffect(() => {
		if (state && state.isToggle) {
			props.dealDashboardSyncToggle(true);
		}
	}, [state]);

	useEffect(() => {
		if (expertConsultInfo) {
			setDataEC(expertConsultInfo);
			if (role === 2 && loginUpdate) {
				props.setLoginUpdate(false);
				props.getWorkDoneData(expertConsultInfo.id);
			}
		}
	}, [expertConsultInfo]);

	useEffect(() => {
		if (workDoneResult) {
			let path;
			if (workDoneResult.status === 0) {
				path = `/deal-dashboard/overview/${dashboardId}/hours-submitted`;
			} else if (workDoneResult.status === 1) {
				path = `/deal-dashboard/overview/${dashboardId}/hours-resubmitted`;
			}
			handleNavigate(path, { add_info: workDoneResult });
		}
	}, [workDoneResult]);

	useEffect(() => {
		if (dashboardInfo) {
			props.getExpertConsultInfo(dashboardId);
			setData(dashboardInfo);
			setStatusDD(dashboardInfo.status_obj?.id);
			if (role === 0) {
				if (!dashboardInfo.seen_by_advisor) {
					history.push(`/deal-dashboard/overview/${dashboardId}/welcome`);
				} else {
					if (dashboardInfo.notifications?.length) {
						showNotification(dashboardInfo.notifications[0]);
					}
				}
			} else {
				if (!dashboardInfo.seen_by_investor?.seen_by_investor) {
					history.push({
						pathname: `/deal-dashboard/overview/${dashboardId}/welcome`,
						state: dashboardInfo.seen_by_investor?.share_id,
					});
				} else {
					if (dashboardInfo.notifications?.length && !loginUpdate) {
						showNotification(dashboardInfo.notifications[0]);
					}
				}
			}
		}
	}, [dashboardInfo]);

	const showNotification = (notification, isHistory) => {
		if (notification != null) {
			const type = isHistory ? notification.notification?.id : notification.name_obj?.id;
			const state = {
				add_info: notification.add_info,
				notification: notification.id,
				isHistory,
			};
			let path;
			switch (type) {
				case 0: {
					path = {
						pathname: `/deal-dashboard/overview/${dashboardId}/intro-target-requested`,
						state,
					};
					break;
				}
				case 1: {
					path = {
						pathname: `/deal-dashboard/overview/${dashboardId}/intro-target-occured`,
						state,
					};
					break;
				}
				case 2: {
					path = {
						pathname:
							isHistory && role === 2 ?
								`/deal-dashboard/overview/${dashboardId}/terminate-engagement`
								:
								`/deal-dashboard/overview/${dashboardId}/engagement-terminated`,
						state,
					};
					break;
				}
				case 3: {
					path = {
						pathname:
							isHistory && role === 0 ?
								`/deal-dashboard/overview/${dashboardId}/terminate-engagement`
								:
								`/deal-dashboard/overview/${dashboardId}/engagement-terminated`,
						state,
					};
					break;
				}
				case 4: {
					path = {
						pathname:
							isHistory && role === 0 ?
								`/deal-dashboard/overview/${dashboardId}/send-ultimatum`
								:
								`/deal-dashboard/overview/${dashboardId}/ultimatum-received`,
						state,
					};
					break;
				}
				case 5: {
					path = {
						pathname: `/deal-dashboard/overview/${dashboardId}/ultimatum-accepted`,
						state,
					};
					break;
				}
				case 6: {
					path = {
						pathname: `/deal-dashboard/overview/${dashboardId}/nbo-submitted`,
						state,
					};
					break;
				}
				case 7: {
					path = {
						pathname: `/deal-dashboard/overview/${dashboardId}/nbo-accepted`,
						state,
					};
					break;
				}
				case 9: {
					path = {
						pathname: `/deal-dashboard/overview/${dashboardId}/binding-offer-accepted`,
						state,
					};
					break;
				}
				case 10: {
					path = {
						pathname: `/deal-dashboard/overview/${dashboardId}/deal-closed`,
						state,
					};
					break;
				}
				case 11: {
					path = {
						pathname: `/deal-dashboard/overview/${dashboardId}/competitive-process-launched`,
						state,
					};
					break;
				}
				case 12: {
					path = {
						pathname: `/deal-dashboard/overview/${dashboardId}/disengagement-notice`,
						state,
					};
					break;
				}
				case 14: {
					path = {
						pathname:
							isHistory && role === 2 ?
								`/deal-dashboard/overview/${dashboardId}/engage-advisor`
								:
								`/deal-dashboard/overview/${dashboardId}/agreement-recieved`,
						state,
					};
					break;
				}
				case 15: {
					path = {
						pathname:
							isHistory && role === 2 ?
								`/deal-dashboard/overview/${dashboardId}/edit-agreement`
								:
								`/deal-dashboard/overview/${dashboardId}/revised-agreement-recieved`,
						state,
					};
					break;
				}
				case 16: {
					path = {
						pathname:
							isHistory && role === 0 ?
								`/deal-dashboard/overview/${dashboardId}/advisor-agreement-signed`
								:
								`/deal-dashboard/overview/${dashboardId}/investor-agreement-signed`,
						state,
					};
					break;
				}
				// case 17: {
				// 	path = {
				// 		pathname: `/deal-dashboard/overview/${dashboardId}/extended-agreement-recieved`,
				// 		state,
				// 	};
				// 	break;
				// }
				case 18: {
					path = {
						pathname:
							isHistory && role === 0 ?
								`/deal-dashboard/overview/${dashboardId}/upload-days-work`
								:
								`/deal-dashboard/overview/${dashboardId}/hours-submitted`,
						state,
					}
					break;
				}
				case 19: {
					path = {
						pathname: role === 0 ?
							`/deal-dashboard/overview/${dashboardId}/days-submitted-approved`
							:
							'',
						state,
					};
					break;
				}
				case 20: {
					path = {
						pathname:
							isHistory && role === 0 ?
								`/deal-dashboard/overview/${dashboardId}/resubmition-days-work-upload`
								:
								`/deal-dashboard/overview/${dashboardId}/hours-resubmitted`,
						state,
					};
					break;
				}
				// case 21: {
				// 	path = {
				// 		pathname: `/deal-dashboard/overview/${dashboardId}/agreement-extension-signed`,
				// 		state,
				// 	};
				// 	break;
				// }
				case 22:
				case 23: {
					path = {
						pathname: `/deal-dashboard/overview/${dashboardId}/agreement-ended`,
						state,
					};
					break;
				}
				case 25: {
					path = {
						pathname:
							isHistory && role === 0 ?
								`/deal-dashboard/overview/${dashboardId}/upload-agreement`
								:
								`/deal-dashboard/overview/${dashboardId}/agreement-signed`,
						state,
					};
					break;
				}
				case 26: {
					path = {
						pathname:
							role === 0 ?
								`/deal-dashboard/overview/${dashboardId}/deal-matched`
								:
								'',
						state,
					};
					break;
				}
				default:
					break;
			}
			if (path) {
				history.push(path);
			}
		}
	};

	const generateActionPath = type => {
		if (type != null) {
			switch (type) {
				case 0:
					return `/deal-dashboard/overview/${dashboardId}/request-introduction`;
				case 1:
					return `/deal-dashboard/overview/${dashboardId}/intro-target-occured`;
				case 2:
					return `/deal-dashboard/overview/${dashboardId}/nbo-submitted`;
				case 3:
					return `/deal-dashboard/overview/${dashboardId}/nbo-accepted`;
				case 4:
					return `/deal-dashboard/overview/${dashboardId}/binding-offer-accepted`;
				case 5:
					return `/deal-dashboard/overview/${dashboardId}/deal-successfully-closed`;
				case 6:
					return `/deal-dashboard/overview/${dashboardId}/terminate-engagement`;
				case 7:
					return `/deal-dashboard/overview/${dashboardId}/disengage`;
				case 8:
					return `/deal-dashboard/overview/${dashboardId}/send-ultimatum`;
				case 9:
					return `/deal-dashboard/overview/${dashboardId}/accept-ultimatum`;
				case 10:
					return `/deal-dashboard/overview/${dashboardId}/reject-ultimatum`;
				case 11:
					return `/deal-dashboard/overview/${dashboardId}/competitive-process-launched`;
				case 13:
					return `/deal-dashboard/overview/${dashboardId}/disengagement-notice`;
				case 15:
				case 25:
					return `/deal-dashboard/overview/${dashboardId}/engage-advisor`;
				case 16:
					return `/deal-dashboard/overview/${dashboardId}/edit-agreement`;
				case 17:
					return `/deal-dashboard/overview/${dashboardId}/upload-signed-agreement`;
				// case 18:
				// 	return `/deal-dashboard/${dashboardId}/expert-consulting-agreement`;
				// case 19:
				// 	return `/deal-dashboard/${dashboardId}/expert-consulting-agreement`;
				// case 20:
				// 	return `/deal-dashboard/${dashboardId}/agreement-review`;
				case 26:
					return `/deal-dashboard/overview/${dashboardId}/upload-agreement`;
				// case 27:
				// 	return `/deal-dashboard/overview/${dashboardId}/reshare-deal`;
				default:
					break;
			}
		}
	};

	const generateButton = data => {
		const type = data.text_obj?.id === 6 || data.text_obj?.id === 7 ? 'primary white-red' : 'primary white-green';
		const path = generateActionPath(data.text_obj?.id);
		let state = null;
		// if (data.text_obj?.id === 27) {
		// 	state = {reshare: true};
		// }
		return (
			<Button type={type} key={data.id} className='btn-primary actions-btn'
			onClick={() => history.push({ pathname: path, state })}
			style={{ minWidth: 100}}
			>
				{data.button_text_obj?.name}
			</Button>
		);
	};

	const goChat = () => {
		if (!endedStatuses.includes(statusDD)) {
			history.push(`/deal-dashboard/chat/${dashboardId}`);
		}
	};

	const onClickDocuments = () => {
		setViewDocuments(!viewDocuments);
	};

	const generateText = data => {
		if (data) {
			let position = data.indexOf('reverted');
			if (position > -1) {
				let res = data.split('');
				res.splice(position, 0, '\n');
				res = res.join('');
				return res;
			} else {
				return data;
			}
		}
	};

	const updateHours = () => {
		if (role === 0 && dataEC?.advisor_app_status_obj?.id === 0) {
			handleNavigate(`/deal-dashboard/overview/${dashboardId}/resubmition-days-work-upload`);
		}
		if (role === 0 && (dataEC?.advisor_app_status_obj === null || dataEC?.advisor_app_status_obj?.id === 1)) {
			handleNavigate(`/deal-dashboard/overview/${dashboardId}/upload-days-work`);
		}
		if (role === 2 && dataEC?.investor_app_status_obj?.id === 0) {
			props.getWorkDoneData(expertConsultInfo.id);
		}
	};

	const fileType = useCallback((el) => {
		return el?.name.split('.')[el?.name.split('.')?.length - 1]
	}, [])

	return (
		<div className='advisordealmain-container'>
			<div className="section-top">
				<div className='section-targetcompany dealoverwiew'>
					<div className='section-text dealoverwiew'>
						<span>Target company:</span>
						<span className='blue dealoverwiew'>{data?.target_company?.name}</span>
					</div>
				</div>
				{
					data?.mandate &&
					<div className='section-targetcompany dealoverwiew'>
						<div className='section-text dealoverwiew'>
							<span style={{ width: 210 }}>Mandate code:</span>
							<span className='blue dealoverwiew'>
								{
									role === 2 ?
										<Link to={`/home/mandate/${data?.mandate}/summary`}>{data?.mandate}</Link>
										:
										<Link to={`/home/mandate-share-info/${data?.mandate_share_id}/summary`}>{data?.mandate}</Link>
								}
							</span>
						</div>
					</div>
				}
			</div>
			<div className='section-status'>
				<div className='section-row'>
					<span>Status:</span>
					<span className='text' style={{ marginRight: 'auto' }}>
						{data?.status_obj?.name}
					</span>
					<div className='wrapper'>
						<span className='section-subtitle'>Length</span>
						<span className='blue'>{`${data?.length?.num_months} month, ${data?.length?.num_days} days`}</span>
					</div>
				</div>
				<div className='section-divider'></div>
				{role === 0 ? (
					<div className='section-row'>
						<span className='section-row-title'>Investor:</span>
						<span className='blue' style={{ marginRight: 'auto' }}>
							{data?.invest_company?.name}
						</span>
						<div className='wrapper'>
							<span className='section-subtitle'>Point of contact</span>
							<span className='blue'>{data?.contact}</span>
						</div>
					</div>
				) : (
					<div className='section-row'>
						<span className='section-row-title'>Advisor:</span>
						<span className='blue' style={{ marginRight: 'auto' }}>
							{data?.advisor?.name}
						</span>
						{!endedStatuses.includes(statusDD) && (
							<div className='wrapper'>
								<Button
									type='primary white'
									className='btn-primary invite'
									size='small'
									onClick={() => history.push(`/deal-dashboard/overview/${dashboardId}/invite-colleagues`)}
								>
									Invite Colleagues
								</Button>
							</div>
						)}
					</div>
				)}
			</div>
			<div className='section-action'>
				<div className='section-col1'>
					<div className='section-actions'>
						<span className='section-action-title'>Actions</span>
						<div className='section-actions-content'>
							{data?.actions
								.filter(el => el.location_obj?.id === 0)
								.map((el, i) => (
									<div className='text' key={i}>
										<span>{el.text_obj?.name}</span>
										{generateButton(el)}
									</div>
								))}
						</div>
					</div>
					<div className="section-actions">
						<span className='section-action-title'>Actions history</span>
						<div className='section-actions-content history'>
							{data?.action_history?.map(el => (
								<div className='text' key={el.id}>
									<span>{el.created && moment(el.created).format('DD/MM/YYYY')}</span>
									<span onClick={() => showNotification(el, true)}>{generateText(el.text?.name)}</span>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className='section-col2'>
					<div className='section-communication'>
						<span className='section-action-title'>Communication</span>
						<Collapse
							bordered={false}
							expandIcon={({ isActive }) => (isActive ? <img src={Images.angleUp} alt='' /> : <img src={Images.angleDown} alt='' />)}
							expandIconPosition='right'
						>
							<Panel key='1' className='site-collapse-custom-collapse'>
								<div className='section-communication-content'>
									<div className='square' style={{ borderColor: dashboardInfo?.chat_notification && '#FF6B0F' }} onClick={goChat}>
										<img src={dashboardInfo?.chat_notification ? Images.chatOrange : Images.chatBlue} alt='' className='image' />
										<div className='square-title'>Chat</div>
										{dashboardInfo?.chat_notification ? (
											<div className='text-light'>
												You have a <br /> new message
											</div>
										) : (
											<div className='text-light'>
												Write a <br /> new message
											</div>
										)}
										{dashboardInfo?.chat_notification && <img className='note-img' src={Images.notification} alt='' />}
									</div>
									<div className={viewDocuments ? 'square open' : 'square'} onClick={onClickDocuments}>
										<img src={Images.docum} alt='' className='image' />
										<div className='square-title'>Documents</div>
										{viewDocuments && <img src={Images.angleDownLightBlue} alt='' style={{ marginTop: 16, width: 20 }} />}
									</div>
									<div className='square'>
										<img src={Images.videocall2} alt='' className='image' />
										<div className='square-title'>Video call</div>
										<div className='text-light'>Coming soon</div>
									</div>

									{viewDocuments && (
										<div className='documents-collapse'>
											<div className='documents-collapse clone'>
												{data?.documents.map((el, i) => (
													<div className='document'
														key={`${el.date}-${el.i}`}
														style={data?.documents?.length > 6 ? { marginBottom: 15 } : null}
													>
														<a href={el.url} target='_blank' rel='noreferrer'
															style={{ marginTop: 5, display: 'flex', alignItems: 'center', flexDirection: 'column' }}
														>
															<div className='document-icon' >
																<FileIcon
																	extension={fileType(el)}
																	{...defaultStyles[`${fileType(el)}`]}
																/>
															</div>
															<Tooltip
																placement="bottom"
																title={el.name}
															>
																<div className='document-title'>{el.name}</div>
															</Tooltip>
															<div className='document-title-date'>{moment(el.date).format('DD:MM:YYYY')}</div>
															<div className='document-title-date'>{moment(el.date).format('HH:mm')}&nbsp;(GMT)</div>
														</a>
													</div>
												))}
											</div>
										</div>
									)}
								</div>
							</Panel>
						</Collapse>
					</div>
					<div className='section-advisorywork'>
						<span className='section-action-title'>
							Expert consulting
							{
								ddNotifications?.expert_consulting &&
								<img src={Images.notification} alt='' />
							}
						</span>
						<Collapse
							bordered={false}
							expandIcon={({ isActive }) => (isActive ? <img src={Images.angleUp} alt='' /> : <img src={Images.angleDown} alt='' />)}
							expandIconPosition='right'
						>
							<Panel key='1' className='site-collapse-custom-collapse'>
								<div className='section-advisorywork-content'>
									<div className='mainblock-wrapper'>
										<Row style={{ alignItems: 'center' }}>
											<Col span={5}>
												<span className='title'>Status:</span>
											</Col>
											<Col span={19}>
												<span className='value'>{dataEC?.status_obj?.name}</span>
											</Col>
										</Row>
										<Row style={{ alignItems: 'center' }}>
											<Col span={5}>
												<span className='title'>Action:</span>
											</Col>
											<Col span={12}>
												<span className='value'>
													{data?.actions.filter(el => el.location_obj?.id === 1).length >= 1
														? data?.actions.filter(el => el.location_obj?.id === 1).map(el => el.text_obj?.name)
														: 'N/A'}
												</span>
											</Col>
											<Col span={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
												{data?.actions?.filter(el => el.location_obj?.id === 1).map(el => generateButton(el))}
											</Col>
										</Row>
										<Divider />
										<div className='submition-block'>
											{role === 2 && (dataEC?.investor_app_status_obj?.id == null || dataEC?.investor_app_status_obj?.id === 1) && (
												<div className='square'>
													<img src={Images.check} alt='' className='image' />
													<div className='text'>
														{
															data?.actions?.filter(el => el.location_obj?.id === 2).length >= 1 ?
																data?.actions.filter(el => el.location_obj?.id === 2).map(el => el.text_obj?.name)
																:
																'Approve work done'
														}
													</div>
												</div>
											)}

											{role === 2 && dataEC?.investor_app_status_obj?.id === 0 && (
												<div className='square orange' onClick={updateHours}>
													<img src={Images.exclamation} alt='' />
													<img src={Images.bell} alt='' className='bell' />
													<div className='text orange'>Approve work done</div>
												</div>
											)}

											{role === 0 && (
												<div
													className={dataEC?.advisor_app_status_obj?.id === 0 ? 'square active' : 'square hover'}
													onClick={
														dataEC && (dataEC.advisor_app_status_obj?.id === 0 ||
															data?.actions?.filter(el => el.location_obj?.id === 2).map(el => el.text_obj?.id === 21)[0]) ?
															updateHours
															:
															null
													}
												>
													<img
														src={dataEC?.advisor_app_status_obj?.id === 0 ? Images.downloadtop_blue : Images.downloadtop}
														alt=''
														className='image active'
														style={{ margin: 5 }}
													/>
													<div className={dataEC?.investor_app_status_obj?.id === 0 ? 'text active' : 'text'}>
														{
															data?.actions.filter(el => el.location_obj?.id === 2).length >= 1 ?
																data?.actions?.filter(el => el.location_obj?.id === 2).map(el => el.text_obj?.name)
																:
																'Update work done'
														}
													</div>
												</div>
											)}

											<Col className='submisison-content' style={{ marginLeft: 20 }}>
												<div className='submisison-text'>
													Last submission:
													<span
														className={role === 2 && dataEC?.investor_app_status_obj?.id === 0 ? 'submisison-data orange' : 'submisison-data'}
													>
														{dataEC?.last_submission !== null ?
															moment(dataEC?.last_submission).format('DD/MM/YYYY')
															:
															'N/A'}
													</span>
												</div>
												<div className='submisison-text'>
													Approval status:
													<span
														className={
															role === 2 && dataEC?.investor_app_status_obj?.id === 0 ? 'submisison-data orange' : 'submisison-data'
														}
													>
														{role === 0 ?
															dataEC && (dataEC?.advisor_app_status_obj?.id === 0 || dataEC?.advisor_app_status_obj?.id === 1) ? dataEC?.advisor_app_status_obj?.name
																:
																'N/A'
															:
															null}

														{
															role === 2 ?
																dataEC && (dataEC?.investor_app_status_obj?.id === 0 || dataEC?.investor_app_status_obj?.id === 1) ?
																	dataEC?.investor_app_status_obj?.name
																	:
																	'N/A'
																:
																null
														}
													</span>
												</div>
											</Col>
										</div>
									</div>
									<div className='secondaryblock'>
										<div className='left' style={{ display: 'flex', flexDirection: 'column' }}>
											<Row style={{ display: 'flex', width: '100%' }}>
												<Col span={8}>
													<div className='price'>{dataEC?.daily_rate == null ? 'N/A' : `£${dataEC.daily_rate}`}</div>
												</Col>
												<Col span={8}>
													<div className='price'>{dataEC?.days_confirmed}</div>
												</Col>
												<Col span={8}>
													<div className='price blue'>{dataEC?.total_fees ? `£${dataEC?.total_fees}` : '0'}</div>
												</Col>
											</Row>
											<Row style={{ display: 'flex', width: '100%' }}>
												<Col span={8}>
													<div className='price-title'>
														{
															dataEC?.remuneration === 1 ?
																<span>Fixed<br />rate</span>
																:
																<span>Daily<br />rate</span>
														}
													</div>
												</Col>
												<Col span={8}>
													<div className='price-title'>
														Days <br /> confirmed
													</div>
												</Col>
												<Col span={8}>
													<div className='price-title'>
														Total <br /> Fees
													</div>
												</Col>
											</Row>
										</div>
										<div className='right'>
											<div className='right-data'>
												{dataEC?.ends_at ? moment(dataEC.ends_at).format('DD/MM/YYYY') : 'N/A'}
											</div>
											<div className='subscription'>[CONTRACT ENDS]</div>
										</div>
									</div>
								</div>
							</Panel>
						</Collapse>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		dashboardInfo: state.dealDashboard.dashboardInfo,
		expertConsultInfo: state.expertConsult.expertConsultInfo,
		role: state.auth.role,
		loginUpdate: state.auth.loginUpdate,
		result: state.expertConsult.result,
		workDoneResult: state.expertConsult.workDoneResult,
		ddNotifications: state.notifications.ddNotifications
	};
};

const mapDispatchToProps = dispatch => ({
	getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
	dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
	setUpdateDashboard: (updateDashboardId) => dispatch(DealDashboardActions.setUpdateDashboard(updateDashboardId)),

	getExpertConsultInfo: (dashboardId) => dispatch(ExpertConsultActions.expertConsultingRequest(dashboardId)),
	getWorkDoneData: (consultId) => dispatch(ExpertConsultActions.investorWorkDoneDataRequest(consultId)),
	getDealDashboardNotifications: (dashboardId) => dispatch(NotificationsActions.dealDashboardNotificationsRequest(dashboardId)),

	setLoginUpdate: (loginUpdate) => dispatch(AuthActions.setLoginUpdate(loginUpdate)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DealOverviewPage);
