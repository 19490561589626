import React, { useState, useEffect } from 'react'
import { Layout } from 'antd'
import {
  useLocation,
  useHistory,
  Switch,
  Route
} from 'react-router-dom'
import { connect } from 'react-redux'

import SidebarDealDashboard from '../Components/SidebarDealDashboard'
import HeaderBlock from '../Components/HeaderBlock'

import PrivateRoute from '../Components/PrivateRoute'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'
import DealDashboardActions from '../Redux/DealDashboardRedux'
import NotificationsActions from '../Redux/NotificationsRedux'

import StartupPage from './StartupPage'
import DealOverviewPage from './DealDashboard/DealOverviewPage'
import DealChatPage from './DealDashboard/DealChatPage'
import AdvisorProfilePage from './DealDashboard/AdvisorProfilePage'
import InvestorProfilePage from './DealDashboard/InvestorProfilePage'
import DealDetailsPage from './DealDashboard/DealDetailsPage'
import NotFoundPage from './NotFoundPage'

import WelcomeDDAdvisorPopupPage from './PopupPages/DealDashboardPopups/WelcomeDDAdvisorPopupPage'
import WelcomeDDInvestorPopupPage from './PopupPages/DealDashboardPopups/WelcomeDDInvestorPopupPage'
import AcceptUltimatumPopupPage from './PopupPages/DealDashboardPopups/AcceptUltimatumPopupPage'
import BindingOfferAcceptedAdvisorPopupPage from './PopupPages/DealDashboardPopups/BindingOfferAcceptedAdvisorPopupPage'
import BindingOfferAcceptedInvestorPopupPage from './PopupPages/DealDashboardPopups/BindingOfferAcceptedInvestorPopupPage'
import CompetitiveProcessLaunchedAdvisorPopupPage from './PopupPages/DealDashboardPopups/CompetitiveProcessLaunchedAdvisorPopupPage'
import CompetitiveProcessLaunchedInvestorPopupPage from './PopupPages/DealDashboardPopups/CompetitiveProcessLaunchedInvestorPopupPage'
import CongratulationsPopupPage from './PopupPages/DealDashboardPopups/CongratulationsPopupPage'
import DealClosedPopupPage from './PopupPages/DealDashboardPopups/DealClosedPopupPage'
import DealSuccessfullyClosedPopupPage from './PopupPages/DealDashboardPopups/DealSuccessfullyClosedPopupPage'
import DisengagePopupPage from './PopupPages/DealDashboardPopups/DisengagePopupPage'
import DisengagementNoticePopupPage from './PopupPages/DealDashboardPopups/DisengagementNoticePopupPage'
import EngagementTerminatedAdvisorPopupPage from './PopupPages/DealDashboardPopups/EngagementTerminatedAdvisorPopupPage'
import EngagementTerminatedInvestorPopupPage from './PopupPages/DealDashboardPopups/EngagementTerminatedInvestorPopupPage'
import IntroductionOccuredPopupPage from './PopupPages/DealDashboardPopups/IntroductionOccuredPopupPage'
import IntroductionRequestedPopupPage from './PopupPages/DealDashboardPopups/IntroductionRequestedPopupPage'
import IntroTargetOccuredPopupPage from './PopupPages/DealDashboardPopups/IntroTargetOccuredPopupPage'
import IntroTargetRequestedPopupPage from './PopupPages/DealDashboardPopups/IntroTargetRequestedPopupPage'
import NBOAcceptedAdvisorPopupPage from './PopupPages/DealDashboardPopups/NBOAcceptedAdvisorPopupPage'
import NBOAcceptedInvestorPopupPage from './PopupPages/DealDashboardPopups/NBOAcceptedInvestorPopupPage'
import NBOSubmittedAdvisorPopupPage from './PopupPages/DealDashboardPopups/NBOSubmittedAdvisorPopupPage'
import NBOSubmittedInvestorPopupPage from './PopupPages/DealDashboardPopups/NBOSubmittedInvestorPopupPage'
import RejectUltimatumPopupPage from './PopupPages/DealDashboardPopups/RejectUltimatumPopupPage'
import RequestIntroductionPopupPage from './PopupPages/DealDashboardPopups/RequestIntroductionPopupPage'
import ReshareDealPopupPage from './PopupPages/DealDashboardPopups/ReshareDealPopupPage'
import SendUltimatumPopupPage from './PopupPages/DealDashboardPopups/SendUltimatumPopupPage'
import TerminateEngagementAdvisorPopupPage from './PopupPages/DealDashboardPopups/TerminateEngagementAdvisorPopupPage'
import TerminateEngagementInvestorPopupPage from './PopupPages/DealDashboardPopups/TerminateEngagementInvestorPopupPage'
import UltimatumAcceptedPopupPage from './PopupPages/DealDashboardPopups/UltimatumAcceptedPopupPage'
import UltimatumRecievedPopupPage from './PopupPages/DealDashboardPopups/UltimatumRecievedPopupPage'
import UltimatumSentPopupPage from './PopupPages/DealDashboardPopups/UltimatumSentPopupPage'
import InviteColleaguesToDealDashboardPopupPage from './PopupPages/DealDashboardPopups/InviteColleaguesToDealDashboardPopupPage'
import UploadAgreementPopupPage from './PopupPages/DealDashboardPopups/UploadAgreementPopupPage'
import AgreementSignedPopupPage from './PopupPages/DealDashboardPopups/AgreementSignedPopupPage'
import AddWorkPopupPage from './PopupPages/AddWorkPopupPage'

import EngageAdvisorPopupPage from './PopupPages/AdvisoryWorkPopups/EngageAdvisorPopupPage'
import AgreementSharedPopupPage from './PopupPages/AdvisoryWorkPopups/AgreementSharedPopupPage'
import EditAgreementPopupPage from './PopupPages/AdvisoryWorkPopups/EditAgreementPopupPage'
import InvestorAgreementSignedPopupPage from './PopupPages/AdvisoryWorkPopups/InvestorAgreementSignedPopupPage'
import AdvisorAgreementSignedPopupPage from './PopupPages/AdvisoryWorkPopups/AdvisorAgreementSignedPopupPage'
import HoursSubmittedPopupPage from './PopupPages/AdvisoryWorkPopups/HoursSubmittedPopupPage'
import HoursResubmittedPopupPage from './PopupPages/AdvisoryWorkPopups/HoursResubmittedPopupPage'
import AgreementExtensionSharedPopupPage from './PopupPages/AdvisoryWorkPopups/AgreementExtensionSharedPopupPage'
import AgreementExtensionSignedPopupPage from './PopupPages/AdvisoryWorkPopups/AgreementExtensionSignedPopupPage'
import AdvisorAgreementExtensionSignedPopupPage from './PopupPages/AdvisoryWorkPopups/AdvisorAgreementExtensionSignedPopupPage'
import InvestorAgreementEndedPopupPage from './PopupPages/AdvisoryWorkPopups/InvestorAgreementEndedPopupPage'
import AdvisorAgreementEndedPopupPage from './PopupPages/AdvisoryWorkPopups/AdvisorAgreementEndedPopupPage'
import AgreementRecievedPopupPage from './PopupPages/AdvisoryWorkPopups/AgreementRecievedPopupPage'
import RevisedAgreementRecievedPopupPage from './PopupPages/AdvisoryWorkPopups/RevisedAgreementRecievedPopupPage'
import UploadSignedAgreementPopupPage from './PopupPages/AdvisoryWorkPopups/UploadSignedAgreementPopupPage'
import UploadDaysOfWorkPopupPage from './PopupPages/AdvisoryWorkPopups/UploadDaysOfWorkPopupPage'
import ReUploadDaysOfWorkPopupPage from './PopupPages/AdvisoryWorkPopups/ReUploadDaysOfWorkPopupPage'
import HoursSubmittedApprovedPopupPage from './PopupPages/AdvisoryWorkPopups/HoursSubmittedApprovedPopupPage'
import ExtendedAgreementReceivedPopupPage from './PopupPages/AdvisoryWorkPopups/ExtendedAgreementReceivedPopupPage'
import DealMatchedPopupPage from './PopupPages/DealMatchedPopupPage'
import ReshareMandateDealPopupPage from './PopupPages/ReshareMandateDealPopupPage'

import ExpertConsultingAgreement from './DealDashboard/ExpertConsultingAgreement'
import ReviewShareAgreement from './DealDashboard/ReviewShareAgreement'

import errorHandler from '../Utils/errorHandler'

const { Content } = Layout;

const endedStatuses = [5, 6, 7, 10];

function DealDashboardPage(props) {
  const { auth, isAdmin, role, subRole, error, startup, dashboardInfo, dashboardId, firmId, advisorId, dealId, backPath, updateDashboardId, ddNotifications, location } = props;
  const {state} = location;

  const [backState, setBackState] = useState('');
  const [abortUpdate, setAbortUpdate] = useState(false);

  const path = useLocation().pathname;

  const history = useHistory();

  function handleNavigate(path, check) {
    let newPath = path;
    if (check) {
      if (backState) {
        newPath = {
          pathname: path,
          state: backState
        }
      }
      if (abortUpdate) {
        newPath = {
          pathname: path,
          state: {abortUpdate}
        }
      }
    }
    history.push(newPath);
  }

  useEffect(() => {
    if (state?.backPath) {
      props.setBackPath(state.backPath);
    } else {
      props.setBackPath('/home/deals');
    }
    if (state?.backState) {
      setBackState(state.backState);
    }
    if (state?.abortUpdate) {
      setAbortUpdate(true);
    }
    return function cleanup() {
      props.dealDashboardSyncToggle(false);
      props.dealDashboardNotificationsSyncToggle(false);
    }
  }, [0]);

  useEffect(() => {
    if (dashboardId && updateDashboardId) {
      if (!endedStatuses.includes(dashboardInfo?.status_obj?.id)) {
        props.dealDashboardSyncToggle(true);
        props.dealDashboardNotificationsSyncToggle(true);
      }
      props.setUpdateDashboard(false);
    }
  }, [dashboardId, updateDashboardId]);

  useEffect(() => {
    if (error) {
      errorHandler(error, props.resetError);
    }
  }, [error]);

  if (auth.userInfo === null || startup.success === false) {
    return (
      <Layout>
        <Route path="*" component={StartupPage} />
      </Layout>
    )
  }

  return (
    <Layout style={{ overflowY: 'hidden' }}>
      <Layout>
        <SidebarDealDashboard
          isAdmin={isAdmin}
          role={role}
          subRole={subRole}
          path={path}
          dashboardId={dashboardId}
          ddNotifications={ddNotifications}
          firmId={firmId}
          advisorId={advisorId}
          dealId={dealId}
          hasNotification={false}
          statusDD={dashboardInfo?.status_obj?.id}
          backPath={backPath}
          onNavigate={handleNavigate}
        />
        <Layout>
          <Content>
            <HeaderBlock
              path={path}
              isAdmin={isAdmin}
              dealId={dealId}
            />
            <Switch>
              <PrivateRoute path="/deal-dashboard/overview/:dashboardId" isAllowed={!isAdmin} component={DealOverviewPage} />
              <PrivateRoute path="/deal-dashboard/chat/:dashboardId" isAllowed={!isAdmin} component={DealChatPage} />
              <PrivateRoute path="/deal-dashboard/investor-profile/:firmId" isAllowed={role === 0} component={InvestorProfilePage} />
              <PrivateRoute path="/deal-dashboard/advisor-profile/:advisorId" isAllowed={role === 2} component={AdvisorProfilePage} />
              <PrivateRoute path="/deal-dashboard/deal-details/:dealId" isAllowed={!isAdmin} component={DealDetailsPage} />

              <PrivateRoute path="/deal-dashboard/:dashboardId/expert-consulting-agreement" isAllowed={!isAdmin} component={ExpertConsultingAgreement} />
              <PrivateRoute path="/deal-dashboard/:dashboardId/agreement-review" isAllowed={!isAdmin} component={ReviewShareAgreement} />

              <PrivateRoute path="/deal-dashboard/investor-profile/:firmId?" isAllowed={role === 0} component={InvestorProfilePage} />
              <PrivateRoute path="/deal-dashboard/advisor-profile/:advisorId?" isAllowed={role === 2} component={AdvisorProfilePage} />
              <PrivateRoute path="/deal-dashboard/deal-details/:dealId?" isAllowed={!isAdmin} component={DealDetailsPage} />
              <Route path="/deal-dashboard/*" component={NotFoundPage} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
      <PrivateRoute
        path="/deal-dashboard/overview/:dashboardId/welcome"
        isAllowed={!isAdmin}
        component={role === 0 ?
          WelcomeDDAdvisorPopupPage
          :
          WelcomeDDInvestorPopupPage
        }
      />

      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/accept-ultimatum" isAllowed={role === 2} component={AcceptUltimatumPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/reject-ultimatum" isAllowed={role === 2} component={RejectUltimatumPopupPage} />
      <PrivateRoute
        path="/deal-dashboard/overview/:dashboardId/binding-offer-accepted"
        isAllowed={!isAdmin}
        component={role === 0 ?
          BindingOfferAcceptedAdvisorPopupPage
          :
          BindingOfferAcceptedInvestorPopupPage
        }
      />

      <PrivateRoute
        path="/deal-dashboard/overview/:dashboardId/competitive-process-launched"
        isAllowed={!isAdmin}
        component={role === 0 ?
          CompetitiveProcessLaunchedAdvisorPopupPage
          :
          CompetitiveProcessLaunchedInvestorPopupPage
        }
      />

      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/deal-successfully-closed" isAllowed={role === 2} component={DealSuccessfullyClosedPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/congratulations" isAllowed={role === 2} component={CongratulationsPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/deal-closed" isAllowed={!isAdmin} component={DealClosedPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/disengage" isAllowed={role === 0} component={DisengagePopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/disengagement-notice" isAllowed={!isAdmin} component={DisengagementNoticePopupPage} />
      <PrivateRoute
        path="/deal-dashboard/overview/:dashboardId/engagement-terminated"
        isAllowed={!isAdmin}
        component={role === 0 ?
          EngagementTerminatedAdvisorPopupPage
          :
          EngagementTerminatedInvestorPopupPage
        }
      />

      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/introduction-requested" isAllowed={role === 2} component={IntroductionRequestedPopupPage} />
      <PrivateRoute
        path="/deal-dashboard/overview/:dashboardId/intro-target-occured"
        isAllowed={!isAdmin}
        component={role === 0 ?
          IntroTargetOccuredPopupPage
          :
          IntroductionOccuredPopupPage
        }
      />
      <PrivateRoute
        path="/deal-dashboard/overview/:dashboardId/intro-target-requested"
        isAllowed={!isAdmin}
        component={role === 0 ?
          IntroTargetRequestedPopupPage
          :
          RequestIntroductionPopupPage
        }
      />
      <PrivateRoute
        path="/deal-dashboard/overview/:dashboardId/nbo-accepted"
        isAllowed={!isAdmin}
        component={role === 0 ?
          NBOAcceptedAdvisorPopupPage
          :
          NBOAcceptedInvestorPopupPage
        }
      />

      <PrivateRoute
        path="/deal-dashboard/overview/:dashboardId/nbo-submitted"
        isAllowed={!isAdmin}
        component={role === 0 ?
          NBOSubmittedAdvisorPopupPage
          :
          NBOSubmittedInvestorPopupPage
        }
      />

      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/request-introduction" isAllowed={role === 2} component={RequestIntroductionPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/reshare-deal" isAllowed={role === 0} component={ReshareDealPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/send-ultimatum" isAllowed={role === 0} component={SendUltimatumPopupPage} />
      <PrivateRoute
        path="/deal-dashboard/overview/:dashboardId/terminate-engagement"
        isAllowed={!isAdmin}
        component={role === 0 ?
          TerminateEngagementAdvisorPopupPage
          :
          TerminateEngagementInvestorPopupPage
        }
      />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/reshare-mandate-deal" isAllowed={role === 0} component={ReshareMandateDealPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/ultimatum-accepted" isAllowed={!isAdmin} component={UltimatumAcceptedPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/ultimatum-received" isAllowed={!isAdmin} component={UltimatumRecievedPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/ultimatum-sent" isAllowed={role === 0} component={UltimatumSentPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/invite-colleagues" isAllowed={role === 2} component={InviteColleaguesToDealDashboardPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/upload-agreement" isAllowed={role === 0} component={UploadAgreementPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/agreement-signed" isAllowed={role === 2} component={AgreementSignedPopupPage} />
      <PrivateRoute path="/home/experience/add-work" isAllowed={role === 0} component={AddWorkPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/engage-advisor" isAllowed={role === 2} component={EngageAdvisorPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/agreement-shared" isAllowed={role === 2} component={AgreementSharedPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/edit-agreement" isAllowed={role === 2} component={EditAgreementPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/investor-agreement-signed" isAllowed={role === 2} component={InvestorAgreementSignedPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/advisor-agreement-signed" isAllowed={role === 0} component={AdvisorAgreementSignedPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/agreement-recieved" isAllowed={role === 0} component={AgreementRecievedPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/revised-agreement-recieved" isAllowed={role === 0} component={RevisedAgreementRecievedPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/upload-signed-agreement" isAllowed={role === 0} component={UploadSignedAgreementPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/upload-days-work" isAllowed={role === 0} component={UploadDaysOfWorkPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/resubmition-days-work-upload" isAllowed={role === 0} component={ReUploadDaysOfWorkPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/days-submitted-approved" isAllowed={role === 0} component={HoursSubmittedApprovedPopupPage} />
      <PrivateRoute
        path="/deal-dashboard/overview/:dashboardId/agreement-ended"
        isAllowed={!isAdmin}
        component={role === 0 ?
          AdvisorAgreementEndedPopupPage
          :
          InvestorAgreementEndedPopupPage
        }
      />

      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/agreement-extension-signed" isAllowed={role === 2} component={AgreementExtensionSignedPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/agreement-extension-shared" isAllowed={role === 2} component={AgreementExtensionSharedPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/advisor-agreement-extension-signed" isAllowed={role === 0} component={AdvisorAgreementExtensionSignedPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/hours-resubmitted" isAllowed={role === 2} component={HoursResubmittedPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/hours-submitted" isAllowed={role === 2} component={HoursSubmittedPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/extended-agreement-recieved" isAllowed={role === 0} component={ExtendedAgreementReceivedPopupPage} />
      <PrivateRoute path="/deal-dashboard/deal-details/:dealId/reshare-deal" isAllowed={role === 0} component={ReshareDealPopupPage} />
      <PrivateRoute path="/deal-dashboard/deal-details/:dealId/reshare-mandate-deal" isAllowed={role === 0} component={ReshareMandateDealPopupPage} />
      <PrivateRoute path="/deal-dashboard/overview/:dashboardId/deal-matched" isAllowed={role === 0} component={DealMatchedPopupPage} />

    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    isAdmin: state.auth.isAdmin,
    role: state.auth.role,
    subRole: state.auth.investorSubRole,
    startup: state.startup,
    error: state.errors.data,
    dashboardInfo: state.dealDashboard.dashboardInfo,
    dashboardId: state.dealDashboard.dashboardId,
    firmId: state.dealDashboard.firmId,
    advisorId: state.dealDashboard.advisorId,
    dealId: state.dealDashboard.dealId,
    backPath: state.dealDashboard.backPath,
    updateDashboardId: state.dealDashboard.updateDashboardId,
    ddNotifications: state.notifications.ddNotifications
  }
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.logout()),
  resetError: () => dispatch(ErrorsActions.errorReset()),

  dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
  setBackPath: (backPath) => dispatch(DealDashboardActions.setBackPath(backPath)),
  setUpdateDashboard: (updateDashboardId) => dispatch(DealDashboardActions.setUpdateDashboard(updateDashboardId)),

  dealDashboardNotificationsSyncToggle: (ddSyncIsStart) => dispatch(NotificationsActions.dealDashboardNotificationsSyncToggle(ddSyncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DealDashboardPage)
