import React, { useState, useEffect } from 'react'
import { Button, Input, Spin, message } from 'antd'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import PopupWrapper from './PopupWrapper'
import DealSharesActions from '../../Redux/DealSharesRedux'
import './PopupPage.less'

const { TextArea } = Input;

function WithDrawInvestorPopupPage(props) {
  const { result, fetching, match } = props;
  const { shareId, tab } = match.params;

  const [value, setValue] = useState(null);
  const [active, setActive] = useState(false);

  const history = useHistory();

  function handleClose() {
    history.push('/home/deals');
  }

  useEffect(() => {
    if (result) {
      handleClose();
    }
  }, [result]);

  const checkRequired = () => {
    if (!value) {
      message.error('Indicate the reason', 3);
      if (!active) {
        setActive(true);
      }
    } else {
      const data = {
        reason: value
      };
      props.withdrawApplication(shareId, data);
    }
  }

  const changeValue = value => {
    if (!active) {
      setActive(true);
    }
    setValue(value);
  }

  return (
    <PopupWrapper
      width={420}
      path={`/home/deal-share-info/${shareId}/${tab}`}
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content" style={{ padding: '24px 48px' }}>
          <div className="dialog-title deal">Withdraw Application</div>
          <div className="deal-text">We are sorry to hear that you would like to withdraw your application. Please provide a reason for the withdrawal:</div>
          <br />
          <div className="area-block">
            <span>Insert reason</span>
            <TextArea
              className={(active && !value) && 'error-input'}
              rows={4}
              onBlur={e => changeValue(e.target.value)}
            />
          </div>
          <div className="btn-footer" style={{ marginTop: 24 }}>
            <Button type="primary white" className="btn-primary" onClick={checkRequired}>
              Confirm
          </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    result: state.dealShares.applicationWithdrawResult,
    fetching: state.dealShares.fetching
  }
}

const mapDispatchToProps = (dispatch) => ({
  withdrawApplication: (shareId, data) => dispatch(DealSharesActions.applicationWithdrawRequest(shareId, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WithDrawInvestorPopupPage)
