import React from 'react'

import './Terms.less'

function TermsPage(props) {

    return (
        <div className="Terms">
            <h1 className="terms--subhead">
                <div>The Infrastructure Deals Network</div>
                <div style={{fontWeight: 400}}>Terms of Use 2021</div>
                </h1>

            <h1 className="terms--head1">Terms of Use</h1>
            <h2><span className="head2--indent">1.</span> What does the User need to know about The Infrastructure Deals Network Ltd and these Terms?</h2>
            <ul>
                <li><span className="number--indent">1.1</span> We are The Infrastructure Deals Network Ltd, a limited company registered in England and Wales with Kemp House, 160 City Road, London, United Kingdom, EC1V 2NX and registered number 12894864 (referred to as <strong>“we”</strong>, <strong>“us”</strong>, <strong>“Infrastructure Deals Network”</strong> and <strong>“IDN”</strong>).
                </li>
                <li><span className="number--indent">1.2</span> IDN provides the Platform and the Services.</li>
                <li><span className="number--indent">1.3</span> Capitalised terms used herein are set out in Schedule hereto.
                </li>
            </ul>
            <h2><span className="head2--indent">2.</span> Agreement to these Terms</h2>
            <ul>
                <li><span className="number--indent">2.1</span> The  User  is  advised  to  review  these  Terms  carefully  and  make  sure  that  it  understands  them  before  using  the Platform  or  Services.  If  the  User  does  not  agree  to  these  Terms,  it  must  cease  use  of  the  Platform  and  Services immediately.
                </li>
                <li><span className="number--indent">2.2</span> The User acknowledges and agrees that any personal data provided by or about the User will be processed by IDN in accordance with:
                </li>
                <li>
                    <ul>
                        <li><span className="letter--indent">i.</span> the Privacy Policy; and
                        </li>
                        <li><span className="letter--indent">ii.</span> paragraph 6 of these Terms.
                        </li>
                    </ul>
                </li>
            </ul>
            <h2><span className="head2--indent">3.</span> Whose responsibility is it to update the User’s information?</h2>
            <ul>
                <li><span className="number--indent">3.1</span> By  using  the  Platform,  the  User warrants  that  all  registration  information  provided  by  the  User  is true,  accurate, complete  and  not  misleading,  and  the  User  undertakes  and  agrees  to  keep  such  information  up-to-date  and current.
                </li>
                <li><span className="number--indent">3.2</span> IDN accepts no liability for any loss or damage suffered by the User by reason of any registration information being incorrect.
                </li>
            </ul>
            <h2><span className="head2--indent">4.</span> What are the User’s obligations?</h2>
            <ul>
                <li><span className="number--indent">4.1</span> The User shall:
                </li>
                <li>
                    <ul>
                        <li><span className="letter--indent">i.</span> provide IDN with:
                        </li>
                        <li>
                            <ul>
                                <li><span className="letter--indent">(i)</span> all necessary co-operation in relation to these Terms; and
                                </li>
                                <li><span className="letter--indent">(ii)</span> all necessary access to such information as may be required by IDN;<br />in order to provide the Services;

                                </li>
                            </ul>
                        </li>
                        <li><span className="letter--indent">ii.</span> without affecting its other obligations under these Terms, comply with all applicable laws and regulations with respect to its activities under these Terms;
                        </li>
                        <li><span className="letter--indent">iii.</span> be, to the extent permitted by law and except as otherwise expressly provided in this agreement, solely responsible  for  procuring,  maintaining  and  securing  its  network  connections  and  telecommunications links from its systems to IDN’s data centres, and all problems, conditions, delays, delivery failures and all other  loss  or  damage  arising  from  or  relating  to  the User’s network  connections  or  telecommunications links or caused by the internet.
                        </li>
                    </ul>
                </li>
                <li><span className="number--indent">4.2</span>The User must not at any point during the User’s use of the Platform and Services do anything which may circumvent IDN’s rights under these Terms of Use or the Platform Contracts, save that it is acknowledged that the restrictive covenants contained in the Platform Contracts do not apply in certain circumstances set out therein.
                </li>
                <li><span className="number--indent">4.3</span> The User shall not distribute or transmit any material during the course of its use of the Services that:
                </li>
                <li>
                    <ul>
                        <li><span className="letter--indent">i.</span> is  unlawful,  harmful,  threatening,  defamatory,  obscene,  infringing,  harassing  or  racially  or  ethnically offensive;
                        </li>
                        <li><span className="letter--indent">ii.</span> facilitates illegal activity;
                        </li>
                        <li><span className="letter--indent">iii.</span> depicts sexually explicit images;
                        </li>
                        <li><span className="letter--indent">iv.</span> promotes unlawful violence;
                        </li>
                        <li><span className="letter--indent">v.</span> is discriminatory based on race, gender, colour, religious belief, sexual orientation, disability; or
                        </li>
                        <li><span className="letter--indent">vi.</span> is otherwise illegal or causes damage or injury to any personor property,
                        </li>
                    </ul>
                </li>
                <li> and IDN reserves the right, without liability or prejudice to its other rights to the User, to disable the User’s access to any material that breaches the provisions of this paragraph 4.3.
                </li>
                <li><span className="number--indent">4.4</span> The user shall not:
                </li>
                <li>
                    <ul>
                        <li><span className="letter--indent">i.</span> except  as  may  be  allowed  by  any  applicable  law  which  is  incapable  of  exclusion  by  agreement  between the parties and except to the extent expressly permitted under these Terms:
                        </li>
                        <li>
                            <ul>
                                <li><span className="letter--indent">(i)</span> attempt  to  copy,  modify,  duplicate,  create  derivative  works  from,  frame,  mirror,  republish, download,  display,  transmit,  or  distribute  all  or  any  portion  of  the  Platform  in  any  form  or media or by any means; or
                                </li>
                                <li><span className="letter--indent">(ii)</span> attempt to de-compile, reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Platform; or

                                </li>
                            </ul>
                        </li>
                        <li><span className="letter--indent">ii.</span> access all  or  any  part  of  the  Services  in  order  to  build  a  product  or  service  which  competes  with  the Services; or
                        </li>
                        <li><span className="letter--indent">iii.</span> use the Services to provide services to third parties; or
                        </li>
                        <li><span className="letter--indent">iv.</span> license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Services available to any third party; or
                        </li>
                        <li><span className="letter--indent">v.</span> attempt  to  obtain,  or  assist  third  parties  in  obtaining,  access  to  the  Services,  other  than  as  explicitly contemplated herein; or
                        </li>
                        <li><span className="letter--indent">vi.</span> introduce  or  permit  the  introduction  of,  any  worms,  Trojan  horses,  viruses  and  other  similar  things  or devices (each a “Virus” and together the “Viruses”) any Virus into IDN’s network and information systems.
                        </li>
                    </ul>
                </li>
                <li><span className="number--indent">4.5</span> The User shall use all reasonable endeavours to prevent any unauthorised access to, or use of, the Services and, in the event of any such unauthorised access or use, promptly Notify IDN.
                </li>
            </ul>
            <h2><span className="head2--indent">5.</span> What are IDN’s rights and obligations?</h2>
            <ul>
                <li><span className="number--indent">5.1</span> In  providing  Users  with  access  to  the  Platform  and/or  the  Services,  IDN  reserves  the  following  rights,  and,  in accessing,  browsing  or  otherwise  using  the  Platform the User  grants  to  and  agrees  that  IDN  shall  have  the following rights:
                </li>
                <li>
                    <ul>
                        <li><span className="letter--indent">i.</span> the right to refuse or withdraw the User’s access to the Platform and/or terminate the User’s account without notice and without liability if, in IDN’s sole and absolute discretion, the User violates, attempts to violate or breaches any of these Terms, or any applicable law or regulation, or if any act or omission of the user brings, or is likely to bring, the Platform into disrepute;
                        </li>
                        <li><span className="letter--indent">ii.</span> the right to amend or update the Platform, billing methods or these Terms from time to time;
                        </li>
                        <li><span className="letter--indent">iii.</span> the right without notice, to remove content, materials or the User’s account for any reason whatsoever at IDN’s sole and absolute discretion, including without limitation content and materials which are unlawful, offensive,  threatening,  libellous,  defamatory,  obscene  or  which  infringe  third  party  rights,  or  where  the account is used to propagate any such content or materials; and
                        </li>
                        <li><span className="letter--indent">iv.</span> the right to access the User’s account in order to respond to its requests for technical support so long as IDN maintains reasonably appropriate administrative physical and technical safeguards for the protection of the security and confidentiality and integrity of the User’s data.
                        </li>
                    </ul>
                </li>
                <li><span className="number--indent">5.2</span> IDN:
                </li>
                <li>
                    <ul>
                        <li><span className="letter--indent">i.</span> subject to paragraph 5.4, does not warrant that:
                        </li>
                        <li>
                            <ul>
                                <li><span className="letter--indent">(i)</span> the Services and/or the information obtained by the User through the Services will meet the User’s requirements;
                                </li>
                                <li><span className="letter--indent">(ii)</span> the  Platform  or  the  Services  will  be  free  from  a  weakness  in  the  computational  logic  (for example, code) found in software and hardware components that when exploited, results in a negative impact to the confidentiality, integrity, or availability;
                                </li>
                                <li><span className="letter--indent">(iii)</span> the  Platform  or  Services  will  comply  with  any  laws,  regulations,  codes,  guidance  (from regulatory  and  advisory  bodies,  whether  mandatory  or  not),  international  and  national standards,  industry  schemes  and  sanctions,  which  are  applicable  to  the  User  relating  to security  of  network  and  information  systems  and  security  breach  and  incident  reporting requirements, which  may include the cybersecurity Directive ((EU) 2016/1148), Commission Implementing Regulation ((EU) 2018/151), the Network and Information systems Regulations 2018 (SI 506/2018), all as amended or updated from time to time; or
                                </li>
                                <li><span className="letter--indent">(iv)</span> the User’s use of the Platform and/or the Services will be uninterrupted or error-free, or that the Services and/or the information obtained by the User through the Services will meet the User’s requirements.
                                </li>
                            </ul>
                        </li>
                        <li><span className="letter--indent">ii.</span> is not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of  data  over  communications  networks  and  facilities,  including  the  internet,  and  the  User  acknowledges that  the  Services  may  be  subject  to  limitations,  delays  and  other  problems  inherent  in  the  use  of  such communications facilities.
                        </li>
                    </ul>
                </li>
                <li><span className="number--indent">5.3</span> The  User  acknowledges  and  agrees  that  IDN  is  not  responsible  or  liable  for  the  activities,  work  product  or behaviour  of  any  other  User  or  third  party  with  whom  the  User  is  in  communication  as  a  result  of  using  the Services.
                </li>
                <li><span className="number--indent">5.4</span> IDN warrants that it has and will maintain all necessary licences, consents, and permissions necessary for the performance of its obligations under these Terms and that it does and shall continue to comply with any anti-bribery and money laundering laws and regulations applicable to it.
                </li>
            </ul>
            <h2><span className="head2--indent">6.</span> How does IDN use the User’s personal data?</h2>
            <ul>
                <li><span className="number--indent">6.1</span> The User and IDN will comply with all applicable requirements of the Data Protection Legislation. This paragraph 6 is in addition to, and does not relieve, remove or replace, a party's obligations or rights under the Data Protection Legislation.
                </li>
                <li><span className="number--indent">6.2</span> Both the User and IDN acknowledge that:
                </li>
                <li>
                    <ul>
                        <li><span className="letter--indent">i.</span> if  IDN  processes  any  personal  data  on  the User’s behalf  when  performing  its  obligations  under  these Terms,  the user is  the  controller  and  IDN  is  the  processor  for  the  purposes  of  the  Data  Protection Legislation;
                        </li>
                        <li><span className="letter--indent">ii.</span> IDN  and  the User shall  set  out  the  scope,  nature  and  purpose  of  processing  by  IDN,  the  duration of  the processing  and  the  types  of  personal  data  and  categories  of  data  subject  upon  the User’s use  of  the Platform; and
                        </li>
                        <li><span className="letter--indent">iii.</span> the personal data may be transferred  or stored by IDN outside of the UK, EEA or the country where the User is located in order to carry out the Services and IDN’s other obligations under these Terms.
                        </li>
                    </ul>
                </li>
                <li><span className="number--indent">6.3</span> Without  prejudice  to  the  generality  of  paragraph 6.1,  the User will  ensure  that  it  has  all  necessary  appropriate consents and notices in place to enable lawful transfer of the personal data to IDN for the duration and purposes of  these  Terms  so  that IDN  may  lawfully  use,  process  and  transfer  the  personal  data  in  accordance  with  these Terms on the User’s behalf.
                </li>
            </ul>
            <h2><span className="head2--indent">7.</span> Confidential Information</h2>
            <ul>
                <li><span className="number--indent">7.1</span> The User acknowledges that the  Confidential Information is confidential and proprietary to IDN, its licensors and other Users and undertakes:
                </li>
                <li>
                    <ul>
                        <li><span className="letter--indent">i.</span> to keep the Confidential Information confidential and not disclose it to anyone;
                        </li>
                        <li><span className="letter--indent">ii.</span> to  ensure  that  all  Confidential  Information  is  kept  in  a  secure mannerand  to  apply  the  same  security measures  and  degree  of  care  to  the  Confidential  Information  as  it  applies  to  its  own  confidential information;
                        </li>
                        <li><span className="letter--indent">iii.</span> to use the Confidential Information only for the purpose of using the Platform and Services and not use or exploit it in any other way;
                        </li>
                        <li><span className="letter--indent">iv.</span> to keep confidential and not disclose to any person, except as permitted by paragraph 7.2, the fact that the Confidential Information has been made available or that any discussions may occur or have occurred between  Users  and/or  IDN  relating  to  the  Confidential  Information  or  the  use  of  the  Platform  and Services; and
                        </li>
                        <li><span className="letter--indent">v.</span> to inform IDN if it becomes aware or suspects that Confidential Information has been disclosed in breach of this paragraph 7.
                        </li>
                    </ul>
                </li>
                <li><span className="number--indent">7.2</span> A User may disclose Confidential Information to
                </li>
                <li>
                    <ul>
                        <li><span className="letter--indent">i.</span> its Representatives, in each case:
                        </li>
                        <li>
                            <ul>
                                <li><span className="letter--indent">(i)</span> only in connection with use of the Platform and Services;
                                </li>
                                <li><span className="letter--indent">(ii)</span> provided  that  the  User  has  informed  them  in  writing  prior  to  disclosing  it,  that  the information  is  confidential  and they  agree  to  maintain  confidentiality  on  the terms  of  these Rules;
                                </li>
                            </ul>
                        </li>
                        <li><span className="letter--indent">ii.</span> any  person  to  whom  information  is  lawfully  required  to  be  disclosed  by  any  governmental,  banking, taxation or other regulatory authority or any similar body, or pursuant to applicable law, regulation, court order  or  by  the  rules  of  any  relevant  exchange,  provided  that,  to  the  extent  permitted  by  law  or regulation, the relevant party being required to make the disclosure, shall use all reasonable endeavours to notify IDN of that disclosure.
                        </li>
                    </ul>
                </li>
                <li><span className="number--indent">7.3</span> A  User  will  be  responsible  for  any  breach  of this paragraph 7 by any Representative  to  whom  it  has  disclosed Confidential Information.
                </li>
                <li><span className="number--indent">7.4</span> The User, without prejudice to any other obligations:
                </li>
                <li>
                    <ul>
                        <li><span className="letter--indent">i.</span> acknowledges that the existence of discussions between Users and/or any Target and the terms of those discussions  may  (in  whole  or  in part) constitute “inside information” for the purposes of Part V of the Criminal Justice Act 1993 (“CJA”) and/or the Market Abuse Regulations (EU 596/2014) which has effect (as amended)  in  English  law  by  virtue  of  the  European  Union  (Withdrawal)  Act  2018 (“UK  MAR”)”, and that any Representatives who are in, or acquire, possession of Confidential Information may have information as an “insider” for the purposes of the CJA and inside information for the purposes of UK MAR;
                        </li>
                        <li><span className="letter--indent">ii.</span> shall  not  make  use  of  the  Confidential  Information  for  the  purposes  of  dealing  or  encouraging  another person to deal in any entity’s securities which would constitute a breach of CJA or UK MAR and shall not disclose any Confidential  Information  to  any  person  or  persons  unless  permitted  to do  so  under this paragraph 7;
                        </li>
                        <li><span className="letter--indent">iii.</span> agrees  that  no  behaviour  amounting  to  market  abuse  (as  defined  in  UK  MAR)  should  be  based  on  any Confidential Information;
                        </li>
                        <li><span className="letter--indent">iv.</span> consents to being made an insider within the meaning of the CJA and/or  UK  MAR  and shall bring to the attention of its Representatives who, from time to time, have information as an insider, the prohibitions on market abuse contained in UK MAR;
                        </li>
                        <li><span className="letter--indent">v.</span> is aware of its obligations under all applicable law and regulations relating to unpublished, price-sensitive information; and
                        </li>
                        <li><span className="letter--indent">vi.</span> shall  comply  with  any  request  from  IDN,  any  Target  and/or  any  other  User to  record  information  in relation  to  itself  and  its Representatives required  to  be  included  on  an  insider  list  in  relation  to any Opportunity to be maintained in accordance with UK MAR and shall provide that information forthwith to IDN,  the  Target  and/or  any  other  User, and  to  the  Financial  Conduct  Authority  (or  any  other  applicable regulator) on request.
                        </li>
                    </ul>
                </li>
                <li><span className="number--indent">7.5</span> IDN shall be bound (to the extent applicable) to the provisions of this clause 7 on an equivalent basis to the User.
                </li>
            </ul>
            <h2><span className="head2--indent">8.</span> Term and termination</h2>
            <ul>
                <li><span className="number--indent">8.1</span> These Terms shall, unless otherwise terminated as provided in this paragraph 8, commence on the Effective Date and shall continue, unless terminated sooner in accordance with these terms, until terminated by IDN or the User.
                </li>
                <li><span className="number--indent">8.2</span> IDN  shall be permitted in its sole and absolute discretion to terminate these Terms in respect of the User at any time without giving any reason. The User shall be permitted in its sole and absolute discretion to terminate these terms  at  any  time  by  giving  no  less  than  4 week’s notice to  IDN. These  Terms  shall  automatically terminate  in respect of a User in the event that the Platform Contract (by which the User is authorised to access the Platform and Services pursuant to paragraph 2.2 of these Terms) is terminated.
                </li>
                <li><span className="number--indent">8.3</span> On termination of these Terms for any reason:
                </li>
                <li>
                    <ul>
                        <li><span className="letter--indent">i.</span> all licences granted under these Terms shall immediately terminate and the User shall immediately cease all use of the Services;
                        </li>
                        <li><span className="letter--indent">ii.</span> IDN may destroy or otherwise dispose of any of the data in its possession relating to the User and/or its activities on the Platform; and
                        </li>
                        <li><span className="letter--indent">iii.</span> any  rights,  remedies,  obligations  or  liabilities  of  the  parties  that  have  accrued  up  to  the  date  of termination shall not be affected or prejudiced by termination. For the avoidance of doubt, the User shall remain  bound  by  the  Platform  Contract  (or  if  the Platform  Contract  has  been  terminated,  by  the provisions  of  the  Platform  Contract  stated  to  remain  in  force  notwithstanding  termination  of  such Platform Contract) including without limitation those provisions relating to any open Opportunity.
                        </li>
                    </ul>
                </li>
            </ul>
            <h2><span className="head2--indent">9.</span> How has IDN limited its liability?</h2>
            <ul>
                <li><span className="number--indent">9.1</span> IDN is not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of data  over  communications  networks  and  facilities,  including  the  internet,  and  the User acknowledges  that  the Platform and/or the Services may be subject to limitations, delays and other problems inherent in the use of such communications facilities.
                </li>
                <li><span className="number--indent">9.2</span> IDN  shall  have  no  liability  to  the User under  these  Terms  if  it  is  prevented  from  or  delayed in  performing  its obligations under these Terms, or from carrying on its business, by acts, events, omissions or accidents beyond its reasonable control, including, without limitation, strikes, lock-outs or other industrial disputes (whether involving the workforce  of IDN  or any other party), failure of a utility service or transport or telecommunications network, act  of  God,  war,  riot,  civil  commotion,  malicious  damage,  lockdown,  breakout  of notifiable  disease  or  pandemic, compliance  with  any  law  or  governmental  order,  rule,  regulation  or  direction,  accident,  breakdown  of  plant  or machinery, fire, flood, storm or default of suppliers or sub-contractors, provided that the User is Notified of such an event and its expected duration.
                </li>
                <li><span className="number--indent">9.3</span> Except as expressly and specifically provided in these Terms:
                </li>
                <li>
                    <ul>
                        <li><span className="letter--indent">i.</span> the User assumes sole responsibility for any information uploaded to the Platform by the User, any results obtained from the use of the Services by the User, and any conclusions drawn from such use;
                        </li>
                        <li><span className="letter--indent">ii.</span> IDN  shall  have  no  liability  for  any  damage  caused  by  errors  or  omissions  in  any  information  provided  to IDN by the User, any exercise of discretion by IDN, any actions taken by IDN at the User’s direction or any breach of applicable laws and/or regulations by reason of the User’s activities or information uploaded to the Platform by the User;
                        </li>
                        <li><span className="letter--indent">iii.</span> the User acknowledges  and  agrees  that  the User must  comply  at all  times  with  rules  applicable  to price sensitive  information in  respect  of any target  listed  on  any  stock  market.  The User acknowledges  and agrees  that  IDN  shall  have  no  liability  whatsoever  for  any  losses,  costs,  fines,  penalties  or  reputational damage  suffered  by  the User or  any  other  person  for  or  to  whom  the User is  liable  arising  from  the publication or dissemination of price sensitive information via the Platform;
                        </li>
                        <li><span className="letter--indent">iv.</span> all warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by applicable law, excluded from these Terms;
                        </li>
                        <li><span className="letter--indent">v.</span> IDN shall have no responsibility or liability for the behaviour or conduct of any person using the Platform;
                        </li>
                        <li><span className="letter--indent">vi.</span> IDN shall have no liability for, and expressly disclaims any liability in respect of, any information provided to the User; and
                        </li>
                        <li><span className="letter--indent">vii.</span> the Services are provided to the User on an "as is" basis
                        </li>
                    </ul>
                </li>
                <li><span className="number--indent">9.4</span> Nothing in these Terms excludes the liability of IDN:
                </li>
                <li>
                    <ul>
                        <li><span className="letter--indent">i.</span> for death or personal injury caused by IDN’s gross negligence;
                        </li>
                        <li><span className="letter--indent">ii.</span> for fraud or fraudulent misrepresentation; or
                        </li>
                        <li><span className="letter--indent">iii.</span> for any other loss or damage incapable of exclusion by applicable law.
                        </li>
                    </ul>
                </li>
                <li><span className="number--indent">9.5</span> Subject to paragraph 9.4:
                </li>
                <li>
                    <ul>
                        <li><span className="letter--indent">i.</span> IDN  shall  not  be  liable  whether  in  tort  (including  for  negligence  or  breach  of  statutory  duty),  contract, misrepresentation, restitution or otherwise for any loss of profits, loss of business, depletion of goodwill and/or  similar  losses  or  loss  or  corruption  of  data  or  information,  or  pure  economic  loss,  or  for  any special, indirect or consequential loss, costs, damages, charges or expenses however arising under these Terms; and
                        </li>
                        <li><span className="letter--indent">ii.</span> IDN’s  total  aggregate  liability  in  contract,  tort  (including  negligence  or  breach  of  statutory  duty), misrepresentation, restitution or otherwise, arising in connection with the performance or contemplated performance of these Terms shall be limited to £1,000,000.
                        </li>
                    </ul>
                </li>
            </ul>
            <h2><span className="head2--indent">10.</span> Who owns the Platform?</h2>
            <ul>
                <li><span className="number--indent">10.1</span> IDN  and  its  licensor(s)  are  the  sole  owners  of  the  Platform,  which  includes  any  software,  domains,  and  content made  available  through  the  Platform.  The  Platform  is  protected  by  UK  and  International copyright  and  other intellectual property laws. All such rights are reserved.
                </li>
                <li><span className="number--indent">10.2</span> IDN permits the User to use the Platform for its own personal and/or commercial use subject to these Terms and IDN grants the User a limited and non-transferable licence solely for that purpose.
                </li>
                <li><span className="number--indent">10.3</span> The User may not sell, export, license, modify, copy, distribute or transmit the Platform (or any part of it) without IDN's prior express written consent.
                </li>
                <li><span className="number--indent">10.4</span> Any  unauthorised  use  of  the  Platform  may  result  in  the  termination  of  the  limited licence  granted  by  IDN.  IDN reserves the right to terminate the limited licence in respect of the User in its sole and absolute discretion
                </li>
                <li><span className="number--indent">10.5</span> IDN  and  its  graphics,  logos,  icons  and  service  names  related  to  the  Platform  are  registered  and  unregistered trademarks or trade dress of IDN. They may not be used without IDN's prior express written permission.
                </li>
                <li><span className="number--indent">10.6</span> All  other  trademarks  not  owned  by  IDN  that  appear  in  connection  with  the  Platform  are  the  property  of  their respective  owners,  who  may  or  may  not  be  affiliated with,  connected  to  or  sponsored  by  IDN.  IDN  accepts  no liability for any unauthorised use of any trademarks any User or third party.
                </li>
            </ul>
            <h2><span className="head2--indent">11.</span> What security measures does IDN have in place?</h2>
            <ul>
                <li><span className="number--indent">11.1</span> Whilst IDN has implemented commercially reasonable technical and organisational measures to secure the User’s personal information from unauthorised use, IDN cannot guarantee that unauthorised third  parties will never be able to defeat those measures. IDN accepts no liability for any losses associated with any unauthorised use.
                </li>
                <li><span className="number--indent">11.2</span> The User acknowledges that it provides personal and other information at its own risk.
                </li>
                <li><span className="number--indent">11.3</span> By downloading, using  and/or  accessing in any way the Platform  and/or  material provided through the Platform, the User consents to receiving electronic communications and notices from IDN.
                </li>
                <li><span className="number--indent">11.4</span> The  User agrees  that  any  notice,  agreement,  disclosure  or  other  communications  that  IDN  sends  to  him electronically will satisfy any legal communication requirements, including that such communications be in writing.
                </li>
            </ul>
            <h2><span className="head2--indent">12.</span> Third party links</h2>
            <ul>
                <li><span className="number--indent">12.1</span> From time to time the User may find links to third-party websites on the Platform. These links are provided for the User’s information only and are not recommended by, associated with or in any way attributable to IDN. IDN has no control over the content of third-party sites and accepts no responsibility or liability for them or for any loss or damage that may arise from the User’s use of them. When accessing a third-party website, the User is advised to check such website’s terms of service and privacy policy to ensure compliance with applicable laws.
                </li>
                <li><span className="number--indent">12.2</span> From  time  to  time the  User may  use  or  access  services,  promotions  and  websites  of  third  parties.  In  using  or accessing  third  party  services,  promotions  and  websites,  the User agrees  to  be  bound  by  the  terms  of service  of such third parties governing their services, promotions and websites and hereby acknowledges that IDN shall not be responsible for the provision of services, accuracy of promotions or content of websites belonging or operated by third parties.
                </li>
            </ul>
            <h2><span className="head2--indent">13.</span> How are these Terms amended?</h2>
            <ul>
                <li><span className="number--indent">13.1</span> Subject  to  paragraph  13.3,  IDN  may  modify  these  Terms  by  giving  the User at least ten (10) Business Days’ prior written notice. IDN will Notify the User of any changes to these Terms by emailing the User (to the email address provided  by  the User to  IDN  on  www.idn.ltd,  in  the User’s Platform  account).  It  is  the User’s responsibility  to ensure that a correct and current email address is always associated with its Platform account.
                </li>
                <li><span className="number--indent">13.2</span> Any changes to these Terms will take effect at the expiry of ten (10) Business Days from the date of Notifying any changes  to  these  Terms  and  being  emailed  by  IDN  to  the  email  address  provided  by  the User to  IDN  on www.idn.ltd  The User explicitly  accepts  that  such  changes  shall  take  effect  irrespective  of  whether  the  email referenced  in  paragraph  13.1  has  not  been  delivered  successfully  by  reason  of  the User not  having  updated its email address, or such email going into the User’s spam box or for any other reason.
                </li>
                <li><span className="number--indent">13.3</span> Any changes to these Terms that are immaterial or are required as a result of new regulation, any judgment of a regulator or court of competent jurisdiction or by law enforcement may be made unilaterally by IDN in accordance with paragraph 13.1. For the avoidance of doubt any material changes to these Terms shall require the prior written consent of the User.
                </li>
            </ul>
            <h2><span className="head2--indent">14.</span> General</h2>
            <ul>
                <li><span className="number--indent">14.1</span> IDN  may  change  or  discontinue  the  Services  and/or  Platform  at  any  time  without  prior  notice.  IDN  reserves  the right  to  terminate  these  Terms  for  any  reason,  without  notice.  In  the  event  of  any  termination,  the User will immediately cease to be able to use of the Services and Platform.
                </li>
                <li><span className="number--indent">14.2</span> These  Terms  are  agreed  between  the User and  IDN. No other  person  shall  have  any  rights  under  or  connection with these Terms under the Contracts (Rights of Third Parties) Act 1999.
                </li>
                <li><span className="number--indent">14.3</span> If  any  court  or  competent  authority  decides  that  any  provision  of  these  Terms  is  held  to  be  invalid,  unlawful  or unenforceable to any extent, such provision shall, to that extent only, be severed from the remaining terms, which shall continue to be valid to the fullest extent permitted by law and interpreted in a manner that is consistent with the commercial interests of the parties.
                </li>
                <li><span className="number--indent">14.4</span> IDN’s failure to enforce any provision of these Terms shall not constitute a waiver of that or any other provision and will not relieve the User from the obligation to comply with such provision.
                </li>
                <li><span className="number--indent">14.5</span> The User is not permitted to assign, transfer, charge, sub-contract or deal in any other manner with all or any of any its rights under these Terms without IDN’s prior express written consent. IDN may assign,  transfer,  charge, sub-contract or deal in any other manner with all or any its rights under these Terms.
                </li>
                <li><span className="number--indent">14.6</span> These  Terms  and  each  of  the  documents  referenced  herein  set  forth  the  entire  understanding  and  agreement between the User and IDN with respect to the subject matter hereof.
                </li>
                <li><span className="number--indent">14.7</span> These Terms, their subject matter and its formation (and any non-contractual disputes or claims) are governed by the laws of England and Wales. IDN and the User agree to be subjected to the exclusive jurisdiction of the courts of England and Wales.
                </li>
                <li><span className="number--indent">14.8</span> Notices shall be served by IDN on the User by email to the email address associated with the User’s account at the date that the notice is sent.
                </li>
                <li><span className="number--indent">14.9</span> Notices may only be served on IDN at its registered office address from time to time and shall be deemed to have been served;
                </li>
                <li>
                    <ul>
                        <li><span className="letter--indent">i.</span> if delivered by hand, at the time proof is obtained that the notice was left at the address or given to the addressee; or
                        </li>
                        <li><span className="letter--indent">ii.</span> in the case of pre-paid first-class UK  post or the other next working day delivery service, at 9:00 am on the second day after posting or at the time recorded by the delivery service.
                        </li>
                    </ul>
                </li>
                <li><span className="number--indent">14.10</span> Nothing  in  these  Terms  is  intended  to  or  shall  operate  to  create  a  partnership  between  IDN  and  the User,  or authorise either of them to act as agent for the other, and neither IDN nor the User shall have the authority to act in the name or on behalf of or otherwise to bind the other in any way (including, but not limited to, the making of any  representation  or  warranty,  the  assumption  of  any  obligation  or  liability  and the  exercise  of  any  right  or power).
                </li>
            </ul>

            <h4 style={{textAlign: 'center'}}>SCHEDULE<br />DEFINITIONS</h4>
            <p>The following capitalised terms shall have the meanings given in this table.</p>
            <table className="first--table">
                <tbody>
                    <tr>
                        <th>Authorised Person</th>
                        <td>
                            <p>a  director  or  more  senior  person  who  is  an  employee  or  contractor  of  the  User authorised to bind the User to contractual obligations (which may be the User);</p></td>
                    </tr>
                    <tr>
                        <th>Business Day</th>
                        <td>
                            <p>a day other than a weekend or bank holiday on which banks are open for business in the City of London;</p>
                        </td>
                    </tr>
                    <tr>
                        <th>Confidential Information</th>
                        <td>
                            <p>means all documents and information made available or disclosed to the User in the course of or as a result of the User’s use of the  Platform and/or  Services  including without limitation the identity of other Users and the fact that they are users of the Platform  and  Services, together with  any  other  information  supplied  to  the User  or its Representatives on request by IDN or any other User;</p></td>
                    </tr>
                    <tr>
                        <th>Data Protection Legislation</th>
                        <td>
                            <p>all applicable data protection and privacy legislation in force from time to time in the UK including the UK implementation of the General Data Protection Regulation ((EU) 2016/679)  as  implemented  by  the  Data  Protection  Act  2018;  the  Privacy  and Electronic    Communications    Directive    2002/58/EC    (as    updated    by    Directive 2009/136/EC)  and  the  Privacy  and  Electronic  Communications  Regulations  2003  (SI 2003/2426)  as  amended  and,  to  the  extent  that  the  General  Data  Protection Regulation  ((EU)  2016/679)  applies  to  the  processing  of  any  personal  data,  such legislation as in force from time to time.</p></td>
                    </tr>
                    <tr>
                        <th>Effective Date</th>
                        <td>
                            <p>the earlier of (i) the date of acceptance of these Terms and (ii) use by the User of the Services and/or Platform;</p></td>
                    </tr>
                    <tr>
                        <th>Notify or Notification</th>
                        <td>
                            <p>send a notice to any party via the Platform or by email;</p></td>
                    </tr>
                    <tr>
                        <th>Opportunity</th>
                        <td>
                            <p>an investment opportunity  made available  by  a  User  to  another  User  relating  to  a potential transaction in respect of a Target;</p></td>
                    </tr>
                    <tr>
                        <th>Platform</th>
                        <td>
                            <p>the  website  at  www.idn.ltd  and  any  other  applications  (including without  limitation for smart devices) published by IDN from time to time;</p></td>
                    </tr>
                    <tr>
                        <th>Platform Contracts</th>
                        <td>
                            <p>the agreements between IDN and a User further regulating the User’s use of the Services;</p></td>
                    </tr>
                    <tr>
                        <th>Privacy Policy</th>
                        <td>
                            <p>IDN’s privacy policy which can be found on the Platform at [https://www.idn.ltd/privacy-policy];</p>
                        </td>
                    </tr>
                    <tr>
                        <th>Representatives</th>
                        <td>
                            <p>in  the  case  of  the  User,  its  members,  officers,  affiliates,  employees,  registered representatives, agents and advisors (including, without limitation, attorneys);</p></td>
                    </tr>
                    <tr>
                        <th>Services</th>
                        <td>
                            <p>the provision of the Platform and all other associated services provided by IDN to the
                            User;</p></td>
                    </tr>
                    <tr>
                        <th>Target</th>
                        <td>
                            <p>the business or asset to which an Opportunity relates;</p></td>
                    </tr>
                    <tr>
                        <th>Terms</th>
                        <td>
                            <p>these terms and conditions;</p></td>
                    </tr>
                    <tr>
                        <th>User</th>
                        <td>
                            <p>any  person  using  the  Platform  and/or  Services  who must  have agreed  to  these Terms prior to such use;</p></td>
                    </tr>
                    <tr>
                        <th>Virus</th>
                        <td>
                            <p>any worms, Trojan horses, viruses and other similar things or devices.</p></td>
                    </tr>
                </tbody>
            </table>
            <h1 className="terms--subhead"
                style={{marginTop: 20, fontSize: 18, fontWeight: 500, textAlign: 'justify'}}
            >
                Copyright © 2021 IDN. All rights reserved. Any unauthorized copying, duplication or distribution of this IDN document will constitute an infringement of IDN’s copyright.
            </h1>
        </div>
    )
}

export default TermsPage
