import React, { useState, useEffect } from 'react'
import { Space, Button } from 'antd'

import Images from '../Images'

function InvestorIntroSuccessPage(props) {

  return (
    <div className="sign-up-step intro-bg">
      <div className="modal-container" style={{ width: 360, padding: 32 }}>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.onClose}>
          <path d="M1.66663 1.66667L11 11M11 11L20.3333 20.3333M11 11L20.3333 1.66667M11 11L1.66663 20.3333" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <Space style={{ marginBottom: 24 }}>
          <img src={Images.phoneOk} alt="" />
        </Space>
        <div className="step-finish-title intro">Form submitted</div>
        <div className="step-finish-text intro">A member of our team will be in touch shortly to schedule a call. .</div>
        <Button type="primary white" className="btn-primary" style={{ width: 217 }} onClick={props.onClose}>
          Go back
        </Button>
      </div>
    </div>
  )
}

export default InvestorIntroSuccessPage
