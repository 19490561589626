import React from 'react'
import { Layout, Space, Button } from 'antd'
import { useHistory } from 'react-router-dom'

import Images from '../../Images'

function InvestorSignUpOnboarding(props) {
  const history = useHistory();

  return (
    <Layout>
      <div className="sign-up-step with-bg">
        <div className="with-bg-top">
          <img src={Images.logoWhite} alt="" onClick={()=>history.push('/')} />
        </div>
        <div className="modal-container" style={{ width: 420, padding: '24px 32px' }}>
          <Space style={{marginBottom: 24}}>
            <img src={Images.phoneOk} alt="" />
          </Space>
          <div className="step-title intro" style={{ marginBottom: 24 }}>Firm onboarding</div>
          <div className="sign-up-start-text" style={{ marginBottom: 32 }}>You have been invited to sign up on IDN as a Lead Investor. The Lead Investor is primarily responsible for creating his/her investment firm's profile and keep it updated.<br /><br /><span><i>All information can be modified at a later stage.</i></span></div>
          <Button type="primary white" className="btn-primary" style={{width: 217}} onClick={props.onChangeStep}>
            Continue
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export default InvestorSignUpOnboarding
