import React, { useEffect } from 'react'
import { Layout, Spin, Space, message } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import AuthActions from '../Redux/AuthRedux'
import Images from '../Images'


function LockedPage(props) {
  const { result, fetching, location } = props;
  const {state} = location;

  const history = useHistory();

  useEffect(() => {
    if (result) {
      message.success("Check your mailbox", 3);
    }
  }, [result])

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <div className="sign-up-step with-bg">
          <div className="with-bg-top">
            <img src={Images.logoWhite} alt="" onClick={()=>history.push('/')} />
          </div>
          <div className="modal-container" style={{ width: 360, padding: '32px 48px' }}>
            <Space style={{ marginBottom: 24 }}>
              <img src={Images.phoneCancel} alt="" />
            </Space>
            <div className="locked-title">Your account is locked</div>
            <div className="locked-text">To unlock it please get in touch directly with IDN</div>
            <Space style={{ margin: '24px 0' }}>
              <span className="link-tag underline" onClick={()=>props.unlockUser(state)}>Get in touch</span>
            </Space>
          </div>
        </div>
      </Spin>
    </Layout>
  )
}


const mapStateToProps = (state) => {
  return {
    result: state.auth.result,
    fetching: state.auth.fetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  unlockUser: (userId) => dispatch(AuthActions.unlockRequest(userId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LockedPage)
