import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux'
import {Button, message} from 'antd'
import {useHistory} from 'react-router-dom'

import ExpertConsultActions from '../../../Redux/ExpertConsultRedux'
import DealDashboardActions from '../../../Redux/DealDashboardRedux'

import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

const data = {
  is_viewed: true,
};

function HoursResubmittedPopupPage(props) {
  const { dashboardInfo, expertConsultInfo, result, match, location } = props;
  const { dashboardId } = match.params;
  const { state } = location;

  const [download, setDownload] = useState(false);
  const [isError, setIsError] = useState(false);
  const [consultId, setConsultId] = useState(null);

	const history = useHistory()

	// const hours = dashboardInfo?.notifications.filter(el => el.add_info)[0]?.add_info?.hours
	// const file = dashboardInfo?.notifications.filter(el => el.add_info)[0]?.add_info?.file

  useEffect(() => {
    props.dealDashboardSyncToggle(false);
    return function cleanup() {
			(!state?.isHistory && state?.notification) && props.notificationViewedRequest(state?.notification, dashboardId, data);
      props.dealDashboardSyncToggle(true);
    }
  }, [0]);

  useEffect(() => {
		if (expertConsultInfo) {
			setConsultId(expertConsultInfo.id);
		}
	}, [expertConsultInfo]);

  useEffect(() => {
    if (result) {
      props.getExpertConsultInfo(dashboardId);
      history.push({ pathname: `/deal-dashboard/overview/${dashboardId}`})
    }
  }, [result])

  const approve = () => {
    if (state?.isHistory) {
      history.push(`/deal-dashboard/overview/${dashboardId}`);
			return;
    }
    if (download) {
      state?.notification && props.notificationViewedRequest(state?.notification, dashboardId, data);
      props.investorImproveHoursRequest(consultId, dashboardId);
    } else {
			setIsError(true);
      message.error("You should download the file", 3);
		}
	}

  const downloadFile = () => {
    setDownload(true);
    setIsError(false);
  }

  return (
    <PopupWrapper
      width={420}
      path={`/deal-dashboard/overview/${dashboardId}`}
      hasDropdown={true}
    >
      <div className="dialog-content" style={{ padding: 30, paddingTop: 20, paddingBottom: 30}}>
        <div className="dialog-title bold" style={{ margin: 6, textAlign: 'center', lineHeight: '32px' }}>Work done<br />re-submission</div>
        <div className="deal-content-wrapper">
          <div className="deal-text" style={{ marginTop: 14, fontSize: 13 }}>
          The Advisor has submitted the following <br/> days of work to be approved:
          </div>
          <br />
          <div style={{ display: 'flex', alignSelf: 'center' }}>
            <div className="hours">{state?.add_info?.days}</div>
            <span className="hours-text">Days</span>
          </div>
          <div className={isError ? 'download-wrapper active red' : 'download-wrapper active'}>
            <a
							href={state?.add_info?.file}
							target='_blank'
							rel='noreferrer'
							onClick={downloadFile}
						>
							<div className='download-text' style={{fontSize: 8}}>
								Download supporting document
							</div>
							<img src={Images.signOut} alt='' style={{transform: 'rotate(-90deg)'}} />
						</a>
          </div>

        </div>

        <div className="btn-footer" style={{marginTop: 12}}>
          <Button type="primary"
          className="btn-primary flat"
          style={{ width: 144, marginTop: 30, background: '@blue_light', border: '1px solid black' }}
          onClick={approve}
          >
            {state?.isHistory ? 'Go back' : 'Approve'}
          </Button>
        </div>
        <div className="note-text">
        <span style={{fontWeight: 700}}>Please note:</span> this re-submission replaces the previous work submitted
        </div>
      </div>
    </PopupWrapper>
  )
}

const mapStateToProps = state => {
	return {
		dashboardInfo: state.dealDashboard.dashboardInfo,
    expertConsultInfo: state.expertConsult.expertConsultInfo,
    result: state.expertConsult.result,
	}
}

const mapDispatchToProps = dispatch => ({
	investorImproveHoursRequest: (consultId, dashboardId) =>
  dispatch(ExpertConsultActions.investorImproveHoursRequest(consultId, dashboardId)),
  notificationViewedRequest: (notifId, dashboardId, data) => dispatch(ExpertConsultActions.notificationViewedRequest(notifId, dashboardId, data)),
  getExpertConsultInfo: dashboardId => dispatch(ExpertConsultActions.expertConsultingRequest(dashboardId)),
  dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart))
})

export default connect(mapStateToProps, mapDispatchToProps)(HoursResubmittedPopupPage)
