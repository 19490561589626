import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Input, Tabs, Button, message, Spin, InputNumber, Modal, Space, Row, Col } from 'antd'
import { useHistory } from 'react-router-dom'

import AuthActions from '../../Redux/AuthRedux'
import UsersActions from '../../Redux/UsersRedux'
import SettingsActions from '../../Redux/SettingsRedux'
import ExperienceActions from '../../Redux/ExperienceRedux'
import Images from '../../Images'
import errorHandler from '../../Utils/errorHandler'
import AdvisorDetailsProfileForm from '../../Components/Forms/AdvisorDetailsProfileForm'

const { TabPane } = Tabs;
const { TextArea } = Input;

const okButtonProps = {
  type:"primary",
  className:"btn-primary",
  style: {width: 172}
};

const deleteButtonProps = {
  type: "primary red",
  className: "btn-primary",
  style: { width: 172 }
};

const cancelButtonProps = {
  type: "primary white",
  className: "btn-primary",
  style: { width: 172 }
};

function AdvisorDetailsPage(props) {
  const { filters, result, error, inviteDataResult, list, admins, lockHistory, userResult, userFetching, userDeleteResult, userDeleteFetching, settingsFetching, settingsSaveFetching, authFetching, experienceFetching, experienceSaveFetching, experienceDeleteFetching, match } = props;
  const { advisorId, tab } = match.params;

  const [active, setActive] = useState(false);
  const [expData, setExpData] = useState(null);
  const [isLock, setIsLock] = useState(null);
  const [operation, setOperation] = useState(null);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [sendVisible, setSendVisible] = useState(false);
  const [inviteTitle, setInviteTitle] = useState(null);
  const [inviteBody, setInviteBody] = useState(null);
  const [activeKey, setActiveKey] = useState('profile');

  const history = useHistory();

  function handleNavigate(path) {
    history.push(path);
  }

  useEffect(() => {
    props.resetData();
    props.getFilters(advisorId, 'advisor');
    props.getUsers('admin');
    props.getExperience(advisorId);
    props.getHistory(advisorId);
  }, [0]);

  useEffect(() => {
    setActiveKey(tab ? tab : 'profile');
  }, [tab]);

  useEffect(() => {
    if (result && result.success) {
      message.success(result.success, 3);
      setSendVisible(false);
    }
  }, [result])

  useEffect(() => {
    if (userResult && userResult.success) {
      message.success(userResult.success, 3);
      if (userResult.success.includes('locked')) {
        setIsLock(!isLock);
      }
    }
  }, [userResult])

  useEffect(() => {
    if (userDeleteResult && userDeleteResult.success) {
      message.success(userDeleteResult.success, 3);
      setDeleteVisible(false);
    }
  }, [userDeleteResult])

  useEffect(() => {
    if (error) {
      errorHandler(error, props.resetError);
    }
  }, [error]);

  useEffect(() => {
    if (list) {
      let newData = {};
      Object.keys(list).forEach(key => {
        newData[key] = {
          value: list[key],
          active: false,
        }
      });
      newData.jobs = list.jobs;
      setExpData(newData);
    }
  }, [list]);

  useEffect(() => {
    if (filters) {
      setIsLock(filters.is_blocked);
      const operation = (filters.register_status_obj?.id !== 'registered') ?
        <Button type="primary" className="btn-primary" onClick={getUserInviteData}>Send invitation</Button>
        :
        null;
      setOperation(operation);
    }
  }, [filters]);

  useEffect(() => {
    if (inviteDataResult) {
      setInviteTitle(inviteDataResult.title);
      setInviteBody(inviteDataResult.body);
      setSendVisible(true);
    }
  }, [inviteDataResult])

  function updateField(field, value) {
    if (filters && filters[field] !== value) {
      props.updateFilters(advisorId, 'advisor', { [field]: value })
    }
  }

  function setFieldActive(field) {
    let newData = { ...expData };
    newData[field].active = true;
    setExpData(newData);
  }

  function setFieldInactive(field, e) {
    let newData = { ...expData };
    newData[field].active = false;
    newData[field].value = e.target.value;
    setExpData(newData);
    const value = field === 'day_rate_for_advisory_work' ? Number(e.target.value) : e.target.value;
    props.updateExperience(advisorId, { [field]: value })
  }

  function getUserInviteData() {
    if (filters) {
      props.getInviteData(filters.email, 'advisor');
    }
  }

  function sendUserInvite() {
    const data = {
      email: filters.email,
      title: inviteTitle,
      body: inviteBody
    }
    props.sendInvite(data, 'advisor');
  }

  function toggleBlock() {
    const action = isLock ? 'unlock' : 'lock';
    props.toggleUserLock(advisorId, action);
  }

  function sendPassword() {
    props.toggleUserLock(advisorId, 'send_password');
  }

  function deleteUser() {
    props.deleteUser('advisor', advisorId);
  }

  const onTabChange = key => {
    history.push(`/home/advisor-details/${advisorId}/${key}`);
    setActiveKey(key);
  }

  return (
    <div className="main-container">
      <Spin size="large" spinning={userFetching || settingsFetching || settingsSaveFetching || authFetching || experienceFetching || experienceSaveFetching || experienceDeleteFetching || userDeleteFetching}>
        <Tabs
          activeKey={activeKey}
          tabBarExtraContent={operation}
          style={{ marginTop: 32 }}
          onChange={onTabChange}
        >
          <TabPane tab="Profile" key="profile">
            <div className="investor-details">
              <div className="details-info">
                <img src={Images.edit} alt="" onClick={() => setActive(!active)} />
                {
                  filters &&
                  <AdvisorDetailsProfileForm
                    data={filters}
                    active={active}
                    admins={admins}
                    updateField={updateField}
                  />
                }
                <Space className="flex-center-horizontal" style={{ marginTop: 32 }}>
                  <Button type="primary red" className="btn-primary" onClick={() => setDeleteVisible(true)}>
                    Delete
                  </Button>
                </Space>
              </div>
            </div>
            {
              filters?.additional_information &&
                <div className="add-info-questions">
                  <Row gutter={[16, 24]}>
                    <Col span={18} className="flex-center-vertical">How many deals do you expect to originate in the next 12 months?</Col>
                    <Col span={6} className="flex-center-vertical">
                      <span className="add-info-value">{filters.additional_information.deals_expect_to_originate}</span>
                    </Col>
                  </Row>
                  <Row gutter={[16, 24]} style={{marginBottom: 0}}>
                    <Col span={18} className="flex-center-vertical">What is the average enterprise value of the deals that you expect to originate over the next 12 months?</Col>
                    <Col span={6} className="flex-center-vertical flex-wrap">
                      <span className="add-info-value">{filters.additional_information.average_enterprise_value_of_the_deals}</span>
                      <span className="enterprise-value">(million £)</span>
                    </Col>
                  </Row>
                </div>
            }
          </TabPane>
          <TabPane tab="Matching filters" key="filters">
            <div className="firm-profile-block">
              <div className="firm-filter-section">
                <div className="firm-filter-header flex-center-vertical">
                  <img src={Images.puzzle} alt="" />
                  <span>Sector Focus</span>
                  <img src={Images.edit} alt="" onClick={() => handleNavigate(`/home/advisor/filters-edit/${advisorId}`)} />
                </div>
                <div className="firm-filter-content" style={{ paddingLeft: 40 }}>
                  <div className="firm-filter-line flex-center-vertical">
                    <div className="firm-filter-left">
                      <span className="flex-center-vertical">Sectors</span>
                    </div>
                    <div className="firm-filter-right">
                      <span className="flex-center-vertical">Sub Sectors</span>
                    </div>
                  </div>
                  {
                    filters?.industry_focus_sub_sectors.map(sector => (
                      <Fragment key={sector.id}>
                        <div className="firm-filter-line flex-center-vertical">
                          <div className="firm-filter-left">
                            <div className="firm-filter-item-name flex-center-vertical">{sector.name}</div>
                          </div>
                          <div className="firm-filter-right">
                            <div className="firm-filter-item-value flex-center-vertical">
                              <span>{sector.children.map(el => el.name).join(', ')}</span>
                            </div>
                          </div>
                        </div>
                        {
                          sector.additional_specification &&
                          <div className="firm-filter-line flex-center-vertical">
                            <div className="firm-filter-left">
                              <div className="firm-filter-add-spec flex-center-vertical">Additional specifications:</div>
                            </div>
                            <div className="firm-filter-right">
                              <div className="firm-filter-add-spec-value flex-center-vertical">{sector.additional_specification}</div>
                            </div>
                          </div>
                        }
                      </Fragment>
                    ))
                  }
                </div>
              </div>

              <div className="firm-filter-section">
                <div className="firm-filter-header flex-center-vertical">
                  <img src={Images.globe} alt="" />
                  <span>Geographic Focus</span>
                  <img src={Images.edit} alt="" onClick={() => handleNavigate(`/home/advisor/filters-edit/${advisorId}`)} />
                </div>
                <div className="firm-filter-content" style={{ paddingLeft: 40 }}>
                  <div className="firm-filter-line flex-center-vertical">
                    <div className="firm-filter-left">
                      <span className="flex-center-vertical">Continents</span>
                    </div>
                    <div className="firm-filter-right">
                      <span className="flex-center-vertical">Country</span>
                    </div>
                  </div>
                  {
                    filters?.continents.map(continent => (
                      <div className="firm-filter-line flex-center-vertical" key={continent.id}>
                        <div className="firm-filter-left">
                          <div className="firm-filter-item-name flex-center-vertical">{continent.name}</div>
                        </div>
                        <div className="firm-filter-right">
                          <div className="firm-filter-item-value flex-center-vertical">
                            <span>{continent.children.map(el => el.name).join(', ')}</span>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Experience" key="experience">
            {
              expData &&
              <div className="experience-block">
                <div className="experience-left">
                  <div className="experience-title">
                    <span>Executive Summary</span>
                    <img src={Images.edit} alt="" onClick={() => setFieldActive('executive_summary')} />
                  </div>
                  <TextArea
                    defaultValue={expData.executive_summary.value}
                    autoSize={true}
                    maxLength={5000}
                    disabled={!expData.executive_summary.active}
                    onBlur={(e) => setFieldInactive('executive_summary', e)}
                  />
                  <div className="experience-title">
                    <span>Deal Origination experience</span>
                    <img src={Images.edit} alt="" onClick={() => setFieldActive('deal_origination_experience')} />
                  </div>
                  <TextArea
                    defaultValue={expData.deal_origination_experience.value}
                    autoSize={true}
                    maxLength={5000}
                    disabled={!expData.deal_origination_experience.active}
                    onBlur={(e) => setFieldInactive('deal_origination_experience', e)}
                  />
                  <div className="experience-title">
                    <span>Private Equity experience</span>
                    <img src={Images.edit} alt="" onClick={() => setFieldActive('private_equity_experience')} />
                  </div>
                  <TextArea
                    defaultValue={expData.private_equity_experience.value}
                    autoSize={true}
                    maxLength={5000}
                    disabled={!expData.private_equity_experience.active}
                    onBlur={(e) => setFieldInactive('private_equity_experience', e)}
                  />
                </div>
                <div className="experience-right">
                  <div className="day-rate">
                    <div className="experience-title">
                      <span>Day rate</span>
                      <img src={Images.edit} alt="" onClick={() => setFieldActive('day_rate_for_advisory_work')} />
                    </div>
                    <div className="flex-center-vertical">
                      <InputNumber
                        defaultValue={expData.day_rate_for_advisory_work.value}
                        min={0}
                        max={99999}
                        parser={value => Number(value).toFixed()}
                        disabled={!expData.day_rate_for_advisory_work.active}
                        onBlur={(e) => setFieldInactive('day_rate_for_advisory_work', e)}
                      />
                      <span className="rate-currency">[in £]</span>
                    </div>
                  </div>
                  <div className="not-active">This is your benchmark day rate (in Pounds) for any due diligence advisory work undertaken via the platform. The rate is customizable every time you enter into an engagement. Please remember that, per our Terms and Conditions, IDN will retain 20 percent of the total amount. As such, feel free to incorporate our mark up in your rate.</div>

                  <div className="experience-title">
                    <span style={{ margin: '0 auto' }}>Work experience</span>
                    {/* <img src={Images.edit} alt="" onClick={()=>setActive('work_experience')} /> */}
                  </div>
                  <div className="not-active">Please enter only your current or previous Board/C-level/Director level experiences, leaving aside any other roles you have covered in the past. Please start from the most recent role. It is pivotal to keep your work experiences up to date as investors will considered them when assigning advisory placement opportunities, as well as in the context of the deal matching process.</div>
                  <div className="experience-list">
                    <div className="list-header">
                      <div>Date</div>
                      <div>Position</div>
                      <div>Company</div>
                    </div>
                    {
                      expData.jobs && expData.jobs.map(job => (
                        <div className="list-item" key={job.id}>
                          <img className="remove-item" src={Images.close} alt="" onClick={() => props.deleteJob(advisorId, job.id)} />
                          <div className="no-wrap">{`${moment(job.start_date).format('MM/YYYY')} - ${job.end_date ? moment(job.end_date).format('MM/YYYY') : 'current'}`}</div>
                          <div>{job.position}</div>
                          <div>{job.company}</div>
                        </div>
                      ))
                    }
                  </div>
                  <div className="add-another-one" onClick={() => handleNavigate(`/home/advisor-details/${advisorId}/add-work`)}>
                    <img src={Images.addressBookSm} alt="" />
                    <span>Add another one</span>
                  </div>
                </div>
              </div>
            }
          </TabPane>
          <TabPane tab="Lock history" key="history">
            <div className="top-btn-section">
              <Button type="primary white" className="btn-primary" onClick={sendPassword}>
                Send password
              </Button>
              <Button type={`primary${isLock ? '' : ' orange'}`} className="btn-primary"  style={{ minWidth: 160 }} onClick={toggleBlock}>
                {isLock ? 'Unlock' : 'Lock'}
              </Button>
            </div>
            <div className="locked-list">
              <div className="locked-list-header">
                <div>Locked</div>
                <div>Unlocked</div>
              </div>
              <div className="locked-list-content">
                <div>
                  {
                    lockHistory && lockHistory?.locked.map(el => (
                      <div className="locked-list-item" key={el.id}>
                        <div className="locked-title">{el.initiator}</div>
                        <div className="locked-time">
                          <span>{moment(el.created).format('DD/MM/YYYY')}</span>
                          <span>{moment(el.created).format('hh:mm:ss a')}</span>
                        </div>
                      </div>
                    ))
                  }
                </div>
                <div className="unlocked">
                  {
                    lockHistory && lockHistory?.unlocked.map(el => (
                      <div className="locked-list-item" key={el.id}>
                        <div className="locked-title">{el.initiator}</div>
                        <div className="locked-time">
                          <span>{moment(el.created).format('DD/MM/YYYY')}</span>
                          <span>{moment(el.created).format('hh:mm:ss a')}</span>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>

        <Modal
          centered={true}
          closable={false}
          destroyOnClose={true}
          width={500}
          visible={deleteVisible}
          onOk={deleteUser}
          okText="Delete"
          okButtonProps={deleteButtonProps}
          onCancel={() => setDeleteVisible(false)}
          cancelButtonProps={cancelButtonProps}
        >
          <div className="delete-title">
            <span>Delete this user?</span>
          </div>
        </Modal>

        <Modal
          centered={true}
          closable={false}
          destroyOnClose={true}
          width={650}
          visible={sendVisible}
          onOk={sendUserInvite}
          okText="Send"
          okButtonProps={okButtonProps}
          onCancel={() => setSendVisible(false)}
          cancelButtonProps={cancelButtonProps}
        >
          <div className="modal-title">
            <span style={{borderBottom: 'none'}}>You are sending invitation</span>
            <br />
            <span>for <b>{`${filters?.name} ${filters?.surname}`}</b></span>
          </div>
          <div className="title-block">
            <Input
              value={inviteTitle}
              onChange={e=>setInviteTitle(e.target.value)}
            />
          </div>
          <div className="body-block">
            <TextArea
              rows={13}
              value={inviteBody}
              onChange={e=>setInviteBody(e.target.value)}
            />
          </div>
        </Modal>

      </Spin>
    </div >
  )
}

const mapStateToProps = (state) => {
  return {
    filters: state.settings.filters,
    settingsFetching: state.settings.fetching,
    settingsSaveFetching: state.settings.saveFetching,

    list: state.experience.list,
    experienceFetching: state.experience.fetching,
    experienceSaveFetching: state.experience.saveFetching,
    experienceDeleteFetching: state.experience.deleteFetching,

    result: state.auth.result,
    error: state.auth.error,
    inviteDataResult: state.auth.inviteDataResult,
    authFetching: state.auth.fetching,

    admins: state.users.list,
    lockHistory: state.users.lockHistory,
    userResult: state.users.result,
    userFetching: state.users.fetching,
    userDeleteResult: state.users.deleteResult,
    userDeleteFetching: state.users.deleteFetching,



  }
}

const mapDispatchToProps = (dispatch) => ({
  getFilters: (userId, role) => dispatch(SettingsActions.filtersRequest(userId, role)),
  updateFilters: (advisorId, role, data) => dispatch(SettingsActions.filtersSaveRequest(advisorId, role, data)),
  resetData: () => dispatch(SettingsActions.settingsReset()),

  getExperience: (advisorId) => dispatch(ExperienceActions.experienceRequest(advisorId)),
  updateExperience: (advisorId, data) => dispatch(ExperienceActions.experienceSaveRequest(advisorId, data)),
  getJobs: (advisorId) => dispatch(ExperienceActions.experienceJobsRequest(advisorId)),
  deleteJob: (advisorId, jobId) => dispatch(ExperienceActions.experienceJobDeleteRequest(advisorId, jobId)),

  getInviteData: (email, role) => dispatch(AuthActions.getInviteDataRequest(email, role)),
  sendInvite: (data, role) => dispatch(AuthActions.sendInviteRequest(data, role)),
  resetError: () => dispatch(AuthActions.authErrorReset()),

  getUsers: (role) => dispatch(UsersActions.usersRequest(role)),
  getHistory: (userId) => dispatch(UsersActions.userLockHistoryRequest(userId)),
  toggleUserLock: (userId, action) => dispatch(UsersActions.userToggleLockRequest(userId, action)),
  deleteUser: (role, id) => dispatch(UsersActions.userDeleteRequest(role, id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorDetailsPage)
