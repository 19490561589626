import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Tabs } from 'antd'

import FirmDealsTable from '../../Components/Tables/FirmDealsTable'
import FirmMandatesTable from '../../Components/Tables/FirmMandatesTable'
import DealSharesActions from '../../Redux/DealSharesRedux'
import MandatesActions from '../../Redux/MandatesRedux'
import DealDashboardActions from '../../Redux/DealDashboardRedux'

const { TabPane } = Tabs;

function FirmActivitiesPage(props) {
  const { dealShares, firmDealStats, mandates, firmActivitiesMandateStats, userId, match } = props;
  const { tab } = match.params;

  const [activeKey, setActiveKey] = useState('deals');

  const history = useHistory();

  useEffect(() => {
    props.resetDealDashboard();
    props.getFirmDeals(dealShares.page_fd, dealShares.page_size_fd);
    props.getFirmStats();
    props.getFirmMandates(mandates.page_fm, mandates.page_size_fm);
    props.getFirmActivitiesMandateStats();
  }, [0]);

  useEffect(() => {
    setActiveKey(tab ? tab : 'deals');
  }, [tab]);

  const onTabChange = key => {
    if (key === tab) return;
    history.push(`/home/firm's-activities/${key}`);
    setActiveKey(key);
  }

  const handleChangePage = (page, page_size) => {
    if (activeKey === 'deals') {
      props.getFirmDeals(page, page_size);
    } else if (activeKey === 'mandate') {
      props.getFirmMandates(page, page_size);
    }
  }

  return (
    <div className="main-container">
      <Tabs
        activeKey={activeKey}
        onChange={onTabChange}
        style={{ marginTop: 32 }}
      >
        <TabPane tab="Deals" key="deals">
          {
            dealShares?.firmDeals?.results?.length ?
              <>
                <div className="section-content stat" style={{ maxWidth: 'max-content', marginBottom: 16 }}>
                  <div className="section-count">
                    <span>Deals Received</span>
                    <div>{firmDealStats?.deals_received}</div>
                  </div>
                  <div className="section-count">
                    <span>Deals Applied</span>
                    <div>{firmDealStats?.deals_applied}</div>
                  </div>
                  <div className="section-count">
                    <span>Engagements</span>
                    <div>{firmDealStats?.engagements}</div>
                  </div>
                  <div className="section-count">
                    <span>Deals Closed</span>
                    <div>{firmDealStats?.deals_closed}</div>
                  </div>
                </div>
                <FirmDealsTable
                  data={dealShares.firmDeals}
                  page={dealShares.page_fd}
                  fetching={dealShares.fetching}
                  pageSize={dealShares.page_size_fd}
                  userId={userId}
                  onChangePage={handleChangePage}
                />
              </>
              :
              <div className="section-content info">You don’t have any active deal in your pipeline</div>
          }
        </TabPane>
        <TabPane tab="Mandate" key="mandate">
        {
          mandates?.firmMandates?.results?.length ?
          <>
            <div className="section-content stat" style={{ maxWidth: 'max-content', marginBottom: 16 }}>
              <div className="section-count">
                <span>Mandate Issued</span>
                <div>{firmActivitiesMandateStats?.mandate_issued}</div>
              </div>
              <div className="section-count">
                <span>Deals Received</span>
                <div>{firmActivitiesMandateStats?.deals_applied}</div>
              </div>
              <div className="section-count">
                <span>Engagements</span>
                <div>{firmActivitiesMandateStats?.engagements}</div>
              </div>
              <div className="section-count">
                <span>Closed Successfully</span>
                <div>{firmActivitiesMandateStats?.deals_closed}</div>
              </div>
            </div>
            <FirmMandatesTable
              data={mandates.firmMandates}
              page={mandates.page_fm}
              fetching={mandates.fetching}
              pageSize={mandates.page_size_fm}
              userId={userId}
              onChangePage={handleChangePage}
            />
          </>
          :
          <div className="section-content info">You don’t have any active mandate in your pipeline</div>
        }
        </TabPane>
        <TabPane tab="Consulting" key="consulting">
          <div className="section-content info" style={{width: '100%', maxWidth: 1125, justifyContent: 'center', margin: 'auto'}}>This service is coming soon</div>
        </TabPane>
      </Tabs>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userInfo.id,
    dealShares: state.dealShares,
    firmDealStats: state.dealShares.firmStats,
    mandates: state.mandates,
    firmActivitiesMandateStats: state.mandates.firmActivitiesMandateStats,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getFirmDeals: (page, page_size) => dispatch(DealSharesActions.firmDealsRequest(page, page_size)),
  getFirmStats: () => dispatch(DealSharesActions.firmStatsRequest()),

  getFirmMandates: (page, page_size) => dispatch(MandatesActions.firmMandatesRequest(page, page_size)),
  getFirmActivitiesMandateStats: () => dispatch(MandatesActions.firmActivitiesMandateStatsRequest()),

  resetDealDashboard: () => dispatch(DealDashboardActions.dealDashboardReset()),

})

export default connect(mapStateToProps, mapDispatchToProps)(FirmActivitiesPage)
