import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spin } from 'antd'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import Images from '../../../Images'
import '../PopupPage.less'

function IntroductionOccuredPopupPage(props) {
	const { result, fetching, match, location } = props;
	const { dashboardId } = match.params
	const { state } = location;

	const history = useHistory()

	useEffect(() => {
		props.dealDashboardSyncToggle(false);
		return function cleanup() {
			props.dealDashboardSyncToggle(true);
		}
	}, [0]);

	useEffect(() => {
		if (result) {
			props.getDealDashboardInfo(dashboardId);
			history.push(`/deal-dashboard/overview/${dashboardId}`);
		}
	}, [result]);

	const removeNotification = () => {
    if (state?.isHistory) {
      history.push(`/deal-dashboard/overview/${dashboardId}`);
    } else {
      props.changeNotificationStatus(state?.notification)
    }
  }

	return (
		<div className="popup-dialog">
			<div className="dialog-container" style={{ width: 420 }}>
				<Spin spinning={fetching} size="large">
					<div className='dialog-content' style={{ padding: 30 }}>
						<img src={Images.phoneOk} alt='' />
						<div className='dialog-title welcome' style={{ marginTop: 10 }}>Introduction Occurred</div>
						<div className='deal-text'>We have been notified by the Senior Advisor <br /> that the introduction to the Target company <br /> took place on the following date:</div>
						<div className='dialog-date'>
							{state?.add_info?.date ? state?.add_info?.date : ''}
						</div>
						<div className='deal-subtext' style={{ margin: 0 }}>
							Please provide confirmation by clicking the button below
        		</div>
						<div className='btn-footer'>
							<Button
								type='primary white'
								className='btn-primary welcome'
								style={{ width: 144 }}
								onClick={removeNotification}
							>
								{state?.isHistory ? 'Go back' : 'Confirm'}
							</Button>
						</div>
					</div>
				</Spin>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		result: state.dealDashboard.result,
		fetching: state.dealDashboard.fetching
	}
}

const mapDispatchToProps = (dispatch) => ({
	getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
	changeNotificationStatus: (notificationId) => dispatch(DealDashboardActions.notificationChangeStatusRequest(notificationId)),
	dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(IntroductionOccuredPopupPage)
