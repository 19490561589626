import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Input, InputNumber, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import ExperienceActions from '../../Redux/ExperienceRedux'
import Images from '../../Images'

const { TextArea } = Input;

function ExperiencePage(props) {
  const { list, fetching, saveFetching, deleteFetching, user } = props;

  const [data, setData] = useState(null);

  const history = useHistory();

  function handleNavigate(path) {
    history.push(path);
  }

  useEffect(() => {
    props.getExperience(user.id);
  }, [0]);

  useEffect(() => {
    if (list) {
      let newData = {};
      Object.keys(list).forEach(key => {
        newData[key] = {
          value: list[key],
          active: false,
        }
      });
      newData.jobs = list.jobs;
      setData(newData);
    }
  }, [list]);

  function setActive(field) {
    let newData = { ...data };
    newData[field].active = true;
    setData(newData);
  }

  function setInactive(field, e) {
    let newData = { ...data };
    newData[field].active = false;
    newData[field].value = e.target.value;
    setData(newData);
    const value = field === 'day_rate_for_advisory_work' ? Number(e.target.value) : e.target.value;
    props.updateExperience(user.id, { [field]: value })
  }

  return (
    <div className="main-container">
      <Spin size="large" spinning={fetching || saveFetching || deleteFetching}>
        <div className="section-title">Your experience</div>
        <div className="section-divider">&nbsp;</div>
        {
          data &&
          <div className="experience-block">
            <div className="experience-left">
              <div className="experience-title">
                <span>Experience - Summary</span>
                <img src={Images.edit} alt="" onClick={() => setActive('executive_summary')} style={{ alignSelf: 'start', marginTop: 5 }} />
              </div>
              <TextArea
                defaultValue={data.executive_summary.value}
                autoSize={true}
                maxLength={5000}
                disabled={!data.executive_summary.active}
                onBlur={(e) => setInactive('executive_summary', e)}
              />
              <div className="experience-title">
                <span>Experience - Deal origination</span>
                <img src={Images.edit} alt="" onClick={() => setActive('deal_origination_experience')} style={{ alignSelf: 'start', marginTop: 5 }} />
              </div>
              <TextArea
                defaultValue={data.deal_origination_experience.value}
                autoSize={true}
                maxLength={5000}
                disabled={!data.deal_origination_experience.active}
                onBlur={(e) => setInactive('deal_origination_experience', e)}
              />
              <div className="experience-title">
                <span>Experience - Working with Private Equity</span>
                <img src={Images.edit} alt="" onClick={() => setActive('private_equity_experience')} style={{ alignSelf: 'start', marginTop: 5}} />
              </div>
              <TextArea
                defaultValue={data.private_equity_experience.value}
                autoSize={true}
                maxLength={5000}
                disabled={!data.private_equity_experience.active}
                onBlur={(e) => setInactive('private_equity_experience', e)}
              />
            </div>
            <div className="experience-right">
              <div className="day-rate">
                <div className="experience-title">
                  <span>Day rate</span>
                  <img src={Images.edit} alt="" onClick={() => setActive('day_rate_for_advisory_work')} />
                </div>
                <div className="flex-center-vertical">
                  <InputNumber
                    defaultValue={data.day_rate_for_advisory_work.value}
                    min={0}
                    max={99999}
                    parser={value => Number(value).toFixed()}
                    disabled={!data.day_rate_for_advisory_work.active}
                    onBlur={(e) => setInactive('day_rate_for_advisory_work', e)}
                  />
                  <span className="rate-currency">[in £]</span>
                </div>
              </div>
              <div className="not-active">This is your benchmark day rate (in Pounds) for any due diligence advisory work undertaken via the platform. The rate is customizable every time you enter into an engagement. Please remember that, per our Terms and Conditions, IDN will retain 20 percent of the total amount. As such, feel free to incorporate our mark up in your rate.</div>

              <div className="experience-title">
                <span style={{ margin: '0 auto' }}>Work experience</span>
              </div>
              <div className="not-active">Please enter only your current or previous Board/C-level/Director level experiences, leaving aside any other roles you have covered in the past. Please start from the most recent role. It is pivotal to keep your work experiences up to date as investors will considered them when assigning advisory placement opportunities, as well as in the context of the deal matching process.</div>
              <div className="experience-list">
                <div className="list-header">
                  <div>Date</div>
                  <div>Position</div>
                  <div>Company</div>
                </div>
                {
                  data.jobs?.map(job => (
                    <div className="list-item" key={job.id}>
                      <img className="remove-item" src={Images.close} alt="" onClick={() => props.deleteJob(user.id, job.id)} />
                      <div className="no-wrap">{`${moment(job.start_date).format('MM/YYYY')} - ${job.end_date ? moment(job.end_date).format('MM/YYYY') : 'current'}`}</div>
                      <div>{job.position}</div>
                      <div>{job.company}</div>
                    </div>
                  ))
                }
              </div>
              <div className="add-another-one" onClick={() => handleNavigate('/home/experience/add-work')}>
                <img src={Images.addressBookSm} alt="" />
                <span>Add another one</span>
              </div>
            </div>
          </div>
        }
      </Spin>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    list: state.experience.list,
    fetching: state.experience.fetching,
    saveFetching: state.experience.saveFetching,
    deleteFetching: state.experience.deleteFetching,
    user: state.auth.userInfo
  }
}

const mapDispatchToProps = (dispatch) => ({
  getExperience: (advisorId) => dispatch(ExperienceActions.experienceRequest(advisorId)),
  updateExperience: (advisorId, data) => dispatch(ExperienceActions.experienceSaveRequest(advisorId, data)),
  getJobs: (advisorId) => dispatch(ExperienceActions.experienceJobsRequest(advisorId)),
  deleteJob: (advisorId, jobId) => dispatch(ExperienceActions.experienceJobDeleteRequest(advisorId, jobId)),

})

export default connect(mapStateToProps, mapDispatchToProps)(ExperiencePage)
