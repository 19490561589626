import React from 'react'
import { useHistory, Link } from 'react-router-dom'

import Images from '../Images'

function NotFoundPage(props) {
  const history = useHistory();

  return (
    <div className="sign-up-step without-bg">
      <img src={Images.logo} alt="" onClick={() => history.push('/')} />
      <div className="not-found">
        <div className="not-found-404">404</div>
        <div className="not-found-text">This page you were trying to reach at this address doesn't seem to exist. This is usually the result of a bad or outdated link. We apologize for any inconvenience.</div>
        <div className="not-found-link">You can try start from the main page <Link to="/">https://www.idn.ltd</Link></div>
      </div>
    </div>
  )
}

export default NotFoundPage
