import React from 'react'
import { Layout, Space, Button } from 'antd'
import { useHistory } from 'react-router-dom'

import Images from '../Images'

function InvestorVerificationPage(props) {
  const history = useHistory();

  return (
    <Layout>
      <div className="sign-up-step with-bg">
        <div className="with-bg-top">
          <img src={Images.logoWhite} alt="" onClick={()=>history.push('/')} />
        </div>
        <div className="modal-container" style={{ width: 650, padding: '56px 88px' }}>
          <Space style={{ marginBottom: 40 }}>
            <img src={Images.mailBox} alt="" />
          </Space>
          <div className="step-title verification">A verification link has been sent to your email address. Please click on the link to access your account</div>
          <div className="step-finish-divider" style={{ width: '45%' }}></div>
        </div>
      </div>
    </Layout>
  )
}

export default InvestorVerificationPage
