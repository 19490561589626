import React, { useState, useEffect } from 'react'
import { Collapse, Input, Radio, message } from 'antd'
import _ from 'lodash'

import CollapseHeader from '../CollapseHeader'
import Images from '../../Images'

const { Panel } = Collapse;
const { TextArea } = Input;

const initial = {
  active: false,
  relationship: '',
  relationship_note: '',
  role: '',
  role_note: '',
}

const radioStyle = {
  width: '300px',
  display: 'inline-flex',
  alignItems: 'flex-start'
};

function DealSharingStep3(props) {
  const {checkRequired, isAccordion, dealData} = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(initial);
  }, [0]);

  useEffect(() => {
    if (dealData && dealData.advisor_role) {
      const deal = _.cloneDeep(dealData);
      const newData = {...initial};
      newData.relationship = deal.advisor_role.relationship_obj?.id;
      newData.relationship_note = deal.advisor_role.relationship_note;
      newData.role = deal.advisor_role.role_obj?.id;
      newData.role_note = deal.advisor_role.role_note;
      setData(newData);
    }
  }, [dealData]);

  useEffect(() => {
    if (checkRequired) {
      checkRequiredFields();
    }
  }, [checkRequired]);

  const changeValue = (field, e) => {
    const newData = { ...data };
    newData[field] = e.target.value;
    setData(newData);
  }

  const checkRequiredFields = () => {
    const newData = { ...data };
    newData.active = true;
    setData(newData);
    if (Object.values(newData).every(el => el)) {
      delete newData.active;
      props.onChangeStep(newData, 'advisor_role');
    } else if (isAccordion) {
      message.error('All fields must be filled in to proceed', 3);
    } else {
      props.onChangeStep(null, 'advisor_role');
    }
  }

  return (
    (data && dealData) &&
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ?
            <img src={Images.angleUp} alt="" />
            :
            <img src={Images.angleDown} alt="" />
        }
        expandIconPosition="right"
        className="site-collapse-custom-collapse"
        accordion={isAccordion}
        defaultActiveKey={isAccordion ?  [] : ['relationship', 'role']}
      >
        <Panel
          header={<CollapseHeader text="Relationship with Target Company" imageType="certificate" />} key="relationship"
          className={`site-collapse-custom-panel${(data && data.active && (!data.relationship || !data.relationship_note)) ? ' error-block' : ''}`}
        >
          <div>
            <div className="collapse-content-title">Indicate what is your relation with the target company and the type of mandate you have (if any)</div>
            <div className="collapse-content-radio">
              <div className="radio-content-wrapper">
                <Radio.Group defaultValue={dealData?.advisor_role?.relationship_obj?.id} onChange={(e) => changeValue('relationship', e)}>
                  <Radio style={radioStyle} value="exclusive">
                    <div className="radio-group-content">
                      <span>Exclusive Mandate</span>
                      <span className="italic">The target company assigned you a formal exclusive mandate to find investors.</span>
                    </div>
                  </Radio>
                  <Radio style={radioStyle} value="non_exclusive" className="radio-margin">
                    <div className="radio-group-content">
                      <span>Non-Exclusive Mandate</span>
                      <span className="italic">The target company assigns you an informal mandate to find investors. The mandate might be verbal and is not given exclusively to you.</span>
                    </div>
                  </Radio>
                  <Radio style={radioStyle} value="not_specified" className="radio-margin">
                    <div className="radio-group-content">
                      <span>No Mandate</span>
                      <span className="italic">The target company is not currently on sale and you do not have any mandate. Yet, you have a tangible investment thesis and/or you believe you could favor a preemptive offer if you find the right investor. Please elaborate in detail.</span>
                    </div>
                  </Radio>
                </Radio.Group>
              </div>
              <div className="vertical-line">&nbsp;</div>
              {
                data && data.relationship &&
                <div style={{ width: '100%', alignSelf: 'center' }}>
                  <TextArea
                    placeholder="Please indicate what value you can add to the investors’ team through the negotiations and the due diligence"
                    defaultValue={data.relationship_note}
                    rows={7}
                    maxLength={3000}
                    onBlur={(e) => changeValue('relationship_note', e)}
                  />
                </div>
              }
            </div>
          </div>
        </Panel>

        <Panel
          header={<CollapseHeader text="Your Role" imageType="userEdit" />} key="role"
          className={`site-collapse-custom-panel${(data && data.active && (!data.role || !data.role_note)) ? ' error-block' : ''}`}
        >
          <div>
            <div className="collapse-content-title">Please indicate your preferred role in this deal</div>
            <div className="collapse-content-radio">
              <div className="radio-content-wrapper">
                <Radio.Group defaultValue={dealData?.advisor_role?.role_obj?.id} onChange={(e) => changeValue('role', e)}>
                  <Radio style={radioStyle} value="parties_only">
                    <div className="radio-group-content">
                      <span>Introduce the parties only</span>
                    </div>
                  </Radio>
                  <Radio style={radioStyle} value="parties_consulting" className="radio-margin">
                    <div className="radio-group-content">
                      <span>Introduce parties & available for consulting</span>
                    </div>
                  </Radio>
                </Radio.Group>
              </div>
              <div className="vertical-line">&nbsp;</div>
              {
                data && data.role &&
                <div style={{ width: '100%', alignSelf: 'center' }}>
                  <TextArea
                    placeholder="Please indicate what value you can add to the investors’ team through the negotiations and the due diligence"
                    defaultValue={data.role_note}
                    rows={7}
                    maxLength={3000}
                    onBlur={(e) => changeValue('role_note', e)}
                  />
                </div>
              }
            </div>
          </div>
        </Panel>

      </Collapse>
  )
}

export default DealSharingStep3
