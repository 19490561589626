import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  mandateSharesRequest: ['page', 'page_size', 'filter'],
  mandateSharesSuccess: ['list'],

  mandateShareMatchRequest: ['mandateShareId'],
  mandateShareMatchSuccess: ['matchResult'],

  mandateSharesDeleteRequest: ['mandateShareId'],
  mandateSharesDeleteSuccess: ['mandateSharesDeleteResult'],

  mandateSharesNotificationDeleteRequest: ['mandateShareId', 'data'],
  mandateSharesNotificationDeleteSuccess: ['mandateSharesNotificationResult'],

  setMandateSharesNotification: ['mandateSharesNotification'],

  mandateSharesSyncToggle: ['syncIsStart'],

  mandateSharesTableSettingsReset: null,

  mandateSharesReset: null,

  resultsReset: null,
  mandateSharesFetchingReset: null
})

export const MandateSharesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  fetching: false,

  matchResult: null,
  mandateSharesDeleteResult: null,
  mandateDealInvestorRejectResult: null,
  mandateSharesNotificationResult: null,

  page:1,
  page_size:20,

  filter: {
    advisor_group: ''
  },

  syncIsStart: false,
  mandateSharesNotification: false

})

/* ------------- Selectors ------------- */
export const MandateSharesSelectors = {
  getState: (state) => state.mandateShares
}

/* ------------- Reducers ------------- */

export const mandateSharesRequest = (state, { page, page_size, filter }) => {
  const newState = {
    fetching: true
  };
  page && (newState.page = page);
  page_size && (newState.page_size = page_size);
  filter && (newState.filter = state.filter.merge(filter));
  return state.merge(newState)
}

export const mandateSharesSuccess = (state, { list }) => {
  return state.merge({ fetching: false, list })
}

export const mandateShareMatchRequest = (state, { mandateShareId }) => {
  return state.merge({ fetching: true })
}

export const mandateShareMatchSuccess = (state, { matchResult }) => {
  return state.merge({ fetching: false, matchResult })
}

export const mandateSharesDeleteRequest = (state, { mandateShareId }) => {
  return state.merge({ fetching: true })
}

export const mandateSharesDeleteSuccess = (state, {mandateSharesDeleteResult}) => {
	return state.merge({ fetching: false, mandateSharesDeleteResult})
}

export const mandateSharesNotificationDeleteRequest = (state, { mandateShareId, data }) => {
  return state.merge({ fetching: true})
}

export const mandateSharesNotificationDeleteSuccess = (state, { mandateSharesNotificationResult }) => {
  return state.merge({ fetching: false, mandateSharesNotificationResult })
}

export const setMandateSharesNotification = (state, { mandateSharesNotification }) => {
  return state.merge({ mandateSharesNotification })
}

export const mandateSharesSyncToggle = (state, { syncIsStart }) => {
  return state.merge({ syncIsStart })
}

export const mandateSharesTableSettingsReset = (state) =>{
  const newState = {
    page:1,
    filter: {
      advisor_group: ''
    },
  };
  return state.merge(newState)
}

export const mandateSharesReset = (state) =>{
  return INITIAL_STATE
}

export const resultsReset = (state) => {
  return state.merge({
    matchResult: null,
    mandateSharesDeleteResult: null,
    mandateDealInvestorRejectResult: null,
    mandateSharesNotificationResult: null
  })
}

export const mandateSharesFetchingReset = (state) => {
  return state.merge({ fetching:false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.MANDATE_SHARES_REQUEST]: mandateSharesRequest,
  [Types.MANDATE_SHARES_SUCCESS]: mandateSharesSuccess,

  [Types.MANDATE_SHARE_MATCH_REQUEST]: mandateShareMatchRequest,
  [Types.MANDATE_SHARE_MATCH_SUCCESS]: mandateShareMatchSuccess,

  [Types.MANDATE_SHARES_DELETE_REQUEST]: mandateSharesDeleteRequest,
  [Types.MANDATE_SHARES_DELETE_SUCCESS]: mandateSharesDeleteSuccess,

  [Types.MANDATE_SHARES_NOTIFICATION_DELETE_REQUEST]: mandateSharesNotificationDeleteRequest,
  [Types.MANDATE_SHARES_NOTIFICATION_DELETE_SUCCESS]: mandateSharesNotificationDeleteSuccess,

  [Types.SET_MANDATE_SHARES_NOTIFICATION]: setMandateSharesNotification,

  [Types.MANDATE_SHARES_SYNC_TOGGLE]: mandateSharesSyncToggle,

  [Types.MANDATE_SHARES_TABLE_SETTINGS_RESET]: mandateSharesTableSettingsReset,

  [Types.MANDATE_SHARES_RESET]: mandateSharesReset,

  [Types.RESULTS_RESET]: resultsReset,

  [Types.MANDATE_SHARES_FETCHING_RESET]: mandateSharesFetchingReset,
})
