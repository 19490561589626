import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {Button} from 'antd'
import {useHistory} from 'react-router-dom'

import ExpertConsultActions from '../../../Redux/ExpertConsultRedux'
import DealDashboardActions from '../../../Redux/DealDashboardRedux'

import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function ExtendedAgreementReceivedPopupPage(props) {
  const {dashboardId} = props.match.params
  const {state} = props.location
  const {result} = props
	const history = useHistory()

	const data = {
		is_viewed: true,
	}

  useEffect(() => {
    if (result) {
      props.getDealDashboardInfo(dashboardId)
      history.push({pathname:`/deal-dashboard/${dashboardId}/agreement-review`, state: {ec: true}})
    }
	}, [result])

	const onContinue = () => {
    props.notificationViewedRequest(state?.notification, dashboardId, data)
	}

	return (
		<PopupWrapper width={420} path={`/deal-dashboard/overview/${dashboardId}`} hasDropdown={true}>
			<div className='dialog-content' style={{padding: 20, paddingTop: 30, paddingBottom: 30}}>
				<img src={Images.phoneOk} alt='' />
				<div className='dialog-title bold' style={{margin: 6, textAlign: 'center'}}>
					Extended <br /> Agreement Received
				</div>
				<div className='deal-text' style={{marginTop: 14}}>
					The Investor has shared with you a new <br /> executed Expert Consulting Agreement that <br /> will extend and replace the
					previous <br /> Agreement. <br />
					Please click continue to review and sign the <br /> new Agreement.
				</div>
				<div className='btn-footer' style={{marginTop: 30}}>
					<Button type='primary white' className='btn-primary flat' style={{width: 144}} onClick={onContinue}>
						Continue
					</Button>
				</div>
			</div>
		</PopupWrapper>
	)
}

const mapStateToProps = state => {
	return {
		dashboardInfo: state.dealDashboard.dashboardInfo,
    result: state.expertConsult.result,
	}
}

const mapDispatchToProps = dispatch => ({
	notificationViewedRequest: (notifId, dashboardId, data) =>
		dispatch(ExpertConsultActions.notificationViewedRequest(notifId, dashboardId, data)),
	getDealDashboardInfo: dashboardId => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ExtendedAgreementReceivedPopupPage)
