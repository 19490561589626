import React, { useState, useEffect } from 'react'
import { List, Button, Space, Checkbox, Spin } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import InviteColleaguesToDealDashboardForm from '../../../Components/Forms/InviteColleaguesToDealDashboardForm'
import PopupWrapper from '../PopupWrapper'
import UsersActions from '../../../Redux/UsersRedux'
import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import Images from '../../../Images'
import '../PopupPage.less'

function InviteColleaguesToDealDashboardPopupPage(props) {
  const { colleagues, result, fetching, match } = props;
  const { dashboardId } = match.params;

  const [data, setData] = useState(null);
  const [users, setUsers] = useState([]);
  const [active, setActive] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setData({
      existent: [],
      new: []
    });
    props.getColleagues(true);
    props.dealDashboardSyncToggle(false);
    return function cleanup() {
      props.dealDashboardSyncToggle(true);
    }
  }, [0]);

  useEffect(() => {
    if (colleagues) {
      setUsers(colleagues.map(el => ({ id: el.id, name: el.name })));
    }
  }, [colleagues]);

  useEffect(() => {
    if (result) {
      history.push(`/deal-dashboard/overview/${dashboardId}`);
    }
  }, [result]);

  const handleSubmit = formData => {
    const newData = { ...data };
    newData.new = formData;
    props.inviteColleaguesToDashboard(dashboardId, newData);
  }

  const changeView = () => {
    setActive(!active);
  }

  const selectUser = (value, checked) => {
    const newData = { ...data };
    if (checked) {
      newData.existent.push({
        id: value,
        is_comain: true
      });
    } else {
      const index = newData.existent.findIndex(el => el.id === value);
      newData.existent.splice(index, 1);
    }
    setData(newData);
  }

  const sendInvite = () => {
    if (active) {
      return;
    } else if (data.existent.length) {
      props.inviteColleaguesToDashboard(dashboardId, data);
    }
  }

  return (
    <PopupWrapper
      width={420}
      path={`/deal-dashboard/overview/${dashboardId}`}
    >
      <Spin spinning={fetching} size="large">
        <div className="dialog-content dynamic" style={{ padding: '24px 48px' }}>
          <img src={Images.phoneOk} alt="" />
          <div className="dialog-title deal">Invite colleague(s)</div>
          <List
            itemLayout="horizontal"
            style={{ marginBottom: 24 }}
            dataSource={users}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  avatar={<img src={Images.user} alt="" />}
                  title={<span className="list-text">{item.name}</span>}
                />
                <div className="checkbox-wrap">
                  <Checkbox onChange={(e) => selectUser(item.id, e.target.checked)} />
                </div>
              </List.Item>
            )}
          />
          {
            active ?
              <InviteColleaguesToDealDashboardForm
                onSubmit={handleSubmit}
                changeView={changeView}
              />
              :
              <Space style={{ margin: '24px 0' }}>
                <span className="link-tag underline" onClick={changeView}>Invite new Colleagues</span>
              </Space>
          }
          <div className="btn-footer" style={{ marginTop: 0 }}>
            <Button type="primary white" htmlType="submit" className="btn-primary welcome" form="share-deal-with-colleagues-form" style={{ width: 144 }} onClick={sendInvite}>
              Send Invite
          </Button>
          </div>
        </div>
      </Spin>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    colleagues: state.users.investorColleagues,
    result: state.dealDashboard.result,
		fetching: state.dealDashboard.fetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getColleagues: (filter) => dispatch(UsersActions.investorColleaguesRequest(filter)),
  inviteColleaguesToDashboard: (dashboardId, data) => dispatch(DealDashboardActions.inviteDashboardRequest(dashboardId, data)),
  dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InviteColleaguesToDealDashboardPopupPage)
