import React, {useState} from 'react'
import { Form, Input, Button, Row, Col, DatePicker, Space, Checkbox } from 'antd'
import { DownOutlined } from '@ant-design/icons'

function AddWorkPopupForm(props) {
  const [current, setCurrent] = useState([]);

  const [form] = Form.useForm();

  const onFinish = values => {
    const data = { ...values };

    data.works.forEach((work, i) => {
      if (work.start_date) {
        work.start_date = work.start_date.format('YYYY-MM-DD');
      }
      if (work.end_date) {
        work.end_date = work.end_date.format('YYYY-MM-DD');
      } else {
        data.works[i].is_current = true;
      }
    })

    props.onSubmit(data.works);
  }

  const changeCurrent = (index, value) => {
    if (value) {
      const values = form.getFieldsValue();
      values.works[index]['end_date'] = null;
      form.setFieldsValue(values);
    }
    const newData = [...current];
    newData[index] = value;
    setCurrent(newData);
  }

  function onChange(date, index) {
    if (date) {
      changeCurrent(index, false);
    }
  }

  return (
    <Form onFinish={onFinish} form={form} id="add-work-form" className="login-form" layout="vertical" requiredMark={false}>
      <Form.List name="works">
        {(fields, { add, remove }) => {
          if (!fields.length) {
            add();
          }
          return (
            <>
              {fields.map(field => (
                <div key={field.key} className="form-wrapper">
                  <svg width="16" height="16" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => remove(field.name)}>
                    <path d="M1.66663 1.66667L11 11M11 11L20.3333 20.3333M11 11L20.3333 1.66667M11 11L1.66663 20.3333" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <Form.Item
                    {...field}
                    name={[field.name, 'position']}
                    fieldKey={[field.fieldKey, 'position']}
                    rules={[
                      { required: true, message: 'This field is required' },
                    ]}
                  >
                    <Input
                      placeholder="Position"
                      maxLength={100}
                    />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, 'company']}
                    fieldKey={[field.fieldKey, 'company']}
                    rules={[
                      { required: true, message: 'This field is required' },
                    ]}
                  >
                    <Input
                      placeholder="Company"
                      maxLength={100}
                    />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, 'website']}
                    fieldKey={[field.fieldKey, 'website']}
                  >
                    <Input
                      placeholder="Company website (homepage)"
                      maxLength={100}
                    />
                  </Form.Item>

                  <Row gutter={48}>
                    <Col span={12}>
                      <Form.Item
                        {...field}
                        label="Start Date"
                        name={[field.name, 'start_date']}
                        fieldKey={[field.fieldKey, 'start_date']}
                        rules={[{required: true, message: 'This field is required'}]}
                      >
                        <DatePicker
                          placeholder="mm/yyyy"
                          format="MM/YYYY"
                          suffixIcon={<DownOutlined />}
                          picker="month"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        {...field}
                        label="End Date"
                        name={[field.name, 'end_date']}
                        fieldKey={[field.fieldKey, 'end_date']}
                        rules={[{required: !current[field.key], message: 'This field is required'}]}
                      >
                        <DatePicker
                          className={current[field.key] ? 'ph-dark' : ''}
                          placeholder={current[field.key] ? 'Current' : 'mm/yyyy'}
                          format="MM/YYYY"
                          renderExtraFooter={() =>
                            <Checkbox
                              className="date-checkbox"
                              checked={current[field.key]}
                              onChange={e=>changeCurrent(field.key, e.target.checked)}
                            >
                              Current
                            </Checkbox>
                          }
                          onChange={(date)=>onChange(date, field.key)}
                          suffixIcon={<DownOutlined />}
                          picker="month"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}
              <Form.Item>
                <Space style={{display: 'flex', justifyContent: 'center', marginTop: 16}}>
                  <span className="add-position" onClick={() => fields.length < 15 && add()}>Add another position</span>
                </Space>
              </Form.Item>
            </>
          )
        }}
      </Form.List>

      <Button type="primary" htmlType="submit" className="btn-primary" style={{ width: 217, marginTop: 24 }}>
        Save
      </Button>
    </Form>
  );
}

export default AddWorkPopupForm
