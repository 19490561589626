import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Tabs, Input, Button, Spin, Modal } from 'antd'
import moment from 'moment'
import _ from 'lodash'

import DealsActions from '../../Redux/DealsRedux'
import DealSharesActions from '../../Redux/DealSharesRedux'
import DealDashboardActions from '../../Redux/DealDashboardRedux'
import SettingsActions from '../../Redux/SettingsRedux'
import MandatesActions from '../../Redux/MandatesRedux'

import DealSharingReview from '../../Components/DealSharing/DealSharingReview'
import MandateDealSharingReview from '../../Components/MandateDealSharing/MandateDealSharingReview'
import ShareInvestorsFilterPanel from '../../Components/FilterPanels/ShareInvestorsFilterPanel'
import ShareInvestorsTable from '../../Components/Tables/ShareInvestorsTable'
import DealMatchesAdminTable from '../../Components/Tables/DealMatchesAdminTable'
import Images from '../../Images'

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Search } = Input;

const styleObj = {
  maxWidth: 'auto',
  // minWidth: '1392px',
  width: '100%',
};

const okButtonProps = {
  type: "primary",
  className: "btn-primary",
  style: { width: 172 }
};

const cancelButtonProps = {
  type: "primary white",
  className: "btn-primary",
  style: { width: 172 }
};

const modalProps = {
  centered: true,
  closable: false,
  destroyOnClose: true,
  width: 560
};

const initial = {
  equity_governace: [],
  investment_type: [],
  enterprise_value_from: null,
  enterprise_value_to: null,
  equity_investment_from: null,
  equity_investment_to: null,
  equity_stake_from: null,
  equity_stake_to: null,
  sectors: [],
  geography: [],
  comment: '',
  status: ''
};

const targetQuestions = [
  {
    field: 'opportunity_related',
    text: 'Is any of the information relating to the opportunity you are sharing of a confidential nature?'
  },
  {
    field: 'public_linked',
    text: 'Is the opportunity you are sharing linked to a public listed company?'
  },
  {
    field: 'inside_info',
    text: 'You acknowledge that the existence of discussions between you and any potential investors and the terms of those discussions may (in whole or in part) constitute “inside information” for the purposes of Part V of the Criminal Justice Act 1993 (“CJA”) and/or the Market Abuse Regulations (EU 596/2014) which has effect (as amended) in English law by virtue of the European Union (Withdrawal) Act 2018 (“UK MAR”)”, and that any parties who are in, or acquire, possession of confidential information may have information as an “insider” for the purposes of the CJA and inside information for the purposes of UK MAR'
  },
  {
    field: 'obligations_aware',
    text: 'Are you aware of your obligations under all applicable law and regulations relating to unpublished, price-sensitive information and are you compliant with all applicable laws and regulations of any relevant jurisdiction?'
  },
  {
    field: 'under_obligations',
    text: 'Are you under any confidentiality obligations in respect of the confidential information, pursuant to a non-disclosure agreement or otherwise?'
  },
  {
    field: 'share_conf_info',
    text: 'Notwithstanding such confidentiality obligations that you are subject to, are you permitted and/or have you been specifically authorised by the relevant company to share the confidential information relating to the opportunity to third parties via the IDN platform?'
  },
];

function DealDetailsAdminPage(props) {
  const { deal, deals, dealTargetStatus, dealShareWithInvestorsResult, dealReshareResult, adminShareMandateDealResult, dealShares, sectorFocus, geographyFocus, equityGovernanceList, investmentTypes, match, role } = props;
  const { dealId, tab } = match.params;

  const [start, setStart] = useState(false);
  const [dealData, setDealData] = useState(null);
  const [shareData, setShareData] = useState({
    ...initial,
    deal: dealId,
    page: deals.filter_is.page,
    page_size: deals.filter_is.page_size,
    search: deals.filter_is.search
  });
  const [active, setActive] = useState(false);
  const [count, setCount] = useState(null);
  const [initialFilters, setInitialFilters] = useState(null);
  const [investorList, setInvestorList] = useState(null);
  const [isClosed, setIsClosed] = useState(null);
  const [isReshare, setIsReshare] = useState(null);
  const [isResharing, setIsResharing] = useState(null);
  const [adminStatus, setAdminStatus] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [targetStatus, setTargetStatus] = useState(null);
  const [activeKey, setActiveKey] = useState('info');
  const [operation, setOperation] = useState(null);
  const [fromMandate, setFromMandate] = useState(null);

  const history = useHistory();

  const generateExtra = (status, adminStatus, mandate) =>
    <div className="tab-extra-content">
      {
        ((!mandate && status === 'waiting') || (mandate && status === 'shared')) &&
        <Button type="primary white"
          className="btn-primary"
          onClick={() => history.push(mandate ?
              `/home/deal-details/${dealId}/${activeKey}/withdraw-deal`
            :
              `/home/deal-details/${dealId}/${activeKey}/withdraw`
            )}
        >
          Withdraw Deal
        </Button>
      }
      {
        (!mandate || (mandate && status === 'shared')) &&
        <Button
          type="primary"
          className="btn-primary"
          style={{ width: 120, marginLeft: 16 }}
          // disabled={!mandate && status !== 'waiting'}
          disabled={!mandate && status !== 'waiting' && adminStatus !== 'resharing'}
          onClick={() =>
            mandate ?
              history.push({
                pathname: `/home/mandate-deal-sharing-edit/${dealId}`,
                state: {
                  backPath: `/home/deal-details/${dealId}`
                }
              })
              :
              history.push(`/home/deal-edit/${dealId}`)
          }
        >
          Edit
        </Button>
      }
      {
        (mandate && adminStatus === 'not_shared') &&
        <Button type="primary white"
          className="btn-primary"
          style={{ width: 'auto', marginLeft: 16 }}
          onClick={() => props.shareMandateDealByAdmin(dealId)}
        >
          Share
        </Button>
      }
      {(mandate && (status === 'rejected' || status === 'withdrawn')) &&
        <Button type="primary"
          className="btn-primary"
          style={{ marginLeft: 'auto' }}
          onClick={reshareMandateDeal}
        >
          Re-Share
        </Button>
      }
    </div>

  useEffect(() => {
    props.resetDealTargetStatus();
    props.getDeal(dealId);
    props.getSectors();
    props.getContinents({ regions: true });
    props.getEquityGovernance();
    props.getInvestmentTypes();
    props.getDealShares(dealShares.page, dealShares.page_size, dealShares.sort_field, dealShares.sort_order, { deal: dealId });
    props.getInvestorShareStatuses();
    props.getDealTargetStatus(dealId);
  }, [0]);

  useEffect(() => {
    setActiveKey(tab ? tab : 'info');
  }, [tab]);

  useEffect(() => {
    if (deal) {
      setDealData(deal);
      setIsClosed(deal.status_obj.id === 'closed' || deal.status_obj.id === 'won' || deal.admin_status_obj.id === 'closed');
      setIsReshare(deal.status_obj.id === 'closed_reshare');
      setIsResharing(deal.status_obj.id === 'resharing');
      setAdminStatus(deal.admin_status_obj.id);
      setFromMandate(!!deal.mandate);
      if (!start) {
        let newData;
        if (deals.filter_is.deal === dealId) {
          newData = deals.filter_is;
          setShareData(newData);
          setInitialFilters(_.omit(newData, ['page', 'page_size', 'search', 'deal']));
        } else {
          newData = {};
          const dealCopy = _.cloneDeep(deal);
          newData.equity_governace = dealCopy.investment_focus?.governace_of_the_targeted_equity?.map(el => el.id) || [];
          newData.investment_type = dealCopy.investment_focus?.investment_type?.map(el => String(el.id)) || [];
          newData.enterprise_value_from = dealCopy.investment_focus?.enterprise_value_from;
          newData.enterprise_value_to = dealCopy.investment_focus?.enterprise_value_to;
          newData.equity_investment_from = dealCopy.investment_focus?.equity_investment_from;
          newData.equity_investment_to = dealCopy.investment_focus?.equity_investment_to;
          newData.equity_stake_from = dealCopy.investment_focus?.equity_stake_from;
          newData.equity_stake_to = dealCopy.investment_focus?.equity_stake_to;
          newData.sectors = dealCopy.industry_focus_sub_sectors?.map(el =>
            el.children.map(child => child.id).flat()
          ).flat();
          newData.geography = dealCopy.geography?.map(el =>
            el.regions.map(child => child.countries).flat()
          ).flat().map(item => item.id);
          newData.comment = dealCopy.investment_focus?.comment;
          setShareData({ ...shareData, ...newData, page: 1 });
          setInitialFilters(newData);
        }
        setStart(true);
      }
      setOperation(generateExtra(deal.status_obj.id, deal.admin_status_obj.id, !!deal.mandate));
    }
  }, [deal]);

  useEffect(() => {
    if (shareData && start) {
      props.getDealInvestorsShared(shareData);
    }
  }, [shareData, start]);

  useEffect(() => {
    if (dealShareWithInvestorsResult) {
      props.getDeal(dealId);
      props.getDealInvestorsShared(shareData);
      props.getDealShares(1, dealShares.page_size, dealShares.sort_field, dealShares.sort_order, { deal: dealId });
    }
  }, [dealShareWithInvestorsResult]);

  useEffect(() => {
    if (dealReshareResult) {
      setModalVisible(false);
      props.getDeal(dealId);
    }
  }, [dealReshareResult]);

  useEffect(() => {
    if (adminShareMandateDealResult) {
      props.getDeal(dealId);
    }
  }, [adminShareMandateDealResult]);

  useEffect(() => {
    if (dealTargetStatus) {
      setTargetStatus(dealTargetStatus);
    }
  }, [dealTargetStatus]);

  function handleSearch(val) {
    if (deals.filter_is.search === val) {
      return;
    }
    setShareData({ ...shareData, page: 1, search: val });
  }

  function changeCount(value, data, isStart) {
    setCount(value);
    if (isStart) return;
    if (data == null) {
      setShareData({
        ...initial,
        deal: dealId,
        page: 1,
        page_size: deals.filter_is.page_size
      });
    } else {
      setShareData({ ...shareData, ...data, page: 1 });
    }
  }

  function handleChangePage(page, page_size) {
    setShareData({ ...shareData, page, page_size });
  }

  function handleChangePage_(page, page_size, sort_field, sort_order) {
    props.getDealShares(page, page_size, sort_field, sort_order, { deal: dealId });
  }

  const shareDeal = () => {
    if (investorList && investorList.length) {
      props.shareDealWithInvestors(dealId, { investors: investorList });
    }
  }

  const reshareMandateDeal = () => {
    history.push({pathname: `/home/deal-details/${dealId}/reshare-mandate-deal`, state: {reshareByAdmin: true}});
  }

  function closeFilter() {
    setActive(!active);
  }

  function generateText(field) {
    if (field === 'inside_info') {
      return 'Confirm';
    } else {
      return targetStatus[field] ? 'Yes' : 'No';
    }
  }

  const onTabChange = key => {
    history.push(`/home/deal-details/${dealId}/${key}`);
    setActiveKey(key);
  }

  return (
    <div className="main-container">
      <Tabs
        className="deal-deatils-tab"
        style={{ marginTop: 32 }}
        activeKey={activeKey}
        tabBarExtraContent={activeKey === 'info' && operation}
        onChange={onTabChange}
      >
        <TabPane tab="Deal information" key="info">
          <Spin spinning={deals.fetching} size="large">
            {
              dealData && !fromMandate &&
              <>
                <div className="deal-top-info">
                  <div className="deal-top-info-item">
                    <span>Deal code</span>
                    <div>{dealData.id}</div>
                  </div>
                  <div className="deal-top-info-item">
                    <span>Advisor name</span>
                    <div>{dealData.owner_obj?.name}</div>
                  </div>
                  <div className="deal-top-info-item">
                    <span>Shared on</span>
                    <div>{dealData.shared && moment(dealData.shared).format('DD/MM/YYYY')}</div>
                  </div>
                </div>
                {
                  dealData.withdraw_reason &&
                  <div className="review-block">
                    <div className="review-title">{`Deal Withdrawn ${dealData.closed ? moment(dealData.closed).format('DD/MM/YYYY') : ''}`}</div>
                    <div className="overview-title">
                      <span>&nbsp;</span>
                      <div>Reason</div>
                    </div>
                    <div className="area-text">
                      <TextArea
                        defaultValue={dealData.withdraw_reason}
                        autoSize={true}
                        disabled={true}
                      />
                    </div>
                  </div>
                }
                <DealSharingReview
                  styleObj={styleObj}
                  dealData={dealData}
                  role={role}
                />
                {
                  targetStatus &&
                  <div className="review-block" style={styleObj}>
                    <div className="review-title" style={{ marginBottom: 24 }}>Advisor screening questions</div>
                    <div className="overview-text question-text">
                      {
                        targetQuestions.filter(el => targetStatus[el.field] != null).map(el => (
                          <div key={el.field} className="sector-add-spec-value question-value target">
                            <div>
                              <span>{el.text}</span>
                              {
                                (el.field === 'under_obligations' && targetStatus.comment) &&
                                <TextArea
                                  style={{ marginTop: 12 }}
                                  rows={4}
                                  value={targetStatus.comment}
                                  disabled={true}
                                />
                              }
                            </div>
                            <span className={!targetStatus[el.field] && 'red'}>{generateText(el.field)}</span>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                }
              </>
            }
            {
              dealData && fromMandate &&
              <>
                {
                  dealData.mandate &&
                  <>
                    <div className="deal-top-info">
                      <div className="deal-top-info-item">
                        <span>Mandate code</span>
                        <div>
                          <Link to={`/home/mandate-details/${dealData.mandate.id}`}>{dealData.mandate.id}</Link>
                        </div>
                      </div>
                      <div className="deal-top-info-item">
                        <span>Investment firm</span>
                        <div>{dealData.mandate.invest_firm}</div>
                      </div>
                      <div className="deal-top-info-item">
                        <span>Investor(s)</span>
                        <div>{dealData.mandate.investors?.map(el => el.name).join(', ')}</div>
                      </div>
                    </div>

                    {
                      dealData.withdraw_reason &&
                      <div className="review-block">
                        <div className="review-title">{`Deal Withdrawn ${dealData.closed ? moment(dealData.closed).format('DD/MM/YYYY') : ''}`}</div>
                        <div className="overview-title">
                          <span>&nbsp;</span>
                          <div>Reason</div>
                        </div>
                        <div className="area-text">
                          <TextArea
                            defaultValue={dealData.withdraw_reason}
                            autoSize={true}
                            disabled={true}
                          />
                        </div>
                      </div>
                    }

                    <MandateDealSharingReview
                      dealData={dealData}
                      investmentTypes={investmentTypes}
                      role={role}
                    />
                  </>
                }
              </>
            }
          </Spin>
        </TabPane>
        {
          (dealData && !dealData.mandate) &&
          <>
            <TabPane tab="Share with investors" key="share">
              <Spin spinning={deals.fetching} size="large">
                <div className="action-panel">
                  <Search
                    placeholder="Search"
                    allowClear
                    className="content-search"
                    suffix={<img src={Images.search} alt="" />}
                    defaultValue={deals.filter_is.search}
                    onSearch={handleSearch}
                  />
                  <Button type={`primary${count ? ' orange' : ' white'}`} className="btn-primary" onClick={closeFilter}>
                    {'Filter' + (count ? ` (${count})` : '')}
                  </Button>
                  {
                    !isClosed ?
                      isReshare ?
                        null
                        // <Button type="primary white"
                        //   className="btn-primary"
                        //   style={{ width: 'auto', marginLeft: 'auto' }}
                        //   onClick={() => setModalVisible(true)}
                        // >
                        //   Enable re-sharing
                        // </Button>
                        :
                        <Button type="primary"
                          className="btn-primary"
                          style={{ marginLeft: 'auto' }}
                          // disabled={isResharing || adminStatus === 'engaged' || adminStatus === 'withdrawn'}
                          disabled={adminStatus === 'engaged' || adminStatus === 'withdrawn'}
                          onClick={shareDeal}
                        >
                          Share
                        </Button>
                      :
                      null
                  }
                  {
                    initialFilters &&
                    <ShareInvestorsFilterPanel
                      active={active}
                      sectors={sectorFocus}
                      continents={geographyFocus}
                      governance={equityGovernanceList}
                      investmentTypes={investmentTypes}
                      choices={deals.investorShareStatuses}
                      initialFilters={initialFilters}
                      changeCount={changeCount}
                      closeFilter={closeFilter}
                    />
                  }
                </div>
                <div>
                  {
                    initialFilters &&
                    <ShareInvestorsTable
                      data={deals.investorSharedList}
                      isReshare={isReshare}
                      adminStatus={adminStatus}
                      page={deals.filter_is.page}
                      fetching={deals.fetching}
                      pageSize={deals.filter_is.page_size}
                      onChangePage={handleChangePage}
                      setInvestors={setInvestorList}
                    />
                  }
                </div>
              </Spin>
            </TabPane>
            <TabPane tab="Matches" key="matches">
              <DealMatchesAdminTable
                data={dealShares.list}
                page={dealShares.page}
                fetching={dealShares.fetching}
                pageSize={dealShares.page_size}
                onChangePage={handleChangePage_}
                resetDealDashboard={props.resetDealDashboard}
              />
            </TabPane>
          </>
        }
      </Tabs>
      <Modal
        {...modalProps}
        visible={modalVisible}
        onOk={() => props.reshareDealByAdmin(dealId)}
        okText="Confirm"
        okButtonProps={okButtonProps}
        onCancel={() => setModalVisible(false)}
        cancelButtonProps={cancelButtonProps}
      >
        <div className="delete-title">
          <span>Are you sure you want to re-activate this deal and enable re-sharing?</span>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    deals: state.deals,
    deal: state.deals.deal,
    dealTargetStatus: state.deals.dealTargetStatus,
    dealShareWithInvestorsResult: state.deals.dealShareWithInvestorsResult,
    dealReshareResult: state.deals.dealReshareResult,
    adminShareMandateDealResult: state.deals.adminShareMandateDealResult,
    dealShares: state.dealShares,
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    equityGovernanceList: state.settings.equityGovernanceList,
    investmentTypes: state.settings.investmentTypes,
    role: state.auth.role,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDealInvestorsShared: (data) => dispatch(DealsActions.dealInvestorsSharedRequest(data)),
  getDeal: (id) => dispatch(DealsActions.dealRequest(id)),
  shareDealWithInvestors: (id, data) => dispatch(DealsActions.dealShareWithInvestorsRequest(id, data)),
  getInvestorShareStatuses: () => dispatch(DealsActions.dealInvestorShareStatusesRequest()),
  reshareDealByAdmin: (id) => dispatch(DealsActions.dealReshareByAdminRequest(id)),
  getDealTargetStatus: (dealId) => dispatch(DealsActions.dealTargetStatusRequest(dealId)),
  resetDealTargetStatus: () => dispatch(DealsActions.dealTargetStatusReset()),
  shareMandateDealByAdmin: (dealId) => dispatch(DealsActions.adminShareMandateDealRequest(dealId)),

  getDealShares: (page, page_size, sort_field, sort_order, filter) => dispatch(DealSharesActions.dealSharesRequest(page, page_size, sort_field, sort_order, filter)),

  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: (filter) => dispatch(SettingsActions.geographyFocusRequest(filter)),
  getEquityGovernance: () => dispatch(SettingsActions.equityGovernanceRequest()),
  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),

  resetDealDashboard: () => dispatch(DealDashboardActions.dealDashboardReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DealDetailsAdminPage)
