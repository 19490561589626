import React, { useState, useEffect, useRef } from 'react'
import { Checkbox } from 'antd'
import { useResizeDetector } from 'react-resize-detector'

function SectorGrid(props) {
  const { data, group, sectorName, setParent, activeItems } = props;

  const [count, setCount] = useState(null);
  const [rows, setRows] = useState(null);
  const [result, setResult] = useState([]);
  const [colWidth, setColWidth] = useState('');
  const [choices, setChoices] = useState(null);

  const targetRef = useRef();
  const { width } = useResizeDetector({ targetRef });

  // useEffect(() => {
  //   if (width) {
  //     if (width > 1599) {
  //       setCount(4);
  //     } else if (width < 1200) {
  //       setCount(2);
  //     } else {
  //       setCount(3);
  //     }
  //   }
  // }, [width]);

  useEffect(() => {
    if (activeItems) {
      setChoices(activeItems);
    }
  }, [activeItems]);

  useEffect(() => {
    if (data) {
      setCount(data.length);
    }
  }, [data]);

  useEffect(() => {
    if (count) {
      let col = (100 / count).toFixed(1);
      if ((col * count) > 100) {
        col -= 0.1;
      }
      setColWidth(col + '%');
      const newData = [];

      data.forEach(el => {
        const arr = [];
        arr.push({
          label: el.name,
          value: el.id,
          parent: true
        });
        if (el.children) {
          el.children.forEach(child => {
            arr.push({
              label: child.name,
              value: child.id
            });
          })
        }
        newData.push(arr);
      });
      setResult(newData);
    }
  }, [count]);

  const onChange = (checked, sectorName, groupId) => {
    if (sectorName) {
      setParent(checked, sectorName, groupId);
    }
  }

  return (
    <div className="grid-container">
      {/* <div className="resize-target" ref={targetRef}></div> */}
      {
        data && result && result.map((res, i) => (
          <div key={i} style={{ width: colWidth }}>
            {
              res.map(el => (
                <div key={el.value} style={{ padding: 8 }}>
                  {
                    el.parent ?
                      <div className="checkbox-header">{el.label}</div>
                    :
                      <Checkbox
                        value={el.value}
                        disabled={(group != null && res[0]?.value !== group) || (choices && !choices.includes(el.value))}
                        onChange={(e)=>onChange(e.target.checked, sectorName, res[0]?.value)}
                      >
                        <span style={{
                          color: ((group != null && res[0]?.value !== group) || (choices && !choices.includes(el.value)) ?
                            'gray'
                          :
                            '#232D53'
                          )}}>
                          {el.label}
                        </span>
                      </Checkbox>
                  }
                </div>
              ))
            }
          </div>
        ))
      }
    </div>
  )
}

export default SectorGrid
