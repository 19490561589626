import React, { useState, useEffect } from 'react'
import { Button, Tabs, Spin } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

import DealsActions from '../../Redux/DealsRedux'
import DealSharesActions from '../../Redux/DealSharesRedux'

import DealSharingReview from '../../Components/DealSharing/DealSharingReview'
import DealMatchesTable from '../../Components/Tables/DealMatchesTable'
import Images from '../../Images'

const { TabPane } = Tabs;

const closedStatuses = ['closed', 'closed_reshare', 'won', 'withdrawn'];

function DealInfoPage(props) {
  const { deal, fetching, dealSharesNotificationResult, dealShares, match, location, notifications, role} = props;
  const { dealId, tab } = match.params;
  const { state } = location;
  const [dealData, setDealData] = useState(null);
  const [shareId, setShareId] = useState(null);
  const [activeKey, setActiveKey] = useState('matches');
  const [backPath, setBackPath] = useState('');

  const history = useHistory();

  const reshareButton =
    <Button
      type="primary"
      className="btn-primary"
      onClick={() => history.push({
        pathname: `/home/deal/${dealId}/summary/reshare-deal`,
        state: { withdrawn: true }
      })}
    >
      Re-share
    </Button>

  useEffect(() => {
    state?.tab ?
      setBackPath(`/home/deals/${state.tab}`)
    :
      setBackPath(`/home/deals`)
    props.getDealShares(1, dealShares.page_size, dealShares.sort_field, dealShares.sort_order, { deal: dealId });
  }, [0]);

  useEffect(() => {
    setActiveKey(tab ? tab : 'matches');
  }, [tab]);

  useEffect(() => {
    if (deal) {
      setDealData(deal);
    }
  }, [deal]);

  useEffect(() => {
    if (dealData) {
      if (dealData.notifications?.length) {
        showNotification(dealData.notifications[0]);
      }
    }
  }, [dealData]);

  useEffect(() => {
    if (dealData && notifications?.deals) {
      if (dealData.notifications?.length) {
        showNotification(dealData.notifications[0]);
      }
    }
  }, [notifications]);

  useEffect(() => {
    if (dealId) {
      props.getDeal(dealId);
    }
  }, [dealId]);

  useEffect(() => {
    if (dealSharesNotificationResult) {
      const item = dealShares.list.results.find(el => el.id === shareId);
      const dashboardId = item.dashboard;
      if (dashboardId) {
        history.push({
          pathname: `/deal-dashboard/overview/${dashboardId}`,
          state: {backPath: `/home/deal/${dealId}/matches`}
        });
      } else {
        history.push(`/home/deal-investor-firm/${shareId}`);
      }
    }
  }, [dealSharesNotificationResult]);

  function showNotification(notification) {
    if (notification != null) {
      const type = notification?.name_obj?.id;
      const state = {
				id: notification.id,
			};
      let path;
      switch (type) {
        case 0: {
          path = {
            pathname: `/home/deal/${dealId}/${activeKey}/deal-rejected`,
            state
          };
          break;
        }
        default:
          break;
      }
      if (path) {
				history.push(path);
			}
    }
  };

  const handleChangePage = (page, page_size) => {
    props.getDealShares(page, page_size, dealShares.sort_field, dealShares.sort_order, { deal: dealId });
  }

  const deleteNotification = (id) => {
    setShareId(id);
    props.deleteDealSharesNotification(id, {advisor_notification: false});
  }

  const onTabChange = key => {
    history.push(`/home/deal/${dealId}/${key}`);
    setActiveKey(key);
  }

  return (
    <div className="sign-up-step filters-edit wo-padding">
      <div className="absolute-header">
        <div className="flex-center-vertical">
          <div className="logo-block">
            <img src={Images.logoWhite} alt="" />
          </div>
          <div className="title-block bottom">{dealId}</div>
        </div>
      </div>
        <div className="return-block">
          <Link to={backPath}>
            <img src={Images.arrowLeft} alt="" />
            <span>Return to Dashboard</span>
          </Link>
        </div>
        <Tabs
          activeKey={activeKey}
          tabBarExtraContent={(dealData?.status_obj?.id === 'withdrawn' && activeKey === 'summary') && reshareButton}
          onChange={onTabChange}
        >
          <TabPane tab="Matches" key="matches" style={{width: '100%'}}>
            {
              dealShares?.list?.results?.length ?
                <DealMatchesTable
                  data={dealShares.list}
                  page={dealShares.page}
                  fetching={dealShares.fetching}
                  pageSize={dealShares.page_size}
                  onChangePage={handleChangePage}
                  deleteNotification={deleteNotification}
                />
                :
                <div className="section-content">Your deal has not been matched by any investor yet</div>
            }
          </TabPane>
          <TabPane tab="Deal Summary" key="summary">
            <div className="tab-btn-block">
              {
                closedStatuses.includes(dealData?.status_obj?.id) ?
                    null
                  :
                    <>
                      <Button type="primary white"
                        className="btn-primary"
                        onClick={() => history.push(`/home/deal/${dealId}/${activeKey}/withdraw`)}
                      >
                        Withdraw Deal
                      </Button>
                      <Button type="primary"
                        className="btn-primary"
                        disabled={dealData?.status_obj?.id === 'matched'}
                        onClick={() => history.push(`/home/deal-edit/${dealId}`)}
                      >
                        Edit
                      </Button>
                    </>
              }
            </div>
            <DealSharingReview
              dealData={dealData}
              role={role}
            />
          </TabPane>
        </Tabs>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    role: state.auth.role,
    deal: state.deals.deal,
    fetching: state.deals.fetching,
    dealShares: state.dealShares,
    dealSharesNotificationResult: state.dealShares.dealSharesNotificationResult,
    ddNotifications: state.notifications.ddNotifications,
    notifications: state.notifications.notifications,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDeal: (id) => dispatch(DealsActions.dealRequest(id)),

  getDealShares: (page, page_size, sort_field, sort_order, filter) => dispatch(DealSharesActions.dealSharesRequest(page, page_size, sort_field, sort_order, filter)),
  deleteDealSharesNotification: (shareId, data) => dispatch(DealSharesActions.dealSharesNotificationDeleteRequest(shareId, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DealInfoPage)
