import React, { useState, useEffect } from 'react'
import { Collapse, Tabs, Checkbox, Row, Col, Input, InputNumber, message, Radio } from 'antd'
import _ from 'lodash'

import Utils from '../../Utils/utils'
import CountryGrid from '../CountryGrid'
import SectorGrid from '../SectorGrid'
import CollapseHeader from '../CollapseHeader'
import Images from '../../Images'

const { Panel } = Collapse;
const { TabPane } = Tabs;
const { TextArea } = Input;

const investment = {
  enterprise_value: [null, null],
  equity_ticket: [null, null],
  equity_stake: [null, null],
  evNaActive: false,
  etNaActive: false,
  esNaActive: false,
  investment_type: [],
  comment: '',
  comments: '',
  governance_other: '',
  governance: [],
  // add_info: '',
}

function MandateDealSharingStart(props) {
  const { dealData, sectorFocus, geographyFocus, investmentTypes, checkRequired, isAccordion, mandateId, 
    setContinents, setRegions, setCountries, subsectors, profileSummary, equityGovernanceList } = props;

  const [start, setStart] = useState(false);

  const [sectorCheckedList, setSectorCheckedList] = useState(null);
  const [sectorActiveTab, setSectorActiveTab] = useState(null);
  const [sectorOptions, setSectorOptions] = useState(null);
  const [group, setGroup] = useState(null);

  const [continentCheckedList, setContinentCheckedList] = useState(null);
  const [continentActiveTab, setContinentActiveTab] = useState(null);
  const [continentOptions, setContinentOptions] = useState(null);
  const [regionActiveTab, setRegionActiveTab] = useState(null);
  const [regionCheckAll, setRegionCheckAll] = useState(null);

  const [otherSectorText, setOtherSectorText] = useState(null);
  const [otherSectorValue, setOtherSectorValue] = useState(null);
  const [otherSectorList, setOtherSectorList] = useState(null);

  const [investmentSector, setInvestmentSector] = useState(null);
  const [investmentTypeOptions, setInvestmentTypeOptions] = useState(null);

  const [governanceOptions, setGovernanceOptions] = useState(null);
  const [governanceActive, setGovernanceActive] = useState(false);

  const [targetInformation, setTargetInformation] = useState({
    // deal_overview: ' ',
    // target_overview: null,
    // target_add_info: null,
    // target_openness_to_sell: null,
    target_name: null,
  });
  const [targetInformationUrl, setTargetInformationUrl] = useState('');
  const [yourProfileData, setYourProfileData] = useState({
    advisor_role_mn: null,
    advisor_sector_experience: null,
    executive_summary: null,
  });

  const [required, setRequired] = useState({
    active: false,
    investment_focus: false,
    sector_focus: false,
    geographic_focus: false,
    target_information: false,
    your_profile: false,
  })

  const [filterData, setFilterData] = useState(null);

  useEffect(() => {
    setInvestmentSector(_.cloneDeep(investment));
  }, [0]);

  useEffect(() => {
    if (checkRequired) {
      checkRequiredFields();
    }
  }, [checkRequired]);

  useEffect(() => {
    if (profileSummary) {
      setYourProfileData({
        ...yourProfileData,
        executive_summary: profileSummary
      });
    }
  }, [profileSummary]);

  useEffect(() => {
    if (dealData && dealData !== true) {
      const deal = _.cloneDeep(dealData);
      setFilterData(deal);
      setYourProfileData({
        advisor_role_mn: dealData.advisor_role_mn,
        advisor_sector_experience: dealData.advisor_sector_experience,
        executive_summary: dealData.executive_summary,
      })
      setTargetInformationUrl(dealData.target_information?.target_information_url);
      setTargetInformation({
        // deal_overview: dealData.target_information?.deal_overview,
        // target_overview: dealData.target_information?.target_overview,
        // target_add_info: dealData.target_information?.target_add_info,
        // target_openness_to_sell: dealData.target_information?.target_openness_to_sell,
        target_name: dealData.target_information?.target_name,
      })

    }
  }, [dealData]);

  useEffect(() => {
    if (filterData) {
      const investment = {
        enterprise_value: [filterData.investment_focus?.enterprise_value_from, filterData.investment_focus?.enterprise_value_to],
        equity_ticket: [filterData.investment_focus?.equity_ticket_from, filterData.investment_focus?.equity_ticket_to],
        equity_stake: [filterData.investment_focus?.equity_stake_from, filterData.investment_focus?.equity_stake_to],
        investment_type: filterData.investment_focus?.investment_type ? filterData.investment_focus?.investment_type.map(el => el.id) : [],
        comment: filterData.investment_focus?.comment,
        comments: filterData.comments,
        governance: filterData.investment_focus?.governace_of_the_targeted_equity ? filterData.investment_focus?.governace_of_the_targeted_equity.map(el => el.id) : [],
        governance_other: filterData.investment_focus?.specified_governace,
        evNaActive: false,
        etNaActive: false,
        esNaActive: false,
      };
      if (filterData.investment_focus?.enterprise_value_from == null) {
        investment.enterprise_value = [];
        investment.evNaActive = true;
      }
      if (filterData.investment_focus?.equity_ticket_from == null) {
        investment.equity_ticket = [];
        investment.etNaActive = true;
      }
      if (filterData.investment_focus?.equity_stake_from == null) {
        investment.equity_stake = [];
        investment.esNaActive = true;
      }
      setInvestmentSector(investment);
      setGovernanceActive(!!investment.governance_other);
    }
  }, [filterData]);

  useEffect(() => {
    if (sectorFocus) {
      let sectorsListInit = {};
      let otherSectorsInit = {};
      let options = {};
      sectorFocus.forEach(sector => {
        sectorsListInit[sector.name] = [];
        otherSectorsInit[sector.name] = {
          active: false,
          text: '',
          parent: sector.id
        };
        options[sector.name] = sector.children;
      });
      if (filterData) {
        filterData.industry_focus_sub_sectors.forEach(el => {
          sectorsListInit[el.name] = el.children.map(child => child.id);
          otherSectorsInit[el.name].text = el.additional_specification;
        });
        setOtherSectorList(otherSectorsInit);
        setSectorCheckedList(sectorsListInit);
        setSectorActiveTab(filterData.industry_focus_sub_sectors[0]?.name);
      } else {
        setOtherSectorList(otherSectorsInit);
        setSectorCheckedList(sectorsListInit);
        if (subsectors) {
          setSectorActiveTab(subsectors.name);
        } else {
          setSectorActiveTab(sectorFocus[0]?.name);
        }
      }
      setSectorOptions(options);
    }
  }, [sectorFocus, filterData]);

  useEffect(() => {
    if (sectorActiveTab) {
      setOtherSectorValue(null);
      let text = sectorFocus.find(sector => sector.name === sectorActiveTab).other_sector;
      setOtherSectorText(text);
    }
  }, [sectorActiveTab]);

  useEffect(() => {
    if (geographyFocus) {
      let continentsListInit = {};
      let regionsInit = {};
      let regionsCountInit = {};
      let options = {};
      geographyFocus.forEach(continent => {
        continentsListInit[continent.name] = [];
        options[continent.name] = [];
        for (let i = 0; i < continent.children.length; i++) {
          continentsListInit[continent.name].push(
            {
              region: continent.children[i].name,
              countries: [],
            }
          )
          options[continent.name].push(
            { [continent.children[i].name]: [] },
          );
          regionsInit[continent.children[i].name] = {
            id: continent.children[i].id,
            value: false
          };
          regionsCountInit[continent.children[i].name] = continent.children[i].children.length;
          ;
          for (let j = 0; j < continent.children[i].children.length; j++) {
            options[continent.name][i][continent.children[i].name].push(
              { label: continent.children[i].children[j].name, value: continent.children[i].children[j].id },
            );
          }
        }
      });
      if (filterData) {
        filterData.geography.forEach(el => {
          el.regions.forEach(region => {
            const index = continentsListInit[el.name].findIndex(item => item.region === region.name);
            continentsListInit[el.name][index].countries = region.countries.map(country => country.id);

            regionsInit[region.name]['value'] = true;
          })
        });
        setContinentCheckedList(continentsListInit);
        setRegionCheckAll(regionsInit);
      } else {
        setContinentCheckedList(continentsListInit);
        setRegionCheckAll(regionsInit);
      }
      setContinentOptions(options);
      setContinentActiveTab(geographyFocus[0]?.name);
    }
  }, [geographyFocus, filterData]);

  useEffect(() => {
    if (equityGovernanceList) {
      const options = [];
      for (let i = 0; i < equityGovernanceList.length; i++) {
        options.push(
          { label: equityGovernanceList[i].name, value: equityGovernanceList[i].id },
        );
      }
      setGovernanceOptions(options);
    }
  }, [equityGovernanceList]);

  useEffect(() => {
    if (investmentTypes) {
      const options = [];
      for (let i = 0; i < investmentTypes.length; i++) {
        options.push(
          { label: investmentTypes[i].name, value: investmentTypes[i].id },
        );
      }
      setInvestmentTypeOptions(options);
    }
  }, [investmentTypes]);

  useEffect(() => {
    if (sectorCheckedList && regionCheckAll && investmentSector && otherSectorList && yourProfileData && targetInformation) {
      if (filterData && !start) {
        const continentId = filterData?.industry_focus_sub_sectors[0]?.id;
        const subsectors = filterData?.industry_focus_sub_sectors[0]?.children?.map(el => el.id);
        let groupId;
        sectorFocus.find(el => el.id === continentId).children.forEach(el => {
          if (el.children.some(item => item.id === subsectors[0])) {
            groupId = el.id;
          }
        })
        setGroup(groupId);
        setStart(true);
      }
      let checkSectors = Object.values(sectorCheckedList).some(el => el.length);
      let checkOtherSectorsList = Object.values(otherSectorList).filter(el => el.active).every(el => el.text);
      let checkContinents = Object.values(regionCheckAll).some(el => el.value);
      let checkInvestment =
        (investmentSector.evNaActive || Utils.checkEmptyValue(investmentSector.enterprise_value)) &&
        (investmentSector.etNaActive || Utils.checkEmptyValue(investmentSector.equity_ticket)) &&
        (investmentSector.esNaActive || (Utils.checkEmptyValue(investmentSector.equity_stake) && investmentSector.equity_stake.every(el => el > 0 && el < 101))) 
        && investmentSector.investment_type.length > 0 && investmentSector.governance.length > 0;
      let checkTargetInformation = Object.values(targetInformation).every(el => el != null);
      let checkYourProfile = Object.values(yourProfileData).every(el => el != null);
      if (investmentSector.investment_type.includes(3) && !investmentSector.comment) {
        checkInvestment = false;
      }

      const newData = {
        investment_focus: checkInvestment,
        sector_focus: checkSectors && checkOtherSectorsList,
        geographic_focus: checkContinents,
        target_information: checkTargetInformation,
        your_profile: checkYourProfile,
      };
      setRequired({ ...required, ...newData });
    }
  }, [sectorCheckedList, regionCheckAll, investmentSector, otherSectorList, targetInformation, yourProfileData]);

  const onChange = (list, isSector) => {
    if (isSector) {
      const newData = { ...sectorCheckedList };
      newData[sectorActiveTab] = list;
      setSectorCheckedList(newData);
    } else {
      const data = { ...continentCheckedList };
      const checkAllList = { ...regionCheckAll };

      const index = data[continentActiveTab].findIndex(el => el.region === regionActiveTab);
      data[continentActiveTab][index].countries = list;
      checkAllList[regionActiveTab]['value'] = checkAllList[regionActiveTab] || !!list.length;

      setContinentCheckedList(data);
      setRegionCheckAll(checkAllList);
    }
  };

  const onCheckAllChange = (region, e) => {
    const data = { ...continentCheckedList };
    const checkAllList = { ...regionCheckAll };

    checkAllList[region]['value'] = e.target.checked;
    const index = data[continentActiveTab].findIndex(el => el.region === region);

    if (!e.target.checked) {
      data[continentActiveTab][index].countries = [];
      setContinentCheckedList(data);
    }
    setRegionCheckAll(checkAllList);
  }

  const changeOtherSector = e => {
    const newData = { ...otherSectorList };
    newData[sectorActiveTab].active = e.target.checked;
    setOtherSectorList(newData);
  }

  const changeSpecValue = e => {
    const newData = { ...otherSectorList };
    newData[sectorActiveTab].text = e.target.value;
    setOtherSectorList(newData);
  }

  const changeInvestmentRange = (e, position, field) => {
    if (e.target.value !== '') {
      const newData = {...investmentSector};
      newData[field][position] = Number(e.target.value);
      if (typeof newData[field][1] === 'number' && newData[field][0] > newData[field][1]) {
        newData[field][0] = newData[field][1];
      }
      setInvestmentSector(newData);
    }
  }

  const changeNaActive = (e, field) => {
    const newData = { ...investmentSector };
    newData[field] = e.target.checked;
    setInvestmentSector(newData);
  }

  const changeInvestmentType = value => {
    const newData = { ...investmentSector };
    newData.investment_type = [value];
    setInvestmentSector(newData);
  }

  const changeComment = value => {
    const newData = { ...investmentSector };
    newData.comment = value;
    setInvestmentSector(newData);
  }

  const changeComments = value => {
    const newData = { ...investmentSector };
    newData.comments = value;
    setInvestmentSector(newData);
  }

  const changeInvestmentOtherInput = e => {
    const newData = { ...investmentSector };
    newData.governance_other = e.target.value;
    setInvestmentSector(newData);
  }

  const changeInvestmentCheck = list => {
    const newData = { ...investmentSector };
    newData.governance = list;
    setInvestmentSector(newData);
  }

  const generateTabTitle = region => {
    return (
      <div className="tab-checkbox-block">
        <Checkbox
          checked={regionCheckAll[region]['value']}
          disabled={!setRegions?.includes(region)}
          onChange={(e) => onCheckAllChange(region, e)}
        >
        </Checkbox>
        <span style={{ color: setRegions?.includes(region) ? '#232D53' : 'gray' }}>{region}</span>
      </div>
    )
  }

  const checkRequiredFields = () => {
    let checkSectors = Object.values(sectorCheckedList).some(el => el.length);
    let checkOtherSectorsList = Object.values(otherSectorList).filter(el => el.active).every(el => el.text);
    let checkContinents = Object.values(regionCheckAll).some(el => el.value);
    let checkInvestment =
        (investmentSector.evNaActive || Utils.checkEmptyValue(investmentSector.enterprise_value)) &&
        (investmentSector.etNaActive || Utils.checkEmptyValue(investmentSector.equity_ticket)) &&
        (investmentSector.esNaActive || (Utils.checkEmptyValue(investmentSector.equity_stake) && investmentSector.equity_stake.every(el => el > 0 && el < 101))) &&
        investmentSector.investment_type.length > 0 && investmentSector.governance.length > 0;
    if (investmentSector.investment_type.includes(3) && !investmentSector.comment) {
      checkInvestment = false;
    }
    let checkTargetInformation = Object.values(targetInformation)?.every(el => !!el );
    // let checkYourProfile = Object.values(yourProfileData)?.every(el => el !== null && el !== "");
    const newData = {
      active: true,
      investment_focus: checkInvestment,
      sector_focus: checkSectors && checkOtherSectorsList,
      geographic_focus: checkContinents,
      target_information: checkTargetInformation,
      // your_profile: checkYourProfile,
    };
    setRequired({ ...required, ...newData });
    if (checkSectors && checkContinents && checkInvestment && checkOtherSectorsList && checkTargetInformation) {
      const totalData = {
        industry_focus_sub_sectors: Object.values(sectorCheckedList).flat(),
        countries: Object.values(continentCheckedList).flat().map(el => el.countries).flat(),
        regions: Object.values(regionCheckAll).filter(el => el.value).map(el => el.id),
        additional_specification: Object.entries(otherSectorList).filter(el => sectorCheckedList[el[0]].length).map(el => el[1]).filter(el => el.text).map(el => ({
          name: el.text,
          industry_focus_sector: el.parent
        })),
        investment_focus: {
          enterprise_value_from: investmentSector.evNaActive ? null : investmentSector.enterprise_value[0],
          enterprise_value_to: investmentSector.evNaActive ? null : investmentSector.enterprise_value[1],
          equity_ticket_from: investmentSector.etNaActive ? null : investmentSector.equity_ticket[0],
          equity_ticket_to: investmentSector.etNaActive ? null : investmentSector.equity_ticket[1],
          equity_stake_from: investmentSector.esNaActive ? null : investmentSector.equity_stake[0],
          equity_stake_to: investmentSector.esNaActive ? null : investmentSector.equity_stake[1],
          governace_of_the_targeted_equity: investmentSector.governance,
          specified_governace: governanceActive ? investmentSector.governance_other : null,
          investment_type: investmentSector.investment_type,
          comment: investmentSector.investment_type.includes(3) ? investmentSector.comment : null
        },
        target_information: { ...targetInformation, target_information_url: targetInformationUrl },
        // advisor_role_mn: yourProfileData.advisor_role_mn,
        // advisor_sector_experience: yourProfileData.advisor_sector_experience,
        // executive_summary: yourProfileData.executive_summary,
        comment: investmentSector.comments,
      };
      if (mandateId) {
        totalData.mandate_id = mandateId;
      }
      props.onChangeStep(totalData, 'matching_criteria')
    } else if (isAccordion) {
      message.error('All fields must be filled in to proceed', 3);
    }
    else {
      // props.onChangeStep(null);
      message.error('All fields must be filled in to proceed', 3);
    }
  }

  const setParent = (checked, sectorName, parentId) => {
    const newData = { ...sectorCheckedList };
    if (checked) {
      if (newData[sectorName].length) {
        return;
      } else {
        setGroup(parentId);
      }
    } else {
      if (newData[sectorName].length === 1) {
        setGroup(null);
      } else {
        return;
      }
    }
  }

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) =>
        isActive ?
          <img src={Images.angleUp} alt="" />
          :
          <img src={Images.angleDown} alt="" />
      }
      expandIconPosition="right"
      className="site-collapse-custom-collapse"
      accordion={isAccordion}
      defaultActiveKey={isAccordion ? [] : ['sector', 'geography', 'investment', 'target_information', 'your_profile']}
    >
      <Panel
        header={<CollapseHeader text="Sector" imageType="puzzle" />} key="sector"
        className={`site-collapse-custom-panel${(required.active && !required.sector_focus) ? ' error-block' : ''}`}
      >
        <div>
          <div className="collapse-content-title">In case the company is selling multiple assets or is operating across different sectors, please thick only the main sector and related sub sector</div>
          <div className="tabs-content">
            <div className="tabs-header">
              <div style={{width: 300}}>Sectors</div>
              <div style={{width: 'auto'}}>Sub Sectors</div>
            </div>
            <Tabs tabPosition="left" onChange={(key) => setSectorActiveTab(key)} activeKey={sectorActiveTab}>
              {
                sectorCheckedList && sectorFocus.map(sector => (
                  <TabPane
                    tab={<div style={{ color: sector.name === subsectors?.name ? '#232D53' : 'gray' }}>{sector.name}</div>}
                    key={sector.name}
                  >
                    <div className="vertical-tab-line"></div>
                    <Checkbox.Group
                      value={sectorCheckedList[sector.name]}
                      onChange={(list) => onChange(list, true)}
                    >
                      <SectorGrid
                        data={sectorOptions[sector.name]}
                        setParent={setParent}
                        group={group}
                        sectorName={sector.name}
                        activeItems={subsectors?.children?.map(el => el.id)}
                      />
                    </Checkbox.Group>
                  </TabPane>
                ))
              }
            </Tabs>
            <div className="divider" style={{ height: 7, margin: '24px 0 16px' }}></div>
            <Row gutter={[16, 24]}>
              <Col span={12} offset={12}>
                <Checkbox
                  disabled={sectorCheckedList && sectorCheckedList[sectorActiveTab].length === 0}
                  checked={otherSectorList && otherSectorList[sectorActiveTab].active}
                  onChange={e => changeOtherSector(e)}
                >
                  {otherSectorText}
                </Checkbox>
              </Col>
            </Row>
            {
              ((sectorCheckedList && sectorCheckedList[sectorActiveTab].length > 0) || (otherSectorList && otherSectorList[sectorActiveTab].active)) &&
              <Row gutter={[16, 24]} style={{marginTop: 16}}>
                <Col span={12}>
                  <div className="add-spec">Additional sector specification</div>
                </Col>
                <Col span={12} className="flex-center-vertical">
                  <Input
                    className={otherSectorList[sectorActiveTab].active && !otherSectorList[sectorActiveTab].text ? 'error-input' : ''}
                    placeholder="Please include any additional specification"
                    maxLength={180}
                    value={otherSectorValue != null ? otherSectorValue : otherSectorList[sectorActiveTab].text}
                    onChange={e => setOtherSectorValue(e.target.value)}
                    onBlur={e => changeSpecValue(e)}
                  />
                </Col>
              </Row>
            }
          </div>
        </div>
      </Panel>
      <Panel
        header={<CollapseHeader text="Geography" imageType="globe" />} key="geography"
        className={`site-collapse-custom-panel${(required.active && !required.geographic_focus) ? ' error-block' : ''}`}
      >
        <div>
          <div className="collapse-content-title">Thick the continent, region, and country in which the target company is headquartered and/or where the main asset on sale is located</div>
          <div className="tabs-content">
            <div className="tabs-header">
              <div>Area</div>
              <div>Region</div>
              <div>Countries</div>
            </div>
            <Tabs tabPosition="left" onChange={(key) => setContinentActiveTab(key)} activeKey={continentActiveTab}>
              {
                continentCheckedList && geographyFocus.map(continent => (
                  <TabPane
                    tab={<div style={{ color: setContinents?.includes(continent.name) ? '#232D53' : 'gray' }}>{continent.name}</div>}
                    key={continent.name}
                  >
                    <div className="vertical-tab-line"></div>

                    <Tabs tabPosition="left" onChange={(key) => setRegionActiveTab(key)} activeKey={regionActiveTab}>
                      {
                        continentCheckedList[continent.name].map((el, i) => (
                          <TabPane
                            tab={generateTabTitle(el.region)}
                            key={el.region}
                          >
                            <div className="vertical-tab-line"></div>
                            <Checkbox.Group
                              value={el.countries}
                              onChange={onChange}
                            >
                              <CountryGrid
                                data={continentOptions[continent.name][i][el.region]}
                                region={3}
                                activeItems={setCountries}
                              />
                            </Checkbox.Group>
                          </TabPane>
                        ))
                      }
                    </Tabs>

                  </TabPane>
                ))
              }
            </Tabs>
          </div>
        </div>
      </Panel>
      <Panel
        header={<CollapseHeader text="Financial Information" imageType="coins" />} key="investment"
        className={`site-collapse-custom-panel${(required.active && !required.investment_focus) ? ' error-block' : ''}`}
      >
        <div>
          <div className="collapse-content-title">Provide accurate information (at the best of your knowledge) on the potential transaction</div>
          <div className="investment-range noborder">
            <div className="investment-range-title">Enterprise Value Range (£)</div>
            <div className="investment-range-data">
              <div className="investment-range-label">From</div>
              <InputNumber
                min={0}
                max={99999}
                placeholder="...ml"
                parser={value => Number(value).toFixed()}
                value={investmentSector?.enterprise_value[0]}
                disabled={investmentSector?.evNaActive}
                onBlur={(e) => changeInvestmentRange(e, 0, 'enterprise_value')}
              />

              <div className="investment-range-label">To</div>
              <InputNumber
                min={0}
                max={99999}
                placeholder="...ml"
                parser={value => Number(value).toFixed()}
                value={investmentSector?.enterprise_value[1]}
                disabled={investmentSector?.evNaActive}
                onBlur={(e) => changeInvestmentRange(e, 1, 'enterprise_value')}
              />
              <div className="na-block">
                <span>or</span>
                <Checkbox
                  checked={investmentSector?.evNaActive}
                  onChange={(e) => changeNaActive(e, 'evNaActive')}
                >
                  N/A
                </Checkbox>
              </div>
            </div>
          </div>
          <div className="investment-range noborder">
            <div className="investment-range-title">Equity ticket range  (£)</div>
            <div className="investment-range-data">
              <div className="investment-range-label">From</div>
              <InputNumber
                min={0}
                max={99999}
                placeholder="...ml"
                parser={value => Number(value).toFixed()}
                value={investmentSector?.equity_ticket[0]}
                disabled={investmentSector?.etNaActive}
                onBlur={(e) => changeInvestmentRange(e, 0, 'equity_ticket')}
              />

              <div className="investment-range-label">To</div>
              <InputNumber
                min={0}
                max={99999}
                placeholder="...ml"
                parser={value => Number(value).toFixed()}
                value={investmentSector?.equity_ticket[1]}
                disabled={investmentSector?.etNaActive}
                onBlur={(e) => changeInvestmentRange(e, 1, 'equity_ticket')}
              />
              <div className="na-block">
                <span>or</span>
                <Checkbox
                  checked={investmentSector?.etNaActive}
                  onChange={(e) => changeNaActive(e, 'etNaActive')}
                >
                  N/A
                </Checkbox>
              </div>
            </div>
          </div>
          <div className="investment-range">
            <div className="investment-range-title">Equity stake range (%)</div>
            <div className="investment-range-data">
              <div className="investment-range-label">From</div>
              <InputNumber
                min={1}
                max={100}
                placeholder="%"
                parser={value => Number(value).toFixed()}
                value={investmentSector?.equity_stake[0]}
                disabled={investmentSector?.esNaActive}
                onBlur={(e) => changeInvestmentRange(e, 0, 'equity_stake')}
              />

              <div className="investment-range-label">To</div>
              <InputNumber
                min={1}
                max={100}
                placeholder="%"
                parser={value => Number(value).toFixed()}
                value={investmentSector?.equity_stake[1]}
                disabled={investmentSector?.esNaActive}
                onBlur={(e) => changeInvestmentRange(e, 1, 'equity_stake')}
              />
              <div className="na-block">
                <span>or</span>
                <Checkbox
                  checked={investmentSector?.esNaActive}
                  onChange={(e) => changeNaActive(e, 'esNaActive')}
                >
                  N/A
                </Checkbox>
              </div>
            </div>
          </div>

          <div className="investment-range base">
            <div className="investment-range-title">
              <div>Governance of the targeted equity</div>
              <span>(Select all applicable)</span>
            </div>
            <div className="investment-range-check">
              <Checkbox.Group
                value={investmentSector?.governance}
                onChange={(list) => changeInvestmentCheck(list)}
              >
                <Row gutter={[16, 30]} style={{marginBottom: 24}}>
                  {
                    governanceOptions && governanceOptions?.map(option => (
                      <Col span={12} key={option.value}>
                        <Checkbox
                          value={option.value}
                        >
                          {option.label}
                        </Checkbox>
                      </Col>
                    ))
                  }
                </Row>
              </Checkbox.Group>
              {
                dealData &&
                  <Row gutter={[16, 30]}>
                    <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        defaultChecked={!!dealData?.investment_focus?.specified_governace}
                        onChange={(e) => setGovernanceActive(e.target.checked)}
                      >
                        Other
                        </Checkbox>
                    </Col>
                    <Col span={16}>
                      {
                        investmentSector &&
                          <Input
                            placeholder="Please Specify…"
                            maxLength={100}
                            defaultValue={dealData?.investment_focus?.specified_governace}
                            onBlur={changeInvestmentOtherInput}
                            disabled={!governanceActive}
                          />
                      }
                    </Col>
                  </Row>
              }
            </div>
          </div>

          <div className="investment-range base" style={{ paddingTop: 24 }}>
            <div className="investment-range-title">
              <div>Type of investment</div>
            </div>
            <div className="investment-range-check">
              <Radio.Group
                onChange={e=>changeInvestmentType(e.target.value)}
                value={investmentSector?.investment_type[0]}
                style={{ width: '100%' }}
              >
                <Row gutter={[16, 30]}>
                  {
                    investmentTypeOptions && investmentTypeOptions?.map(option => (
                      <Col span={8} key={option.value}>
                        <Radio value={option.value}>{option.label}</Radio>
                      </Col>
                    ))
                  }
                </Row>
              </Radio.Group>
              {
                (investmentSector && investmentSector.investment_type.includes(3)) &&
                <div className="investment-range base" style={{ marginTop: 16, padding: 0 }}>
                  <Row gutter={[16, 0]} style={{ width: '100%' }}>
                    <Col span={24}>
                      <Input
                        className={investmentSector && investmentSector.investment_type.includes(3) && !investmentSector.comment ? 'error-input' : ''}
                        placeholder="Please Specify…"
                        maxLength={100}
                        defaultValue={investmentSector.comment}
                        onBlur={e => changeComment(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
              }
            </div>

          </div>
          {
            dealData &&
            <>
              <div className="investment-range noborder">
                <div className="investment-range-title">Comments</div>
                {/* <div className="investment-range-title">Additional financial information</div> */}
              </div>
              <div className="collapse-content-title md" style={{ marginBottom: 4 }}>
                {/* {`Please elaborate on the accuracy of the financial information you provided, and include any additional information linked to the additional financial requirements outlined by the investor`} */}
                {`Please add here any additional comment you might have regarding the financial information of this transaction`}
              </div>
              <div className="collapse-content-area">
                <TextArea
                  defaultValue={dealData?.comment}
                  maxLength={5000}
                  rows={6}
                  onBlur={e => changeComments(e.target.value)}
                />
              </div>
            </>
          }
        </div>
      </Panel>
      <Panel
        header={<CollapseHeader text="Target Information" imageType="target1" />} key="target_information"
        className={`site-collapse-custom-panel${(required.active && !required.target_information) ? ' error-block' : ''}`}
      >
        {
          dealData &&
          <>
          {/* {isAdmin && 
            <div className="grey-wrapper">
              <div className="investment-range noborder">
                <div className="investment-range-title">Deal overview</div>
              </div>
              <div className="collapse-content-title md" style={{ marginBottom: 4 }}>
                {`Please provide a one line descriptive overview of the deal`}
              </div>
              <div className="collapse-content-area">
                <TextArea
                  defaultValue={dealData.target_information?.deal_overview}
                  maxLength={500}
                  rows={4}
                  onBlur={(e) => setTargetInformation({ ...targetInformation, deal_overview: e.target.value })}
                />
              </div>
            </div>}
            <div className="grey-wrapper">
              <div className="investment-range noborder">
                <div className="investment-range-title">Target overview</div>
              </div>
              <div className="collapse-content-title md" style={{ marginBottom: 4 }}>
                {`Please provide here a brief overview on the Target company and its business`}
              </div>
              <div className="collapse-content-area">
                <TextArea
                  defaultValue={dealData.target_information?.target_overview}
                  maxLength={5000}
                  rows={4}
                  onBlur={(e) => setTargetInformation({ ...targetInformation, target_overview: e.target.value })}
                />
              </div>
            </div>
            <div className="grey-wrapper">
              <div className="investment-range noborder">
                <div className="investment-range-title">Target additional information</div>
              </div>
              <div className="collapse-content-title md" style={{ marginBottom: 4 }}>
                {`Based on the Target's additional requirements specified in the origination mandate, please explain how your Target is aligned to those. Feel free also to add any additional relevant information on the target`}
              </div>
              <div className="collapse-content-area">
                <TextArea
                  defaultValue={dealData.target_information?.target_add_info}
                  maxLength={5000}
                  rows={4}
                  onBlur={(e) => setTargetInformation({ ...targetInformation, target_add_info: e.target.value })}
                />
              </div>
            </div>
            <div className="grey-wrapper">
              <div className="investment-range noborder">
                <div className="investment-range-title">Target openness to sell</div>
              </div>
              <div className="collapse-content-title md" style={{ marginBottom: 4 }}>
                {`Have you already approached the Target company's management or owners to assess their openness to sell? Please elaborate and specify whether you have any direct relation with the Target's management or owners`}
              </div>
              <div className="collapse-content-area">
                <TextArea
                  defaultValue={dealData.target_information?.target_openness_to_sell}
                  maxLength={5000}
                  rows={4}
                  onBlur={(e) => setTargetInformation({ ...targetInformation, target_openness_to_sell: e.target.value })}
                />
              </div>
            </div> */}
            {/* <div className="grey-wrapper"> */}
              <div className="investment-range noborder">
                <div className="investment-range-title">Target name</div>
              </div>
              <div className="collapse-content-title md" style={{ marginBottom: 4 }}>
                {`Please insert the name and website of the Target company`}
              </div>
              <div className="collapse-content-title sm" style={{ marginBottom: 4 }}>
                {`Please note: the target's name will be disclosed only in case the investor matches your deal, in line with Section 3 of the Platform Contract`}
              </div>
              <div className="collapse-content-area">
                <Input.Group style={{ width: 'auto', display: 'flex' }}>
                  <div className="collapse-content-area inputwrapper" style={{ width: '40%' }}>
                    <Input
                      defaultValue={dealData.target_information?.target_name}
                      maxLength={50}
                      className="site-input-split"
                      style={{
                        borderColor: '#232D53',
                      }}
                      placeholder="Type Target full name"
                      onBlur={(e) => setTargetInformation({ ...targetInformation, target_name: e.target.value })}
                    />
                  </div>
                  <div className="collapse-content-area inputwrapper" style={{ width: '50%' }}>
                    <Input
                      defaultValue={dealData.target_information?.target_information_url}
                      className="site-input-split"
                      style={{
                        borderColor: '#232D53',
                      }}
                      placeholder="Link to Website (homepage)"
                      onBlur={(e) => setTargetInformationUrl(e.target.value)}
                    />
                  </div>
                </Input.Group>
              </div>
            {/* </div> */}
          </>
        }
      </Panel>
      {/* <Panel
        header={<CollapseHeader text="Your profile" imageType="pen" />} key="your_profile"
        className={`site-collapse-custom-panel${(required.active && !required.your_profile) ? ' error-block' : ''}`}
      >
        {
          dealData &&
          <>
            <div className="grey-wrapper">
              <div className="investment-range noborder">
                <div className="investment-range-title">Executive summary</div>
              </div>
              <div className="collapse-content-title md" style={{ marginBottom: 4 }}>
                {`Please note: the executive summary is taken directly from your IDN's profile. Any edit you will make here will be applied to your IDN's profile as well`}
              </div>
              <div className="collapse-content-area">
                <TextArea
                  defaultValue={yourProfileData.executive_summary || dealData.executive_summary}
                  maxLength={5000}
                  rows={4}
                  onBlur={(e) => setYourProfileData({ ...yourProfileData, executive_summary: e.target.value })}
                />
              </div>
            </div>
            <div className="grey-wrapper">
              <div className="investment-range noborder">
                <div className="investment-range-title">Sector experience</div>
              </div>
              <div className="collapse-content-title md" style={{ marginBottom: 4 }}>
                {`Please provide an overview on your experience in the specific sector related to the opportunity you are sharing, encompassing any related M&A, deal origination, and private equity experience (if any)`}
              </div>
              <div className="collapse-content-area">
                <TextArea
                  defaultValue={dealData?.advisor_sector_experience}
                  maxLength={5000}
                  rows={4}
                  onBlur={(e) => setYourProfileData({ ...yourProfileData, advisor_sector_experience: e.target.value })}
                />
              </div>
            </div>
            <div className="grey-wrapper">
              <div className="investment-range noborder">
                <div className="investment-range-title">Your role</div>
              </div>
              <div className="collapse-content-title md" style={{ marginBottom: 4 }}>
                {`Please provide details regarding the support you can offer to the investor in relation to the investment opportunity you are sharing`}
              </div>
              <div className="collapse-content-area">
                <TextArea
                  defaultValue={dealData?.advisor_role_mn}
                  maxLength={5000}
                  rows={4}
                  onBlur={(e) => setYourProfileData({ ...yourProfileData, advisor_role_mn: e.target.value })}
                />
              </div>
            </div>
          </>
        }
      </Panel> */}
    </Collapse >
  )
}

export default MandateDealSharingStart
