import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Tabs, Input, message } from 'antd'
import { useHistory, Link } from 'react-router-dom'

import MandatesInvestorTable from '../../Components/Tables/MandatesInvestorTable'
import MandatesAdvisorTable from '../../Components/Tables/MandatesAdvisorTable'
import ShareMandateFilterPanel from '../../Components/FilterPanels/ShareMandateFilterPanel'
import Images from '../../Images'
import MandatesActions from '../../Redux/MandatesRedux'
import MandateSharesActions from '../../Redux/MandateSharesRedux'
import SettingsActions from '../../Redux/SettingsRedux'
import NotificationsActions from '../../Redux/NotificationsRedux'

const { TabPane } = Tabs;
const { Search } = Input;

function MandatesPage(props) {
  const { role, mandates, match, mandateShares, filters, sectorFocus, geographyFocus, investmentTypes, choices, notifications, investorMandateGroupNotifications, advisorMandateGroupNotifications } = props;
  const {tab} = match.params;

  const [activeKey, setActiveKey] = useState(tab ? tab : 'your');
  const [start, setStart] = useState(false);
  const [active, setActive] = useState(false);
  const [count, setCount] = useState(null);
  const [mandateId, setMandateId] = useState(null);
  const [mandateShareId, setMandateShareId] = useState(null);

  const history = useHistory();

  useEffect(() => {
    setStart(true);
    if (role === 0) {
      props.getSectors();
      props.getContinents({ regions: true });
      props.getInvestmentTypes();
      props.getDealSize();
      props.getMandateStatuses();
      props.getAdvisorMandateGroupNotifications();
    } else {
      props.getInvestorMandateGroupNotifications();
    }
    return function cleanup() {
      props.mandatesSyncToggle(false);
      props.mandateSharesSyncToggle(false);
    }
  }, [0]);

  useEffect(() => {
    setActiveKey(tab ? tab : 'your');
  }, [tab]);

  useEffect(() => {
    if (activeKey && start) {
      if (role === 2) {
        if (activeKey === 'your') {
          props.getMandates(1, mandates.page_size, null, null, null, { group: '' });
          props.mandatesSyncToggle(true)
        } else if (activeKey === 'closed') {
          props.mandatesSyncToggle(false);
          props.getMandates(1, mandates.page_size, null, null, null, { group: 'closed' });
        }
      } else {
        if (activeKey === 'your') {
          props.getMandateShares(1, mandateShares.page_size, { advisor_group: 'own'});
          props.mandateSharesSyncToggle(true);
        } else if (activeKey === 'all') {
          props.mandateSharesSyncToggle(false);
          props.getMandates(mandates.page, mandates.page_size, null, null, null, mandates.filter);
        } else if (activeKey === 'closed') {
          props.mandateSharesSyncToggle(false);
          props.getMandateShares(1, mandateShares.page_size, { advisor_group: 'closed'});
        }
      }
    }
  }, [activeKey, start]);

  useEffect(() => {
    if (mandates.mandateNotificationResult) {
      if (role === 2) {
        history.push(`/home/mandate/${mandateId}`);
      } else {

      }
    }
  }, [mandates.mandateNotificationResult]);

  useEffect(() => {
    if (mandateShares.mandateSharesNotificationResult) {
      activeKey === 'all' ?
        history.push({
          pathname: `/home/mandate-share-info-all/${mandateId}`,
          state: {tab: activeKey}
        })
      :
        history.push(`/home/mandate-share-info/${mandateShareId}/summary`)
    }
  }, [mandateShares.mandateSharesNotificationResult]);

  useEffect(() => {
    if (mandates.list && role === 2) {
      const checkNote = mandates.list.results?.some(el => el.investor_notification);
      props.setMandateNotification(checkNote);
    }
  }, [mandates.list]);

  useEffect(() => {
    if (mandateShares.list && role === 0) {
      const checkNote = mandateShares.list.results?.some(el => el.advisor_notification);
      props.setMandateSharesNotification(checkNote);
    }
  }, [mandateShares.list]);

  const handleChangePage = (page, page_size) => {
    if (role === 2) {
      if (activeKey === 'your') {
        props.getMandates(page, page_size);
      } else {
        props.getMandates(page, page_size, null, null, null, { group: 'closed' });
      }
    } else {
      if (activeKey === 'your') {
        props.getMandateShares(page, page_size, { advisor_group: 'own'});
      }
      else if (activeKey === 'all') {
        props.getMandates(page, page_size, null, null, mandates.search, mandates.filter);
      }
      else {
        props.getMandateShares(page, page_size, { advisor_group: 'closed'});
      }
    }
  }

  useEffect(() => {
    if (notifications?.mandate) {
      if (role === 0) {
        onTabChange(activeKey);
        props.getAdvisorMandateGroupNotifications();
      } else {
        onTabChange(activeKey);
        props.getInvestorMandateGroupNotifications();
      }
    }
  }, [notifications]);

  const onTabChange = key => {
    history.push(`/home/mandates/${key}`);
    setActiveKey(key);
  }

  const generateTabTitle = key => {
    let title;
    switch(key) {
      case 'your': {
        title = 'Your Mandates';
        break;
      }
      case 'closed': {
        title = 'Closed Mandates';
        break;
      }
      default: {
        title = 'All Mandates';
        break;
      }
    }
    let notes = (role === 0) ? advisorMandateGroupNotifications : investorMandateGroupNotifications;
    return (
      <div className="tab-title">
        {/* <Link to={`/home/mandates/${key}`}> */}
          <span>{title}</span>
          {
            notes && notes[key] &&
              <img src={Images.notification} alt="" />
          }
        {/* </Link> */}
      </div>
    )
  }

  const deleteNotification = id => {
    if (role === 2) {
      setMandateId(id);
      props.deleteMandateNotification(id, {investor_notification: false});
    } else {
      if (activeKey === 'all') {
        setMandateId(id);
        props.deleteMandateNotification(id, {advisor_notification: false});
      } else {
        setMandateShareId(id);
        props.deleteMandateSharesNotification(id, {advisor_notification: false});
      }
    }
  }

  const handleSearch = val => {
    if (mandates.search === val) {
      return;
    }
    props.getMandates(1, mandates.page_size, null, null, val, mandates.filter);
  }

  const changeCount = (value, data, start) => {
    setCount(value);
    !start && props.getMandates(1, mandates.page_size, null, null, mandates.search, data);
  }

  function closeFilter() {
    setActive(!active);
  }

  const createMandate = () => {
    if (!filters.all_agreements) {
      message.error('Legal agreement not approved - please get in touch with IDN', 3);
    } else {
      history.push('/home/mandate-sharing/step/start');
    }
  }

  return (
    <div className="main-container">
      {
        role === 0 ?
          <>
             <Tabs
              activeKey={activeKey}
              style={{ marginTop: 32 }}
              className='mandates'
              onChange={onTabChange}
            >
              <TabPane tab={generateTabTitle('your')} key="your">
                {
                  mandateShares?.list?.results?.length ?
                    <MandatesAdvisorTable
                      tab="your"
                      data={mandateShares.list}
                      page={mandateShares.page}
                      fetching={false}
                      pageSize={mandateShares.page_size}
                      onChangePage={handleChangePage}
                      deleteNotification={deleteNotification}
                    />
                    :
                    <div className="section-content info">You don’t have any active mandate in your pipeline</div>
                }
              </TabPane>
              <TabPane tab={generateTabTitle('all')} key="all" style={{ height: '100%' }}>
              <div className="action-panel">
                <Search
                  placeholder="Search"
                  allowClear
                  className="content-search"
                  suffix={<img src={Images.search} alt="" />}
                  defaultValue={mandates.search}
                  onSearch={handleSearch}
                />
                <Button type={`primary${count ? ' orange' : ' white'}`} className="btn-primary" onClick={closeFilter}>
                  {'Filter' + (count ? ` (${count})` : '')}
                </Button>
                <ShareMandateFilterPanel
                  filterData={mandates.filter}
                  active={active}
                  sectors={sectorFocus}
                  continents={geographyFocus}
                  investmentTypes={investmentTypes}
                  choices={choices}
                  dealSizeList={mandates.dealSizeList}
                  changeCount={changeCount}
                  closeFilter={closeFilter}

                />
              </div>
              {
                mandates?.list?.results?.length ?
                  <MandatesAdvisorTable
                    tab="all"
                    data={mandates.list}
                    page={mandates.page}
                    fetching={false}
                    pageSize={mandates.page_size}
                    onChangePage={handleChangePage}
                    allMandate={true}
                    deleteNotification={deleteNotification}
                  />
                  :
                  <div className="section-content info">You don’t have any active mandate in your pipeline</div>
                }
              </TabPane>
              <TabPane tab={generateTabTitle('closed')} key="closed">
              {
                  mandateShares?.list?.results?.length ?
                    <MandatesAdvisorTable
                      tab="closed"
                      data={mandateShares.list}
                      page={mandateShares.page}
                      fetching={false}
                      pageSize={mandateShares.page_size}
                      onChangePage={handleChangePage}
                      deleteNotification={deleteNotification}
                    />
                    :
                    <div className="section-content info">You don’t have any active mandate in your pipeline</div>
                }
              </TabPane>
            </Tabs>
          </>
          :
          <>
            <div className="section-title">Mandates</div>
            <div className="section-content" style={{ marginBottom: 'auto' }}>
              <div className="section-text">
                <span>Looking for new investment opportunities?</span>
                <span>Issue <b>origination mandates</b> via IDN</span>
                <span className="blue">Receive proprietary deals matching your mandate</span>
              </div>
              <Button type="primary orange-margin" className="btn-primary deal-btn" onClick={createMandate}>
                Issue it now
              </Button>
            </div>
            <Tabs
              activeKey={activeKey}
              style={{ marginTop: 32 }}
              className='mandates'
              onChange={onTabChange}
            >
              <TabPane tab={generateTabTitle('your')} key="your">
                {
                  mandates?.list?.results?.length ?
                  <>
                    <MandatesInvestorTable
                      data={mandates.list}
                      page={mandates.page}
                      fetching={false}
                      pageSize={mandates.page_size}
                      onChangePage={handleChangePage}
                      deleteNotification={deleteNotification}
                    />
                  </>
                  :
                  <div className="section-content info">You don’t have any active mandate in your pipeline</div>
                }
              </TabPane>
              <TabPane tab={generateTabTitle('closed')} key="closed">
                {
                  mandates?.list?.results?.length ?
                    <MandatesInvestorTable
                      data={mandates.list}
                      page={mandates.page}
                      fetching={false}
                      pageSize={mandates.page_size}
                      onChangePage={handleChangePage}
                      deleteNotification={deleteNotification}
                    />
                    :
                    <div className="section-content info">You don’t have any closed mandate in your pipeline</div>
                }
              </TabPane>
            </Tabs>

          </>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    role: state.auth.role,
    mandates: state.mandates,
    mandateShares: state.mandateShares,
    stats: state.mandateShares.investorStats,
    filters: state.settings.filters,
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    equityGovernanceList: state.settings.equityGovernanceList,
    investmentTypes: state.settings.investmentTypes,
    choices: state.mandates.choices,
    investorMandateGroupNotifications: state.notifications.investorMandateGroupNotifications,
    advisorMandateGroupNotifications: state.notifications.advisorMandateGroupNotifications,
    notifications: state.notifications.notifications
  }
}

const mapDispatchToProps = (dispatch) => ({
  getMandates: (page, page_size, sort_field, sort_order, search, filter) => dispatch(MandatesActions.mandatesRequest(page, page_size, sort_field, sort_order, search, filter)),
  mandatesSyncToggle: (syncIsStart) => dispatch(MandatesActions.mandatesSyncToggle(syncIsStart)),
  setMandateNotification: (value) => dispatch(MandatesActions.setMandateNotification(value)),
  deleteMandateNotification: (mandateId, data) => dispatch(MandatesActions.mandateNotificationDeleteRequest(mandateId, data)),

  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: (filter) => dispatch(SettingsActions.geographyFocusRequest(filter)),
  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),
  // getFirmFilters: (firmId) => dispatch(SettingsActions.firmFiltersRequest(firmId)),
  getDealSize: () => dispatch(MandatesActions.dealSizeRequest()),
  getMandateStatuses: () => dispatch(MandatesActions.mandateStatusesRequest()),

  getMandateShares: (page, page_size, filter) => dispatch(MandateSharesActions.mandateSharesRequest(page, page_size, filter)),
  setMandateSharesNotification: (value) => dispatch(MandateSharesActions.setMandateSharesNotification(value)),
  mandateSharesSyncToggle: (syncIsStart) => dispatch(MandateSharesActions.mandateSharesSyncToggle(syncIsStart)),
  deleteMandateSharesNotification: (mandateShareId, data) => dispatch(MandateSharesActions.mandateSharesNotificationDeleteRequest(mandateShareId, data)),

  getInvestorMandateGroupNotifications: () => dispatch(NotificationsActions.investorMandateGroupNotificationsRequest()),
  getAdvisorMandateGroupNotifications: () => dispatch(NotificationsActions.advisorMandateGroupNotificationsRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MandatesPage)
