import { call, put, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import SettingsActions from '../Redux/SettingsRedux'
import AuthActions, {AuthSelectors} from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'
import DealsActions from '../Redux/DealsRedux'
import DealSharesActions from '../Redux/DealSharesRedux'
import MandatesActions from '../Redux/MandatesRedux'
import MandateSharesActions from '../Redux/MandateSharesRedux'
import FirmsActions from '../Redux/FirmsRedux'
import UsersActions from '../Redux/UsersRedux'
import AuthApi from '../Services/AuthApi'

export function * getFilters(api, authApi, {id, role}) {
  try {
    const response = yield call(api.getFilters, id, role);
    if (response && response.ok) {
      yield put(SettingsActions.filtersSuccess(response.data))
      yield put(SettingsActions.setUserName(response.data.name, response.data.surname))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getFilters(api, authApi, {id, role});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * updateFilters(api, authApi, { id, role, data }) {
  try {
    const response = yield call(api.updateFilters, id, role, data);
    if (response && response.ok) {
      yield put(SettingsActions.filtersSaveSuccess(response.data ? response.data : true))
      yield put(SettingsActions.resultsReset())
      yield put(SettingsActions.setUserName(response.data.name, response.data.surname))
      yield put(SettingsActions.filtersRequest(id, role))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * updateFilters(api, authApi, { id, role, data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getFirmFilters(api, authApi, {firmId}) {
  try {
    const response = yield call(api.getFirmFilters, firmId);
    if (response && response.ok) {
      yield put(SettingsActions.firmFiltersReset())
      yield put(SettingsActions.firmFiltersSuccess(response.data))
      yield put(SettingsActions.setFirmName(response.data.name))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getFirmFilters(api, authApi, {firmId});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * updateFirmFilters(api, authApi, { firmId, data }) {
  try {
    const response = yield call(api.updateFirmFilters, firmId, data);
    if (response && response.ok) {
      yield put(SettingsActions.firmFiltersSaveSuccess(response.data ? response.data : true))
      yield put(SettingsActions.resultsReset())
      yield put(SettingsActions.setFirmName(response.data.name))
      yield put(SettingsActions.firmFiltersRequest(firmId))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else if (response.status === 413) {
          yield put(ErrorsActions.errorSave("Request entity too large"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * updateFirmFilters(api, authApi, { firmId, data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * updateBilateralContract(api, authApi, { firmId, data }) {
  try {
    const response = yield call(api.updateBilateralContract, firmId, data);
    if (response && response.ok) {
      yield put(SettingsActions.bilateralContractSaveSuccess(response.data ? response.data : true))
      yield put(SettingsActions.resultsReset())

    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else if (response.status === 413) {
          yield put(ErrorsActions.errorSave("Request entity too large"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * updateBilateralContract(api, authApi, { firmId, data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * addBilateralContractByAdmin(api, authApi, { shareId, data }) {
  try {
    const response = yield call(api.addBilateralContractByAdmin, shareId, data);
    if (response && response.ok) {
      yield put(SettingsActions.addBilateralContractByAdminSuccess(response.data ? response.data : true))
      yield put(SettingsActions.resultsReset())

    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else if (response.status === 413) {
          yield put(ErrorsActions.errorSave("Request entity too large"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * addBilateralContractByAdmin(api, authApi, { shareId, data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getSectorFocus(api, authApi) {
  try {
    const response = yield call(api.getSectorFocus);
    if (response && response.ok) {
      yield put(SettingsActions.sectorFocusSuccess(response.data))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * createSectorItem(api, authApi, { data }) {
  try {
    const response = yield call(api.createSectorItem, data);
    if (response && response.ok) {
      yield put(SettingsActions.sectorFocusItemCreateSuccess(response.data))
      yield put(SettingsActions.resultsReset())
      yield put(SettingsActions.sectorFocusRequest())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * createSectorItem(api, authApi, { data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * updateSectorItem(api, authApi, { itemId, data }) {
  try {
    const response = yield call(api.updateSectorItem, itemId, data);
    if (response && response.ok) {
      yield put(SettingsActions.sectorFocusItemUpdateSuccess(response.data))
      yield put(SettingsActions.resultsReset())
      yield put(SettingsActions.sectorFocusRequest())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * updateSectorItem(api, authApi, { itemId, data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * deleteSectorItem(api, authApi, { itemId }) {
  try {
    const response = yield call(api.deleteSectorItem, itemId);
    if (response && response.ok) {
      yield put(SettingsActions.sectorFocusItemDeleteSuccess(response.data ? response.data : true))
      yield put(SettingsActions.resultsReset())
      yield put(SettingsActions.sectorFocusRequest())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * deleteSectorItem(api, authApi, { itemId });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * changeSectorOrder(api, authApi, { data }) {
  try {
    const response = yield call(api.changeSectorOrder, data);
    if (response && response.ok) {
      yield put(SettingsActions.sectorFocusOrderSuccess(response.data))
      yield put(SettingsActions.resultsReset())
      yield put(SettingsActions.sectorFocusRequest())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * changeSectorOrder(api, authApi, { data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getGeographyFocus(api, authApi, {filter}) {
  try {
    const response = yield call(api.getGeographyFocus, filter);
    if (response && response.ok) {
      yield put(SettingsActions.geographyFocusSuccess(response.data))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getEquityGovernance(api, authApi) {
  try {
    const response = yield call(api.getEquityGovernance);
    if (response && response.ok) {
      yield put(SettingsActions.equityGovernanceSuccess(response.data))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getInvestmentTypes(api, authApi) {
  try {
    const response = yield call(api.getInvestmentTypes);
    if (response && response.ok) {
      yield put(SettingsActions.investmentTypesSuccess(response.data))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getCountries(api, authApi) {
  try {
    const response = yield call(api.getCountries);
    if (response && response.ok) {
      yield put(SettingsActions.countriesSuccess(response.data))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getEuCountries(api, authApi, {filter}) {
  try {
    const response = yield call(api.getCountries, filter);
    if (response && response.ok) {
      yield put(SettingsActions.euCountriesSuccess(response.data))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getRegions(api, authApi) {
  try {
    const response = yield call(api.getRegions);
    if (response && response.ok) {
      yield put(SettingsActions.regionsSuccess(response.data))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getInvestorFilters(api, authApi, {hash}) {
  try {
    const response = yield call(api.getInvestorFilters, hash);
    if (response && response.ok) {
      yield put(SettingsActions.investorFirmFiltersSuccess(response.data))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getUpdateAgreement(api, authApi, {advisorId}) {
  try {
    const response = yield call(api.getUpdateAgreement, advisorId);
    if (response && response.ok) {
      yield put(SettingsActions.advisorUpdateAgreementSuccess(response.data))
      yield put(SettingsActions.resultsReset())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * confirmAgreementByAdvisor(api, authApi, {advisorId}) {
  try {
    const response = yield call(api.confirmAgreementByAdvisor, advisorId);
    if (response && response.ok) {
      yield put(SettingsActions.advisorConfirmAgreementSuccess(response.data))
      yield put(SettingsActions.resultsReset())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getAgreementBlank(api, authApi) {
  try {
    const response = yield call(api.getAgreementBlank);
    if (response && response.ok) {
      yield put(SettingsActions.advisorAgreementBlankSuccess(response.data))
      yield put(SettingsActions.resultsReset())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getAgreementBlank(api, authApi);
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}
export function * getExportData(api, authApi) {
  try {
    const response = yield call(api.getExportData);
    if (response && response.ok) {
      yield put(SettingsActions.exportDataSuccess(response.data))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(SettingsActions.settingsFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getExportData(api, authApi);
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(SettingsActions.settingsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * resetTableSettings() {
  yield put(DealsActions.dealTableSettingsReset())
  yield put(DealSharesActions.dealSharesTableSettingsReset())
  yield put(MandatesActions.mandateTableSettingsReset())
  yield put(MandateSharesActions.mandateSharesTableSettingsReset())
  yield put(FirmsActions.firmTableSettingsReset())
  yield put(UsersActions.userTableSettingsReset())

  yield put(SettingsActions.setTableSettingsFetching(false))
}
