import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import SettingsActions from '../../Redux/SettingsRedux'
import Images from '../../Images'

function PlatformAgreementPage(props) {
  const { user, role, filters, firmFilters, advisorAgreementBlankResult } = props;

  const [src, setSrc] = useState(false);

  useEffect(() => {
    if (role === 0) {
      if (user?.signed_platform_agreement) {
        setSrc(filters?.platform_agreement);
      } else {
        props.getAgreementBlank();
      }
    } else if (role === 2) {
      props.getFirmFilters(filters?.company?.id);
    }
  }, [0]);

  useEffect(() => {
    if (firmFilters) {
      setSrc(firmFilters.platform_agreement);
    }
  }, [firmFilters]);

  useEffect(() => {
    if (advisorAgreementBlankResult) {
      setSrc(advisorAgreementBlankResult.platform_agreement);
    }
  }, [advisorAgreementBlankResult]);

  return (
    <div className="main-container">
      <div className="pa-header">
        <div className="section-title" style={{whiteSpace: 'nowrap'}}>Platform Agreement</div>
        {
          (role === 0 && !user?.signed_platform_agreement) &&
            <div className="pa-message">The agreement below will have to be signed once you share the first opportunity on the platform.</div>
        }
        {
          src !== false &&
            <div className="download-btn main">
              <a href={src} target="_blank" rel="noopener noreferrer">
                <img src={Images.pdf} alt="" />
                <span>Download an offline copy</span>
              </a>
            </div>
        }
      </div>
      {
        src !== false &&
        <>
          <div className="section-content t-cs">
            <div className="terms-conditions">
              {
                role === 0 &&
                  <iframe src={src} width="100%" height="100%" title="Platform Agreement"></iframe>
              }
              {
                role === 2 &&
                  <>
                  {
                     src != null ?
                      <iframe src={src} width="100%" height="100%" title="Platform Agreement"></iframe>
                      :
                      <div className="not-agreement">Your firm has not agreed yet to our Platform Agreement</div>
                  }
                  </>
              }
            </div>
          </div>
        </>
      }
    </div >
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    role: state.auth.role,
    filters: state.settings.filters,
    firmFilters: state.settings.firmFilters,
    advisorAgreementBlankResult: state.settings.advisorAgreementBlankResult,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getFirmFilters: (firmId) => dispatch(SettingsActions.firmFiltersRequest(firmId)),
  getAgreementBlank: () => dispatch(SettingsActions.advisorAgreementBlankRequest()),

})

export default connect(mapStateToProps, mapDispatchToProps)(PlatformAgreementPage)
