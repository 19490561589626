import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import moment from 'moment'

import PaginationItemRender from '../PaginationItemRender'
import { TitleWithSortFilters } from '../TitleWithSortFilters'
import CustomIcon from '../../Icon'

import './Table.less'

function MandatePipelineTable(props){
  const {
    page,
    pageSize,
    data,
    fetching
  } = props;

  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    let arr = (data && data.results) ? data.results.asMutable({deep:true}) : [];
    arr = arr.map(el => ({
      id: el.id,
      invest_firm_winner: el.invest_firm,
      owner: el.owner_obj?.name,
      account_manager: el.account_manager,
      shared: el.shared,
      status: el.status_obj?.name,
      deals_received: el.deals_received_admin,
    }))
    setDataSource(arr);
  }, [data]);

  function getColumns() {
    return [
      {
        title: (stateData)=>TitleWithSortFilters("Mandate ID", true, false, stateData, 'id'),
        dataIndex: 'id',
        key: 'id',
        sorter: true,
        className: 'cell-left',
        render: (text, record) => {
          return (
            <Link to={'/home/mandate-details/' + record.id}>{text}</Link>
          )
        },
      },
      {
        title: (stateData)=>TitleWithSortFilters("Investment firm", true, false, stateData, 'invest_firm_winner'),
        dataIndex: 'invest_firm_winner',
        key: 'invest_firm_winner',
        sorter:true,
      },
      {
        title: (stateData)=>TitleWithSortFilters("Investor", true, false, stateData, 'owner'),
        dataIndex: 'owner',
        key: 'owner',
        sorter:true,
      },
      {
        title: (stateData)=>TitleWithSortFilters("Account Manager", true, false, stateData, 'account_manager'),
        dataIndex: 'account_manager',
        key: 'account_manager',
        sorter:true,
      },
      {
        title: (stateData)=>TitleWithSortFilters("Date", true, false, stateData, 'shared'),
        dataIndex: 'shared',
        key: 'shared',
        sorter: true,
        render: (text, record) => {
          return (
              <span>{text && moment(text).format('DD/MM/YYYY')}</span>
          )
        },
      },
      {
        title: (stateData)=>TitleWithSortFilters("Status", true, false, stateData, 'status'),
        dataIndex: 'status',
        key: 'status',
        sorter:true,
      },
      {
        title: (stateData)=>TitleWithSortFilters("Deals received", true, false, stateData, 'deals_received'),
        dataIndex: 'deals_received',
        key: 'deals_received',
        sorter:true,
        className: 'cell-center'
      },
    ];
  }

  function handleTableChange(pagination, filters, sorter){

    var sort_field = null;
    var sort_order = null;

    if(sorter && sorter.field && sorter.order){
      sort_field = sorter.field;
      sort_order = sorter.order //=== "ascend" ? 1 : 0;
    }

    props.onChangePage(pagination.current, pagination.pageSize, sort_field, sort_order)

  }

  const totalCount = data ? data.count : 0;

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount, showSizeChanger: true}}
      className="main-table"
      rowKey={row => row.id}
      rowClassName="process-table-row"
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
      showSorterTooltip={false}
    />
   )


}

export default MandatePipelineTable
