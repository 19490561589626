import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spin, message, Upload, DatePicker } from 'antd'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

const dateFormat = 'DD/MM/YYYY';

function BindingOfferAcceptedInvestorPopupPage(props) {
	const { result, fetching, match, isAdmin, location } = props;
	const { dashboardId } = match.params;
	const { state } = location;

	const [fileUid, setFileUid] = useState(null);
  const [fileData, setFileData] = useState(null);
	const [dateValue, setDateValue] = useState(null);
	const [active, setActive] = useState(false);

	const history = useHistory()

	useEffect(() => {
		props.dealDashboardSyncToggle(false);
		return function cleanup() {
			props.dealDashboardSyncToggle(true);
		}
	}, [0]);

	useEffect(() => {
    if (result) {
			props.getDealDashboardInfo(dashboardId);
			isAdmin ?
				history.push(`/home/match-company/${dashboardId}`)
			:
				history.push(`/deal-dashboard/overview/${dashboardId}`);
    }
  }, [result]);

	function onChange({ file, fileList }) {
		if (fileUid && fileUid === file.uid) {
      return;
    }
    setFileUid(file.uid);

    let formData = new FormData();

    if (fileList && fileList.length) {
      formData.set('file', fileList[0].originFileObj, fileList[0].originFileObj.name);
    }
    setFileData(formData);
  }

	function changeDate(date, dateString) {
    setDateValue(dateString);
  }

	const checkRequired = () => {
		if (state?.isHistory) {
			history.push(`/deal-dashboard/overview/${dashboardId}`);
			return;
		}
		if (!dateValue) {
      message.error('Indicate the date', 3);
      if (!active) {
        setActive(true);
      }
		} else if (!fileData) {
			message.error('Upload the file', 3);
		} else {
			fileData.set('date', dateValue);
			props.acceptedBindingOfferByInvestor(dashboardId, fileData);
		}
	}

	return (
		<PopupWrapper
			width={460}
			path={isAdmin ?
				`/home/match-company/${dashboardId}`
				:
				`/deal-dashboard/overview/${dashboardId}`
			}
			hasDropdown={true}
		>
			<Spin spinning={fetching} size="large">
				<div className='dialog-content' style={{ padding: 30 }}>
					<img src={Images.phoneOk} alt='' />
					<div className='dialog-title welcome' style={{ marginTop: 10, marginBottom: 20 }}>
						Binding offer accepted
				</div>
					<div className='deal-text'>Congratulations for having a binding offer accepted</div>
					<br />
					<div className='deal-subtext engagment'>Please upload a signed written statement from an Authorised Person of your firm confirming the Enterprise Value of the Target set out in the Non-Binding Offer accepted by the Target and the percentage of the Fully Diluted Share Capital of the Target to be issued or transferred when the Binding Offer is accepted.</div>
					<br />
					<Upload
						method="get"
						maxCount={1}
						onChange={onChange}
						className="upload-block"
						beforeUpload={file => new Promise((resolve, reject) => {
							if (file.size > 20971520) {
								message.error('File size must not exceed 20 Mbyte', 3);
								reject();
							} else {
								resolve();
							}
						})}
					>
						<Button
							disabled={state?.isHistory}
							type='primary white'
							className='btn-primary upload'
							style={{ width: 62, borderRadius: 5, margin: 6 }}
						>
							<img src={Images.upload} alt='' style={{ height: 20 }} />
						</Button>
					</Upload>
					<div className='deal-input' style={{ marginTop: 10 }}>
						Insert Date offer was accepted:
						<span>
							<DatePicker
								disabled={state?.isHistory}
								defaultValue={state?.add_info?.date && moment(state.add_info.date, dateFormat)}
								format={dateFormat}
								dropdownClassName="bottomCenter"
								className={`deal-input nbo${(active && !dateValue) ? ' error-input' : ''}`}
								placeholder=""
								allowClear={false}
								onChange={changeDate}
								suffixIcon={<img src={Images.clock} alt='' className='deal-input clock' />}
							/>
						</span>
					</div>
					<div className='dealsizerange' style={{ marginTop: 15 }}>
						<div className='divider'></div>
					</div>
					<br />
        	<div className="deal-text sm" style={{width: '90%'}}>Please review IDN's Platform Agreement to remember your<br />rights and obligations in the context of this engagement</div>
					<div className='btn-footer' style={{ marginTop: 24 }}>
						<Button
							type='primary white'
							className='btn-primary welcome'
							style={{ width: 144 }}
							onClick={checkRequired}
						>
							{state?.isHistory ? 'Go back' : 'Confirm'}
					</Button>
					</div>
				</div>
			</Spin>
		</PopupWrapper>
	)
}

const mapStateToProps = (state) => {
	return {
		isAdmin: state.auth.isAdmin,
		result: state.dealDashboard.result,
		fetching: state.dealDashboard.fetching
	}
}

const mapDispatchToProps = (dispatch) => ({
	getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
	acceptedBindingOfferByInvestor: (dashboardId, data) => dispatch(DealDashboardActions.investorAcceptedBindingOfferRequest(dashboardId, data)),
	dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BindingOfferAcceptedInvestorPopupPage)
