import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import ChatActions from '../Redux/ChatRedux'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'

export function * getChatMembers(api, authApi, { dashboardId }) {
  try {
    const response = yield call(api.getChatMembers, dashboardId)
    if (response && response.ok) {
      yield put(ChatActions.chatMembersSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(ChatActions.chatFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getChatMembers(api, authApi, { dashboardId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(ChatActions.chatFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * removeChatNotification(api, authApi, { dashboardId }) {
  try {
    const response = yield call(api.removeChatNotification, dashboardId)
    if (response && response.ok) {
      yield put(ChatActions.chatRemoveNotificationSuccess(response.data))
      yield put(ChatActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(ChatActions.chatFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * removeChatNotification(api, authApi, { dashboardId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(ChatActions.chatFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getChatMessages(api, authApi, { page, page_size, chat }) {
  try {
    const response = yield call(api.getChatMessages, page, page_size, chat)
    if (response && response.ok) {
      yield put(ChatActions.chatMessagesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(ChatActions.chatFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getChatMessages(api, authApi, { page, page_size, chat })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(ChatActions.chatFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * sendChatMessage(api, authApi, { data }) {
  try {
    const response = yield call(api.sendChatMessage, data)
    if (response && response.ok) {
      yield put(ChatActions.chatMessageSaveSuccess(response.data))
      yield put(ChatActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(ChatActions.chatFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * sendChatMessage(api, authApi, { data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(ChatActions.chatFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * setChatUserStatus(api, authApi, { dashboardId, user_active }) {
  try {
    const response = yield call(api.setChatUserStatus, dashboardId, user_active)
    if (response && response.ok) {
      yield put(ChatActions.chatUserStatusSuccess(response.data))
      yield put(ChatActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(ChatActions.chatFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * setChatUserStatus(api, authApi, { dashboardId, user_active })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(ChatActions.chatFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}
