import React, { useState, useEffect, Fragment, useCallback } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Tabs, Input, Select, Button, Modal, message, Upload, Tooltip, Row, Spin } from 'antd'
import { FileIcon, defaultStyles } from 'react-file-icon'

import SettingsActions from '../../Redux/SettingsRedux'
import UsersActions from '../../Redux/UsersRedux'
import FirmsActions from '../../Redux/FirmsRedux'
import InvestorsTable from '../../Components/Tables/InvestorsTable'
import Images from '../../Images'

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

const deleteButtonProps = {
  type: "primary red",
  className: "btn-primary",
  style: { width: 172 }
};

const cancelButtonProps = {
  type: "primary white",
  className: "btn-primary",
  style: { width: 172 }
};

function FirmDetailsPage(props) {
  const { filters, saveResult, saveFetching, bilateralUpdateResult, countries, deleteResult, users, match, result } = props;
  const { firmId, tab } = match.params;

  const [profileData, setProfileData] = useState(null);
  const [activeKey, setActiveKey] = useState('profile');
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [src, setSrc] = useState(null);
  const [srcBilateral, setSrcBilateral] = useState(null);
  const [srcBilateralDocx, setSrcBilateralDocx] = useState(null);
  const [agreementUpdate, setAgreementUpdate] = useState(false);
  const [bilateralUpdate, setBilateralUpdate] = useState(false);

  let fileUid = null;

  const adminList = [];
  if (users?.list?.results) {
    for (let i = 0; i < users.list.results.length; i++) {
      adminList.push(<Option key={users.list.results[i]?.user?.id}>{`${users.list.results[i]?.name} ${users.list.results[i]?.surname}`}</Option>);
    }
  }

  const countryList = [];
  if (countries) {
    for (let i = 0; i < countries.length; i++) {
      countryList.push(<Option key={countries[i].id}>{countries[i].name}</Option>);
    }
  }

  const deleteFirmButton =
    <Button
      type="primary red"
      className="btn-primary"
      onClick={() => setDeleteVisible(true)}
    >
      Delete
    </Button>

  const history = useHistory();

  function handleNavigate(path) {
    history.push(path);
  }

  useEffect(() => {
    props.resetData();
    props.getFirmFilters(firmId);
    props.getCountries();
  }, [0]);

  useEffect(() => {
    setActiveKey(tab ? tab : 'profile');
  }, [tab]);

  useEffect(() => {
    if (activeKey === 'profile') {
      props.getUsers('admin');
    } else if (activeKey === 'users') {
      props.getUsers('investor', users.page_firm, users.page_size_firm, users.sort_field_firm, users.sort_order_firm, null, { invest_company: firmId }, true);
    }
  }, [activeKey]);

  useEffect(() => {
    if (filters) {
      let newData = {
        overview: {
          value: filters.overview,
          active: false,
        },
        investment_preferences: {
          value: filters.investment_preferences,
          active: false,
        },
        management_style: {
          value: filters.management_style,
          active: false,
        },
        portfolio: {
          value: filters.portfolio,
          active: false,
        },
        headquarter: {
          value: '',
          active: false,
        },
        account_manager: {
          value: '',
          active: false,
        },
        website: {
          value: filters.website,
          active: false,
        },
        name: {
          value: filters.name,
          active: false,
        },
        status: {
          value: filters.status_obj?.id,
          active: false,
        },
        special_provisions: {
          value: filters.special_provisions,
          active: false,
        }
      };
      setProfileData(newData);
      setSrcBilateral(filters.bilateral_contract);
      setSrcBilateralDocx(filters.bilateral_contract_docx);
      setSrc(filters.platform_agreement);
    }
  }, [filters]);

  useEffect(() => {
    if (deleteResult) {
      message.success('Firm successfully deleted', 3);
      setDeleteVisible(false);
    }
  }, [deleteResult]);

  useEffect(() => {
    if (saveResult) {
      if (agreementUpdate) {
        message.success('Platform agreement successfully updated', 3);
        setAgreementUpdate(false);
      }
    }
  }, [saveResult]);

  useEffect(() => {
    if (bilateralUpdateResult) {
      if (bilateralUpdate) {
        message.success('Bilateral contract successfully uploaded', 3);
        setBilateralUpdate(false);
        props.getFirmFilters(firmId);
      }
    }
  }, [bilateralUpdateResult]);

  function onChangePlatformAgreement({ file, fileList }) {
    if (fileUid && fileUid === file.uid) {
      return;
    }
    fileUid = file.uid;
    setAgreementUpdate(true);
    let formData = new FormData();
    if (fileList && fileList.length) {
      formData.set('platform_agreement', fileList[0].originFileObj, fileList[0].originFileObj.name);
      props.updateFirmFilters(firmId, formData);
    }
  }

  const beforeUpload = (file, fileList) => {
    //this need to work func onChangeBilateralContract once time
    return false;
  }

  function onChangeBilateralContract({ file, fileList }) {
    if (fileUid && fileUid === file.uid) {
      return;
    }
    fileUid = file.uid;
    setBilateralUpdate(true);
    let formData = new FormData();
    if (fileList && fileList.length) {
      formData.set('file', fileList[0].originFileObj, fileList[0].originFileObj.name);
      props.updateBilateralContract(firmId, formData);
    }
  }


  function setActive(field) {
    let newData = { ...profileData };
    newData[field].active = true;
    setProfileData(newData);
  }

  function setInactive(field, e) {
    let newData = { ...profileData };
    newData[field].active = false;
    newData[field].value = e.target.value;
    setProfileData(newData);
    props.updateFirmFilters(firmId, { [field]: e.target.value });
  }

  function changeManager(value) {
    let newData = { ...profileData };
    newData['account_manager'].active = false;
    newData['account_manager'].value = value;
    setProfileData(newData);
    props.updateFirmFilters(firmId, { account_manager: value });
  }

  function changeStatus(value) {
    let newData = { ...profileData };
    newData['status'].active = false;
    newData['status'].value = value;
    setProfileData(newData);
    props.updateFirmFilters(firmId, { status: value });
  }

  function changeHeadquarter(value) {
    let newData = { ...profileData };
    newData['headquarter'].active = false;
    newData['headquarter'].value = value;
    setProfileData(newData);
    props.updateFirmFilters(firmId, { headquarter: value });
  }

  function handleChangePage(page, page_size, sort_field, sort_order) {
    props.getUsers('investor', page, page_size, sort_field, sort_order, null, { invest_company: firmId }, true);
  }

  function deleteFirm() {
    props.deleteFirm(firmId);
  }

  const onTabChange = key => {
    history.push(`/home/firm-details/${firmId}/${key}`);
    setActiveKey(key);
  }

  return (
    <div className="main-container">
      <Spin size="large" spinning={saveFetching}>
        <Tabs
          activeKey={activeKey}
          tabBarExtraContent={deleteFirmButton}
          style={{ marginTop: 32 }}
          onChange={onTabChange}>
          <TabPane tab="Profile" key="profile">
            <div className="firm-profile-block">
              {
                filters &&
                <>
                  <div className="firm-profile-section-top">
                    <div className="firm-profile-input" style={{ marginRight: 40 }}>
                      <img src={Images.edit} alt="" onClick={() => setActive('name')} />
                      <span>Firm name</span>
                      <Input
                        defaultValue={filters.name}
                        disabled={!profileData?.name?.active}
                        onBlur={(e) => setInactive('name', e)}
                      />
                    </div>
                    <div className="firm-profile-input">
                      <img src={Images.edit} alt="" onClick={() => setActive('website')} />
                      <span style={{ color: '#232D53' }}>Website</span>
                      <Input
                        defaultValue={filters.website}
                        disabled={!profileData?.website?.active}
                        onBlur={(e) => setInactive('website', e)}
                      />
                    </div>
                  </div>

                  <div className="firm-profile-title">
                    <span>Firm Overview</span>
                    <img src={Images.edit} alt="" onClick={() => setActive('overview')} />
                  </div>

                  <div className="firm-profile-area">
                    <TextArea
                      defaultValue={filters.overview}
                      autoSize={true}
                      maxLength={5000}
                      disabled={!profileData?.overview?.active}
                      onBlur={(e) => setInactive('overview', e)}
                    />
                  </div>
                  <div className="firm-profile-title">
                    <span>Investment preferences</span>
                    <img src={Images.edit} alt="" onClick={() => setActive('investment_preferences')} />
                  </div>
                  <div className="firm-profile-area">
                    <TextArea
                      defaultValue={filters.investment_preferences}
                      autoSize={true}
                      maxLength={5000}
                      disabled={!profileData?.investment_preferences?.active}
                      onBlur={(e) => setInactive('investment_preferences', e)}
                    />
                  </div>
                  <div className="firm-profile-title">
                    <span>Asset Management Style</span>
                    <img src={Images.edit} alt="" onClick={() => setActive('management_style')} />
                  </div>
                  <div className="firm-profile-area">
                    <TextArea
                      defaultValue={filters.management_style}
                      autoSize={true}
                      maxLength={5000}
                      disabled={!profileData?.management_style?.active}
                      onBlur={(e) => setInactive('management_style', e)}
                    />
                  </div>
                  <div className="firm-profile-title">
                    <span>Special provisions</span>
                    <img src={Images.edit} alt="" onClick={() => setActive('special_provisions')} />
                  </div>
                  <div className="firm-profile-area">
                    <TextArea
                      defaultValue={filters.special_provisions}
                      autoSize={true}
                      maxLength={12000}
                      disabled={!profileData?.special_provisions?.active}
                      onBlur={(e) => setInactive('special_provisions', e)}
                    />
                  </div>
                  <div className="firm-profile-input-bottom row">
                    <span>Portfolio companies</span>
                    <div>
                      <img src={Images.edit} alt="" onClick={() => setActive('portfolio')} />
                      <Input
                        defaultValue={filters.portfolio}
                        placeholder="Link to Website"
                        disabled={!profileData?.portfolio?.active}
                        onBlur={(e) => setInactive('portfolio', e)}
                      />
                    </div>
                  </div>
                  <div className="firm-profile-input-bottom row">
                    <span>Company status</span>
                    <div>
                      <img src={Images.edit} alt="" onClick={() => setActive('status')} />
                      <Select
                        defaultValue={filters.status_obj?.id + ''}
                        style={{ width: '100%' }}
                        disabled={!profileData?.status?.active}
                        onChange={changeStatus}
                      >
                        <Option key={0}>Lead</Option>
                        <Option key={1}>Client</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="firm-profile-input-bottom row">
                    <span>Headquarter</span>
                    <div>
                      <img src={Images.edit} alt="" onClick={() => setActive('headquarter')} />
                      <Select
                        defaultValue={filters.headquarter != null ? String(filters.headquarter) : ''}
                        showSearch
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        placeholder="Select headquarter"
                        disabled={!profileData?.headquarter?.active}
                        onChange={changeHeadquarter}
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {countryList}
                      </Select>
                    </div>
                  </div>
                  <div className="firm-profile-input-bottom row">
                    <span>Account manager</span>
                    <div>
                      <img src={Images.edit} alt="" onClick={() => setActive('account_manager')} />
                      <Select
                        defaultValue={filters.account_manager?.id}
                        style={{ width: '100%' }}
                        placeholder="Select manager"
                        disabled={!profileData?.account_manager?.active}
                        onChange={changeManager}
                      >
                        {adminList}
                      </Select>
                    </div>
                  </div>

                  <div className="firm-profile-input-bottom row">
                    <span>Documents</span>
                    <div className='documents-admin'>
                      <div className='documents-admin clone'>
                        {
                          srcBilateral &&
                          <div className='document-admin'>
                            <a href={srcBilateral} target='_blank' rel='noreferrer'
                              style={{ marginTop: 5, display: 'flex', alignItems: 'center', flexDirection: 'column' }}
                            >
                              <div className='document-admin-icon'>
                                <FileIcon
                                  extension={srcBilateral?.split('.')?.pop()}
                                  {...defaultStyles[srcBilateral?.split('.')?.pop()]}
                                />
                              </div>
                              <Tooltip
                                placement="bottom"
                                title={'Bilateral contract'}
                              >
                                <div className='document-admin-title'>Bilateral contract</div>
                              </Tooltip>
                            </a>
                          </div>
                        }
                        {
                          srcBilateralDocx &&
                          <div className='document-admin'>
                            <a href={srcBilateralDocx} target='_blank' rel='noreferrer'
                              style={{ marginTop: 5, display: 'flex', alignItems: 'center', flexDirection: 'column' }}
                            >
                              <div className='document-admin-icon'>
                                <FileIcon
                                  extension={srcBilateralDocx?.split('.')?.pop()}
                                  {...defaultStyles[srcBilateralDocx?.split('.')?.pop()]}
                                />
                              </div>
                              <Tooltip
                                placement="bottom"
                                title={'Bilateral contract'}
                              >
                                <div className='document-admin-title'>Bilateral contract</div>
                              </Tooltip>
                            </a>
                          </div>
                        }
                        {
                          src &&
                          <div className='document-admin'>
                            <a href={src} target='_blank' rel='noreferrer'
                              style={{ marginTop: 5, display: 'flex', alignItems: 'center', flexDirection: 'column' }}
                            >
                              <div className='document-admin-icon' >
                                <FileIcon
                                  extension={'pdf'}
                                  {...defaultStyles[`pdf`]}
                                />
                              </div>
                              <Tooltip
                                placement="bottom"
                                title={'Platform agreement'}
                              >
                                <div className='document-admin-title'>Platform agreement</div>
                              </Tooltip>
                            </a>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="firm-profile-input-bottom row">
                    <span>Bilateral contract of engagement</span>
                    <Row style={{ width: '570px'}}>
                      <Upload
                        method="get"
                        accept="application/pdf,.doc,.docx"
                        maxCount={1}
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={onChangeBilateralContract}
                      >
                        <Button
                          type='primary white'
                          className='btn-primary'
                          style={{ width: 'auto', margin: '0 12px 0 0' }}
                        >
                          Upload
                        </Button>
                      </Upload>
                      <a
                        href={srcBilateral}
                        target='_blank'
                        rel='noreferrer'
                        download
                      >
                        <Button
                          type='primary white'
                          className='btn-primary'
                          style={{ width: 'auto', margin: '0 12px 0 0' }}
                        >
                          Download
                          <div style={{ width: 14, marginLeft: 4}}>
                            <FileIcon
                              extension={'pdf'}
                              {...defaultStyles['pdf']}
                            />
                          </div>
                        </Button>
                      </a>
                      {srcBilateralDocx &&
                      <a
                        href={srcBilateralDocx}
                        target='_blank'
                        rel='noreferrer'
                        download
                      >
                        <Button
                          type='primary white'
                          className='btn-primary'
                          style={{ width: 'auto' }}
                        >
                          Download
                          <div style={{ width: 14, marginLeft: 4}}>
                            <FileIcon
                              extension={'docx'}
                              {...defaultStyles['docx']}
                            />
                          </div>
                        </Button>
                      </a>}
                    </Row>
                  </div>
                  <div className="firm-profile-input-bottom row">
                    <span>Platform agreement</span>
                    <Row>
                      <Upload
                        method="get"
                        accept="application/pdf"
                        maxCount={1}
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={onChangePlatformAgreement}
                      >
                        <Button
                          type='primary white'
                          className='btn-primary'
                          style={{ width: 'auto', margin: '0 12px 0 0' }}
                        >
                          Upload
                        </Button>
                      </Upload>
                      <a
                        href={src}
                        target='_blank'
                        rel='noreferrer'
                        download
                      >
                        <Button
                          type='primary white'
                          className='btn-primary'
                          style={{ width: 'auto', margin: '0' }}
                        >
                          Download
                        </Button>
                      </a>
                    </Row>
                  </div>
                </>
              }
            </div>
          </TabPane>
          <TabPane tab="Matching filters" key="filters">
            <div className="firm-profile-block">
              <div className="firm-filter-section">
                <div className="firm-filter-header flex-center-vertical">
                  <img src={Images.coins} alt="" />
                  <span>Investment focus</span>
                  <img src={Images.edit} alt="" onClick={() => handleNavigate(`/home/investor-firm/filters-edit/${firmId}`)} />
                </div>

                <div className="firm-filter-content">
                  <div className="investment-range">
                    <div className="investment-range-title">Enterprise value</div>
                    <div className="investment-range-data">
                      <span>Range (ml)</span>
                      <div className="investment-range-label">From</div>
                      <Input value={filters?.investment_focus?.enterprise_value_from} disabled={true} />

                      <div className="investment-range-label">To</div>
                      <Input value={filters?.investment_focus?.enterprise_value_to} disabled={true} />
                      <span>[in £]</span>
                    </div>
                  </div>
                  <div className="investment-range">
                    <div className="investment-range-title">Equity ticket</div>
                    <div className="investment-range-data">
                      <span>Range (ml)</span>
                      <div className="investment-range-label">From</div>
                      <Input value={filters?.investment_focus?.equity_investment_from} disabled={true} />

                      <div className="investment-range-label">To</div>
                      <Input value={filters?.investment_focus?.equity_investment_to} disabled={true} />
                      <span>[in £]</span>
                    </div>
                  </div>
                  <div className="investment-range">
                    <div className="investment-range-title">Equity stake</div>
                    <div className="investment-range-data">
                      <span className="percent-span">Range (%)</span>
                      <div className="investment-range-label">From</div>
                      <Input value={filters?.investment_focus?.equity_stake_from} disabled={true} />

                      <div className="investment-range-label">To</div>
                      <Input value={filters?.investment_focus?.equity_stake_to} disabled={true} />
                    </div>
                  </div>

                  <div className="investment-range base" style={{ alignItems: 'center', paddingBottom: 16 }}>
                    <div className="investment-range-title">Governance of the targeted equity</div>
                    <div className="investment-range-value flex-center-vertical">
                      <span>{filters?.investment_focus?.governace_of_the_targeted_equity.map(el => el.name).join(', ')}</span>
                      {
                        filters?.investment_focus?.specified_governace &&
                          filters?.investment_focus?.governace_of_the_targeted_equity?.length ?
                          <span>{`, ${filters?.investment_focus?.specified_governace}`}</span>
                          :
                          <span>{filters?.investment_focus?.specified_governace}</span>
                      }
                    </div>
                  </div>

                  <div className="investment-range base" style={{ alignItems: 'center' }}>
                    <div className="investment-range-title">Type of investment</div>
                    <div className="investment-range-value flex-center-vertical">
                      <span>{filters?.investment_focus?.investment_type.filter(el => el.id !== 3).map(el => el.name).join(', ')}</span>
                      {
                        filters?.investment_focus?.comment &&
                          filters?.investment_focus?.investment_type?.length > 1 ?
                          <span>{`, ${filters?.investment_focus?.comment}`}</span>
                          :
                          <span>{filters?.investment_focus?.comment}</span>
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="firm-filter-section">
                <div className="firm-filter-header flex-center-vertical">
                  <img src={Images.puzzle} alt="" />
                  <span>Sector Focus</span>
                  <img src={Images.edit} alt="" onClick={() => handleNavigate(`/home/investor-firm/filters-edit/${firmId}`)} />
                </div>
                <div className="firm-filter-content" style={{ paddingLeft: 40 }}>
                  <div className="firm-filter-line flex-center-vertical">
                    <div className="firm-filter-left">
                      <span className="flex-center-vertical">Sectors</span>
                    </div>
                    <div className="firm-filter-right">
                      <span className="flex-center-vertical">Sub Sectors</span>
                    </div>
                  </div>
                  {
                    filters?.industry_focus_sub_sectors.map(sector => (
                      <Fragment key={sector.id}>
                        <div className="firm-filter-line flex-center-vertical">
                          <div className="firm-filter-left">
                            <div className="firm-filter-item-name flex-center-vertical">{sector.name}</div>
                          </div>
                          <div className="firm-filter-right">
                            <div className="firm-filter-item-value flex-center-vertical">
                              <span>{sector.children.map(el => el.name).join(', ')}</span>
                            </div>
                          </div>
                        </div>
                        {
                          sector.additional_specification &&
                          <div className="firm-filter-line flex-center-vertical">
                            <div className="firm-filter-left">
                              <div className="firm-filter-add-spec flex-center-vertical">Additional specifications:</div>
                            </div>
                            <div className="firm-filter-right">
                              <div className="firm-filter-add-spec-value flex-center-vertical">{sector.additional_specification}</div>
                            </div>
                          </div>
                        }
                      </Fragment>
                    ))
                  }
                </div>
              </div>

              <div className="firm-filter-section">
                <div className="firm-filter-header flex-center-vertical">
                  <img src={Images.globe} alt="" />
                  <span>Geographic Focus</span>
                  <img src={Images.edit} alt="" onClick={() => handleNavigate(`/home/investor-firm/filters-edit/${firmId}`)} />
                </div>
                <div className="firm-filter-content" style={{ paddingLeft: 40 }}>
                  <div className="firm-filter-line flex-center-vertical">
                    <div className="firm-filter-left">
                      <span className="flex-center-vertical">Continents</span>
                    </div>
                    <div className="firm-filter-right">
                      <span className="flex-center-vertical">Country</span>
                    </div>
                  </div>
                  {
                    filters?.continents.map(continent => (
                      <div className="firm-filter-line flex-center-vertical" key={continent.id}>
                        <div className="firm-filter-left">
                          <div className="firm-filter-item-name flex-center-vertical">{continent.name}</div>
                        </div>
                        <div className="firm-filter-right">
                          <div className="firm-filter-item-value flex-center-vertical">
                            <span>{continent.children.map(el => el.name).join(', ')}</span>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Users" key="users">
            <InvestorsTable
              data={users.list}
              page={users.page_firm}
              fetching={users.fetching}
              pageSize={users.page_size_firm}
              onChangePage={handleChangePage}
            />
          </TabPane>
        </Tabs>
      </Spin>

      <Modal
        centered={true}
        closable={false}
        destroyOnClose={true}
        width={500}
        visible={deleteVisible}
        onOk={deleteFirm}
        okText="Delete"
        okButtonProps={deleteButtonProps}
        onCancel={() => setDeleteVisible(false)}
        cancelButtonProps={cancelButtonProps}
      >
        <div className="delete-title">
          <span>Delete this firm?</span>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    filters: state.settings.firmFilters,
    saveResult: state.settings.saveResult,
    saveFetching: state.settings.saveFetching,
    bilateralUpdateResult: state.settings.bilateralUpdateResult,
    countries: state.settings.countries,
    deleteResult: state.firms.deleteResult,
    users: state.users,
    result: state.settings.result,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getFirmFilters: (firmId) => dispatch(SettingsActions.firmFiltersRequest(firmId)),
  updateFirmFilters: (firmId, data) => dispatch(SettingsActions.firmFiltersSaveRequest(firmId, data)),
  updateBilateralContract: (firmId, data) => dispatch(SettingsActions.bilateralContractSaveRequest(firmId, data)),
  getCountries: () => dispatch(SettingsActions.countriesRequest()),
  resetData: () => dispatch(SettingsActions.settingsReset()),

  getUsers: (role, page, page_size, sort_field, sort_order, search, filter, forFirm) => dispatch(UsersActions.usersRequest(role, page, page_size, sort_field, sort_order, search, filter, forFirm)),

  deleteFirm: (id) => dispatch(FirmsActions.firmDeleteRequest(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FirmDetailsPage)
