import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import { Button } from 'antd'
import {useHistory} from 'react-router-dom'

import ExpertConsultActions from '../../../Redux/ExpertConsultRedux'
import DealDashboardActions from '../../../Redux/DealDashboardRedux'

import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function HoursSubmittedApprovedPopupPage(props) {
  const {result, match, location} = props;
  const {dashboardId} = match.params;
  const {state} = location;

	const history = useHistory();

  const data = {
    is_viewed: true
  };

  useEffect(()=> {
    if(result) {
      props.getDealDashboardInfo(dashboardId);
      props.getExpertConsultInfo(dashboardId);
      history.push({ pathname: `/deal-dashboard/overview/${dashboardId}`})
    }
  }, [result])

  const onContinue = () => {
    if (state?.isHistory) {
      history.push(`/deal-dashboard/overview/${dashboardId}`);
    } else {
      props.notificationViewedRequest(state?.notification, dashboardId, data);
    }
  };

  return (
    <PopupWrapper
      width={420}
      path={`/deal-dashboard/overview/${dashboardId}`}
      hasDropdown={true}
    >
      <div className="dialog-content" style={{ padding: 30, paddingTop: 20, paddingBottom: 30}}>
        <img src={Images.phoneOk} alt="" />
        <div className="dialog-title bold" style={{ margin: 6, textAlign: 'center' }}>Days submitted <br/> approved</div>

        <div className="btn-footer" style={{marginTop: 12}}>
        <Button type="primary white"
        className="btn-primary flat"
        style={{ width: 144, marginTop: 30, background: '@blue_light', border: '1px solid black' }}
        onClick={onContinue}
        >
          {state?.isHistory ? 'Go back' : 'Continue'}
        </Button>
        </div>
      </div>
    </PopupWrapper>
  )
}

const mapStateToProps = state => {
  return {
    dashboardInfo: state.dealDashboard.dashboardInfo,
    result: state.expertConsult.result,
  }
}

const mapDispatchToProps = dispatch => ({
	notificationViewedRequest: (notifId, dashboardId, data) => dispatch(ExpertConsultActions.notificationViewedRequest(notifId, dashboardId, data)),
  getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
  getExpertConsultInfo: dashboardId => dispatch(ExpertConsultActions.expertConsultingRequest(dashboardId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HoursSubmittedApprovedPopupPage)
