import React, { useState, useEffect } from 'react'
import { Progress, Button, Input, Space, InputNumber, message } from 'antd'
import { useHistory, Link } from 'react-router-dom'
import moment from 'moment'

import Images from '../../Images'

const { TextArea } = Input;

function DealSharingProfile(props) {
  const { dealId, user, experience, targetId, state } = props;

  const [data, setData] = useState(null);

  const history = useHistory();

  useEffect(() => {
    props.getExperience(user.id);
  }, [0]);

  useEffect(() => {
    if (state?.step_4_submited) {
      targetId ?
        props.reshareDealByAdvisor(dealId)
      :
        props.shareDealByAdvisor(dealId);
    }
  }, [state?.step_4_submited]);

  useEffect(() => {
    if (experience) {
      setData(experience);
    }
  }, [experience]);

  function setValue(field, e) {
    let newData = { ...data };
    newData[field] = e.target.value;
    setData(newData);
    const value = field === 'day_rate_for_advisory_work' ? Number(e.target.value) : e.target.value;
    props.updateExperience(user.id, { [field]: value })
  }

  function checkRequired() {
    if (
        data.day_rate_for_advisory_work != null &&
        data.executive_summary &&
        data.deal_origination_experience &&
        data.private_equity_experience &&
        data.jobs.length
      ) {
         if (!user?.signed_platform_agreement) {
            history.push(`/home/deal-sharing/step/profile/platform-agreement-info`)
          } else {
            targetId ?
              props.reshareDealByAdvisor(dealId)
            :
              props.shareDealByAdvisor(dealId);
          }
    } else {
      if (!data?.jobs?.length) {
        message.error('Add at least one job in work experience', 3);
      } else {
        message.error('All fields must be filled in to proceed', 3);
      }
    }
  }

  return (
    <div className="sign-up-step white filters-edit">
      <img src={Images.logo} alt="" onClick={()=>history.push('/home/deals')} />
      <div className="step-investor-content">
        <div className="step-title">NEW DEAL SHARING</div>
        <div className="step-number">Your Profile</div>
        <Progress
          className="progress-bar"
          percent={100}
          showInfo={false}
          strokeWidth={13}
          trailColor="rgba(31, 73, 228, 0.12)"
          strokeColor="#3680F6"
          status="active"
        />
        <div className="step-text">Please review carefully your profile information. Only your "Experience - Summary", "Experience - Deal origination" and "Experience - Working with Private Equity" will be shown to the Investor, keeping your identity anonymous.</div>
      </div>
      {
        data &&
        <div className="review-block">
          <div className="review-title">Advisor's profile</div>
          <div className="overview-title">
            <div>Experience - Summary</div>
          </div>
          <div className="area-text exp">
            <TextArea
              defaultValue={data.executive_summary}
              maxLength={5000}
              autoSize={true}
              onBlur={(e) => setValue('executive_summary', e)}
            />
          </div>
          <div className="overview-title">
            <div>Experience - Deal origination</div>
          </div>
          <div className="area-text exp">
            <TextArea
              defaultValue={data.deal_origination_experience}
              maxLength={5000}
              autoSize={true}
              onBlur={(e) => setValue('deal_origination_experience', e)}
            />
          </div>
          <div className="overview-title">
            <div>Experience - Working with Private Equity</div>
          </div>
          <div className="area-text exp">
            <TextArea
              defaultValue={data.private_equity_experience}
              maxLength={5000}
              autoSize={true}
              onBlur={(e) => setValue('private_equity_experience', e)}
            />
          </div>

          <div className="work-exp-block">
            <div className="experience-list-block">
              <div className="overview-title" style={{marginBottom: 24}}>
                <div>Work Experience</div>
              </div>
              <div className="experience-list">
                <div className="list-header">
                  <div>Date</div>
                  <div>Position</div>
                  <div>Company</div>
                </div>
                {
                  data.jobs?.map(job => (
                    <div className="list-item" key={job.id}>
                      <img className="remove-item" src={Images.close} alt="" onClick={() => props.deleteJob(user.id, job.id)} />
                      <div className="no-wrap">{`${moment(job.start_date).format('MM/YYYY')} - ${job.end_date ? moment(job.end_date).format('MM/YYYY') : 'current'}`}</div>
                      <div>{job.position}</div>
                      <div>{job.company}</div>
                    </div>
                  ))
                }
              </div>
              <div className="add-another-one" onClick={() => history.push({pathname: '/home/deal-sharing/step/profile/add-work', state: true})}>
                <img src={Images.addressBookSm} alt="" />
                <span>Add another one</span>
              </div>
            </div>
            <div className="vertical-line"></div>
            <div className="day-rate-block">
              <div className="day-rate">
                <div className="experience-title">
                  <span>Day rate</span>
                </div>
                <div className="flex-center-vertical">
                  <InputNumber
                    defaultValue={data.day_rate_for_advisory_work}
                    min={0}
                    max={99999}
                    parser={value => Number(value).toFixed()}
                    onBlur={(e) => setValue('day_rate_for_advisory_work', e)}
                  />
                  <span className="rate-currency">[in £]</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      <Button type="primary" className="btn-primary" style={{ width: 217, margin: '0 32px' }} onClick={checkRequired}>
        Submit
        </Button>

      <Space className="return-link" style={{ marginTop: 32 }}>
        <Link to="/home/deals">
          <img src={Images.arrowLeft} alt="" />
          <span>Return to main dashboard</span>
        </Link>
      </Space>
    </div>
  )
}

export default DealSharingProfile
