import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Button, Upload, message, Spin} from 'antd';
import {useHistory} from 'react-router-dom';

import ExpertConsultActions from '../../../Redux/ExpertConsultRedux';
import DealDashboardActions from '../../../Redux/DealDashboardRedux';

import PopupWrapper from '../PopupWrapper';
import Images from '../../../Images';
import '../PopupPage.less';

function UploadSignedAgreementPopupPage(props) {
	const {isAdmin, expertConsultInfo, result, fetching, match} = props;
	const {dashboardId} = match.params;

	const [fileUid, setFileUid] = useState(null);
	const [fileData, setFileData] = useState(null);
	const [consultId, setConsultId] = useState(null);

	const history = useHistory();

	useEffect(() => {
		props.dealDashboardSyncToggle(false);
		return function cleanup() {
			props.dealDashboardSyncToggle(true);
		};
	}, [0]);

	useEffect(() => {
		if (expertConsultInfo) {
			setConsultId(expertConsultInfo.id);
		}
	}, [expertConsultInfo]);

	useEffect(() => {
		if (result) {
			isAdmin ?
				history.push(`/home/match-company/${dashboardId}/admin-edit-options`)
			:
				history.push(`/deal-dashboard/overview/${dashboardId}/advisor-agreement-signed`);
		}
	}, [result]);

	function onChange({file, fileList}) {
		if (fileUid && fileUid === file.uid) {
			return;
		}
		setFileUid(file.uid);

		let formData = new FormData();

		if (fileList && fileList.length) {
			formData.set('file', fileList[0].originFileObj, fileList[0].originFileObj.name);
		}
		setFileData(formData);
	}

	const checkRequired = () => {
		if (!fileData) {
			message.error('Upload the file', 3);
		} else {
			props.uploadAgreementByAdvisor(consultId, fileData);
		}
	};

	return (
		<PopupWrapper
			width={420}
			path={!isAdmin ?
					`/deal-dashboard/overview/${dashboardId}`
				:
					`/home/match-company/${dashboardId}`
			}
			hasDropdown={true}
		>
			<Spin spinning={fetching} size="large">
				<div className='dialog-content' style={{padding: 30, paddingTop: 20, paddingBottom: 30}}>
					<img src={Images.phoneOk} alt='' />
					<div className='dialog-title bold' style={{margin: 6, textAlign: 'center'}}>
						Upload Signed Agreement
					</div>
					<div className='deal-text' style={{marginTop: 14}}>
						To be engage by the Investor for this <br /> consulting work, please sign and upload <br /> the Consulting Agreement, which will
						be <br /> sent to the Investor
						<Upload
							method='get'
							maxCount={1}
							onChange={onChange}
							className='upload-block'
							beforeUpload={file =>
								new Promise((resolve, reject) => {
									if (file.size > 20971520) {
										message.error('File size must not exceed 20 Mbyte', 3);
										reject();
									} else {
										resolve();
									}
								})
							}
						>
							<Button type='primary white' className='btn-primary upload' style={{width: 62, borderRadius: 5, margin: 6}}>
								<img src={Images.upload} alt='' style={{height: 20}} />
							</Button>
						</Upload>
						<br />
						<br />
						<div className='deal-text' style={{fontSize: 10, marginTop: -16}}>
							Please review IDN's Platform Agreement to remember your <br /> rights and obligations in the context of consulting work
						</div>
					</div>
					<div className='btn-footer' style={{marginTop: 12}}>
						<Button type='primary white' className='btn-primary flat' style={{width: 144, marginTop: 10}} onClick={checkRequired}>
							Continue
						</Button>
					</div>
				</div>
			</Spin>
		</PopupWrapper>
	);
}

const mapStateToProps = state => {
	return {
		isAdmin: state.auth.isAdmin,
		expertConsultInfo: state.expertConsult.expertConsultInfo,
		result: state.expertConsult.result,
		fetching: state.expertConsult.fetching_,
	};
};

const mapDispatchToProps = dispatch => ({
	uploadAgreementByAdvisor: (consultId, data) => dispatch(ExpertConsultActions.advisorUploadAgreementRequest(consultId, data)),
	getDealDashboardInfo: dashboardId => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
	dealDashboardSyncToggle: syncIsStart => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadSignedAgreementPopupPage);
