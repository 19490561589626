import { call, put, delay, take, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import DealSharesActions, {DealSharesTypes, DealSharesSelectors} from '../Redux/DealSharesRedux'
import AuthActions, {AuthSelectors} from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'
import NotificationsActions from '../Redux/NotificationsRedux'

export function * getDealShares(api, authApi, { page, page_size, sort_field, sort_order, filter }) {
  try {
    const response = yield call(api.getDealShares, page, page_size, sort_field, sort_order, filter)
    if (response && response.ok) {
      yield put(DealSharesActions.dealSharesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getDealShares(api, authApi, { page, page_size, sort_field, sort_order, filter })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getDealMatchInfo(api, authApi, { shareId, filter }) {
  try {
    const response = yield call(api.getDealMatchInfo, shareId, filter)
    if (response && response.ok) {
      yield put(DealSharesActions.dealShareMatchSuccess(response.data))
      yield put(DealSharesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getDealMatchInfo(api, authApi, { shareId, filter })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * changeDealShareStatus(api, authApi, { shareId, status }) {
  try {
    const response = yield call(api.changeDealShareStatus, shareId, status)
    if (response && response.ok) {
      yield put(DealSharesActions.dealShareChangeStatusSuccess(response.data))
      yield put(DealSharesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * changeDealShareStatus(api, authApi, { shareId, status })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * deleteDealSharesNotification(api, authApi, { shareId, data }) {
  try {
    const response = yield call(api.deleteDealSharesNotification, shareId, data)
    if (response && response.ok) {
      yield put(DealSharesActions.dealSharesNotificationDeleteSuccess(response.data))
      yield put(DealSharesActions.resultsReset())
      const role = yield select(AuthSelectors.getRole);
      yield put(NotificationsActions.notificationsRequest())
      if (role === 0) {
        yield put(NotificationsActions.advisorDealGroupNotificationsRequest())
      } else if (role === 2) {
        yield put(NotificationsActions.investorDealGroupNotificationsRequest())
      }
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * deleteDealSharesNotification(api, authApi, { shareId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * updateDealShareData(api, authApi, { shareId, data }) {
  try {
    const response = yield call(api.updateDealShareData, shareId, data)
    if (response && response.ok) {
      yield put(DealSharesActions.dealShareUpdateSuccess(response.data))
      yield put(DealSharesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * updateDealShareData(api, authApi, { shareId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * applyDealByInvestor(api, authApi, { shareId, data }) {
  try {
    const response = yield call(api.applyDealByInvestor, shareId, data)
    if (response && response.ok) {
      yield put(DealSharesActions.dealInvestorApplySuccess(response.data))
      yield put(DealSharesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * applyDealByInvestor(api, authApi, { shareId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * reapplyDealByInvestor(api, authApi, { shareId }) {
  try {
    const response = yield call(api.reapplyDealByInvestor, shareId)
    if (response && response.ok) {
      yield put(DealSharesActions.dealInvestorReapplySuccess(response.data))
      yield put(DealSharesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * reapplyDealByInvestor(api, authApi, { shareId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * shareDealWithColleagues(api, authApi, { shareId, data }) {
  try {
    const response = yield call(api.shareDealWithColleagues, shareId, data)
    if (response && response.ok) {
      yield put(DealSharesActions.dealInvestorSendColleaguesSuccess(response.data))
      yield put(DealSharesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * shareDealWithColleagues(api, authApi, { shareId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getInvestorStats(api, authApi) {
  try {
    const response = yield call(api.getInvestorStats)
    if (response && response.ok) {
      yield put(DealSharesActions.dealSharesInvestorStatsSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getInvestorStats(api, authApi)
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getFirmDeals(api, authApi, { page, page_size }) {
  try {
    const response = yield call(api.getFirmDeals, page, page_size)
    if (response && response.ok) {
      yield put(DealSharesActions.firmDealsSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getFirmDeals(api, authApi, { page, page_size })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getFirmDeal(api, authApi, { shareId }) {
  try {
    const response = yield call(api.getFirmDeal, shareId)
    if (response && response.ok) {
      yield put(DealSharesActions.firmDealSuccess(response.data))
      yield put(DealSharesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getFirmDeal(api, authApi, { shareId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getFirmStats(api, authApi) {
  try {
    const response = yield call(api.getFirmStats)
    if (response && response.ok) {
      yield put(DealSharesActions.firmStatsSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * getFirmStats(api, authApi)
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * withdrawApplication(api, authApi, { shareId, data }) {
  try {
    const response = yield call(api.withdrawApplication, shareId, data)
    if (response && response.ok) {
      yield put(DealSharesActions.applicationWithdrawSuccess(response.data))
      yield put(DealSharesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * withdrawApplication(api, authApi, { shareId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * rejectDealByInvestor(api, authApi, { shareId }) {
  try {
    const response = yield call(api.rejectDealByInvestor, shareId)
    if (response && response.ok) {
      yield put(DealSharesActions.dealInvestorRejectSuccess(response.data))
      yield put(DealSharesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * rejectDealByInvestor(api, authApi, { shareId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * createMatchAnswer(api, authApi, { data }) {
  try {
    const response = yield call(api.createMatchAnswer, data)
    if (response && response.ok) {
      yield put(DealSharesActions.matchAnswerSaveSuccess(response.data))
      yield put(DealSharesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * createMatchAnswer(api, authApi, { data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * updateMatchAnswer(api, authApi, { answerId, data }) {
  try {
    const response = yield call(api.updateMatchAnswer, answerId, data)
    if (response && response.ok) {
      yield put(DealSharesActions.matchAnswerUpdateSuccess(response.data))
      yield put(DealSharesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * updateMatchAnswer(api, authApi, { answerId, data })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * keepMatchUnderReview(api, authApi, { shareId }) {
  try {
    const response = yield call(api.keepMatchUnderReview, shareId)
    if (response && response.ok) {
      yield put(DealSharesActions.advisorKeepMatchReviewSuccess(response.data))
      yield put(DealSharesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * keepMatchUnderReview(api, authApi, { shareId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * updateSeenByInvestor(api, authApi, { shareId }) {
  try {
    const response = yield call(api.updateSeenByInvestor, shareId)
    if (response && response.ok) {
      yield put(DealSharesActions.seenByInvestorSuccess(response.data))
      yield put(DealSharesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * updateSeenByInvestor(api, authApi, { shareId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * confirmMatchByAdvisor(api, authApi, { shareId }) {
  try {
    const response = yield call(api.confirmMatchByAdvisor, shareId)
    if (response && response.ok) {
      yield put(DealSharesActions.advisorMatchConfirmSuccess(response.data))
      yield put(DealSharesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * confirmMatchByAdvisor(api, authApi, { shareId })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * checkConfirmMatchHash(api, authApi, { hash }) {
  try {
    const response = yield call(api.checkConfirmMatchHash, hash)
    if (response && response.ok) {
      yield put(DealSharesActions.matchConfirmHashSuccess(response.data))
      yield put(DealSharesActions.resultsReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(DealSharesActions.dealSharesFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield * checkConfirmMatchHash(api, authApi, { hash })
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(DealSharesActions.dealSharesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * dealSharesSyncStartMonitor() {
  while (true) {
    const payload = yield take(DealSharesTypes.DEAL_SHARES_SYNC_TOGGLE);
    let doWork = payload.syncIsStart;
    while (doWork) {
      const {page, page_size, sort_field, sort_order, filter, syncIsStart} = yield select(DealSharesSelectors.getState);
      if (!syncIsStart) {
        break;
      }
      yield put(DealSharesActions.dealSharesRequest(page, page_size, sort_field, sort_order, filter));
      doWork = syncIsStart;
      yield delay(30000);
    }
  }
}
