import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { Input } from 'antd';

import DealDashboardActions from '../../../Redux/DealDashboardRedux'
import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function UltimatumRecievedPopupPage(props) {
  const { result, match, location } = props;
	const { dashboardId } = match.params
	const { state } = location;

  const history = useHistory();

  useEffect(() => {
		props.dealDashboardSyncToggle(false);
		return function cleanup() {
			props.dealDashboardSyncToggle(true);
		}
	}, [0]);

	useEffect(() => {
		if (result) {
			props.getDealDashboardInfo(dashboardId);
			history.push(`/deal-dashboard/overview/${dashboardId}`);
		}
	}, [result]);

  const removeNotification = () => {
    if (state?.isHistory) {
      history.push(`/deal-dashboard/overview/${dashboardId}`);
    } else {
      props.changeNotificationStatus(state?.notification)
    }
  }

  return (
    <PopupWrapper
      width={460}
      path={`/deal-dashboard/overview/${dashboardId}`}
    >
      <div className="dialog-content" style={{ padding: '20px 32px 30px'}}>
        <img src={Images.phoneCancel} alt="" />
        <div className="dialog-title welcome" style={{ margin: 8 }}>Ultimatum Received</div>
        <div className="deal-text engagment" style={{ marginTop: 6 }}>The Senior Advisor has submitted an ultimatum. You have ten business days to either:</div>
        <div className="textarea">
          <div className="choosefield">
            <b>a)</b> Reject the ultimatum and terminate the engagement
          </div>
          <div className="choosefield">
            <b>b)</b> Accept the ultimatum (Deal Reserve fee applies)
          </div>
          <div className="choosefield">
            <b>c)</b> Submit a Non-Binding offer (NBO)
          </div>
        </div>
        <br />
				<div className="deal-text sm" style={{width: '90%'}}>Please review IDN's Platform Agreement to remember your<br />rights and obligations in the context of this engagement</div>
        <div className="btn-footer">
          <Button type="primary white"
            className="btn-primary welcome"
            style={{ width: 144 }}
            onClick={removeNotification}
          >
            {state?.isHistory ? 'Go back' : 'Confirm'}
          </Button>
        </div>
      </div>
    </PopupWrapper>
  )
}

const mapStateToProps = (state) => {
	return {
		result: state.dealDashboard.result,
		fetching: state.dealDashboard.fetching
	}
}

const mapDispatchToProps = (dispatch) => ({
	getDealDashboardInfo: (dashboardId) => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
	changeNotificationStatus: (notificationId) => dispatch(DealDashboardActions.notificationChangeStatusRequest(notificationId)),
	dealDashboardSyncToggle: (syncIsStart) => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UltimatumRecievedPopupPage)
