import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  mailTemplatesRequest: null,
  mailTemplatesSuccess: ['list'],

  mailTemplateRequest: ['templateId'],
  mailTemplateSuccess: ['mailTemplateData'],

  mailTemplateSaveRequest: ['templateId', 'data'],
  mailTemplateSaveSuccess: ['saveResult'],
  mailTemplateSaveReset: null,

  mailTemplatesReset: null,

  mailTemplateFetchingReset: null
})

export const MailTemplatesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  fetching: false,

  mailTemplateData: null,

  saveResult: null,
  saveFetching: false,

})

/* ------------- Selectors ------------- */
export const MailTemplatesSelectors = {
  getState: state => state.mailTemplates,
}

/* ------------- Reducers ------------- */


export const mailTemplatesRequest = (state) => {
  return state.merge({ fetching: false })
}

export const mailTemplatesSuccess = (state, { list }) => {
  return state.merge({ fetching: false, list })
}

export const mailTemplateRequest = (state, { templateId }) => {
  return state
}

export const mailTemplateSuccess = (state, { mailTemplateData }) => {
  return state.merge({ mailTemplateData })
}

export const mailTemplateSaveRequest = (state, { templateId, data }) => {
  return state.merge({ saveFetching: true})
}

export const mailTemplateSaveSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const mailTemplateSaveReset = (state) =>{
  return state.merge({ saveFetching: false,  saveResult:null })
}

export const mailTemplatesReset = (state) =>{
  return INITIAL_STATE
}

export const mailTemplateFetchingReset = (state) => {
  return state.merge({ fetching:false, saveFetching:false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.MAIL_TEMPLATES_REQUEST]: mailTemplatesRequest,
  [Types.MAIL_TEMPLATES_SUCCESS]: mailTemplatesSuccess,

  [Types.MAIL_TEMPLATE_REQUEST]: mailTemplateRequest,
  [Types.MAIL_TEMPLATE_SUCCESS]: mailTemplateSuccess,

  [Types.MAIL_TEMPLATE_SAVE_REQUEST]: mailTemplateSaveRequest,
  [Types.MAIL_TEMPLATE_SAVE_SUCCESS]: mailTemplateSaveSuccess,
  [Types.MAIL_TEMPLATE_SAVE_RESET]: mailTemplateSaveReset,

  [Types.MAIL_TEMPLATES_RESET]: mailTemplatesReset,

  [Types.MAIL_TEMPLATE_FETCHING_RESET]: mailTemplateFetchingReset,
})
