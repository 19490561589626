import React from 'react'
import { Button } from 'antd'
import {useHistory} from 'react-router-dom'

import PopupWrapper from '../PopupWrapper'
import Images from '../../../Images'
import '../PopupPage.less'

function AdvisorAgreementExtensionSignedPopupPage(props) {
  const {dashboardId} = props.match.params

	const history = useHistory()

	const onContinue = () => {
		history.push({pathname: `/deal-dashboard/overview/${dashboardId}`})
	}

  return (
    <PopupWrapper
      width={300}
      path={`/deal-dashboard/overview/${dashboardId}`}
      hasDropdown={true}
    >
      <div className="dialog-content" style={{ padding: 20, paddingTop: 30, paddingBottom: 30}}>
        <img src={Images.phoneO} alt="" />
        <div className="dialog-title bold" style={{ margin: 6, textAlign: 'center' }}>Agreement Extension signed</div>
        <div className="btn-footer" style={{marginTop: 40}}>
        <Button type="primary white"
        className="btn-primary flat"
        style={{ width: 144 }}
        onClick={onContinue}>
          Continue
        </Button>
        </div>
      </div>
    </PopupWrapper>
  )
}

export default AdvisorAgreementExtensionSignedPopupPage
