import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {Collapse, Button, InputNumber, Input, message, Space, Radio, DatePicker} from 'antd';
import moment from 'moment';

import ExpertConsultActions from '../../Redux/ExpertConsultRedux';
import DealsActions from '../../Redux/DealsRedux';

import CollapseHeader from '../../Components/CollapseHeader';
import Images from '../../Images';
import './ECAgreement.less';

const {Panel} = Collapse;
const {TextArea} = Input;

function ExpertConsultingAgreement(props) {
	const {expertConsultInfo, editAgreement, agreementData, dashboardInfo, dealId} = props;
	const {dashboardId} = props.match.params;
	const {state} = props.location;
	const dateFormat = 'DD/MM/YYYY';

	const [stateDashboardId, setDashboardId] = useState(dashboardId);
	const [active, setActive] = useState(false);

	const history = useHistory();

	const initialState = {
		consulting: null,
		firm_name: '',
		investor_name: '',
		investor_surname: '',
		advisor_name: '',
		advisor_surname: '',
		project_scope: '',
		tasks: '',
		timeline: null,
		timeline_start: null,
		timeline_stop: null,
		remuneration: null,
		remuneration_price: null,
		add_terms: '',
		// file: null,
	};

	useEffect(() => {
		if (!state?.ec) {
			history.push(`/deal-dashboard/overview/${dashboardId}`);
		}
	}, [0]);

	//get agreement data if it was saved
	useEffect(() => {
		props.getDataForAgreementRequest(dashboardId);
	}, [0]);

	useEffect(() => {
		dashboardInfo && props.dealRequest(dealId);
	}, [dealId]);

	const [saveAgreementData, setSaveAgreementData] = useState(initialState);

	//save agreement data from server to state if agreement was already saved
	useEffect(() => {
		if (agreementData && expertConsultInfo) {
			setSaveAgreementData({
				...saveAgreementData,
				consulting: expertConsultInfo.id,
				firm_name: agreementData[0]?.firm_name,
				investor_name: agreementData[0]?.investor_name,
				investor_surname: agreementData[0]?.investor_surname,
				advisor_name: agreementData[0]?.advisor_name,
				advisor_surname: agreementData[0]?.advisor_surname,
				project_scope: agreementData[0]?.project_scope,
				tasks: agreementData[0]?.tasks,
				timeline: agreementData[0]?.timeline_obj?.id,
				timeline_start: agreementData[0]?.timeline_start ? moment(agreementData[0]?.timeline_start).format('DD-MM-YYYY') : null,
				timeline_stop: agreementData[0]?.timeline_stop ? moment(agreementData[0]?.timeline_stop).format('DD-MM-YYYY') : null,
				remuneration: agreementData[0]?.remuneration_obj?.id,
				remuneration_price: agreementData[0]?.remuneration_price,
				add_terms: agreementData[0]?.add_terms,
			});
		}
	}, [agreementData, expertConsultInfo]);

	//for renumeration field
	const [priceOption1, setPriceOption1] = useState('');
	const [priceOption2, setPriceOption2] = useState('');
	const [priceOption3, setPriceOption3] = useState('');

	const changeActive = (field, value) => {
		const newData = {...saveAgreementData};
		newData[field] = value;
		setSaveAgreementData(newData);
	};

	useEffect(() => {
		if (saveAgreementData.remuneration === 1) {
			setPriceOption2('');
			setPriceOption3('');
		}
		if (saveAgreementData.remuneration === 2) {
			setPriceOption1('');
			setPriceOption3('');
		}
		if (saveAgreementData.remuneration === 3) {
			setPriceOption1('');
			setPriceOption2('');
		}
	}, [saveAgreementData.remuneration]);

	const setPrice = e => {
		if (e?.target?.name === 'price_option1') {
			setSaveAgreementData({...saveAgreementData, remuneration_price: e.target.value});
			setPriceOption1(e.target.value);
			setPriceOption2('');
			setPriceOption3('');
		}
		if (e?.target?.name === 'price_option2') {
			setSaveAgreementData({...saveAgreementData, remuneration_price: e.target.value});
			setPriceOption2(e.target.value);
			setPriceOption1('');
			setPriceOption3('');
		}
		if (e?.target?.name === 'price_option3') {
			setSaveAgreementData({...saveAgreementData, remuneration_price: e.target.value});
			setPriceOption3(e.target.value);
			setPriceOption1('');
			setPriceOption2('');
		}
	};

	const checksaveAgreementDataFields = () => {
		setActive(true);
		if (
			saveAgreementData.consulting &&
			saveAgreementData.firm_name &&
			saveAgreementData.investor_name &&
			saveAgreementData.investor_surname &&
			saveAgreementData.advisor_name &&
			saveAgreementData.advisor_surname &&
			saveAgreementData.project_scope &&
			saveAgreementData.tasks &&
			(saveAgreementData.timeline === 0 || saveAgreementData.timeline === 1 ? true : false) &&
			saveAgreementData.timeline_start &&
			saveAgreementData.remuneration_price &&
			saveAgreementData.add_terms &&
			(saveAgreementData.timeline === 1 ? true : saveAgreementData.timeline === 0 ? saveAgreementData.timeline_stop : false) &&
			(saveAgreementData.remuneration === 0 || saveAgreementData.remuneration === 1 || saveAgreementData.remuneration === 2 ? true : false)
		) {
			if (editAgreement && dashboardInfo?.actions?.filter(el => el.location_obj?.id === 1).map(el => el.text_obj?.id === 18)[0]) {
				if (saveAgreementData.timeline === 1) {
					props.agreementDataSaveRequest(agreementData[0].id, stateDashboardId, {
						...saveAgreementData,
						timeline_start: moment(saveAgreementData.timeline_start, 'DD/MM/YYYY').format('YYYY-MM-DD'),
						timeline_stop: null,
					});
				} else {
					props.agreementDataUpdateRequest(agreementData[0].id, stateDashboardId, {
						...saveAgreementData,
						timeline_stop: moment(saveAgreementData.timeline_stop, 'DD/MM/YYYY').format('YYYY-MM-DD'),
						timeline_start: moment(saveAgreementData.timeline_start, 'DD/MM/YYYY').format('YYYY-MM-DD'),
					});
				}
			} else {
				if (saveAgreementData.timeline === 1) {
					props.agreementDataSaveRequest(stateDashboardId, {
						...saveAgreementData,
						timeline_start: moment(saveAgreementData.timeline_start, 'DD/MM/YYYY').format('YYYY-MM-DD'),
						timeline_stop: null,
					});
				} else {
					props.agreementDataSaveRequest(stateDashboardId, {
						...saveAgreementData,
						timeline_stop: moment(saveAgreementData.timeline_stop, 'DD/MM/YYYY').format('YYYY-MM-DD'),
						timeline_start: moment(saveAgreementData.timeline_start, 'DD/MM/YYYY').format('YYYY-MM-DD'),
					});
				}
			}
		} else {
			message.error('All fields must be filled to continue', 3);
		}
	};

	return (
		<div className='sign-up-step white filters-edit'>
			<div className='absolute-header'>
				<div className='logo-block'>
					<img src={Images.logoWhite} alt='' />
				</div>
				<div className='title-block'></div>
			</div>
			<div className='return-block'>
				<Link to={`/deal-dashboard/overview/${stateDashboardId}?`}>
					<img src={Images.arrowLeft} alt='' />
					<span>Return to Dashboard</span>
				</Link>
			</div>
			<div className='step-investor-content'>
				<div className='step-number underline'>
					{dashboardInfo?.actions?.filter(el => el.location_obj?.id === 1).map(el => el.text_obj?.id === 18)[0] ||
					dashboardInfo?.actions?.filter(el => el.location_obj?.id === 1).map(el => el.text_obj?.id === 19)[0]
						? 'Expert Consulting Agreement - Extension'
						: 'Expert Consulting Agreement'}
				</div>
				<div className='step-text border' style={{paddingLeft: 80, paddingRight: 80}}>
					{dashboardInfo?.actions?.filter(el => el.location_obj?.id === 1).map(el => el.text_obj?.id === 18)[0] ? (
						<div>
							You can extend or edit the Expert Consulting Agreement by modifying the terms below. <br /> The new Expert Consulting
							Agreement Agreement will replace, in a non-retroactive way, the previous Agreement. The Advisor will have to agree to its
							terms.
						</div>
					) : (
						<div>
							In order to engage the Senior Advisor for expert consulting or related support, you have to fill in the fields below, <br />{' '}
							which will set the terms of the work with the Senior Advisor.
							<br /> <br /> Please fill in each relevant field before clicking the button "Continue", which will prompt you to the final
							version of the agreement, <br /> which can then be signed and shared with the Senior Advisor.
						</div>
					)}
				</div>
			</div>
			<Collapse
				bordered={false}
				expandIcon={({isActive}) => (isActive ? <img src={Images.angleUp} alt='' /> : <img src={Images.angleDown} alt='' />)}
				expandIconPosition='right'
				className='site-collapse-custom-collapse'
				accordion
			>
				<Panel
					header={<CollapseHeader text='Parties Details' imageType='addressBook' />}
					key='details'
					className={`site-collapse-custom-panel${
						active &&
						(!saveAgreementData.firm_name ||
							!saveAgreementData.investor_name ||
							!saveAgreementData.investor_surname ||
							!saveAgreementData.advisor_name ||
							!saveAgreementData.advisor_surname)
							? ' error-block'
							: ''
					}`}
					forceRender={true}
				>
					<div className='details'>
						<div className='details-row'>
							<span className='details-row-img'>
								<img src={Images.building1} alt='' />
							</span>
							<div className='details-row-text'>Firm</div>
							<span className='details-row-input'>
								<Input
									value={saveAgreementData?.firm_name ? saveAgreementData?.firm_name : null}
									style={{margin: 10}}
									placeholder='Full legal name of the entity engaging the Advisor'
									onChange={e => setSaveAgreementData({...saveAgreementData, firm_name: e.target.value})}
								/>
							</span>
						</div>
						<div className='details-row'>
							<span className='details-row-img'>
								<img src={Images.user} alt='' />
							</span>
							<div className='details-row-text'>Investor</div>
							<span className='details-row-input'>
								<Input
									value={saveAgreementData?.investor_name ? saveAgreementData.investor_name : null}
									style={{margin: 10}}
									placeholder='First Name'
									onChange={e => setSaveAgreementData({...saveAgreementData, investor_name: e.target.value})}
								/>
								<Input
									style={{margin: 10}}
									value={saveAgreementData?.investor_surname ? saveAgreementData.investor_surname : null}
									placeholder='Last Name'
									onChange={e => setSaveAgreementData({...saveAgreementData, investor_surname: e.target.value})}
								/>
							</span>
						</div>
						<div className='details-row'>
							<span className='details-row-img'>
								<img src={Images.user} alt='' />
							</span>
							<div className='details-row-text'>Advisor</div>
							<span className='details-row-input'>
								<Input
									style={{margin: 10, background: '#E5E9EE'}}
									value={saveAgreementData?.advisor_name ? saveAgreementData.advisor_name : null}
									placeholder='First Name'
									onChange={e => setSaveAgreementData({...saveAgreementData, advisor_name: e.target.value})}
								/>
								<Input
									style={{margin: 10, background: '#E5E9EE'}}
									value={saveAgreementData?.advisor_surname ? saveAgreementData.advisor_surname : null}
									placeholder='Last Name'
									onChange={e => setSaveAgreementData({...saveAgreementData, advisor_surname: e.target.value})}
								/>
							</span>
						</div>
					</div>
				</Panel>

				<Panel
					header={<CollapseHeader text='Project Scope' imageType='comment' />}
					key='scope'
					className={`site-collapse-custom-panel${active && !saveAgreementData.project_scope ? ' error-block' : ''}`}
					forceRender={true}
				>
					<div className='sub-title' style={{marginTop: -10}}>
						Please provide a short description of the project's for which the Senior Advisor is going to be hired for
					</div>
					<TextArea
						maxLength={500}
						value={saveAgreementData?.project_scope ? saveAgreementData.project_scope : null}
						style={{marginTop: 18, width: 1200, height: 160, borderRadius: 16}}
						placeholder='Complete the phrase - Investment firm x, represented by Mr/Ms Y, is engaging Mr/Ms Z for  {…}  [200 words max]'
						className='input-textarea'
						autoSize={{minRows: 6, maxRows: 6}}
						onChange={e => setSaveAgreementData({...saveAgreementData, project_scope: e.target.value})}
					/>
				</Panel>

				<Panel
					header={<CollapseHeader text='Tasks & Deliverables' imageType='check' />}
					key='tasks'
					className={`site-collapse-custom-panel${active && !saveAgreementData.tasks ? ' error-block' : ''}`}
					forceRender={true}
				>
					<div className='sub-title' style={{marginTop: -10}}>
						Please provide a detailed description of the tasks that the Senior Advisors will be required to perform as part of this
						Agreement (in bullet points)
					</div>
					<TextArea
						maxLength={500}
						style={{marginTop: 18, width: 1200, height: 160, borderRadius: 16}}
						value={saveAgreementData?.tasks ? saveAgreementData.tasks : null}
						placeholder='Complete the phrase - as part of this project, the advisor will be required to perform the following tasks:
            -review and analyse documents provided by the target company
            -provide written analysis and reports;
            -attend video calls and meetings with the investment team and the target company, as requested'
						className='input-textarea'
						autoSize={{minRows: 6, maxRows: 6}}
						onChange={e => setSaveAgreementData({...saveAgreementData, tasks: e.target.value})}
					/>
				</Panel>

				<Panel
					header={<CollapseHeader text='Project Timeline' imageType='clock' />}
					key='timeline'
					className={`site-collapse-custom-panel${
						active &&
						!(saveAgreementData.timeline === 1 ? true : saveAgreementData.timeline === 0 && saveAgreementData.timeline_stop ? true : false)
							? ' error-block'
							: ''
					}`}
					forceRender={true}
				>
					<div className='sub-title' style={{marginTop: -10}}>
						Please indicate the contract length. You can either set it to specific dates (expected end date extendable), or link it to the
						duration of deal or mandate engagement in the context of which the Agreement is signed. In this latter instance the Agreement
						will remain in place until the related engagement is closed.
					</div>
					<div className='greywrapper'>
						<div className='content-title'>Contract length</div>
						<Space className='collapse-checkbox-timeline'>
							<Radio.Group
								onChange={e => changeActive('timeline', e.target.value)}
								value={saveAgreementData?.timeline === 0 ? 0 : saveAgreementData?.timeline === 1 ? 1 : null}
							>
								<div className='timeline-checkboxes'>
									<Radio value={0} style={{margin: 10}}>
										<span className='timeline-checkboxes'>
											<span style={{fontWeight: 'bold'}}>Option 1</span> - set dates
										</span>
									</Radio>
									<Radio value={1} style={{margin: 10}}>
										<span className='timeline-checkboxes'>
											<span style={{fontWeight: 'bold'}}>Option 2</span> - link it to deal/mandate engagement duration
											<span className='timeline-startdate-text' style={{marginLeft: 10}}>
												&nbsp; Start Date &nbsp;
												<DatePicker
													value={
														saveAgreementData?.timeline === 1
															? saveAgreementData?.timeline_start
																? moment.utc(`${saveAgreementData?.timeline_start}`, 'DD/MM/YYYY')
																: null
															: null
													}
													format={dateFormat}
													disabled={saveAgreementData?.timeline === 0}
													style={{width: 135, height: 37}}
													onChange={(date, dateString) => setSaveAgreementData({...saveAgreementData, timeline_start: dateString})}
												/>
											</span>
										</span>
									</Radio>
								</div>
							</Radio.Group>
						</Space>
						<div className='timeline-date'>
							&nbsp; Start Date &nbsp;
							<DatePicker
								value={
									saveAgreementData?.timeline === 0
										? saveAgreementData?.timeline_start
											? moment.utc(`${saveAgreementData?.timeline_start}`, 'DD/MM/YYYY')
											: null
										: null
								}
								format={dateFormat}
								style={{width: 135, height: 37}}
								disabled={saveAgreementData?.timeline === 1}
								onChange={(date, dateString) => setSaveAgreementData({...saveAgreementData, timeline_start: dateString})}
							/>
							<div style={{width: 50}}></div>
							&nbsp; End Date &nbsp;
							<DatePicker
								value={
									saveAgreementData?.timeline === 0
										? saveAgreementData?.timeline_stop
											? moment.utc(`${saveAgreementData?.timeline_stop}`, 'DD/MM/YYYY')
											: null
										: null
								}
								format={dateFormat}
								style={{width: 135, height: 37}}
								disabled={saveAgreementData?.timeline === 1}
								onChange={(date, dateString) => setSaveAgreementData({...saveAgreementData, timeline_stop: dateString})}
							/>
						</div>
					</div>
				</Panel>

				<Panel
					header={<CollapseHeader text='Remuneration' imageType='coins' />}
					key='remuneration'
					className={`site-collapse-custom-panel${
						active &&
						((saveAgreementData?.remuneration !== 0 && saveAgreementData?.remuneration !== 1 && saveAgreementData?.remuneration !== 2) ||
							!saveAgreementData?.remuneration_price)
							? ' error-block'
							: ''
					}`}
					forceRender={true}
				>
					<div className='sub-title'>
						Please indicate the type and amount of remuneration you have agreed upon with the Advisor when discussing this Advisory
						engagement
					</div>
					<div className='greywrapper'>
						<div className='content-title'>Options</div>
						<Space className='collapse-checkbox-timeline'>
							<Radio.Group
								onChange={e => changeActive('remuneration', e.target.value)}
								value={
									saveAgreementData?.remuneration === 1
										? 1
										: saveAgreementData?.remuneration === 2
										? 2
										: saveAgreementData?.remuneration === 0
										? 0
										: null
								}
							>
								<div className='timeline-checkboxes'>
									<Radio value={0} style={{margin: 5}}>
										<span className='timeline-checkboxes'>
											Option 1<span style={{fontWeight: 'bold'}}>- day rate</span>
										</span>
									</Radio>
									<Radio value={1} style={{margin: 5}}>
										<span className='timeline-checkboxes'>
											Option 2<span style={{fontWeight: 'bold'}}>- fixed amount</span>
										</span>
									</Radio>
									<Radio value={2} style={{margin: 5}} disabled>
										<span className='timeline-checkboxes' style={{color: 'grey'}}>
											Option 3<span style={{fontWeight: 'bold'}}>- success fee</span>
										</span>
									</Radio>
								</div>
							</Radio.Group>
						</Space>
						<div className='remuneration-input-wrapper'>
							<div className='input-block'>
								<InputNumber
									name='price_option1'
									style={{margin: 2}}
									placeholder='€0'
									disabled={saveAgreementData?.remuneration !== 0}
									value={saveAgreementData?.remuneration === 0 ? saveAgreementData?.remuneration_price : priceOption1}
									maxLength='7'
									onBlur={e => setPrice(e)}
								/>
								<InputNumber
									name='price_option2'
									value={saveAgreementData?.remuneration === 1 ? saveAgreementData?.remuneration_price : priceOption2}
									style={{margin: 2}}
									placeholder='€0'
									disabled={saveAgreementData?.remuneration !== 1}
									maxLength='7'
									onBlur={e => setPrice(e)}
								/>
								<InputNumber
									value={saveAgreementData?.remuneration === 2 ? saveAgreementData?.remuneration_price : priceOption3}
									name='price_option3'
									// disabled={saveAgreementData?.remuneration !== 2}
									disabled={true}
									style={{margin: 2}}
									placeholder='€0'
									maxLength='7'
									// onBlur={e => setPrice(e)}
								/>
							</div>
							<div className='input-description-block'>
								<div className='text'>
									<span style={{fontWeight: 'bold'}}>Please note:&nbsp; </span> the day rate will then be shown in a hourly rate format on
									the dashboard to facilitate calculations (one day equal 8 working hours)
								</div>
								<div className='text'></div>
								<div className='text'>
									<span style={{fontWeight: 'bold', color: 'grey'}}>Please note:&nbsp; </span> the success fee selected here does not substitute the
									underlying success fee of IDN, where applicable
								</div>
							</div>
						</div>
					</div>
				</Panel>

				<Panel
					header={<CollapseHeader text='Additional Terms' imageType='pencil' />}
					key='addTerms'
					className={`site-collapse-custom-panel${active && !saveAgreementData.add_terms ? ' error-block' : ''}`}
					forceRender={true}
				>
					<div className='sub-title'>Please indicate any additional term you want to set herein this Advisory Agreement</div>
					<TextArea
						maxLength={500}
						style={{marginTop: 18, width: 1200, height: 160, borderRadius: 16}}
						value={saveAgreementData?.add_terms ? saveAgreementData.add_terms : null}
						className='input-textarea'
						autoSize={{minRows: 6, maxRows: 6}}
						onChange={e => setSaveAgreementData({...saveAgreementData, add_terms: e.target.value})}
					/>
				</Panel>
			</Collapse>
			<Button type='primary' className='btn-primary' style={{width: 217, marginTop: 24}} onClick={checksaveAgreementDataFields}>
				Continue
			</Button>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		expertConsultInfo: state.expertConsult.expertConsultInfo,
		editAgreement: state.expertConsult.editAgreement,
		agreementData: state.expertConsult.agreementData,
		dashboardInfo: state.dealDashboard.dashboardInfo,
		dealId: state.dealDashboard.dealId,
	};
};

const mapDispatchToProps = dispatch => ({
	agreementDataSaveRequest: (dashboardId, data) => dispatch(ExpertConsultActions.agreementDataSaveRequest(dashboardId, data)),
	getDataForAgreementRequest: dashboardId => dispatch(ExpertConsultActions.getDataForAgreementRequest(dashboardId)),
	agreementDataUpdateRequest: (agreementId, dashboardId, data) =>
		dispatch(ExpertConsultActions.agreementDataUpdateRequest(agreementId, dashboardId, data)),
	dealRequest: id => dispatch(DealsActions.dealRequest(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpertConsultingAgreement);
