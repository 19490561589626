import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

import DealsActions from '../../Redux/DealsRedux'

import PopupWrapper from './PopupWrapper';

import Images from '../../Images'
import './PopupPage.less'

function DealRejectedPopupPage(props) {

  const { deal, notificationDealResult, match, location } = props;
  const { dealId } = match.params;
  const { state } = location;

  const [start, setStart] = useState(false)

  const history = useHistory();

  useEffect(() => {
    if (notificationDealResult) {
      if (!start) {
        setStart(true);
      }
      props.getDeal(dealId);
    }
	}, [notificationDealResult])

  useEffect(() => {
    if (deal && start) {
      history.push(`/home/mandate-deal/${dealId}/reshare-mandate-deal`);
    }
  }, [deal]);

  return (
    <PopupWrapper
      width={297}
      // path={`/home/mandate-deal/`}
    >
      <div className="dialog-content" style={{ padding: '40px 14px' }}>
        <img src={Images.phoneCancel} alt="" />
        <div className="dialog-title deal">Deal rejected</div>
        <div className="deal-text" style={{ padding: '0 10px', fontSize: 13 }}>
        We are sorry to inform you that the investor has rejected your deal.
        </div>
        <div className="btn-footer" style={{ marginTop: 20 }}>
          <Button type="primary white" className="btn-primary" onClick={() => props.changeNotificationDealStatus(state?.id)}>
            Continue
          </Button>
        </div>
      </div>
    </PopupWrapper>
  )
}


const mapStateToProps = state => {
	return {
    deal: state.deals.deal,
    notificationDealResult: state.deals.notificationDealResult,
	}
}

const mapDispatchToProps = dispatch => ({
  changeNotificationDealStatus: (notificationId) => dispatch(DealsActions.notificationChangeDealStatusRequest(notificationId)),
  getDeal: (id) => dispatch(DealsActions.dealRequest(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DealRejectedPopupPage)
