import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Input } from 'antd'

import MandateFilterPanel from '../../Components/FilterPanels/MandateFilterPanel'
import MandatePipelineTable from '../../Components/Tables/MandatePipelineTable'
import MandatesActions from '../../Redux/MandatesRedux'
import DealsActions from '../../Redux/DealsRedux'
import SettingsActions from '../../Redux/SettingsRedux'
import Images from '../../Images'

const { Search } = Input;

function MandatePipelinePage(props) {
  const { mandates, sectorFocus, geographyFocus, investmentTypes } = props;

  const [active, setActive] = useState(false);
  const [count, setCount] = useState(null);

  useEffect(() => {
    props.setDealId(null);
    props.setMandateId(null);
    props.getMandates(mandates.page, mandates.page_size, mandates.sort_field, mandates.sort_order, mandates.search, {...mandates.filter, group: 'all'});
    props.getSectors();
    props.getContinents();
    props.getInvestmentTypes();
    props.getMandateStatuses();
    props.getDealSize();
  }, [0]);

  function handleSearch(val) {
    if (mandates.search === val) {
      return;
    }
    props.getMandates(1, mandates.page_size, mandates.sort_field, mandates.sort_order, val, mandates.filter);
  }

  function changeCount(value, data, start) {
    setCount(value);
    !start && props.getMandates(1, mandates.page_size, mandates.sort_field, mandates.sort_order, mandates.search, {...data, group: 'all'});
  }

  function handleChangePage(page, page_size, sort_field, sort_order){
    props.getMandates(page, page_size, sort_field, sort_order, mandates.search, mandates.filter);
  }

  function closeFilter() {
    setActive(!active);
  }

  return (
    <div className="main-container admin-container">
      <div className="action-panel">
        <Search
          placeholder="Search"
          allowClear
          className="content-search"
          suffix={<img src={Images.search} alt="" />}
          defaultValue={mandates.search}
          onSearch={handleSearch}
        />
        <Button type={`primary${count ? ' orange' : ' white'}`} className="btn-primary" onClick={closeFilter}>
          {'Filter' + (count ? ` (${count})` : '')}
        </Button>
        <MandateFilterPanel
          filterData={mandates.filter}
          active={active}
          sectors={sectorFocus}
          continents={geographyFocus}
          investmentTypes={investmentTypes}
          choices={mandates.choices}
          dealSizeList={mandates.dealSizeList}
          changeCount={changeCount}
          closeFilter={closeFilter}
        />
      </div>
      <div className="table-container">
        <MandatePipelineTable
          data={mandates.list}
          page={mandates.page}
          fetching={mandates.fetching}
          pageSize={mandates.page_size}
          onChangePage={handleChangePage}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    mandates: state.mandates,
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    investmentTypes: state.settings.investmentTypes,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getMandates: (page, page_size, sort_field, sort_order, search, filter) => dispatch(MandatesActions.mandatesRequest(page, page_size, sort_field, sort_order, search, filter)),
  getMandateStatuses: () => dispatch(MandatesActions.mandateStatusesRequest()),
  getDealSize: () => dispatch(MandatesActions.dealSizeRequest()),
  setMandateId: (mandateId) => dispatch(MandatesActions.setMandateId(mandateId)),

  setDealId: (dealId) => dispatch(DealsActions.setDealId(dealId)),

  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: (filter) => dispatch(SettingsActions.geographyFocusRequest(filter)),
  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MandatePipelinePage)
