import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Spin, Tabs, Row, Col, InputNumber, DatePicker, message, Tooltip } from 'antd'
import moment from 'moment'
import { FileIcon, defaultStyles } from 'react-file-icon'

import MandateDealSharingReview from '../../Components/MandateDealSharing/MandateDealSharingReview'
import DealDashboardActions from '../../Redux/DealDashboardRedux'
import ExpertConsultActions from '../../Redux/ExpertConsultRedux'
import DealsActions from '../../Redux/DealsRedux'
import MandatesActions from '../../Redux/MandatesRedux'

import ActionsTable from '../../Components/Tables/ActionsTable'
import ExpertCActionsTable from '../../Components/Tables/ExpertCActionsTable'
import Images from '../../Images'

const { TabPane } = Tabs;

function MatchCompanyPage(props) {
	const { dashboardInfo, result, fetching, match, expertConsultInfo, dataHistoryEC, adminEditResult, investmentTypes, deal, location, role } = props;
	const { dashboardId } = match.params;
	const { state } = location;

	const history = useHistory();

	const [data, setData] = useState(null);
	const [dataHistory, setDataHistory] = useState(null);
	const [dataEC, setDataEC] = useState(null);
	const [dataHistoryEClength, setdataHistoryEClength] = useState([]);
	const [file, setFile] = useState(null);
	const [btnActive, setBtnActive] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [showDealInfo, setShowDealInfo] = useState(false);
	const [dealData, setDealData] = useState(null);
	const [activeKey, setActiveKey] = useState('deal_actions');
	const [start, setStart] = useState(false);

	useEffect(() => {
		props.getDealDashboardInfo(dashboardId);
		props.getExpertConsultInfo(dashboardId);
		props.expertConsultActionHistoryRequest(dashboardId);
		state?.fromDealPipeline && props.setFromDP(!!state?.fromDealPipeline);
		state?.fromInvestorHistory && props.setFromIH(!!state?.fromInvestorHistory, state?.investorId);
		return function cleanup() {
			props.dealDashboardSyncToggle(false);
			props.expertConsultSyncToggle(false);
			props.setFromIH(false, null);
		};
	}, [0]);

	useEffect(() => {
		if (deal) {
			setDealData(deal);
			props.dealDashboardSyncToggle(true);
			props.expertConsultSyncToggle(true);
			if (deal.mandate && !start) {
				setShowDealInfo(true);
				setActiveKey('deal_info');
				setStart(true);
			}
		}
	}, [deal]);

	useEffect(() => {
		if (dashboardInfo) {
			setData(dashboardInfo);
			if (dashboardInfo.mandate) {
				props.setMandateId(dashboardInfo.mandate);
			} else {
				props.setMandateId(null);
			}
		}
	}, [dashboardInfo]);

	useEffect(() => {
		if (dataHistoryEC) {
			setDataHistory(dataHistoryEC);
		}
	}, [dataHistoryEC]);

	useEffect(() => {
		if (expertConsultInfo) {
			setDataEC(expertConsultInfo);
			setFile(expertConsultInfo.file);
			setBtnActive(expertConsultInfo.upload_agreement)
		}
	}, [expertConsultInfo]);

	useEffect(() => {
		if (dashboardInfo?.action_history?.length > dataHistoryEClength) {
			props.expertConsultActionHistoryRequest(dashboardId);
			setdataHistoryEClength(dashboardInfo?.action_history?.length);
		}
	}, [dashboardInfo?.action_history?.length]);

	useEffect(() => {
		if (data) {
			props.getDeal(data.deal);
		}
	}, [data]);

	useEffect(() => {
		if (result) {
			props.getDealDashboardInfo(dashboardId);
		}
	}, [result]);


	useEffect(() => {
		if (adminEditResult) {
			message.success('Data successfully updated', 3);
			props.getExpertConsultInfo(dashboardId);
		}
	}, [adminEditResult]);

	const UploadSignedAgreement = () => {
		history.push(`/home/match-company/${dashboardId}/upload-signed-agreement`);
	};

	const changeValue = (field, value) => {
		if (value) {
			if (field === 'ends_at') {
				value = value.format('YYYY-MM-DD');
			}
			props.editDataByAdmin(expertConsultInfo?.id, { [field]: value });
		}
	}

	const fileType = useCallback((el) => {
		return el?.name.split('.')[el?.name.split('.')?.length - 1]
	}, [])

	const onTabChange = key => {
		setActiveKey(key);
	}

	return (
		<div className='main-container match-company'>
			{
				dealData &&
				<Tabs activeKey={activeKey} style={{ paddingTop: 32 }} className='deal-deatils-tab' onChange={onTabChange}>
					{
						showDealInfo &&
						<TabPane tab='Deal information' key='deal_info'>
							{
              dealData.mandate &&
              <div className="deal-top-info">
                <div className="deal-top-info-item">
                  <span>Mandate code</span>
                  <div>{dealData.mandate.id}</div>
                </div>
                <div className="deal-top-info-item">
                  <span>Investment firm</span>
                  <div>{dealData.mandate.invest_firm}</div>
                </div>
                <div className="deal-top-info-item">
                  <span>Investor(s)</span>
                  <div>{dealData.mandate.investors?.map(el => el.name).join(', ')}</div>
                </div>
              </div>
            }
							<MandateDealSharingReview
								dealData={dealData}
								investmentTypes={investmentTypes}
								role={role}
							/>
						</TabPane>
					}
					<TabPane tab='Deal actions' key='deal_actions'>
						<Spin spinning={fetching} size="large">
							<div className='advisordealmain-container admin'>
								<div className='flex-center-vertical' style={{ marginBottom: 24 }}>
									{/* <div className='section-targetcompany dealoverwiew'>
										<div className='section-text dealoverwiew'>
											<span>Target company:</span>
											<span className='blue dealoverwiew'>{data?.target_company?.name}</span>
										</div>
									</div> */}
									{data?.revertable && (
										<Button
											type='primary white'
											className='btn-primary'
											style={{ marginLeft: 'auto' }}
											onClick={() => props.revertActionByAdmin(dashboardId)}
										>
											Revert action
										</Button>
									)}
								</div>
								<div className='section-status' style={{maxWidth: 'initial'}}>
									<div className='section-row'>
										<span>Status:</span>
										<span className='text'>{data?.status_obj?.name}</span>
										<div className='wrapper'>
											<span className='section-subtitle'>Length:</span>
											<span className='blue'>{`${data?.length?.num_months} month, ${data?.length?.num_days} days`}</span>
										</div>
									</div>
								</div>

								<div className='match-company-content'>
									<div className='match-company-side'>
										<div className='match-company-header'>
											<span>Advisor</span>
										</div>
										<ActionsTable
											data={data?.actions.filter(el => el.user_role_obj?.id === 0 && el.location_obj?.id === 0)}
											dashboardId={dashboardId}
										/>
									</div>

									<div className='match-company-divider' />

									<div className='match-company-side'>
										<div className='match-company-header'>
											<span>Investor</span>
										</div>
										<ActionsTable
											data={data?.actions.filter(el => el.user_role_obj?.id === 2 && el.location_obj?.id === 0)}
											dashboardId={dashboardId}
										/>
									</div>
								</div>
							</div>
						</Spin>
					</TabPane>
					<TabPane tab='Expert Consulting actions' key='ec_actions'>
						<Spin spinning={fetching} size="large">
							<div className='advisordealmain-container admin'>
								<div className='flex-center-vertical' style={{ marginBottom: 24 }}>
									{/* <div className='section-targetcompany dealoverwiew'>
										<div className='section-text dealoverwiew'>
											<span>Target company:</span>
											<span className='blue dealoverwiew'>{data?.target_company?.name}</span>
										</div>
									</div> */}
									<a href={file} target='_blank' rel='noreferrer' style={{ margin: '12px 12px 12px auto' }}
									className='dowload-button'
									>
										<Button
											type='primary white'
											className='btn-primary'
											style={{ width: 'auto' }}
											disabled={!file}
										>
											Download agreement
										</Button>
									</a>
									<div className='dowload-button'>
									<Button
										type='primary white'
										className='btn-primary'
										style={{ width: 'auto'}}
										disabled={!btnActive}
										onClick={UploadSignedAgreement}
									>
										Upload signed agreement
									</Button>
									</div>
								</div>
								<Row gutter={[16, 8]} style={{ width: '100%', flexWrap: 'nowrap' }}>
									<Col style={{marginRight: 'auto'}}>
										<div className='section-status'>
											<div className='section-row'>
												<span>Status:</span>
												<span className='text' style={{marginRight: 32}}>{data?.status_obj?.name}</span>
												<div className='wrapper'>
													<span className='section-subtitle'>Length:</span>
													<span className='blue'>{`${data?.length?.num_months} month, ${data?.length?.num_days} days`}</span>
												</div>
											</div>
										</div>
									</Col>
									<Col>
										<div className='hours_admin_panel'>
											{
												(file && !btnActive) &&
												<img src={Images.edit} alt="" onClick={() => setIsEdit(!isEdit)} />
											}
											<Row gutter={24}>
												<Col span={8}>
													{
														isEdit ?
															<InputNumber
																className="ec-data"
																defaultValue={dataEC?.daily_rate}
																min={0}
																onBlur={e => changeValue('daily_rate', e.target.value)}
															/>
															:
															<div className='price'>{dataEC?.daily_rate == null ? 'N/A' : `£${dataEC.daily_rate}`}</div>
													}
													<div className='price-title'>
													{
															dataEC?.remuneration === 1 ?
																<span>Fixed<br />rate</span>
																:
																<span>Daily<br />rate</span>
														}
													</div>
												</Col>

												<Col span={8}>
													{
														isEdit ?
															<InputNumber
																className="ec-data"
																defaultValue={dataEC?.days_confirmed}
																min={0}
																onBlur={e => changeValue('days_confirmed', e.target.value)}
															/>
															:
															<div className='price'>{dataEC?.days_confirmed}</div>
													}
													<div className='price-title'>
														Days <br /> confirmed
													</div>
												</Col>
												<Col span={8}>
													<div className='price blue'>{dataEC?.total_fees ? `£${dataEC?.total_fees}` : '0'}</div>
													<div className='price-title'>
														Total <br /> Fees
													</div>
												</Col>
											</Row>
										</div>

										<div className='contract-ends-row' style={{ margin: '4px auto' }}>
											{
												isEdit ?
													<DatePicker
														className="ec-data"
														defaultValue={dataEC?.ends_at && moment(dataEC.ends_at)}
														format="DD/MM/YYYY"
														onChange={date => changeValue('ends_at', date)}
													/>
													:
													<div className='date-contract-ends'>
														{dataEC?.ends_at ? moment(dataEC.ends_at).format('DD/MM/YYYY') : 'N/A'}
														<span className='text-label'> -[Contract ends]</span>
													</div>
											}
										</div>
									</Col>
									{/* <Col>
									<Row>

									</Row>
									<Row >
                    <Button className='contract-ends-row' style={{backgroundColor: '#EBEFFC'}} onClick={openPopup}>
                        <div className='date-contract-ends'>
                          EDIT
                        </div>
                    </Button>
                  </Row>
								</Col> */}
								</Row>

								<div className='match-company-content'>
									<div className='match-company-side'>
										<div className='match-company-header'>
											<span>Advisor</span>
										</div>
										<ExpertCActionsTable data={dataHistory?.filter(el => el.role === 'Advisor')} />
									</div>

									<div className='match-company-divider' />

									<div className='match-company-side'>
										<div className='match-company-header'>
											<span>Investor</span>
										</div>
										<ExpertCActionsTable data={dataHistory?.filter(el => el.role === 'Investor')} />
									</div>
								</div>
							</div>
						</Spin>
					</TabPane>

					<TabPane tab='Documents' key='documents'>
						<Spin spinning={fetching} size="large">
							<div className='advisordealmain-container admin'>
								<div className='flex-center-vertical' style={{ marginBottom: 24 }}>

								</div>
								<div className='match-company-content'>
									<div className='match-company-side'>
										<div className='match-company-header'>
											<span>Advisor</span>
										</div>
										<div className='documents-admin' style={{ height: '60vh', padding: 18 }}>
											<div className='documents-admin clone'>
												{data?.documents?.filter(el => el.user_role === 0).map((el, i) => (
													<div key={i}>
														<div className='document-admin'
															key={`${el.date}-${el.i}`}
														>
															<a href={el.url} target='_blank' rel='noreferrer'
																style={{ marginTop: 5, display: 'flex', alignItems: 'center', flexDirection: 'column' }}
															>
																<div className='document-admin-icon' >
																	<FileIcon
																		extension={fileType(el)}
																		{...defaultStyles[`${fileType(el)}`]}
																	/>
																</div>
																<Tooltip
																	placement="bottom"
																	title={el.name}
																>
																	<div className='document-admin-title'>{el.name}</div>
																</Tooltip>
																<div className='document-admin-title-date'>{moment(el.date).format('DD:MM:YYYY')}</div>
																<div className='document-admin-title-date'>{moment(el.date).format('HH:mm')}&nbsp;(GMT)</div>
															</a>
														</div>
														<Tooltip
															placement="top"
															title={el.action_history_text}

														>
															<div className='document-admin-footer'                            >
																{el.action_history_text}
															</div>
														</Tooltip>
													</div>
												))}
											</div>
										</div>
									</div>

									<div className='match-company-divider' />

									<div className='match-company-side'>
										<div className='match-company-header'>
											<span>Investor</span>
										</div>
										<div className='documents-admin' style={{ height: '60vh', padding: 18 }}>
											<div className='documents-admin clone'>
												{data?.documents?.filter(el => el.user_role === 2).map((el, i) => (
													<div key={i}>
														<div className='document-admin'
															key={`${el.date}-${el.i}`}
														>
															<a href={el.url} target='_blank' rel='noreferrer'
																style={{ marginTop: 5, display: 'flex', alignItems: 'center', flexDirection: 'column' }}
															>
																<div className='document-admin-icon' >
																	<FileIcon
																		extension={fileType(el)}
																		{...defaultStyles[`${fileType(el)}`]}
																	/>
																</div>
																<Tooltip
																	placement="bottom"
																	title={el.name}
																>
																	<div className='document-admin-title'>{el.name}</div>
																</Tooltip>
																<div className='document-admin-title-date'>{moment(el.date).format('DD:MM:YYYY')}</div>
																<div className='document-admin-title-date'>{moment(el.date).format('HH:mm')}&nbsp;(GMT)</div>
															</a>
														</div>
														<Tooltip
															placement="top"
															title={el.action_history_text}
														>
															<div className='document-admin-footer'>
																{el.action_history_text}
															</div>
														</Tooltip>
													</div>
												))}
											</div>
										</div>
									</div>
								</div>
							</div>
						</Spin>
					</TabPane>
				</Tabs>
			}
		</div>
	);
}

const mapStateToProps = state => {
	return {
		dashboardInfo: state.dealDashboard.dashboardInfo,
		result: state.dealDashboard.result,
		fetching: state.dealDashboard.fetching,
		deal: state.deals.deal,
		expertConsultInfo: state.expertConsult.expertConsultInfo,
		dataHistoryEC: state.expertConsult.ecHistoryActions,
		adminEditResult: state.expertConsult.adminEditResult,
		investmentTypes: state.settings.investmentTypes,
		role: state.auth.role,
	};
};

const mapDispatchToProps = dispatch => ({
	getDealDashboardInfo: dashboardId => dispatch(DealDashboardActions.dealDashboardRequest(dashboardId)),
	revertActionByAdmin: dashboardId => dispatch(DealDashboardActions.adminRevertActionRequest(dashboardId)),
	dealDashboardSyncToggle: syncIsStart => dispatch(DealDashboardActions.dealDashboardSyncToggle(syncIsStart)),

	getExpertConsultInfo: dashboardId => dispatch(ExpertConsultActions.expertConsultingRequest(dashboardId)),
	expertConsultActionHistoryRequest: dashboardId => dispatch(ExpertConsultActions.expertConsultActionHistoryRequest(dashboardId)),
	expertConsultSyncToggle: syncIsStart => dispatch(ExpertConsultActions.expertConsultSyncToggle(syncIsStart)),
	editDataByAdmin: (consultId, data) => dispatch(ExpertConsultActions.adminEditDataRequest(consultId, data)),

	getDeal: (dealId) => dispatch(DealsActions.dealRequest(dealId)),

	setMandateId: (mandateId) => dispatch(MandatesActions.setMandateId(mandateId)),
	setFromDP: (fromDP) => dispatch(MandatesActions.setFromDealPipeline(fromDP)),
	setFromIH: (fromIH, investorId) => dispatch(MandatesActions.setFromInvestorHistory(fromIH, investorId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchCompanyPage);
