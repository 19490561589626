import React, { useState, useEffect } from 'react'
import { Collapse, Input, message } from 'antd'
import _ from 'lodash'

import CollapseHeader from '../CollapseHeader'
import Images from '../../Images'

const { Panel } = Collapse;
const { TextArea } = Input;

const initial = {
  active: false,
  deal_overview: ' ',
  company_overview: '',
  transaction: '',
  timeline: '',
}
//for advisor role - without deal_overview
const initial2 = {
  active: false,
  company_overview: '',
  transaction: '',
  timeline: '',
}

function DealSharingStep2(props) {
  const {checkRequired, isAccordion, dealData, isAdmin} = props;
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    isAdmin ? setData(initial) : setData(initial2);
  }, [0]);

  useEffect(() => {
    if (isAdmin) {
      if (dealData && dealData.investment_overview && !error) {
        const deal = _.cloneDeep(dealData);
        const newData = {...initial};
        newData.deal_overview = deal.investment_overview.deal_overview || ' ';
        newData.company_overview = deal.investment_overview.company_overview;
        newData.transaction = deal.investment_overview.transaction;
        newData.timeline = deal.investment_overview.timeline;
        setData(newData);
      } else if (dealData && dealData.reshared && dealData.target_information) {
        const deal = _.cloneDeep(dealData);
        setData({
          ...initial,
          company_overview: deal.target_information.target_overview,
          deal_overview: deal.target_information?.deal_overview
        });
      }
    } else {
      if (dealData && dealData.investment_overview && !error) {
        const deal = _.cloneDeep(dealData);
        const newData = {...initial2};
        newData.company_overview = deal.investment_overview.company_overview;
        newData.transaction = deal.investment_overview.transaction;
        newData.timeline = deal.investment_overview.timeline;
        setData(newData);
      } else if (dealData && dealData.reshared && dealData.target_information && !error) {
        const deal = _.cloneDeep(dealData);
        setData({
          ...initial2,
          company_overview: deal.target_information.target_overview,
        });
      }
    }
  }, [dealData]);

  useEffect(() => {
    if (checkRequired) {
      checkRequiredFields();
    }
  }, [checkRequired]);

  const changeArea = (field, e) => {
    setError(false);
    const newData = { ...data };
    if (field === 'deal_overview' && !e.target.value) {
      newData[field] = ' ';
    } else {
      newData[field] = e.target.value;
    }
    setData(newData);
  }

  const checkRequiredFields = () => {
    const newData = { ...data };
    newData.active = true;
    setData(newData);
    if (Object.values(newData).every(el => el) && !error) {
      delete newData.active;
      setError(false);
      props.onChangeStep(newData, 'investment_overview');
    } else if (isAccordion) {
      message.error('All fields must be filled in to proceed', 3);
      setError(true);
    } else {
      message.error('All fields must be filled in to proceed (2)', 3);
      setError(true);
      // props.onChangeStep(null, 'investment_overview');
    }
  }

  return (
    (data && dealData) &&
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ?
            <img src={Images.angleUp} alt="" />
            :
            <img src={Images.angleDown} alt="" />
        }
        expandIconPosition="right"
        className="site-collapse-custom-collapse"
        accordion={isAccordion}
        defaultActiveKey={isAccordion ?  [] : ['deal_overview', 'company_overview', 'transaction', 'timeline']}
      >
        {isAdmin && 
        <Panel
          header={<CollapseHeader text="Deal Overview" imageType="briefcase" />} key="deal_overview"
          className={`site-collapse-custom-panel${(data && data.active && !data.deal_overview) ? ' error-block' : ''}`}
        >
          <div>
            <div className="collapse-content-area">
              <TextArea
                placeholder="Insert here a one line descriptive overview of the deal (max 500 characters)"
                rows={3}
                maxLength={500}
                defaultValue={dealData.investment_overview?.deal_overview || dealData.target_information?.deal_overview}
                onBlur={(e) => changeArea('deal_overview', e)}
              />
            </div>
          </div>
        </Panel>
        }

        <Panel
          header={<CollapseHeader text="Company Overview" imageType="company" />} key="company_overview"
          className={`site-collapse-custom-panel${(data && data.active && !data.company_overview) ? ' error-block' : ''}`}
        >
          <div>
            <div className="collapse-content-area">
              <TextArea
                placeholder="Please provide here a brief overview on the company, ideally encompassing its outlook, competitive positioning, main business model, regulations, etc. (500 words max)"
                rows={15}
                maxLength={5000}
                defaultValue={dealData.investment_overview?.company_overview ? dealData.investment_overview?.company_overview :
                   (dealData.reshared ? dealData.target_information?.target_overview : null)}
                onBlur={(e) => changeArea('company_overview', e)}
              />
            </div>
          </div>
        </Panel>

        <Panel
          header={<CollapseHeader text="Transaction background & rationale" imageType="coins" />} key="transaction"
          className={`site-collapse-custom-panel${(data && data.active && !data.transaction) ? ' error-block' : ''}`}
        >
          <div>
            <div className="collapse-content-area">
              <TextArea
                placeholder="Please explain why the company is looking to sell/receive an investment and why an investment in this company can be attractive / what the potential ROI could be (200 words max)"
                rows={15}
                maxLength={5000}
                defaultValue={dealData.investment_overview?.transaction}
                onBlur={(e) => changeArea('transaction', e)}
              />
            </div>
          </div>
        </Panel>

        <Panel
          header={<CollapseHeader text="Expected Timeline" imageType="clock" />} key="timeline"
          className={`site-collapse-custom-panel${(data && data.active && !data.timeline) ? ' error-block' : ''}`}
        >
          <div>
            <div className="collapse-content-area">
              <TextArea
                placeholder="Indicate what the expected timeline of the deal could be, including timeline and milestones after you are matched with an investor through IDN’s platform."
                rows={15}
                maxLength={5000}
                defaultValue={dealData.investment_overview?.timeline}
                onBlur={(e) => changeArea('timeline', e)}
              />
            </div>
          </div>
        </Panel>

      </Collapse>
  )
}

export default DealSharingStep2
