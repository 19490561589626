import React, { useState, useEffect } from 'react'
import { Space } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import PopupWrapper from './PopupWrapper'
import ExperienceActions from '../../Redux/ExperienceRedux'
import AddWorkPopupForm from '../../Components/Forms/AddWorkPopupForm'
import Images from '../../Images'

import './PopupPage.less'

function AddWorkPopupPage(props) {
  const { user, isAdmin, match, location } = props;
  const { advisorId } = match.params;
  const { state } = location;

  const [pathToClose, setPathToClose] = useState(null);

  const history = useHistory();

  useEffect(() => {
    const path = isAdmin ?
      `/home/advisor-details/${advisorId}/experience`
      :
      state ?
        '/home/deal-sharing/step/profile'
        :
        '/home/experience';

    setPathToClose(path);
  }, [0]);

  function handleSubmit(data) {
    props.addJobs(isAdmin ? advisorId : user.id, null, data);
    history.push(pathToClose);
  }

  return (
    <PopupWrapper
      width={550}
      path={pathToClose}
      hasDropdown={true}
    >
      <div className="dialog-content dynamic" style={{ padding: 40 }}>
        <Space style={{ marginBottom: 16 }}>
          <img src={Images.addressBookLg} alt="" />
        </Space>
        <div className="dialog-title">Work experience</div>
        <AddWorkPopupForm onSubmit={handleSubmit} />
      </div>
    </PopupWrapper>
  )
}


const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    isAdmin: state.auth.isAdmin
  }
}

const mapDispatchToProps = (dispatch) => ({
  addJobs: (advisorId, jobId, data) => dispatch(ExperienceActions.experienceJobSaveRequest(advisorId, jobId, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddWorkPopupPage)
