import React from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

import PopupWrapper from './PopupWrapper'
import Images from '../../Images'
import './PopupPage.less'

function DealSharedPopupPage(props) {

  const history = useHistory();

  function handleClose() {
    history.push('/home/deals/your');
  }

  return (
    <PopupWrapper
      width={420}
      path={'/home/deals/your'}
    >
      <div className="dialog-content" style={{ padding: 40 }}>
        <img src={Images.checkCircle} alt="" />
        <div className="dialog-title deal">Deal shared</div>
        <div className="deal-text">Congratulations for originating this deal!</div>
        <br />
        <div className="deal-subtext">As a next step, you will receive email notifications anytime new investors express interest in your deal. In the meantime, please feel free to contact us if you have any query.</div>
        <div className="btn-footer">
          <Button type="primary white" className="btn-primary" onClick={handleClose}>
            Continue
            </Button>
        </div>
      </div>
    </PopupWrapper>
  )
}

export default DealSharedPopupPage
