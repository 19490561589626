import React, { useState, useEffect } from 'react'
import { Layout, Progress, Button, Space } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Switch, Route, Link } from 'react-router-dom'

import SettingsActions from '../../Redux/SettingsRedux'
import DealsActions from '../../Redux/DealsRedux'
import ExperienceActions from '../../Redux/ExperienceRedux'

import DealSharingStart from '../../Components/DealSharing/DealSharingStart'
import DealSharingStep1 from '../../Components/DealSharing/DealSharingStep1'
import DealSharingStep2 from '../../Components/DealSharing/DealSharingStep2'
import DealSharingStep3 from '../../Components/DealSharing/DealSharingStep3'
import DealSharingStep4 from '../../Components/DealSharing/DealSharingStep4'
import DealSharingReview from '../../Components/DealSharing/DealSharingReview'
import DealSharingProfile from '../../Components/DealSharing/DealSharingProfile'
import Images from '../../Images'

const initial = {
  1: {
    title: 'Step 1/4 - Deal Matching Criteria',
    progress: 16,
    text: 'The fields outlined below are the foundations of our matching algorithm. Please try to fill them in at the best of your knowledge as this will enable us to match you with relevant investors'
  },
  2: {
    title: 'Step 2/4 - Investment Overview',
    progress: 32,
    text: 'Please provide an overview of the asset, context on the transaction background and rationale, and the expected timeline'
  },
  3: {
    title: 'Step 3/4 - Your Role',
    progress: 48,
    text: 'Please elaborate on your relationship with the target company and on your potential role in this transaction.'
  },
  4: {
    title: 'Step 4/4 - Investor Sought',
    progress: 64,
    text: 'Please indicate the type of investor sought for this deal opportunity, you have the possibility of creating screening questions which can help you choose the right investor for this opportunity.'
  },
  review: {
    title: 'Deal Review',
    progress: 80,
    text: 'Please review all the information you have inserted and confirm they are correct'
  },
  profile: {
    title: 'Your Profile',
    progress: 100,
    text: 'Please review carefully your profile information. Only your "Experience - Summary", "Experience - Deal origination" and "Experience - Working with Private Equity" will be shown to the Investor, keeping your identity anonymous.'
  }
}

function DealSharingPage(props) {
  const { deal, saveResult, dealTargetStatusSaveResult, dealTargetStatusUpdateResult, dealOverviewResult, dealRoleInfoResult,
    dealInvestorSoughtResult, dealGroupDataResult, dealChangeStatusResult, dealShareResult, dealReshareResult, dealNotificationResult,
    match, location, sectorFocus, geographyFocus, equityGovernanceList, investmentTypes, user, experience, getExperience, updateExperience,
     deleteJob, shareDealByAdvisor, reshareDealByAdvisor, role, deals,
  } = props;
  const { step } = match.params;
  const { state } = location;

  const [dealData, setDealData] = useState(null);
  const [dealId, setDealId] = useState(null);
  const [checkRequired, setCheckRequired] = useState(false);
  const [titleData, setTitleData] = useState(initial);
  const [targetStatus, setTargetStatus] = useState(null);
  const [targetId, setTargetId] = useState(null);
  const [fromMandate, setFromMandate] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);

  const history = useHistory();

  function changeStep(data, check) {
    if (step === 'start' && check) {
      history.push('/home/deal-sharing/step/start/not-qualified');
    } else if (step === 'start' && !check) {
      if (targetId) {
        data.deal = dealId;
        props.updateDealTargetStatus(targetId, data);
      } else {
        setTargetStatus(data);
        if (dealId?.substr(0, 1) === 'R') {
          history.push('/home/deal-sharing/step/review');
        } else {
          history.push('/home/deal-sharing/step/1');
        }
      }
    } else if (step === '1') {
      if (fromMandate) {
        // data.investment_overview = null;
        // data.advisor_role = null;
        data.investor_sought = null;
        props.saveDealGroupData(dealId, data);
      } else {
        props.saveDeal(data);
      }
    } else if (step === '2') {
      data.deal = dealId;
      props.saveDealOverview(data);
    } else if (step === '3') {
      data.deal = dealId;
      props.saveDealRoleInfo(data);
    } else if (step === '4') {
      data.deal = dealId;
      props.saveDealInvestorSought(data);
    } else if (step === 'review') {
      props.resetExperience();
      history.push('/home/deal-sharing/step/profile');
    }
  }

  useEffect(() => {
    // if (!user?.signed_platform_agreement) {
    //   history.push('/home/deals');
    //   return;
    // }
    if (state?.dealId) {
      setDealId(state.dealId);
    }
    if (state?.targetId) {
      setTargetId(state.targetId);
    }
    if (state?.fromMandate) {
      setFromMandate(state.fromMandate);
    }
    props.getSectors();
    props.getContinents({ regions: true });
    props.getEquityGovernance();
    props.getInvestmentTypes();
  }, [0]);

  useEffect(() => {
    switch(step) {
      case '1':
      case '2':
      case '3':
      case '4': {
        if (isFinite(currentStep) && (step > currentStep)) {
          setCurrentStep(step);
        } else if (currentStep !== 'start') {
          history.push('/home/deals');
        } else {
          setCurrentStep(step);
        }
        break;
      }
      case 'start':
      case 'review':
      case 'profile': {
        setCurrentStep(step);
        break;
      }
      default: break;
    }
  }, [step]);

  useEffect(() => {
    if (deal) {
      setDealData(deal);
    }
  }, [deal]);

  useEffect(() => {
    if (dealId) {
      props.getDeal(dealId);
    }
  }, [dealId]);

  useEffect(() => {
    if (saveResult) {
      setDealId(saveResult.id);
      const data = {...targetStatus};
      data.deal = saveResult.id;
      props.saveDealTargetStatus(data);
    }
  }, [saveResult]);

  useEffect(() => {
    if (dealGroupDataResult && targetStatus) {
      const data = {...targetStatus};
      data.deal = dealId;
      props.saveDealTargetStatus(data);
    }
  }, [dealGroupDataResult]);

  useEffect(() => {
    if (dealTargetStatusSaveResult) {
      if (fromMandate) {
        props.changeDealStatus(dealId, 'in_progress');
      } else {
        history.push('/home/deal-sharing/step/2');
      }
      history.push('/home/deal-sharing/step/2');
    }
  }, [dealTargetStatusSaveResult]);

  useEffect(() => {
    if (dealChangeStatusResult) {
      history.push('/home/deal-sharing/step/2');
    }
  }, [dealChangeStatusResult]);

  useEffect(() => {
    if (dealTargetStatusUpdateResult) {
      history.push('/home/deal-sharing/step/review');
    }
  }, [dealTargetStatusUpdateResult]);

  useEffect(() => {
    if (dealOverviewResult) {
      history.push('/home/deal-sharing/step/3');
    }
  }, [dealOverviewResult]);

  useEffect(() => {
    if (dealRoleInfoResult) {
      history.push('/home/deal-sharing/step/4');
    }
  }, [dealRoleInfoResult]);

  useEffect(() => {
    if (dealInvestorSoughtResult) {
      props.getDeal(dealId);
      history.push('/home/deal-sharing/step/review');
    }
  }, [dealInvestorSoughtResult]);

  useEffect(() => {
    if (dealShareResult) {
      if (dealData?.reshared) {
        props.changeResharedStatus(dealId, {reshared: false});
      } else {
        history.push('/home/deals/your/deal-shared');
      }
    }
  }, [dealShareResult]);

  useEffect(() => {
    if (dealReshareResult) {
      history.push('/home/deals');
    }
  }, [dealReshareResult]);

  useEffect(() => {
    if (dealNotificationResult) {
      history.push('/home/deals');
    }
  }, [dealNotificationResult]);

  const checkRequiredFields = () => {
    setCheckRequired(true);
    setTimeout(()=> {
      setCheckRequired(false);
    })
  }

  return (
    <Layout>
      <div className="sign-up-step white filters-edit">
        <img src={Images.logo} alt="" onClick={()=>history.push('/home/deals')} />
        {
          step !== 'start' && step !== 'edit' &&
          <div className="step-investor-content">
            <div className="step-title">NEW DEAL SHARING</div>
            <div className="step-number">{titleData[step].title}</div>
            <Progress
              className="progress-bar"
              percent={titleData[step].progress}
              showInfo={false}
              strokeWidth={13}
              trailColor="rgba(31, 73, 228, 0.12)"
              strokeColor="#3680F6"
              status="active"
            />
            <div className="step-text">{titleData[step].text}</div>
          </div>
        }
        <Switch>
          <Route path="/home/deal-sharing/step/start" render={
            props => (
              <DealSharingStart
                onChangeStep={changeStep}
              />
            )} />
          <Route path="/home/deal-sharing/step/1" render={
            props => (
              <DealSharingStep1
                checkRequired={checkRequired}
                isAccordion={true}
                dealData={fromMandate ? dealData : true}
                sectorFocus={sectorFocus}
                geographyFocus={geographyFocus}
                equityGovernanceList={equityGovernanceList}
                investmentTypes={investmentTypes}
                onChangeStep={changeStep}
              />
            )} />
          <Route path="/home/deal-sharing/step/2" render={
            props => (
              <DealSharingStep2
                checkRequired={checkRequired}
                isAccordion={true}
                dealData={dealData}
                onChangeStep={changeStep}
              />
            )} />
          <Route path="/home/deal-sharing/step/3" render={
            props => (
              <DealSharingStep3
                checkRequired={checkRequired}
                isAccordion={true}
                dealData={dealData}
                onChangeStep={changeStep}
              />
            )} />
          <Route path="/home/deal-sharing/step/4" render={
            props => (
              <DealSharingStep4
                checkRequired={checkRequired}
                isAccordion={true}
                dealData={dealData}
                onChangeStep={changeStep}
              />
            )} />
          <Route path="/home/deal-sharing/step/review" render={
            props => (
              <DealSharingReview
                dealId={dealId}
                dealData={dealData}
                isSharing={true}
                onChangeStep={changeStep}
                role={role}
              />
            )} />
          <Route path="/home/deal-sharing/step/profile" render={
            props => (
              <DealSharingProfile
                dealId={dealId || deals?.dealId}
                state={state}
                targetId={targetId}
                user={user}
                experience={experience}
                onChangeStep={changeStep}
                getExperience={getExperience}
                updateExperience={updateExperience}
                deleteJob={deleteJob}
                shareDealByAdvisor={shareDealByAdvisor}
                reshareDealByAdvisor={reshareDealByAdvisor}
              />
            )} />
        </Switch>
        {
          step !== 'start' && step !== 'review' && step !== 'profile' && step !== 'edit' &&
          <>
            <Button type="primary" className="btn-primary" style={{ width: 217, marginTop: 40 }} onClick={checkRequiredFields}>
              Continue
            </Button>
            <Space className="return-link" style={{marginTop: 24}}>
              <Link to="/home/deals">
                <img src={Images.arrowLeft} alt="" />
                <span>Return to main dashboard</span>
              </Link>
            </Space>
          </>
        }
      </div>
    </Layout>
  )
}


const mapStateToProps = (state) => {
  return {
    deal: state.deals.deal,
    deals: state.deals,
    saveResult: state.deals.saveResult,
    dealTargetStatusSaveResult: state.deals.dealTargetStatusSaveResult,
    dealTargetStatusUpdateResult: state.deals.dealTargetStatusUpdateResult,
    dealOverviewResult: state.deals.dealOverviewResult,
    dealRoleInfoResult: state.deals.dealRoleInfoResult,
    dealInvestorSoughtResult: state.deals.dealInvestorSoughtResult,
    dealGroupDataResult: state.deals.dealGroupDataResult,
    dealChangeStatusResult: state.deals.dealChangeStatusResult,
    dealShareResult: state.deals.dealShareResult,
    dealReshareResult: state.deals.dealReshareResult,
    dealNotificationResult: state.deals.dealNotificationResult,
    sectorFocus: state.settings.sectorFocus,
    geographyFocus: state.settings.geographyFocus,
    equityGovernanceList: state.settings.equityGovernanceList,
    investmentTypes: state.settings.investmentTypes,
    user: state.auth.userInfo,
    role: state.auth.role,
    experience: state.experience.list,
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveDeal: (data) => dispatch(DealsActions.dealSaveRequest(data)),
  saveDealOverview: (data) => dispatch(DealsActions.dealOverviewSaveRequest(data)),
  saveDealRoleInfo: (data) => dispatch(DealsActions.dealRoleInfoSaveRequest(data)),
  saveDealInvestorSought: (data) => dispatch(DealsActions.dealInvestorSoughtSaveRequest(data)),
  saveDealGroupData: (id, data) => dispatch(DealsActions.dealGroupDataSaveRequest(id, data)),
  getDeal: (id) => dispatch(DealsActions.dealRequest(id)),
  shareDealByAdvisor: (id) => dispatch(DealsActions.dealShareByAdvisorRequest(id)),
  reshareDealByAdvisor: (id) => dispatch(DealsActions.dealReshareByAdvisorRequest(id)),
  changeDealStatus: (id, status) => dispatch(DealsActions.dealChangeStatusRequest(id, status)),
  changeResharedStatus: (id, data) => dispatch(DealsActions.dealNotificationDeleteRequest(id, data)),

  saveDealTargetStatus: (data) => dispatch(DealsActions.dealTargetStatusSaveRequest(data)),
  updateDealTargetStatus: (targetId, data) => dispatch(DealsActions.dealTargetStatusUpdateRequest(targetId, data)),

  getSectors: () => dispatch(SettingsActions.sectorFocusRequest()),
  getContinents: (filter) => dispatch(SettingsActions.geographyFocusRequest(filter)),
  getCountries: (filter) => dispatch(SettingsActions.countriesRequest(filter)),
  getEquityGovernance: () => dispatch(SettingsActions.equityGovernanceRequest()),
  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),

  getExperience: (userId) => dispatch(ExperienceActions.experienceRequest(userId)),
  resetExperience: () => dispatch(ExperienceActions.experienceReset()),
  updateExperience: (userId, data) => dispatch(ExperienceActions.experienceSaveRequest(userId, data)),
  deleteJob: (userId, jobId) => dispatch(ExperienceActions.experienceJobDeleteRequest(userId, jobId)),

})

export default connect(mapStateToProps, mapDispatchToProps)(DealSharingPage)
