import React from 'react'
// import { Button } from 'antd'
// import { useHistory } from 'react-router-dom'

import PopupWrapper from './PopupWrapper'
import './PopupPage.less'

function ConfirmingMatchDealPopupPage({ match }) {
  const { shareId } = match.params;

  // const history = useHistory();

  return (
    <PopupWrapper
      width={420}
      path={`/home/deal-investor-firm/${shareId}`}
    >
      <div className="dialog-content" style={{ padding: '40px 52px' }}>
        <div className="dialog-title deal">Match confirmation</div>
        <div className="deal-text md">
          {/* In order to engage the investor and disclose the name of the target company, you have to review carefully the confirmation
          email we have just sent you, and click on the link contained therein in order to progress. */}
          To confirm the match and initiate the engagement with this investor, please follow the instructions
          contained in the email that was just sent to you.
        </div>
      </div>
      <br />
    </PopupWrapper>
  )
}

export default ConfirmingMatchDealPopupPage
