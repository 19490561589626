import React, { useState, useEffect, Fragment } from 'react'
import { Button, Tabs, Input, message, Spin } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
// import mixpanel from 'mixpanel-browser'

import SettingsActions from '../../Redux/SettingsRedux'
import MandatesActions from '../../Redux/MandatesRedux'
import MandateSharesActions from '../../Redux/MandateSharesRedux'
import DealsActions from '../../Redux/DealsRedux'

import MandateSharingReview from '../../Components/MandateSharing/MandateSharingReview'
import MandatesAdvisorDealsSumbittedTable from '../../Components/Tables/MandatesAdvisorDealsSumbittedTable'
import Images from '../../Images'

import _ from 'lodash'

const { TabPane } = Tabs;
const { TextArea } = Input;

const styleObj = {
  maxWidth: 'auto',
  minWidth: '1392px',
};

function MandateShareInfoPage(props) {
  const { mandate, mandates, mandateShares, investmentTypes, dealSizeList, match, mandateAddByAdvisorResult, fetching, mandateSharesDeleteResult, mandateAskQuestionResult, matchResult, dealNotificationResult, notifications, mandateQuestionResult, user } = props;
  const { tab, mandateShareId } = match.params;

  const [mandateData, setMandateData] = useState(null);
  const [allData, setAllData] = useState(null);
  const [dealId, setDealId] = useState(null);
  const [activeKey, setActiveKey] = useState(tab !== 'deals-submitted' ? 'summary' : 'deals-submitted');
  const [backPath, setBackPath] = useState('');
  const [questions, setQuestions] = useState(null)

  const history = useHistory();

  useEffect(() => {
    props.getInvestmentTypes();
    props.getDealSize();
    props.getMandateMatchInfo(mandateShareId);
  }, [0]);

  useEffect(() => {
    if (matchResult) {
      setMandateData(matchResult.mandate);
      setAllData(matchResult);
      setBackPath(`/home/mandates${matchResult.advisor_status === 'closed' ? '/closed' : ''}`);
      props.getDealsSubmitted(mandates.page_ds, mandates.page_size_ds, matchResult.mandate?.id);
      setQuestions(matchResult.mandate?.mandate_questions?.map(el => ({
        id: el.id,
        active: false,
        question: el.question,
        answer: el.answer,
        advisor: el.advisor,
        text: '',
      })))
    }
  }, [matchResult]);

  useEffect(() => {
    if (mandateQuestionResult) {
      props.getMandateMatchInfo(mandateShareId);
      message.success("IDN’s team will review and share the question", 3);
    }
  }, [mandateQuestionResult]);

  useEffect(() => {
    if (mandate) {
      setMandateData(mandate);
    }
  }, [mandate]);

  useEffect(() => {
    if (allData) {
      if (allData.notifications?.length) {
        showNotification(allData.notifications[0]);
      }
    }
  }, [allData]);

  useEffect(() => {
    if (allData && notifications?.mandate) {
      if (allData.notifications?.length) {
        showNotification(allData.notifications[0]);
      }
    }
  }, [notifications]);

  useEffect(() => {
    if (dealNotificationResult) {
      const item = mandates.dealsSubmittedList?.results?.find(el => el.id === dealId);
      const dashboardId = item.dashboard;
      if (dashboardId) {
        history.push({
          pathname: `/deal-dashboard/overview/${dashboardId}`,
          state: { backPath: `/home/mandate-share-info/${mandateShareId}/deals-submitted` }
        });
      } else {
        history.push(`/home/mandate-deal/${dealId}`);
      }
    }
  }, [dealNotificationResult]);

  const deleteNotification = (dealId) => {
    setDealId(dealId);
    props.deleteDealNotification(dealId, { advisor_notification: false });
  }

  const handleChangePage = (page, page_size) => {
    props.getDealsSubmitted(page, page_size, mandateData?.id);
  }

  const showNotification = (notification) => {
    if (notification != null) {
      const type = notification?.name_obj?.id;
      const state = {
        notification: notification.id,
      }
      let path;
      switch (type) {
        case 0: {
          path = {
            pathname: `/home/mandate-share-info/${mandateShareId}/${tab}/mandate-closed-out/`,
            state
          };
          break;
        }
        default:
          break;
      }
      if (path) {
        history.push(path);
      }
    }
  };

  const onTabChange = key => {
    history.push(`/home/mandate-share-info/${mandateShareId}/${key}`);
    setActiveKey(key);
  }

  useEffect(() => {
    if (mandateAddByAdvisorResult && mandateAddByAdvisorResult.success) {
      message.success(mandateAddByAdvisorResult.success, 3);
      props.getMandateMatchInfo(mandateShareId);
    }
    if (mandateSharesDeleteResult && mandateSharesDeleteResult.success) {
      message.success(mandateSharesDeleteResult.success, 3);
      props.getMandateShares(1, mandateShares.page_size);
    }
    if (mandateAskQuestionResult && mandateAskQuestionResult.success) {
      message.success(mandateAskQuestionResult.success, 3);
      props.getMandateMatchInfo(mandateShareId);
    }
  }, [mandateAddByAdvisorResult, mandateSharesDeleteResult, mandateAskQuestionResult])

  const onCloseMandate = key => {
    history.push(`/home/mandate-share-info/${mandateShareId}/${tab}/close-out-mandate/`);
  }

  const onAskQuestion = () => {
    history.push({ pathname: `/home/mandate-share-info/${mandateShareId}/${tab}/ask-question`, state: mandateData?.id });
  }

  const onSubmit = () => {
    if (allData?.advisor_status !== 'on_hold') {
      if (user?.signed_platform_agreement) {
        history.push({
          pathname: `/home/mandate-deal-sharing/step/start`,
          state: { mandateShareId }
        });
        // mixpanel.track('advisor starts deal sharing process', {
        //   mandate_id: mandateData?.id,
        //   role: 'advisor'
        // })
      } else {
        history.push({
          pathname: `/home/mandate-share-info/${mandateShareId}/${activeKey ? activeKey + '/' : ''}platform-agreement-info`,
          state: { isMandate: true }
        })
      }
    }
  }

  const showMessage = () => {
    message.success('The mandate is currently on hold. Please contact IDN for further information')
  }

  const generateTabTitle = key => {
    let title, showNote;
    switch (key) {
      case 'deals-submitted': {
        title = 'Deals submitted';
        showNote = mandates.dealsSubmittedList?.results?.some(el => el.advisor_notification);
        break;
      }
      default: {
        title = 'Mandate Summary';
        break;
      }
    }
    return (
      <div className="tab-title">
        <span>{title}</span>
        {
          showNote &&
          <img src={Images.notification} alt="" />
        }
      </div>
    )
  }

  const changeActive = (id) => {
    let newData = _.cloneDeep(questions);
    const index = newData.findIndex(el => el.id === id)
    newData[index].active = true;
    setQuestions(newData)
  }

  const changeQuestion = (value, id) => {
    let newData = _.cloneDeep(questions);
    const index = newData.findIndex(el => el.id === id)
    newData[index].text = value;
    setQuestions(newData)
  }

  const saveQuestion = (id) => {
    const index = questions.findIndex(el => el.id === id);
    if (!questions[index].text) {
      message.error('Enter question please');
      return;
    }
    let data = {
      id: questions[index].id,
      question: questions[index].text,
    }
    if (questions[index].question !== questions[index].text) {
      props.saveMandateQuestion(mandateData.id, data);
    } else if (questions[index].question === questions[index].text) {
      let newData = _.cloneDeep(questions);
      // const index = newData.findIndex(el => el.id === id);
      newData[index].active = false;
      setQuestions(newData);
    }
  }

  return (
    <div className="sign-up-step filters-edit wo-padding" style={{paddingBottom: 0}}>
      <div className="absolute-header">
        <div className="flex-center-vertical">
          <div className="logo-block">
            <img src={Images.logoWhite} alt="" />
          </div>
          <div className="title-block bottom">{mandateData?.id}</div>
        </div>
      </div>
      <div className="return-block">
        <Link to={backPath}>
          <img src={Images.arrowLeft} alt="" />
          <span>Return to Mandates</span>
        </Link>
      </div>

      <Tabs
        activeKey={activeKey}
        onChange={onTabChange}
        className='deals-submitted'
      >
        <TabPane tab={generateTabTitle('summary')} key="summary">
          <Spin size="large" spinning={fetching}>
            <div className="close-document">
              {
                allData && allData.advisor_group !== 'closed' &&
                  <div className="close-document link delete" onClick={onCloseMandate}>
                    X Close out this mandate
                  </div>
              }
            </div>
            <MandateSharingReview
              mandateData={mandateData}
              investmentTypes={investmentTypes}
              dealSizeList={dealSizeList}
            />

            {mandateData?.mandate_questions?.length > 0 &&

              <div className="review-block mandate" style={{ width: '100%' }}>
                <div className="overview-title mandate" style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                  <div style={{ fontSize: 22, alignSelf: 'flex-start' }}>Additional information - Q&A</div>
                  <div className="overview-title mandate subtitle" style={{ margin: '15px 0' }}>Below are listed all queries asked by Senior Advisors to the investor in relation to the mandate, as well as the investor's answers to those</div>
                </div>
                {questions?.map(el =>
                  <Fragment key={el.id}>
                    <div className="overview-title mandate smaller">
                      <div className="overview-title mandate smaller mandate-question">Question:</div>
                      {
                        !el.active
                          ?
                          <div style={{ fontWeight: 600, marginRight: 'auto', wordBreak: 'break-word' }}>{el.question}</div>
                          :
                          <>
                            <TextArea
                              defaultValue={el.question}
                              maxLength={300}
                              autoSize={true}
                              onBlur={(e) => changeQuestion(e.target.value, el.id)}
                            />
                            <div
                              className='save-btn'
                              onClick={() => saveQuestion(el.id)}
                            >Save</div>
                          </>
                      }
                      {!el.answer && !el.active && user?.id === el.advisor &&
                        <img src={Images.edit} style={{ marginLeft: 24 }} alt="" onClick={() => changeActive(el.id)} />}
                    </div>
                    <div className="overview-title mandate smaller" >
                      <div className="overview-title mandate smaller" style={{ padding: '25px 0', marginBottom: 'auto' }}>Answer:</div>
                      <div className="area-text addinfo">
                        <TextArea
                          value={el.answer}
                          disabled={true}
                          autoSize={true}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
            }

            {
              allData && allData.advisor_group !== 'closed' &&
                <div className="review-block deal-share-info">
                  <div>
                    <Button type="primary white"
                      className="btn-primary"
                      onClick={onAskQuestion}
                    >
                      Ask a question
                    </Button>
                    <span>[Get clarifications from the investor]</span>
                  </div>
                  <div>
                    <Button type="primary orange-margin"
                      className="btn-primary"
                    >
                      <a
                        className="share-now-link"
                        href='https://www.google.com/url?q=https://docs.google.com/forms/d/1Vy-38r8uO8k_hsVz-dqwl4NQuqC9jCzkFnupsJOhlw8/edit&sa=D&source=docs&ust=1644395572666159&usg=AOvVaw1N__yr0RvDFsqiqjliCp2f'
                        target="_blank" rel="noopener noreferrer">
                        Referral
                      </a>
                    </Button>
                    <span>[Refer a Senior Advisor to get a fee]</span>
                  </div>
                  <div>
                    <Button
                      type={mandateData?.status_obj?.id === "on_hold" ? "primary greyout" : "primary"}
                      className="btn-primary"
                      onClick={mandateData?.status_obj?.id === "on_hold" ? showMessage : onSubmit}
                    >
                      Submit a deal
                    </Button>
                    <span>[Submit a deal in response to this Mandate]</span>
                  </div>
                </div>
            }

          </Spin>
        </TabPane>
        <TabPane tab={generateTabTitle('deals-submitted')} key="deals-submitted" style={{ width: '100%' }}>
          {
            mandateShares?.list?.results?.length
              ?
              <MandatesAdvisorDealsSumbittedTable
                mandateShareId={mandateShareId}
                tab={tab}
                mandateId={mandateData?.id}
                data={mandates.dealsSubmittedList}
                page={mandates.page_ds}
                fetching={false}
                pageSize={mandates.page_size_ds}
                onChangePage={handleChangePage}
                deleteNotification={deleteNotification}
              />
              :
              <div className="section-content info">You don’t have any active mandate in your pipeline</div>
          }
        </TabPane>
      </Tabs>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    role: state.auth.role,
    user: state.auth.userInfo,
    mandate: state.mandates.mandate,
    mandates: state.mandates,
    mandateId: state.mandates.mandateId,
    fetching: state.mandates.fetching,
    dealSizeList: state.mandates.dealSizeList,
    mandateShares: state.mandateShares,
    mandateAddByAdvisorResult: state.mandates.mandateAddByAdvisorResult,
    mandateSharesDeleteResult: state.mandateShares.mandateSharesDeleteResult,
    matchResult: state.mandateShares.matchResult,
    mandateQuestionResult: state.mandates.mandateQuestionResult,
    mandateAskQuestionResult: state.mandates.mandateAskQuestionResult,
    dealNotificationResult: state.deals.dealNotificationResult,
    investmentTypes: state.settings.investmentTypes,
    notifications: state.notifications.notifications,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDealsSubmitted: (page, page_size, mandateId) => dispatch(MandatesActions.dealsSubmittedRequest(page, page_size, mandateId)),
  getDealSize: () => dispatch(MandatesActions.dealSizeRequest()),
  addMandateByAdvisor: (mandateId) => dispatch(MandatesActions.mandateAddByAdvisorRequest(mandateId)),
  saveMandateQuestion: (mandateId, data) => dispatch(MandatesActions.mandateQuestionEditRequest(mandateId, data)),

  getMandateMatchInfo: (mandateShareId) => dispatch(MandateSharesActions.mandateShareMatchRequest(mandateShareId)),
  getMandateShares: (page, page_size, filter) => dispatch(MandateSharesActions.mandateSharesRequest(page, page_size, filter)),

  deleteDealNotification: (id, data) => dispatch(DealsActions.dealNotificationDeleteRequest(id, data)),

  getInvestmentTypes: () => dispatch(SettingsActions.investmentTypesRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MandateShareInfoPage)
